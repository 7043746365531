<template>
  <div @click="playSoundChiffre">
    <DeUniteComplex0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeUniteComplex1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeUniteComplex2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeUniteComplex3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeUniteComplex4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeUniteComplex5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeUniteComplex6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeUniteComplex7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeUniteComplex8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeUniteComplex9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeUniteComplex10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeUniteComplex0 from "./DeUniteComplex0";
import DeUniteComplex1 from "./DeUniteComplex1";
import DeUniteComplex2 from "./DeUniteComplex2";
import DeUniteComplex3 from "./DeUniteComplex3";
import DeUniteComplex4 from "./DeUniteComplex4";
import DeUniteComplex5 from "./DeUniteComplex5";
import DeUniteComplex6 from "./DeUniteComplex6";
import DeUniteComplex7 from "./DeUniteComplex7";
import DeUniteComplex8 from "./DeUniteComplex8";
import DeUniteComplex9 from "./DeUniteComplex9";
import DeUniteComplex10 from "./DeUniteComplex10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_Unite,
  playSound
} from "../../../../js/utils";



//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  }
});


//
// Reactive values
//

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  var soundPath;
  if (props.withSound) {
    if (props.withSoundDecomposed)
      soundPath = getSoundPath_Unite(refChiffre.value);
    else 
      soundPath = getSoundPath_Chiffre(refChiffre.value);
    playSound(soundPath);
  } 
  
}



//
// LifeCycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>