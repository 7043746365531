<template>
  <div class="de"></div>
</template>

<style scoped lang="scss">

  .de {
    width: 288px;
    height: 300px;
    background-image: url(../../../../assets/images/svg/jeu_des_couleurs/des/Number_1.svg);
  }

</style>
