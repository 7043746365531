<template>
  <div class="content content-v2">
    <HeaderOutils
        :title="titreOutil"
        :niveau="niveau"
    />

    <main class="activite" >
        <TableMultiplication :niveau="niveau"/>
    </main>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import HeaderOutils from "../../../components/v2/Headers/HeaderOutils";
  import TableMultiplication from "../../../components/v2/Outils/TableMultiplication";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  import {computed, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";


  // Niveau de l'activité
  const route = useRoute();
  const niveau = computed(() => parseInt(route.params.niveau) );

  // Titre activité :
  const titreOutil = ref('');
  const store = useStore();
  const outilInfos = store.getters.outilFromPath('table-de-multiplication');

  titreOutil.value = outilInfos.titre;

   // Composant de l'activité
  //const activite = ref(null);


  /*
  function replay() {
    activite.value.replay();
  }
  */
</script>