<template>
  <div class="content content-v2">

    <HeaderParcours
        :title="titreParcours"
        :username="username"
        :parcours-slug="parcoursSlug"
    />

    <div class="parcours-sommaire">

      <ul class="activites-sommaire">
        <li v-for="(item, i) in activitesParcours" :key="i" >

          <div class="resume">
            <!-- Icone -->
            <div :class="getIdActivite(item.path)">
              <BoutonNiveauActivite
                  :titre="getTitreActivite(item.path)"
                  :niveau="getNiveauActivite(item.path)"
                  class="disabled"
              />
            </div>
            <!-- Smileys -->
            <div class="simple-stats" @click="showDetailedStats(i)">
              <ul class="smileys">
                <li v-for="item in getStatsActivite(i)" v-bind:key="item">
                <span
                    :class="item.isCorrect ? 'success' : 'error' "
                    class="smiley"
                />
                </li>
              </ul>
            </div>
          </div>

          <!-- Pour l'impression uniquement : détails des essais de l'élève -->
          <div class="details">
            <div class="table-parent">
              <table class="help-panel-contents">
                <thead>
                <tr>
                  <th><!--
                    {{ getTitreActivite(item.path) }} - Niveau {{ getStatsActivite(i)[0].niveau }}
                  --></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td colspan="2">
                    <h5>Enoncé</h5>
                  </td>
                </tr>
                <tr class="enonce">
                  <td colspan="2">
                    <div>
                      <span>{{ getStatsActivite(i)[0].enonce }}</span>
                      <strong>{{ getStatsActivite(i)[0].solution }}</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <h5>Propositions</h5>
                  </td>
                </tr>
                <tr v-for="detailedStat in getStatsActivite(i)" :key="detailedStat" class="propositions">
                  <td colspan="2">
                    <div>
                      <span><strong>{{ detailedStat.proposition }}</strong></span>
                      <span
                          :class="detailedStat.isCorrect ? 'success' : 'error' "
                          class="smiley"
                      />
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="help-panel" :class="isOpenedCssClass">
      <div class="table-parent" v-if="detailedStats !== null && detailedStatsActivite !== null">
        <table class="help-panel-contents">
          <thead>
          <tr>
            <th class="help-panel-close-parent-button">
              <a class="help-panel-close-button" href="#" @click="closePanel($event)"></a>
            </th>
            <th>
              {{ detailedStatsActivite }} - Niveau {{ detailedStatsNiveau }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="2">
              Enoncé
            </td>
          </tr>
          <tr class="enonce">
            <td colspan="2">
              <div>
                <span>{{ detailedStatsEnonce }}</span>
                <strong>{{ detailedStatsSolution }}</strong>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              Propositions
            </td>
          </tr>
          <tr v-for="detailedStat in detailedStats" :key="detailedStat" class="propositions">
            <td colspan="2">
              <div>
                <span><strong>{{ detailedStat.proposition }}</strong></span>
                <span
                    :class="detailedStat.isCorrect ? 'success' : 'error' "
                    class="smiley"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ResponsiveWarning />
  </div>

</template>

<script setup>

import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {stopAllSounds} from "../../../js/utils";

import HeaderParcours from "../../../components/v2/Headers/HeaderParcours.vue";
import BoutonNiveauActivite from "../../../components/v2/Sommaires/BoutonNiveauActivite.vue";
import ResponsiveWarning from "../../../components/v2/ResponsiveWarning.vue";


const store = useStore();
const route = useRoute();
const router = useRouter();

const titreParcours = ref('Titre du parcours');
const activitesParcours = ref(null);
const username = ref(null);

const detailedStats = ref(null);
const detailedStatsActivite = ref(null);
const detailedStatsNiveau = ref(null);
const detailedStatsEnonce = ref(null);
const detailedStatsSolution = ref(null);

// Parcours courant
const parcoursSlug = ref('');

// Liste des activités (cf activites_v2.js )
const tous_les_parcours  = ref(store.state.parcours);

const getParcours = function(id) {
  const n = tous_les_parcours.value.length;
  let i, parcours;
  for(i=0;i<n;i++) {
    parcours = tous_les_parcours.value[i];
    if (parcours.id === id) {
      return parcours;
    }
  }
}

const getIdActivite = function(pathActivite) {
  if (!pathActivite) return '';
  // Expression régulière
  let params;
  if (pathActivite.indexOf('/niveau/') === -1) {
    // ===> [ "/activite/dictee-vocale", "dictee-vocale" ]
    params = pathActivite.match(/.*\/(.*)$/)
  } else {
    // ===> [ "/activite/dictee-vocale/niveau/1", "dictee-vocale", "niveau", "1" ]
    params = pathActivite.match(/.*\/(.*)\/(.*)\/(.*)$/)
  }
  return params.length ? params[1] : "";
}

const getTitreActivite = function(pathActivite) {
  if (!pathActivite) return '';
  const route = router.resolve({ path: pathActivite});
  return route ? route.name.split('custom').join('') : pathActivite;
}

const getNiveauActivite = function(pathActivite) {
  if (!pathActivite) return '';
  const route = router.resolve({ path: pathActivite});
  return route ? parseInt(route.params.niveau) : "";
}

const getStatsActivite = function(playlistStepNo) {
  return store.getters.getPlaylistHistoryForStep(playlistStepNo);
}

const showDetailedStats = function (playlistStepNo) {
  // Retourne toutes les tentatives (complètes) de l'élève pour l'activité/niveau cliquée :
  detailedStats.value = getStatsActivite(playlistStepNo);

  // Dans chaque tentative, sont stockées le nom de l'activité et le niveau.
  // On les récupère dans le premier élément :
  if (detailedStats.value.length > 0) {
    const firstStat = detailedStats.value[0];
    detailedStatsActivite.value = firstStat.activite.split('custom').join('');
    detailedStatsNiveau.value = firstStat.niveau;
    detailedStatsEnonce.value = firstStat.enonce;
    detailedStatsSolution.value = firstStat.solution;
  } else {
    detailedStatsActivite.value = null;
    detailedStatsNiveau.value = null;
    detailedStatsEnonce.value = null;
    detailedStatsSolution.value = null;
  }

  isOpened.value = true;

  // console.log("detailed statistics", detailedStats.value, detailedStatsActivite.value, detailedStatsNiveau.value);
}

const isOpened = ref(false);
const isOpenedCssClass = computed(() => isOpened.value ? 'opened' : '');

const closePanel = function($event) {
  $event.preventDefault();
  isOpened.value = false;
}



//
// Lifecycle Hooks
//

watch( route.params.id, () => {
  init();
});

onMounted(() => {
  init();
  document.body.className = 'heigth-auto';
});

onUnmounted(() => {
  document.body.className = '';
});


//
// Init
//

const init = function() {

  stopAllSounds();

  const parcoursId = route.params.id;

  // Parcours courant ( ajoutera le bouton replay )
  parcoursSlug.value = parcoursId;

  // On recupère les informations du parcours à partir de l'id
  const parcours = getParcours(parcoursId);
  if (parcours)
  {
    titreParcours.value = parcours.titre;
    activitesParcours.value = parcours.routes.concat();
    username.value = store.getters.getPlaylistUsername();

    const n = activitesParcours.value.length;
    let i, pathParcours;
    for(i=0;i<n;i++) {
      pathParcours = activitesParcours.value[i];
      pathParcours.query = { playlist: i };
    }
  }
};


</script>

<style scoped lang="scss">

  .parcours-sommaire {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul.activites-sommaire {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin: 50px auto;
    padding: 0;
    list-style-type: none;
    flex-wrap: wrap;
    gap: 20px;

    @include on-print {
      gap: 5px;
      margin: 10px auto 0;
    }

    & > li {
      flex: 25% 0 0;

      @include on-print {
        flex: 100% 0 0;
      }

      display: flex;
      gap: 40px;

      & > .resume {
        flex: 360px 0 0;
        align-self: flex-start;

        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 25px;
        background-color: #FFFFFF50;
        border: solid 2px #333333;
        border-radius: 20px;
        margin-bottom: 30px;
        cursor: default;

        @include on-print {
          flex: 350px 0 0;
          gap: 15px;
          padding: 15px 0 0;
          margin-bottom: 0;
        }

        & > div {
          min-width: 300px;

          &.simple-stats {
            cursor: pointer;
          }

          .niveau-button {
            height: 200px;
          }
        }
      }

      & > .details {
        display: none;
        flex: calc( 100% - 360px ) 0 0;


        @include on-print {
          display: block;
        }
      }
    }

  }

  ul.smileys {
    list-style-type: none;
    padding: 0;
  }

  ul.smileys {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 5px;
  }

  span.smiley {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  span.smiley.success {
    background-color: #94e088;
    background-image: url(../../../assets/images/svg/smileys/smiley-success.svg);
  }

  span.smiley.error {
    background-color: #f6756c;
    background-image: url(../../../assets/images/svg/smileys/smiley-error.svg);
  }

  .content-v2 .help-panel {
    position: absolute;
    top: 140px;
    right: 0;
    z-index: 3;

    display: inline-block;
    transition: all ease-out 0.5s;
    transform: translateX(100%);

    @include on-print {
      display: none;
    }
  }

  .content-v2 .help-panel.opened {
    transform: translateX(0%);
  }

  .content-v2 .help-panel .table-parent {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    max-height: calc( 100vh - 200px );
    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.25) transparent;

    ::-webkit-scrollbar {
      width: 9px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155, 0.25);
      border-radius: 20px;
      border: transparent;
    }
  }

  .content-v2 .help-panel .table-parent table.help-panel-contents {
    min-width: 320px;
  }

  .content-v2 .help-panel .table-parent table.help-panel-contents tr > td:first-child {
    width: 35px;
  }

  .content-v2 .help-panel .table-parent table.help-panel-contents tr > td {
    text-align: left;
    padding: 10px 50px 10px 0;
  }

  .content-v2 .table-parent table.help-panel-contents tr.enonce > td > div,
  .content-v2 .table-parent table.help-panel-contents tr.propositions > td > div {
    font-family: "Quicksand", sans-serif !important;
    display: flex;
    align-items: center;
    gap: 10px;

  }

  .content-v2 .details .table-parent table.help-panel-contents tr > td h5 {
    font-family: "Titan One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #666666;
    margin: 10px 0;
  }

  /* iPad */

  @media screen and (max-width: 1080px ) {

    .activite-sommaire {
      width: 300px;
      height: 190px;
    }

  }

</style>
