<template>
    <a class="order-toggle-btn"
       :class = "cssClass"
       href="#"
       @click="toggle($event)"
    >
      {{ buttonLabel }}
    </a>
</template>

<script setup>

  import { computed, ref } from "vue";

  const props = defineProps({
    ordre: {
      type: String,
      required: false,
      default: "asc"
    }
  });

  const ordre = ref(props.ordre);

  const cssClass = computed(() => ordre.value === "asc" ? 'croissant' : 'decroissant' );
  const buttonLabel = computed(() => ordre.value === "asc" ? 'Croissant' : 'Décroissant' );

  const emit = defineEmits(['change'])

  const toggle = function($event){
    $event.preventDefault();
    ordre.value = (ordre.value === "asc") ? "desc" : "asc";
    emit("change", ordre.value);
  };


</script>


<style scoped lang="scss">

  .order-toggle-btn {
    box-sizing: border-box;
    display: inline-block;
    height: 32px;
    padding: 4px 36px 4px 10px;

    background: #FFFFFF url(../../../assets/images/svg/icones/order-asc.svg) right 10px center / 20px auto no-repeat;
    border: 1px solid #333333;
    border-radius: 64px;
    text-decoration: none;

    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;

    &.decroissant {
      background-image: url(../../../assets/images/svg/icones/order-desc.svg)
    }
  }

</style>
