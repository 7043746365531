<template>
    <span class="signe"  @click="playOperation">-</span>
</template>

<script setup>

  import {getSoundPath, playSound} from "../../../js/utils";

  const playOperation = function(){
        var soundPath = getSoundPath("moins");
        playSound(soundPath);
  }

</script>
