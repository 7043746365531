<template>
  <div class="content content-v2">

    <HeaderActivite
        :title="titreActivite"
        :has-tools="true"
        :has-tools-active="toolsActivite"
        :has-replay="false"
        @openTools="openToolsPopUp"
    />

    <main>
      <div class="niveaux" :class="cssClassActivite">
        <router-link
            v-for="(item, index) in items"
            :key="index"
            :to="item.path"
            :class="item.cssClass"
        >
          <BoutonNiveauActivite
              :titre="item.titre"
          />
        </router-link>
      </div>
    </main>

    <div class="popup" :class="cssClassPopUp">
      <SettingsPanel :activite="pathActivite" @close="closeToolsPopUp" />
    </div>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import {computed, onMounted, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";

  import BoutonNiveauActivite from "../../components/v2/Sommaires/BoutonNiveauActivite";
  import HeaderActivite from "../../components/v2/Headers/HeaderActivite";
  import SettingsPanel from "../../components/v2/ActivitesSettings/SettingsPanel";
  import ResponsiveWarning from "../../components/v2/ResponsiveWarning";

  import {stopAllSounds} from "../../js/utils";

  const store = useStore();
  const route = useRoute();


  //
  // Reactive values
  //

  const titreActivite = ref('');
  const cssClassActivite = ref('');
  const toolsActivite = ref('');
  const items = ref(null);
  const cssClassPopUp = ref(null);

  const pathActivite = computed(() => route.params.activite);


  //
  // LifeCycle Hooks
  //

  onMounted(() => {

    stopAllSounds();

    // Détails de l'activité ( titre, nombre de niveaux, class CSS des niveaux, ...)
    const activiteInfos = store.getters.activiteFromPath(pathActivite.value);
    titreActivite.value = activiteInfos.titre;
    toolsActivite.value = activiteInfos.tools;
    cssClassActivite.value = activiteInfos.class + ' ' + pathActivite.value;

    const nombreNiveaux = activiteInfos.niveaux;
    const nombreNiveauxTablette = activiteInfos.niveaux_tablet ? activiteInfos.niveaux_tablet : nombreNiveaux;

    let i, niveaux = [];
    for(i=1; i<=nombreNiveaux; i++) {
      niveaux.push({
        titre: "Niveau " + i,
        path: '/activite/' + pathActivite.value + '/niveau/' + i,
        cssClass: [
          pathActivite.value + "",
          i <= nombreNiveauxTablette ? "tablet-compliant" : "tablet-not-compliant"
        ].join(" ")
      })
    }

    items.value = niveaux;

    // PROD
    document.addEventListener('keyup', function ($event) {
      // P for Popup :
      if ($event.which === 80) {
        openToolsPopUp();
      }
    })
  });

  //
  // Fonctions spécifiques
  //

  const openToolsPopUp = function() {
    cssClassPopUp.value = "open";
  }

  const closeToolsPopUp = function() {
    cssClassPopUp.value = "";
  }

</script>

<style scoped lang="scss">

  /* Masque les niveaux ne pouvant être affichés sur tablette */
  .tablet-not-compliant {
    @include on-tablet {
      display: none;
    }
  }

  .popup {
    position: fixed;
    top:0;
    left:0;
    z-index: 10;
    display: none;

    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.90);
    justify-content: center;
    align-items: center;

    &.open {
      display: flex;
    }
  }

</style>
