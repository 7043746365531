<template>
  <a class="svg-icon print-link hidden" href="#"></a>
</template>

<script>
export default {
  name: 'PrintBtn',
}
</script>

<style scoped lang="scss">

  a.svg-icon.print-link {
    background-image: url(../../../assets/images/svg/icones/print.svg);
  }

</style>
