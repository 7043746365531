<template>
  <div class="settings-tab-content">
    <div class="flex flex-gap-10">
      <div>
        <div></div>
        <InputNombre
            ref="inputText1"
            input-id="nombre1"
            text-label="Nombre 1"
            :input-value="currentInput1Value"
            @change="onChangeInput1"
        />
      </div>
      <div style="font-weight: bold">
        <div>+</div>
        <div></div>
      </div>
      <div>
        <InputNombre
            ref="inputText2"
            input-id="nombre2"
            text-label="Nombre 2"
            :input-value="currentInput2Value"
            @change="onChangeInput2"
        />
        <span>= {{ result1Value }}</span>
      </div>
      <div style="font-weight: bold">
        <div>+</div>
        <div></div>
      </div>
      <div>
        <InputNombre
            ref="inputText3"
            input-id="nombre3"
            text-label="Nombre 3"
            :input-value="currentInput3Value"
            @change="onChangeInput3"
        />
        <span>= {{ result2Value }}</span>
      </div>
      <div style="font-weight: bold">
        <div>+</div>
        <div></div>
      </div>
      <div>
        <InputNombre
            ref="inputText4"
            input-id="nombre4"
            text-label="Nombre 4"
            :input-value="currentInput4Value"
            @change="onChangeInput4"
        />
        <span>= {{ result3Value }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref, watch} from "vue";
import InputNombre from "../Controls/InputNombre";

//
// Propriétés du composant
//

const props = defineProps({
  tabId: {
    type: String,
    required: false,
    default: ""
  },
  tabValues: {
    type: Object,
    required: false,
    default: null
  },
});

// Référence des éléments du formulaire
const inputText1 = ref(null);
const inputText2 = ref(null);
const inputText3 = ref(null);
const inputText4 = ref(null);

// Valeurs des différents onglets
const currentTabValues = ref();

// Valeur de l'onglet courant
const currentInput1Value = ref("");
const currentInput2Value = ref("");
const currentInput3Value = ref("");
const currentInput4Value = ref("");

// Valeurs de l'interface de settings s'adaptant aux valeurs saisies
const result1Value = ref("--");
const result2Value = ref("--");
const result3Value = ref("--");

const emit = defineEmits(['change'])


//
// Lifecycle Hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

function update() {
  // console.log("props.tabValues", JSON.stringify(props.tabValues));
  const valueObj = currentTabValues.value = props.tabValues;

  // Champ texte 1 :
  if ((valueObj !== null) && (valueObj.v1 !== undefined)) {
    currentInput1Value.value = valueObj.v1;
  } else {
    currentInput1Value.value = "";
    inputText1.value.reset();
  }

  // Champ texte 2 :
  if ((valueObj !== null) && (valueObj.v2 !== undefined)) {
    currentInput2Value.value = valueObj.v2;
  } else {
    currentInput2Value.value = "";
    inputText2.value.reset();
  }

  // Champ texte 3 :
  if ((valueObj !== null) && (valueObj.v3 !== undefined)) {
    currentInput3Value.value = valueObj.v3;
  } else {
    currentInput3Value.value = "";
    inputText3.value.reset();
  }

  // Champ texte 4 :
  if ((valueObj !== null) && (valueObj.v4 !== undefined)) {
    currentInput4Value.value = valueObj.v4;
  } else {
    currentInput4Value.value = "";
    inputText4.value.reset();
  }

  updateResults();
}

function updateResults() {
  const values = currentTabValues.value;
  if (values && values.v1 && values.v2 &&! isNaN(values.v1) && ! isNaN(values.v2)) {
    result1Value.value = parseInt(values.v1) + parseInt(values.v2);
    if (values.v3 && ! isNaN(values.v3)) {
      result2Value.value = result1Value.value + parseInt(values.v3);
      if (values.v4 && ! isNaN(values.v4)) {
        result3Value.value = result2Value.value + parseInt(values.v4);
      } else {
        result3Value.value = "--";
      }
    } else {
      result2Value.value = "--";
    }
  } else {
    result1Value.value = "--";
    result2Value.value = "--";
    result3Value.value = "--";
  }
}


//
// Events
//

function onChangeInput1(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v1: inputValue, v2: "", v3: "", v4: "" };
  } else {
    currentTabValues.value.v1 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateResults();
}

function onChangeInput2(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v2: inputValue, v1: "", v3: "", v4: "" };
  } else {
    currentTabValues.value.v2 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateResults();
}

function onChangeInput3(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v3: inputValue, v1: "", v2: "", v4: "" };
  } else {
    currentTabValues.value.v3 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateResults();
}

function onChangeInput4(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v4: inputValue, v1: "", v2: "", v3: "" };
  } else {
    currentTabValues.value.v4 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateResults();
}

</script>

<style scoped lang="scss">

</style>