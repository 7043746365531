<template>
  <div class="settings-tab-content">
    <div>
      <Checkbox
          ref="checkbox"
          text-label="Aide colorée"
          input-value="aide-coloree"
          :checked="currentCheckboxValue"
          @change="onChangeCheckBox"
      />
    </div>
    <div>
      <InputNombre
          ref="inputText"
          :input-value="currentInputValue"
          text-label="Nombre"
          @change="onChangeInput"
      />
    </div>
  </div>
</template>

<script setup>

  import InputNombre from "../Controls/InputNombre";
import Checkbox from "../Controls/Checkbox";
  import {onMounted, ref, watch} from "vue";

  //
  // Propriétés du composant
  //

  const props = defineProps({
    tabId: {
      type: String,
      required: false,
      default: ""
    },
    tabValues: {
      type: Object,
      required: false,
      default: null
    },
  });

  // Référence des éléments du formulaire
  const inputText = ref(null);
  const checkbox = ref(null);

  // Valeurs des différents onglets
  const currentTabValues = ref();

  // Valeur de l'onglet courant
  const currentInputValue = ref("");
  const currentCheckboxValue = ref(true);

  const emit = defineEmits(['change'])


  //
  // Lifecycle Hooks
  //

  onMounted(() => {
    update();
  });

  watch( props, () => {
    // Mise à jour lorsqu'on passe d'un onglet à l'autre
    update();
  });

  function update() {
    // console.log("props.tabValues", JSON.stringify(props.tabValues));
    const valueObj = currentTabValues.value = props.tabValues;

    // Champ texte :
    if ((valueObj !== null) && (valueObj.v !== undefined)) {
      currentInputValue.value = valueObj.v;
    } else {
      currentInputValue.value = "";
      inputText.value.reset();
    }

    // Checkbox :
    let checkedValue;
    if ((valueObj !== null) && (valueObj.couleurs !== undefined)) {
      checkedValue = valueObj.couleurs;
    } else {
      checkedValue = true;
    }

    currentCheckboxValue.value = checkedValue;
    checkbox.value.setCheckBox(checkedValue);
  }

  //
  // Events
  //

  function onChangeInput(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: inputValue, couleurs: true };
    } else {
      currentTabValues.value.v = inputValue;
    }
    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeCheckBox(checkboxValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: "", couleurs: checkboxValue };
    } else {
      currentTabValues.value.couleurs = checkboxValue;
    }
    // console.log("change",  JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

</script>

<style scoped lang="scss">

</style>