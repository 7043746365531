<template>
  <div class="help-panel large-help-panel help-panel-milliers" :class="isOpenedCssClass">
    <div class="help-panel-button">
      <a href="#" @click="togglePanel($event)"></a>
    </div>
    <div class="table-parent">
      <table class="help-panel-contents">
        <thead>
          <tr>
            <th class="help-panel-close-parent-button">
              <a class="help-panel-close-button" href="#" @click="closePanel($event)"></a>
            </th>
            <th>
              <div>
                <span>Centaines de milliers</span>
              </div>
            </th>
            <th>
              <div>
                <span>Dizaines de milliers</span>
              </div>
            </th>
            <th>
              <div>
                <span>Milliers</span>
              </div>
            </th>
            <th>
              <div>
                <span>Centaines</span>
              </div>
            </th>
            <th>
              <div>
                <span>Dizaines</span>
              </div>
            </th>
            <th>
              <div>
                <span>Unités</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="thin-scroll">
          <tr v-for="item in items" :key="item">
            <td>
              {{ item }}
            </td>
            <td>
              <DeCentaineMillierComplex :chiffre="1" />
            </td>
            <td>
              <DeDizaineMillierComplex :chiffre="1" />
            </td>
            <td>
              <DeMillierComplex :chiffre="1" />
            </td>
            <td>
              <DeCentaineComplex :chiffre="1" />
            </td>
            <td>
              <DeDizaineComplex :chiffre="1" />
            </td>
            <td>
              <DeUniteComplex :chiffre="1" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>

  import {computed, ref} from "vue";

  import DeUniteComplex from "../Des/DesUnitesComplex/DeUniteComplex";
  import DeDizaineComplex from "../Des/DesDizainesComplex/DeDizaineComplex";
  import DeCentaineComplex from "../Des/DesCentainesComplex/DeCentaineComplex";
  import DeMillierComplex from "../Des/DesMilliersComplex/DeMillierComplex";
  import DeDizaineMillierComplex from "../Des/DesDizainesMilliersComplex/DeDizaineMillierComplex";
  import DeCentaineMillierComplex from "../Des/DesCentainesMilliersComplex/DeCentaineMillierComplex";

  const items = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];
  const isOpened = ref(false);
  const isOpenedCssClass = computed(() => isOpened.value ? 'opened' : '');

  // Fermer / Ouvrir (transition CSS)

  function togglePanel($event) {
    $event.preventDefault();
    isOpened.value = ! isOpened.value;
  }

  function closePanel($event) {
    $event.preventDefault();
    isOpened.value = false;
  }
</script>

<style scoped lang="scss">

.help-panel-milliers {

  transform: translateX(690px);
  transition: transform ease-in-out 0.75s;

  &.opened {
    transform: translateX(0);
  }

  table {

    width: 670px;

    tbody {
      display: block;
      height: calc( 100vh - 110px );
      overflow: auto;
      pointer-events: auto;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }

  }

}

</style>
