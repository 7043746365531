<template>
  <DeUniteDraggable0 v-if="refChiffre === 0" :css-class="cssClass" />
  <DeUniteDraggable1 v-else-if="refChiffre === 1" :css-class="cssClass" />
  <DeUniteDraggable2 v-else-if="refChiffre === 2" :css-class="cssClass" />
  <DeUniteDraggable3 v-else-if="refChiffre === 3" :css-class="cssClass" />
  <DeUniteDraggable4 v-else-if="refChiffre === 4" :css-class="cssClass" />
  <DeUniteDraggable5 v-else-if="refChiffre === 5" :css-class="cssClass" />
  <DeUniteDraggable6 v-else-if="refChiffre === 6" :css-class="cssClass" />
  <DeUniteDraggable7 v-else-if="refChiffre === 7" :css-class="cssClass" />
  <DeUniteDraggable8 v-else-if="refChiffre === 8" :css-class="cssClass" />
  <DeUniteDraggable9 v-else-if="refChiffre === 9" :css-class="cssClass" />
  <DeUniteDraggable10 v-else-if="refChiffre === 10" :css-class="cssClass" />
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeUniteDraggable0 from "./DeUniteDraggable0";
import DeUniteDraggable1 from "./DeUniteDraggable1";
import DeUniteDraggable2 from "./DeUniteDraggable2";
import DeUniteDraggable3 from "./DeUniteDraggable3";
import DeUniteDraggable4 from "./DeUniteDraggable4";
import DeUniteDraggable5 from "./DeUniteDraggable5";
import DeUniteDraggable6 from "./DeUniteDraggable6";
import DeUniteDraggable7 from "./DeUniteDraggable7";
import DeUniteDraggable8 from "./DeUniteDraggable8";
import DeUniteDraggable9 from "./DeUniteDraggable9";
import DeUniteDraggable10 from "./DeUniteDraggable10";

import {ref, onMounted, watch} from "vue";


//
// Reactive values
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  }
});

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

</script>
