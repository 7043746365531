<template>
  <div class="content content-v2">

    <HeaderOutils
        :title="titreOutil"
        :has-tools="true"
        :has-replay="false"
    />

    <main>

      <div class="niveaux" :class="cssClassOutil">
        <router-link
            v-for="(item, index) in items"
            :key="index"
            :to="item.path"
            :class="pathOutil"
        >
          <BoutonTableOutil
              :titre="item.titre"
          />
        </router-link>
      </div>

    </main>
  </div>
</template>

<script setup>

  import {onMounted, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";

  import HeaderOutils from "../../components/v2/Headers/HeaderOutils";
  import {stopAllSounds} from "../../js/utils";
  import BoutonTableOutil from "../../components/v2/Sommaires/BoutonTableOutil";

  const store = useStore();
  const route = useRoute();

  const pathOutil = ref('');
  const titreOutil = ref('');
  const cssClassOutil = ref('');
  const items = ref(null);

  //
  // LifeCycle Hooks
  //

  onMounted(() => {

    console.log("---------- LISTE NIVEAU OUTILS ---------------")
    stopAllSounds();

    const pathOutil = route.params.outil;

    // Détails de l'activité ( titre, nombre de niveaux, class CSS des niveaux, ...)
    const outilInfos = store.getters.outilFromPath(pathOutil);
    titreOutil.value = outilInfos.titre;
    cssClassOutil.value = outilInfos.class + ' ' + pathOutil;

    const nombreNiveaux = outilInfos.niveaux;
    let i, niveaux = [];
    for(i=1; i<=nombreNiveaux; i++) {
      niveaux.push({
        titre: i,
        path: '/outil/' + pathOutil + '/table/' + i
      })
    }
    items.value = niveaux;
  });


</script>

<style scoped lang="scss">

  .sommaire {
    width:100%;
    padding-bottom:  120px;
  }

  .sommaire h1 {
    margin-top: 30px;
  }

  .activites-sommaire {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    gap:30px;
  }

  .activites-sommaire > a {
    flex: calc( 50% - 15px ) 0 0;

    @include on-mobile {
      flex: 100% 0 0;
    }
  }

</style>
