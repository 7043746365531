<template>
  <div @click="playSoundChiffre">
    <DeDizaineCouleur0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeDizaineCouleur1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeDizaineCouleur2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeDizaineCouleur3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeDizaineCouleur4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeDizaineCouleur5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeDizaineCouleur6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeDizaineCouleur7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeDizaineCouleur8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeDizaineCouleur9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeDizaineCouleur10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeDizaineCouleur0 from "./DeDizaineCouleur0";
import DeDizaineCouleur1 from "./DeDizaineCouleur1";
import DeDizaineCouleur2 from "./DeDizaineCouleur2";
import DeDizaineCouleur3 from "./DeDizaineCouleur3";
import DeDizaineCouleur4 from "./DeDizaineCouleur4"
import DeDizaineCouleur5 from "./DeDizaineCouleur5";
import DeDizaineCouleur6 from "./DeDizaineCouleur6";
import DeDizaineCouleur7 from "./DeDizaineCouleur7";
import DeDizaineCouleur8 from "./DeDizaineCouleur8";
import DeDizaineCouleur9 from "./DeDizaineCouleur9";
import DeDizaineCouleur10 from "./DeDizaineCouleur10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_Dizaine,
  playSound
} from "../../../../js/utils";


//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  }
});


//
// Reactive values
//

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  var soundPath;
  
  if (props.withSound) {
    if (props.withSoundDecomposed)
      soundPath = getSoundPath_Dizaine(refChiffre.value);
    else 
      soundPath = getSoundPath_Chiffre(refChiffre.value * 10);
    playSound(soundPath);
  } 
}


//
// Lifecycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>