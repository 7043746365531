<template>
  <div class="input-nombre">
    <label>
      <span v-if="hasLabel">{{ textLabel }}</span>
      <input type="text"
             :placeholder="placeholderLabel"
             :id="inputId"
             :name="inputId"
             :value="inputValue"
             @input="updateInput($event)"
      />
    </label>
  </div>
</template>

<script setup>

  //
  // Propriétés du composant
  //

  import {defineExpose} from "vue";

  const props = defineProps({
    inputId: {
      type: String,
      required: false,
      default: "input-nombre"
    },
    inputValue: {
      type: String,
      required: false,
      default: ""
    },
    hasLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    textLabel: {
      type: String,
      required: false,
      default: "Label"
    },
    placeholderLabel: {
      type: String,
      required: false,
      default: "valeur..."
    }
  });


  const emit = defineEmits(['change']);

  const allowedChars = [0,1,2,3,4,5,6,7,8,9];

  const updateInput = function($event) {
    const inputText = $event.target;
    const inputValue = inputText.value;
    let i, charValue, filteredValue = "";
    for(i=0; i< inputValue.length; i++) {
      charValue = parseInt(inputValue.charAt(i));
      if ( ! isNaN(charValue)  && allowedChars.indexOf(charValue) !== -1) {
        filteredValue += charValue;
      }
    }
    inputText.value = filteredValue;

    emit( 'change', inputText.value);
  }

  //
  // Méthodes publiques
  //

  defineExpose({
    reset
  })

  function reset() {
    document.getElementById(props.inputId).value = "";
  }

</script>

<style scoped lang="scss">

.input-nombre {
  display: inline-block;
  width: 120px;

  & > * {
    display: inline-block;
    width: 100%;
    margin: 0;

    & > * {
      width: 100%;
      margin: 0;

      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
    }

    input[type="text"] {
      border: 1px solid #666666;
      border-radius: 4px;
      padding: 4px 8px;
      box-sizing: border-box;
    }

    ::-webkit-input-placeholder {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
      opacity: 0.3;
    }

    :-moz-placeholder {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
      opacity: 0.3;
    }

    ::-moz-placeholder {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
      opacity: 0.3;
    }

    :-ms-input-placeholder {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
      opacity: 0.3;
    }
  }
}

</style>