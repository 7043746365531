<template>

  <div class="appariement-nombre-quantite-v2">

    <div v-if="niveauActivite === 1">

      <!-- Niveau 1 -->

      <div class="blocs quantites niveau-1">
        <div class="draggable">
          <DeUniteDraggable
              :chiffre="chiffreDe1"
              :data-value="chiffreDe1"
              :css-class="formeDes"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <DeUniteDraggable
              :chiffre="chiffreDe2"
              :data-value="chiffreDe2"
              :css-class="formeDes"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <DeUniteDraggable
              :chiffre="chiffreDe3"
              :data-value="chiffreDe3"
              :css-class="formeDes"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <DeUniteDraggable
              :chiffre="chiffreDe4"
              :data-value="chiffreDe4"
              :css-class="formeDes"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <DeUniteDraggable
              :chiffre="chiffreDe5"
              :data-value="chiffreDe5"
              :css-class="formeDes"
              class="with-value"
          />
        </div>
      </div>

      <div class="nombres">
        <DropZoneUnites
            data-ordre="1"
            :chiffre="chiffre1"
            :data-correct="chiffre1"
            :validation-css-class="validation1CssClass"
            :has-validation="withValidation"
        />
        <DropZoneUnites
            data-ordre="2"
            :chiffre="chiffre2"
            :data-correct="chiffre2"
            :validation-css-class="validation2CssClass"
            :has-validation="withValidation"
        />
        <DropZoneUnites
            data-ordre="3"
            :chiffre="chiffre3"
            :data-correct="chiffre3"
            :validation-css-class="validation3CssClass"
            :has-validation="withValidation"
        />
        <DropZoneUnites
            data-ordre="4"
            :chiffre="chiffre4"
            :data-correct="chiffre4"
            :validation-css-class="validation4CssClass"
            :has-validation="withValidation"
        />
        <DropZoneUnites
            data-ordre="5"
            :chiffre="chiffre5"
            :data-correct="chiffre5"
            :validation-css-class="validation5CssClass"
            :has-validation="withValidation"
        />
      </div>

    </div>

    <div v-else-if="niveauActivite === 2 || niveauActivite === 3" >

      <!-- Niveaux 2 et 3 -->

      <div class="blocs quantites">
        <div class="draggable">
          <Card
              :chiffre="chiffreDe1"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe1"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreDe2"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe2"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreDe3"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe3"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreDe4"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe4"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
      </div>

      <div class="nombres">
        <DropZoneCard data-ordre="1" :chiffre="chiffre1" :data-correct="chiffre1" :class="cssClass" :validation-css-class="validation1CssClass" />
        <DropZoneCard data-ordre="2" :chiffre="chiffre2" :data-correct="chiffre2" :class="cssClass" :validation-css-class="validation2CssClass" />
        <DropZoneCard data-ordre="3" :chiffre="chiffre3" :data-correct="chiffre3" :class="cssClass" :validation-css-class="validation3CssClass" />
        <DropZoneCard data-ordre="4" :chiffre="chiffre4" :data-correct="chiffre4" :class="cssClass" :validation-css-class="validation4CssClass" />
      </div>

    </div>

    <div v-else-if="niveauActivite === 4" >

      <!-- Niveaux 4 -->

      <div class="blocs quantites">
        <div class="draggable">
          <Card
              :chiffre="chiffreDe1"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe1"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreDe2"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe2"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreDe3"
              :code="code"
              :decomposition-mininum="decompositionMinimum"
              :data-value="chiffreDe3"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :withSoundDecomposed="true"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
      </div>

      <div class="nombres">
        <DropZoneCard data-ordre="1" :chiffre="chiffre1" :data-correct="chiffre1" class="avec-centaines" :validation-css-class="validation1CssClass" />
        <DropZoneCard data-ordre="2" :chiffre="chiffre2" :data-correct="chiffre2" class="avec-centaines" :validation-css-class="validation2CssClass" />
        <DropZoneCard data-ordre="3" :chiffre="chiffre3" :data-correct="chiffre3" class="avec-centaines" :validation-css-class="validation3CssClass" />
      </div>

    </div>

    <div v-else >
        <!-- Niveaux 5,6,7 -->
        <!-- ALIGNER VERTICAL -->
      <table>
        <tr>
          <td class="td-quantites">
            <div class="blocs quantites vertical">
              <div class="draggable">
                <Card
                    :chiffre="chiffreDe1"
                    :code="code"
                    :decomposition-mininum="decompositionMinimum"
                    :data-value="chiffreDe1"
                    :css-class="formeDes"
                    :has-separator="separateurMilliers"
                    :withSoundDecomposed="true"
                    :with-sound="withSound"
                    class="with-value"
                />
              </div>
              <div class="draggable">
                <Card
                    :chiffre="chiffreDe2"
                    :code="code"
                    :decomposition-mininum="decompositionMinimum"
                    :data-value="chiffreDe2"
                    :css-class="formeDes"
                    :has-separator="separateurMilliers"
                    :withSoundDecomposed="true"
                    :with-sound="withSound"
                    class="with-value"
                />
              </div>
              <div class="draggable">
                <Card
                    :chiffre="chiffreDe3"
                    :code="code"
                    :decomposition-mininum="decompositionMinimum"
                    :data-value="chiffreDe3"
                    :css-class="formeDes"
                    :has-separator="separateurMilliers"
                    :withSoundDecomposed="true"
                    :with-sound="withSound"
                    class="with-value"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="nombres vertical">
              <DropZoneCard data-ordre="1" :chiffre="chiffre1" :data-correct="chiffre1" :class="cssClass" :validation-css-class="validation1CssClass" />
              <DropZoneCard data-ordre="2" :chiffre="chiffre2" :data-correct="chiffre2" :class="cssClass" :validation-css-class="validation2CssClass" />
              <DropZoneCard data-ordre="3" :chiffre="chiffre3" :data-correct="chiffre3" :class="cssClass" :validation-css-class="validation3CssClass" />
            </div>
          </td>
        </tr>
      </table>
    </div>

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite.value" :successMax="successMax" @readConsigne="playSound_Consigne"/>

  </div>

</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router"

  import $ from 'jquery'
  import _ from 'lodash'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    playSound,
    randomIntFromInterval,
    stopAllAnimations,
    shuffleArray, getSoundPath_Consigne, getSoundPath_Unite, getSound_Felicitations
  } from "../../../js/utils";

  import DropZoneUnites from "../Zones/DropZoneUnites";
  import DeUniteDraggable from "../Des/DeUnitesDraggable/DeUniteDraggable";
  import Card from "../Card";
  import Ada from "../Ada";
  import DropZoneCard from "../Zones/DropZoneCard";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber;
  let currentMaximumNumber;
  let numbersCount;
  let previousNumbers;

  const overlapThreshold = "50%";
  let isDragging = false;
  let dropZones;
  let draggables;
  let selectedDraggable;
  let correctDropZones;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  let chiffre1 = ref(0);
  let chiffre2 = ref(0);
  let chiffre3 = ref(0);
  let chiffre4 = ref(0);
  let chiffre5 = ref(0);

  let chiffreDe1 = ref(0);
  let chiffreDe2 = ref(0);
  let chiffreDe3 = ref(0);
  let chiffreDe4 = ref(0);
  let chiffreDe5 = ref(0);

  // Code :
  const code = ref(null);

  // Décomposition minimum
  const decompositionMinimum = ref(null);

  const validation1CssClass = ref();
  const validation2CssClass = ref();
  const validation3CssClass = ref();
  const validation4CssClass = ref();
  const validation5CssClass = ref();

  const cssClass = computed(() => {
    switch ( niveauActivite.value ) {
      case 5 :
        return 'avec-milliers'
      case 6 :
        return 'avec-dizaines-de-milliers'
      case 7 :
        return 'avec-centaines-de-milliers'
    }
    return '';
  });


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle Hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite();
    }
  }


  //
  // Niveaux
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 10;
        successMax.value = 3;
        code.value = "simple";
        numbersCount = 5;
        break;

      case 2:
        currentMinimumNumber = 10;
        currentMaximumNumber = 50;
        successMax.value = 3;
        code.value = "simple";
        decompositionMinimum.value = 2;
        numbersCount = 4;
      break;

      case 3:
        currentMinimumNumber = 50;
        currentMaximumNumber = 99;
        successMax.value = 3;
        code.value = "simple";
        decompositionMinimum.value = 2;
        numbersCount = 4;
        break;

      case 4:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        successMax.value = 3;
        code.value = "complex";
        decompositionMinimum.value = 3;
        numbersCount = 3;
        break;

      case 5:
        currentMinimumNumber = 1000;
        currentMaximumNumber = 9999;
        successMax.value = 3;
        code.value = "complex";
        decompositionMinimum.value = 4;
        numbersCount = 3;
        break;

      case 6:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        successMax.value = 3;
        code.value = "complex";
        decompositionMinimum.value = 5;
        numbersCount = 3;
        break;

      case 7:
        currentMinimumNumber = 100000;
        currentMaximumNumber = 999999;
        successMax.value = 3;
        code.value = "complex";
        decompositionMinimum.value = 6;
        numbersCount = 3;
        break;
    }

    // Ada
    success = 0;

    // Valeurs tirées au sort
    previousNumbers = [];


    // console.log('niveau', props.niveau, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 50) {
      return 2;
    } else if (maxValue <= 100) {
      return 3;
    } else if (maxValue <= 1000) {
      return 4;
    } else if (maxValue <= 10000) {
      return 5;
    } else if (maxValue <= 100000) {
      return 6;
    } else {
      return 7;
    }
  }

  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres ) {

    // Ada
    updateAda("normal");

    // Suppression d'une précédente initialisation
    gsap.set($('.draggable'), { x:0, y:0 } );
    removeDraggedStyle();

    $('.zone').removeClass("hilite").removeClass("filled");

    isDragging = false;
    selectedDraggable = null;

    validation1CssClass.value = "";
    validation2CssClass.value = "";
    validation3CssClass.value = "";
    validation4CssClass.value = "";
    validation5CssClass.value = "";

    // Nombre de zones correctes (cf updateDropZoneCssClass et checkDropZones)
    correctDropZones = [];


    // Initialisation de l'activité :

    let firstNumber;

    if ((chiffres === null) || (chiffres === undefined))
    {
      // Tirage au sort des chiffres
      chiffres = [];


      // Cas particuliers
      if (Math.random() < 0.4) {

        switch (props.niveau) {
          case 2:
            // Il faut insister sur la suite 97 98 99 100 (qui doit revenir plus souvent)
            // firstNumber = 97;
            break;

          case 3:
            // Il faut insister sur 98 99 100 101 (qui doit revenir plus souvent) mais aussi sur 67 68 69 70 71
            // et aussi sur les séries entre 70 et 100 (plus compliquées)
            break;

          case 4:
            // Il faut insister sur la suite 98 99 100  101 (qui doit revenir plus souvent)
            // mais d'après la même logique que niveau 2 sur les passages de centaines (199 200, 299 300, etc)
            // et sur les séries ou apparaissent les nombres 70, 71 etc, 80, 81 etc ou 90, 91 etc
            // par exemple 379 380 381 382 doit apparaitre plus souvent en faisant varier les centaines
            break;

          case 5:
            // 999 - 1000 - 1001 doit être plus récurent mais aussi 9 998 - 9 999 - 10 000 - 10 001 -
            // et toutes les séries ou apparaissent 70, 80, 90 en faisant varier les milliers
            break;

          case 6:
            //  9 999 - 10 000 - 10 001 doit être plus récurent mais aussi 99 998 - 99 999 - 100 000 - 100 001 -
            //  et toutes les séries où apparaissent 70, 80, 90 en faisant varier les dizaines de milliers
            break;

          case 7:
            // 99 999 - 100 000 - 100 001 doit être récurent ainsi que 999 998 - 999 999 - 1 000 000-
            // et toutes les séries où apparaissent 70, 80, 90 en faisant varier les centaines de milliers.
        }
      }

      // Cas général :
      // Tirage au sort des chiffres : le premier chiffre peut être entre 0 et 5 ( le dernier ira donc de 4 à 9)
      firstNumber = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber - 4);

      /*
      if (firstNumber === undefined)
      {
        do {
        } while ( previousNumbers.indexOf(firstNumber) !== -1)
      }
       */

      previousNumbers.push(firstNumber);

      let i;
      for(i=0; i< numbersCount; i++) {
        chiffres.push(firstNumber + i);
      }

    }
    else
    {
      // Saisie manuelle

    }


    // console.log(currentMinimumNumber, currentMaximumNumber, numbersCount, chiffres)

    // Nombres
    chiffre1.value = chiffres[0];
    chiffre2.value = chiffres[1];
    chiffre3.value = chiffres[2];
    chiffre4.value = chiffres[3];
    chiffre5.value = chiffres[4];

    // Affichage des dés, dans le désordre
    shuffleArray(chiffres);

    chiffreDe1.value = chiffres[0];
    chiffreDe2.value = chiffres[1];
    chiffreDe3.value = chiffres[2];
    chiffreDe4.value = chiffres[3];
    chiffreDe5.value = chiffres[4];

    // Consigne
    playSound_Consigne();

    // Dragg and Drop
    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    dropZones = $(".nombre-parent");
    dropZones.data('value', null)

    const $target = $(".draggable", '.quantites').not('.draggable-initialized');
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create($target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        // On retire l'effet à tous les dés
        removeDraggedStyle();

        // On ajoute l'effet au dé déplacé
        var draggableElement = $(pointEvent.target).closest(".draggable");
        draggableElement.find('.with-dragged-style').addClass("dragged"); // Effet sur la carte ou le dé


        if (props.withSound) {
          // Niveau 1 : 1 unité, 2 unités, ...
          // Niveaux suivants : 1, 2, ...
          var nombre = draggableElement.find('.with-value').attr('data-value');
          var soundPath = props.niveau === 1 ? getSoundPath_Unite(nombre) : getSoundPath_Chiffre(nombre);
          playSound(soundPath);
        }

        selectedDraggable = draggableElement;

        var draggableElementOffset = draggableElement.offset();
        if ((draggableElement.data('x') === undefined) || (draggableElement.data('x') === -1)) {
          draggableElement.data('x', draggableElementOffset.left);
          draggableElement.data('y', draggableElementOffset.top);
        }
      },
      onClick: function() {
        // On retire l'effet à tous les dés
        removeDraggedStyle();
      },
      onDragStart: function(pointEvent) {

        const draggableElement = $(pointEvent.target).closest('.draggable');
        let nombre_drag =  parseInt(draggableElement.find('.with-value').attr('data-value'));
        let nombre_ordre, nombre;

        isDragging = true;
        selectedDraggable = null;

        dropZones.css("pointer-events", "none");
        dropZones.each(function(no, item)
        {
          var dropZone = $(item);

          nombre = dropZone.data('value');
          nombre_ordre = parseInt( dropZone.attr('data-ordre'));

          // console.log(nombre_ordre, dropZone.data('value'), "==", nombre_drag);

          if (nombre_drag === nombre )
          {
            dropZone.data('value', null);
            dropZone.removeClass("hilite").removeClass("filled");
            updateDropZoneCssClass(nombre_ordre, "");
          }
        });
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        stopAllAnimations();

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        // On retire l'effet :
        removeDraggedStyle();

        const draggableElement = $(pointEvent.target).closest('.draggable');

        let i = dropZones.length;
        let hiliteFound = false;
        let dropZone;
        let nombre_ordre = 0, nombre_drag, nombre_drop;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if ( dropZone.data('value') === null )
          {
            if (this.hitTest(dropZone, overlapThreshold))
            {
              nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value'));
              nombre_drop = parseInt( dropZone.attr('data-correct'));
              nombre_ordre = parseInt( dropZone.attr('data-ordre'));

              // console.log('drag end', nombre_ordre, nombre_drag, nombre_drop, draggableElement);

              var offsetX = draggableElement.data('x');
              var offsetY = draggableElement.data('y');
              var targetOffset = dropZone.offset();

              gsap.to(this.target, {
                duration: 0.1,
                x: targetOffset.left - offsetX,
                y: targetOffset.top - offsetY
              });

              let soundPath = getSoundPath_Chiffre(nombre_drop);

              if (nombre_drag === nombre_drop)
              {
                // Bonne réponse
                hiliteFound = true;
                updateDropZoneCssClass(nombre_ordre, "is-correct");
                dropZone.data('value', nombre_drag);

                //
                // On vérifie si tout est rempli
                //

                const completeSuccess = checkDropZones();
                // console.log('completeSuccess', completeSuccess);
                if (completeSuccess)
                {
                  // On empêche l'interaction de l'activité en cours
                  updateAda("lock");

                  // Son du chiffre, suivi des félicitations
                  playSound_Felicitations(soundPath);
                }
                else
                {
                  if (props.withSound) {
                    // Son du chiffre
                    playSound(soundPath);
                  }
                }
              }
              else
              {
                // Mauvaise réponse
                updateDropZoneCssClass(nombre_ordre, "is-wrong");

                if (props.withSound) {
                  // Son du chiffre
                  playSound(soundPath);
                }

                // ADA
                updateAda("error", 3000);
              }
            }
          }
        }

        if (! hiliteFound) {

          // Placé hors de zones de drop ou mauvaise réponse : retour à la position initiale

          gsap.set(this.target, { x:0, y:0, delay:0.5, onComplete: function() {
              if (nombre_ordre > 0) {
                updateDropZoneCssClass(nombre_ordre, "");
              }
            }
          });

          isDragging = false;
        }
      },
      onDrag: function() {

        var i = dropZones.length, dropZone;
        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (isNaN( dropZone.data('value')))
          {
            if (this.hitTest(dropZone, overlapThreshold)) {
              dropZone.addClass("hilite");
            } else {
              dropZone.removeClass("hilite");
            }
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    dropZones.on('click', function() {

      if (! isDragging && selectedDraggable )
      {
        // On retire l'effet déplacé
        removeDraggedStyle();

        var draggableElement = selectedDraggable;
        var offsetX = draggableElement.data('x');
        var offsetY = draggableElement.data('y');

        var dropZone = $(this);
        var targetOffset = dropZone.offset();

        gsap.to(draggableElement, 0.1,{

          x:targetOffset.left - offsetX,
          y:targetOffset.top - offsetY,
          onComplete: function() {

            removeDraggedStyle();

            var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value'));
            var nombre_drop = parseInt( dropZone.attr('data-correct'));

            dropZone.removeClass("hilite").addClass("filled");
            dropZone.data('value', nombre_drag);

            // console.log(nombre_drag, nombre_drop, nombre_drag === nombre_drop);

            if (props.withSound) {
              var soundPath = getSoundPath_Chiffre(nombre_drop);
              playSound(soundPath);
            }

            if (nombre_drag !== nombre_drop)
            {
              // Mauvaise réponse : fond rouge et retour à la position initiale
              draggableElement.addClass('incorrect');

              gsap.to(draggableElement, 0.1,{
                delay:0.25,
                x:0,
                y:0,
                onStart: function() {
                  dropZone.data('value', NaN);
                  dropZone.removeClass("hilite").removeClass("filled");
                },
                onEnded: function () {
                  removeDraggedStyle();
                }
              });
            }
            else
            {
              // Bonne réponse : fond vert
              draggableElement.addClass('correct');
              removeDraggedStyle();
            }
          }
        });

      }
    });
  }

  function removeDragAndDrop() {
    $(".draggable", '.quantites').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  function updateDropZoneCssClass(no, cssClass) {
    switch (no) {
      case 1 :
        validation1CssClass.value = cssClass;
        break;
      case 2 :
        validation2CssClass.value = cssClass;
        break;
      case 3 :
        validation3CssClass.value = cssClass;
        break;
      case 4 :
        validation4CssClass.value = cssClass;
        break;
      case 5 :
        validation5CssClass.value = cssClass;
        break;
    }

    // On stocke les zones correctes :

    const position = correctDropZones.indexOf(no);

    if (cssClass === "is-correct") {
      if (position === -1) {
        correctDropZones.push(no);
      }
    } else if (position !== -1) {
      correctDropZones.splice(position, 1);
    }
  }

  function checkDropZones() {
    // Est ce que toutes les quantités ont été trouvées ?
    // console.log('Ada :', correctDropZones, "win?", correctDropZones.length, numbersCount);
    return correctDropZones.length === numbersCount;
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound)
    {
      if (props.niveau === 1)
      {
        const soundPath1 = getSoundPath_Consigne("consigne-jeu-des-quantites");
        playSound( soundPath1, () => {updateAda("help")} );
      }
      else if (props.niveau === 2 || props.niveau === 3)
      {
        const soundPath1 = getSoundPath_Consigne("consigne-jeu-des-quantites-niv-2");
        const soundPath2 = getSoundPath_Consigne("pour-entendre-le-nombre-en-entier-clique", true, "new");
        playSound([ soundPath1, soundPath2 ], () => {updateAda("help")});
      }
      else
      {
        const soundPath1 = getSoundPath_Consigne("consigne-jeu-des-quantites-niv-2");
        const soundPath2 = getSoundPath_Consigne("pour-entendre-le-nombre-en-entier-clique-chiffre-des-centaines", true, "new");
        playSound([ soundPath1, soundPath2 ], () => {updateAda("help")});
      }
    }
  }

  function playSound_Felicitations(soundPath) {
    if (props.withSound)
    {
      // Son du chiffre, suivi d'un son de félicitations :
      const soundPathFelicitations = getSound_Felicitations();

      let soundPaths;
      if (Array.isArray(soundPath)) {
        soundPaths = soundPath.concat(soundPathFelicitations);
      } else {
        soundPaths = [soundPath, soundPathFelicitations];
      }

      playSound(soundPaths, addSuccessAndReplay);

    } else {

      addSuccessAndReplay();
    }
  }

</script>

<style scoped lang="scss">

  .appariement-nombre-quantite-v2 {

    @include on-small-height-desktop {
      padding-top: 20px;
    }

    table {
      margin: 0 auto;

      tr {
        margin: 0;
        padding: 0;

        td {
          vertical-align: top;

          &.td-quantites {
            padding-top: 20px;
          }
        }
      }
    }

    .quantites {
      z-index:2;
      position: relative;
      display: flex;
      justify-content: center;

      .draggable > div {
        margin: 10px 12px 4px;

        @include on-mobile-landscape {
          margin: 10px 5px 4px;
        }

        @include on-print {
          margin: 10px 0 4px;
        }
      }

      &.niveau-1 .draggable > div {
        margin: 8px 6px 4px;
      }

      &.vertical{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 64px;
      }
    }

    .nombres {
      z-index:1;
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 16px;

      @include on-small-desktop {
        gap: 20px 0;
      }

      @include on-mobile-landscape {
        margin-top: 5px;
      }

      &.vertical{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

  }

</style>
