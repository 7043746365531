<template>
  <div class="tab-content">
    <ul class="flex tab-content-nombres">
      <li
          v-for="index in parseInt(count)"
          v-bind:key="'item'+ index"
      >
        <InputNombre
            :input-id="itemName(index)"
            :text-label="inputLabel(index)"
            placeholder-label="valeur..."
          />
      </li>
    </ul>
  </div>
</template>

<script setup>

//
// Propriétés du composant
//

import InputNombre from "./InputNombre";

defineProps({
  count: {
    type: Number,
    required: false,
    default: 4
  }
});

//
// Fonctions spécifiques
//

const itemName = function(index) {
  return 'nombre' + index;
}

const inputLabel = function(index) {
  return 'Nombre ' + index;
}

</script>

<style scoped lang="scss">

  .tab-content {

    .tab-content-nombres {
      margin: 0;
      padding: 0;
      list-style-type: none;

      flex-wrap: wrap;
      gap: 20px;

      li {
        flex: calc( 25% - 15px ) 0 0;
        width: calc( 25% - 15px );
        margin: 0;

        & > * {
          display: inline-block;
          width: 100%;
          margin: 0;

          & > * {
            width: 100%;
            margin: 0;

            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
          }

          input[type="text"] {
            border: 1px solid #666666;
            border-radius: 4px;
            padding: 4px 8px;
            box-sizing: border-box;
          }

          ::-webkit-input-placeholder {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            opacity: 0.3;
          }

          :-moz-placeholder {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            opacity: 0.3;
          }

          ::-moz-placeholder {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            opacity: 0.3;
          }

          :-ms-input-placeholder {
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            opacity: 0.3;
          }
        }
      }
    }
  }

</style>