<template>
  <div class="aide-quantites-milliers-unites">
    <AideColonneQuantitesMilliersUnitesUn
        v-if="chiffre >= 100000"
        column-type="centaines-milliers"
        :chiffre="centaines_milliers"
        :css-class="largeur_colonne"
        @change="emitChangeEvent"
    />
    <AideColonneQuantitesMilliersUnitesUn
        v-if="chiffre >= 10000"
        column-type="dizaines-milliers"
        :chiffre="dizaines_milliers"
        :css-class="largeur_colonne"
        @change="emitChangeEvent"
    />
    <AideColonneQuantitesMilliersUnitesUn
        v-if="chiffre >= 1000"
        column-type="milliers"
        :chiffre="milliers"
        :css-class="largeur_colonne"
        @change="emitChangeEvent"
    />
    <AideColonneQuantitesMilliersUnitesUn
        v-if="chiffre >= 100"
        column-type="centaines"
        :chiffre="centaines"
        :css-class="largeur_colonne"
        @change="emitChangeEvent"
    />
    <AideColonneQuantitesMilliersUnitesUn
        v-if="chiffre >= 10"
        :column-type="typeDizaine"
        :chiffre="dizaines"
        :css-class="largeur_colonne"
        @change="emitChangeEvent"
    />
    <AideColonneQuantitesMilliersUnitesUn
        :column-type="typeUnites"
        :chiffre="unites"
        :css-class="largeur_colonne"
        @change="emitChangeEvent"
    />
  </div>
</template>

<script setup>

import {computed, defineExpose, onMounted, ref, watch} from "vue";
  import {getDizainesUnites} from "../../../js/utils";
  import AideColonneQuantitesMilliersUnitesUn from "./AideColonneQuantitesMilliersUnitesUn";

  const props = defineProps({
    chiffre: {
      type: Number,
      required: false,
      default: 100
    },
    dizainesSimples: {
      type: Boolean,
      required: false,
      default: false
    },
  });

  const emit = defineEmits(['change']);


  // Valeurs issus de chiffre
  let centaines_milliers = ref(0),
      dizaines_milliers = ref(0),
      milliers = ref(0),
      centaines = ref(0),
      dizaines = ref(0),
      unites = ref(0);

  let columnsCorrectState;
  let columnsValueState;


  //
  // Lifecycle hooks
  //

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });


  const update = function() {

    console.log("update", props.chiffre);

    const chiffre = parseInt(props.chiffre);

    // Scores : true/false pour chaque colonne ( par défaut : false )
    // NB : les colonnes inutiles seront undefined

    columnsCorrectState = [];

    if (chiffre >= 100000) {
      columnsCorrectState['centaines-milliers'] = false;
    }
    if (chiffre >= 10000) {
      columnsCorrectState['dizaines-milliers'] = false;
    }
    if (chiffre >= 1000) {
      columnsCorrectState['milliers'] = false;
    }
    if (chiffre >= 100) {
      columnsCorrectState['centaines'] = false;
    }

    // Dizaines ou simples dizaines
    columnsCorrectState[typeDizaine.value ] = false;

    // Unités
    columnsCorrectState['unites'] = false;

    // Décomposition
    const dizainesUnites = getDizainesUnites(chiffre);
    centaines_milliers.value = dizainesUnites.centaines_milliers;
    dizaines_milliers.value = dizainesUnites.dizaines_milliers;
    milliers.value = dizainesUnites.milliers;
    centaines.value = dizainesUnites.centaines;
    dizaines.value = dizainesUnites.dizaines;
    unites.value = dizainesUnites.unites;
  };


  //
  // Reactive values
  //

  const typeUnites = ref('unites');

  const typeDizaine = computed(() => {
    return props.dizainesSimples ? "dizaines-simples" : "dizaines";
  });

  const largeur_colonne = computed(() => {
    return ! centaines_milliers.value && ! dizaines_milliers.value && ! milliers.value ? "large" : "";
  });


  //
  // Evènements
  //

  const emitChangeEvent = function(columnType, columnValue, isCorrect, eventType) {

    // On met à jour le tableau des valeurs des colonnes :
    if (columnsValueState === undefined) {
      columnsValueState = []; // au cas où...
    }
    columnsValueState[columnType] = columnValue

    // On met à jour le tableau correct/incorrect des colonnes :
    if (columnsCorrectState === undefined) {
      columnsCorrectState = []; // au cas où...
    }
    columnsCorrectState[columnType] = isCorrect

    // On vérifie si toutes les colonnes sont correctes
    let allIsCorrect = true;
    for (let columnType in columnsCorrectState) {
      if (columnsCorrectState[columnType] === false) {
        allIsCorrect = false;
        break;
      }
    }

    // On vérifie si toutes les colonnes sont complétées
    let allIsComplete = true;
    for (let columnType in columnsValueState) {
      if (columnsValueState[columnType] === undefined) {
        allIsComplete = false;
        break;
      }
    }

    let completeValue = null;

    if (allIsComplete) {
      let completeValues = [];
      completeValues.push(
          columnsValueState['unites']
      );
      if (columnsCorrectState[typeDizaine.value ] !== undefined) {
        completeValues.push(
            columnsValueState[typeDizaine.value]
        );
      }
      if (columnsCorrectState['centaines'] !== undefined) {
        completeValues.push(
            columnsValueState['centaines']
        );
      }
      if (columnsCorrectState['milliers'] !== undefined) {
        completeValues.push(
            columnsValueState['milliers']
        );
      }
      if (columnsCorrectState['dizaines-milliers'] !== undefined) {
        completeValues.push(
            columnsValueState['dizaines-milliers']
        );
      }
      if (columnsCorrectState['centaines-milliers'] !== undefined) {
        completeValues.push(
            columnsValueState['centaines-milliers']
        );
      }

      // Chiffre obtenu en prenant les nombres proposés dans chaque colonne (centaines de milliers à unités)
      completeValue = completeValues.reverse().join('');
    }

    emit('change', columnType, columnValue, isCorrect, allIsCorrect, allIsComplete, completeValue, eventType);
  }


  //
  // Méthodes publiques
  //

  defineExpose({
    reset
  })

  function reset() {
    columnsValueState = []
    columnsCorrectState = []
  }


</script>

<style scoped lang="scss">

.aide-quantites-milliers-unites {
  display: flex;
  gap:10px;

  @include on-small-height-desktop {
    transform: scale(0.8);
  }

  @include on-print {
    gap:5px;
  }
}

</style>
