<template>
  <div class="content content-v2">
    <HeaderParcours
        :title="titreParcours"
    />

    <div class="parcours-sommaire">

      <div class="saisie-nom-parcours">
        <h4>Entre ton prénom</h4>
        <input type="text" v-model="username" />
      </div>

      <a href="#"
         class="commencer-parcours-btn"
         @click="startPlayList">
      </a>

      <h4 style="margin: 60px 0 5px">Ce parcours est composé des activités suivantes :</h4>
      <ul class="activites-sommaire">
        <li
            v-for="(item, i) in activitesParcours"
            :key="i"
        >
          <div :class="getIdActivite(item.path)">
            <BoutonNiveauActivite
                :titre="getTitreActivite(item.path)"
                :niveau="getNiveauActivite(item.path)"
                class="disabled"
            />
          </div>
        </li>
      </ul>
    </div>

    <ResponsiveWarning />
  </div>

</template>

<script setup>

  import {onMounted, ref, watch} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {useStore} from "vuex";

  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning.vue";
  import HeaderParcours from "../../../components/v2/Headers/HeaderParcours.vue";
  import {stopAllSounds} from "../../../js/utils";
  import BoutonNiveauActivite from "../../../components/v2/Sommaires/BoutonNiveauActivite.vue";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const username = ref('');

  // Liste des activités (cf activites_v2.js )
  const tous_les_parcours  = ref(store.state.parcours);

  const getParcours = function(id) {
    const n = tous_les_parcours.value.length;
    let i, parcours;
    for(i=0;i<n;i++) {
      parcours = tous_les_parcours.value[i];
      if (parcours.id === id) {
        return parcours;
      }
    }
  }

  const getIdActivite = function(pathActivite) {
    if (!pathActivite) return '';
    // Expression régulière
    let params;
    if (pathActivite.indexOf('/niveau/') === -1) {
      // ===> [ "/activite/dictee-vocale", "dictee-vocale" ]
      params = pathActivite.match(/.*\/(.*)$/)
    } else {
      // ===> [ "/activite/dictee-vocale/niveau/1", "dictee-vocale", "niveau", "1" ]
      params = pathActivite.match(/.*\/(.*)\/(.*)\/(.*)$/)
    }
    return params.length ? params[1] : "";
  }

  const getTitreActivite = function(pathActivite) {
    if (!pathActivite) return '';
    const route = router.resolve({ path: pathActivite});
    return route ? route.name.split('custom').join('') : pathActivite;
  }

  const getNiveauActivite = function(pathActivite) {
    if (!pathActivite) return '';
    const route = router.resolve({ path: pathActivite});
    return route ? parseInt(route.params.niveau) : "";
  }

  const titreParcours = ref('Titre du parcours');
  const activitesParcours = ref(null);


  //
  // Lifecycle Hooks
  //

  watch( route.params.id, () => {
    init();
  });

  onMounted(() => {
    init();
  });


  //
  // Init
  //

  const init = function() {

    stopAllSounds();

    const parcours = getParcours(route.params.id);
    titreParcours.value = parcours.titre;

    activitesParcours.value = parcours.routes.concat();

    console.log("parcours.routes",  parcours.routes);

    const n = parcours.routes.length;
    let i, pathParcours, paramsParcours, urlParcours;

    for(i=0;i<n;i++)
    {
      pathParcours = parcours.routes[i].path;
      urlParcours = new URL('http://localhost:' + pathParcours);
      paramsParcours = urlParcours.searchParams;

      activitesParcours.value[i] = {
        path : urlParcours.pathname,
        query : { playlist: i, parcours: parcours.id }
      };

      for (const key of paramsParcours.keys()) {
        activitesParcours.value[i].query[key] = paramsParcours.get(key);
        console.log(`key`, key);
      }

    }

    // On ajoute la vue du bilan du parcours
    const playlist = activitesParcours.value.concat();
    playlist.push(
        router.resolve({
          name: 'Bilan Parcours',
          params: { id: parcours.id }
        })
    )

    // Mémorisation de la playlist
    store.dispatch("savePlaylist", playlist);
  };

  // Lancement de la première activité
  const startPlayList = function() {
    if (activitesParcours.value && activitesParcours.value.length)
    {
      // On stocke le prénom de l'élève
      store.dispatch("savePlaylistUsername", username.value);

      // On lance la première activité du parcours
      const pathParcours = activitesParcours.value[0];

      console.log(pathParcours)

      router.push(pathParcours);
    }
  };

</script>

<style scoped lang="scss">

  .parcours-sommaire {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .saisie-nom-parcours {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;

    h4 {
      text-align: center;

      @include on-print {
        display: none;
      }
    }

    input[type=text] {
      border-radius: 5px;
      font-size: 22px;
      line-height: 25px;
      padding: 8px;

      @include on-print {
        text-align: center;
        border: none;
        inset: unset;
        outline: none;
      }
    }
  }

  ul.activites-sommaire {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    list-style-type: none;
    margin-bottom: 50px;
    padding: 0;

    li {
      min-width: 300px;
    }

    .niveau-button {
      height: 200px;
    }
  }

  a.commencer-parcours-btn {
    display: block;
    width: 249px;
    height: 78px;
    min-height: 78px;
    background: url(../../../assets/images/svg/boutons_v2/Commencer_accueil_4x.png) center / 249px auto no-repeat;
    margin-top: 30px;
    text-decoration: none;

    @include on-mobile-landscape {
      background-size: contain;
      background-position: left center;
      width: 180px;
      height: 45px;
      min-height: 45px;
    }

    @include on-print {
      display: none;
    }
  }


  /* iPad */

  @media screen and (max-width: 1080px ) {

    .activite-sommaire {
      width: 300px;
      height: 190px;

    }

  }

</style>
