<template>
  <div class="settings-tab-content">

    <div class="settings-tab-row flex flex-gap-20">
      <RadioButton
          ref="radio1"
          input-id="radio1"
          input-name="group"
          input-value="simple"
          text-label="Opération simple"
          :checked="radio1Value"
          @change="onChangeRadio1"
      />
      <RadioButton
          ref="radio2"
          input-id="radio2"
          input-name="group"
          input-value="a-trou"
          text-label="Opération à trou"
          :checked="radio2Value"
          @change="onChangeRadio2"
      />
    </div>

    <div class="settings-tab-row flex flex-gap-10 flex-align-end" :class="radioCssClass">
      <InputNombre
          ref="inputText1"
          :input-value="currentInput1Value"
          text-label="Nombre 1"
          @change="onChangeInput1"
      />
      <div style="margin: 0 10px; font-weight: bold">
        <div></div>
        <div>-</div>
      </div>
      <InputNombre
          ref="inputText2"
          class="nombre2"
          :input-value="currentInput2Value"
          text-label="Nombre 2"
          @change="onChangeInput2"
      />
      <span class="egal">=</span>
      <div class="reponse">
        <label>Réponse</label>
        <div>{{ missingValue }}</div>
      </div>
    </div>

  </div>
</template>

<script setup>

import RadioButton from "../Controls/RadioButton";
import InputNombre from "../Controls/InputNombre";
import {computed, onMounted, ref, watch} from "vue";

//
// Propriétés du composant
//

const props = defineProps({
  tabId: {
    type: String,
    required: false,
    default: ""
  },
  tabValues: {
    type: Object,
    required: false,
    default: null
  },
});

// Référence des éléments du formulaire
const inputText1 = ref(null);
const inputText2 = ref(null);
const radio1 = ref(null);
const radio2 = ref(null);

// Valeurs des différents onglets
const currentTabValues = ref();

// Valeur de l'onglet courant
const currentInput1Value = ref("");
const currentInput2Value = ref("");
const currentRadioButtonValue = ref(false); // true si "à trou"

const radio1Value = computed(() => currentRadioButtonValue.value === false);
const radio2Value = computed(() => currentRadioButtonValue.value === true);
const radioCssClass = computed(() => radio2Value.value === true ? "a-trou" : "simple");

// Valeurs de l'interface de settings s'adaptant aux valeurs saisies
const missingValue = ref("--");

const emit = defineEmits(['change'])


//
// Lifecycle Hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  // Mise à jour lorsqu'on passe d'un onglet à l'autre
  update();
});

function update() {
  // console.log("props.tabValues", JSON.stringify(props.tabValues));
  const valueObj = currentTabValues.value = props.tabValues;

  // Champ texte 1 :
  if ((valueObj !== null) && (valueObj.v1 !== undefined)) {
    currentInput1Value.value = valueObj.v1;
  } else {
    currentInput1Value.value = "";
    inputText1.value.reset();
  }

  // Champ texte 2 :
  if ((valueObj !== null) && (valueObj.v2 !== undefined)) {
    currentInput2Value.value = valueObj.v2;
  } else {
    currentInput2Value.value = "";
    inputText2.value.reset();
  }

  // Radiobutton :
  let checkedValue;
  if ((valueObj !== null) && (valueObj.trou !== undefined)) {
    checkedValue = valueObj.trou;
  } else {
    checkedValue = false; // Par défaut, coché sur "simple"
  }

  currentRadioButtonValue.value = checkedValue;
  radio1.value.setRadioButton(! checkedValue);
  radio2.value.setRadioButton(checkedValue);

  updateMissingValue();
}

function updateMissingValue() {
  const values = currentTabValues.value;
  if (values && ! isNaN(values.v1) && ! isNaN(values.v2)) {
    let mv;
    if (values.trou) {
      mv = parseInt(values.v1) - parseInt(values.v2);
    } else {
      mv = parseInt(values.v1) - parseInt(values.v2);
    }
    missingValue.value = isNaN(mv) ? "--" : mv;
  } else {
    missingValue.value = "--";
  }
}


//
// Events
//

function onChangeInput1(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v1: inputValue, v2: "", trou: false };
  } else {
    currentTabValues.value.v1 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateMissingValue();
}

function onChangeInput2(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v2: inputValue, v1: "", trou: false };
  } else {
    currentTabValues.value.v2 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateMissingValue();
}

function onChangeRadio1() {
  // Addition simple
  onChangeRadio(false);
  currentRadioButtonValue.value = false;
}

function onChangeRadio2() {
  // Addition à trou
  onChangeRadio(true);
  currentRadioButtonValue.value = true;
}

function onChangeRadio(radioButtonValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v1: "", v2: "", trou: radioButtonValue };
  } else {
    currentTabValues.value.trou = radioButtonValue;
  }

  // console.log("change",  radioButtonValue, JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateMissingValue();
}

</script>

<style scoped lang="scss">

.settings-tab-row {

  &.a-trou {
    .nombre2 {
      order: 5;
    }
    .egal {
      order: 4;
    }
    .reponse {
      order: 3;
    }
  }

}

</style>