<template>
  <a class="settings-des-couleur" href="#"></a>
</template>

<script>
</script>

<style scoped lang="scss">

a.settings-des-couleur {
  display: inline-block;
  width: 133px;
  height: 137px;
  text-decoration: none;
  background: url(../../../assets/images/svg/settings/Parametres_de_couleur_off.svg) center / 133px auto no-repeat;

  &.on {
    background-image: url(../../../assets/images/svg/settings/Parametres_de_couleur_on.svg);
  }
}

</style>
