<template>
    <div class="frogger" id="frogger">
      <div id="fps-parent">FPS : <span id="fps">0</span></div>
      <div id="canvas-parent"></div>
      <div id="score">
        <h1 class="titan">{{ score }}</h1>
      </div>
      <div id="score-ada">
        <div id="score-icon"></div>
        <div id="score-text"><h2 class="titan">{{ life_number }}</h2></div>
      </div>
      <div id="score-car">
        <div id="car-icon"></div>
        <div id="car-text"><h2 class="titan">{{ cars_number }}</h2></div>
      </div>
      <div id="debug-render"><canvas id="debug-canvas"></canvas></div>
    </div>
</template>


<script setup>

  import {onMounted, onUnmounted, watch, ref} from "vue";
  import * as PIXI from 'pixi.js'
  import * as Matter from 'matter-js'
  import { gsap } from 'gsap';

  import {
    shuffleArray
  }  from "../../../js/utils";


  //
  // Propriétés du composant
  //
  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    }
  });


  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function() {

    switch(props.niveau) {

      default:
      case 1:
        //vitesse.value = 1;
        break;

      case 2:
        break;

      case 3:
        break;

      case 4:
        break;

      case 5:
        break;

      case 6:
        break;
    }
  };


  //
  // Variables spécifiques à l'activité
  //

  let pixiApp;
  let pixiAppBackground;
  let pixiAppColumn;
  let pixiAppRow;

  // reactive data
  let life_number = ref();
  let score = ref();
  let cars_number = ref();

  //let pixiAppWall;

  //let bodyElement;
  let canvasParent;
  let fpsText;
  let scoresCanvas;
  let carsCanvas;
  let bodies;
  //let scorePairsText;
  //let scoreImpairsText;


  const RENDER_WITH_CANVAS = false; // permet de voir les contours des objets du moteur physique Matter au lieu du rendu Pixi
  const PIXI_DEBUG = true;

  const WIDTH = Math.min(650, window.innerWidth);
  const HEIGHT = Math.min(1200, window.innerHeight - 60);
  const MARGIN_X = PIXI_DEBUG ? 0 : 0;
  const MARGIN_Y = PIXI_DEBUG ? 0 : 0;

  // GRID
  // column
  const nombreColumn = 5;
  const margin = 20;
  const columnW = WIDTH  / nombreColumn;// + marginX;

  // row
  const nombreRow = 6;
  const rowH = Math.floor(HEIGHT / nombreRow);
  const rowCenter = rowH / 2;
  const assetH_max = 128; // taille max assets
  const rowYMarg = (rowH - assetH_max) / 2;
  //const rows

  let fromY = 0;
  


  //ADA 
  let adaInPixi;
  const ADA_WIDTH = 130;
  const ADA_HEIGHT = 84;
  const ADA_X = WIDTH / 2;
  const ADA_Y = HEIGHT - ( rowH - ADA_HEIGHT ) / 2 - margin; //columnW * nombreRow - margin ; 
  const adaImage = "/assets/jeux/frogger/ada.png";
  //const adaCategory = 0x1000;

  //ARROWS
  let arrowL_inPixi;
  let arrowR_inPixi;
  let arrowT_inPixi;
  let isMoved = false;
  let clicked = 0;

  //const arrow_W = 50;
  //const arrow_H = 50;
  const marg_arrow = 40;
  const arrow_Y = ADA_Y;
  const arrowT_Y = ADA_Y - ADA_HEIGHT + 15;
  const arrowL_X = ADA_X - ADA_WIDTH + marg_arrow;
  const arrowR_X = ADA_X + ADA_WIDTH - marg_arrow;
  const arrowT_X = ADA_X;

  console.log("arrowL_X  ", arrowL_X, " arrowR_X ",  arrowR_X, " arrowT_X ", arrowT_X);

  const arrowImage_L = "/assets/jeux/frogger/arrowL.png";
  const arrowImage_R = "/assets/jeux/frogger/arrowR.png";
  const arrowImage_T = "/assets/jeux/frogger/arrowT.png";


  //const arrowImage = "/assets/jeux/frogger/arrow.png"

  // ASSETS
  const roadImage = "/assets/jeux/frogger/road.png";
  const rectOrangeImage = "/assets/jeux/frogger/rectOrange.png";
  const rectRougeImage = "/assets/jeux/frogger/rectRouge.png";

  // CARS
  const carImage = "/assets/jeux/frogger/car.png";

  // ada game
  const adaSmallImage = "/assets/jeux/frogger/ada_small@1px.png";

  // niveau
  let difficulty = 1;
  let collisionLoose = 0;
  let collisionLooseMax = 5;
  let timer;
  let scd = 0;


  //------- Matter :

  const Engine = Matter.Engine;
  const Render = Matter.Render;
  const Runner = Matter.Runner;
  const Composite = Matter.Composite;
  const Events = Matter.Events;
  const World = Matter.World;

  let runner;
  let engine;
  let render;

  const adaCategory  = 0x0001;
  const lineCategory = 0x0010;
  const roadCategory = 0x0100;
  const lifeCategory = 0x1000;


  const adaLabel  = 'adaAsset';
  const lifeLabel = 'lifeAsset';
  const roadLabel = 'roadAsset';
  const lineAssetLabel = 'lineAsset';
 
  //let isKeyDown;

  let matterAda;
  //let matterArrowL;
  //let matterArrowR;
  //let matterArrowT;
  
  let lineblocks = [];
  let roads = [];
  let lifes = [];

  //let arrows = [];

  // Rendu Matter --> Pixi :
  // pour debug
  if (! RENDER_WITH_CANVAS ) {

    // 1. Initialization
    Render.create = function (options) {

      const render = {};
      render.startTime = getCurrentTime();
      render.engine = options.engine;
      render.element = options.element;
      render.app = options.app;
      render.frameNo = 0;

      render.timing = {
        historySize: 60,
        delta: 0,
        deltaHistory: [],
        lastTime: 0,
        lastTimestamp: 0,
        lastElapsed: 0,
        timestampElapsed: 0,
        timestampElapsedHistory: [],
        engineDeltaHistory: [],
        engineElapsedHistory: [],
        elapsedHistory: []
      };

      render.options = {
        width: 800,
        height: 600,
        pixelRatio: 1,
        background: '#14151f',
        wireframeBackground: '#14151f',
        hasBounds: !!options.bounds,
        enabled: true,
        wireframes: true,
        showSleeping: true,
        showDebug: false,
        showStats: false,
        showPerformance: false,
        showBounds: false,
        showVelocity: false,
        showCollisions: true,
        showSeparations: false,
        showAxes: false,
        showPositions: false,
        showAngleIndicator: false,
        showIds: false,
        showVertexNumbers: false,
        showConvexHulls: false,
        showInternalEdges: false,
        showMousePosition: false
      }

      // Pixi
      const app = options.app;
      const element = options.element;

      element.appendChild(app.view);

      // Texture loading in Pixi
      app.loader
        .add('ada', adaImage)
        .add('road', roadImage)
        .add('rectOrange', rectOrangeImage)
        .add('rectRouge', rectRougeImage)
        .add('car', carImage)
        .add('adaSmall', adaSmallImage)
        .add('arrowL', arrowImage_L)
        .add('arrowR', arrowImage_R)
        .add('arrowT', arrowImage_T)
        .load((loader, resources) => {
          app.resources = resources;


          //arrows.forEach(function(matter_assets) {
          //  addAssetToView(app, matter_assets);
          //});
          
          // LAYER 0
          roads.forEach(function(matter_assets) {
            addAssetToView(app, matter_assets);
          });

          // LAYER 1
          lineblocks.forEach(function(lines) {
              //console.log("LINES ", lines);

              lines.forEach(function(matter_assets) {
               //console.log("lines matter_assets", matter_assets);
                addAssetToView(app, matter_assets);
              });
          });
          
          // LAYER 2
          lifes.forEach(function(matter_assets) {
            addAssetToView(app, matter_assets);
          });

          
          // ADA
          var adaDefinition = { lineType : "ada"};
          adaInPixi = addAssetToView(app, adaDefinition, ADA_X, ADA_Y);


          // ARROWS
          var arrowLDefinition = { lineType : "arrowL"};
          arrowL_inPixi = addAssetToView(app, arrowLDefinition, arrowL_X, arrow_Y);
                    
          var arrowRDefinition = { lineType : "arrowR"};
          arrowR_inPixi = addAssetToView(app, arrowRDefinition, arrowR_X, arrow_Y);
          
          var arrowYDefinition = { lineType : "arrowT"};
          arrowT_inPixi = addAssetToView(app, arrowYDefinition, arrowT_X, arrowT_Y);


      });

      app.loader.onComplete.add(() => {
        console.log("lad Complete")
      });

      console.log("APP ", app, "adaInPixi" , adaInPixi, "arrowL_inPixi", arrowL_inPixi, "arrowR_inPixi", arrowR_inPixi, "arrowT_inPixi", arrowT_inPixi);

      return render;

    }

    // 2. Update loop ( => Pixi update)

    Render.world = function(render) {

      const startTime = (new Date()).getTime() - render.startTime,
          engine = render.engine,
          world = engine.world,
          timing = render.timing,
          app = render.app;

      const pixi_bodies = app.stage.children;

      fpsText.textContent = Math.floor(app.ticker.FPS);

      if (pixi_bodies.length) {

        let pixi_sprite;

        world.bodies.forEach(function(matter_body) {

          // On met à jour :
          // - les objects non statiques géré par le modèle physique : route - rec
          // - les objets dont la position est géré par programmation : ada

          if (matter_body.isSleeping ) {
            pixi_sprite = getSpriteById(render.app, matter_body.id);
            if (pixi_sprite) {
              pixi_sprite.visible = false;
            }
          }
          else if ( ! matter_body.isStatic || matter_body.isPseudoStatic ) {
            pixi_sprite = getSpriteById(render.app, matter_body.id);
            if (pixi_sprite) {
              pixi_sprite.x = matter_body.position.x;
              pixi_sprite.y = matter_body.position.y;
            }
          }
        });
      }

      // log the time elapsed computing this update
      timing.lastElapsed = getCurrentTime() - startTime;
    }

  }

  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite() {
    canvasParent = document.getElementById('canvas-parent');

    // score
    scoresCanvas = document.getElementById('score-ada');
    scoresCanvas.style.left = (WIDTH - 150) + "px";

    carsCanvas = document.getElementById('score-car');
    carsCanvas.style.top = (HEIGHT - 64) + "px";

    life_number.value = 3;
    score.value = 0;
    cars_number.value = 0;

    timer = setInterval(scoreCount, 1000);
    //scorePairsText = document.getElementById('score-pairs');
    //scoreImpairsText = document.getElementById('score-impairs');

    fpsText = document.getElementById('fps');

    //---------------------------------------------------
    //------------------------- PIXI -------------------- 
    //---------------------------------------------------
    // main app
    pixiApp = new PIXI.Application({
      width: WIDTH,// + 2 * MARGIN_X,
      height: HEIGHT,// + 2 * MARGIN_Y,
      resolution: 1
    });

    pixiAppBackground = new PIXI.Graphics();
    pixiAppBackground.beginFill(0xFFFFFF);
    pixiAppBackground.drawRect(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT);
    pixiAppBackground.interactive = true;
    pixiApp.stage.addChild(pixiAppBackground);

    // columns
    for (var i = 0; i <= nombreColumn; i++ ) {
      var xposition = columnW * i - 1;// -1 = moitié épaisseur trait

      pixiAppColumn = new PIXI.Graphics();
      pixiAppColumn.beginFill(0xFFAB02);
      pixiAppColumn.alpha = 0.3;
      pixiAppColumn.drawRect(xposition, MARGIN_Y, 2, HEIGHT);
      pixiApp.stage.addChild(pixiAppColumn);
    }

    // ROWS
    if (PIXI_DEBUG === true) {
      for (var j = 0; j <= nombreRow; j++ ) {
        var rowxposition = 0;
        var yposition = rowH * j;

        pixiAppRow = new PIXI.Graphics();
        pixiAppRow.beginFill(0xFFAB02);
        pixiAppRow.alpha = 0.3;
        pixiAppRow.drawRect(rowxposition, yposition, WIDTH, 2);
        pixiApp.stage.addChild(pixiAppRow);
      }
    }

    //---------------------------------------------------
    //-------------- MATTER ENGINE ----------------------
    //---------------------------------------------------
    

    engine = Engine.create();
    engine.world.gravity.y = 0;

    // ADA
    matterAda = makeAdaElement('ada', ADA_X, ADA_Y, ADA_WIDTH, ADA_HEIGHT);
    Composite.add(engine.world, matterAda);


    // ROAD
    roads = makeRoad();
    Composite.add(engine.world, roads);
    

    // LINES
    lineblocks = makeLines();
    for (var lines = 0; lines < lineblocks.length; lines++) {
      Composite.add(engine.world, lineblocks[lines]);
    }

    // LIFES
    lifes = makeLifes();
    Composite.add(engine.world, lifes);


    if ( RENDER_WITH_CANVAS ) {

      /* Canvas render (Matter default rendering) */

      const canvas = document.getElementById('debug-canvas');
      canvas.width = WIDTH + 2 * MARGIN_X;
      canvas.height = HEIGHT + 2 * MARGIN_Y;

      render = Render.create({
        element: canvasParent,
        canvas: canvas,
        app: pixiApp,
        engine: engine,
        options: {
          width: WIDTH + 2 * MARGIN_X,
          height: HEIGHT + 2 * MARGIN_Y
        }
      });

      pixiApp.loader
        .add('ada', adaImage)
        .add('car', carImage)
        .add('adaSmall', adaSmallImage)
        .add('rectOrange', rectOrangeImage)
        .add('rectRouge', rectRougeImage)
        .add('arrowL', arrowImage_L)
        .add('arrowR', arrowImage_R)
        .add('arrowT', arrowImage_T)
        .load((loader, resources) => {
          pixiApp.resources = resources;

          
          //arrows.forEach(function(matter_assets) {
          //  addAssetToView(app, matter_assets);
          //});
          
          
          // LAYER 0
          roads.forEach(function(matter_assets) {
            addAssetToView(pixiApp, matter_assets);
          });

          // LAYER 1
          lineblocks.forEach(function(lines) {
              lines.forEach(function(matter_assets) {
                //console.log("lines matter_assets", matter_assets);
                addAssetToView(pixiApp, matter_assets);
              });
          });

          // LAYER 2
          lifes.forEach(function(matter_assets) {
            addAssetToView(pixiApp, matter_assets);
          });

          
          // ADA
          var adaDefinition = { lineType : "ada"};
          adaInPixi = addAssetToView(pixiApp, adaDefinition, ADA_X, ADA_Y);

          // ARROWS
          var arrowLDefinition = { lineType : "arrowL"};
          arrowL_inPixi = addAssetToView(pixiApp, arrowLDefinition, arrowL_X, arrow_Y);
                    
          var arrowRDefinition = { lineType : "arrowR"};
          arrowR_inPixi = addAssetToView(pixiApp, arrowRDefinition, arrowR_X, arrow_Y);
          
          var arrowYDefinition = { lineType : "arrowT"};
          arrowT_inPixi = addAssetToView(pixiApp, arrowYDefinition, arrowT_X, arrowT_Y);

        });


        pixiApp.loader.onComplete.add(() => {
          //console.log("lad Complete")
        });


    } else {
      
      /* Pixi render */

      render = Render.create({
        element: canvasParent,
        app: pixiApp,
        engine: engine
      });
    }


    Render.run(render);

    runner = Runner.create();
    Runner.run(runner, engine);

    //
    // MATTERS MOVING CARS - and blocks
    //
    Events.on(engine, 'beforeUpdate', function() {

      if (! isInitialized) {
        isInitialized = true;
        initBackroundPosition();
      }

      if (! animVars.isPlaying) {
        updateBackroundPosition();
      }


      /*
      bodies = Composite.allBodies(engine.world);
      let i, body
      for(i = 0; i < bodies.length; i++) {
        body = bodies[i];


        // move assets ( car, rect ...)

        if (isMoved === true && clicked === 1) {
          //console.log("CLICKED ", clicked);
          fromY = -HEIGHT + rowH;// (rowH * nombreRow); 

          const newlinesBlock = makeLines();

          if (newlinesBlock.length) {
              //var firstline = newlinesBlock[0];
              // add juste one line
              //for (var i = 0; i < firstline.length; i++) {
              //   Composite.add(engine.world,firstline[i]);
              //}
              
              //-----------  ADD LINES ASSETS -----------------
              // pixi
              newlinesBlock.forEach(function(lines) {
                  lines.forEach(function(matter_assets) {
                  addAssetToView(pixiApp, matter_assets);
                });
              });

              // matter  
              for (var lines = 0; lines < newlinesBlock.length; lines++) {
                  Composite.add(engine.world, newlinesBlock[lines]);
              }


              // MOVE OBJECT
              //newlinesBlock.forEach(function(lines) {
              //    moveObjects(lines);
              //});

              lineblocks.concat(newlinesBlock);
              console.log("LINE BLOCKS LENGTH ", newlinesBlock.length);

              
              //------------- ADD LIFES ------------
              console.log("--------------addLifes")
              // matter
              lifes = [];
              lifes = makeLifes();
              Composite.add(engine.world, lifes);

              // pixi
              lifes.forEach(function(matter_assets) {
                addAssetToView(pixiApp, matter_assets);
              });
          }
          
          isMoved = false;
        }
      }
       */

    });

    //------------------------------------------------------//
    // -----------   MATTER COLLISION EVENTS ---------------//
    //------------------------------------------------------//

    Events.on(engine, 'collisionStart', function(event) {
      console.log("------------------ COLISION --------------------")
      //const pixi_app = render.app;
      const pairs = event.pairs;

      //console.log('PAIRS ', pairs);
      for (var i = 0; i < pairs.length; i++) {
        
        let pair = pairs[i];
        const bodyA = pair.bodyA;
        const bodyB = pair.bodyB;
        
        if(( bodyA.label === adaLabel) && (bodyB.label === lifeLabel)) {
            lifeNumbers("win");
            //life_number.value += 1;
            bodyB.isSleeping = true;

        } else if ( ( bodyA.label === adaLabel) && (bodyB.label === roadLabel)) {
          console.log("------- vcollision with ROAD -------------" );
        
        } else if ( ( bodyA.label === adaLabel) && (bodyB.label === lineAssetLabel) ) {
          console.log("------- vcollision with line -------------" );

          if (bodyB.lineType === "rectOrange") {
              score.value -= 2;
              lifeNumbers("loose");
          } else if (bodyB.lineType === "rectRouge") {
              score.value -= 2;
              lifeNumbers("loose");

          } else if (bodyB.lineType === "car") {
              cars_number.value += 1;
              bodyB.isSleeping = true;
              scoreCount("collect");
          }
        }
        //console.log('PAIR BODY A ', pair.bodyA);
        //console.log('PAIR BODY B ', pair.bodyB);

      }

    });
  }

  let isInitialized = false;

  const initBackroundPosition = function() {
    bodies = Composite.allBodies(engine.world);

    let i, posx, body;
    for (i = 0; i < bodies.length; i++) {
      body = bodies[i];
      if (body.label === lineAssetLabel ) {
        var blockinfos = getAssetsInfosByType(body.lineType);
        var assetW = blockinfos.w;
        if (body.dir === 1) {
          posx = body.position.x - ( assetW / 2 ) - body.marg;
        } else if (body.dir === -1) {
          posx = body.position.x + ( assetW / 2 ) + body.marg;
        }
        Matter.Body.setPosition(body, {
          x: posx,
          y: body.position.y
        });
      }
    }
  }

  const storeBackroundPosition = function() {
    for (var i = 0; i < bodies.length; i++) {
      var body = bodies[i];
      body.position.init_x = body.position.x;
      body.position.init_y = body.position.y;
    }
  }

  const updateBackroundPosition = function(arrow) {
      //console.log(" BODY ", bodies.length);

      for (var i = 0; i < bodies.length; i++) {
        var body = bodies[i];

        var posx, posy;

        if (animVars.isPlaying && animVars.percent > 0) {
          if (arrow === "arrowL") {
              posx = body.position.init_x + animVars.percent * columnW;
              posy = body.position.y;
          }
          else if (arrow === "arrowR") {
              posx = body.position.init_x - animVars.percent * columnW;
              posy = body.position.y;
          }
          else if (arrow === "arrowT") {
              posx = body.position.x;
              posy = body.position.init_y - animVars.percent * rowH;
          }
        } else {
          posx = body.position.x;
          posy = body.position.y;
        }

        if (body.vitesse) {
          posx += body.vitesse * body.dir;
          posx = checkHorizontalLimits(body, posx);
        }

        if (body.lineType !== "arrowL" &&
           body.lineType !== "arrowR" &&
           body.lineType !== "arrowT" &&
           body.lineType !== "road" &&
           body.lineType !== "ada")
        {
            Matter.Body.setPosition(body, {
              x: posx,
              y: posy
            });
        }

      }
  };

  /*
  const moveObjects = function(objects) {
      //console.log("MOVE OBJECTS ", objects);
      var lastobjnumber;
      var previousobj;

      var blockinfos;// = getAssetsInfosByType(lineType);
      var objwidth;// = blockinfos.w * dir;

      var lineType, marg, dir, vitesse;



      for (var j = 0; j < objects.length; j++) {
        var obj = objects[j];
        
        lineType = obj.lineType;
        marg = obj.marg;
        dir = obj.dir;
        vitesse = obj.vitesse * difficulty;//;

        blockinfos = getAssetsInfosByType(lineType);
        objwidth = blockinfos.w * dir;

        var posx;
        if(dir === 1)
          posx = obj.position.x - ( blockinfos.w / 2 ) - marg;
        else if (dir === -1)
          posx = obj.position.x + ( blockinfos.w / 2 ) + marg;
        
        // gestion boucle
        if (posx > WIDTH && dir === 1) {
          if (j === objects.length - 1)
            lastobjnumber = 0;
          else 
            lastobjnumber = j + 1;

          previousobj = objects[lastobjnumber];

          obj.position.x = previousobj.position.x - columnW * 2 - marg;
        }
        else if (posx < objwidth && dir === -1) {
          if(j === 0)
            lastobjnumber = objects.length - 1;
          else
            lastobjnumber = j - 1;

          previousobj = objects[lastobjnumber];
          obj.position.x = previousobj.position.x + columnW * 2 + marg;
        }

        // movement direction
        if (dir === 1) {
          Matter.Body.setPosition(obj, {
            x: obj.position.x + vitesse,
            y: obj.position.y
          });
        } else if (dir === -1 ) {
          Matter.Body.setPosition(obj, {
            x: obj.position.x - vitesse,
            y: obj.position.y
          });
        }
      }
      
  }
  */

  const checkHorizontalLimits = function(body, posx) {

    const dir = body.dir;
    const blockinfos = getAssetsInfosByType(body.lineType);
    const id = body.assetID;
    const row = body.row;
    const currline = lineblocks[row];

    const assetW = blockinfos.w;
    const assetHW = assetW / 2;

    let prevobjid, prevOBJ;

    if (dir === 1 && posx > WIDTH + assetHW)
    {
      prevobjid = id === currline.length - 1 ? 0 : id + 1;
      prevOBJ = currline[prevobjid];
      return prevOBJ.position.x + assetHW - body.marg;
    }
    else if (dir === -1 && posx < -assetHW)
    {
      prevobjid = id === 0 ? currline.length - 1 : prevobjid = id - 1;
      prevOBJ = currline[prevobjid];
      return prevOBJ.position.x + assetHW + body.marg;
    }

    return posx;
  }


  const addAssetToView = function(pixi_app, asset, x, y) {
    const assetType = asset.lineType;

    const sprite = new PIXI.Sprite(pixi_app.resources[assetType].texture);

    sprite.id = asset.id;
    sprite.x = x;
    sprite.y = y;
    sprite.anchor.x = 0.5;
    sprite.anchor.y = 0.5;
    
    // create sprite interactive
    if (asset.lineType === "arrowL" || asset.lineType === "arrowR" || asset.lineType === "arrowT") {

      sprite.interactive = true;
      sprite.buttonMode = true;

      // Flèches de direction :
      sprite.on('mousedown', function() {

        if (asset.lineType === "arrowT") {
          isMoved = true;
          clicked++;
          score.value += 2;

          if (clicked === nombreRow)
              clicked = 1;
        }

        console.log('isMoved : ', isMoved, ' clicked : ', clicked);

        animVars.percent = 0;
        animVars.isPlaying = true;

        storeBackroundPosition();

        gsap.to(animVars, {
          percent: 1,
          ease: "linear",
          onUpdate: function() {
            updateBackroundPosition(asset.lineType);
          },
          onComplete: function() {
            animVars.isPlaying = false;
            animVars.percent = 0;
          }
        });

      });
    }
    
    pixi_app.stage.addChild(sprite);

    return sprite;
  };


  let animVars = { percent: 0, isPlaying: false };


  const getCurrentTime = function() {
    return new Date().getTime();
  }

  const getSpriteById = function(pixi_app, particleId) {
    let foundSprite = false;
    pixi_app.stage.children.forEach( function(sprite) {
      if (sprite.id === particleId) {
        foundSprite = sprite;
      }
    });
    return foundSprite;
  };

  const lifeNumbers = function(type) {
      if ( type === "win") {
        life_number.value += 1;
      } else if (type === "loose") {
        collisionLoose++;

        if (collisionLoose === collisionLooseMax) {
          // loose one life
          life_number.value -= 1;
          collisionLoose = 0;

          console.log("loose life")
        } 
      }
  }

  
  const scoreCount = function(type) {
      console.log("----------- SCORE COUNT -------------");
      scd++

      // PALIER
      if(scd%60 === 0) {
        difficulty++;
        console.log("difficulty ", difficulty);
      }
       
      if(scd%3 === 0 && clicked > 0) {
        score.value += 10 * difficulty;
      }

      if (type === "collect") {
        score.value += 20;
      }
  }



  /* ------------------ MATTER ------------------------ */

  const MARG_r1 = columnW;
  const MARG_r2 = columnW * 2;
  const MARG_r3 = columnW * 3;
  const MARG_r4 = columnW * 4;

  //const MARG_r5 = 110;
  //const MARGS = [MARG_r1, MARG_r2, MARG_r3, MARG_r4, MARG_r5];

  let linesBlockDefinitions = [];

  linesBlockDefinitions.push([
      { lineType: 'rectOrange', row:0, marg: MARG_r2, dir:1,  vitesse:0.5  },
      { lineType: 'car',        row:1, marg: MARG_r3, dir:-1, vitesse:0.2  },
      { lineType: 'car',        row:2, marg: MARG_r1, dir:1,  vitesse:0.2  },
      { lineType: 'rectRouge',  row:3, marg: MARG_r2, dir:-1, vitesse:0.5  },
      { lineType: 'rectRouge',  row:4, marg: MARG_r3, dir:1,  vitesse:1    },
      { lineType: 'rectOrange', row:5, marg: MARG_r4, dir:1,  vitesse:0.5  },
  ]);

  linesBlockDefinitions.push([
      { lineType: 'car',        row:0, marg: MARG_r1, dir:-1, vitesse:1.5  },
      { lineType: 'car',        row:1, marg: MARG_r2, dir:1,  vitesse:0.5  },
      { lineType: 'rectOrange', row:2, marg: MARG_r2, dir:1,  vitesse:0.2  },
      { lineType: 'rectRouge',  row:3, marg: MARG_r2, dir:-1, vitesse:0.5  },
      { lineType: 'rectOrange', row:4, marg: MARG_r3, dir:1,  vitesse:0.5  },
      { lineType: 'rectRouge',  row:5, marg: MARG_r4, dir:1,  vitesse:0.8  },

  ]);

  linesBlockDefinitions.push([
      { lineType: 'rectRouge',  row:0, marg: MARG_r3, dir:1, vitesse:1.5 },
      { lineType: 'rectOrange', row:1, marg: MARG_r3, dir:1, vitesse:0.5 },
      { lineType: 'car',        row:2, marg: MARG_r3, dir:1, vitesse:0.5 },
      { lineType: 'rectRouge',  row:3, marg: MARG_r2, dir:1, vitesse:1.5 },
      { lineType: 'car',        row:4, marg: MARG_r1, dir:1, vitesse:0.5 },
      { lineType: 'car',        row:5, marg: MARG_r1, dir:1, vitesse:0.5 },
  ]);

   linesBlockDefinitions.push([
      { lineType: 'rectOrange', row:0, marg: MARG_r2, dir:1,  vitesse:1.5  },
      { lineType: 'rectOrange', row:1, marg: MARG_r3, dir:-1, vitesse:1.5  },
      { lineType: 'car',        row:2, marg: MARG_r1, dir:1,  vitesse:0.5  },
      { lineType: 'rectRouge',  row:3, marg: MARG_r2, dir:-1, vitesse:0.8  },
      { lineType: 'rectRouge',  row:4, marg: MARG_r3, dir:1,  vitesse:0.5  },
      { lineType: 'car',        row:5, marg: MARG_r4, dir:1,  vitesse:0.2  },
  ]);

  linesBlockDefinitions.push([
      { lineType: 'car',        row:0, marg: MARG_r1, dir:1,  vitesse:1.5  },
      { lineType: 'car',        row:1, marg: MARG_r2, dir:1,  vitesse:1    },
      { lineType: 'rectOrange', row:2, marg: MARG_r3, dir:-1, vitesse:0.3  },
      { lineType: 'rectRouge',  row:3, marg: MARG_r4, dir:1,  vitesse:0.5  },
      { lineType: 'rectOrange', row:4, marg: MARG_r3, dir:-1, vitesse:0.4  },
      { lineType: 'rectRouge',  row:5, marg: MARG_r4, dir:1,  vitesse:1    },
  ]);


  const linesBlockDefinitionIds = [0, 1, 2, 3, 4]; //
  let linesBlockDefinitionIdCopy = shuffleArray(linesBlockDefinitionIds).concat();

  const getNextLinesBlocks = function() {

    if (linesBlockDefinitionIdCopy.length === 0) {
      linesBlockDefinitionIdCopy = shuffleArray(linesBlockDefinitionIds).concat();
    }

    const lineBlockDefinitionId = linesBlockDefinitionIdCopy.shift();

    return linesBlockDefinitions[lineBlockDefinitionId];
  };

  // all assets info
  const getAssetsInfosByType = function(lineType) {
    let w, h;
    
    switch(lineType) {
      case "road":
        w = 692;
        h = 28;
      break;
      case "rectOrange":
        w = 310;
        h = 128;
      break;
      
      case "rectRouge":
        w = 256;
        h = 128;
      break;

      case "car":
        w = 64;
        h = 64;
      break;

      case "adaSmall":
        w = 32;
        h = 32;
      break;
    }

    return { w:w , h:h}
  }

  //
  // MATTER ELEMENTS CREATION
  //

  const makeAdaElement = function(lineType, x, y, w, h) {

    return Matter.Bodies.rectangle(
        x,
        y,
        w,
        h,
        {
          label:adaLabel,
          lineType: lineType,
          isStatic: true,
          //isPseudoStatic: true,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: adaCategory,
            mask: lineCategory | roadCategory | lifeCategory
          },
        });
  };

  const makeLifeElement = function(lineType, x, y, w, h) {

    return Matter.Bodies.rectangle(
        x,
        y,
        w,
        h,
        {
          label:lifeLabel,
          lineType: lineType,
          isSleeping:false,
          //isStatic: true,
          //isPseudoStatic: true,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: lifeCategory,
            mask: adaCategory
          },
        });
  };

  const makeRoadElement = function(lineType, x, y, w, h) {

    return Matter.Bodies.rectangle(
        x,
        y,
        w,
        h,
        {
          label:roadLabel,
          lineType: lineType,
          //isStatic: true,
          //isPseudoStatic: true,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: roadCategory,
            mask: adaCategory
          },
        });
  };
    
  const makeLineAsset = function(lineType, x, y, w, h, dir, marg, vitesse, row, assetID) {
    const rect = Matter.Bodies.rectangle(
        x,
        y,
        w,
        h,
        {
          label:lineAssetLabel,
          lineType: lineType,
          dir:dir,
          marg:marg,
          row:row,
          assetID:assetID,
          vitesse:vitesse,
          isSleeping:false,
          //isStatic: true,
          //isPseudoStatic: true,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: lineCategory,
            mask: adaCategory
          },
        });
      
      //Matter.Body.setVelocity(rect, {x:0, y:0});

    return rect;
  };

  // ASSETS - cars , rectOrange, rectRouge, ...
  const makeLine = function(rowNumber, lineType, marg, dir, vitesse) {

    const assets = [];
    let assetX, assetY, asset;
    let w, h;

    var blockinfos = getAssetsInfosByType(lineType);
    w = blockinfos.w;
    h = blockinfos.h;

    for (let i = 0; i < nombreColumn; i++) {
    
      assetX = (columnW * 2 + marg) * i;

      assetY = fromY + (rowH * rowNumber) + ( assetH_max / 2) + rowYMarg;

      asset = makeLineAsset(lineType, assetX, assetY, w, h, dir, marg, vitesse, rowNumber, i );

      assets.push(asset);
      
    }

    return assets;
    
  };

  const makeLines = function() {
    const linesBlockDefinition = getNextLinesBlocks();
    let linesDefinition, line, lineBlocks;
    let row, lineType, marg, dir, vitesse;

    const newLines = [];

    for (let i = 0; i < linesBlockDefinition.length - 1; i++) {
        linesDefinition = linesBlockDefinition[i];
        row      = linesDefinition.row;
        lineType = linesDefinition.lineType;
        marg     = linesDefinition.marg;
        dir      = linesDefinition.dir;
        vitesse  = linesDefinition.vitesse;

        console.log("---------- LINE ", row, lineType, dir, vitesse);

        // Récupération d'un set de lignes existant
        lineBlocks = findSleepyLines(lineType);
        //console.log("lineblcks sleeping ? ", lineBlocks);

        if(lineBlocks) {
          //restoreLines(lineBlocks);
        } else { 
          line = makeLine(row, lineType, marg, dir, vitesse);
          newLines.push(line);        
        }
    }

    return newLines;
  }

  /*
  const removeBlock = function(block) {
    block.isSleeping = true;
    block.collisionFilter.mask = topWallCategory;
    block.isLastBlock = false;
  };
 

  const restoreLines = function(line, x, y) {
    line.isSleeping = false;
    line.collisionFilter.mask = ballCategory | topWallCategory;
    Matter.Body.setPosition(line, { x: x, y: y });
  }
 */

  const findSleepyLines = function(lineType) {
    console.log("lineType sleepy ", lineType);
    /*
    let i, matterLines;

    for(i = 0; i < lineblocks.length; i++) {

      matterLines = lineblocks[i];

      if (matterLines.isSleeping && matterLines.lineType === lineType ) {
        return matterLines;
      }
    }
    */
    return false;
    
  };


  // ROADS
  let roadDefinition = {type: "road", x: WIDTH/2, w: 692, h: 28 };

  // ROW VALUES
  const ROW_1 = rowH + rowCenter;
  const ROW_2 = rowH * 2 + rowCenter;
  const ROW_3 = rowH * 3 + rowCenter;
  const ROW_4 = rowH * 4 + rowCenter;
  const ROW_5 = rowH * 5 + rowCenter;

  const ROWS = [ROW_1, ROW_2, ROW_3, ROW_4, ROW_5];
  const ROWScopy = shuffleArray(ROWS).concat();

  const ROWS_number = [2, 3, 4, 5];
  const ROWS_numbercopy = shuffleArray(ROWS_number).concat();

  
  const makeRoad = function(){
    const roads = [];

    let n = ROWS_numbercopy[0];

    let roadX, roadY, roadW, roadH;
    let road, roadType;
    
    for (let i = 0; i < n; i++) {

      roadType = roadDefinition.type;
      roadX = roadDefinition.x;
      roadY = ROWScopy[i];
      roadW = roadDefinition.w;
      roadH = roadDefinition.h;

      road = makeRoadElement(roadType, roadX, roadY , roadW, roadH);

      roads.push(road);
      
    }

    return roads;

  }
  



  // LIFE 
  
  let lifeDefinitions = {type: "adaSmall", w: 32, h: 32 };

  let lifeX_positions = [30, 60, 100, 140, 180, 220, 260, 300, 340, 380, 420, 460, 500];
  let lifeX_positionsCopy = shuffleArray(lifeX_positions).concat();

  const ROWScopy2 = shuffleArray(ROWS).concat();
  const ROWS_numbercopy2 = shuffleArray(ROWS_number).concat();

  
  const makeLifes = function() {
    const adalifes = [];

    let n =  ROWS_numbercopy2[0];
    let adaLifeX, adaLifeY, adaLifeW, adaLifeH;
    let adaLife, adaLifeType;

    for (let i = 0; i < n; i++) {


      adaLifeType = lifeDefinitions.type;
      adaLifeX = lifeX_positionsCopy[i];
      adaLifeY = fromY + ROWScopy2[i];
      adaLifeW = lifeDefinitions.w;
      adaLifeH = lifeDefinitions.h;

      adaLife = makeLifeElement(adaLifeType, adaLifeX, adaLifeY , adaLifeW, adaLifeH);

      adalifes.push(adaLife);
      
    }

    return adalifes;

  }
  

  //ARROWS
  /*
  const makeArrows = function(){
      const arrows = [];

      let arrow;
      let assetX, assetY, arrowType;



      for (let i = 0; i < 3; i++) {
        if ( i === 0 ) {
          assetX = ADA_X - ADA_WIDTH;
          assetY = ADA_Y;
          arrowType = "arrowL";
        } 
        else if ( i === 1 ) {
          assetX = ADA_X + ADA_WIDTH;
          assetY = ADA_Y;
          arrowType = "arrowR";
        }
        else if (i === 2 ) {
          assetX = ADA_X;
          assetY = ADA_Y - 100;
          arrowType = "arrowT";
        }

        arrow = makeElement(arrowType, assetX, assetY , 50, 50);

        arrows.push(arrow);      
      
      }
          
      return arrows;
  }
  */
  

  //
  // Lifecycle hooks
  //

  watch( props, () => {
    initNiveau();
    initActivite();
  });

  onMounted(() => {
    initNiveau();
    initActivite();
  });


  onUnmounted(() => {
    if (engine && engine.world) {
      World.clear(engine.world);
      Engine.clear(engine);
      Render.stop(render);
      Runner.stop(runner);
    }


    Events.off(engine, 'beforeUpdate');
    Events.off(engine, 'collisionStart');

    clearInterval(timer);
    
    if (pixiApp) {
      pixiApp.loader.reset();
      pixiApp.destroy(true);
      // pixiApp.utils.destroyTextureCache();
      pixiApp = null;
    }

    // Suppression des canvas résiduels éventuels
    const element = document.getElementsByTagName('canvas');
    for (let i = element.length - 1; i >= 0; i--) {
      element[i].parentNode.removeChild(element[i]);
    }

  });

</script>




<style scoped lang="scss">

  .frogger {

    position: relative;
    padding-top: 30px;

    display: flex;
    justify-content: center;
    gap: 50px;
   
    
    #canvas-parent {
      border: 4px solid #FFD32C;
      border-radius: 24px;
      overflow: hidden;
      height: calc( 100vh - 60px );
    }

    #score {
      position:absolute;
      left:-220px;
      top:100px;
      width:340px;
      height:128px;
      background: #fff;
      border: 2px solid #E6E6E6;
      border-radius: 40px;
      padding: 3px 6px 6px;
      
      display: flex;
      justify-content: center;
    
      box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
    }

    #score-car {
      position:absolute;
      left:-150px;
    }

    #score-ada, #score-car {
      width: 138px;
      height:64px;
      background: #fff;
      border: 2px solid #E6E6E6;
      border-radius: 50px;
      padding: 0px 25px;
      display: inline-block;
      box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
    }

    #score-icon,
    #score-text,
    #car-icon,
    #car-text {
      display: inline-block;
      width: 32px;
      height: 32px;
      padding: 0px 15px;
    }

    #score-icon {
      background: url("/assets/jeux/frogger/ada_small@1px.png") 0 0 no-repeat;
      background-size: contain;
    }

    #car-icon {
      background: url("/assets/jeux/frogger/car_small@1px.png") 0 0 no-repeat;
      background-size: contain;
    }



    #fps-parent {
      display: none;
    }

    #fps {
      color: red;
    }

    #debug-render {
      position: absolute;
      left:0;
      top:0;
      width: 1000px;
      height: 900px;
      pointer-events: none;
    }


  }


</style>
