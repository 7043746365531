<template>
  <div class="header-activite"  :class="niveauCss" >
    <div>
      <h1 class="titan">{{ title }}</h1>
      <h2>{{ niveauStr }}</h2>
    </div>
    <router-link :to="backRoute" class="back-button" ></router-link>
    <div class="buttons">
      <a class="restart-button" :class="replayCss" @click="replay" />
      <a class="tools-button" :class="toolsCss" @click="toggleTools" />
    </div>
  </div>
</template>

<script setup>

  console.log("---------- HEADER OUTILS ---------------")

  import {onMounted, computed, ref} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Par défaut, retour à l'accueil
  const backRoute = ref('/');
  const niveauxOutil = 'Tables Outil'

  const emit = defineEmits(['replay', 'toggleTools']);

  function replay() {
    emit('replay');
  }

  function toggleTools() {
    emit('toggleTools');
  }

  //
  // Propriétés
  //

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: "Titre de l'activité"
    },
    niveau: {
      type: Number,
      required: false,
      default: 0
    },
    hasReplay: {
      type: Boolean,
      required: false,
      default: true
    },
    hasTools: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  //
  // LifeCycle Hooks
  //

  onMounted(() => {

    console.log(currentRouteName.value, "/", niveauxOutil)

    if (currentRouteName.value !== niveauxOutil) {
      // Pour une activité, retour au choix des niveaux
      const outilInfos = store.getters.outilFromName(currentRouteName.value);
      if (outilInfos) {
        console.log("outil infos path ", outilInfos.path);

        backRoute.value = router.resolve({
          name: niveauxOutil,
          params: {
            outil: outilInfos.path
          }
        }).href;

      }
    }

  });

  const niveauCss = computed(() => props.niveau === 0 ? 'sans-niveau' : '' );
  const niveauStr = computed(() => "Table " +  props.niveau );
  const replayCss = computed(() => props.hasReplay ? '' : 'hidden' );
  const toolsCss = computed(() => props.hasTools ? '' : 'hidden' );

  const currentRouteName = computed(() => route.name);

</script>

<style scoped lang="scss">

  .header-activite {

    position: relative;
    margin-bottom: 40px;

    @include on-small-height-desktop {
      margin-bottom: 0;
    }

    @include on-print {
      margin-bottom: 25px;
    }

    h1, h2 {
      display: block;
      width: 100%;
      text-align: center;
    }

    h1 {
      padding-top: 20px;
    }

    h1.titan {
      font-size: 48px;
      line-height: 54px;
      -webkit-text-stroke-width: 1px;
      text-shadow: 0px 4px 0px #333333;

      @include on-small-height-desktop {
        font-size: 42px;
        line-height: 46px;
      }

      @include on-mobile {
        font-size: 30px;
        line-height: 1.2;
      }

      @include on-print {
        padding-top: 0 !important;
        font-size: 42px;
        line-height: 46px;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 400;
      padding-top: 4px;

      @include on-small-height-desktop {
        font-size: 24px;
      }

      @include on-print {
        font-size: 20px;
      }
    }

    &.sans-niveau {
      h2 {
        display: none;
      }
    }


    /* Back */

    a.back-button {
      position: absolute;
      top: 20px;
      left: 0;

      background: url(../../../assets/images/svg/icones_v2/Back_4x.png) center / cover no-repeat;
      width:63px;
      height:71px;

      /*
      border: 2px solid #333333;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #333333;
      border-radius: 10px;
      */


      @include on-print {
        display: none;
      }
    }

    /* Tools + replay */

    .buttons {

      position: absolute;
      top: 20px;
      right: 0;

      @include on-print {
        display: none;
      }

      a {
        display: inline-block;
        width:63px;
        height:71px;
        cursor: pointer;

        &.tools-button {
          background: url(../../../assets/images/svg/icones_v2/Tools_4x.png) center / cover no-repeat;

          @include on-mobile {
            width:50px;
            height:60px;
          }
        }

        &.restart-button {
          background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / cover no-repeat;

          @include on-mobile {
            width:50px;
            height:60px;
          }
        }

      }
    }

  }

</style>