<template>
  <div class="header-activite" :class="niveauCss" >
    <div>
      <h1 class="titan">{{ title }}</h1>
      <h2>{{ niveauStr }}</h2>
    </div>
    <router-link :to="backRoute" class="back-button" ></router-link>
    <div class="buttons">
      <!-- Boutons Activités -->
      <a class="restart-button" :class="replayCss" @click="replay" />
      <a class="tools-button" :class="toolsCss" @click="toggleTools" />

      <!-- Boutons Playlist -->
      <div class="playlist-buttons" :class="nextCss">
        <a class="next-button"  @click="replay" />
        <ul :class="nextCss" class="playlist-steps">
          <li
              v-for="i in playlistLength"
              :key="i"
              :class="i === playlistPosition + 1 ? 'active' : ''"
          >
            {{ i }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {onMounted, computed, ref} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Par défaut, retour à l'accueil
  const backRoute = ref('/sommaire');

  // Nom de la route du sommaire des niveau d'une activité
  const niveauxActivite = 'Niveaux Activité'

  // Cas d'un parcours : nombre d'étapes dans la playlist
  const playlistLength = ref(0);

  const emit = defineEmits(['replay', 'openTools']);

  function replay() {
    emit('replay');
  }

  function toggleTools() {
    emit('openTools');
  }

  //
  // Propriétés
  //

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: "Titre de l'activité"
    },
    niveau: {
      type: Number,
      required: false,
      default: 0
    },
    hasReplay: {
      type: Boolean,
      required: false,
      default: true
    },
    hasTools: {
      type: Boolean,
      required: false,
      default: false
    },
    hasToolsActive: {
      type: Boolean,
      required: false,
      default: false
    }
  });


  //
  // LifeCycle Hooks
  //

  onMounted(() => {

    // console.log('route.query.parcours', route.query.parcours);

    if (route.query.parcours !== undefined)
    {
      // Cas particulier : parcours
      playlistLength.value = store.getters.getPlaylistLength(route) - 1;
      backRoute.value = router.resolve({
        path: "/sommaire",
        hash: "#parcours"
      });
    }
    else if (route.name !== niveauxActivite)
    {
      // Cas général :
      // Pour une activité, retour au choix des niveaux
      const activiteInfos = store.getters.activiteFromName(route.name);
      if (activiteInfos)
      {
        // Activité : retour à la liste des niveaux
        backRoute.value = router.resolve({
          name: niveauxActivite,
          params: {
            activite: activiteInfos.path
          }
        }).href;
      }
      else
      {
       backRoute.value = "/sommaire";
      }

    }

  });

  const niveauCss = computed(() => isNaN(props.niveau) || (props.niveau === 0) ? 'sans-niveau' : '' );
  const niveauStr = computed(() => isNaN(props.niveau) ? "" : "Niveau " +  props.niveau );
  const replayCss = computed(() => props.hasReplay && route.query.playlist === undefined ? '' : 'hidden' );
  const playlistPosition = computed(() => route.query.playlist === undefined ? 0 : parseInt(route.query.playlist));

  // Playlist
  const nextCss = computed(() => props.hasReplay && route.query.playlist !== undefined ? '' : 'hidden' );

  const toolsCss = computed(() => [
      props.hasTools ? '' : 'hidden',
      props.hasToolsActive ? 'active' : 'inactive'
    ].join(' ')
  );


</script>

<style scoped lang="scss">

  .header-activite {

    position: relative;
    margin-bottom: 40px;

    @include on-small-height-desktop {
      margin-bottom: 0;
    }

    @include on-print {
      margin-bottom: 25px;
    }

    h1, h2 {
      display: block;
      width: 100%;
      text-align: center;
    }

    h1 {
      padding-top: 20px;

      @include on-small-height-desktop {
        padding-top: 20px;
      }

      @include on-mobile {
        box-sizing: border-box;
        padding: 15px 70px 0;
      }

      @include on-mobile-landscape {
        padding-top: 10px;
      }

      @include on-print {
        padding-top: 5px;
      }
    }

    h1.titan {
      font-size: 48px;
      line-height: 54px;
      -webkit-text-stroke-width: 1px;
      text-shadow: 0 4px 0 #333333;

      @include on-small-height-desktop {
        font-size: 42px;
        line-height: 46px;
      }

      @include on-mobile {
        font-size: 32px;
        line-height: 1.2;
      }

      @include on-mobile-landscape {
        font-size: 32px;
        line-height: 1.2;
      }

      @include on-print {
        padding-top: 0;
        font-size: 42px;
        line-height: 46px;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 400;
      line-height: 1.1;
      padding-top: 4px;

      @include on-small-height-desktop {
        font-size: 24px;
      }

      @include on-mobile-landscape {
        font-size: 20px;
        line-height: 1.2;
      }

      @include on-print {
        font-size: 20px;
      }
    }

    &.sans-niveau {
      h2 {
        display: none;
      }
    }


    /* Back */

    a.back-button {
      position: absolute;
      top: 20px;
      left: 0;

      background: url(../../../assets/images/svg/icones_v2/Back_4x.png) center / cover no-repeat;
      width:63px;
      height:71px;

      @include on-mobile {
        top: 15px;
        width:50px;
        height:60px;
      }

      @include on-mobile-landscape {
        top: 15px;
        width:50px;
        height:60px;
      }

      @include on-print {
        display: none;
      }
    }


    /* Tools + replay */

    .buttons {
      position: absolute;
      top: 20px;
      right: 0;

      @include on-mobile {
        top: 15px;
      }

      @include on-mobile-landscape {
        top: 15px;
      }

      @include on-print {
        display: none;
      }

      a {
        display: inline-block;
        width:63px;
        height:71px;
        cursor: pointer;

        @include on-mobile {
          width:50px;
          height:60px;
        }

        @include on-mobile-landscape {
          width:50px;
          height:60px;
        }

        &.tools-button {
          background: url(../../../assets/images/svg/icones_v2/Tools_4x.png) center / cover no-repeat;

          &.inactive {
            opacity: 0.25;
            pointer-events: none;
          }
        }

        &.restart-button {
          background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / cover no-repeat;
        }

        &.next-button {
          background: url(../../../assets/images/svg/icones_v2/Next_4x.png) center / cover no-repeat;
        }
      }

      .playlist-buttons {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
      }

      ul.playlist-steps {
        display: flex;
        gap: 5px;

        list-style-type: none;
        margin: 0;
        padding: 0;

        & > li {
          display: inline-block;
          text-indent: -9999px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 2px black solid;

          &.active {
            background-color: #79d86a;
          }
        }
      }
    }

  }

</style>