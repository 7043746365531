<template>
  <div @click="playSoundChiffre">
    <DeUnite0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeUnite1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeUnite2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeUnite3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeUnite4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeUnite5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeUnite6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeUnite7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeUnite8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeUnite9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeUnite10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeUnite0 from "./DeUnite0";
import DeUnite1 from "./DeUnite1";
import DeUnite2 from "./DeUnite2";
import DeUnite3 from "./DeUnite3";
import DeUnite4 from "./DeUnite4";
import DeUnite5 from "./DeUnite5";
import DeUnite6 from "./DeUnite6";
import DeUnite7 from "./DeUnite7";
import DeUnite8 from "./DeUnite8";
import DeUnite9 from "./DeUnite9";
import DeUnite10 from "./DeUnite10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_Unite,
  playSound
} from "../../../../js/utils";


//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
});


//
// Reactive values
//

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  var soundPath;
  if (props.withSound)
  {
    if (props.withSoundDecomposed) {
      soundPath = getSoundPath_Unite(refChiffre.value);
    } else {
      soundPath = getSoundPath_Chiffre(refChiffre.value);
    }
    playSound(soundPath);
  } 
}


//
// Lifecycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

</script>