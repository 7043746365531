<template>
  <label class="rounded-radio" :for="inputId">
    <input
        type="radio"
        :name="inputName"
        :value="inputValue"
        :id="inputId"
        @change="onClickInput($event)"
    >
    <span class="label">{{ props.textLabel }}</span>
  </label>
</template>
<script setup>

//
// Propriétés du composant
//

import {computed, defineExpose, onMounted, ref} from "vue";

const props = defineProps({
  inputId: {
    type: String,
    required: false,
    default: "radio1"
  },
  inputName: {
    type: String,
    required: false,
    default: "radio-group"
  },
  inputValue: {
    type: String,
    required: false,
    default: ""
  },
  checked: {
    type: Boolean,
    required: false,
    default: false
  },
  textLabel: {
    type: String,
    required: false,
    default: "Label"
  },
});


//
// Reactive value
//

const isChecked = ref(props.checked);

const inputId = computed(() => {
  return ["radio", props.inputName, props.inputValue].join('-');
})


//
// LifeCycle hooks
//

onMounted(()=> {
  updateChecked(props.checked);
})


//
// Fonctions spécifiques
//

const updateChecked = function(bool) {
  const inputElement = document.getElementById(inputId.value);
  if (inputElement) {
    inputElement.checked = bool;
  }
}


//
// Events
//

const emit = defineEmits(['change'])

const onClickInput = function($event) {
  const inputRadio = $event.target;
  isChecked.value = inputRadio.checked

  emit( 'change', isChecked.value, props.inputName, props.inputValue);
}


//
// Méthodes publiques
//

defineExpose({
  setRadioButton
})

function setRadioButton(bool) {
  isChecked.value = bool;
  updateChecked(bool);
}

</script>


<style scoped lang="scss">

label.rounded-radio {
  display: block;
  cursor: pointer;

  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666666;


  [type="radio"] {
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px
  }
  [type="radio"][disabled]+.label {
  }
  [type="radio"][disabled]+.label:hover {
    cursor:default
  }
  [type="radio"]+.label {
    display:flex;
    align-items: center;
    position:relative
  }
  [type="radio"]+.label::before {
    content:"";
    display:inline-block;
    flex:0 0 56px;
    width:56px;
    height:56px;
    background: url(../../../assets/images/svg/icones_v2/RoundedCheckbox_off.svg) center / 28px auto no-repeat;
  }
  [type="radio"]:focus:not([disabled])+.label::after,
  [type="radio"]:hover:not([disabled])+.label::after {
    content:"";
    display:inline-block;
    position:absolute;
    left:-4px;
    top:-4px;
    width:25px;
    height:25px
  }
  [type="radio"]:checked+.label::before {
    background: url(../../../assets/images/svg/icones_v2/RoundedCheckbox_on.svg) center / 56px auto no-repeat;
    content: "";
  }
}


</style>
