<template>
  <div @click="playSoundChiffre">
    <DeDizaineMillierComplex0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeDizaineMillierComplex1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeDizaineMillierComplex2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeDizaineMillierComplex3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeDizaineMillierComplex4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeDizaineMillierComplex5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeDizaineMillierComplex6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeDizaineMillierComplex7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeDizaineMillierComplex8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeDizaineMillierComplex9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeDizaineMillierComplex10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeDizaineMillierComplex0 from "./DeDizaineMillierComplex0";
import DeDizaineMillierComplex1 from "./DeDizaineMillierComplex1";
import DeDizaineMillierComplex2 from "./DeDizaineMillierComplex2";
import DeDizaineMillierComplex3 from "./DeDizaineMillierComplex3";
import DeDizaineMillierComplex4 from "./DeDizaineMillierComplex4"
import DeDizaineMillierComplex5 from "./DeDizaineMillierComplex5";
import DeDizaineMillierComplex6 from "./DeDizaineMillierComplex6";
import DeDizaineMillierComplex7 from "./DeDizaineMillierComplex7";
import DeDizaineMillierComplex8 from "./DeDizaineMillierComplex8";
import DeDizaineMillierComplex9 from "./DeDizaineMillierComplex9";
import DeDizaineMillierComplex10 from "./DeDizaineMillierComplex10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_DizaineMillier,
  playSound
} from "../../../../js/utils";


//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  }
});


//
// Reactive values
//


let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  var soundPath;
  if (props.withSound) {
    if (props.withSoundDecomposed)
      soundPath = getSoundPath_DizaineMillier(refChiffre.value);
    else 
      soundPath = getSoundPath_Chiffre(refChiffre.value * 10000);
    playSound(soundPath);
  } 
}


//
// LifeCycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>