<template>
  <div @click="playSoundChiffre">
    <DesDizainesNoirEtBlanc0 v-if="refChiffre === 0" />
    <DesDizainesNoirEtBlanc1 v-else-if="refChiffre === 1" />
    <DesDizainesNoirEtBlanc2 v-else-if="refChiffre === 2" />
    <DesDizainesNoirEtBlanc3 v-else-if="refChiffre === 3" />
    <DesDizainesNoirEtBlanc4 v-else-if="refChiffre === 4" />
    <DesDizainesNoirEtBlanc5 v-else-if="refChiffre === 5" />
    <DesDizainesNoirEtBlanc6 v-else-if="refChiffre === 6" />
    <DesDizainesNoirEtBlanc7 v-else-if="refChiffre === 7" />
    <DesDizainesNoirEtBlanc8 v-else-if="refChiffre === 8" />
    <DesDizainesNoirEtBlanc9 v-else-if="refChiffre === 9" />
    <DesDizainesNoirEtBlanc10 v-else-if="refChiffre === 10" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DesDizainesNoirEtBlanc0 from "./DeDizaineNoirEtBlanc0";
import DesDizainesNoirEtBlanc1 from "./DeDizaineNoirEtBlanc1";
import DesDizainesNoirEtBlanc2 from "./DeDizaineNoirEtBlanc2";
import DesDizainesNoirEtBlanc3 from "./DeDizaineNoirEtBlanc3";
import DesDizainesNoirEtBlanc4 from "./DeDizaineNoirEtBlanc4"
import DesDizainesNoirEtBlanc5 from "./DeDizaineNoirEtBlanc5";
import DesDizainesNoirEtBlanc6 from "./DeDizaineNoirEtBlanc6";
import DesDizainesNoirEtBlanc7 from "./DeDizaineNoirEtBlanc7";
import DesDizainesNoirEtBlanc8 from "./DeDizaineNoirEtBlanc8";
import DesDizainesNoirEtBlanc9 from "./DeDizaineNoirEtBlanc9";
import DesDizainesNoirEtBlanc10 from "./DeDizaineNoirEtBlanc10";

import {ref, onMounted, watch} from "vue";
import {getSoundPath_Chiffre, playSound} from "../../../../js/utils";


//
// Reactive values
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  }
});

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  if (props.withSound) {
    var soundPath = getSoundPath_Chiffre(refChiffre.value * 10);
    playSound(soundPath);
  }
}


//
// Lifecycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>