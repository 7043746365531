import $ from 'jquery'
import { gsap } from 'gsap';
import { TimelineMax } from 'gsap/gsap-core';
import { TweenMax } from 'gsap/gsap-core';
import {jsPDF} from 'jspdf';
import jQuery from 'jquery'
import {Howl, Howler} from 'howler';


//
// Génération du HTML des dés (l'image SVG du dé est chargée via CSS)
//

function getHtmlUnDe( nombre, cssClass ) {
    var cssDe = nombre > 9 ? "double" : "";
    if (cssClass && cssClass.length) {
        cssDe += ' ' + cssClass;
    }
    return '<div class="de-parent ' + cssDe + '"><div data-value="' + nombre +  '" class="de chiffre' + nombre + '"></div></div>';
}

function getHtmlUnDeAvecParametre( no, param, cssClass ) {
    if (! cssClass) cssClass = '';
    var cssTaille = no > 9 ? "double" : "";
    return '<div class="de-parent ' + cssTaille + '"><div data-value="' + no +  '" data-param="' + param +  '" class="de chiffre' + no + ' ' + cssClass + '"></div></div>';
}

function getHtmlDeuxDes( no1, no2 ) {
    var html = '<div class="deux-des">';
    html += getHtmlUnDe(no1);
    html += getHtmlUnDe(no2);
    html += '</div>';

    return html;
}

function getHTMLNombre( nombre, cssClass, zoneCssClass ) {

    const dizaines = Math.floor(nombre/10);
    const unites = nombre - dizaines * 10;

    return getHTMLDizainesUnites(dizaines, unites, '', '', cssClass, zoneCssClass)
}

function getHTMLDizainesUnites( dizaines, unites, position, positionCorrecte, cssClass, zoneCssClass ) {

    var chiffre = dizaines * 10 + unites;
    var blocHtml = '';
    var blockParentCss = 'zone' + (zoneCssClass && zoneCssClass.length ? ' ' + zoneCssClass : '');
    var blockCss = 'bloc' + (cssClass && cssClass.length ? ' ' + cssClass : '');

    blocHtml += '<div class="'+ blockParentCss + '">';
    blocHtml += '<div class="'+ blockCss + '" data-position="' + position + '"  data-ordre="' + positionCorrecte + '" data-value="' + chiffre + '">';

    blocHtml += '<div class="des-dizaines-unites">';

    // Colonne des Dizaines
    blocHtml += '<div class="dizaines">';

    blocHtml += '<div class="des">';
    var i;
    for(i=0;i<dizaines;i++) {
        blocHtml += '<div class="de-parent double"><div class="de chiffre10 "></div></div>';
    }
    blocHtml += '</div>';

    blocHtml += '<div class="nombre dizaines">'+ dizaines +'</div>';
    blocHtml += '</div>';

    // Colonne des Unités

    blocHtml += '<div class="unites">';

    blocHtml += '<div class="des">';
    blocHtml += '<div class="de-parent"><div class="de chiffre' + unites + '"></div></div>';
    blocHtml += '</div>';

    blocHtml += '<div class="nombre unites">' + unites + '</div>';
    blocHtml += '</div>';


    blocHtml += '</div>';
    blocHtml += '<div class="clear"></div>';
    blocHtml += '</div>';

    blocHtml += '</div>';

    return blocHtml;
}


function getHtmlCartoucheNombre(chiffre, no, forceDizaine, isHidden) {

    var blocHtml = '';

    if ((chiffre >= 10 ) || forceDizaine) {

        var dizainesUnites = getDizainesUnites( chiffre );
        var dizaines     = dizainesUnites.dizaines;
        var unites       = dizainesUnites.unites;
        var noDizaineCss = dizaines > 0 ? '' : 'pas-de-dizaines';

        var textZoneDizaines = isHidden ? "&nbsp;" : dizaines;
        var textZoneUnites   = isHidden ? "&nbsp;" : unites;

        var cssZoneDizaines = (dizaines > 0) && isHidden ? 'zone' : '';
        var cssZoneUnites   = isHidden ? 'zone' : '';

        blocHtml += '<div id="chiffre'+ no +'" class="de-parent dizaines-et-unites" data-value="'+ chiffre +'">';

        blocHtml += ' <div class="nombres nombres-dizaines-unites" data-no="'+ no +'">';
        blocHtml += '   <div class="dizaines ' + cssZoneDizaines + ' ' + noDizaineCss + '" data-value="'+ dizaines + '">' + textZoneDizaines + '</div>';
        blocHtml += '   <div class="unites   ' + cssZoneUnites   + ' ' + noDizaineCss + '" data-value="'+ unites   + '">' + textZoneUnites + '</div>';
        blocHtml += ' </div>';

        if (isHidden) {
            blocHtml += ' <div class="correction-parent ' + noDizaineCss + '">';
            blocHtml += '   <div class="correction correction-dizaines"></div>';
            blocHtml += '   <div class="correction correction-unites"></div>';
            blocHtml += ' </div>';
        }

        blocHtml += '</div>';

    } else {

        var cssZone = isHidden ? "de-parent zone" : "de-parent";
        var textZone = isHidden ? "" : chiffre;

        blocHtml += ' <div class="unites-parent">';

        blocHtml += '<div class="nombres unites-seules" data-no="'+ no +'">';
        blocHtml += '<div id="chiffre'+ no +'" class="' + cssZone+ '" data-value="'+ chiffre +'">' + textZone + '</div>';
        blocHtml += '</div>';

        if (isHidden) {
            blocHtml += ' <div class="correction-parent">';
            blocHtml += '   <div class="correction correction-unites"></div>';
            blocHtml += ' </div>';
        }

        blocHtml += '</div>';
    }

    return blocHtml;
}



//
// Table de multiplication
//

function getTableMultiplication( table, cssClass ) {

    // 1  x no
    // 2  x no
    // ...
    // 10 x no

    var html = '<div class="table-multiplication">';

    var i, j, n = 10, css;
    for(i=0;i<n;i++) {
        j = i + 1;
        css = j +  'x' + table;
        html += '<div class="multiplication-et-de">';
        html +=     '<a href="#" class="chiffres ' + css + '">' + j +  ' x ' + table + '</a>';
        html +=     '<div class="de-outer-background">' + getHtmlUnDeAvecParametre( table, j, cssClass )+ '</div>';
        html += '</div>';
    }

    html += '</div>';

    return html;
}

function getTableMultiplicationAvecDes( no ) {

    // 1  x no
    // 2  x no
    // ...
    // 10 x no

    var html = '<div class="table-multiplication">';

    var i, n = 10;
    for(i=0;i<n;i++) {
        html += getHtmlDeuxDes( no , i + 1);
    }

    html += '</div>';

    return html;
}


//
// Player son (via Howler)
//

function getSoundPath( filename ) {
    return  "../../../assets/sons/" + filename + ".mp3";
}

function getSoundPath_Consigne( filename, newpath, path ) {
    if (newpath) {
        return  "../../../assets/sons/consignes/" + path + "/" + filename + ".mp3";
    } else {
        return  "../../../assets/sons/consignes/" + filename + ".mp3";
    }
}

function getSound_Felicitations() {
    const global_path =  "../../../assets/sons/consignes/validations/";
    const felicitations = [
        "bravo",
        "c-est-bien",
        "bonne-reponse",
        "bravo-tu-as-reussi",
        "tres-bien",
        "felicitations",
        "bravo-bonne-reponse",
    ];
    const no = randomIntFromInterval(0, felicitations.length - 1);
    return global_path + felicitations[no] + ".mp3";
}

function getSound_ValidationSlot(result, nombre, colonne) {
    
    const global_path =  "../../../assets/sons/consignes/";
    let file_path;
    let consigne;
    let path;

    switch(result) {

      case "error":
        file_path = "tu-dois-ecrire";
        consigne = "mauvaise-reponse--" + file_path;

        path =  global_path + file_path + "/" + consigne + "-" + nombre + ".mp3";

        break;
      
      case "win":
        consigne = file_path = "tu-as-ecrit";
        //consigne = "tu-as-ecrit";

        if (colonne === "unites")
            path = global_path + file_path + "/" + "sans-colonne" + "/" + consigne + "-" + nombre + ".mp3";
        else
            path = global_path + file_path + "/" + "colonne-des-" + colonne + "/" + consigne + "-" + nombre + "-dans-la-colonne-des-" + colonne + ".mp3";


        break;
    }

    return path;

}
 

function getSoundPath_Multiplication( table, multiplicateur ) {
    return  "../../../assets/sons/multiplication/" + table + "/" + multiplicateur + 'x' + table + ".mp3";
}

function getSoundPath_Chiffre( chiffre, feminin = false ) {
    // console.log("getSoundPath_Chiffre", chiffre);
    if (isNaN(chiffre)) return false;
    if (chiffre < 1000) {
        if (feminin) {
            const decomposition = getDizainesUnites(chiffre);
            if ((chiffre === 1) || (decomposition.unites === 1 && [2, 3, 4, 5, 6, 8].indexOf(decomposition.dizaines) !== -1)) {
                return  "../../../assets/sons/chiffres_feminin/" + chiffre + ".mp3";
            } else {
                return  "../../../assets/sons/chiffres/" + chiffre + ".mp3";
            }
        } else {
            return  "../../../assets/sons/chiffres/" + chiffre + ".mp3";
        }
    } else {
        const milliers = Math.floor(chiffre/1000) * 1000;
        const reste = chiffre - milliers;
        if (reste > 0) {
            return [
                "../../../assets/sons/chiffres_milliers/" + milliers + ".mp3",
                getSoundPath_Chiffre( reste, feminin )
            ]
        } else {
            return  "../../../assets/sons/chiffres_milliers/" + milliers + ".mp3";
        }
    }
}

function getSoundPath_Chiffre_AsArray( chiffre, feminin = false ) {
    const soundPath = getSoundPath_Chiffre(chiffre, feminin);
    return Array.isArray(soundPath) ? soundPath : [soundPath];
}

function getSoundPath_CentaineMillier( chiffre ) {
    return  "../../../assets/sons/decomposition/centaines-milliers/" + chiffre + "-centaine-de-milliers" + ".mp3";
}

function getSoundPath_DizaineMillier( chiffre ) {
    return  "../../../assets/sons/decomposition/dizaines-milliers/" + chiffre + "-dizaine-de-milliers" + ".mp3";
}

function getSoundPath_Millier( chiffre ) {
    return  "../../../assets/sons/decomposition/milliers/" + chiffre + "-milliers" + ".mp3";
}

function getSoundPath_Centaine( chiffre ) {
    return  "../../../assets/sons/decomposition/centaines/" + chiffre + "-centaines" + ".mp3";
}

function getSoundPath_Dizaine( chiffre ) {
    return  "../../../assets/sons/decomposition/dizaines/" + chiffre + "-dizaines" + ".mp3";
}

function getSoundPath_Unite( chiffre ) {
    return  "../../../assets/sons/decomposition/unites/" + chiffre + "-unites" + ".mp3";
}

function getSoundPath_Colonne( chiffre, colonne = "unites" ) {
    switch(colonne) {
        case "unites":
            return getSoundPath_Unite(chiffre);
        case "dizaines":
            return getSoundPath_Dizaine(chiffre);
        case "centaines":
            return getSoundPath_Centaine(chiffre);
        case "milliers":
            return getSoundPath_Millier(chiffre);
        case "dizaines-de-milliers":
            return getSoundPath_DizaineMillier(chiffre);
        case "centaines-de-milliers":
            return getSoundPath_CentaineMillier(chiffre);
    }
    return getSoundPath_Chiffre(chiffre);
}


function playSound( url, complete ) {
    if (! url) return;

    stopAllSounds();

    // v1
    if ( $('.sound-state').hasClass('off') ) return;

    // Cf : https://github.com/goldfire/howler.js#documentation

    if (window.sound) {
        window.sound.unload();
    }

    let sound;

    if ( typeof url === "string" ) {

        // Son unique + callback

        sound = window.sound = new Howl({
            src: [ url ],
            html5: true,
        });

        sound.once('loaderror', function(){
            console.log('erreur de chargement du son', url);
        });

        sound.once('load', function(){
            sound.play();
        });

        sound.once('end', function(){
            sound.unload();
            if (complete) complete();
        });

    }
    else if (Array.isArray(url) && url.length)
    {
        //
        // Série de sons
        //

        const urls = url;
        const n = urls.length;

        let i, howls = [];
        for(i=0; i<n; i++)
        {
            howls.push( new Howl({
                src: [ urls[i] ],
                html5: true,
            }));
        }

        // Premier son :
        window.sound = sound = howls[0];
        sound.play();

        for(i=0; i<n-1; i++) {
            // A la fin d'un son, on appelle le son suivant :
            howls[i].once('end',
                (function(i) {
                    return function() {
                        window.sound = howls[i + 1];
                        howls[i + 1].play();
                    }
                }(i))
            );
        }

        // Dernier son : appel du callback éventuel
        howls[i].once('end', function() {
            for(i=0; i<n; i++)  {
                howls[i].unload();
            }
            if (complete) complete();
        })
    }
}

function playSound_Decomposition( chiffre, complete ) {
    const decomposition = getDizainesUnites(chiffre);
    const soundPaths = [];
    if (chiffre >= 100000) {
        soundPaths.push(getSoundPath_CentaineMillier(decomposition.centaines_milliers));
    }
    if (chiffre >= 10000) {
        soundPaths.push(getSoundPath_DizaineMillier(decomposition.dizaines_milliers));
    }
    if (chiffre >= 1000) {
        soundPaths.push(getSoundPath_Millier(decomposition.milliers));
    }
    if (chiffre >= 100) {
        soundPaths.push(getSoundPath_Centaine(decomposition.centaines));
    }
    if (chiffre >= 10) {
        soundPaths.push(getSoundPath_Dizaine(decomposition.dizaines));
    }
    soundPaths.push(getSoundPath_Unite(decomposition.unites));
    playSound(soundPaths, complete);
}


function stopAllSounds() {
    Howler.unload();
}

function stopAllAnimations() {
    gsap.globalTimeline.getChildren().forEach(t => t.kill());
}


//
// Fonctions utiles
//

function log( message ) {
    console.log( message );
}

function randomIntFromInterval(min,max) {
    return Math.floor(Math.random()*( max - min + 1) + min );
}

function getDizainesUnites( chiffre ) {

   let reste;

   var millions = Math.floor(chiffre / 1000000);
   reste = chiffre - millions * 1000000;

   var centaines_milliers = Math.floor(reste / 100000);
   reste = reste - centaines_milliers * 100000;

   var dizaines_milliers = Math.floor(reste / 10000);
   reste = reste - dizaines_milliers * 10000;

   var milliers = Math.floor(reste / 1000);
   reste = reste - milliers * 1000;

   var centaines = Math.floor(reste / 100);
   reste = reste - centaines * 100;

   var dizaines = Math.floor(reste / 10);
   var unites = reste - dizaines * 10;

    return { millions, centaines_milliers, dizaines_milliers, milliers, centaines, dizaines, unites };
}

function shuffleArray (p_array)
{
    const n = p_array.length;
    let i = n;
    let temp;
    let p;

    while (i--) {
        p = Math.floor(Math.random()*n);
        temp = p_array[i];
        p_array[i] = p_array[p];
        p_array[p] = temp;
    }
    return p_array;
}

function parseIntArray (p_array)
{
    const n = p_array.length;
    let i, value;

    for(i=0; i<n; i++) {
        value = p_array[i];
        if (isNaN(value)) {
            p_array[i] = 0;
        } else {
            p_array[i] = parseInt(p_array[i]);
        }
    }

    return p_array;
}


const chiffresEnLettres = [
    "zéro", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf",
    "dix", "onze", "douze", "treize", "quatorze", "quinze", "seize"
]

const autresChiffresEnLettres = [
    "vingt", "vingts", "trente", "quarante", "cinquante", "soixante", "cent", "cents", "mille", "et"
]

function getEtiquettesPourChiffres(autresChiffresEnLettres) {
    // On retire le zéro, et on combine les deux tableaux précédents :
    return chiffresEnLettres.slice(1).concat(autresChiffresEnLettres);
}

function getLettresEnChiffres(chiffreEnLettres) {
    const index = chiffresEnLettres.indexOf(chiffreEnLettres);
    if (index !== -1) {
        return index;
    } else {
        const index2 = autresChiffresEnLettres.indexOf(chiffreEnLettres);
        if (index2 !== -1) {
            return [20, 20, 30, 40, 50, 60, 100, 100, 1000, -10 ][index2];
        } else {
            return -1;
        }
    }
}

function getChiffreEnLettres(chiffre) {
    if (chiffre < 17) {
        return chiffresEnLettres[chiffre];
    } else if (chiffre < 20) {
        return "dix-" + chiffresEnLettres[chiffre - 10];
    } else if (chiffre === 20) {
        return "vingt";
    } else if (chiffre === 21) {
        return "vingt-et-un";
    } else if (chiffre < 30) {
        return "vingt-" + chiffresEnLettres[chiffre - 20];
    } else if (chiffre === 30) {
        return "trente";
    } else if (chiffre === 31) {
        return "trente-et-un";
    } else if (chiffre < 40) {
        return "trente-" + chiffresEnLettres[chiffre - 30];
    } else if (chiffre === 40) {
        return "quarante";
    } else if (chiffre === 41) {
        return "quarante-et-un";
    } else if (chiffre < 50) {
        return "quarante-" + chiffresEnLettres[chiffre - 40];
    } else if (chiffre === 50) {
        return "cinquante";
    } else if (chiffre === 51) {
        return "cinquante-et-un";
    } else if (chiffre < 60) {
        return "cinquante-" + chiffresEnLettres[chiffre - 50];
    } else if (chiffre === 60) {
        return "soixante";
    } else if (chiffre === 61) {
        return "soixante-et-un";
    } else if (chiffre < 70) {
        return "soixante-" + chiffresEnLettres[chiffre - 60];
    } else if (chiffre === 71) {
        return "soixante-et-onze";
    } else if (chiffre < 80) {
        return "soixante-" + getChiffreEnLettres(chiffre - 60);
    } else if (chiffre === 80) {
        return "quatre-vingts";
    } else if (chiffre < 100) {
        return "quatre-vingt-" + getChiffreEnLettres(chiffre - 80);
    } else if (chiffre === 100) {
        return "cent";
    } else if (chiffre < 1000) {
        const nbCentaines = Math.floor(chiffre/100);
        const reste = chiffre - nbCentaines * 100;
        if ( nbCentaines === 1 ) {
            return "cent " + getChiffreEnLettres(chiffre - 100);
        } else if ( nbCentaines * 100 === chiffre ) {
            return getChiffreEnLettres(nbCentaines) + " cents";
        } else {
            return getChiffreEnLettres(nbCentaines) + " cent " + getChiffreEnLettres(reste);
        }
    } else if (chiffre === 1000) {
        return "mille";
    } else if (chiffre < 1000000) {
        const nbMilliers = Math.floor(chiffre/1000);
        const reste = chiffre - nbMilliers * 1000;
        if ( nbMilliers === 1 ) {
            return "mille " + getChiffreEnLettres(chiffre - 1000);
        } else if ( nbMilliers * 1000 === chiffre ) {
            return getChiffreEnLettres(nbMilliers) + " mille";
        } else {
            return getChiffreEnLettres(nbMilliers) + " mille " + getChiffreEnLettres(reste);
        }
    } else {
        return "indéfini";
    }
}



//
// Séquence animée (via jQuery Deferred)
//

function playSequence( actions ) {

    var deferred = $.Deferred();
    var r = deferred;
    var j, m = actions.length;
    var timelineMax;
    var deferredTimeout;

    // On génère autant de callbacks qu'il n'y a d'action
    // A chaque action, on passe le tableau d'action

    // Chaque exécution synchrone/asynchrone dépile le tableau des actions
    // jusqu'à épuiser le tableau

    for(j=0; j<m; j++) {

        // actionObj = actions[j];
        // actionName = actionObj.action;
        // actionParam = actionObj.param;

        r = r.then(function( remainingActions ) {

            var deferredAction = new $.Deferred();

            if (remainingActions) {
                // console.log("playSequence", remainingActions.length);
            }

            if (! remainingActions || remainingActions.length === 0) {

                // Suite à l'interruption de la séquence :

                if (timelineMax) timelineMax.kill();

                if (deferredTimeout) clearTimeout(deferredTimeout);
                deferredAction.resolve( remainingActions );

                return;
            }

            var actionObj = remainingActions.shift();
            var actionName = actionObj.action;
            var actionParam = actionObj.param;

            var url, element, className, left, top, duration, zindex;

            switch ( actionName ) {

                case "wait" :

                    var delay = isNaN(actionParam) ? 1 : parseInt(actionParam);

                    // log( "Attente de " + delay + " " + (delay > 1 ? "secondes" : "seconde") + "  ..." );

                    if (deferredTimeout) clearTimeout(deferredTimeout);

                    deferredTimeout = setTimeout(function () {
                        // log( "Fin de l'attente.");
                        deferredAction.resolve( remainingActions );
                        return deferredAction;
                    }, delay * 1000);

                    break;

                case "playSound":

                    url = actionParam;

                    playSound(url, function(){
                        // log( "Fin du son.");
                        deferredAction.resolve( remainingActions );
                        return deferredAction;
                    });

                    break;

                case "hilite":

                    element = actionParam;
                    element.css("opacity", 0.5);

                    // log( "hilite", element);

                    deferredAction.resolve( remainingActions );
                    return deferredAction;

                case "unhilite":

                    element = actionParam;
                    element.css("opacity", 1);

                    // log( "unhilite", element);

                    deferredAction.resolve( actions );
                    return deferredAction;

                case "addClass":

                    element = actionParam.element;
                    className = actionParam.className;
                    element.addClass(className);

                    deferredAction.resolve( remainingActions );
                    return deferredAction;
                
                // AUDREY ADD VUE CLASS
                case "addVueClass":
                    
                    element = actionParam.element;
                    element.value = true;
                    deferredAction.resolve( remainingActions );
                    return deferredAction;

                case "setText":

                    element = actionParam.element;
                    var text = actionParam.text;
                    element.text(text);

                    deferredAction.resolve( remainingActions );
                    return deferredAction;

                case "removeClass":

                    element = actionParam.element;
                    className = actionParam.className;
                    element.removeClass(className);

                    deferredAction.resolve( remainingActions );
                    return deferredAction;

                case "setElement":

                    element = actionParam.element;
                    left = actionParam.left;
                    top  = actionParam.top;

                    TweenMax.set(element, {left:left, top:top });

                    deferredAction.resolve( actions );
                    return deferredAction;

               case "moveElement":

                    element = actionParam.element;

                    if (actionParam.target) {
                        var offset = actionParam.target.offset();
                        left = offset.left;
                        top = offset.top;
                        // console.log("left", left, "top", top);
                    } else {
                        left = actionParam.left;
                        top  = actionParam.top;
                    }

                   if (actionParam.targetOffset) {
                       left += actionParam.targetOffset.left;
                       top += actionParam.targetOffset.top;
                   }

                   duration = actionParam.duration;

                   if (duration === 0) {

                       TweenMax.set(element,{ left:left, top:top });

                       deferredAction.resolve( actions );
                       return deferredAction;

                   } else {

                       timelineMax = new TimelineMax({
                            onComplete: function() {
                                deferredAction.resolve( actions );
                                return deferredAction;
                            }
                        });
                   }

                   timelineMax.add( TweenMax.to(element, 0.75, {left:left, top:top }) );

                   break;

               case "transformElement":

                    element = actionParam.element;
                    var x = 0, y = 0;

                    if (actionParam.x) {
                        x = actionParam.x;
                    } else if (actionParam.toX) {
                        x = actionParam.toX - element.offset().left;
                    }

                    if (actionParam.y) {
                       y = actionParam.y;
                    } else if (actionParam.toY) {
                       y = actionParam.toY - element.offset().top;
                    }

                    zindex = actionParam.zindex;
                    duration = actionParam.duration;

                    var onTransformComplete = actionParam.onComplete;

                    if (duration === 0) {

                        TweenMax.set(element,{x:x, y:y });

                        deferredAction.resolve( actions );
                        return deferredAction;

                    } else {

                        element.css("z-index", zindex);

                        timelineMax = new TimelineMax({
                            onComplete: function() {
                                if (onTransformComplete) {
                                    onTransformComplete( element );
                                }
                                deferredAction.resolve( actions );
                                return deferredAction;
                            }
                        });

                        timelineMax.add( TweenMax.to(element, 0.75, {x:x, y:y }) );

                    }

                   break;

               case "fadeOutElement":

                   element = actionParam.element;

                   timelineMax = new TimelineMax({
                        onComplete: function() {
                            deferredAction.resolve( actions );
                            return deferredAction;
                        }
                    });

                   timelineMax.add( TweenMax.to(element, 0.75, { opacity: 0 }) );

                   break;

               case "fadeInElement":

                   element = actionParam.element;

                   timelineMax = new TimelineMax({
                        onComplete: function() {
                            deferredAction.resolve( actions );
                            return deferredAction;
                        }
                    });

                   timelineMax.add( TweenMax.to(element, 0.75, { opacity: 1 }) );

                   break;

               case "callback":

                    var callback = actionParam;

                    if (typeof(callback) === "function") {
                        callback();
                    }

                    deferredAction.resolve( actions );
                    return deferredAction;

               case "reparentElement":

                    element = actionParam.element;
                    var newParent = actionParam.parent;

                    // var elementOffset = element.offset();
                   // var parentOffset = newParent.offset();

                    newParent.append(element);

                    deferredAction.resolve( actions );
                    return deferredAction;

            }

            return deferredAction;

        });
    }

    // Lancement de la pile d'actions
    deferred.resolve( actions );

    return deferred;
}

function stopSequence( actions ) {
    if (actions) actions.length = 0;
}


//
// Génération PDF (via jsPDF)
//

function getPDF( orientation ) {

    var doc = new jsPDF( { orientation: orientation });
    doc.setFont('Quicksand-Bold', 'bold');
    doc.setFontSize(40);

    return doc;
}

function getSVGSize( svgFileContent ) {
    var svgObject = svgFileContent instanceof jQuery ? svgFileContent : $( svgFileContent.childNodes[0] );
    return { width: svgObject.attr("width"), height : svgObject.attr("height") };
}

function addSVGToPDF( jsPdf, svgFileContent, x, y, alias, withStroke, scale ) {

    var svgObject = svgFileContent instanceof jQuery ? svgFileContent : $( svgFileContent.childNodes[0] );

    if (isNaN(scale)) {
        scale = 1.0;
    }

    if (withStroke === true) {
        var rect = svgObject.find('rect');
        rect.attr("stroke", "rgba(0,0,0,0.25)");
    }

    var svgWidth = svgObject.attr("width");
    var svgHeight = svgObject.attr("height");

    var newSvg = new XMLSerializer().serializeToString(svgObject[0]);

    var resolution = 4 / scale;
    var imageWidth = svgWidth/resolution;
    var imageHeight = svgHeight/resolution;

    jsPdf.addSvgAsImage(newSvg, x, y, imageWidth, imageHeight, alias, 'SLOW', 0, resolution);
}

function getURLFromBackgroundImageStyle( item ) {
    console.log(item)
    var url = item.css('background-image');
    return url.replace('url(','').replace(')','').replace(/"/gi, "");

}

function emptyAjaxResponse(response) {
    var deferred = $.Deferred().resolve(response);
    return deferred.promise();
}

function addSVGKeyboardToPDF( jsPdf, svgCadre, x, y, rowLength ) {

    jsPdf.setFontSize(25);
    jsPdf.setTextColor('#000000');

    var alias = "bgChiffre";
    var topChiffres_1 = y;
    var topChiffres_2 = y + 25;
    var topChiffres_3 = y + 50;
    var topChiffres_4 = y + 75;

    var offsetChiffreCadreClavierX = 6;
    var offsetChiffreCadreClavierY = 14;

    var scale = 0.9;

    var x1, x2, x3, x4, x5, x6, x7, x8, x9, x0;
    var y1, y2, y3, y4, y5, y6, y7, y8, y9, y0;

    if ( rowLength === 4 ) {

        x0 = x5 = x;
        x1 = x6 = x + 20;
        x2 = x7 = x + 40;
        x3 = x8 = x + 60;
        x4 = x9 = x + 80;

        y0 = y1 = y2 = y3 = y4 = topChiffres_1;
        y5 = y6 = y7 = y8 = y9 = topChiffres_2;

    } else {

        x1 = x4 = x7 = x;
        x2 = x5 = x8 = x0 = x + 20;
        x3 = x6 = x9 = x + 40;

        y1 = y2 = y3 = topChiffres_1;
        y4 = y5 = y6 = topChiffres_2;
        y7 = y8 = y9 = topChiffres_3;
        y0 = topChiffres_4;

    }

    addSVGToPDF( jsPdf, svgCadre, x0, y0, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x1, y1, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x2, y2, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x3, y3, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x4, y4, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x5, y5, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x6, y6, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x7, y7, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x8, y8, alias, false, scale);
    addSVGToPDF( jsPdf, svgCadre, x9, y9, alias, false, scale);

    jsPdf.text( x0 + offsetChiffreCadreClavierX, y0 + offsetChiffreCadreClavierY, "0" );
    jsPdf.text( x1 + offsetChiffreCadreClavierX, y1 + offsetChiffreCadreClavierY, "1" );
    jsPdf.text( x2 + offsetChiffreCadreClavierX, y2 + offsetChiffreCadreClavierY, "2" );
    jsPdf.text( x3 + offsetChiffreCadreClavierX, y3 + offsetChiffreCadreClavierY, "3" );
    jsPdf.text( x4 + offsetChiffreCadreClavierX, y4 + offsetChiffreCadreClavierY, "4" );
    jsPdf.text( x5 + offsetChiffreCadreClavierX, y5 + offsetChiffreCadreClavierY, "5" );
    jsPdf.text( x6 + offsetChiffreCadreClavierX, y6 + offsetChiffreCadreClavierY, "6" );
    jsPdf.text( x7 + offsetChiffreCadreClavierX, y7 + offsetChiffreCadreClavierY, "7" );
    jsPdf.text( x8 + offsetChiffreCadreClavierX, y8 + offsetChiffreCadreClavierY, "8" );
    jsPdf.text( x9 + offsetChiffreCadreClavierX, y9 + offsetChiffreCadreClavierY, "9" );

}

export {
    getHtmlUnDe,
    getHtmlUnDeAvecParametre,
    getHtmlDeuxDes,
    getHTMLNombre,
    getHTMLDizainesUnites,
    getHtmlCartoucheNombre,
    getTableMultiplication,
    getTableMultiplicationAvecDes,
    getSoundPath,
    getSound_Felicitations,
    getSound_ValidationSlot,
    getSoundPath_Consigne,
    getSoundPath_Multiplication,
    getSoundPath_Chiffre,
    getSoundPath_Chiffre_AsArray,
    getSoundPath_CentaineMillier,
    getSoundPath_DizaineMillier,
    getSoundPath_Millier,
    getSoundPath_Centaine,
    getSoundPath_Dizaine,
    getSoundPath_Unite,
    getSoundPath_Colonne,
    playSound,
    playSound_Decomposition,
    stopAllSounds,
    stopAllAnimations,
    log,
    randomIntFromInterval,
    getDizainesUnites,
    shuffleArray,
    parseIntArray,
    getChiffreEnLettres,
    getLettresEnChiffres,
    getEtiquettesPourChiffres,
    playSequence,
    stopSequence,
    getPDF,
    getSVGSize,
    addSVGToPDF,
    getURLFromBackgroundImageStyle,
    emptyAjaxResponse,
    addSVGKeyboardToPDF
}