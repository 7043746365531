<template>

    <div class="nombre" :data-value="chiffre">
      <div v-if="chiffre > 99999" class="centaines-milliers">
        {{ v_centaines_milliers }}
      </div>
      <div v-if="chiffre > 9999" class="dizaines-milliers">
        {{ v_dizaines_milliers }}
      </div>
      <div v-if="chiffre > 999" class="milliers">
        {{ v_milliers }}
      </div>
      <div v-if="chiffre > 99" class="centaines">
        {{ v_centaines }}
      </div>
      <div v-if="chiffre > 9" class="dizaines">
        {{ v_dizaines }}
      </div>
      <div class="unites">
        {{ v_unites }}
      </div>
    </div>

</template>


<script setup>

  import {ref, onMounted, watch} from "vue";
  import { getDizainesUnites } from "../../js/utils";

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 1
    },
    hasSeparator: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  let centaines_milliers = ref(0),
      dizaines_milliers = ref(0),
      milliers = ref(0),
      centaines = ref(0),
      dizaines = ref(0),
      unites = ref(0);

  let v_centaines_milliers = ref(''),
      v_dizaines_milliers = ref(''),
      v_milliers = ref(''),
      v_centaines = ref(''),
      v_dizaines = ref(''),
      v_unites = ref('');

  let withSeparator = ref();

  const update = function() {

    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites(chiffre);

    // Décomposition
    centaines_milliers.value = dizainesUnites.centaines_milliers;
    dizaines_milliers.value = dizainesUnites.dizaines_milliers;
    milliers.value = dizainesUnites.milliers;
    centaines.value = dizainesUnites.centaines;
    dizaines.value = dizainesUnites.dizaines;
    unites.value = dizainesUnites.unites;

    v_centaines_milliers.value = chiffre < 100000 && centaines_milliers.value === 0 ? '' : centaines_milliers.value;
    v_dizaines_milliers.value = chiffre < 10000 && dizaines_milliers.value === 0 ? '' : dizaines_milliers.value;
    v_milliers.value = chiffre < 1000 && milliers.value === 0 ? '' : milliers.value;
    v_centaines.value = chiffre < 100 && centaines.value === 0 ? '' : centaines.value;
    v_dizaines.value = chiffre < 10 && dizaines.value === 0 ? '' : dizaines.value;
    v_unites.value = unites.value;

    // Séparateur de milliers ?
    withSeparator.value = props.hasSeparator === true;
  };

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });

</script>

<style scoped lang="scss">

  .nombre {

    display: flex;
    justify-content: center;

    & > div {
      font-size: 28px;
    }

    .milliers,
    .unites {
      color:  rgba(44, 133, 237, 1); // #2C85ED;
    }

    .milliers {
      margin-right: 10px;
    }

    .milliers:first-child {
      color:  rgba(0, 0, 0, 1);
    }

    .dizaines-milliers,
    .dizaines {
      color: rgba(255, 63, 51, 1); // #FF3F33;
    }

    .centaines-milliers,
    .centaines {
      color: rgba(88, 206, 69, 1); // #FF3F33;
    }
  }

</style>
