<template>
  <a class="svg-icon manual-input-link saisie-manuelle" href="#"></a>
</template>

<script>
export default {
  name: 'ManualInputsBtn',
}
</script>

<style scoped lang="scss">

  a.svg-icon.manual-input-link {
    background-image: url(../../../assets/images/svg/icones/manual-input.svg);
  }

</style>
