<template>
    <span class="signe"  @click="playOperation">
      <span class="rotation">+</span>
    </span>
</template>


<script setup>

  import {getSoundPath, playSound} from "../../../js/utils";

  const playOperation = function(){
        var soundPath = getSoundPath("multiplié-par");
        playSound(soundPath);
  }

</script>


<style scoped lang="scss">

.signe {
  text-shadow: -3px 3px 0px #333;

  .rotation {
    transform: translateX(-8px) rotate(-45deg);
    transform-origin: 50% 50%;
  }
}

</style>
