<template>

    <ul class="niveaux">
        <li v-for="niveau in props.niveaux" :key="niveau">
            <a class="niveau" :class= "{courant:niveau.isSelected}" :data-level="niveau.dataLevel" href="#" @click="changeLevel($event, niveau, props.niveaux)">{{ niveau.title}}</a>
        </li>
    </ul>

</template>

<script setup>

    const props = defineProps(
        { 
            niveaux : { 
                type:Array, 
                required: false, 
                default: () => [{ dataLevel: '0', title: "title", isSelected: true }] 
            }
        }
    )

    const emit = defineEmits(['change-level'])

    const changeLevel = function($event, niveau, niveaux) {
        $event.preventDefault();

        var curniveau = niveau;
        
        for (niveau of niveaux) {
            if (niveau != curniveau ) 
                niveau.isSelected = false; 
            else {
                niveau.isSelected = true;
                emit( 'change-level', niveau.dataLevel);
            }
                
        }
    }
    
</script>


<!--   STYLE CSS   -->
<style scoped lang="scss">
  ul.niveaux {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 20px 0;
  }

  .niveaux li {
    float: left;
    list-style-type: none;
    margin-right: 20px;
  }

  .niveaux .niveau {
    background: #FFFFFF;
    color: blue;
    border: 1px solid #15299C;
    box-sizing: border-box;
    border-radius: 16px;
    text-decoration: none;
    padding: 5px 14px;
    box-shadow: 0 0 10px rgba(21, 41, 156, 0.3);
  }

  .niveaux .niveau.courant {
    color: #FFFFFF;
    background: #15299C;
  }

  @media screen and (max-width: 1080px ) {

    /* iPad */

    .niveaux li {
      margin-right: 10px;
    }
  }

</style>