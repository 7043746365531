<template>
  <div class="content content-v2">
    <HeaderJeux
        @replay="replay"
    />
    <Frogger :niveau="niveau" />
    <ResponsiveWarning />
  </div>

</template>

<script setup>

  import HeaderJeux from "../../../components/v2/Headers/HeaderJeux";
  import Frogger from "../../../components/v2/Jeux/Frogger";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  import {computed} from "vue";
  import {useRoute} from "vue-router";

  const route = useRoute();
  const niveau = computed(() => parseInt(route.params.niveau) );

</script>