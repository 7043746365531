<template>

  <div class="dictee-vocale-v2" >

    <div class="sound-player-et-chiffres-a-trouver">

      <Slots
          ref="slots"
          :chiffre="chiffreSlot"
          :isHidden="true"
          :has-header="true"
          :has-validation="withValidation"
          @change="slotChanged"
      />

      <EcouterBtn @playSound="playSound_CurrentNumber" />

    </div>

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne_CurrentNumber"/>

    <ClavierChiffres :nb_touches_par_ligne="10" class="clavier" />

    <HelpPanel
        class="helper-panel"
        :panel-type="helperPanelType"
        :chiffre="chiffreSlot"
        :chiffre-mis-en-valeur="withValidation"
        :minimum="1"
        :maximum="9"
    />

  </div>

</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
  import {useStore} from "vuex";

  import $ from 'jquery'
  import _ from 'lodash';
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    getSound_ValidationSlot,
    playSound,
    randomIntFromInterval, getSound_Felicitations,
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Slots from "../Slots";
  import EcouterBtn from "../Boutons/EcouterBtn";
  import HelpPanel from "../HelpPanel/HelpPanel";
  import Ada from "../../../components/v2/Ada";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 99;

  let draggables;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Référence du composant Slots :
  const slots = ref(null);

  // Valeur affichée dans ce composant
  let chiffreSlot = ref(123456);

  // Panneau d'aide :
  const helperPanelType = computed(() => {
    if (niveauActivite.value === 1 || chiffreSlot.value < 50) {
      return "dizaines-unites-un";
    } else if (niveauActivite.value < 3 || chiffreSlot.value < 100) {
      return"dizaines-unites";
    } else {
      return"milliers-dizaines-unites";
    }
  })


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }



  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch (niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 50;
        successMax.value = 5;
        break;

      case 2:
        currentMinimumNumber = 50;
        currentMaximumNumber = 99;
        successMax.value = 5;
        break;

      case 3:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        successMax.value = 5;
        break;

      case 4:
        currentMinimumNumber = 1000;
        currentMaximumNumber = 9999;
        successMax.value = 5;
        break;

      case 5:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        successMax.value = 5;
        break;

      case 6:
        currentMinimumNumber = 100000;
        currentMaximumNumber = 999999;
        successMax.value = 5;
       break;
    }

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 50) {
      return 2;
    } else if (maxValue <= 100) {
      return 3;
    } else if (maxValue <= 1000) {
      return 4;
    } else if (maxValue <= 10000) {
      return 5;
    } else if (maxValue <= 100000) {
      return 6;
    } else {
      return 7;
    }
  }



  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffre, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    if ((chiffre === null) || (chiffre === undefined))
    {
      // Tirage au sort des chiffres
      chiffre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
    }
    else
    {
      // Saisie manuelle
    }

    // Chiffre à trouver :
    chiffreSlot.value = chiffre;
    // console.log("----> chiffreSlot", chiffre);

    if (avecConsigne) {
      // Son de la consigne, puis du chiffre
      let intro = true;
      playSound_Consigne_CurrentNumber(intro);
    } else {
      playSound_CurrentNumber();
    }

    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    var dropZones = $(".slot-zone");
    var overlapThreshold = "50%";
    var isDragging = false;
    var selectedDraggable;

    const $target = $(".draggable", '.clavier').not('.draggable-initialized');
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create( $target, {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true,
      onPress: function (pointEvent) {

        removeDraggedStyle();

        // On ajoute l'effet au dé déplacé
        var draggableElement = $(pointEvent.target).closest(".draggable");
        draggableElement.find('.with-dragged-style').addClass("dragged"); // Effet sur la carte ou le dé

        selectedDraggable = draggableElement;
      },
      onDragStart: function (pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre = draggableElement.find('.with-value').data('value');
          var soundPath = getSoundPath_Chiffre(nombre);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function (pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        var dropZones = $(".slot-zone");

        setTimeout(function () {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre = parseInt( draggableElement.find('.with-value').data('value') );

        var i = dropZones.length;
        var dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            // Vérification :
            checkZone(dropZone, nombre);
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, {x: 0, y: 0, delay: 0.1});

        isDragging = false;
      },
      onDrag: function () {
        var i = dropZones.length, dropZone;
        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    dropZones.on('click', function () {

      if (!isDragging && selectedDraggable) {
        $('.draggable').removeClass("dragged");

        var nombre = selectedDraggable.data('value');
        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre);
      }
    })
  }

  function removeDragAndDrop() {

    const $target = $(".draggable", '.clavier')
    $target.removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  function checkZone( dropZone, nombre ) {

    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );
    const dropZoneSoundPath = dropZone.attr("data-decomposition");

    // console.log("checkZone", dropZoneNo, '-->', dropZoneCorrectValue, 'dropped', nombre);
    // console.log("dropZoneSoundPath --> ", dropZoneSoundPath);

    const composantSlots = slots.value;

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      const composantSlotComplete = composantSlots.isComponentComplete();
      const composantSlotCorrect = composantSlots.isComponentCorrect();

      if (composantSlotComplete && composantSlotCorrect)
      {
        // Tous les slots sont bien remmplis : lit le nombre

        // Son de conclusion, succès et replay
        playSoundConclusionAndReplay();
      }
      else
      {
        // Le dernier slot rempli est correct
        playSoundValidation("win", nombre, dropZoneSoundPath);
        updateAda("normal");
      }
    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      updateAda("error", 3000);
      playSoundValidation("error", dropZoneCorrectValue, dropZoneSoundPath, dropZoneNo);
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => { replay() }, 1000);
    }
  }


  //
  // Statistiques
  //

  const slotChanged = function($event) {
    // console.log('slotChanged', $event);

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: 'Retrouvez le chiffre suivant',
        solution: $event.chiffre,
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  // Bouton Ré-écouter
  const playSound_CurrentNumber = function() {
    if (props.withSound) {
      var soundPath = getSoundPath_Chiffre(chiffreSlot.value);
      playSound(soundPath);
    }
  };

  const playSound_Consigne_CurrentNumber = function(intro) {
    if (props.withSound === true) {
      // console.log("playSound_Consigne_CurrentNumber", props.withSound)
      const soundPath1 = getSoundPath_Consigne("consigne-dictee-vocale");
      const soundPath2 = getSoundPath_Consigne("si-tu-veux-entendre-un-autre-nombre-clique", true, "new");

      const chiffre = chiffreSlot.value;
      const soundPath3 = getSoundPath_Chiffre(chiffre);

      // RQ : Cas particuliers : en mode parcours (i.e quand il n'y a pas de validation), on ne propose pas de jouer avec un autre nombre

      let soundPaths;

      if (chiffre < 1000) {
        if (intro === true) {
          soundPaths = props.withValidation ? [soundPath1, soundPath2, soundPath3] : [soundPath1, soundPath3];
          playSound(soundPaths, function(){ updateAda("help") });
        } else {
          soundPaths = props.withValidation ? [soundPath1, soundPath3] : [soundPath1];
          playSound(soundPaths);
        }
      } else {
        if (intro === true) {
          soundPaths = props.withValidation ? [soundPath1, soundPath2, soundPath3] : [soundPath1, soundPath3];
          playSound(soundPaths, function(){ updateAda("help") });
        } else {
          soundPaths = props.withValidation ? [soundPath1, soundPath2] : [soundPath1];
          playSound(soundPaths);
        }
      }
    }
  };

  const playSoundValidation = function(result, nombre, colonne, dropZoneNo) {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPath = getSound_ValidationSlot(result, nombre, colonne);
        const composantSlots = slots.value;

        playSound(soundPath, () => {
          updateAda("normal");
          if (result === "error") {
            composantSlots.setSlotExternalValue(dropZoneNo, null);
            composantSlots.setSlotVisible(dropZoneNo, false);
          }
        });
      }
    }
  }

  const playSoundConclusionAndReplay = function() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        let soundPaths, soundPath = getSoundPath_Chiffre(chiffreSlot.value);
        const soundPathFelicitations = getSound_Felicitations();

        if (Array.isArray(soundPath)) {
          soundPaths = [soundPathFelicitations].concat(soundPath);
        } else {
          soundPaths = [soundPathFelicitations, soundPath];
        }

        playSound(soundPaths, () => addSuccessAndReplay );
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }

</script>


<style scoped lang="scss">

  .dictee-vocale-v2 {

    @include on-tablet {
      padding-top: 50px;
    }

    .sound-player-et-chiffres-a-trouver {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      margin-bottom: 50px;
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      @include on-tablet {
        margin-left: 40px;
      }
    }

    .helper-panel {
      position: fixed;
      right: 0;
      top:0;
      z-index: 3;
    }

  }

</style>
