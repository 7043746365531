<template>
  <div class="content content-v2">
    <HeaderActivite
        :title="titreActivite"
        :niveau="niveau"
        @replay="replay"
    />
    <main class="activite" >
        <JeuDesQuantites v-if="niveau === 1"
           ref="activiteNiveau1"
           :niveau="1"
           :with-sound="soundActive"
           :with-validation="withValidation"
        />
        <JeuDesCouleurs v-else
          ref="activite"
          :niveau="niveau"
          :with-sound="soundActive"
          :with-validation="withValidation"
          :with-free-colors="couleursLibres"
          :with-custom-values="withCustomValues"
          :values="values"
        />
    </main>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v2/Headers/HeaderActivite";
  import JeuDesCouleurs from "../../../components/v2/Activites/JeuDesCouleurs";
  import JeuDesQuantites from "../../../components/v2/Activites/JeuDesQuantites";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  import {computed, onBeforeMount, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import {parseIntArray} from "../../../js/utils";

  const store = useStore();

  const pathActivite = 'jeu-des-couleurs';

  // Settings
  const soundActive = ref();

  // Validation
  const withValidation = ref();

  // Niveau de l'activité
  const route = useRoute();
  const niveau = computed(() => parseInt(route.params.niveau) );

  // Valeurs manuelles
  const values = computed(() => route.query.v === undefined ? "" : parseIntArray(route.query.v.split(',')) );
  const couleursLibres = computed(() => route.query.libre === undefined ? true : route.query.libre === "true" );
  const withCustomValues = computed(() => route.query.v !== undefined );

  // Titre activité :
  const titreActivite = ref('');
  const activiteInfos = store.getters.activiteFromPath(pathActivite);
  titreActivite.value = activiteInfos.titre;

  // Composant de l'activité
  const activiteNiveau1 = ref(null);
  const activite = ref(null);

  function replay() {
    if (niveau.value === 1) {
      activiteNiveau1.value.replay();
    } else {
      activite.value.replay();
    }
  }

  //
  // LifeCycle hooks
  //

  onBeforeMount(() => {
    soundActive.value = store.getters.isSoundActive(pathActivite);
    withValidation.value = route.query.parcours === undefined;
  })

</script>
