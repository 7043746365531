<template>
  <a class="sans-milliers" href="#"></a>
</template>

<script>
</script>

<style scoped lang="scss">

a.sans-milliers {
  display: inline-block;
  width: 133px;
  height: 73px;
  text-decoration: none;
  background: url(../../../assets/images/svg/settings/Parametres_avec_separateur_milliers_off.svg) center / 133px auto no-repeat;

  &.on {
    background-image: url(../../../assets/images/svg/settings/Parametres_avec_separateur_milliers_on.svg);
  }
}

</style>
