<template>

  <div class="ajouter-quantites-manquantes-v2" >

    <div class="sound-player-et-chiffres-a-trouver"  @change="change">

      <div class="slots-and-columns">
        <div class="column">
          <div class="slots-parent">
            <Slots
                ref="slots"
                :chiffre="chiffreSlot"
                :has-header="true"
            />
          </div>
        </div>

        <div class="column">
          <div class="aide-parent">
            <AideQuantitesMilliersUnitesUn
                ref="colonnes"
                :chiffre="chiffreSlot"
                :dizaines-simples="dizainesSimples"
                @change="onChangeEvent"
            />
          </div>
        </div>
      </div>

    </div>

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne_CurrentNumber" />

  </div>

</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed} from "vue";

  import _ from 'lodash';
  import {
    getSoundPath_Centaine,
    getSoundPath_CentaineMillier,
    getSoundPath_Chiffre,
    getSoundPath_Consigne, getSoundPath_Dizaine, getSoundPath_DizaineMillier, getSoundPath_Millier, getSoundPath_Unite,
    playSound,
    playSound_Decomposition,
    randomIntFromInterval,
  } from "../../../js/utils";

  import Slots from "../Slots";
  import AideQuantitesMilliersUnitesUn from "../HelpPanel/AideQuantitesMilliersUnitesUn";
  import Ada from "../Ada";
  import {useStore} from "vuex";
  import {useRoute, useRouter} from "vue-router";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 99;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Référence du composant Slots :
  const slots = ref(null);

  // Référence du composant des colonnes
  const colonnes = ref(null);

  // Valeur affichée dans ce composant
  let chiffreSlot = ref(123456);

  const dizainesSimples = computed(() => {
    return niveauActivite.value === 1;
  });


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  onMounted(() => {
    init();
  });

  watch( props, () => {
    init();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }


  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 10;
        currentMaximumNumber = 50;
        break;

      case 2:
        currentMinimumNumber = 50;
        currentMaximumNumber = 99;
        break;

      case 3:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        break;

      case 4:
        currentMinimumNumber = 1000;
        currentMaximumNumber = 9999;
        break;

      case 5:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        break;

      case 6:
        currentMinimumNumber = 100000;
        currentMaximumNumber = 999999;
        break;
    }

    // Ada
    successMax.value = 3;
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 100) {
      return 2;
    } else if (maxValue <= 1000) {
      return 3;
    } else if (maxValue <= 10000) {
      return 4;
    } else if (maxValue <= 100000) {
      return 5;
    } else {
      return 6;
    }
  }


  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffre, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    if ((chiffre === null) || (chiffre === undefined))
    {
      // Tirage au sort des chiffres
      chiffre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
    }
    else
    {
      // Saisie manuelle
    }

    // Remise à zéro du composant des colonnes (qui gère les colonnes correctes)
    chiffreSlot.value = 0;

    setTimeout(() => {

      // Chiffre à trouver :
      chiffreSlot.value = chiffre;
      // console.log("----> chiffreSlot", chiffre);

      if (avecConsigne) {
        // Son de la consigne, puis du chiffre
        playSound_Consigne_CurrentNumber();
      } else {
        playSound_CurrentNumber();
      }

    })
  }

  const onChangeEvent = function(columnType, columnValue, columnIsCorrect, allIsCorrect, allIsComplete, completeValue, eventType) {
    console.log("change", columnType, "Correct ?", columnIsCorrect, "All is Correct ?", allIsCorrect, eventType);
    if (eventType === "onClick")
    {

      let soundPath = false;
      switch (columnType) {
        case "centaines-milliers":
          soundPath = getSoundPath_CentaineMillier(columnValue);
          break;
        case "dizaines-milliers":
          soundPath = getSoundPath_DizaineMillier(columnValue);
          break;
        case "milliers":
          soundPath = getSoundPath_Millier(columnValue);
          break;
        case "centaines":
          soundPath = getSoundPath_Centaine(columnValue);
          break;
        case "dizaines":
        case "dizaines-simples":
          soundPath = getSoundPath_Dizaine(columnValue);
          break;
        case "unites":
          soundPath = getSoundPath_Unite(columnValue);
          break;
      }

      if (props.withSound && soundPath) {

        playSound(soundPath, function() {

          if (allIsCorrect)
          {
            // On empêche l'interaction de l'activité en cours
            updateAda("lock");

            // "Quand la réponse est bonne, la synthèse vocale indique 32, 3 dizaines, deux unités"
            playSound_Felicitations_and_AddSuccess();
          }

        });
      }
    }

    if (allIsComplete) {
      slotChanged({
        isComplete: true,
        isCorrect: allIsCorrect,
        proposed: completeValue
      });
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }



  //
  // Statistiques
  //

  const slotChanged = function($event) {

    // console.log('slotChanged', $event);

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: "Retrouvez les unités, dizaines, ...",
        solution: chiffreSlot.value,
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  const playSound_CurrentNumber = function( onCompleteFunction ) {
    if (props.withSound)
    {
      var soundPath = getSoundPath_Chiffre(chiffreSlot.value);
      playSound(soundPath, onCompleteFunction);
    }
  };

  const playSound_Consigne_CurrentNumber = function() {
    if (props.withSound)
    {
      const soundPath1 = getSoundPath_Consigne("regarde-bien-le-nombre-clique-sur-les-quantites-correspondantes");
      const soundPath2 = getSoundPath_Chiffre(chiffreSlot.value);
      playSound([ soundPath1, soundPath2 ] );
    }
  };

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withSound)
    {
      playSound_CurrentNumber(function() {
        playSound_Decomposition(chiffreSlot.value, addSuccessAndReplay);
      });
    }
    else
    {
      addSuccessAndReplay();
    }
  }

</script>

<style lang="scss">

  .ajouter-quantites-manquantes-v2 {

    .sound-player-et-chiffres-a-trouver {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-bottom: 50px;

      .slots-and-columns {
        display: flex;
        justify-content: center;
        width: 100%;

        .column {
          gap: 10px;

          &:first-child {
            max-width: 510px;
          }

          &:last-child {
            flex: calc( 100% - 510px ) 0 0;
          }

          text-align: center;

          .slots-parent {
            margin-top: 130px;
          }

          .aide-parent,
          .slots-parent {
            display: inline-block;
          }
        }
      }
    }

  }

  

</style>
