<template>
  <ul
      class="clavier-chiffres"
      :class="cssClass"
  ><!--
    --><li class="draggable" data-value="0">0</li><!--
    --><li class="draggable" data-value="1">1</li><!--
    --><li class="draggable" data-value="2">2</li><!--
    --><li class="draggable" data-value="3">3</li><!--
    --><li class="draggable" data-value="4">4</li><!--
    --><li class="draggable" data-value="5">5</li><!--
    --><li class="draggable" data-value="6">6</li><!--
    --><li class="draggable" data-value="7">7</li><!--
    --><li class="draggable" data-value="8">8</li><!--
    --><li class="draggable" data-value="9">9</li><!--
    --><li class="draggable" data-value=""></li>
  </ul>
</template>

<script setup>

  import {computed} from "vue";

  const props = defineProps({
    nb_touches_par_ligne: {
      type: Number,
      required: false,
      default: 5
    }
  });

  const cssClass = computed(() => {
    switch (parseInt(props.nb_touches_par_ligne)) {
      case 3 :
        return 'trois-par-ligne'
      case 5 :
        return 'cinq-par-ligne'
    }

    return '';
  });

</script>

<style scoped lang="scss">

  /* Clavier 0 à 9 + gomme */

  ul.clavier-chiffres {
    display: block;
    margin: 20px 0 0 0;
    padding: 0;

    li {
      display:block;
      width: 76px;
      height: 96px;
      box-sizing: border-box;
      padding-top: 20px;
      margin:0 15px 20px 0;
      float: left;

      list-style-type: none;
      cursor: pointer;

      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 52px;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #0B154B;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }

    li.dragged {
      background-color: rgba(255, 255, 255, 100);
    }

    &.cinq-par-ligne li:nth-child(5n) {
      margin-right: 0;
    }

    &.cinq-par-ligne li:nth-child(5n+1) {
      clear: both;
    }

    &.trois-par-ligne li:nth-child(3n) {
      margin-right: 0;
    }

    &.trois-par-ligne li:nth-child(3n+1) {
      clear: both;
    }

  }




</style>
