<template>
    <div class="jeu-des-etiquettes-v2"  :class="cssClassName" >

      <div class="card-and-drop-parent">

        <div class="card-parent not-draggable">
          <Card
              :chiffre="refChiffre"
              :has-number="true"
              :withSound="false"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              @click="playSound_CurrentNumber"
          />
        </div>

        <div class="drop-zone-and-validation">

          <!-- Zone de drop -->
          <div class="drop-zone-border">
            <div class="drop-zone">
              <div class="drop-zone-inner-border" v-html="dropZoneText">
              </div>
            </div>
            <ul id="drop-zone-cards" class="drop-zone-cards">
              <li v-for="(item, index) in etiquettesDropZones"
                  v-bind:key="index"
                  class="draggable"
                  style="opacity: 0"
              >
                <div :data-value="item.chiffre" :data-id="item.id" class="with-value with-dragged-style">
                  <Etiquette :chiffre="item.chiffre" />
                </div>
              </li>
            </ul>
          </div>

          <!-- Validation -->
          <LedValidation class="validation" :class="cssClassValidation" />
        </div>
      </div>

      <PaletteEtiquettes
          class="clavier"
          ref="paletteEtiquettes"
          :is-shuffled="true"
          :etiquettes="etiquettes"
          :etiquettes-en-lettres="etiquettesEnLettres"
      />

      <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne_CurrentNumber"/>

    </div>
</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import _ from 'lodash'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getChiffreEnLettres,
    getEtiquettesPourChiffres,
    getLettresEnChiffres,
    getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval,
    shuffleArray,
  } from "../../../js/utils";

  import PaletteEtiquettes from "../Palettes/PaletteEtiquettes";
  import Etiquette from "../Etiquettes/Etiquette";
  import Card from "../Card";
  import Ada from "../Ada";
  import LedValidation from "../Validation/LedValidation";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 99;

  let draggables;
  let solutionEnLettresAvecTirets;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Valeur affichée dans ce composant
  let refChiffre = ref(0);
  let cssClassValidation = ref();

  // Clavier avec les étiquettes
  let paletteEtiquettes = ref(null);

  // Valeurs des étiquettes
  let etiquettes = ref([]);
  let etiquettesEnLettres = ref(null);
  let etiquettesDropZones = ref(null);

  let cardPositions = ref([]);
  let etiquetteId = ref(0);

  const dropZoneText = computed(() => {
    if (etiquettesDropZones.value && etiquettesDropZones.value.length ) {
      let html = "<div>";
      for(let i = 0; i < etiquettesDropZones.value.length - 1; i++) {
        html += "<span>-</span>";
      }
      html += "</div>";
      return html;
    } else {
      return "<div class='centered'><span>?</span></div>";
    }
  })

  // Css selon niveau
  const cssClassName = computed(() => ' niveau-' + niveauActivite.value );


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }


  //
  // Niveaux
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    let diffCardX = 154 + 20;
    let diffCardY = 94;
    let offsetCardX = 8;
    let offsetCardY = 6;

    successMax.value = 3;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 16;

        cardPositions.value = [
          {x: offsetCardX, y: offsetCardY}
        ];

        break;

      case 2:
        currentMinimumNumber = 17;
        currentMaximumNumber = 99;

        cardPositions.value = [
          {x:                 offsetCardX, y: offsetCardY},
          {x: diffCardX +     offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
        ];
        break;

      case 3:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;

        cardPositions.value = [
          {x:                 offsetCardX, y: offsetCardY},
          {x: diffCardX +     offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 5 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 6 + offsetCardX, y: offsetCardY},
        ];
        break;

      case 4:
        currentMinimumNumber = 1000;
        currentMaximumNumber = 9999;

        cardPositions.value = [
          {x:                 offsetCardX, y: offsetCardY},
          {x: diffCardX +     offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 5 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 6 + offsetCardX, y: offsetCardY},
        ];
        break;

      case 5:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;

        cardPositions.value = [
          {x:                 offsetCardX, y: offsetCardY},
          {x: diffCardX +     offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 5 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 6 + offsetCardX, y: offsetCardY},

          {x:                 offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX +     offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 5 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 6 + offsetCardX, y: diffCardY + offsetCardY}
        ];
        break;

      case 6:
        currentMinimumNumber = 100000;
        currentMaximumNumber = 999999 ;

        cardPositions.value = [
          {x:                 offsetCardX, y: offsetCardY},
          {x: diffCardX +     offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 5 + offsetCardX, y: offsetCardY},

          {x:                 offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX +     offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: diffCardY + offsetCardY},
          {x: diffCardX * 5 + offsetCardX, y: diffCardY + offsetCardY},
        ];
        break;
    }

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 100) {
      return 2;
    } else if (maxValue <= 1000) {
      return 3;
    } else if (maxValue <= 10000) {
      return 4;
    } else if (maxValue <= 100000) {
      return 5;
    } else {
      return 6;
    }
  }



  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffre, avecConsigne = false  ) {

    // Ada
    updateAda("normal");

    if ((chiffre === null) || (chiffre === undefined))
    {
      // Tirage au sort des chiffres
      chiffre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
    }
    else
    {
      // Saisie manuelle
    }

    // Chiffre à trouver :
    refChiffre.value = parseInt(chiffre);
    console.log("----> refChiffre", chiffre);

    etiquettesDropZones.value = [];

    // Chiffres proposés sur la palette
    let i, chiffresProposes, chiffresEnLettresProposes;
    let chiffreEnLettres, chiffreEnLettresArray;

    // Liste de toutes les étiquettes possibles
    const toutesEtiquettes = getEtiquettesPourChiffres();

    // Etiquettes inutilisées
    let etiquettesInutiles;

    // Mélange
    const melange = shuffleArray([0,1,2,3,4,5,6,7,8,9])
    const melangeChiffres = [];
    const melangeLettres = [];
    let index;


    // On constitue le tableau des étiquettes en lettres
    chiffreEnLettres = getChiffreEnLettres(chiffre);
    chiffreEnLettres = chiffreEnLettres.split('-').join(' ');
    chiffreEnLettresArray = chiffreEnLettres.split(' ');

    // Solution (sert dans checkPosition)
    solutionEnLettresAvecTirets = chiffreEnLettresArray.join('-');

    // On supprime les doublons
    chiffreEnLettresArray = _.uniq(chiffreEnLettresArray);

    const chiffreEnLettresCopy = chiffreEnLettresArray.concat();

    // Si on a vingt, on ne veut pas vingts (et réciproquement)
    if (chiffreEnLettresCopy.indexOf("vingt") !== -1) {
      chiffreEnLettresCopy.push("vingts");
    }
    if (chiffreEnLettresCopy.indexOf("vingts") !== -1) {
      chiffreEnLettresCopy.push("vingt");
    }

    // Si on a cent, on ne veut pas cents (et réciproquement)
    if (chiffreEnLettresCopy.indexOf("cent") !== -1) {
      chiffreEnLettresCopy.push("cents");
    }
    if (chiffreEnLettresCopy.indexOf("cents") !== -1) {
      chiffreEnLettresCopy.push("cent");
    }


    // On ajoute d'autres étiquettes pour arriver à 10 étiquettes
    etiquettesInutiles = _.difference(toutesEtiquettes, chiffreEnLettresCopy);
    shuffleArray(etiquettesInutiles);
    chiffreEnLettresArray = chiffreEnLettresArray.concat(etiquettesInutiles);

    // On constitue les tableaux en chiffres et en lettres
    chiffresProposes = [];
    chiffresEnLettresProposes = [];

    for(i=0; i<chiffreEnLettresArray.length; i++) {
      let etiquette = chiffreEnLettresArray[i];
      if (etiquette !== undefined) {
        let chiffre = getLettresEnChiffres(etiquette);
        if (chiffre !== undefined) {
          chiffresEnLettresProposes.push(etiquette);
          chiffresProposes.push(chiffre);

          if (chiffresProposes.length >= 10) {
            break;
          }
        }
      }
    }

    for(i=0; i<chiffresProposes.length; i++) {
      index = melange[i];

      melangeChiffres.push(chiffresProposes[index]);
      melangeLettres.push(chiffresEnLettresProposes[index]);
    }

    // On met à jour la palette
    etiquettes.value = melangeChiffres;
    etiquettesEnLettres.value = melangeLettres;

    // Son du chiffre
    if (avecConsigne) {
      playSound_Consigne_CurrentNumber();
    } else {
      playSound_CurrentNumber();
    }

    //
    // Drag and Drop
    //

    // Le temps que Vue modifie le DOM (pour que JQuery puisse retrouver les nouveaux éléments)
    setTimeout( initDraggables, 100);
  }


  // A. Drag and drop des étiquettes de la palette (vers la zone) : génère une étiquette dans la zone ( voir B.)

  const addDraggableTo = function( $target ) {

    $target.addClass('draggable-initialized');

    const dropZones = $('#drop-zone-cards');
    let draggableDefaultOffset;
    const overlapThreshold = "50%";

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create( $target, {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true,
      onPress: function (pointEvent) {

        removeDraggedStyle();

        // On ajoute l'effet au dé déplacé
        var draggableElement = $(pointEvent.target).closest(".draggable");
        draggableElement.find('.with-dragged-style').addClass("dragged"); // Effet sur la carte ou le dé

        // On note la position d'origine sur la palette
        var target = this.target;
        var draggableTarget = $(target);
        draggableDefaultOffset = draggableTarget.offset();

        // On tient compte du fait que l'étiquette a peut-être déjà été mise dans un slot via le drag and drop : on doit retirer ce déplacement
        draggableDefaultOffset.left -= gsap.getProperty(target, 'x');
        draggableDefaultOffset.top -= gsap.getProperty(target, 'y');
      },
      onDragStart: function (pointEvent) {
        if (props.withSound) {
          var draggableElement = $(pointEvent.target).closest('.draggable');
          var chiffreEnLettre = draggableElement.find('.with-value').data('value');
          var chiffre = getLettresEnChiffres(chiffreEnLettre);
          if (chiffre > 0) {
            var soundPath = getSoundPath_Chiffre(chiffre);
            playSound(soundPath);
          } else {
            // TODO
            // Si -10 : "et"
          }
        }
      },
      onDragEnd: function (pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest(".draggable");
        let draggableValue = draggableElement.find('.with-value').attr("data-value");

        let i, dropZone;
        let j, dropEtiquettesData = etiquettesDropZones.value, dropEtiquettesDatum;
        let dropReplacement = false;

        // On dépose sur une étiquette de la zone de drop
        const dropEtiquettes = $(".draggable", "#drop-zone-cards");
        i = dropEtiquettes.length;
        while (--i > -1) {
          dropZone = $(dropEtiquettes[i]);
          if (this.hitTest(dropZone, "80%")) {

            let dropZoneWithValue = dropZone.find('.with-value')
            let cardId = parseInt(dropZoneWithValue.attr("data-id"));

            for(j=0;j<dropEtiquettesData.length;j++) {
              dropEtiquettesDatum = dropEtiquettesData[j];
              if (dropEtiquettesDatum.id === cardId) {
                dropEtiquettesDatum.chiffre = draggableValue;
                dropReplacement = true;
                etiquetteId.value ++;
                break;
              }
            }

            break;
          }
        }

        if (! dropReplacement)
        {
          // On dépose l'étiquette sur la zone de drop

          // NB : On vérifie si on a pas déjà rempli la zone
          const dropZones = $("#drop-zone-cards");
          const cardsOnDropZone = $(".draggable", dropZones).length;
          const cardsMaxOnDropZone = cardPositions.value.length;

          i = dropZones.length;
          while (--i > -1) {
            dropZone = $(dropZones[i]);
            if (this.hitTest(dropZone, overlapThreshold)) {

              // On doit ajouter un étiquette dans la dropzone (s'il reste de la place)
              if (cardsOnDropZone < cardsMaxOnDropZone)
              {
                var card = $(pointEvent.target).closest('.draggable');
                let cardValue = card.find('.with-value').attr("data-value");
                etiquettesDropZones.value.push({
                  id: ++ etiquetteId.value,
                  chiffre : cardValue // en lettres
                });

                /*
                var cardClone = card.clone();
                cardClone.removeClass('draggable-initialized');
                cardClone.find('.etiquette-v2').removeClass('blue red green');
                cardClone.css('transform', '');
                cardClone.css('opacity', 0);
                dropZone.append(cardClone);
                console.log("card", card, card.clone());
                */

                setTimeout(initDropZoneDraggables, 10);
              }
            }
          }
        }
        else
        {
          // On doit vérifier si l'ordre est le bon
          if (checkPositionTimeout) clearTimeout(checkPositionTimeout);
          checkPositionTimeout = setTimeout(checkPositions, 200);
        }

        // On remet l'étiquette du clavier à sa place dans tous les cas
        gsap.set(this.target, {x: 0, y: 0});
      },
      onDrag: function () {
        var i = dropZones.length, dropZone;
        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

  };

  const initDraggables = function() {
    const $target = $(".clavier .draggable").not('.draggable-initialized');
    addDraggableTo($target);
    checkPositions();
  };

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }



  // B. Drag and drop des étiquettes dans la Drop zone

  let checkPositionTimeout;

  const addDropZoneDraggableTo = function( $target ) {

    $target.addClass('draggable-initialized');

    Draggable.create( $target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,

      onPress : function() {
        if (props.withSound) {
          const $target = $(this.target);
          var chiffreEnLettre = $target.find('.with-value').data('value');
          var chiffre = getLettresEnChiffres(chiffreEnLettre);
          if (chiffre > 0) {
            var soundPath = getSoundPath_Chiffre(chiffre);
            playSound(soundPath);
          } else {
            // TODO
            // Si -10 : "et"
          }
        }
      },
      onDrag : function() {
        var tile = $(this.target);
        var zone = getZone(tile);
        if (!zone) reorderTiles(true);
        if (hitTest(tile)) reorderTiles();
      },
      onDragEnd   : function() {
        const $target = $(this.target);
        $target.removeClass("dragging");
        $target.find('.with-dragged-style').removeClass("dragged");

        // Si on dépose l'étiquette en dehors de la zone de drop, on la retire de la zone de drop
        const dropZone = $("#drop-zone-cards");
        if (this.hitTest(dropZone, "50%"))
        {
          hitTest(this.target);
          reorderTiles();

          // On doit vérifier si l'ordre est le bon
          if (checkPositionTimeout) clearTimeout(checkPositionTimeout);
          checkPositionTimeout = setTimeout(checkPositions, 600);
        }
        else
        {
          const cardId = parseInt($target.find('.with-value').attr('data-id'));
          let j, dropEtiquettesData = etiquettesDropZones.value, dropEtiquettesDatum;
          for(j=0;j<dropEtiquettesData.length;j++) {
            dropEtiquettesDatum = dropEtiquettesData[j];
            // console.log(j, dropEtiquettesDatum.chiffre, dropEtiquettesDatum.id, cardId);
            if (dropEtiquettesDatum.id === cardId) {
              dropEtiquettesData.splice(j, 1);
              break;
            }
          }

          setTimeout(initDropZoneDraggables, 10);
        }
      },
      onDragStart : function() {
        const $target = $(this.target);
        $target.addClass("dragging");
        $target.find('.with-dragged-style').addClass("dragged");
      },
      onRelease   : function() {
        hitTest(this.target);
        reorderTiles();
      }
    });

  };

  const initDropZoneDraggables = function() {

    let tiles  = $(".draggable", ".drop-zone-cards");
    let position, cp;

    tiles.each(function(i, tile) {

      // Setup tiles with some data
      tile = $(tile);
      tile.data({ index: i });

      cp = cardPositions.value;
      position = cp[i];

      gsap.killTweensOf(tile);
      gsap.set(tile, {
        x: position.x,
        y: position.y,
        opacity: 1
      });
    });

    const $target = $(".draggable", ".drop-zone-cards").not('.draggable-initialized');
    addDropZoneDraggableTo($target);

    // On doit vérifier si l'ordre est le bon
    if (checkPositionTimeout) clearTimeout(checkPositionTimeout);
    checkPositionTimeout = setTimeout(checkPositions, 200);
  };

  function getZone(tile) {
    var pool = $("#drop-zone-cards");
    return Draggable.hitTest(tile, pool) ? pool : null;
  }

  function hitTest(tile) {

    var target;
    var pool = $("#drop-zone-cards");
    const overlapThreshold = "50%";
    var query = ".draggable:not(.dragging)";

    // Hit test tiles that aren't moving or being dragging
    pool.children(query).each(function(i, element) {
      if (Draggable.hitTest(tile, element, overlapThreshold)) {
        target = element;
        return false;
      }
    });

    if (target) changePosition(tile, target);
    return target;
  }

  function reorderTiles(dragging) {
    var pool   = $("#drop-zone-cards");
    var query = dragging ? ".draggable:not(.dragging)" : ".draggable";
    pool.children(query).each(moveTile);
  }

  function moveTile(index, tile) {

    tile = $(tile);
    tile.data("index", index);

    if (tile.hasClass("dragging")) return;
    tile.addClass("moving");

    let cp = cardPositions.value;
    let position = cp[index];

    gsap.killTweensOf(tile);
    gsap.to(tile, 0.25, {
      x: position.x,
      y: position.y,
      onComplete: function() {
        tile.removeClass("moving");
      }
    });
  }

  function changePosition(tile1, tile2) {

    tile1 = $(tile1);
    tile2 = $(tile2);

    // Changes tiles position on the DOM which is used to
    // index and find the position to move to
    tile1.data("index") > tile2.data("index")
        ? tile1.insertBefore(tile2)
        : tile1.insertAfter(tile2);
  }

  function checkPositions() {
    if (checkPositionTimeout) clearTimeout(checkPositionTimeout);

    var pool = $("#drop-zone-cards");
    var query = ".draggable";
    // const cardsValues = [];
    const chiffresOrdreActuel = [];
    let chiffreElement;

    pool.children(query).each(function(i, element) {

      chiffreElement = $(element).find('.with-value').attr("data-value");

      chiffresOrdreActuel.push( chiffreElement );

    });

    // console.log(solutionEnLettresAvecTirets, chiffresOrdreActuel.join('-'));

    const dropBorder = $('.drop-zone-inner-border');
    dropBorder.removeClass('is-correct');

    const isCorrect = chiffresOrdreActuel.join('-') === solutionEnLettresAvecTirets;

    if (isCorrect)
    {
      dropBorder.addClass('is-correct');
      cssClassValidation.value = 'is-correct';

      // On empêche l'interaction de l'activité en cours
      updateAda("lock");

      // Son de félicitations, succès et replay
      playSound_Felicitations_and_AddSuccess();

    } else if (chiffresOrdreActuel.length > 0) {
      // Etiquettes ou ordre incorrects
      cssClassValidation.value = 'is-wrong';

      // updateAda("error", 3000);

    } else {
      // Pas d'étiquettes
      cssClassValidation.value = '';
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Sons
  //

  function playSound_CurrentNumber() {
    if (props.withSound)
    {
      var soundPath = getSoundPath_Chiffre(refChiffre.value);
      playSound(soundPath);
    }
  }

  function playSound_Consigne_CurrentNumber() {
    if (props.withSound)
    {
      const soundPath1 = getSoundPath_Consigne("ecris-le-nombre-avec-les-etiquettes");
      const chiffre = refChiffre.value;
      const soundPath2 = getSoundPath_Chiffre(chiffre);

      if (chiffre < 1000) {
        playSound([soundPath1, soundPath2], () => {updateAda("help")});
      } else {
        playSound([soundPath1].concat(soundPath2), () => {updateAda("help")});
      }
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withSound)
    {
      let soundPaths;
      const soundPath = getSoundPath_Chiffre(refChiffre.value);
      const soundPathFelicitations = getSound_Felicitations();

      if (Array.isArray(soundPath)) {
        soundPaths = [soundPathFelicitations].concat(soundPath);
      } else {
        soundPaths = [soundPathFelicitations, soundPath];
      }

      playSound(soundPaths, () => addSuccessAndReplay);
    }
    else
    {
      addSuccessAndReplay();
    }
  }

</script>

<style lang="scss">

  .jeu-des-etiquettes-v2 {

    @include on-tablet {
      padding-top: 20px;
    }

    .card-and-drop-parent {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      margin-bottom: 50px;

      .drop-zone-and-validation {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .drop-zone-border {

          position: relative;

          display: inline-block;
          background: #FFFFFF;
          border-radius: 22px;
          border: solid 8px #FFF;

          .drop-zone {
            pointer-events: none;

            .drop-zone-inner-border {
              position: relative;

              width: 100%;
              height: 100%;
              min-height: 104px;
              overflow: hidden;

              background: #F2F2F2;
              border: 4px solid #FFD32C;
              border-radius: 16px;

              display: flex;
              justify-content: center;
              align-items: center;

              &.is-correct {
                border-color: #58CE45;
              }

              &.is-wrong {
                border-color: #FF3F33;
              }

              &::before {
                content: "";
                display: block;

                position: absolute;
                width: 6.64px;
                height: 174.92px;
                left: -15.46px;
                top: -20.19px;

                /* White / 100 */
                background: #FFFFFF;
                opacity: 0.5;
                transform: rotate(30deg);
              }

              &::after {
                content: "";
                display: block;

                position: absolute;
                width: 23.96px;
                height: 169.69px;
                left: 0.77px;
                top: -22.75px;

                /* White / 100 */
                background: #FFFFFF;
                opacity: 0.5;
                transform: rotate(30deg);
              }

              & > div {  /* Tirets */
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                column-gap: 157px;
                row-gap: 13px;
                width: 100%;
                height: 100%;
                padding-left: 160px;
                transform: translateY(-4px);

                &.centered {
                  justify-content: center;
                  height: auto;
                  padding-left: 0;
                  padding-top: 0;
                }

                span {
                  position: relative;

                  font-family: 'Quicksand', sans-serif;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 42px;
                  line-height: 52px;
                  text-align: center;
                  color: rgba(102,102,102, 0.5);

                  user-select: none;
                }

              }
            }
          }

          ul.drop-zone-cards {
            position: absolute;
            top: 0;
            z-index: 1;

            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            list-style-type: none;


            & > li {
              position: absolute;
            }
          }

        }
      }
    }

    &.niveau-1 .drop-zone-border {
      width: 184px;
    }

    &.niveau-1 .drop-zone-border {
      width: 184px;
    }

    &.niveau-2 .drop-zone-border {
      width: 880px;
    }

    &.niveau-3 .drop-zone-border,
    &.niveau-4 .drop-zone-border,
    &.niveau-5 .drop-zone-border,
    &.niveau-6 .drop-zone-border {
      width: 1228px;

      @include on-print {
        width: 1024px;
      }
    }

    &.niveau-3 .drop-zone-inner-border,
    &.niveau-4 .drop-zone-inner-border,
    &.niveau-5 .drop-zone-inner-border,
    &.niveau-6 .drop-zone-inner-border {
      @include on-print {
        height: 150px !important;
      }
    }


     &.niveau-5 .drop-zone,
    &.niveau-6 .drop-zone {
      height: 200px;

      & > .drop-zone-inner-border {
        & > div {
          transform: translateY(17px) !important; /* Tirets */

          &.centered {
            transform: translateY(0) !important; /* ? */
          }
        }
      }
    }

    .validation {
      @include on-print {
        display: none;
      }
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9999;

      @include on-tablet {
        margin-left: 40px;
      }

      &.not-allowed .draggable {

        cursor: not-allowed;

        & .with-dragged-style:hover {
          cursor: not-allowed;
        }
      }
    }

  }

</style>
