<template>
    <div class="breakout" id="breakout">
      <!-- Colonne 1 -->
      <div class="score1">
        <div class="score1-top">
          <div class="score-jeu score-panel">
            <h1 class="titan">{{ scoreGlobal }}</h1>
          </div>
          <div class="score-ball-parent">
            <div class="score-ball score-panel">
              <h2 class="titan">{{ ballnumber }}</h2>
            </div>
          </div>
        </div>
        <div class="score-chip score-panel">
          <h2 class="titan">{{ chipsetsnumber }}</h2>
        </div>
      </div>
      <!-- Colonne 2 -->
      <div class="jeu-parent">
        <!-- Ada et sa bulle -->
        <div class="dicey-large">
          <div class="bulle-parent" :class="gameOverCssClass">
            <div class="bulle">
              <div class="bulle-content">
                La partie est terminée !
                <br/>
                Ton score est de {{ score }} points.
                <br/>
                <br/>
                Clique sur ce bouton pour rejouer
                <a href="#" class="restart-button" @click="replay"></a>
              </div>
            </div>
          </div>
        </div>
        <!-- canvas -->
        <div id="canvas-parent"></div>
        <div id="fps-parent">FPS : <span id="fps">0</span></div>
        <div id="debug-render"><canvas id="debug-canvas"></canvas></div>
      </div>
      <!-- Colonne 3 -->
      <div class="score2">
        <div class="vies-et-retour">
          <router-link :to="backRoute" class="back-button" ></router-link>
        </div>
      </div>
    </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref, watch} from "vue";
  import {useRouter} from "vue-router";
  import * as PIXI from 'pixi.js'
  import * as Matter from 'matter-js'

  const router = useRouter();


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    }
  });

  const speed = ref(1);

  // Par défaut, retour à l'accueil
  const backRoute = ref('/');
  backRoute.value = router.resolve({
    name: "ListeActivites"
  }).href;



  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function() {
    speed.value = 1;
  };


  //
  // Variables spécifiques à l'activité
  //

  let pixiApp;
  let pixiAppBackground;
  let pixiAppWall;

  let bodyElement;
  let canvasParent;
  let fpsTextDebug;

  // reactive data
  let scoreGlobal = ref();
  let chipsetsnumber = ref();
  let ballnumber = ref();
  const gameOver = ref(false);


  const RENDER_WITH_CANVAS = false;
  const PIXI_DEBUG = false;

  const WIDTH = Math.min(520, window.innerWidth);
  const HEIGHT = Math.min(1200, window.innerHeight - 185);

  const MARGIN_X = PIXI_DEBUG ? 200 : 0;
  const MARGIN_Y = PIXI_DEBUG ? 100 : 0;

  const PARTICLE_COUNT = 10;
  const PARTICLE_SPEED = 20;
  const PARTICLE_RADIUS = 20;

  let blocks = [];
  let BLOCK_SPEED = 0.5;

  const wallLabel = 'wall';
  const topWallLabel = 'topWall';
  const ballLabel = 'ball';
  const blockLabel = 'block';

  const ballCategory = 0x0001;
  const wallCategory = 0x0010;
  const topWallCategory = 0x0100;
  const blockCategory = 0x1000;

  const assetsFolder = "/assets/jeux/breakout/";
  const ballImage = assetsFolder + "Ball.png";
  const block_1x2Image = assetsFolder + "block_1x2.png";
  const block_2x1Image = assetsFolder + "block_2x1.png";
  const block_2x2Image = assetsFolder + "block_2x2.png";
  const block_2x3Image = assetsFolder + "block_2x3.png";
  const block_3x1Image = assetsFolder + "block_3x1.png";
  const block_4x1Image = assetsFolder + "block_4x1.png";



  // Matter :

  const Engine = Matter.Engine,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Composite = Matter.Composite,
      Events = Matter.Events,
      World = Matter.World;

  let runner;
  let engine;
  let render;
  let particles;

  let ballDirectionGraphics;
  let updateNewBallDirectionBind;
  let createNewBallInterval;
  let ballVelocityX, ballVelocityY;
  let ballStartPositionX, ballStartPositionY;


  // Rendu Matter --> Pixi :

  if (! RENDER_WITH_CANVAS ) {

    // 1. Initialization

    Render.create = function (options) {

      const render = {};
      render.startTime = getCurrentTime();
      render.engine = options.engine;
      render.element = options.element;
      render.app = options.app;
      render.frameNo = 0;

      render.timing = {
        historySize: 60,
        delta: 0,
        deltaHistory: [],
        lastTime: 0,
        lastTimestamp: 0,
        lastElapsed: 0,
        timestampElapsed: 0,
        timestampElapsedHistory: [],
        engineDeltaHistory: [],
        engineElapsedHistory: [],
        elapsedHistory: []
      };

      render.options = {
        width: 800,
        height: 600,
        pixelRatio: 1,
        background: '#14151f',
        wireframeBackground: '#14151f',
        hasBounds: !!options.bounds,
        enabled: true,
        wireframes: true,
        showSleeping: true,
        showDebug: false,
        showStats: false,
        showPerformance: false,
        showBounds: false,
        showVelocity: false,
        showCollisions: false,
        showSeparations: false,
        showAxes: false,
        showPositions: false,
        showAngleIndicator: false,
        showIds: false,
        showVertexNumbers: false,
        showConvexHulls: false,
        showInternalEdges: false,
        showMousePosition: false
      }

      // Pixi
      const app = options.app;
      const element = options.element;

      element.appendChild(app.view);

      // Texture loading in Pixi
      app.loader
          .add('balle', ballImage)
          .add('block_1x2', block_1x2Image)
          .add('block_2x1', block_2x1Image)
          .add('block_2x2', block_2x2Image)
          .add('block_2x3', block_2x3Image)
          .add('block_3x1', block_3x1Image)
          .add('block_4x1', block_4x1Image)
          .load((loader, resources) => {

            app.resources = resources;

            particles.forEach(function(matter_particle) {
              addParticleToView(app, matter_particle);
            });

            blocks.forEach(function(matter_block) {
              addBlockToView(app, matter_block);
            });
          });

      return render;

    }

    // 2. Update loop ( => Pixi update)

    Render.world = function(render) {

      var startTime = getCurrentTime() - render.startTime,
          engine = render.engine,
          world = engine.world,
          timing = render.timing,
          app = render.app;

      const pixi_bodies = app.stage.children;

      fpsTextDebug.textContent = Math.floor(app.ticker.FPS);

      if (pixi_bodies.length) {

        render.frameNo++;

        let pixi_sprite;
        world.bodies.forEach(function(matter_body) {

          // On met à jour :
          // - les objects non statiques géré par le modèle physique : astéroides
          // - les objets dont la position est géré par programmation :

          if (matter_body.isSleeping ) {

            pixi_sprite = getSpriteById(render.app, matter_body.id);
            if (pixi_sprite) {
              pixi_sprite.visible = false;
            }

          } else if (! matter_body.isStatic || matter_body.isPseudoStatic ) {

            pixi_sprite = getSpriteById(render.app, matter_body.id);
            if (pixi_sprite) {
              pixi_sprite.x = matter_body.position.x;
              pixi_sprite.y = matter_body.position.y;
              pixi_sprite.visible = true;
            }

            if (render.frameNo < 2) {
              // console.log('matter_body', matter_body.label, matter_body.position.x, matter_body.position.y, matter_body);
              // console.log(pixi_FirstBody.x, pixi_FirstBody.y, matter_FirstBody);
            }
          }
        });

      }

      // log the time elapsed computing this update
      timing.lastElapsed = getCurrentTime() - startTime;
    }

  }





  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite() {

    gameOver.value = false;

    canvasParent = document.getElementById('canvas-parent');
    bodyElement = document.getElementsByTagName('body')[0];
    fpsTextDebug = document.getElementById('fps');

    //---- AUDREY
    // score 
    // intialisation value
    scoreGlobal.value = 0;
    chipsetsnumber.value = 0;
    ballnumber.value = 0;


    /* --------------------- PIXI ------------------- */

    pixiApp = new PIXI.Application({
      width: WIDTH + 2 * MARGIN_X,
      height: HEIGHT + 2 * MARGIN_Y,
      resolution: 1
    });

    pixiAppBackground =  pixiAppBackground =  new PIXI.Graphics();
    pixiAppBackground.beginFill(0xFFFFFF);
    pixiAppBackground.drawRect(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT);
    pixiAppBackground.interactive = true;
    pixiApp.stage.addChild(pixiAppBackground);


    pixiAppWall =  new PIXI.Graphics();
    pixiAppWall.drawRect(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT);
    pixiAppWall.interactive = true;
    pixiApp.stage.addChild(pixiAppWall);


    ballDirectionGraphics =  new PIXI.Graphics();
    pixiApp.stage.addChild(ballDirectionGraphics);

    // Canon :
    ballStartPositionX = MARGIN_X + WIDTH / 2;
    ballStartPositionY = MARGIN_Y;
    ballVelocityX = 0;
    ballVelocityY = 1;

    initEventListeners();
    

    //
    // MATTER ENGINE
    //

    engine = Engine.create();
    engine.world.gravity.y = 0;

    Composite.add(engine.world, drawWalls(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT));

    particles = makeParticles();
    Composite.add(engine.world, particles);

    // Premiers blocs
    blocks = makeBlocks();
    Composite.add(engine.world, blocks);


    if ( RENDER_WITH_CANVAS ) {

      /* Canvas render (Matter default rendering) */

      const canvas = document.getElementById('debug-canvas');
      canvas.width = WIDTH + 2 * MARGIN_X;
      canvas.height = HEIGHT + 2 * MARGIN_Y;

      render = Render.create({
        element: canvasParent,
        canvas: canvas,
        app: pixiApp,
        engine: engine,
        options: {
          width: WIDTH + 2 * MARGIN_X,
          height: HEIGHT + 2 * MARGIN_Y
        }
      });

      pixiApp.loader
          .add('sprite', ballImage)
          .load((loader, resources) => {

            pixiApp.resources = resources;

            particles.forEach(function(matter_particle) {
              addParticleToView(pixiApp, matter_particle);
            });
          });


    } else {

      /* Pixi render */

      render = Render.create({
        element: canvasParent,
        app: pixiApp,
        engine: engine
      });

    }

    Render.run(render);

    runner = Runner.create();
    Runner.run(runner, engine);

    // MATTERS MOVING BLOCKS
    Events.on(engine, 'beforeUpdate', onBeforeUpdateEnginefunction);

    // MATTERS COLLISION EVENTS
    Events.on(engine, 'collisionStart', onCollisionStartfunction);
  }


  const updateNewBallDirection = function(e) {

    const mouseX = e.x || e.data.global.x;
    const mouseY = e.y || e.data.global.y;

    // Vecteur Canon -> Souris
    ballVelocityX = (mouseX - ballStartPositionX);
    ballVelocityY = (mouseY - ballStartPositionY);

    // Normalisation du vecteur
    const vectorLength = Math.sqrt( ballVelocityX * ballVelocityX + ballVelocityY * ballVelocityY );
    ballVelocityX /= vectorLength;
    ballVelocityY /= vectorLength;

    //
    ballVelocityX *= PARTICLE_SPEED;
    ballVelocityY *= PARTICLE_SPEED;

    // Représentation du vecteur à l'écran
    ballDirectionGraphics.clear();
    ballDirectionGraphics.lineStyle(1, 0x00FF00);
    ballDirectionGraphics.moveTo(ballStartPositionX, ballStartPositionY);

    const polygons = [ { x: ballStartPositionX, y: ballStartPositionY} , { x: mouseX, y: mouseY} ];
    ballDirectionGraphics.drawDashedPolygon(polygons, 0, 0, 0, 25, 25);
  };

  const createNewBall = function() {
    // On doit réactiver une balle en attente
    const ball = findLockedParticle();
    if (ball) {
      const ballSprite = getSpriteById(pixiApp, ball.id);
      if (ballSprite) {
        unlockParticleReference(ball);
      }
    } else {
      // console.log('Le stock de balle est vide !!!');
    }
  };

  // Effacement de la représentation du vecteur à l'écran
  const hideNewBallDirection = function() {
    ballDirectionGraphics.clear();
  }

  const getCurrentTime = function() {
    return new Date().getTime();
  }

  const addParticleToView = function(pixi_app, particle) {

    const sprite = new PIXI.Sprite(pixi_app.resources.balle.texture);
    sprite.nombre = 9;
    sprite.id = particle.id;
    sprite.anchor.x = 0.5;
    sprite.anchor.y = 0.5;
    pixi_app.stage.addChild(sprite);

  };

  const addBlockToView = function(pixi_app, block) {

    const blockType = block.blockType;

    const sprite = new PIXI.Sprite(pixi_app.resources[blockType].texture);
    sprite.nombre = block.scorenumber;//9;
    sprite.id = block.id;
    sprite.anchor.x = 0.5;
    sprite.anchor.y = 0.5;
    pixi_app.stage.addChild(sprite);

    if (blockType === "block_2x3") {
      // sprite.alpha = 0.5;
    }

    const infos = getBlockInfosByType(blockType);

    let text = new PIXI.Text( sprite.nombre, { fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center' });
    text.anchor.x = 0.5;

    if (infos.text_x) {
      text.x = infos.text_x + 7;
    }
    if (infos.text_y) {
      text.y = infos.text_y;
    }
    sprite.addChild(text);

  };

  const getSpriteById = function(pixi_app, particleId) {
    let foundSprite = false;
    pixi_app.stage.children.forEach( function(sprite) {
      if (sprite.id === particleId) {
        foundSprite = sprite;
      }
    });
    return foundSprite;
  };

  const updateSpriteNo = function(pixi_sprite, no) {
    pixi_sprite.nombre = no;
    pixi_sprite.children[0].text = no > 0 ? no : '';
  }


  /* ------------------ EVENTS ------------------------ */

  const onBeforeUpdateEnginefunction =  function() {

    let i,  bodies = Composite.allBodies(engine.world);

    for (i=0; i < bodies.length; i++) {
      var body = bodies[i];

      if ((body.label === blockLabel) && ! body.isSleeping) {

        // Fin du jeu :
        if (body.position.y < 50) {
          gameOver.value = true;
          break;
        }

        if (! gameOver.value) {

          Matter.Body.setPosition(body, {
            x: body.position.x,
            y: body.position.y - BLOCK_SPEED
          });

          if (body.isLastBlock) {

            if (body.position.y + body.size.height * 0.5 < MARGIN_Y + HEIGHT)
            {
              body.isLastBlock = false;

              const newBlocks = makeBlocks(body.position.y + body.size.height * 0.5 + 15);

              if (newBlocks.length) {
                Composite.add(engine.world, newBlocks);

                newBlocks.forEach(function(matter_block) {
                  addBlockToView(pixiApp, matter_block);
                });
              }

              blocks = blocks.concat(newBlocks);
              // console.log('total blocks', blocks.length);
            }
          }
        }
      }
    }

    if (gameOver.value) {
      pauseGameEngine();
    }
  }

  const onCollisionStartfunction = function(event) {

    const pixi_app = render.app;
    const pairs = event.pairs;

    for (var i = 0; i < pairs.length; i++) {

      let pair = pairs[i];
      const bodyA = pair.bodyA;
      const bodyB = pair.bodyB;

      let block = null;
      let ball = null;
      let sprite = null;

      // Collision entre la balle et un bloc  ----------------------

      if (gameOver.value === false) {
        if ((bodyA.label ===  blockLabel) && (bodyB.label === ballLabel)) {
          block = bodyA;
        } else if ((bodyB.label === blockLabel) && (bodyA.label === ballLabel)) {
          block = bodyB;
        }
      }

      if (block) {

        // Sprite Pixi
        sprite = getSpriteById(pixi_app, block.id);

        if (sprite) {
          if (sprite.nombre > 1)
          {
            updateSpriteNo(sprite, sprite.nombre - 1);

          } else {

            // On retire le bloc
            removeBlock(block);

            //update score
            scoreCount(block);
          }
        }
      }
      else
      {
        // Collision entre la balle et le mur du haut  ----------------------
        if ((bodyA.label ===  ballLabel) && (bodyB.label === topWallLabel)) {
          ball = bodyA;
        } else if ((bodyB.label === ballLabel) && (bodyA.label === topWallLabel)) {
          ball = bodyB;
        }

        if (ball) {
          lockParticle(ball);
        }
      }
    }
  };


  function startTouchStartUpdate() {
    // Direction des nouvelles balles
    updateNewBallDirectionBind = updateNewBallDirection.bind(this);
    pixiAppBackground.on('touchmove', updateNewBallDirectionBind);

    // Début de la création des nouvelles balles
    if (createNewBallInterval) clearInterval(createNewBallInterval);
    createNewBallInterval = setInterval(createNewBall, 400);
  }

  function startMouseDownUpdate() {
    // Direction des nouvelles balles
    updateNewBallDirectionBind = updateNewBallDirection.bind(this);
    pixiAppBackground.on('mousemove', updateNewBallDirectionBind);

    // Début de la création des nouvelles balles
    if (createNewBallInterval) clearInterval(createNewBallInterval);
    createNewBallInterval = setInterval(createNewBall, 400);
  }

  function stopMouseDownUpdate() {
    // Fin du mouseMove
    pixiAppBackground.off('mousemove', updateNewBallDirectionBind);

    // Fin du tracé de la direction des nouvelles balles
    hideNewBallDirection();

    // Arrêt de la création des nouvelles balles
    if (createNewBallInterval) clearInterval(createNewBallInterval);
  }

  function stopTouchStartUpdate() {
    // Fin du mouseMove
    pixiAppBackground.off('touchmove', updateNewBallDirectionBind);

    // Fin du tracé de la direction des nouvelles balles
    hideNewBallDirection();

    // Arrêt de la création des nouvelles balles
    if (createNewBallInterval) clearInterval(createNewBallInterval);
  }


  /* ------------------ MATTER ------------------------ */

  //
  // MATTER ELEMENTS CREATION
  //

  const drawWalls = function(x, y, w, h) {

    const Bodies = Matter.Bodies;
    const thickness = 1;

    // Mur du haut :
    const topWallOptions = {
      label: topWallLabel,
      isStatic: true,
      collisionFilter: {
        category: topWallCategory,
        mask: ballCategory
      }
    };

    // Autres murs :
    const wallOptions = {
      label: wallLabel,
      isStatic: true,
      collisionFilter: {
        category: wallCategory,
        mask: ballCategory
      }
    };

    return [
      // Bottom wall
      Bodies.rectangle(
          // x, y
          x + w / 2, y + h,
          // width, height
          w, thickness,
          wallOptions
      ),
      // right wall
      Bodies.rectangle(
          // x, y
          x + w, y + h / 2,
          // width, height
          thickness, h,
          wallOptions
      ),
      // top wall
      Bodies.rectangle(
          // x, y
          x + w / 2, y - 20,
          // width, height
          w, thickness,
          topWallOptions
      ),
      // left wall
      Bodies.rectangle(
          // x, y
          x, y + h / 2,
          // width, height
          thickness, h,
          wallOptions
      ),
    ];
  };

  /* Balles (particles) */

  const makeParticle = function(label, isStatic) {

    const p = Matter.Bodies.circle(
        ballStartPositionX,
        ballStartPositionY,
        PARTICLE_RADIUS,
        {
          label: label,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: ballCategory,
            mask: wallCategory | topWallCategory | blockCategory
          },
        });

    Matter.Body.setInertia(p, Infinity);

    Matter.Body.setVelocity(p, {
      x: ballVelocityX,
      y: ballVelocityY
    });

    if (isStatic) {
      lockParticle(p);
    }

    return p;
  };

  const lockParticle = function( matterParticle ) {
    Matter.Body.setPosition(matterParticle, { x: 0, y: 0 });
    Matter.Body.setVelocity(matterParticle, { x: 0, y: 0 });
    matterParticle.isSleeping = true;
  }

  const lockAllBalls = function() {
    var bodies = Composite.allBodies(engine.world);
    for (var i = 0; i < bodies.length; i++) {
      var body = bodies[i];

      if (body.label === ballLabel) {
        lockParticle(body);
      }
    }
  }


  const findLockedParticle = function() {
    let i, matterParticle;
    for(i=0; i< PARTICLE_COUNT; i++) {
      matterParticle = particles[i];
      if (matterParticle.isSleeping) {
        return matterParticle;
      }
    }
    return false;
  }

  const unlockParticleReference = function( matterParticle ) {
    Matter.Body.setPosition(matterParticle, { x: ballStartPositionX + 10 , y: ballStartPositionY + 20 });
    Matter.Body.setVelocity(matterParticle, { x: ballVelocityX, y: ballVelocityY });
    matterParticle.isSleeping = false;
  }

  const makeParticles = function() {
    const particles = [];
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      particles.push(makeParticle(ballLabel, true ));
    }
    return particles;
  };

  /* ------------------ BLOCKS ------------------------ */


  /* Blocks of blocks */

  const margin_left = 12;

  const X0 =  margin_left;
  const X1 =  80 + margin_left;
  const X2 = 168 + margin_left;
  const X3 = 248 + margin_left;
  const X4 = 328 + margin_left;
  const X5 = 412 + margin_left;

  const Y0 =  0;
  const Y1 =  82;
  const Y2 = 168;
  const Y3 = 248;
  const Y4 = 336;
  // const Y5 = 422;

  let blockDefinitions = [];

  blockDefinitions.push([
    { type: 'block_4x1', x: X0, y: Y0},
    { type: 'block_3x1', x: X0, y: Y1 },
    { type: 'block_2x3', x: X3, y: Y1 },
    { type: 'block_1x2', x: X5, y: Y1 },
    { type: 'block_2x2', x: X0, y: Y2 },
    { type: 'block_1x2', x: X2, y: Y2 },
    { type: 'block_3x1', x: X0, y: Y4 },
    { type: 'block_2x1', x: X3, y: Y4 },
    { type: 'block_1x2', x: X5, y: Y3 }
  ]);

  blockDefinitions.push([
    { type: 'block_3x1', x: X0, y: Y0},
    { type: 'block_3x1', x: X3, y: Y0 },
    { type: 'block_2x2', x: X0, y: Y1 },
    { type: 'block_2x2', x: X2, y: Y1 },
    { type: 'block_2x2', x: X4, y: Y1 },
    { type: 'block_2x1', x: X0, y: Y3 },
    { type: 'block_2x1', x: X2, y: Y3 },
    { type: 'block_2x1', x: X4, y: Y3 }
  ]);

  blockDefinitions.push([
    { type: 'block_2x2', x: X0, y: Y0},
    { type: 'block_2x3', x: X2, y: Y0 },
    { type: 'block_2x2', x: X4, y: Y0 },
    { type: 'block_2x1', x: X0, y: Y2 },
    { type: 'block_2x1', x: X4, y: Y2 }
  ]);

  blockDefinitions.push([
    { type: 'block_1x2', x: X0, y: Y0},
    { type: 'block_3x1', x: X1, y: Y0 },
    { type: 'block_3x1', x: X1, y: Y1 },
    { type: 'block_2x2', x: X4, y: Y0 }
  ]);

  blockDefinitions.push([
    { type: 'block_2x1', x: X0, y: Y0},
    { type: 'block_2x2', x: X2, y: Y0 },
    { type: 'block_2x3', x: X4, y: Y0 },
    { type: 'block_2x3', x: X0, y: Y1 },
    { type: 'block_2x1', x: X2, y: Y2 },
    { type: 'block_2x1', x: X2, y: Y3 },
    { type: 'block_2x1', x: X4, y: Y3 },
    { type: 'block_4x1', x: X0, y: Y4 },
  ]);

  const shuffleArray = function(array) {
    let curId = array.length;
    while (0 !== curId) {
      let randId = Math.floor(Math.random() * curId);
      curId -= 1;
      let tmp = array[curId];
      array[curId] = array[randId];
      array[randId] = tmp;
    }
    return array;
  };


  const blockDefinitionIds = [0, 1, 2, 3, 4]; //
  let blockDefinitionIdCopy = shuffleArray(blockDefinitionIds).concat();

  const getNextBlocks = function() {

    if (blockDefinitionIdCopy.length === 0) {
      blockDefinitionIdCopy = shuffleArray(blockDefinitionIds).concat();
    }

    const blockDefinitionId = blockDefinitionIdCopy.shift();
    // console.log('blockDefinitionId', blockDefinitionId);

    return blockDefinitions[blockDefinitionId];
  };


  /* Blocks */

  const getBlockInfosByType = function(blockType) {

    let w, h, text_x = 0, text_y = 0;
    let score;

    switch(blockType) {

      case 'block_1x2' :
        w = 68;
        h = 160;
        text_x = -7;
        text_y = -16;
        score = 8;
        break;

      case 'block_2x1' :
        w = 152;
        h = 74;
        text_x = -7;
        text_y = -16;
        score = 6;
        break;

      case 'block_3x1' :
        w = 236;
        h = 74;
        text_x = -7;
        text_y = -16;
        score = 16;
        break;

      case 'block_4x1' :
        w = 488;
        h = 76;
        text_x = -39;
        text_y = -16;
        score = 1;
        break;

      case 'block_2x2' :
        w = 152;
        h = 160;
        text_x = -39;
        text_y = -16;
        score = 24;
        break;

      case 'block_2x3' :
        w = 152;
        h = 244;
        text_x = -39;
        text_y = -16;
        score = 36;
        break;

    }

    return { w:w , h:h, text_x: text_x, text_y: text_y, score:score };
  }

  const makeBlock = function(blockType, w, h, x, y, scorenumber) {

    return Matter.Bodies.rectangle(
        x,
        y,
        w,
        h,
        {
          label: blockLabel,
          blockType: blockType,
          scorenumber:scorenumber,
          isStatic: true,
          isPseudoStatic: true,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: blockCategory,
            mask: ballCategory | topWallCategory
          },
        });
  };

  const makeBlocks = function( fromY ) {

    if (fromY === undefined) {
      fromY = HEIGHT + MARGIN_Y;
    }

    const blocksDefinition = getNextBlocks();
    const n = blocksDefinition.length;
    let i, blockDefinition, lastBlock, blocX, blocY, blockType, blocTypeInfos, block, bottomBlock, maxBottom = 0;
    let score;

    const newBlocks = [];

    for (i = 0; i < n; i++) {

      blockDefinition = blocksDefinition[i];
      blockType = blockDefinition.type;
      blocTypeInfos = getBlockInfosByType(blockType);

      blocX =  blockDefinition.x + MARGIN_X + 5  + blocTypeInfos.w * 0.5;
      blocY =  blockDefinition.y + fromY  + blocTypeInfos.h * 0.5;
      score = blocTypeInfos.score;

      // Récupération d'un block existant
      block = findSleepyBlock(blockDefinition.type);

      if (block) {
        restoreBlock(block, blocX, blocY);
      } else {
        block = makeBlock(blockDefinition.type, blocTypeInfos.w, blocTypeInfos.h, blocX, blocY, score );
        block.size = { height : blocTypeInfos.h };
        newBlocks.push(block);
      }

      // Dernier bloc
      bottomBlock = blocY + blocTypeInfos.h;
      if (bottomBlock > maxBottom) {
        maxBottom = bottomBlock;
        lastBlock = block;
      }
    }

    if (lastBlock) {
      lastBlock.isLastBlock = true;
    }

    // Sert à créer les blocs dans Pixi
    return newBlocks;
  };

  const removeBlock = function(block) {
    block.isSleeping = true;
    block.collisionFilter.mask = topWallCategory;
    block.isLastBlock = false;
  };

  const removeAllBlocks = function() {
    var bodies = Composite.allBodies(engine.world);

    for (var i = 0; i < bodies.length; i++) {
      var body = bodies[i];

      if (body.label === blockLabel) {
        removeBlock(body);
      }
    }

  }

  const restoreBlock = function(block, x, y) {
    block.isSleeping = false;
    block.collisionFilter.mask = ballCategory | topWallCategory;
    Matter.Body.setPosition(block, { x: x, y: y });
  }

  const findSleepyBlock = function(blockType) {
    let i, matterBlock, n = blocks.length;
    for(i=0; i< n; i++) {
      matterBlock = blocks[i];
      if (matterBlock.isSleeping && matterBlock.blockType === blockType ) {
        return matterBlock;
      }
    }
    return false;
  };

  //AUDREY
  const scoreCount = function(block) {
    var scorenumber = block.scorenumber;

    if ( block.blockType === "block_4x1" || block.blockType === "block_2x2")
      ballnumber.value += 1;
    else if (block.blockType === "block_2x3")
      chipsetsnumber.value += 1;

    scoreGlobal.value += scorenumber;
  }

  PIXI.Graphics.prototype.drawDashedPolygon = function(polygons, x, y, rotation, dash, gap, offsetPercentage){
    var i;
    var p1;
    var p2;
    var dashLeft = 0;
    var gapLeft = 0;

    if ( offsetPercentage > 0 ) {
      var progressOffset = (dash+gap)*offsetPercentage;
      if(progressOffset < dash) dashLeft = dash-progressOffset;
      else gapLeft = gap-(progressOffset-dash);
    }

    var rotatedPolygons = [];
    var dx, dy;

    for (i = 0; i<polygons.length; i++){
      var p = {x:polygons[i].x, y:polygons[i].y};
      var cosAngle = Math.cos(rotation);
      var sinAngle = Math.sin(rotation);
      dx = p.x;
      dy = p.y;
      p.x = (dx*cosAngle-dy*sinAngle);
      p.y = (dx*sinAngle+dy*cosAngle);
      rotatedPolygons.push(p);
    }

    for (i = 0; i<rotatedPolygons.length; i++){
      p1 = rotatedPolygons[i];
      if (i === rotatedPolygons.length-1) p2 = rotatedPolygons[0];
      else p2 = rotatedPolygons[i+1];
      dx = p2.x-p1.x;
      dy = p2.y-p1.y;
      var len = Math.sqrt(dx*dx+dy*dy);
      var normal = {x:dx/len, y:dy/len};
      var progressOnLine = 0;
      this.moveTo(x+p1.x+gapLeft*normal.x, y+p1.y+gapLeft*normal.y);
      while (progressOnLine<=len){
        progressOnLine+=gapLeft;
        if (dashLeft > 0) progressOnLine += dashLeft;
        else progressOnLine+= dash;
        if (progressOnLine>len){
          dashLeft = progressOnLine-len;
          progressOnLine = len;
        }else{
          dashLeft = 0;
        }
        this.lineTo(x+p1.x+progressOnLine*normal.x, y+p1.y+progressOnLine*normal.y);
        progressOnLine+= gap;
        if (progressOnLine>len && dashLeft === 0){
          gapLeft = progressOnLine-len;
          // console.log(progressOnLine, len, gap);
        } else {
          gapLeft = 0;
          this.moveTo(x+p1.x+progressOnLine*normal.x, y+p1.y+progressOnLine*normal.y);
        }
      }
    }
  }


  //
  // GAME OVER
  //

  const gameOverCssClass = computed(() => gameOver.value === false ? "hidden" : "" );

  function initEventListeners() {
    pixiAppBackground.on('mousedown', startMouseDownUpdate );
    pixiAppBackground.on('touchstart', startTouchStartUpdate );

    bodyElement.addEventListener('mouseup', stopMouseDownUpdate);
    bodyElement.addEventListener('touchend', stopTouchStartUpdate );
  }
  
  function removeEventListeners() {

    pixiAppBackground.off('touchstart');
    pixiAppBackground.off('mousedown');

    pixiAppBackground.off('touchmove');
    pixiAppBackground.off('touchend');

    pixiAppBackground.off('mousemove');
    pixiAppBackground.off('mouseup');

    bodyElement.removeEventListener('mousedown', startMouseDownUpdate);
    bodyElement.removeEventListener('touchstart', startTouchStartUpdate);
    bodyElement.removeEventListener('mouseup', stopMouseDownUpdate);
    bodyElement.removeEventListener('touchend', stopTouchStartUpdate);

  }

  function pauseGameEngine() {

    if (createNewBallInterval) clearInterval(createNewBallInterval);

    lockAllBalls();
    hideNewBallDirection();
    removeEventListeners();

    Events.off(engine, 'beforeUpdate');
    Events.off(engine, 'collisionStart');
  }

  function resumeGameEngine() {

    initEventListeners();

    Events.on(engine, 'beforeUpdate', onBeforeUpdateEnginefunction);
    Events.on(engine, 'collisionStart', onCollisionStartfunction);
  }

  function stopGameEngine() {

    if (createNewBallInterval) clearInterval(createNewBallInterval);

    if (engine && engine.world) {
      World.clear(engine.world);
      Engine.clear(engine);
      Render.stop(render);
      Runner.stop(runner);
    }

    Events.off(engine, 'beforeUpdate');
    Events.off(engine, 'collisionStart');
  }

  function clearGameRendering() {

    if (render.canvas) {
      render.canvas.remove();
      render.canvas = null;
    }

    render.context = null;
    render.textures = {};

    if (pixiApp) {
      pixiApp.loader.reset();
      pixiApp.destroy(true);
      // pixiApp.utils.destroyTextureCache();
      pixiApp = null;
    }

    // Suppression des canvas résiduels éventuels
    const element = document.getElementsByTagName('canvas');
    for (let i = element.length - 1; i >= 0; i--) {
      element[i].parentNode.removeChild(element[i]);
    }
  }

  function replay() {

    gameOver.value = false;

    // Scores
    scoreGlobal.value = 0;
    chipsetsnumber.value = 0;
    ballnumber.value = 0;

    //
    lockAllBalls();
    removeAllBlocks();
    makeBlocks();

    // Matter
    resumeGameEngine();
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    initNiveau();
    initActivite();
  });

  onMounted(() => {
    initNiveau();
    initActivite();
  });

  onUnmounted(() => {
    removeEventListeners();
    stopGameEngine();
    clearGameRendering();
  });

</script>

<style scoped lang="scss">

  .breakout {

    position: relative;
    padding-top: 30px;

    display: flex;
    justify-content: center;
    gap: 30px;

    .score1 {
      position: relative;
      flex: 350px 0 0;

      display: flex;
      align-items: flex-start;
      gap: 30px;

      .score1-top {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 30px;

        .score-jeu {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 341px;
          height: 128px;
        }

        .score-ball-parent {
          text-align: right;

          .score-ball {
            display: inline-block;
            width: 128px;
            height: 64px;
            padding-top: 2px;
            padding-right: 20px;
            text-align: right;

            background-image: url(../../../assets/jeux/breakout/Ball.svg);
            background-position: left 20px top 13px;
            background-size: 40px auto;
            background-repeat: no-repeat;
          }
        }
      }

      .score-chip {
        position: absolute;
        bottom: 0;
        right: 0;

        width: 128px;
        height: 64px;
        padding-top: 2px;
        padding-right: 20px;
        text-align: right;

        background-image: url(../../../assets/jeux/asteroids/chip.svg);
        background-position: left 20px top 13px;
        background-size: 40px auto;
        background-repeat: no-repeat;
      }
    }

    .jeu-parent {

      #canvas-parent {
        border: 4px solid #FFD32C;
        border-radius: 24px;
        overflow: hidden;
        height: calc( 100vh - 185px );
      }

      #fps-parent {
        display: none;
      }

      #fps {
        color: red;
      }

      #debug-render {
        position: absolute;
        left:0;
        top:0;
        width: 1000px;
        height: 900px;
        pointer-events: none;
      }
    }

    .score2 {
      position: relative;
      flex: 250px 0 0;

      .vies-et-retour {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        .back-button {
          display: inline-block;
          width:59px;
          height:65px;
          cursor: pointer;
          background: url(../../../assets/jeux/Bouton_menu.svg) center / 59px auto no-repeat;
        }
      }

    }

    .dicey-large {
      position: relative;
      width: 100%;
      height: 150px;
      margin-top: -25px;

      background-image: url(../../../assets/jeux/Dicey.svg);
      background-position: center top;
      background-size: 224px auto;
      background-repeat: no-repeat;
    }

    .bulle-parent {
      position: absolute;
      left: 220px;
      top: 230px;

      &.hidden {
        display: none;
      }

      .bulle {
        width: 720px;
        height: 330px;
        position: absolute;

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          display: block;
          width: 100%;
          height: 100%;
          transform: scale(0.85, 0.85) translate(-70px,-90px);
          background-size: 100% 100%;
          background-image: url(../../../assets/images/svg/ada/Bulle_bravo_bottom.svg);
        }

        .bulle-content {
          position: absolute;
          z-index: 2;
          padding: 23px 35px;
          font-size: 30px;
          line-height: 1.3;
        }
      }
    }

    .restart-button {
      display: inline-block;
      width:50px;
      height:50px;
      cursor: pointer;
      background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / contain no-repeat;
      transform: translateY(20px);
    }

    .score-panel {
      background: rgb(255, 255, 255, 0.1);
      box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
      border-radius: 32px;
    }

  }

</style>
