<template>
  <div class="content content-v2">
    <HeaderActivite
        :title="titreActivite"
        :niveau="niveau"
        @replay="replay"
    />
    <main class="activite" >
        <CestPareilQue
            ref="activite"
            :niveau="niveau"
            :with-custom-values="withCustomValues"
            :values="values"
            :forme-des="formeDes"
            :separateur-milliers="separateurMilliers"
            :with-sound="soundActive"
            :with-validation="withValidation"
        />
    </main>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v2/Headers/HeaderActivite";
  import CestPareilQue from "../../../components/v2/Activites/CestPareilQue";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  import {computed, onBeforeMount, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();

  const pathActivite = 'pareil-que';

  // Settings
  const formeDes = ref();
  const separateurMilliers = ref();
  const soundActive = ref();

  // Validation
  const withValidation = ref();

  // Niveau de l'activité
  const niveau = computed(() => parseInt(route.params.niveau) );

  // Valeurs manuelles
  const values = computed(() => [
    route.query.v1 === undefined ? "" : parseInt(route.query.v1),
    route.query.v2 === undefined ? "" : parseInt(route.query.v2)
  ]);
  const withCustomValues = computed(() => (route.query.v1 !== undefined) && (route.query.v2 !== undefined) );

  // Titre activité :
  const titreActivite = ref('');
  const activiteInfos = store.getters.activiteFromPath(pathActivite);
  titreActivite.value = activiteInfos.titre;

  // Composant de l'activité
  const activite = ref(null);

  function replay() {
    activite.value.replay();
  }


  //
  // LifeCycle hooks
  //

  onBeforeMount(() => {
    formeDes.value = store.getters.formeDesV2();
    separateurMilliers.value = store.getters.separateurMilliers();
    soundActive.value = store.getters.isSoundActive(pathActivite);
    withValidation.value = route.query.parcours === undefined;
  })

</script>
