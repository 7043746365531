<template>

    <div class="table-multiplication">

      <div class="activite table">
      </div>

      <!-- Dés -->
      <div class="cards-parent components-parent not-draggable">

          <Card
            v-for="chiffre in chiffres"
            ref="'card'+chiffre"
            :id="'card'+chiffre"
            :key="chiffre"
            :chiffre="table_number"
            :decomposition="false"
            :multiplication="chiffre"
            :hasMultiplication="true"
            :withLargeImage="true"
            @click="selectCard"
          />

          <Reglette id="reglette_tool" :class="{isHidden}" />

      </div>
    </div>

</template>

<script setup>

  import { onMounted, ref, watch } from "vue";
  import Card from "../Card";
  import Reglette from "../Reglette";

  import {
    getSoundPath,
    getSoundPath_Chiffre,
    getSoundPath_Multiplication,
    playSound,
  } from "../../../js/utils";

  // Interactive value
  const chiffres = ref('');
  const table_number = ref();

  let isHidden = ref('');


  // Selon niveau :
  let currtable = 1;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: true,
      default: 1
    },
    /*
    chiffres: {
      type:Array,
      required:true,
      default:() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
    */
  });


  //
  // Niveau : chiffres minimum et ma*imum
  //

  const initNiveau = function() {

    switch(props.niveau) {

      default:
      case 1:
        currtable = 1;
        break;

      case 2:
        currtable = 2;
        break;

      case 3:
        currtable = 3;
        break;

      case 4:
        currtable = 4;
        break;

      case 5:
        currtable = 5;
        break;

      case 6:
        currtable = 6;
        break;

      case 7:
        currtable = 7;
        break;

      case 8:
        currtable = 8;
        break;

      case 9:
        currtable = 9;
        break;

      case 10:
        currtable = 10;
        break;
    }

    // console.log('niveau', props.niveau);

  };

  //
  // Lifecycle hooks
  //

  watch( props, () => {
    initNiveau();
    initActivite();
  });

  onMounted(() => {
    initNiveau();
    initActivite();
  });


  function initActivite() {
      chiffres.value = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      table_number.value = parseInt(currtable);

      isHidden.value = "isHidden";
  }

  const selectCard = function(event) {
    var $target = event.target;
    var $card =  $target.closest(".card");
    var $de = $target.closest(".de");

    var id = $card.id;
    var multiplicateur;

      
    multiplicateur = id.match(/\d/g).join('');
    
    //var offsets = {x: $div.offsetLeft, y : $div.offsetTop };


    // regle display
    isHidden.value = "";

    var $reglette = document.getElementById("reglette_tool");
    $card.after($reglette);

    // sound
    var soundPath1 = getSoundPath_Multiplication(currtable, multiplicateur);
    var soundPath2 = getSoundPath("égal");
    
    var resultat = parseInt(multiplicateur * currtable);
    var soundPath3 = getSoundPath_Chiffre(resultat);

    if($de)
      playSound(soundPath3);
    else
      playSound([soundPath1, soundPath2, soundPath3]);
    //playSound(soundPath);

    // bricole
    clearCssActiveCards();
    setCssActiveCards(multiplicateur);

  }

  const clearCssActiveCards = function() {
      var id;
      var $div;

      for(var i = 1; i <= 10; i++) {
          id = "card" + i;

          $div = document.getElementById(id);
          //console.log('id ', id);
          //console.log("$div ", $div);

          $div.style.background = "rgb(255, 171, 2, 0)";
      }
  }

  const setCssActiveCards = function(number) {
      var id;
      var $div;

      for(var i = 1; i <= number; i++) {
          id = "card" + i;

          $div = document.getElementById(id);
          console.log('id ', id);
          console.log("$div ", $div);

          $div.style.background = "rgb(255, 171, 2, 0.2)";
      }
  }


</script>

<style lang="scss">

  .table-multiplication {

    .reglette_tool {
      margin: 20px 0;
    }

    .reglette_tool.isHidden {
      display: none;
    }

    .cards-parent {
      display: inline-flex;
        

        // A AMELIORER
        .card {
          display: flex;
          align-items: center;

          .card-border {
            background: rgba(0,0,0,0);

            @include on-print {
              border-width: 1px !important;
            }
          }
          .card-content {
            background: #ffffff;
          }

          @include on-print {

            .card-content > div > div {
              width: 80px;
            }

            .multiplication {
              font-size: 28px !important;
            }
          }

        }
    }

  }

</style>