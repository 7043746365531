<template>

  <div class="drop-zone-dizaines-unites">

    <div class="colonnes" :id="refId">
      <ul class="colonne colonne-dizaines" v-if="refDizaines">
        <li v-for="dizaineItem in refDizaines" :key="dizaineItem.id"
            :data-index="dizaineItem.id"
            :style="setPosition(dizaineItem.tx, dizaineItem.ty)"
            class="draggable"
        >
          <div class="with-value with-dragged-style" data-value="dizaine">
            <DeDizaineNoirEtBlanc1 class="dizaines bg-image"/>
          </div>
        </li>
      </ul>
      <ul class="colonne colonne-unites" v-if="refUnites">
        <li v-for="uniteItem in refUnites" :key="uniteItem.id"
            :data-index="uniteItem.id"
            :style="setPosition(uniteItem.tx, uniteItem.ty)"
            class="draggable"
        >
          <div class="with-value with-dragged-style" data-value="unite">
            <DeUniteNoirEtBlanc1 class="unites bg-image" />
          </div>
        </li>
      </ul>
    </div>

    <div class="chiffre">{{ totalCourant }}</div>

  </div>

</template>

<script setup>

  import {onMounted, ref, computed, watch, onBeforeMount} from "vue";
  import {getDizainesUnites} from "../../../js/utils";

  import { v4 as uuidv4 } from 'uuid';

  import DeDizaineNoirEtBlanc1 from "../Des/DesDizainesNoirEtBlanc/DeDizaineNoirEtBlanc1";
  import DeUniteNoirEtBlanc1 from "../Des/DesUnitesNoirEtBlanc/DeUniteNoirEtBlanc1";
  import {defineExpose} from "vue";


  //
  // Propriétés du composant
  //

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 0
    }
  });

  // const emit = defineEmits(['change'])


  //
  // Reactive values
  //

  // Id du composant dans le DOM
  const refId = ref(null);

  // Tableaux d'objects : { tx, ty, dragged }
  const refDizaines = ref([]);
  const refUnites = ref([]);

  const totalCourant = computed(() => {

    let total = 0;
    let i, obj;

    const dizainesObjects = refDizaines.value;
    const nd = dizainesObjects.length;
    for(i=0; i<nd; i++) {
      obj = dizainesObjects[i];
      if (!obj.dragged) {
        total += 10;
      }
    }

    const unitesObjects = refUnites.value;
    const nu = unitesObjects.length;
    for(i=0; i<nu; i++) {
      obj = unitesObjects[i];
      if (!obj.dragged) {
        total += 1;
      }
    }

    return total;
  });



  //
  // Lifecycle hooks
  //

  onBeforeMount(() => {
    // On crée un id pour el composant, pour pouvoir récupérer la hauteur
    refId.value = uuidv4();
  });

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });


  let index = 0;

  function update() {

    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites(chiffre);

    const totalDizaines = dizainesUnites.dizaines + 10 * dizainesUnites.centaines;
    const totalUnites = dizainesUnites.unites;

    const marginLeft = 60;
    const marginBottom = 90;

    const colonneElement = document.getElementById(refId.value);
    const bottom = colonneElement ? colonneElement.clientHeight - marginBottom : 400;

    const gapItem = 40;

    let i;

    refDizaines.value = [];

    for(i=0; i<totalDizaines; i++) {
      refDizaines.value.push({
        id: index++,
        tx: marginLeft,
        ty: bottom - i * gapItem,
        dragged: false
      });
    }

    refUnites.value = [];

    for(i=0; i<totalUnites; i++) {
      refUnites.value.push({
        id: index++,
        tx: marginLeft + 100,
        ty: bottom - i * gapItem,
        dragged: false
      });
    }

  }

  function setPosition( tx, ty ) {
    return "left:" + tx + "px; top:" + ty + "px";
  }


  //
  // Fonctions spécifiques
  //

  function findById(id) {

    let i, obj;

    const dizainesObjects = refDizaines.value;
    const nd = dizainesObjects.length;
    for(i=0; i<nd; i++) {
      obj = dizainesObjects[i];
      if (obj.id === id) {
        return obj
      }
    }

    const unitesObjects = refUnites.value;
    const nu = unitesObjects.length;
    for(i=0; i<nu; i++) {
      obj = unitesObjects[i];
      if (obj.id === id) {
        return obj
      }
    }

    return false;
  }

  //
  // Gestion des chiffres
  //

  const addChiffre = function(value, tx = 0, ty= 0) {
    // console.log(value, tx, ty);
    if (value === 1)
    {
      refUnites.value.push({
        id: index++,
        tx: tx,
        ty: ty,
        dragged: false
      });
    }
    else if (value === 10)
    {
      refDizaines.value.push({
        id: index++,
        tx: tx,
        ty: ty,
        dragged: false
      });
    }
  }

  const removeChiffre = function(id) {

    let i, obj;

    const dizainesObjects = refDizaines.value;
    const nd = dizainesObjects.length;
    for(i=0; i<nd; i++) {
      obj = dizainesObjects[i];
      if (obj.id === id) {
        dizainesObjects.splice(i, 1);
        return true;
      }
    }

    const unitesObjects = refUnites.value;
    const nu = unitesObjects.length;
    for(i=0; i<nu; i++) {
      obj = unitesObjects[i];
      if (obj.id === id) {
        unitesObjects.splice(i, 1);
        return true;
      }
    }

    return false;
  }

  const addDixUnites = function() {

    let i, tx = 20, ty = 20;

    for(i=0; i<10; i++) {

      refUnites.value.push({
        id: index++,
        tx: tx,
        ty: ty,
        dragged: false
      });

      ty += 40;
    }

  }



  // Pendant le drag, on retire la valeur déplacée du total

  const startDragChiffre = function(id) {
    const idNum = parseInt(id);
    const obj = findById(idNum);
    if (obj) {
      obj.dragged = true;
    }
  }

  const stopDragChiffre = function(id) {
    const idNum = parseInt(id);
    const obj = findById(idNum);
    if (obj) {
      obj.dragged = false;
    }
  }

  const clear = function() {
    refDizaines.value = [];
    refUnites.value = [];
  }

  defineExpose({
    addChiffre,
    removeChiffre,
    addDixUnites,
    startDragChiffre,
    stopDragChiffre,
    clear
  })


</script>

<style scoped lang="scss">

  .drop-zone-dizaines-unites {

    width: 100%;
    height: 100%;

    padding: 30px 30px 60px 30px;
    position: relative;

    .colonnes {

      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      ul.colonne {

        list-style-type: none;
        margin: 0;
        padding: 0;

        position: relative;

        /* Style des dés : identiques à ceux de la PaletteDizainesUnites */

        li {

          position: absolute;
          top:0;
          left:0;

          & > div {

            display: block;
            overflow: hidden;

            position: relative;

            list-style-type: none;

            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;

            /* White / 100 */
            color: #FFFFFF;

            background-color: #FFF;
            border-radius: 6px;
            box-shadow: 0 0 0 3px #FFF;

            & > .unites {
              width: 25px;
              height: 25px;
            }

            & > .dizaines > .de,
            & > .dizaines {
              width: 39px;
              height: 25px;
            }
          }
        }

      }

    }

    .chiffre {
      position: absolute;
      bottom: 0;
      left:50%;
      transform: translateX(-50%);
      padding: 12px 0;

      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      color: #666;
      opacity: 0.3;

      user-select: none;
    }
  }

</style>
