<template>
  <div class="de image-de"></div>
</template>

<style scoped lang="scss">

  .image-de {
    width: 28px;
    height: 20px;
    background-image: url(../../../../assets/images/svg/des_v2/dizaines_des_noir_et_blanc/Number_0.png);
  }

</style>
