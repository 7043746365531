<template>
  <div class="settings-tab-content">
    <div class="settings-tab-row flex flex-gap-10 flex-align-end">
      <div>Le double de</div>
      <InputNombre
          :input-value="currentInputValue"
          ref="inputText"
          text-label="Nombre"
          @change="onChangeInput"
      />
      <div>est égal à ...</div>
      <div>{{ doubleValue }}</div>
    </div>
  </div>
</template>


<script setup>

import InputNombre from "../Controls/InputNombre";
import {onMounted, ref, watch} from "vue";

//
// Propriétés du composant
//

const props = defineProps({
  tabId: {
    type: String,
    required: false,
    default: ""
  },
  tabValues: {
    type: Object,
    required: false,
    default: null
  },
});

// Référence des éléments du formulaire
const inputText = ref(null);

// Valeurs des différents onglets
const currentTabValues = ref();

// Valeur de l'onglet courant
const currentInputValue = ref("");

// Valeurs de l'interface de settings s'adaptant aux valeurs saisies
const doubleValue = ref("");

const emit = defineEmits(['change'])


//
// Lifecycle Hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

function update() {
  const valueObj = currentTabValues.value = props.tabValues;
  if ((valueObj !== null) && (valueObj.v !== undefined)) {
    currentInputValue.value = valueObj.v;
  } else {
    currentInputValue.value = "";
    inputText.value.reset();
  }
}

function onChangeInput(inputValue) {
  currentTabValues.value = { v: inputValue };
  doubleValue.value = inputValue * 2;
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
}

</script>

<style scoped lang="scss">

</style>