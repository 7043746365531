<template>
  <div class="settings-panel">
    <h1>Réglages</h1>
    <div class="setting-options">
      <div class="setting-type setting-shape-options">

        <h2>Forme des Dés</h2>

        <div class="setting-group setting-group-forme">

            <div class="setting-bg de-fond-blanc-noir-et-blanc" :class="deClassiqueCssClass" :data-setting="deClassiqueCss">
                <div class="setting des-carres half-size" @click="selectionDeClassique">
                    <div class="de-parent">
                         <div class="de chiffre6"></div>
                      </div>
                    <div class="setting-de-name">Classique</div>
                  </div>
              </div>

            <div class="setting-bg de-fond-blanc-noir-et-blanc" :class="deObliqueCssClass" :data-setting="deObliqueCss">
                <div class="setting des-carres half-size" @click="selectionDeOblique">
                    <div class="de-parent">
                        <div class="de chiffre6"></div>
                      </div>
                    <div class="setting-de-name">Oblique</div>
                  </div>
              </div>

            <div class="setting-bg de-fond-blanc-noir-et-blanc" :class="deBilleCssClass" :data-setting="deBilleCss">
                <div class="setting des-carres" @click="selectionBille">
                    <div class="de-parent">
                        <div class="de chiffre6"></div>
                      </div>
                    <div class="setting-de-name">Billes</div>
                  </div>
              </div>

            </div>

        <h2>Couleurs des Dés</h2>

        <div class="setting-group setting-group-couleur">

            <div class="setting-bg" :class="deFondBlancNoirEtBlancCssClass" :data-setting="deFondBlancNoirEtBlancCss">
                <div class="setting des-carres half-size" @click="selectionFondBlancNoirEtBlanc">
                    <div class="de-parent">
                         <div class="de chiffre6"></div>
                      </div>
                    <div class="setting-de-name">Noir et blanc</div>
                  </div>
              </div>

            <div class="setting-bg" :class="deFondBlancCssClass" :data-setting="deFondBlancCss">
                <div class="setting des-carres half-size" @click="selectionFondBlanc">
                    <div class="de-parent">
                        <div class="de chiffre6"></div>
                      </div>
                    <div class="setting-de-name">Clair</div>
                  </div>
              </div>

            <div class="setting-bg" :class="deFondBleuCssClass" :data-setting="deFondBleuCss">
                <div class="setting des-carres half-size" @click="selectionFondBleu">
                    <div class="de-parent">
                        <div class="de chiffre6"></div>
                      </div>
                    <div class="setting-de-name">Foncés</div>
                  </div>
              </div>

            </div>
      </div>
    </div>    
  </div>
</template>

<script setup>

  import { ref, computed, onMounted } from "vue";
  import { useStore } from 'vuex';

  import {
    createCookie,
    readCookie
  } from "../../js/cookies";

  const store = useStore();

  // Formes : valeurs de styles
  const deClassiqueCss = "de-classique";
  const deObliqueCss = "de-oblique";
  const deBilleCss = "de-bille";

  // Couleurs : valeurs de styles
  const deFondBleuCss = "de-fond-bleu";
  const deFondBlancCss = "de-fond-blanc";
  const deFondBlancNoirEtBlancCss = "de-fond-blanc-noir-et-blanc";


  // Valeurs actives par défaut :
  let activeClassiqueCss = ref("active");
  let activeObliqueCss = ref("");
  let activeBilleCss = ref("");
  let activeFormeCss = ref(deClassiqueCss);

  let activeFondBleuCss = ref("active");
  let activeFondBlancCss = ref("");
  let activeFondBlancNoirEtBlancCss = ref("");
  let activeCouleurCss = ref(deFondBleuCss);

  // Css classes :

  const deClassiqueCssClass = computed(() => deClassiqueCss + ' ' + activeClassiqueCss.value)
  const deObliqueCssClass = computed(() => deObliqueCss + ' ' + activeObliqueCss.value)
  const deBilleCssClass = computed(() => deBilleCss + ' ' + activeBilleCss.value)

  const deFondBlancNoirEtBlancCssClass = computed(() => activeFormeCss.value + ' ' + deFondBlancNoirEtBlancCss + ' ' + activeFondBlancNoirEtBlancCss.value)
  const deFondBlancCssClass = computed(() => activeFormeCss.value + ' ' + deFondBlancCss + ' ' + activeFondBlancCss.value)
  const deFondBleuCssClass = computed(() => activeFormeCss.value + ' ' + deFondBleuCss + ' ' + activeFondBleuCss.value)


  // Formes :

  const selectionDeClassique = function() {
    activeFormeCss.value = deClassiqueCss;
    activeClassiqueCss.value = "active";
    activeObliqueCss.value = "";
    activeBilleCss.value = "";
    saveSettings();
  }

  const selectionDeOblique = function() {
    activeFormeCss.value = deObliqueCss;
    activeClassiqueCss.value = "";
    activeObliqueCss.value = "active";
    activeBilleCss.value = "";
    saveSettings();
  }

  const selectionBille = function() {
    activeFormeCss.value = deBilleCss;
    activeClassiqueCss.value = "";
    activeObliqueCss.value = "";
    activeBilleCss.value = "active";
    saveSettings();
  }

  // Couleurs :

  const selectionFondBlancNoirEtBlanc = function() {
    activeCouleurCss.value = deFondBlancNoirEtBlancCss;
    activeFondBlancNoirEtBlancCss.value = "active";
    activeFondBlancCss.value = "";
    activeFondBleuCss.value = "";
    saveSettings();
  }

  const selectionFondBlanc = function() {
    activeCouleurCss.value = deFondBlancCss;
    activeFondBlancNoirEtBlancCss.value = "";
    activeFondBlancCss.value = "active";
    activeFondBleuCss.value = "";
    saveSettings();
  }

  const selectionFondBleu = function() {
    activeCouleurCss.value = deFondBleuCss;
    activeFondBlancNoirEtBlancCss.value = "";
    activeFondBlancCss.value = "";
    activeFondBleuCss.value = "active";
    saveSettings();
  }

  //
  // Settings
  //

  function saveSettings() {

    var settingsObj = { };
    settingsObj['forme_des'] = activeFormeCss.value;
    settingsObj['couleur_des'] = activeCouleurCss.value;

    // console.log("saveSettings", settingsObj);

    // Store Vuex :
    store.dispatch('saveSettings', settingsObj);

    // Cookie
    createCookie("cognitice-settings", JSON.stringify(settingsObj));
  }

  function getSettings() {
    var cogniticeCookie = readCookie("cognitice-settings");
    var settingsObj;

    if (cogniticeCookie && cogniticeCookie.length > 2) {
      settingsObj = JSON.parse( cogniticeCookie );
    }

    return settingsObj;
  }

  onMounted(() => {

    var settingsObj = getSettings();
    if (settingsObj)
    {
      var settingFormeDes = settingsObj.forme_des;
      var settingCouleurDes = settingsObj.couleur_des;

      console.log("setting dés", settingFormeDes, settingCouleurDes);

      // Forme :

      switch(settingFormeDes) {
        case deClassiqueCss :
          activeFormeCss.value = deClassiqueCss;
          activeClassiqueCss.value = "active";
          activeObliqueCss.value = "";
          activeBilleCss.value = "";
          break;
        case deObliqueCss :
          activeFormeCss.value = deObliqueCss;
          activeClassiqueCss.value = "";
          activeObliqueCss.value = "active";
          activeBilleCss.value = "";
          break;
        case deBilleCss :
          activeFormeCss.value = deBilleCss;
          activeClassiqueCss.value = "";
          activeObliqueCss.value = "";
          activeBilleCss.value = "active";
          break;

        default:
          console.log("valeur non reconnue : ", settingFormeDes);
      }

      console.log("activeFormeCss =", activeFormeCss, deObliqueCss);

      // Couleur :

      switch(settingCouleurDes) {
        case deFondBlancNoirEtBlancCss :
          activeCouleurCss.value = deFondBlancNoirEtBlancCss;
          activeFondBlancNoirEtBlancCss.value = "active";
          activeFondBlancCss.value = "";
          activeFondBleuCss.value = "";
          break;
        case deFondBlancCss :
          activeCouleurCss.value = deFondBlancCss;
          activeFondBlancNoirEtBlancCss.value = "";
          activeFondBlancCss.value = "active";
          activeFondBleuCss.value = "";
          break;
        case deFondBleuCss :
          activeCouleurCss.value = deFondBleuCss;
          activeFondBlancNoirEtBlancCss.value = "";
          activeFondBlancCss.value = "";
          activeFondBleuCss.value = "active";
          break;
      }
    }
    else
    {
      console.log("settingDes", "aucun...");
    }

  });

</script>

<style lang="scss">

  .settings-panel {

    display: none;
    pointer-events: none;
    text-align: center;
    background-color: #fff;

    &.settings-opened {
      display: block;
      height: 100vh;
      position: relative;
      top: 0;
      pointer-events: auto;
    }

    h1 {
      margin-top: 30px;
    }

    h2 {
      text-align: left;
      margin-top: 30px;
    }

    .de-bille .de {
      background-size: contain;
    }

    .setting-group {
      display: inline-block;
    }

    .setting-options {
    }

    .setting-group > div {
      float: left;
      width: 221px;
      text-align: center;
    }

    .setting-group > div .de-parent {
      display: inline-block;
      height: 66px;
    }

    .de-bille .de-parent {
      width: 166px;
      height: 71px;
    }

    .save-settings-btn {
      display: inline-block;
      padding: 20px;
      margin: 100px;
      border:solid 2px black;
      background-color: #999999;
      color:#FFFFFF;
    }

    .setting-bg {
    }

    .setting-bg .setting {
      border-radius: 16px;
      border: 5px solid rgba(0,0,0,0);
      padding: 10px;
    }

    .setting-bg.active {
    }

    .setting-bg.active .setting {
      border-color: #15299C;
    }

    .setting-bg.de-parent {
      border: none;
    }

    .setting-de-name {
      padding: 20px 0 10px 0;
    }

  }

</style>
