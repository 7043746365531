<template>
  <ul>
    <li>
      <router-link to="/le-projet">A propos</router-link>
    </li>
    <li>
      <router-link to="/partenaires">Partenaires</router-link>
    </li>
    <li>
      <router-link to="/guide-enseignant">Guide de l'enseignant</router-link>
    </li>
  </ul>
</template>

<script setup>
</script>

<style scoped lang="scss">

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;

    & > li {
      margin-right: 5px;

      & > a {
        display: inline-block;
        padding: 15px;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        color: #000000;
        text-decoration: none;

        @include on-mobile-landscape {
          font-size: 14px;
          line-height: 1.2;
        }

        &:hover {
          text-decoration: underline;
        }
      }

    }
  }


</style>
