<template>
  <div class="settings-tab-content">
    <div class="settings-tab-row flex flex-gap-20 flex-align-end">
      <InputNombre
          ref="inputText1"
          input-id="nombre1"
          text-label="Nombre 1"
          :input-value="currentInput1Value"
          @change="onChangeInput1"
      />
      <div>
        <div></div>
        <div>x</div>
      </div>
      <InputNombre
          ref="inputText2"
          input-id="nombre2"
          text-label="Nombre 2"
          :input-value="currentInput2Value"
          @change="onChangeInput2"
      />
      <div style="margin: 0 10px; font-weight: bold">
        <div></div>
        <div>=</div>
      </div>
      <div>
        <div></div>
        <div>{{ multiplicationValue }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>

import InputNombre from "../Controls/InputNombre";
import {onMounted, ref, watch} from "vue";

//
// Propriétés du composant
//

const props = defineProps({
  tabId: {
    type: String,
    required: false,
    default: ""
  },
  tabValues: {
    type: Object,
    required: false,
    default: null
  },
});

// Référence des éléments du formulaire
const inputText1 = ref(null);
const inputText2 = ref(null);

// Valeurs des différents onglets
const currentTabValues = ref();

// Valeur de l'onglet courant
const currentInput1Value = ref("");
const currentInput2Value = ref("");

// Valeurs de l'interface de settings s'adaptant aux valeurs saisies
const multiplicationValue = ref("--");

const emit = defineEmits(['change'])


//
// Lifecycle Hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

function update() {
  // console.log("props.tabValues", JSON.stringify(props.tabValues));
  const valueObj = currentTabValues.value = props.tabValues;

  // Champ texte 1 :
  if ((valueObj !== null) && (valueObj.v1 !== undefined)) {
    currentInput1Value.value = valueObj.v1;
  } else {
    console.log("reset1")
    currentInput1Value.value = "";
    inputText1.value.reset();
  }

  // Champ texte 2 :
  if ((valueObj !== null) && (valueObj.v2 !== undefined)) {
    currentInput2Value.value = valueObj.v2;
  } else {
    console.log("reset2")
    currentInput2Value.value = "";
    inputText2.value.reset();
  }

  console.log("currentInput1Value.value", currentInput1Value.value, currentInput2Value.value)

  updateMultiplicationValue();
}

function updateMultiplicationValue() {
  const values = currentTabValues.value;
  if (values && ! isNaN(values.v1) && ! isNaN(values.v2)) {
    let mv = parseInt(values.v2) * parseInt(values.v1);
    multiplicationValue.value = isNaN(mv) ? "--" : mv;
  } else {
    multiplicationValue.value = "--";
  }
}

//
// Events
//

function onChangeInput1(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v1: inputValue, v2: "" };
  } else {
    currentTabValues.value.v1 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateMultiplicationValue();
}

function onChangeInput2(inputValue) {
  if (! currentTabValues.value) {
    currentTabValues.value = { v2: inputValue, v1: "" };
  } else {
    currentTabValues.value.v2 = inputValue;
  }

  // console.log("change", JSON.stringify(currentTabValues.value));
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });

  updateMultiplicationValue();
}

</script>

<style scoped lang="scss">

</style>