<template>

    <div class="card" :class="cssClass">
      <div class="with-dragged-style">
        <div class="card-border">
          <div class="card-content" :class="stateCssClass">
            <div v-if="! hasOperation" :data-value="chiffre">
              <div v-if="(chiffre > 999999) || (decompositionMininum > 6)" class="millions"
              >
                <DeCentaineMillierComplex
                    :chiffre="10"
                    :withSound="props.withSound"
                    :withSoundDecomposed="props.withSoundDecomposed"
                    :css-class="cssClass"
                />
                <span v-if="withNumber">
                  {{ v_millions }}
                </span>
            </div>
              <div v-if="(chiffre > 99999) || (decompositionMininum > 5)" class="centaines-milliers"
              >
                <span v-if="isCodeMix4">
                  <DeDizaineMillierComplex
                      :chiffre="10"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-else>
                  <DeCentaineMillierComplex
                      :chiffre="v_centaines_milliers"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-if="withNumber">
                  {{ v_centaines_milliers }}
                </span>
              </div>
              <div v-if="(chiffre > 9999) || (decompositionMininum > 4)" class="dizaines-milliers">
                <span v-if="isCodeMix3">
                  <DeMillierComplex
                      :chiffre="10"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-else>
                  <DeDizaineMillierComplex
                      :chiffre="v_dizaines_milliers"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-if="withNumber">
                  {{ v_dizaines_milliers }}
                </span>
              </div>
              <div v-if="(chiffre > 999) || (decompositionMininum > 3)" class="milliers">
                <span v-if="isCodeMix2">
                  <DeCentaineComplex
                      :chiffre="10"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-else>
                  <DeMillierComplex
                      :chiffre="v_milliers"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-if="withNumber">
                  {{ v_milliers }}
                </span>
              </div>
              <div v-if="(chiffre > 99) || (decompositionMininum > 2)" class="centaines">
                <span class="ok" v-if="isCodeSimple && (v_centaines === 1)">
                  <DeDizaineCouleur
                      :chiffre="10"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span class="pas ok" v-else>
                  <DeCentaineComplex
                      :chiffre="v_centaines"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-if="withNumber">
                  {{ v_centaines }}
                </span>
              </div>
              <div v-if="decomposition && ((chiffre > 9) || (decompositionMininum > 1))" class="dizaines">
                <span v-if="isCodeSimple || isCodeMix">
                  <DeDizaineCouleur
                      :chiffre="v_dizaines"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-else>
                  <DeDizaineComplex
                      :chiffre="v_dizaines"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-if="withNumber">
                  {{ v_dizaines }}
                </span>
              </div>
              <div class="unites">
                <span v-if="isCodeSimple || isCodeMix">
                  <DeUniteLarge
                      v-if="withLargeImage"
                      :chiffre="v_unites"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                  <DeUnite
                      v-else
                      :chiffre="v_unites"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-else>
                  <DeUniteComplex
                      :chiffre="v_unites"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span v-if="withNumber">
                  {{ v_unites }}
                </span>
                <span v-if="withTable" class="multiplication">
                  {{ v_multiplication }} x {{ v_unites }}
                </span>
              </div>
              <div v-if="imageIsHidden && hasShowHiddenToggle" class="hidden-bg" @mousedown="show" @touchstart="show"></div>
              <div v-if="imageIsHidden && !hasShowHiddenToggle" class="hidden-bg locked"></div>
            </div>
            <div v-else :data-value="chiffre">
              <div v-for="(item, index) in decompositionOperation"
                   v-bind:key="index"
                   class="unites operation">
                <span>
                  <DeUnite
                      :chiffre="parseInt(item)"
                      :withSound="props.withSound"
                      :withSoundDecomposed="props.withSoundDecomposed"
                      :css-class="cssClass"
                  />
                </span>
                <span>
                  {{ item }}
                  <span class="signe-operation">{{ operation }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasValidation" class="validation">
        <LedValidation :class="validationCssClass" />
      </div>
    </div>

</template>


<script setup>

  import {ref, onMounted, watch, computed } from "vue";
  import { getDizainesUnites } from "../../js/utils";
  import DeCentaineMillierComplex from "./Des/DesCentainesMilliersComplex/DeCentaineMillierComplex";
  import DeDizaineMillierComplex from "./Des/DesDizainesMilliersComplex/DeDizaineMillierComplex";
  import DeMillierComplex from "./Des/DesMilliersComplex/DeMillierComplex";
  import DeCentaineComplex from "./Des/DesCentainesComplex/DeCentaineComplex";
  import DeDizaineComplex from "./Des/DesDizainesComplex/DeDizaineComplex";
  import DeUniteComplex from "./Des/DesUnitesComplex/DeUniteComplex";
  import DeUnite from "./Des/DesUnites/DeUnite";
  import DeUniteLarge from "./Des/DesUnites/large/DeUniteLarge";
  import DeDizaineCouleur from "./Des/DesDizainesCouleur/DeDizaineCouleur";
  import LedValidation from "./Validation/LedValidation";

  const props = defineProps({
    code: {
      type: String,
      required: false,
      default: "simple"
    },
    cssClass: {
      type: String,
      required: false,
      default: ""
    },
    chiffre: {
      type: [String, Number],
      required: true,
      default: 1
    },
    multiplication : {
      type: Number,
      required: false,
      default: 1
    },
    operation : {
      type: String,
      required: false,
      default: "+"
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    hasShowHiddenToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    decomposition: {
      type: Boolean,
      required: false,
      default: true
    },
    decompositionMininum: {
      type: Number,
      required: false,
      default: 1
    },
    hasNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSeparator: {
      type: Boolean,
      required: false,
      default: true
    },
    hasMultiplication: {
      type: Boolean,
      required: false,
      default: false
    },
    hasOperation: {
      type: Boolean,
      required: false,
      default: false
    },
    hasValidation: {
      type: Boolean,
      required: false,
      default: false
    },
    withLargeImage: {
      type: Boolean,
      required: false,
      default: false
    },
    withSoundDecomposed: {
      type: Boolean,
      required: false,
      default: false
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    validationCssClass: {
      type: String,
      required: false,
      default: ""
    }
  });


  //
  // Reactive values
  //

  // Valeur numérique
  let millions = ref(0),
      centaines_milliers = ref(0),
      dizaines_milliers = ref(0),
      milliers = ref(0),
      centaines = ref(0),
      dizaines = ref(0),
      unites = ref(0);

  // Valeur string
  let v_millions = ref(''),
      v_centaines_milliers = ref(''),
      v_dizaines_milliers = ref(''),
      v_milliers = ref(''),
      v_centaines = ref(''),
      v_dizaines = ref(''),
      v_unites = ref(''),
      v_multiplication = ref('');

  let code = ref();
  let decompositionMininum = ref();
  let withNumber = ref();
  let withSeparator = ref();
  let withTable = ref();
  let decompositionOperation = ref(null );
  let imageIsHidden = ref();


  /* Computed */

  const stateCssClass = computed(() => {
    return [
      imageIsHidden.value ? "is-hidden" : '',
      props.hasNumber ? "" : 'without-number',
      props.hasSeparator ? "" : 'without-separator',
      props.hasMultiplication ? "" : 'without-multiplication'
    ].join(' ');
  });

  const isCodeSimple = computed(() => {
    return (props.code === "simple") && (props.chiffre < 200);
  });

  const isCodeMix = computed(() => {
    return (props.code === "mix") && (props.chiffre < 200);
  });

  const isCodeMix2 = computed(() => {
    return (props.code === "mix2") && (props.chiffre === 1000);
  });

  const isCodeMix3 = computed(() => {
    return (props.code === "mix3") && (props.chiffre === 10000);
  });

  const isCodeMix4 = computed(() => {
    return (props.code === "mix4") && (props.chiffre === 100000);
  });


  //
  // LifeCycle hooks
  //

  const update = function() {

    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites(chiffre);
    const isHidden = imageIsHidden.value;

    // Décomposition
    if (props.decomposition) {
      millions.value = dizainesUnites.millions;
      centaines_milliers.value = dizainesUnites.centaines_milliers;
      dizaines_milliers.value = dizainesUnites.dizaines_milliers;
      milliers.value = dizainesUnites.milliers;
      centaines.value = dizainesUnites.centaines;
      dizaines.value = dizainesUnites.dizaines;
      unites.value = dizainesUnites.unites;
    } else {
      unites.value = chiffre;
    }

    v_millions.value = isHidden ? '?' : (chiffre < 100000 && millions.value === 0 ? '' : millions.value);
    v_centaines_milliers.value = isHidden ? '?' : (chiffre < 100000 && centaines_milliers.value === 0 ? '' : centaines_milliers.value);
    v_dizaines_milliers.value = isHidden ? '?' : (chiffre < 10000 && dizaines_milliers.value === 0 ? '' : dizaines_milliers.value);
    v_milliers.value = isHidden ? '?' : (chiffre < 1000 && milliers.value === 0 ? '' : milliers.value);
    v_centaines.value = isHidden ? '?' : (chiffre < 100 && centaines.value === 0 ? '' : centaines.value);
    v_dizaines.value = isHidden ? '?' : (chiffre < 10 && dizaines.value === 0 ? '' : dizaines.value);
    v_unites.value = isHidden ? '?' : unites.value;
    v_multiplication.value = isHidden ? '?' : props.multiplication;


    // simple
    // mix, mix2, mix3, mix4
    // complex
    code.value = props.code;

    // Nombre de décimales affichées ( 5 s'affiche pour 05 si decompositionMininum > 1 )
    decompositionMininum.value = props.decompositionMininum;

    // Nombre affiché ?
    withNumber.value = props.hasNumber === true;

    // Séparateur de milliers ?
    withSeparator.value = props.hasSeparator === true;

    // Table de multiplication ?
    withTable.value = props.hasMultiplication === true;

    if (props.hasOperation) {
      decompositionOperation.value = String(props.chiffre).split('');
    }

    // console.log(chiffre, isHidden, dizaines.value, chiffre < 10);
  };

  onMounted(() => {
    imageIsHidden.value = props.isHidden;
    update();
  });

  watch( props, () => {
    imageIsHidden.value = props.isHidden;
    update();
  });

  //
  // Fonctions spécifiques
  //

  const show = function() {
    if (imageIsHidden.value) {
      imageIsHidden.value = false;
      update();
    }
    document.body.addEventListener('mouseup', hide);
    document.body.addEventListener('touchend', hide);
  }

  const hide = function() {
    if (! imageIsHidden.value) {
      imageIsHidden.value = true;
      update();
    }
    document.body.removeEventListener('mouseup', hide);
    document.body.removeEventListener('touchend', hide);
  }

</script>

<style scoped lang="scss">

  .card {

    display: inline-block;
    text-align: center;

    .validation {
      display: inline-block;
    }

    /* Version N&B : doit-on retirer les couleurs de la typo et des fonds ? */
    &.noir-et-blanc .unites,
    &.noir-et-blanc .dizaines,
    &.noir-et-blanc .centaines,
    &.noir-et-blanc .milliers,
    &.noir-et-blanc .dizaines-milliers,
    &.noir-et-blanc .centaines-milliers,
    &.noir-et-blanc .millions {
        color: rgba(51, 51, 51, 1) !important; // #FF3F33;

      & > span:first-child {
        background: rgba(51, 51, 51, 0.1) !important;
      }
    }

    &.figures-only {
      .card-content > div > div > span:first-child {
        display: none;
      }
      .card-content > div > div > span:last-child {
        height: 180px;
      }
    }

    .card-border {
      display: inline-block;
      background: #FFFFFF;
      border-radius: 22px;
      border: solid 8px #FFF;
    }

    .card-content {
      display: inline-block;
      border: 2px solid #666666;
      border-radius: 16px;
      padding: 8px;
      user-select: none;

      & > div {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 52px;

        position: relative;
        overflow: hidden;

        display: flex;
        gap: 8px;
        border-radius: 8px;

        @include on-print {
          gap: 7px;
        }

        & > div {
          display: flex;
          flex-direction: column;
          width: 96px;

          @include on-small-desktop {
            width: $de_small_desktop_width;
          }

          @include on-mobile-landscape {
            width: $de_mobile_width;
          }

          @include on-print {
            width: $de_print_width;
          }

          & > span {
            display: flex;
            align-content: flex-start;
            align-items: center;
            justify-content: center;
            border-radius: 8px;

            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 52px;
          }

          & > span:last-child {
            height: 69px;
          }

          & > span:first-child {
            min-height: 96px;

            @include on-small-desktop {
              min-height: 85px;
            }

            @include on-mobile-landscape {
              min-height: 85px;
            }

            @include on-print {
              min-height: 85px;
            }
          }

        }
      }

      &.is-hidden {
        position: relative;
      }

      & > div > .hidden-bg {
        width: 100%;
        height: 100%;
        display: inline-block;
        background: #f0f0f0 url("../../assets/images/svg/search.svg") center no-repeat;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        visibility: visible;

        &.locked {
          background-image: none;
          pointer-events: none;
        }
      }

      &.is-hidden > div {
        visibility: visible;
        position: relative;
      }

      &.is-hidden.without-number > div {
        visibility: hidden;
      }

      &.is-hidden.without-multiplication > div {
        visibility: hidden;
      }
    }

    .milliers,
    .unites {
      color:  rgba(44, 133, 237, 1); // #2C85ED;
    }

    .millions > span:first-child,
    .milliers > span:first-child,
    .unites > span:first-child {
      background: rgba(44, 133, 237, 0.1);
    }

    .unites.operation:not(:last-child) {
      margin-right: 8px;
    }

    .unites.operation span:last-child {
      position: relative;
    }

    .unites.operation span.signe-operation {
      content: "+";
      color: #333333;
      position: absolute;
      z-index: 2;
      right: -21px;
    }

    .unites.operation:last-child span.signe-operation {
      display: none;
    }

    .is-hidden .milliers,
    .is-hidden .unites {
      color:  rgba(44, 133, 237, 0.5); // #2C85ED;
    }

    .dizaines-milliers,
    .dizaines {
      color: rgba(255, 63, 51, 1); // #FF3F33;
    }

    .dizaines-milliers > span:first-child,
    .dizaines > span:first-child {
      background: rgba(255, 63, 51, 0.1);
    }

    .is-hidden .dizaines-milliers,
    .is-hidden .dizaines {
      color: rgba(255, 63, 51, 0.5); // #FF3F33;
    }

    .centaines-milliers,
    .centaines {
      color: rgba(88, 206, 69, 1); // #FF3F33;
    }

    .centaines-milliers > span:first-child,
    .centaines > span:first-child {
      background: rgba(88, 206, 69, 0.1);
    }

    .millions > span:nth-child(2),
    .milliers > span:nth-child(2) {
      position: relative;
    }

    .millions > span:nth-child(2):after,
    .milliers > span:nth-child(2):after {
      content: '.';
      display: inline-block;
      position: absolute;
      right: -9px;
      bottom: 9px;

      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      color: #666666;
    }

    .without-separator .millions > span:nth-child(2):after,
    .without-separator .milliers > span:nth-child(2):after {
      display: none;
    }

    .millions:first-child,
    .milliers:first-child {
      color: rgba(51, 51, 51, 1); // #FF3F33;
    }

    .is-hidden .milliers:first-child {
      color: rgba(51, 51, 51, 0.5); // #FF3F33;
    }

    .milliers:first-child > span:first-child {
      background: rgba(51, 51, 51, 0.1);
    }

    .is-hidden .centaines-milliers,
    .is-hidden .centaines {
      color: rgba(88, 206, 69, 0.5); // #FF3F33;
    }

    .is-hidden .centaines-milliers > span:first-child,
    .is-hidden .centaines > span:first-child {
      background: rgba(88, 206, 69, 0.1);
    }

    .is-hidden.without-number span:before {
      content: '?';
    }

    .is-hidden.without-multiplication span:before {
      content: '?';
    }

    // AUDREY
    .multiplication { 
      font-size:32px !important;
      letter-spacing: -1px;
      color: #333333;
    }
  }



</style>
