<template>
  <div class="header-activite" >
    <div>
      <h1 class="titan">{{ title }}</h1>
      <p class="username">{{ username }}</p>
    </div>
    <router-link :to="backRoute" class="back-button" ></router-link>
    <div class="buttons">
      <!-- Boutons Parcours -->
      <router-link v-if="hasReplay" :to="replayRoute" class="restart-button" ></router-link>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref, watch} from "vue";
  import {useRouter} from "vue-router";

  const router = useRouter();

  //
  // Propriétés
  //

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: "Titre du parcours"
    },
    parcoursSlug: {
      type: String,
      required: false,
      default: ""
    },
    username: {
      type: String,
      required: false,
      default: ""
    }
  });

  const backRoute = ref(router.resolve({
        path: "/sommaire",
        hash: "#parcours"
      })
  );

  const hasReplay = ref(false);
  const replayRoute = ref(null);


  //
  // Lifecycle Hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  //
  // Init
  //

  const init = function() {

    hasReplay.value = props.parcoursSlug.length > 0;

    console.log(hasReplay.value)
    if (hasReplay.value) {
      replayRoute.value = router.resolve({
            name: 'Sommaire Parcours',
            params: { id: props.parcoursSlug }
          }
      );
    }
  }

</script>

<style scoped lang="scss">

  .header-activite {

    position: relative;
    margin-bottom: 40px;

    @include on-small-height-desktop {
      margin-bottom: 0;
    }

    @include on-print {
      margin-bottom: 25px;
    }

    h1, h2 {
      display: block;
      width: 100%;
      text-align: center;
    }

    h1 {
      padding-top: 20px;
    }

    h1.titan {
      font-size: 48px;
      line-height: 54px;
      -webkit-text-stroke-width: 1px;
      text-shadow: 0px 4px 0px #333333;

      @include on-small-height-desktop {
        font-size: 42px;
        line-height: 46px;
      }

      @include on-mobile {
        font-size: 30px;
        line-height: 1.2;
      }

      @include on-print {
        padding-top: 0 !important;
        font-size: 42px;
        line-height: 46px;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 400;
      padding-top: 4px;

      @include on-small-height-desktop {
        font-size: 24px;
      }

      @include on-print {
        font-size: 20px;
      }
    }

    &.sans-niveau {
      h2 {
        display: none;
      }
    }

    .username {
      margin-top: 10px;

      font-size: 25px;
      line-height: 32px;
      text-align: center;
    }


    /* Back */

    a.back-button {
      position: absolute;
      top: 20px;
      left: 0;

      background: url(../../../assets/images/svg/icones_v2/Back_4x.png) center / cover no-repeat;
      width:63px;
      height:71px;

      /*
      border: 2px solid #333333;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #333333;
      border-radius: 10px;
      */


      @include on-print {
        display: none;
      }
    }

    a.restart-button {
      position: absolute;
      top: 20px;
      right: 0;

      background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / cover no-repeat;
      width:63px;
      height:71px;

      @include on-print {
        display: none;
      }
    }


  }

</style>