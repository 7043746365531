<template>
  <div class="clavier-chiffres-v2">
    <!-- Chiffres -->
    <ul :class="cssClass" >
        <li class="draggable" >
          <div data-value="0" class="with-value with-dragged-style">
            <span>0</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="1" class="with-value with-dragged-style">
            <span>1</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="2" class="with-value with-dragged-style">
            <span>2</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="3" class="with-value with-dragged-style">
            <span>3</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="4" class="with-value with-dragged-style">
            <span>4</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="5" class="with-value with-dragged-style">
            <span>5</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="6" class="with-value with-dragged-style">
            <span>6</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="7" class="with-value with-dragged-style">
            <span>7</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="8" class="with-value with-dragged-style">
            <span>8</span>
          </div>
        </li>
        <li class="draggable" >
          <div data-value="9" class="with-value with-dragged-style">
            <span>9</span>
          </div>
        </li>
    </ul>
    <!-- Réservoir à gommettes -->
    <div class="parent-gommettes" v-if="avecGommettes">
      <Gommettes ref="gommettes" />
    </div>
  </div>
</template>

<script setup>

  import {computed, ref, defineExpose} from "vue";
  import Gommettes from "../Gommettes/Gommettes";

  const props = defineProps({
    nbTouchesParLigne: {
      type: Number,
      required: false,
      default: 10
    },
    avecGommettes: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  let gommettes = ref(null);

  const cssClass = computed(() => {
    switch (parseInt(props.nbTouchesParLigne)) {
      case 3 :
        return 'trois-par-ligne'
      case 5 :
        return 'cinq-par-ligne'
    }

    return '';
  });


  /* Public methods */

  const resetGommettes = function() {
    // Retour à la position initiale des gommettes
    if (gommettes.value) gommettes.value.reset()
  }

  defineExpose({
    resetGommettes
  });


</script>

<style scoped lang="scss">

  /* Clavier 0 à 9 + gomme */

  .clavier-chiffres-v2 {

    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 0;

    // Bg et border : clavier + réservoir à gommettes
    ul,
    .parent-gommettes {
      /* Light Grey/90 -100 */
      background: #E6E6E6;

      /* Light Grey/95 - 100 */
      border: 8px solid #F2F2F2;
      /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25); */
      border-radius: 32px 32px 0px 0px;
      border-bottom: none;

      @include on-print {
        background: none;
        border: none;
      }
    }

    .parent-gommettes {
      width: 200px;
      height: 144px;
    }

    ul {
      list-style-type: none;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;

      padding: 24px;
      margin-bottom: 0;

      &.cinq-par-ligne {
        flex-wrap: wrap;
        width: 370px;
        padding-bottom: 20px;
      }

      li > div {
        display: block;
        width: 58px;
        height: 88px;
        overflow: hidden;

        position: relative;

        list-style-type: none;
        cursor: pointer;

        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;

        /* White / 100 */
        color: #FFFFFF;

        border: solid 2px rgba(51, 51, 51, 0.5);
        background: #E6E6E6;
        border-radius: 8px;

        box-shadow: 0 0 0 5pt #FFF;

        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 100%;

          position: relative;
          z-index: 2;

          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #333333;

          text-shadow: 0px 2px 0px #333333;
        }

        &::before {
          content: '';
          display: block;
          width: 3.51px;
          height: 114px;

          position: absolute;
          top: -24.56px;
          left: 7.19px;

          /* Light Grey/95 - 100 */
          background: #F2F2F2;
          opacity: 0.5;
          transform: rotate(33.66deg);
        }

        &::after {
          content: '';
          display: block;

          position: absolute;
          width: 19.1px;
          height: 114px;
          top: -29px;
          left: 17.12px;

          /* Light Grey/95 - 100 */
          background: #F2F2F2;
          opacity: 0.5;
          transform: rotate(33.66deg);
        }

      }




      &.cinq-par-ligne li:nth-child(5n) {
        margin-right: 0;
      }

      &.cinq-par-ligne li:nth-child(5n+1) {
        clear: both;
      }

      &.trois-par-ligne li:nth-child(3n) {
        margin-right: 0;
      }

      &.trois-par-ligne li:nth-child(3n+1) {
        clear: both;
      }
    }
  }

</style>
