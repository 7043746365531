<template>
  <header>
    <div class="content">
      <!-- Dedys -->
      <router-link to="/">
        <DedysLogo />
      </router-link>
    </div>
  </header>
  <div class="content">

    <div class="qui-sommes-nous">
      <h2>Avigal Amar-Tuillier</h2>
      <h3>Enseignante en Ulis-collège à Paris</h3>
      <p>Directrice de <a href="http://www.cognitice.education/" target="_blank">Cognitice</a> et initiatrice du projet <a href="http://www.dedys.fr/" target="_blank">Dédys</a></p>
    </div>

    <div class="liens">
      <p>
        <span>Suivez-nous !</span>
        <a class="twitter-follow-button" href="https://twitter.com/avigalat" target="_blank">@avigalat</a>
        <a class="twitter-follow-button" href="https://twitter.com/cognitice" target="_blank">@cognitice</a>
      </p>
      <p>
        <span>Contact : </span>
        <a href="mailto:cognitice@gmail.com" >cognitice@gmail.com</a></p>
    </div>

    <div class="presentation">
      <h1>Une ressource gratuite et inclusive</h1>
      <h2>Comment enseigner les mathématiques à des enfants dyscalculiques qui, en CP ou CE1, ne savent toujours pas résoudre une simple opération du type  1 + 1 ou 2 - 1 ?</h2>
      <p>C'est en tentant de répondre à cette question qu’est né l'objet de ce projet. Parmi les 3 élèves qui nous ont aidé à imaginer cet outil, Eve atteinte de dyspraxie développementale visuo-spatiale. Au jour, où nous l'avons rencontrée, en 2013, elle était scolarisée en classe de CE1 après deux années de CP qui n'avaient pas permis de combler ses lacunes.</p>
      <p>Le projet, porté par l’association <a href="http://www.cognitice.education/" target="_blank">CogniTICE</a>, bâti avec l'accord de son enseignante et de l'orthophoniste logico-mathématique qui la suivait, consista à utiliser exclusivement un support informatique, à renoncer à toute manipulation d'objets ou de collections d'objets concrets, ne proposant que <strong>la représentation à l'écran de quantités, sous forme de constellation de points (dé)</strong>. L’enfant était guidée par son avs et le nombre était indiqué sous forme audio, sans possibilité d’erreur.</p>
      <p>Tous les exercices (petites additions en ligne, manipulation de dizaines, multiplications, problèmes) présentant des nombres sous forme symbolique, chiffres en lettres ou chiffres arabes, se voyaient systématiquement doublés de leur représentation sous forme de quantités (dés).</p>
      <p>L'hypothèse était la suivante : grâce à ces supports visuels de quantités (dé), Eve pourrait accéder à des opérations plus complexes comme par exemple trouver le multiplicateur d'une opération à trou, décomposer un nombre élevé en centaine, dizaine et unité ou, plus complexe encore, trouver la somme exacte de grands nombres (alors qu'elle continuait à échouer à résoudre 2 + 1 sans compter sur ses doigts, comptage toujours susceptible d'erreurs).</p>
      <p>Et en effet, la réussite dans ces différents exercices lui a permis de progresser dans presque tous les domaines des mathématiques.</p>
      <p>Dédys a pour vocation d’être un outil d’inclusion, adapté <strong>aux enfants dyscalculiques et à tous les jeunes enfants</strong>, pour qui le renforcement par l’image constitue une aide à l’apprentissage.</p>
      <p>Cet outil vise des difficultés de type dyscalculie importante pour lesquelles les enseignants sont très démunis :</p>
      <ul>
        <li>Automatisation impossible des tables (l’outil dédys permet de visualiser le résultat d’une multiplication)</li>
        <li>Difficulté à réaliser une opération même très simple mentalement sans soutien visuel</li>
        <li>Difficulté à manipuler, compter et déplacer en même temps sans erreur (dyspraxie)</li>
      </ul>
      <p>Les enfants atteints de ce type de troubles ont beaucoup de mal à être inclus en classe ordinaire pour les activités mathématiques et ce dès le plus jeune âge. <strong>Leur handicap les empêche de développer une bonne intuition du nombre, alors qu’ils sont, comme Eve, en capacité de progresser, pour peu qu’on les aide à contourner leur handicap.</strong></p>
      <p>Dédys se veut <strong>un outil d’inclusion et de renforcement visuel</strong> pour les élèves en grande difficulté avec les mathématiques mais aussi pour l’ensemble de la classe. <strong>Cette ressource, entièrement gratuite, a été développée grâce au soutien de la <a href="https://eduscol.education.fr/cid56176/aide-et-commission-dispositif-edu-up.html" target="_blank">commission EduUp</a> et du <a href="https://www.education.gouv.fr/" target="_blank">ministère de l’éducation nationale</a>, grâce également à la <a href="http://rozan.fondationjudaisme.org" target="_blank">fondation Julien et Stella Rozan</a>, qui décerne chaque année le prix de la coopération féminine et a récompensé Dédys en 2018.</strong></p>
      <p>De nouvelles activités (couvrant l’ensemble du cycle 2 à terme et une partie du cycle 3) sont en cours de développement. Pour l’heure ce premier « pilote » est encore en phase de développement et d’expérimentation auprès de jeunes enfants en difficulté avec les mathématiques ou non, en lien avec des enseignants de classes ordinaires et spécialisées, ainsi que des neuropsychologues spécialisés dans les troubles des apprentissages.</p>
      <p>N’hésitez pas à nous faire part de vos remarques ou souhaits de développements futurs.</p>
      cognitice@gmail.com
    </div>

  </div>
  <Footer />
</template>

<script>
  import DedysLogo from "../../components/v1/Logos/DedysLogo";
  import Footer from "../../components/v1/FooterActivite";

  export default {
    name: 'QuiSommesNous',
    components: {
      DedysLogo,
      Footer
    },

  }
</script>

<style lang="scss">

  .qui-sommes-nous, .liens {
    margin-bottom: 20px;
  }

  .qui-sommes-nous p {
    margin: 5px 0;
  }

  .qui-sommes-nous h2 {
    margin: 5px 0;
  }

  .qui-sommes-nous h3 {
  }

  .presentation {
    margin: 40px 0;
  }

  .presentation h1 {
    font-size:30px;
  }

  .presentation p {
    margin: 20px 0;
  }

  .qui-sommes-nous a,
  .liens a,
  .presentation a {
    text-decoration: none;
    color:#3B8FF2;
  }

  .liens a {
    color:#3B8FF2;
  }

  .qui-sommes-nous a:hover,
  .liens a:hover,
  .presentation a:hover {
    text-decoration: underline;
  }

</style>
