<template>
  <div class="help-panel large-help-panel help-panel-milliers" :class="isOpenedCssClass">
    <div class="help-panel-button">
      <a href="#" @click="togglePanel($event)"></a>
    </div>
    <div class="table-parent">
      <table class="help-panel-contents">
        <thead>
          <tr>
            <th class="help-panel-close-parent-button">
              <a class="help-panel-close-button" href="#" @click="closePanel($event)"></a>
            </th>
            <th>
              <div>
                <span>Centaines de milliers</span>
              </div>
            </th>
            <th>
              <div>
                <span>Dizaines de milliers</span>
              </div>
            </th>
            <th>
              <div>
                <span>Milliers</span>
              </div>
            </th>
            <th>
              <div>
                <span>Centaines</span>
              </div>
            </th>
            <th>
              <div>
                <span>Dizaines</span>
              </div>
            </th>
            <th>
              <div>
                <span>Unités</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="thin-scroll">
          <tr v-for="item in items" :key="item">
            <td>
              {{ item }}
            </td>
            <td :class="centainesMilliersCssClass(item)">
              <DeCentaineMillierComplex :chiffre="item" />
            </td>
            <td :class="dizainesMilliersCssClass(item)">
              <DeDizaineMillierComplex :chiffre="item" />
            </td>
            <td :class="milliersCssClass(item)">
              <DeMillierComplex :chiffre="item" />
            </td>
            <td :class="centainesCssClass(item)">
              <DeCentaineComplex :chiffre="item" />
            </td>
            <td :class="dizainesCssClass(item)">
              <DeDizaineComplex :chiffre="item" />
            </td>
            <td :class="unitesCssClass(item)">
              <DeUniteComplex :chiffre="item" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>

  import { ref, computed, onMounted, watch } from "vue";
  import {getDizainesUnites} from "../../../js/utils";

  import DeUniteComplex from "../Des/DesUnitesComplex/DeUniteComplex";
  import DeDizaineComplex from "../Des/DesDizainesComplex/DeDizaineComplex";
  import DeCentaineComplex from "../Des/DesCentainesComplex/DeCentaineComplex";
  import DeMillierComplex from "../Des/DesMilliersComplex/DeMillierComplex";
  import DeDizaineMillierComplex from "../Des/DesDizainesMilliersComplex/DeDizaineMillierComplex";
  import DeCentaineMillierComplex from "../Des/DesCentainesMilliersComplex/DeCentaineMillierComplex";



  //
  // Propriétés du composant
  //

  const props = defineProps({
    minimum: {
      type: Number,
      required: false,
      default: 0
    },
    maximum: {
      type: Number,
      required: false,
      default: 10
    },
    chiffre: {
      type: Number,
      required: false,
      default: -1
    },
    chiffreMisEnValeur: {
      type: Boolean,
      required: false,
      default: true
    },
    soundMode: {
      type: String,
      required: false,
      default: "nombre"
    },
  });


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    updatePanel();
  });

  onMounted(() => {
    initPanel();
    updatePanel();
  });


  //
  // Données et fonctions du composant
  //

  const items = ref(null);

  let chiffreCourant = ref(-1);
  let centainesMilliers = ref(-1);
  let dizainesMilliers = ref(-1);
  let milliers = ref(-1);
  let centaines = ref(-1);
  let dizaines = ref(-1);
  let unites = ref(-1);

  const isOpened = ref(false);
  const isOpenedCssClass = computed(() => isOpened.value ? 'opened' : '');

  //
  // Fonctions
  //

  const initPanel = function() {
    let i;
    items.value = [];
    for(i=props.minimum; i<= props.maximum; i++) {
      items.value.push(i);
    }
  };

  const updatePanel = function() {
    // Chiffre
    chiffreCourant.value = props.chiffre;

    // Décomposition du chiffre
    const dizainesUnites = getDizainesUnites(chiffreCourant.value);
    centainesMilliers.value = dizainesUnites.centaines_milliers;
    dizainesMilliers.value = dizainesUnites.dizaines_milliers;
    milliers.value = dizainesUnites.milliers;
    centaines.value = dizainesUnites.centaines;
    dizaines.value = dizainesUnites.dizaines;
    unites.value = dizainesUnites.unites;
  }

  const unitesCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return item !== unites.value ? 'off' : '';
  };

  const dizainesCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return (item !== dizaines.value) || (chiffreCourant.value < 10)  ? 'off'  : '';
  };

  const centainesCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return (item !== centaines.value) || (chiffreCourant.value < 100) ? 'off' : '';
  };

  const milliersCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return (item !== milliers.value) || (chiffreCourant.value < 1000) ? 'off' : '';
  };

  const dizainesMilliersCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return (item !== dizainesMilliers.value) || (chiffreCourant.value < 10000) ? 'off' : '';
  };

  const centainesMilliersCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return (item !== centainesMilliers.value) || (chiffreCourant.value < 100000) ? 'off' : '';
  };


  // Fermer / Ouvrir (transition CSS)

  function togglePanel($event) {
    $event.preventDefault();
    isOpened.value = ! isOpened.value;
  }

  function closePanel($event) {
    $event.preventDefault();
    isOpened.value = false;
  }

</script>

<style scoped lang="scss">

.help-panel-milliers {

  transform: translateX(690px);
  transition: transform ease-in-out 0.75s;

  &.opened {
    transform: translateX(0);
  }

  table {

    width: 670px;

    tbody {
      display: block;
      height: calc( 100vh - 110px );
      overflow: auto;
      pointer-events: auto;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }

    td.off > div {
      opacity: 0.8;
      filter: grayscale(1);
    }

  }

}

</style>
