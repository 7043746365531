<template>
  <div class="content">
    <HeaderActivite @toggle-settings="toggleSettings" />
    <main
        class="activite appariement-nombre-quantite"
        :class="desSettingsCssClass"
    >
      <div class="activite-header">
        <!-- Recommencer -->
        <ReplayBtn />
        <!-- Démo -->
        <DemoBtn />
        <!-- Saisie manuelle -->
        <ManualInputsBtn />
      </div>

      <h1>Appariement nombre et quantité</h1>

      <div class="interaction">

        <ul class="niveaux half-size">
          <li><a class="niveau courant" data-level="1" href="#">Niveau 1 : de 0 à 10</a></li>
          <li><a class="niveau" data-level="2" href="#">Niveau 2 : de 10 à 50</a></li>
          <li><a class="niveau" data-level="3" href="#">Niveau 3 : de 50 à 70</a></li>
          <li><a class="niveau" data-level="4" href="#">Niveau 4 : de 70 à 99</a></li>
        </ul>

        <!-- Dés draggables -->
        <div class="blocs quantites">
          <div class="quantite">
            <div class="draggable" id="drag1">
            </div>
          </div>
          <div class="quantite">
            <div class="draggable">
            </div>
          </div>
          <div class="quantite">
            <div class="draggable">
            </div>
          </div>
          <div class="quantite">
            <div class="draggable">
            </div>
          </div>
          <div class="quantite">
            <div class="draggable">
            </div>
          </div>
        </div>

        <!-- Quantités et zones de drop -->
        <div class="nombres">
          <div class="nombre-parent">
            <div class="nombre">1</div>
            <div class="zone-parent">
              <div class="zone-border"></div>
              <div class="zone"></div>
            </div>
          </div>
          <div class="nombre-parent">
            <div class="nombre">2</div>
            <div class="zone-parent">
              <div class="zone-border"></div>
              <div class="zone"></div>
            </div>
          </div>
          <div class="nombre-parent">
            <div class="nombre">3</div>
            <div class="zone-parent">
              <div class="zone-border"></div>
              <div class="zone"></div>
            </div>
          </div>
          <div class="nombre-parent">
            <div class="nombre">4</div>
            <div class="zone-parent">
              <div class="zone-border"></div>
              <div class="zone"></div>
            </div>
          </div>
          <div class="nombre-parent">
            <div class="nombre">5</div>
            <div class="zone-parent">
              <div class="zone-border"></div>
              <div class="zone"></div>
            </div>
          </div>
        </div>

        <!-- pour démo -->
        <div class="souris"></div>

      </div>

    </main>
    <Footer />
    <ManualInputsPopUp
        :slots=5
        :maxlength=3
        @change="validationSaisieManuelle"
        @close="fermerSaisieManuelle"
    />
    <SettingsPanel />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v1/HeaderActiviteV1";
  import Footer from "../../../components/v1/FooterActivite";
  import ManualInputsBtn from "../../../components/v1/Boutons/ManualInputsBtn"
  import ManualInputsPopUp from "../../../components/v1/ManualInputsPopUp"
  import SettingsPanel from "../../../components/v1/SettingsPanel"
  import ReplayBtn from "../../../components/v1/Boutons/ReplayBtn";
  import DemoBtn from "../../../components/v1/Boutons/DemoBtn";

  import { ref, onMounted } from "vue";
  import { useStore } from 'vuex';

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    getHtmlUnDe,
    getHTMLNombre,
    playSound,
    randomIntFromInterval,
    playSequence,
    stopSequence,
    stopAllAnimations,
    stopAllSounds,
    getPDF,
    addSVGToPDF,
    getURLFromBackgroundImageStyle,
    shuffleArray
  } from "../../../js/utils";

  const store = useStore();

  // Selon niveau :
  let currentLevel = 1;
  let currentMinimumNumber = 1;
  let currentMaximumNumber = 10;

  let activite;
  // let outils;
  let interaction;
  let souris;
  let settingsPanel;

  const overlapThreshold = "50%";
  let isDragging = false;
  let selectedDraggable;

  // Style des dés (forme, couleur)
  let desSettingsCssClass = store.getters.desSettingsCssClass;

  onMounted(() => {

    activite = $(".activite");
    // outils = $(".outils");
    interaction = $(".interaction");
    souris = $(".souris");
    settingsPanel = $(".settings-panel");


    //
    // Initializations
    //

    // Event delegation pattern :
    activite.on("click", function(e)
    {
      e.preventDefault();

      var target = $(e.target);

      if (target.is(".demo"))
      {
        demo( target );
      }
      else if (target.is(".replay"))
      {
        replay( target );
      }
      else if (target.is(".saisie-manuelle"))
      {
        saisieManuelle( target );
      }

    });

    activite.on("mousedown", function(e)
    {
      e.preventDefault();
    });

    activite.on("replay", function(e)
    {
      e.preventDefault();
      replay();
    });

    activite.on("stopDemo", function(e)
    {
      e.preventDefault();
      stopDemo();
    });

    $('.print-link').on("click", function(e)
    {
      e.preventDefault();
      printAsPDF();
    });



    /* Niveaux */

    $('.niveau').on("click", function(e)
    {
      e.preventDefault();

      var niveau = $(e.target);

      // Activation du lien
      var niveaux = niveau.closest(".niveaux");
      var tousLesniveaux = niveaux.find(".niveau");
      tousLesniveaux.removeClass("courant");

      niveau.addClass("courant");

      // Définition du nombre maximum du tirage au sort
      var niveauNo = niveau.data("level");

      switch(niveauNo) {

        case 1:
          currentLevel = 1;
          currentMinimumNumber = 0;
          currentMaximumNumber = 10;
          break;

        case 2:
          currentLevel = 2;
          currentMinimumNumber = 10;
          currentMaximumNumber = 50;
          break;

        case 3:
          currentLevel = 3;
          currentMinimumNumber = 50;
          currentMaximumNumber = 70;
          break;

        case 4:
          currentLevel = 4;
          currentMinimumNumber = 70;
          currentMaximumNumber = 99;
          break;
      }

      $('.activite').attr('data-niveau', currentLevel);

      resetActivite();
    });


    /* Drag and Drop */

    var dropZones = $(".zone");

    Draggable.create( $(".draggable"), {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        $('.draggable').removeClass("dragged");
        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.addClass("dragged");

        var nombre = draggableElement.find('.de').data('value');
        var soundPath = getSoundPath_Chiffre(nombre);
        playSound(soundPath);

        selectedDraggable = draggableElement;

        var draggableElementOffset = draggableElement.offset();
        if ((draggableElement.data('x') === undefined) || (draggableElement.data('x') === -1)) {
          draggableElement.data('x', draggableElementOffset.left);
          draggableElement.data('y', draggableElementOffset.top);
        }
      },
      onDragStart: function(pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre = draggableElement.find('.de').data('value');

        isDragging = true;
        selectedDraggable = null;

        dropZones.css("pointer-events", "none");
        dropZones.each(function(no, item)
        {
          var dropZone = $(item);

          if ( dropZone.data('value') == nombre )
          {
            dropZone.data('value', NaN);
            dropZone.removeClass("hilite").removeClass("filled");
          }
        });
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        stopAllAnimations();

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        $('.draggable').removeClass("dragged");

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.removeClass("dragged");

        var offsetX = draggableElement.data('x');
        var offsetY = draggableElement.data('y');

        var i = dropZones.length;
        var hiliteFound = false;
        var dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if ( isNaN( $(dropZone).data('value')))
          {
            if (this.hitTest(dropZone, overlapThreshold))
            {
              var dropNombreElement = dropZone.closest('.zone-parent').siblings('.nombre');
              var dropNombreParent = dropNombreElement.parent();

              var bloc = draggableElement.find('.bloc');
              if (! bloc.length) {
                bloc = draggableElement.find('.de')
              }

              var nombre_drag = parseInt( bloc.data('value'));
              var nombre_drop = parseInt( dropNombreElement.attr('data-nombre'));

              // console.log(nombre_drag, nombre_drop);

              var soundPath = getSoundPath_Chiffre(nombre_drop);
              playSound(soundPath);

              var targetOffset = dropZone.offset();
              var marginLeft =  parseInt(draggableElement.find('.de-parent').css('margin-left'));

              gsap.to(this.target, 0.1,{
                x:targetOffset.left - offsetX - marginLeft,
                y:targetOffset.top - offsetY,
                onComplete: function() {
                }
              });

              if (nombre_drag === nombre_drop)
              {
                // Bonne répone
                hiliteFound = true;

                dropNombreParent.removeClass("correct").addClass("correct");
                dropZone.removeClass("hilite").addClass("filled");
              }
              else
              {
                dropNombreParent.addClass("incorrect");
                dropZone.removeClass("hilite").removeClass("filled");
              }
            }
          }
        }

        if (! hiliteFound) {

          // Mauvaise réponse : fond rouge et retour à la position initiale
          if (dropNombreParent) dropNombreParent.addClass('incorrect');

          gsap.set(this.target, { x:0, y:0, delay:1, onComplete: function() {
              $('.nombre', '.nombres').parent().removeClass('incorrect');
            }
          });

          isDragging = false;
        }
      },
      onDrag: function() {

        var i = dropZones.length, dropZone;
        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (isNaN( dropZone.data('value')))
          {
            if (this.hitTest(dropZone, overlapThreshold)) {
              dropZone.addClass("hilite");
            } else {
              dropZone.removeClass("hilite");
            }
          }
        }
      }
    });

    dropZones.on('click', function() {
      if (! isDragging && selectedDraggable )
      {
        $('.draggable').removeClass("dragged");

        var draggableElement = selectedDraggable;
        var offsetX = draggableElement.data('x');
        var offsetY = draggableElement.data('y');
        var nombre = draggableElement.find('.de').data('value');

        var dropZone = $(this);
        var targetOffset = dropZone.offset();

        gsap.to(draggableElement, 0.1,{

          x:targetOffset.left - offsetX,
          y:targetOffset.top - offsetY,
          onComplete: function() {

            dropZone.removeClass("hilite").addClass("filled");
            dropZone.data('value', nombre);

            var dropNombreElement = dropZone.closest('.zone-parent').siblings('.nombre');
            var dropNombreParent = dropNombreElement.parent();

            var nombre_drag = draggableElement.find('.de').data('value');
            var nombre_drop = dropNombreElement.data('nombre');

            console.log(nombre_drag, nombre_drop, "ZONE", dropZone.closest('.zone-parent').siblings('.nombre'));

            var soundPath = getSoundPath_Chiffre(nombre_drop);
            playSound(soundPath);


            if (nombre_drag !== nombre_drop)
            {
              // Mauvaise réponse : fond rouge et retour à la position initiale
              dropNombreParent.addClass('incorrect');
              draggableElement.addClass('incorrect');

              gsap.to(this.target, 0.1,{
                delay:1,
                x:0,
                y:0,
                onStart: function() {
                  dropZone.data('value', NaN);
                  dropZone.removeClass("hilite").removeClass("filled");
                }
              });
            }
            else
            {
              // Bonne réponse : fond vert
              dropNombreParent.addClass('correct');
              draggableElement.addClass('correct');
            }
          }
        });

      }
    });


    /* Première initialisation au chargement de la page */

    // initSettingsEvents();
    // restoreSettings();

    initActivite();

  });


  //
  // 0. Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres ) {

    console.log('initActivite');

    // Suppression d'une précédente initialisation
    gsap.set($('.draggable'), { x:0, y:0 } );
    $('.draggable').removeClass(".dragged");
    $('.zone').removeClass("hilite").removeClass("filled");
    isDragging = false;
    selectedDraggable = null;


    // Initialisation de l'activité :

    if ((chiffres === undefined) || (chiffres.length < 5))
    {
      chiffres = [];

      // Tirage au sort des chiffres : le premier chiffre peut être entre 0 et 5 ( le dernier ira donc de 4 à 9)
      var firstNumber = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber - 5);

      // Affichage des nombres (sous les dés)
      $('.nombre', '.nombres').each(function(no, item) {

        var nombre = firstNumber + no;

        chiffres.push(nombre);

        $(item).attr('data-nombre', nombre);
        $(item).text(nombre);
      });
    }
    else
    {
      // Saisie manuelle

      // Affichage des nombres
      $('.nombre', '.nombres').each(function(no, item) {

        var nombre = chiffres[no];

        $(item).attr('data-nombre', nombre);
        $(item).text(nombre);
      });
    }

    console.log(chiffres);

    // Affichage des dés, dans le désordre
    shuffleArray(chiffres);

    var html;

    $('.draggable', '.quantites').each(function() {

      let nombre = chiffres.shift();

      if ( currentLevel === 1 )
      {
        html = getHtmlUnDe(nombre, '');
      }
      else
      {
        html = getHTMLNombre(nombre, '');
      }

      $(this).html(html);

    });

    $('.zone-parent').height ( $('.blocs.quantites').find('.draggable > div').height() );
  }


  //
  // 3. Démo Activité
  //

  var sequenceActions;

  function demo() {

    stopDemo();
    resetActivite();

    souris.css("display", "block");
    souris.css("opacity", 1);

    var positionInteraction = interaction.offset();
    var zindex = 10;

    sequenceActions = [];

    console.log('------- demo --------');

    $('.de', '.quantites').each(function(no, item) {

      var deElement = $(item);
      var nombre = deElement.data('value');

      // Parent draggable du dé à déplacer
      var draggableElement = deElement.closest('.draggable');

      // Position de départ du dé
      var draggablePosition = draggableElement.offset();

      // Nom près de la zone de dépôt
      var nombreElement = $('.nombre[data-nombre=' + nombre + ']');

      // Zone de dépôt du dé
      var zoneNombreElement = nombreElement.closest('.nombre-parent').find('.zone');

      // Position d'arrivée du dé
      var zonePosition = zoneNombreElement.offset();

      console.log(nombre, draggablePosition, zonePosition);

      var dx = zonePosition.left - draggablePosition.left;
      var dy = zonePosition.top  - draggablePosition.top;

      var sourisLeft = draggablePosition.left - positionInteraction.left + 0.5 * draggableElement.width();
      var sourisTop = draggablePosition.top - positionInteraction.top + 0.5 * draggableElement.height();
      var parentDraggableOffset = draggableElement.parent().offset();
      var reparentedSourisLeft = sourisLeft - parentDraggableOffset.left + interaction.offset().left;

      sequenceActions.push( { action : "moveElement", param : { element:souris , left: sourisLeft, top: sourisTop, duration: 0 } } );
      sequenceActions.push( { action : "fadeInElement", param : { element:souris } } );
      sequenceActions.push( { action : "addClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:draggableElement } } );
      sequenceActions.push( { action : "moveElement", param : { element:souris , left: reparentedSourisLeft, top: sourisTop, duration: 0 } } );
      sequenceActions.push( { action : "playSound", param : getSoundPath_Chiffre(nombre) } );
      sequenceActions.push( { action : "transformElement", param : { element:draggableElement , x: dx, y: dy, zindex:zindex ++ } } );
      sequenceActions.push( { action : "playSound", param : getSoundPath_Chiffre(nombre) } );
      sequenceActions.push( { action : "removeClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "fadeOutElement", param : { element:souris } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:interaction } } );
      sequenceActions.push( { action : "wait", param : 2 } );
    });

    sequenceActions.push( { action : "callback", param : resetActivite } );

    playSequence( sequenceActions );
  }

  function stopDemo() {
    stopSequence(sequenceActions);
    stopAllAnimations();
    stopAllSounds();
  }


  //
  // 4. Rejouer Activité
  //

  function replay() {

    stopDemo();
    resetActivite();

    activite.css("display", "block");
    interaction.css("display", "block");
    settingsPanel.css("display", "none");

  }

  function resetActivite( chiffres ) {

    souris.css("display", "none");

    $('.nombre-parent').removeClass('correct').removeClass('incorrect');

    var draggableElement = $('.draggable');
    draggableElement.data('x', -1);
    draggableElement.data('y', -1);

    initActivite( chiffres );
  }


  //
  // 5. Saisie manuelle
  //

  function saisieManuelle() {
    $("#popup-saisie-manuelle input[type=text]").val('');
    $("#popup-saisie-manuelle").removeClass('open').addClass('open');
  }

  function validationSaisieManuelle(chiffres_saisis) {

    let allValid = true;
    let chiffresArray = [];
    let chiffre;

    for(let i=0; i<chiffres_saisis.length; i++) {
      chiffre = parseInt(chiffres_saisis[i]);
      if ( ! isNaN(chiffre) && (chiffre > 0))
      {
        chiffresArray.push(chiffre);
      }
      else
      {
        allValid = false;
      }
    }

    if (allValid)
    {
      resetActivite( chiffresArray );
      fermerSaisieManuelle();
    }
  }

  function fermerSaisieManuelle() {
    $("#popup-saisie-manuelle").removeClass('open');
  }


  //
  // 6. PDF
  //

  function printAsPDF() {

    var doc = getPDF('landscape');
    doc.text(5, 20, 'Appariement nombre et quantité');

    var urls = [];
    var nombres = [];
    var positions_x = [];
    var position_y = 50;
    var scale;

    if ($('.activite').hasClass('de-bille')) {
      scale = 0.56;
    } else {
      scale = 1.0;
    }

    $(".nombre", ".nombres").each(function(no, item){

      let itemObj = $(item);
      let nombre = itemObj.attr('data-nombre');

      nombres.push( nombre );
    });

    $(".de", ".quantite").each(function(no, item){

      let itemObj = $(item);
      // let value = itemObj.data('value');

      urls.push( getURLFromBackgroundImageStyle(itemObj) );

      positions_x.push( 25 + no * 50 );
    });

    $.when(
        $.ajax({ url: "images/svg/cadre_vide_de.svg" }),
        $.ajax({ url: urls[0] }),
        $.ajax({ url: urls[1] }),
        $.ajax({ url: urls[2] }),
        $.ajax({ url: urls[3] }),
        $.ajax({ url: urls[4] })
    )
        .done(function( jqXHR0, jqXHR1, jqXHR2, jqXHR3, jqXHR4, jqXHR5){

          addSVGToPDF( doc, jqXHR1[0], positions_x[0], position_y, "de1", true, scale);
          addSVGToPDF( doc, jqXHR2[0], positions_x[1], position_y, "de2", true, scale);
          addSVGToPDF( doc, jqXHR3[0], positions_x[2], position_y, "de3", true, scale);
          addSVGToPDF( doc, jqXHR4[0], positions_x[3], position_y, "de4", true, scale);
          addSVGToPDF( doc, jqXHR5[0], positions_x[4], position_y, "de5", true, scale);

          var cadreSVG = jqXHR0[0];
          var positionCadre_y = position_y + 50;
          var cadreAlias = "cadre";

          addSVGToPDF( doc, cadreSVG, positions_x[0], positionCadre_y, cadreAlias);
          addSVGToPDF( doc, cadreSVG, positions_x[1], positionCadre_y, cadreAlias);
          addSVGToPDF( doc, cadreSVG, positions_x[2], positionCadre_y, cadreAlias);
          addSVGToPDF( doc, cadreSVG, positions_x[3], positionCadre_y, cadreAlias);
          addSVGToPDF( doc, cadreSVG, positions_x[4], positionCadre_y, cadreAlias);

          var positionTypo_y = positionCadre_y + 40;
          var offsetPositionTypo_x = 19;

          doc.setFontSize(20);

          doc.text(positions_x[0] + offsetPositionTypo_x, positionTypo_y, nombres[0]);
          doc.text(positions_x[1] + offsetPositionTypo_x, positionTypo_y, nombres[1]);
          doc.text(positions_x[2] + offsetPositionTypo_x, positionTypo_y, nombres[2]);
          doc.text(positions_x[3] + offsetPositionTypo_x, positionTypo_y, nombres[3]);
          doc.text(positions_x[4] + offsetPositionTypo_x, positionTypo_y, nombres[4]);

          doc.setFontSize(14);
          doc.setTextColor('#CCCCCC');
          doc.text(5, 200, "2019 © Cognitice");

          doc.save('appariement_nombre_quantité.pdf');

        })
        .fail(function(){
        });

  }

  //
  // 7. Settings
  //

  let settingsCssClass = ref('');

  const toggleSettings = function(bool) {
    if (bool) {
      $('.activite').trigger("stopDemo");
      settingsCssClass.value = "settings-opened";
    } else {
      $('.activite').trigger("replay");
      settingsCssClass.value = "";
    }

  };

</script>

<style lang="scss">

  .appariement-nombre-quantite {

    &.de-bille .nombres .nombre-parent .zone,
    &.de-bille .de-parent {
      height:90px;
    }

    &.de-bille .nombres .nombre-parent .zone-border,
    &.de-bille .nombres .nombre-parent {
      height:98px;
    }

    &.de-bille.de-fond-bleu .quantite .de-parent {
      background-color: #142896;
    }

    &.de-classique.de-fond-bleu .quantite .de-parent {
      background-color: #0f1c68;
    }

    &.de-bille .quantite .de-parent .de {
      /* border-radius: 12px; */
      background-size: 100%;
    }

    //

    .quantites {
      z-index:2;
      position: relative;
    }

    &.activite[data-niveau='2'] .blocs.quantites .quantite > .draggable,
    &.activite[data-niveau='3'] .blocs.quantites .quantite > .draggable,
    &.activite[data-niveau='4'] .blocs.quantites .quantite > .draggable {
      margin: 16px 4px;
    }

    .bloc {
      padding-bottom: 0;
    }

    .bloc .nombre {
      display: none;
    }

    .nombres {
      z-index:1;
      display: flex;
      position: relative;
      margin-top: 16px;
    }

    .nombres .nombre-parent {
      width: 168px;
      height: 128px;
      margin: 16px;
    }

    &.activite[data-niveau='2'] .nombres .nombre-parent,
    &.activite[data-niveau='3'] .nombres .nombre-parent,
    &.activite[data-niveau='4'] .nombres .nombre-parent {
      width: 223px;
      margin: 16px 4px;
    }

    .nombres .nombre-parent .zone-parent {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .nombres .nombre-parent .zone-border {
      border: 4px solid rgba(0,0,0,0);
      border-radius: 16px;
      position: absolute;
      left:-4px;
      top:-4px;
      width: calc( 100% + 8px );
      height: calc( 100% + 8px );
    }

    .nombres .nombre-parent .zone {
      display: block;
      width: 100%;
      height: 100%;

      position: absolute;

      background: #FFFFFF;
      border: 1px solid #0B154B;
      box-sizing: border-box;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }

    .nombres .nombre-parent .zone.hilite {
      background-color: white;
    }

    .nombres .nombre-parent .zone.filled {
      background-color: #EEEEEE;
    }

    .nombres .nombre-parent .nombre {
      display: block;
      height: 40px;
      color:#000000;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
    }

    &.activite[data-niveau='2'] .nombres,
    &.activite[data-niveau='3'] .nombres,
    &.activite[data-niveau='4'] .nombres {
      margin-top: 50px;
      width: 1160px;
    }

    .nombres .nombre-parent.correct .nombre {
      color: rgba(0, 255, 0, 0.5);
      transition-duration: 2s;
    }

    .nombres .nombre-parent.incorrect .nombre {
      color: rgba(255, 0, 0, 0.5);
      transition-duration: 0.5s;
    }

    .nombres .nombre-parent.correct .zone-border {
      border: 5px solid rgba(0, 255, 0, 0.5);
    }

    .nombres .nombre-parent.incorrect .zone-border {
      border: 5px solid rgba(255, 0, 0, 0.5);
    }



    /* iPad */

    @media screen and (max-width: 1080px ) {

      .nombres .nombre-parent {
        width: 134px;
        height: 104px;
      }

      .nombres .nombre-parent .zone-border {
        width: 134px;
        height: 104px;
      }

      .nombres .nombre-parent .zone {
        width: 126px;
        height: 96px;
      }

    }


  }

</style>
