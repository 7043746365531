<template>
  <div class="tab-wrapper" :class="tabCssClass">
    <a
        class="tab"
        href="#"
        @click="selectTab"
    >
      <span>
      {{ tabLabel }}
      </span>
    </a>
    <CloseTabBtn
        class="close-btn"
        @click="removeTab"
    />
  </div>
</template>

<script setup>
  import {computed} from "vue";
  import CloseTabBtn from "./CloseTabBtn";

  const emit = defineEmits(['select', 'remove']);

  //
  // Propriétés du composant
  //

  const props = defineProps({
    tabId: {
      type: Number,
      required: true,
      default: 1
    },
    tabIndex: {
      type: Number,
      required: true,
      default: 1
    },
    selected: {
      type: Boolean,
      required: true,
      default: false
    },
  });


  //
  // Reactive values
  //

  const tabLabel = computed(() => {
    return "Ex. " + String(props.tabIndex);
  })

  const tabCssClass = computed(() => {
    return props.selected ? "selected" : "";
  })


  //
  // Foncitons spécifiques
  //

  const selectTab = function() {
    emit( 'select', props.tabId);
  };

  const removeTab = function() {
    emit( 'remove', props.tabId);
  };

</script>


<style scoped lang="scss">

div.tab-wrapper {
  position: relative;

  &.selected {
    a.tab {
      background-image: url(../../../assets/images/svg/tab_bg_on.svg);
    }
  }

  a.tab {
    display: block;
    width: 70px;
    height: 32px;
    background: url(../../../assets/images/svg/tab_bg_off.svg) center / 70px auto no-repeat;
    transform: translateY(3px);
    text-decoration: none;

    & > span {
      padding: 0 10px;
      color: #666666;
    }

  }

  .close-btn {
    position: absolute;
    right: 10px;
    top: 8px;
    opacity: 0;
  }

  &:hover {
    .close-btn {
      opacity: 1;
    }
  }

}

</style>
