
// TODO : Sera remplacé par un appel à la base de données

function getActivites() {
    return [
        {
            titre: "Jeu des couleurs",
            name: 'Jeu des couleurs',
            path: 'jeu-des-couleurs',
            niveaux: 3,
            niveaux_tablet: 3,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex2-1'
        },
        {
            titre: "Jeu des quantités",
            name: 'Jeu des quantites',
            path: 'jeu-des-quantites',
            niveaux: 7,
            niveaux_tablet: 5,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex4-3'
        },
        {
            titre: "Jeu du tableau",
            name: 'Jeu du tableau',
            path: 'jeu-du-tableau',
            niveaux: 4,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex2-2'
        },
        {
            titre: "Taguer",
            name:"Taguer",
            path:"taguer",
            niveaux: 6,
            niveaux_tablet: 4,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Suite",
            name: 'Suite',
            path: 'suite',
            niveaux: 6,
            niveaux_tablet: 3,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Dictée vocale",
            name: 'Dictée vocale', // cf on reprend la valeur name définie dans le router pour chaque activité
            path: 'dictee-vocale',
            niveaux: 6,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Ordonner",
            name: 'Ordonner',
            path: 'ordonner',
            niveaux: 6,
            niveaux_tablet: 4,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Jeu des étiquettes",
            name: 'JeuDesEtiquettes',
            path: 'jeu-des-etiquettes',
            niveaux: 6,
            niveaux_tablet: 2,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Décomposer",
            name: 'Décomposer',
            path: 'decomposer',
            niveaux: 6,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Complément",
            name: 'Complement',
            path: 'complement',
            niveaux: 4,
            niveaux_tablet: 2,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex2-2'
        },
        {
            titre: "Additionner",
            name: 'Additionner',
            path: 'additionner',
            niveaux: 6,
            niveaux_tablet: 3,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Soustraire",
            name: 'Soustraire',
            path: 'soustraire',
            niveaux: 6,
            niveaux_tablet: 3,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Double",
            name: 'Double',
            path: 'double',
            niveaux: 2,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex1-1-column'
        },
        {
            titre: "Moitié",
            name: 'Moitie',
            path: 'moitie',
            niveaux: 2,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex1-1'
        },
        {
            titre: "Operations en ligne",
            name: 'Operations en ligne',
            path: 'operations-en-ligne',
            niveaux: 6,
            niveaux_tablet: 3,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex3-3'
        },
        {
            titre: "Multiplier",
            name: 'Multiplier',
            path: 'multiplier',
            niveaux: 1,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex1'
        },
        {
            titre: "C'est pareil que",
            name: 'CestPareilQue',
            path: 'pareil-que',
            niveaux: 1,
            tools: true,
            sound_active: true,
            print_active: false,
            class: 'flex1'
        },

    ]
 }

function getJeux() {
    return [
        {
            titre: "Astéroïdes",
            name: 'Asteroids',
            path: 'asteroids',
            sound_active: true,
            active: true
        },
        {
            titre: "Le circuit d'Ada",
            name: 'Frogger',
            path: 'frogger',
            sound_active: true,
            active: false
        },
        {
            titre: "Mitraille",
            name: 'Breakout',
            path: 'mitraille',
            sound_active: true,
            active: true
        },

    ]
}

function getOutils() {
    return [
        {
            titre: "Tables de multiplication",
            name: 'Tables de multiplication',
            path: 'table-de-multiplication',
            niveaux: 10,
            sound_active: true,
            class: 'flex5-5'
        },

    ]
}

function getParcours() {

    const parcours1 = {
        id: "dictees-vocales",
        titre: "Parcours 1 : Dictée vocale",
        routes: [
            {
                path: '/activite/dictee-vocale/niveau/1',
            },
            {
                path: '/activite/dictee-vocale/niveau/2',
            },
            {
                path: '/activite/dictee-vocale/niveau/3',
            },
            {
                path: '/activite/dictee-vocale/niveau/4',
            },
            {
                path: '/activite/dictee-vocale/niveau/5',
            },
            {
                path: '/activite/dictee-vocale/niveau/6',
            }
        ]
    };

    const parcours2 = {
        id: "construction-du-nombre",
        titre: "Parcours 2 : Construction du nombre",
        routes: [
            {
                path: '/activite/dictee-vocale/niveau/2',
            },
            {
                path: '/activite/jeu-du-tableau/niveau/2',
            },
            {
                path: '/activite/decomposer/niveau/1',
            },
            {
                path: '/activite/additionner/niveau/1',
            }
        ]
    };

    const parcours3 = {
        id: "operations",
        titre: "Parcours 3 : Opérations",
        routes: [
            {
                path: '/activite/suite/niveau/2',
            },
            {
                path: '/activite/additionner/niveau/2',
            },
            {
                path: '/activite/complement/niveau/2',
            },
            {
                path: '/activite/soustraire/niveau/2',
            },
            {
                path: '/activite/double/niveau/1',
            },
        ]
    };

    /*
    Exemple avec une valeur fixe, non tirée au sort par le niveau :
    const parcours4 = {
        id: "test-bilan",
        titre: "Test : Dictée vocale avec 89",
        routes: [
            {
                path: '/activite/dictee-vocale?v=89',
            }
        ]
    };
    */

    return [
        parcours1,
        parcours2,
        parcours3
    ]
}


export {
    getActivites,
    getJeux,
    getOutils,
    getParcours
}
