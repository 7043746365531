import { createRouter, createWebHistory } from 'vue-router'


// V1
import Accueil_v1 from '../views/v2/Pages/AccueilSiteV1.vue'
import SommaireSite_v1 from '../views/v1/SommaireSiteV1.vue'
import QuiSommesNous from "../views/v1/QuiSommesNousV1";
import DicteeVocale_V1 from "../views/v1/Activites/DicteeVocale";
import AppariementNombreQuantite_V1 from "../views/v1/Activites/AppariementNombreQuantite";
import ComplementA10_V1 from "../views/v1/Activites/ComplementA10";
import IdentifierOrdonner_V1 from "../views/v1/Activites/IdentifierOrdonner";
import RepertoireAdditif_V1 from "../views/v1/Activites/RepertoireAdditif";
import RepertoireSoustractif_V1 from "../views/v1/Activites/RepertoireSoustractif";
import RepertoireMultiplicatif_V1 from "../views/v1/Activites/RepertoireMultiplicatif";
import TableMultiplication_V1 from "../views/v1/Activites/TableMultiplication";
import Encadrer_V1 from "../views/v1/Activites/Encadrer";
import Decomposer_V1 from "../views/v1/Activites/Decomposer";

// V2
import ListeActivites from '../views/v2/ListeActivites.vue'
import ListeNiveauxActivite from '../views/v2/ListeNiveauxActivite.vue'

// V2 : pages
import Accueil_v2 from '../views/v2/Pages/AccueilSiteV2.vue'
import GuideEnseignant from '../views/v2/Pages/GuideEnseignant.vue'
import Partenaires from '../views/v2/Pages/Partenaires.vue'
import Projet from '../views/v2/Pages/Projet.vue'
import GeneralSettings from '../views/v2/Pages/GeneralSettings.vue'

// V2 : activités
import DicteeVocale from "../views/v2/Activites/DicteeVocale";
import JeuDesQuantites from "../views/v2/Activites/JeuDesQuantites";
import Complement from "../views/v2/Activites/Complement";
import Ordonner from "../views/v2/Activites/Ordonner";
import Additionner from "../views/v2/Activites/Additionner";
import Soustraire from "../views/v2/Activites/Soustraire";
import Multiplier from "../views/v2/Activites/Multiplier";
import Suite from "../views/v2/Activites/Suite";
import Decomposer from "../views/v2/Activites/Decomposer";
import OperationsEnLigne from "../views/v2/Activites/OperationsEnLigne";
import JeuDesCouleurs from "../views/v2/Activites/JeuDesCouleurs";
import JeuDuTableau from "../views/v2/Activites/JeuDuTableau";
import Taguer from "../views/v2/Activites/Taguer";
import Double from "../views/v2/Activites/Double";
import Moitie from "../views/v2/Activites/Moitie";
import JeuDesEtiquettes from "../views/v2/Activites/JeuDesEtiquettes";
import CestPareilQue from "../views/v2/Activites/CestPareilQue";


// V2 : jeux
import Asteroids from "../views/v2/Jeux/Asteroids";
import Breakout from "../views/v2/Jeux/Breakout";
import Frogger from "../views/v2/Jeux/Frogger";

// V2 : outils
import ListeTables from '../views/v2/ListeTables.vue'
import TableMultiplication from "../views/v2/Outils/TableMultiplication";
import Reglette from "../views/v2/Outils/Reglette";
import N1 from "../views/v2/Outils/N1";


// Design : intégration des composants
import TestsTypos from "../views/v2/Design/Typos";
import TestNiveaux from "../views/v2/Design/Niveaux";
import TestDes from "../views/v2/Design/Des";
import TestHelpPanel from "../views/v2/Design/HelpPanel";
import TestsCards from "../views/v2/Design/Cards";
import TestsSlots from "../views/v2/Design/Slots";
import TestsComposantsV1 from "../views/v2/Design/ComposantsV1"; // v1
import TestsEtiquettes from "../views/v2/Design/Etiquettes";
import TestSettingsActivites from "../views/v2/Design/SettingsActivites";
import TestControls from "../views/v2/Design/Controls";
import TestsAda from "../views/v2/Design/Ada";
import SommaireParcours from "../views/v2/Parcours/SommaireParcours.vue";
import BilanParcours from "../views/v2/Parcours/BilanParcours.vue";

const routes = [

  // v1
  {
    path: '/v1/sommaire',
    name: 'Accueil v1',
    component: SommaireSite_v1
  },
  {
    path: '/v1/dictee-vocale',
    name: 'Dictée vocale v1',
    component: DicteeVocale_V1
  },
  {
    path: '/v1/appariement-nombre-quantite',
    name: 'Appariement Nombre Quantite v1',
    component: AppariementNombreQuantite_V1
  },
  {
    path: '/v1/complement-a-10',
    name: 'Complement à 10 v1',
    component: ComplementA10_V1
  },
  {
    path: '/v1/identifier-ordonner',
    name: 'Identifier ordonner v1',
    component: IdentifierOrdonner_V1
  },
  {
    path: '/v1/repertoire-multiplicatif',
    name: 'Répertoire multiplicatif v1',
    component: RepertoireMultiplicatif_V1
  },
  {
    path: '/v1/repertoire-additif',
    name: 'Répertoire additif v1',
    component: RepertoireAdditif_V1
  },
  {
    path: '/v1/repertoire-soustractif',
    name: 'Répertoire soustractif v1',
    component: RepertoireSoustractif_V1
  },
  {
    path: '/v1/table-de-multiplication',
    name: 'Table de multiplication v1',
    component: TableMultiplication_V1
  },
  {
    path: '/v1/encadrer',
    name: 'Encadrer v1',
    component: Encadrer_V1
  },
  {
    path: '/v1/decomposer',
    name: 'Decomposer v1',
    component: Decomposer_V1
  },
  {
    path: '/v1/qui-sommes-nous',
    name: 'Qui sommes nous ?',
    component: QuiSommesNous
  },

  // v2
  {
    path: '/',
    name: 'Accueil v2',
    component: Accueil_v2
  },
  {
    path: '/v1/accueil',
    name: 'Accueil v1',
    component: Accueil_v1
  },
  {
    path: '/guide-enseignant',
    name: 'Guide Enseignant',
    component: GuideEnseignant
  },
  {
    path: '/le-projet',
    name: 'Projet',
    component: Projet
  },
  {
    path: '/partenaires',
    name: 'Partenaires',
    component: Partenaires
  },

  // v2
  {
    path: '/sommaire',
    name: 'ListeActivites',
    component: ListeActivites
  },
  {
    path: '/niveaux/:activite',
    name: 'Niveaux Activité',
    component: ListeNiveauxActivite
  },
  {
    path: '/activite/dictee-vocale',
    name: 'Dictée vocale custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: DicteeVocale
  },
  {
    path: '/activite/dictee-vocale/niveau/:niveau',
    name: 'Dictée vocale',
    component: DicteeVocale
  },
  {
    path: '/activite/jeu-des-quantites/',
    name: 'Jeu des quantites custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: JeuDesQuantites
  },
  {
    path: '/activite/jeu-des-quantites/niveau/:niveau',
    name: 'Jeu des quantites',
    component: JeuDesQuantites
  },
  {
    path: '/activite/complement/',
    name: 'Complement custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Complement
  },
  {
    path: '/activite/complement/niveau/:niveau',
    name: 'Complement',
    component: Complement
  },
  {
    path: '/activite/ordonner/',
    name: 'Ordonner custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Ordonner
  },
  {
    path: '/activite/ordonner/niveau/:niveau',
    name: 'Ordonner',
    component: Ordonner
  },
  {
    path: '/activite/multiplier/',
    name: 'Multiplier custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Multiplier
  },
  {
    path: '/activite/multiplier/niveau/:niveau',
    name: 'Multiplier',
    component: Multiplier
  },
  {
    path: '/activite/additionner/',
    name: 'Additionner custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Additionner
  },
  {
    path: '/activite/additionner/niveau/:niveau',
    name: 'Additionner',
    component: Additionner
  },
  {
    path: '/activite/soustraire/',
    name: 'Soustraire custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Soustraire
  },
  {
    path: '/activite/soustraire/niveau/:niveau',
    name: 'Soustraire',
    component: Soustraire
  },
  {
    path: '/activite/suite/',
    name: 'Suite custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Suite
  },
  {
    path: '/activite/suite/niveau/:niveau',
    name: 'Suite',
    component: Suite
  },
  {
    path: '/activite/operations-en-ligne/',
    name: 'Operations en ligne custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: OperationsEnLigne
  },
  {
    path: '/activite/operations-en-ligne/niveau/:niveau',
    name: 'Operations en ligne',
    component: OperationsEnLigne
  },
  {
    path: '/activite/decomposer/',
    name: 'Décomposer custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Decomposer
  },
  {
    path: '/activite/decomposer/niveau/:niveau',
    name: 'Décomposer',
    component: Decomposer
  },
  {
    path: '/activite/jeu-des-couleurs/',
    name: 'Jeu des couleurs custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: JeuDesCouleurs
  },
  {
    path: '/activite/jeu-des-couleurs/niveau/:niveau',
    name: 'Jeu des couleurs',
    component: JeuDesCouleurs
  },
  {
    path: '/activite/jeu-du-tableau/',
    name: 'Jeu du tableau custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: JeuDuTableau
  },
  {
    path: '/activite/jeu-du-tableau/niveau/:niveau',
    name: 'Jeu du tableau',
    component: JeuDuTableau
  },
  {
    path: '/activite/taguer/',
    name: 'Taguer custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Taguer
  },
  {
    path: '/activite/taguer/niveau/:niveau',
    name: 'Taguer',
    component: Taguer
  },
  {
    path: '/activite/double/',
    name: 'Double custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Double
  },
  {
    path: '/activite/double/niveau/:niveau',
    name: 'Double',
    component: Double
  },
  {
    path: '/activite/moitie/',
    name: 'Moitie custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: Moitie
  },
  {
    path: '/activite/moitie/niveau/:niveau',
    name: 'Moitie',
    component: Moitie
  },
  {
    path: '/activite/jeu-des-etiquettes/',
    name: 'JeuDesEtiquettes custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: JeuDesEtiquettes
  },
  {
    path: '/activite/jeu-des-etiquettes/niveau/:niveau',
    name: 'JeuDesEtiquettes',
    component: JeuDesEtiquettes
  },
  {
    path: '/activite/pareil-que/',
    name: 'CestPareilQue custom', // Données issues de la boîte de réglages de l'activité, et passées via l'URL
    component: CestPareilQue
  },
  {
    path: '/activite/pareil-que/niveau/:niveau',
    name: 'CestPareilQue',
    component: CestPareilQue
  },

  // Jeux
  {
    path: '/jeux/asteroids',
    name: 'Asteroids',
    component: Asteroids
  },
  {
    path: '/jeux/mitraille',
    name: 'Breakout',
    component: Breakout
  },
  {
    path: '/jeux/frogger',
    name: 'Frogger',
    component: Frogger
  },

  // Outils
  {
    path: '/tables/:outil',
    name: 'Tables Outil',
    component: ListeTables
  },
  {
    path: '/outil/table-de-multiplication/table/:niveau',
    name: 'Tables de multiplication',
    component: TableMultiplication
  },
  {
    path: '/outil/reglette',
    name: 'Reglette',
    component: Reglette
  },
  {
    path: '/outil/n1',
    name: 'N1',
    component: N1
  },
  {
    path: '/outil/table-de-multiplication/niveau/:niveau',
    name: 'Table de multiplication',
    component: TableMultiplication
  },

  // Parcours
  {
    path: '/parcours/sommaire-parcours/:id',
    name: 'Sommaire Parcours',
    component: SommaireParcours
  },
  {
    path: '/parcours/bilan-parcours/:id',
    name: 'Bilan Parcours',
    component: BilanParcours
  },

  // Settings
  {
    path: '/parametres',
    name: 'general-settings',
    component: GeneralSettings
  },

  // Intégration CSS du design
  {
    path: '/design/composants-v1',
    name: 'Test',
    component: TestsComposantsV1
  },
  {
    path: '/design/slots',
    name: 'TestsSlots',
    component: TestsSlots
  },
  {
    path: '/design/cards',
    name: 'TestsCards',
    component: TestsCards
  },
  {
    path: '/design/cards/noir-et-blanc',
    name: 'TestsCards Noir et blanc',
    component: TestsCards
  },
  {
    path: '/design/typos',
    name: 'TestsTypos',
    component: TestsTypos
  },
  {
    path: '/design/niveaux',
    name: 'TestNiveaux',
    component: TestNiveaux
  },
  {
    path: '/design/help_panel',
    name: 'TestHelpPanel',
    component: TestHelpPanel
  },
  {
    path: '/design/des',
    name: 'TestDes',
    component: TestDes
  },
  {
    path: '/design/ada',
    name: 'TestsAda',
    component: TestsAda
  },
  {
    path: '/design/etiquettes',
    name: 'TestsEtiquettes',
    component: TestsEtiquettes
  },
  {
    path: '/design/controls',
    name: 'TestControls',
    component: TestControls
  },
  {
    path: '/design/settings-activites',
    name: 'TestSettingsActivites',
    component: TestSettingsActivites
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
