<template>
    <div ref="reglette" class="reglette_tool">
    </div>

</template>

<script setup>

  import {ref, onMounted, watch} from "vue";


  let reglette = ref(null);


  const props = defineProps({
      posx: {
        type: Number,
        required: true,
        default: 1
      },
      posy: {
        type: Number,
        required: true,
        default: 1
      },
      isHidden: {
        type:Boolean,
        required: true,
        default: false
      }
  })

  /* Computed 

  const stateCssClass = computed(() => {
    var str = 'top : ' + props.posy + 'px';
    return str;
  });
*/

  const update = function() {

    var $reglette = reglette.value;

    console.log("REGLETTE  ", $reglette);

  }

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });

</script>





<style lang="scss">
  .reglette_tool {
    //position:absolute;
    display:block;
    height:268px;
    width:76px;
    background-size: 100% 100%;
    background-image: url(../../assets/images/svg/ruler_v2.svg);
  }

</style>