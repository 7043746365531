<template>
  <a class="add-tab" href="#">
    <span>
    +
    </span>
  </a>

</template>

<script>
</script>


<style scoped lang="scss">

a.add-tab {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-decoration: none;

  & > span {
    padding: 5px;
    font-size: 24px;
    line-height: 30px;
    color: #666666;
  }
}

</style>
