<template>
  <DeUnite1 v-if="refChiffre === 1" />
  <DeUnite2 v-else-if="refChiffre === 2" />
  <DeUnite3 v-else-if="refChiffre === 3" />
  <DeUnite4 v-else-if="refChiffre === 4" />
  <DeUnite5 v-else-if="refChiffre === 5" />
  <DeUnite6 v-else-if="refChiffre === 6" />
  <DeUnite7 v-else-if="refChiffre === 7" />
  <DeUnite8 v-else-if="refChiffre === 8" />
  <DeUnite9 v-else-if="refChiffre === 9" />
  <DeUnite10 v-else-if="refChiffre === 10" />
</template>

<style scoped lang="scss">
</style>

<script setup>

import {ref, onMounted, watch} from "vue";

import DeUnite1 from "./DeCouleursUnite1";
import DeUnite2 from "./DeCouleursUnite2";
import DeUnite3 from "./DeCouleursUnite3";
import DeUnite4 from "./DeCouleursUnite4";
import DeUnite5 from "./DeCouleursUnite5";
import DeUnite6 from "./DeCouleursUnite6";
import DeUnite7 from "./DeCouleursUnite7";
import DeUnite8 from "./DeCouleursUnite8";
import DeUnite9 from "./DeCouleursUnite9";
import DeUnite10 from "./DeCouleursUnite10";


//
// Reactive values
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  }
});

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>