<template>
  <a class="replay activite-recommencer" href="#" title="recommencer">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" transform="translate(0,2)">
      <path d="M1.33325 2.66667V6.66667H5.33325" stroke="#15299C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.00659 9.99999C3.89459 12.5205 6.34248 14.1499 9.0104 13.9963C11.6783 13.8427 13.9231 11.9433 14.5161 9.33756C15.109 6.73185 13.9072 4.04805 11.5685 2.75516C9.22972 1.46227 6.31781 1.87196 4.42659 3.75999L1.33325 6.66665" stroke="#15299C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Recommencer
  </a>
</template>

<script setup>
</script>

<style scoped lang="scss">
</style>
