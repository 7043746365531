<template>
  <div class="de"></div>
</template>

<style scoped lang="scss">

  .de {
    width: 40px;
    height: 44px;
    background-image: url(../../../../assets/images/svg/des_v2/unites_noir_et_blanc/Number_1.svg);

    &.bg-svg {
      background-image: url(../../../../assets/images/svg/des_v2/unites_noir_et_blanc/Number_1.svg);
    }

    &.bg-image {
      background-image: url(../../../../assets/images/svg/des_v2/unites_noir_et_blanc/Number_1_4x.png);
    }
  }

</style>
