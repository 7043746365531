<template>
  <div class="settings-tab-content">
    <div class="settings-tab-row flex flex-gap-10 flex-align-end">
      <div>Combien y-a-t'il de</div>
      <ListeDeroulanteColonnes
          ref="selectColonnne"
          @change="onChangeColonne"
      />
      <div>dans</div>
      <InputNombre
          ref="inputText"
          :input-value="currentInputValue"
          text-label="Nombre"
          @change="onChangeInput"
      />
    </div>
  </div>
</template>

<script setup>

  import InputNombre from "../Controls/InputNombre";
  import {onMounted, ref, watch} from "vue";
  import ListeDeroulanteColonnes from "../Controls/ListeDeroulanteColonnes";

  //
  // Propriétés du composant
  //

  const props = defineProps({
    tabId: {
      type: String,
      required: false,
      default: ""
    },
    tabValues: {
      type: Object,
      required: false,
      default: null
    },
  });

  // Référence des éléments du formulaire
  const inputText = ref(null);
  const selectColonnne = ref(null);

  // Valeurs des différents onglets
  const currentTabValues = ref();

  // Valeur de l'onglet courant
  const currentInputValue = ref("");
  const currentListItemValue = ref();

  const emit = defineEmits(['change'])


  //
  // Lifecycle Hooks
  //

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });

  function update() {
    // console.log("props.tabValues", JSON.stringify(props.tabValues));
    const valueObj = currentTabValues.value = props.tabValues;

    // Champ texte :
    if ((valueObj !== null) && (valueObj.v !== undefined)) {
      currentInputValue.value = valueObj.v;
    } else {
      currentInputValue.value = "";
      inputText.value.reset();
    }

    // Liste :
    let itemValue;
    if ((valueObj !== null) && (valueObj.colonne !== undefined)) {
      itemValue = valueObj.colonne;
    } else {
      itemValue = true;
    }

    currentListItemValue.value = itemValue;
    selectColonnne.value.setSelect(itemValue);
  }

  //
  // Events
  //

  function onChangeInput(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: inputValue, colonne: true };
    } else {
      currentTabValues.value.v = inputValue;
    }
    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeColonne(itemValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: "", colonne: itemValue };
    } else {
      currentTabValues.value.colonne = itemValue;
    }
    // console.log("change",  JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

</script>

<style scoped lang="scss">

</style>