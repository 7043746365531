<template>
  <div
      class="aide-colonne-quantites-milliers-unites"
      :class="columnCssClass"
      @click="clear($event)"
  >
    <div class="aide-colonne">
      <div v-for="item in items" :key="item" :class="columnItemCssClass(item)">

        <div v-if="columnType === 'centaines-milliers'">
          <BoutonDeCentainesMilliers
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>
        <div v-else-if="columnType === 'dizaines-milliers'" >
          <BoutonDeDizainesMilliers
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>
        <div v-else-if="columnType === 'milliers'">
          <BoutonDeMilliers
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>
        <div v-else-if="columnType === 'centaines'">
          <BoutonDeCentaine
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>
        <div v-else-if="columnType === 'dizaines-simples'">
          <BoutonDeDizaineSimple
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>
        <div v-else-if="columnType === 'dizaines'">
          <BoutonDeDizaine
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>
        <div v-else-if="columnType === 'unites' || columnType === 'unites-simples'">
          <BoutonDeUnite
              :chiffre="1"
              :class="columnItemCssClass(item)"
              @click="select($event,item)"
          />
        </div>

      </div>
    </div>

    <div class="led-validation-parent">
      <LedValidation
          class="led-validation aide-quantite without-border"
          :class="ledCssClass"
      />
    </div>

  </div>
</template>

<script setup>

import {computed, onMounted, ref, watch} from "vue";

  import BoutonDeCentainesMilliers from "../Des/Boutons/BoutonDeCentainesMilliers";
  import BoutonDeDizainesMilliers from "../Des/Boutons/BoutonDeDizainesMilliers";
  import BoutonDeMilliers from "../Des/Boutons/BoutonDeMilliers";
  import BoutonDeCentaine from "../Des/Boutons/BoutonDeCentaine";
  import BoutonDeDizaine from "../Des/Boutons/BoutonDeDizaine";
  import BoutonDeUnite from "../Des/Boutons/BoutonDeUnite";
  import BoutonDeDizaineSimple from "../Des/Boutons/BoutonDeDizaineSimple";
import LedValidation from "../Validation/LedValidation";

  const emit = defineEmits(['change']);


  const items = [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ];

  const props = defineProps({
    columnType: {
      type: String,
      required: false,
      default: "unites" // "milliers", "dizaines", "unites", ...
    },
    chiffre: {
      type: Number,
      required: false,
      default:5
    },
    cssClass: {
      type: String,
      required: false,
      default:""
    }
  });


  //
  // Reactive values
  //

  // Chiffre proposé
  const refChiffre = ref(0);

  // Chiffres proposés
  const refChiffres = ref([])


  const columnCssClass = computed(() => {
    return [
      refChiffres.value.length === props.chiffre ? 'correct' : 'erreur',
      props.columnType + "-column",
      props.cssClass].join(" ");
  });

  const ledCssClass = computed(() => {
    if (refChiffre.value === -1) return "";
    return refChiffres.value.length === props.chiffre ? 'is-correct' : 'is-wrong';

    /*
    if (refChiffre.value === -1) return "";
    return refChiffre.value === props.chiffre ? 'is-correct' : 'is-wrong';
     */
  });

  const columnItemCssClass = function(item) {
    return refChiffres.value.indexOf(item) === -1 ? 'off' : '';

    /*
    return  [
      item <= 9 - refChiffre.value ? 'off' : '',
      item === 9 - refChiffre.value + 1 ? 'first-off' : ''
    ]
     */
  };


  //
  // Lifecycle hooks
  //

  const update = function() {

    refChiffre.value = -1;
    refChiffres.value = [];

    if (props.chiffre === 0) {
      emitOnMountedChangeEvent();
    }
  };

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });


  //
  // Evènements
  //

  const select = function( $event, no ) {
    $event.stopPropagation();
    refChiffre.value = 10 - no;

    const position = refChiffres.value.indexOf(no);
    if (position === -1) {
      refChiffres.value.push(no);
    } else {
      refChiffres.value.splice(position, 1);
    }

    emitChangeEvent();
  }

  const clear = function( $event ) {
    $event.stopPropagation();
    refChiffre.value = 0;
    refChiffres.value = [];

    emitChangeEvent();
  }

  const emitChangeEvent = function( eventType = "onClick") {
    emit( 'change', props.columnType, refChiffres.value.length, refChiffres.value.length === props.chiffre, eventType);

    // emit( 'change', props.columnType, refChiffre.value, refChiffre.value === props.chiffre, eventType);
  }


  // Cas particulier : au lancement de l'activité, si le chiffre est 0
  // il est considéré comme correct par défaut : on prévient l'activité
  // pour qu'elle mette à jour le tableau correct/incorrect des colonnes
  const emitOnMountedChangeEvent = function() {
    setTimeout(function() {
      emitChangeEvent("onMounted");
    }, 100)
  }


</script>


<style scoped lang="scss">

.aide-colonne-quantites-milliers-unites {

  position: relative;

  .aide-colonne {

    position: relative;
    width: 108px;
    overflow: hidden;

    .image-de {
      width: 100px !important;
    }

    /* White / 100 */
    background: #FFFFFF;

    /* Colors/Green - 100 */
    border: 2px solid #CCC;
    border-radius: 16px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    @include on-print {
      width: 80px;
    }


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 75px;
      padding: 10px 20px;

      position: relative;

      @include on-print {
        height: 62px;
      }

      &.off::after {
        display: none;
      }

      &.first-off::after {
        border-radius: 16px 16px 0 0;
      }

      & > div {
        z-index: 2;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;

        position: absolute;
        bottom: 0;
        left:0;
        z-index: 1;

      }
    }
  }

  & > .led-validation-parent {
    position: absolute;
    top: -36px;
    left:-12px;
    z-index: 2;

    width: 58px;
    height: 48px;
    background-color: #fff !important;
    border-radius: 14px;
    border: solid 2px #CCC;

    @include on-print {
      display: none;
    }

    & > .led-validation {
      margin-top: -3px;
    }
  }

  &.centaines-column .aide-colonne,
  &.centaines-column .led-validation-parent,
  &.centaines-milliers-column .aide-colonne,
  &.centaines-milliers-column .led-validation-parent {
    border-color: #58CE45;
  }

  &.erreur .aide-colonne > div:after {
    background: rgba(0, 0, 0, 0.05) !important;
  }

  &.centaines-column .aide-colonne > div:after ,
  &.centaines-milliers-column .aide-colonne > div::after {
    /* Colors/Green - 25 */
    background: rgba(88, 206, 69, 0.25);
  }

  &.dizaines-column .aide-colonne,
  &.dizaines-column .led-validation-parent,
  &.dizaines-milliers-column .aide-colonne,
  &.dizaines-milliers-column .led-validation-parent {
    border-color: #FF3F33;
  }

  &.large .aide-colonne {
    width: 200px;
  }

  &.dizaines-simples-column .aide-colonne > div:after ,
  &.dizaines-column .aide-colonne > div:after ,
  &.dizaines-milliers-column .aide-colonne > div::after {
    /* Colors/Red - 25 */
    background: rgba(255, 63, 51, 0.25);
  }

  &.unites-simples-column .aide-colonne,
  &.unites-simples-column .led-validation-parent,
  &.unites-column .aide-colonne,
  &.unites-column .led-validation-parent,
  &.milliers-column .aide-colonne,
  &.milliers-column .led-validation-parent {
    border-color: #5BA0F1;
  }

  &.unites-simples-column .aide-colonne > div:after ,
  &.unites-column .aide-colonne > div:after ,
  &.milliers-column .aide-colonne > div::after {
    /* Colors/Blue - 25 */
    background: rgba(44, 133, 237, 0.25);
  }

}

</style>
