<template>
  <div class="content content-v2">
    <HeaderActivite
        title="Paramètres"
        :has-replay="false"
        :has-tools="false"
    />
    <main class="cards-and-sound-settings">
      <div class="cards-settings">
        <div>
          <h2>Couleurs des dés</h2>
          <div class="flex boutons-settings">
            <div>
              <DesAvecCouleurs
                  :class="desEnCouleurCssClass"
                  @click="onClickDesEnCouleur"
              />
              <span>Colorés</span>
            </div>
            <div>
              <DesNoirEtBlanc
                  :class="desEnNoirEtBlancCssClass"
                  @click="onClickDesNoirEtBlanc"
              />
              <span>Noirs et blancs</span>
            </div>
          </div>
        </div>
        <div>
          <h2>Affichage des milliers</h2>
          <div class="flex boutons-settings">
            <div>
              <SansMilliers
                  :class="avecSeparateurMilliersCssClass"
                  @click="onClickAvecSeparateurMilliers"
              />
              <span>Avec point</span>
            </div>
            <div>
              <AvecMilliers
                  :class="sansSeparateurMilliersCssClass"
                  @click="onClickSansSeparateurMilliers"
              />
              <span>Sans point</span>
            </div>
          </div>
      </div>
      </div>
      <div class="sound-settings">
        <h2>Son</h2>
        <div class="sound-settings-panel">
          <div class="sound-settings-general">
            <h3>Général</h3>
            <Checkbox
                text-label=""
                input-name="general"
                :checked="sonGeneral"
                :has-label="false"
                @change="onChangeGeneralSoundSetting"
            />
          </div>
          <div class="sound-settings-activites-jeux">
            <div class="sound-settings-activites">
              <h3>Activités</h3>
              <ul class="sound-settings-activites-columns">
                <li v-for="item in items" :key="item">
                  <span>{{ item.titre }}</span>
                  <Checkbox
                      text-label=""
                      :has-label="false"
                      :input-name="item.path"
                      :checked="item.sound_active"
                      @change="onChangeSoundSettingActivite"
                  />
                </li>
              </ul>
            </div>
            <div class="sound-settings-jeux">
              <h3>Jeux</h3>
              <ul class="sound-settings-jeux">
                <li v-for="item in jeux" :key="item">
                  <span>{{ item.titre }}</span>
                  <Checkbox
                      text-label=""
                      :has-label="false"
                      :input-name="item.path"
                      :checked="item.sound_active"
                      @change="onChangeSoundSettingJeu"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
    <ResponsiveWarning />
  </div>
</template>

<script setup>

import {computed, onBeforeMount} from "vue";
  import {ref} from "vue";
  import {useStore} from "vuex";

  import HeaderActivite from "../../../components/v2/Headers/HeaderActivite";
  import SansMilliers from "../../../components/v2/GeneralSettings/SansMilliers";
  import AvecMilliers from "../../../components/v2/GeneralSettings/AvecMilliers";
  import DesNoirEtBlanc from "../../../components/v2/GeneralSettings/DesNoirEtBlanc";
  import DesAvecCouleurs from "../../../components/v2/GeneralSettings/DesAvecCouleurs";
  import Checkbox from "../../../components/v2/Controls/Checkbox";
import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  const store = useStore();


  //
  // Reactive values
  //

  const items = ref(store.state.activites);
  const jeux  = ref(store.state.jeux);

  const sonGeneral = ref();
  const formeDes = ref();
  const desEnCouleurCssClass = computed(() => formeDes.value === "en-couleurs" ? "on" : "off");
  const desEnNoirEtBlancCssClass = computed(() => formeDes.value === "noir-et-blanc" ? "on" : "off");

  const separateurMilliers = ref();
  const avecSeparateurMilliersCssClass = computed(() => separateurMilliers.value === true ? "on" : "off");
  const sansSeparateurMilliersCssClass = computed(() => separateurMilliers.value === true ? "off" : "on");



  //
  // LifeCycle hooks
  //

  onBeforeMount(() => {
    formeDes.value = store.getters.formeDesV2();
    separateurMilliers.value = store.getters.separateurMilliers();
    sonGeneral.value = store.getters.sonGeneral();
    console.log("settings dés:", formeDes.value, "séparateur:", separateurMilliers.value, "son général", sonGeneral.value);
  })



  //
  // Fonctions spécifiques
  //

  const onClickDesEnCouleur = function() {
    formeDes.value = "en-couleurs";
    store.dispatch("saveSettingsV2_FormeDes", formeDes);
  }

  const onClickDesNoirEtBlanc = function() {
    formeDes.value = "noir-et-blanc";
    store.dispatch("saveSettingsV2_FormeDes", formeDes);
  }

  const onClickAvecSeparateurMilliers = function() {
    separateurMilliers.value = true;
    store.dispatch("saveSettingsV2_SeparateurMilliers", separateurMilliers);
  }

  const onClickSansSeparateurMilliers = function() {
    separateurMilliers.value = false;
    store.dispatch("saveSettingsV2_SeparateurMilliers", separateurMilliers);
  }


  //
  // Events
  //

  function onChangeGeneralSoundSetting( inputValue ) {
    store.dispatch("saveGeneralSoundSetting", { value: inputValue });
  }

  function onChangeSoundSettingActivite( inputValue, inputName ) {
    const activiteInfos = store.getters.activiteFromPath(inputName);
    store.dispatch("saveSoundSettingActivite", { path: activiteInfos.path, value: inputValue });
  }

  function onChangeSoundSettingJeu( inputValue, inputName ) {
    const activiteInfos = store.getters.activiteFromPath(inputName);
    store.dispatch("savePrintSettingActivite", { path: activiteInfos.path, value: inputValue });
  }

</script>

<style scoped lang="scss">

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
    color: #666666;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #666666;
  }

  .boutons-settings {
    justify-content: center;
    align-items: center;
    gap: 50px 35px;
    margin: 10px 0 20px;

    @include on-mobile {
      justify-content: flex-start;
    }

    span {
      display: block;
      text-align: center;
    }
  }

  .cards-and-sound-settings {
    display: flex;
    gap: 30px;
    padding-bottom: 20px;

    @include on-tablet {
      flex-direction: column;
    }

    .cards-settings {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 30px;

      @include on-tablet {
        flex-direction: row;
      }

      @include on-mobile {
        flex-direction: column;
      }
    }

    .sound-settings {
      flex: calc( 100% - 460px);
      width: calc( 100% - 460px);

      @include on-tablet {
        flex: 100%;
        width: 100%;
      }

      .sound-settings-panel {
        background: #FFFFFF;
        border: 1px solid rgba(102, 102, 102, 0.5);
        border-radius: 8px;
        margin: 10px 0 0;

        .sound-settings-general {
          display: flex;
          justify-content: space-between;
          align-items: center;

          border-bottom: 1px solid rgba(102, 102, 102, 0.5);
          padding: 15px;

          label {
            width: 42px;
          }
        }

        .sound-settings-activites-jeux {
          display: flex;
          gap: 40px;
          padding: 15px;

          .sound-settings-activites {
            flex: 60% 0 0;
          }

          .sound-settings-jeux {
            flex: calc( 40% - 40px ) 0 0;
          }
        }

        .sound-settings-activites-columns {
          columns: 2;
          column-gap: 30px;

          @include on-mobile {
            columns: 1;
          }
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(102, 102, 102, 0.5);

            font-size: 12px;
            line-height: 15px;
            break-inside: avoid;

            label {
              width: 42px;
            }
          }
        }

      }
    }
  }

</style>