<template>
  <div class="de image-de image-de-dizaines-simples"></div>
</template>

<style scoped lang="scss">

  .image-de.image-de-dizaines-simples {
    width: 65px;
    height: 53px;
    background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_simple/State_Enabled_Active_Yes.svg);

    &:hover {
      cursor: pointer;
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_simple/State_Hover_Active_Yes.svg);
    }

    &.pressed {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_simple/State_Pressed_Active_Yes.svg);
    }

    &.off {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_simple/State_Enabled_Active_No.svg);
    }

    &.off.pressed {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_simple/State_Pressed_Active_No.svg);
    }

    &.off:hover {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_simple/State_Hover_Active_No.svg);
    }

  }


</style>
