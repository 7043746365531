<template>
  <div class="activites-settings-panel">
    <div class="settings-panel-header">
      <h2 class="titan">{{ titreActivite }}</h2>
      <CloseWindowBtn class="close-settings-panel-btn" @click="sendCloseEvent" />
    </div>
    <div class="global-settings flex">
      <Checkbox
          text-label="Imprimer"
          input-name="print-setting"
          :checked="printSettingActivite"
          @change="onChangePrintSetting"
      />
      <Checkbox
          text-label="Son"
          input-name="sound-setting"
          :checked="soundSettingActivite"
          @change="onChangeSoundSetting"
      />
    </div>
    <div class="settings-tabs-title">
      <div>
        Configuration de l’exercice
      </div>
      <div style="display: none">
        Difficulté estimée
      </div>
    </div>
    <div class="tabs">
      <Tab
          v-for="(item, index) in tabs"
          :key="'tab'+item"
          :tab-id="item"
          :tab-index="index + 1"
          :selected="isSelectedTabId(item)"
          @remove="removeTab"
          @select="selectTab"
      />
      <AddTabBtn @click="addTab" :class="addTabCssClass" />
    </div>
    <div class="tabs-contents">

      <SettingsJeuDesCouleurs v-if="activite === 'jeu-des-couleurs'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsJeuDuTableau v-if="activite === 'jeu-du-tableau'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsDicteeVocale v-if="activite === 'dictee-vocale'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsTaguer v-if="activite === 'taguer'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsMoitie v-if="activite === 'moitie'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsDouble v-if="activite === 'double'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsSuite v-if="activite === 'suite'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsJeuDesEtiquettes v-if="activite === 'jeu-des-etiquettes'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsComplement v-if="activite === 'complement'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsDecomposer v-if="activite === 'decomposer'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsAdditioner v-if="activite === 'additionner'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsSoustraire v-if="activite === 'soustraire'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsMultiplier v-if="activite === 'multiplier'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsOrdonner v-if="activite === 'ordonner'"
        :tab-id="selectedTabId"
        :tab-values="selectedTabValues"
        @change="onChangeTabValues"
      />

      <SettingsCestPareilQue v-if="activite === 'pareil-que'"
       :tab-id="selectedTabId"
       :tab-values="selectedTabValues"
       @change="onChangeTabValues"
      />

      <SettingsJeuDesQuantites v-if="activite === 'jeu-des-quantites'"
       :tab-id="selectedTabId"
       :tab-values="selectedTabValues"
       @change="onChangeTabValues"
      />

      <SettingsOperationsEnLigne v-if="activite === 'operations-en-ligne'"
       :tab-id="selectedTabId"
       :tab-values="selectedTabValues"
       @change="onChangeTabValues"
      />

    </div>
    <div class="settings-panel-footer">
      <GenererBtn @click="generatePlaylist" />
    </div>
  </div>

</template>

<script setup>

  import {computed, onBeforeMount, ref} from "vue";

  import {useStore} from "vuex";
  import {useRouter} from "vue-router";

  import GenererBtn from "../../../components/v2/Boutons/GenererBtn";
  import Tab from "../../../components/v2/Boutons/Tab";
  import AddTabBtn from "../Boutons/AddTabBtn";
  import CloseWindowBtn from "../Boutons/CloseWindowBtn";
  import Checkbox from "../../../components/v2/Controls/Checkbox";

  import SettingsJeuDesCouleurs from "./SettingsJeuDesCouleurs";
  import SettingsJeuDuTableau from "./SettingsJeuDuTableau";
  import SettingsJeuDesQuantites from "./SettingsJeuDesQuantites";
  import SettingsDicteeVocale from "./SettingsDicteeVocale";
  import SettingsTaguer from "./SettingsTaguer";
  import SettingsDecomposer from "./SettingsDecomposer";
  import SettingsSuite from "./SettingsSuite";
  import SettingsMoitie from "./SettingsMoitie";
  import SettingsDouble from "./SettingsDouble";
  import SettingsJeuDesEtiquettes from "./SettingsJeuDesEtiquettes";
  import SettingsOrdonner from "./SettingsOrdonner";
  import SettingsAdditioner from "./SettingsAdditioner";
  import SettingsComplement from "./SettingsComplement";
  import SettingsMultiplier from "./SettingsMultiplier";
  import SettingsSoustraire from "./SettingsSoustraire";
  import SettingsOperationsEnLigne from "./SettingsOperationsEnLigne";
  import SettingsCestPareilQue from "./SettingsCestPareilQue";

  const store = useStore();
  const router = useRouter();

  let tabId = 1;
  const tabContentValuesKeyPrefix = "tab-key-prefix";
  let tabContentValues = [];


  //
  // Propriétés du composant
  //

  const props = defineProps({
    activite: {
      type: String,
      required: false,
      default: 'jeu-des-couleurs'
    }
  });


  //
  // Reactive values
  //

  const titreActivite = ref(null);
  const soundSettingActivite = ref(null);
  const printSettingActivite = ref(null);

  const tabs = ref(null);

  const selectedTabId = ref(tabId);
  const selectedTabValues = ref(null);

  const addTabCssClass = computed(() => Array.isArray(tabs.value) && tabs.value.length > 7 ? "hidden" : "" );


  //
  // Lifecycle Hooks
  //

  onBeforeMount(() => {

    // Détails de l'activité ( titre, nombre de niveaux, class CSS des niveaux, ...)
    const activiteInfos = store.getters.activiteFromPath(props.activite);
    titreActivite.value = activiteInfos.titre;
    soundSettingActivite.value = activiteInfos.sound_active;
    printSettingActivite.value = activiteInfos.print_active;
    // console.log(soundSettingActivite.value, printSettingActivite.value)

    // Tableau des ids des onglets ( par défaut : 1 onglet )
    tabs.value = [ tabId ];

  });


  //
  // Fonctions spécifiques
  //

  const isSelectedTabId = function(tabId) {
    return tabId === selectedTabId.value;
  }

  const addTab = function() {

    const newTabId = ++tabId;
    tabs.value.push(newTabId);

    // Valeurs associées
    tabContentValues[ tabContentValuesKeyPrefix + newTabId ] = {};

    selectedTabId.value = newTabId;
    selectedTabValues.value = null;
  };

  const removeTab = function( tabId ) {
    if ( Array.isArray(tabs.value) && (tabs.value.length > 1)) {
      const pos = tabs.value.indexOf(tabId);
      if (pos !== -1)
      {
        let nextTabId = false;

        if (tabId === selectedTabId.value) {
          if (pos > 0) {
            nextTabId = tabs.value[pos - 1];
          } else {
            nextTabId = tabs.value[0];
          }
        }

        tabs.value.splice(pos, 1);
        tabContentValues.splice(pos, 1);

        if (nextTabId) {
          selectTab(nextTabId);
        }
      }
    }
  };

  const selectTab = function( tabId ) {
    selectedTabId.value = tabId;
    selectedTabValues.value = tabContentValues[ tabContentValuesKeyPrefix + tabId ];
  };

  function generatePlaylist() {
    if (tabs.value.length)
    {
      const routePath = "/activite/" + props.activite;

      let i, tabId, tabValues, tabRoute, routes = [];
      for(i=0; i<tabs.value.length; i++)
      {
        tabId = tabs.value[i];

        tabValues = tabContentValues[ tabContentValuesKeyPrefix + tabId ];
        tabValues.playlist = i;

        tabRoute = {
          path  : routePath,
          query : tabValues
        };

        routes.push(tabRoute);
      }

      // Mémorisation de la playlist
      store.dispatch("savePlaylist", routes);

      // Lancement de la première activité
      router.push(routes[0]);
    }
  }


  //
  // Events
  //

  const emit = defineEmits(['close']);
  const sendCloseEvent = function() {
    emit("close");
  }

  function onChangeTabValues( eventValue ) {
    const tabId = parseInt(eventValue.tabId);
    if ( tabs.value.indexOf(tabId) !== -1) {
      tabContentValues[ tabContentValuesKeyPrefix + tabId ] = eventValue.tabValues;
      // console.log('onChangeTabValues', tabId, tabContentValues[ tabContentValuesKeyPrefix + tabId ])
    }
  }

  function onChangeSoundSetting( eventValue ) {
    const activiteInfos = store.getters.activiteFromPath(props.activite);
    store.dispatch("saveSoundSettingActivite", { path: activiteInfos.path, value: eventValue });
  }

  function onChangePrintSetting( eventValue ) {
    const activiteInfos = store.getters.activiteFromPath(props.activite);
    store.dispatch("savePrintSettingActivite", { path: activiteInfos.path, value: eventValue });
  }


</script>

<style scoped lang="scss">

  .activites-settings-panel {

    position: relative;
    width: 598px;
    padding: 5px 15px 20px;

    background: #FFFFFF;
    border: 2px solid #666666;
    border-radius: 12px;

    &.hidden {
      display: none;
    }

    h2 {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 10px;
    }

    .settings-panel-header {
      position: relative;

      .close-settings-panel-btn {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .settings-tabs-title {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 5px;
    }

    .settings-panel-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .global-settings {
      align-items: center;
      gap: 20px;
      border-bottom: 2px solid #E6E6E6;
      padding-bottom: 10px;
    }

    .tabs {
      display: flex;
      border-bottom: 2px solid #666666;
      padding-left: 5px;
    }

    .tabs-contents {
      padding: 10px 0;

      & > *.hidden {
        display: none;
      }
    }

  }

</style>