<template>
    <span class="signe" @click="playOperation">+</span>
</template>


<script setup>

  import {getSoundPath, playSound} from "../../../js/utils";

  const playOperation = function(){
        var soundPath = getSoundPath("plus");
        playSound(soundPath);
  }

</script>