<template>
  <div class="boite-unites">
    <div class="boite-bg">
      <div class="boite-header">
        <h4 class="titan">Unité</h4>
      </div>
      <div class="boite-content">
        <div class="boite-content-bg">
          <div></div>
          <div></div>
        </div>
        <div class="boite-content-wrapper">
          <div class="drop-zone-de"></div>
          <div>Fabrique 10 unités</div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">

  .boite-unites {

    position: relative;
    width: 280px;
    height: 176px;

    /* Light Grey/90 -100 */
    background: #47688f;

    /* Dark Grey/20 - 100 */
    border: 2px solid #333333;

    /* Dropshadow-Simple */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    .boite-bg {

      position: absolute;
      left:0;
      top:0;
      width: 100%;
      height: calc( 100% - 5px );
      background: #5b9deb;
      border-radius: 6px;

      .boite-header {
        padding: 20px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .boite-content {
        position: absolute;
        left: 8px;
        right: 7.5px;
        top: 60px;
        bottom: 12px;

        background: #46678e;
        border-radius: 4px;

        .boite-content-bg {
          position: absolute;
          display: block;
          width: 100%;
          height: 8px;
          left: 0px;
          top: 0px;

          background: #3d4d61;
          border-radius: 4px 4px 0 0;

          & > div:first-child {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 4px;
            bottom: -4px;

            background: #46678e;
            border-radius: 6px;
          }

          & > div:last-child {
            position: absolute;
            height: 98px;
            left: 0px;
            right: 0px;
            top: 0px;

            background: #C4C4C4;
            border-radius: 4px;
            opacity: 0;
          }

        }

        .boite-content-wrapper {
          height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          input[type="text"],
          .drop-zone-de {

            width: 76px;
            height: 42px;
            padding: 0;

            /* Light Grey/95 - 100 */
            border: 2px solid #F2F2F2;
            border-radius: 4px;
            background: rgba(51, 51, 51, 0.5);

          }

          input[type="text"] {

            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;

            // AUDREY TMP
            z-index:1000;
          }

          div {
            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #F2F2F2;
          }
        }
      }

    }
  }

</style>
