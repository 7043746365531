<template>
  <div class="gommette-parent">
    <div class="gommette with-dragged-style"></div>
  </div>
</template>

<style scoped lang="scss">

.gommette-parent {

  .gommette {
    display: block;
    width: 64px;
    height: 64px;
    background: url(../../../assets/images/svg/gommettes/CircleBlack.svg);
    background-repeat: no-repeat;
  }

  /* Circle */

  &.circle-black .gommette {
    background: url(../../../assets/images/svg/gommettes/CircleBlack.svg);
    background-repeat: no-repeat;    
  }

  &.circle-blue .gommette {
    background: url(../../../assets/images/svg/gommettes/CircleBlue.svg);
    background-repeat: no-repeat;    
  }

  &.circle-white .gommette {
    background: url(../../../assets/images/svg/gommettes/CircleWhite.svg);
    background-repeat: no-repeat;    
  }

  &.circle-red .gommette {
    background: url(../../../assets/images/svg/gommettes/CircleRed.svg);
    background-repeat: no-repeat;    
  }

  &.circle-green .gommette {
    background: url(../../../assets/images/svg/gommettes/CircleGreen.svg);
    background-repeat: no-repeat;    
  }

  &.circle-yellow .gommette {
    background: url(../../../assets/images/svg/gommettes/CircleYellow.svg);
    background-repeat: no-repeat;    
  }

  /* Square */

  &.square-black .gommette {
    background: url(../../../assets/images/svg/gommettes/SquareBlack.svg);
    background-repeat: no-repeat;    
  }

  &.square-blue .gommette {
    background: url(../../../assets/images/svg/gommettes/SquareBlue.svg);
    background-repeat: no-repeat;    
  }

  &.square-white .gommette {
    background: url(../../../assets/images/svg/gommettes/SquareWhite.svg);
    background-repeat: no-repeat;    
  }

  &.square-red .gommette {
    background: url(../../../assets/images/svg/gommettes/SquareRed.svg);
    background-repeat: no-repeat;    
  }

  &.square-green .gommette {
    background: url(../../../assets/images/svg/gommettes/SquareGreen.svg);
    background-repeat: no-repeat;    
  }

  &.square-yellow .gommette {
    background: url(../../../assets/images/svg/gommettes/SquareYellow.svg);
    background-repeat: no-repeat;    
  }

  /* Hexagone */

  &.hexagone-black .gommette {
    background: url(../../../assets/images/svg/gommettes/HexagoneBlack.svg);
    background-repeat: no-repeat;    
  }

  &.hexagone-blue .gommette {
    background: url(../../../assets/images/svg/gommettes/HexagoneBlue.svg);
    background-repeat: no-repeat;    
  }

  &.hexagone-white .gommette {
    background: url(../../../assets/images/svg/gommettes/HexagoneWhite.svg);
    background-repeat: no-repeat;    
  }

  &.hexagone-red .gommette {
    background: url(../../../assets/images/svg/gommettes/HexagoneRed.svg);
    background-repeat: no-repeat;    
  }

  &.hexagone-green .gommette {
    background: url(../../../assets/images/svg/gommettes/HexagoneGreen.svg);
    background-repeat: no-repeat;    
  }

  &.hexagone-yellow .gommette {
    background: url(../../../assets/images/svg/gommettes/HexagoneYellow.svg);
    background-repeat: no-repeat;    
  }

  /* Star */

  &.star-black .gommette {
    background: url(../../../assets/images/svg/gommettes/StarBlack.svg);
    background-repeat: no-repeat;    
  }

  &.star-blue .gommette {
    background: url(../../../assets/images/svg/gommettes/StarBlue.svg);
    background-repeat: no-repeat;    
  }

  &.star-white .gommette {
    background: url(../../../assets/images/svg/gommettes/StarWhite.svg);
    background-repeat: no-repeat;    
  }

  &.star-red .gommette {
    background: url(../../../assets/images/svg/gommettes/StarRed.svg);
    background-repeat: no-repeat;    
  }

  &.star-green .gommette {
    background: url(../../../assets/images/svg/gommettes/StarGreen.svg);
    background-repeat: no-repeat;    
  }

  &.star-yellow .gommette {
    background: url(../../../assets/images/svg/gommettes/StarYellow.svg);
    background-repeat: no-repeat;    
  }

  /* Triangle */

  &.triangle-black .gommette {
    background: url(../../../assets/images/svg/gommettes/TriangleBlack.svg);
    background-repeat: no-repeat;    
  }

  &.triangle-blue .gommette {
    background: url(../../../assets/images/svg/gommettes/TriangleBlue.svg);
    background-repeat: no-repeat;    
  }

  &.triangle-white .gommette {
    background: url(../../../assets/images/svg/gommettes/TriangleWhite.svg);
    background-repeat: no-repeat;    
  }

  &.triangle-red .gommette {
    background: url(../../../assets/images/svg/gommettes/TriangleRed.svg);
    background-repeat: no-repeat;    
  }

  &.triangle-green .gommette {
    background: url(../../../assets/images/svg/gommettes/TriangleGreen.svg);
    background-repeat: no-repeat;    
  }

  &.triangle-yellow .gommette {
    background: url(../../../assets/images/svg/gommettes/TriangleYellow.svg);
    background-repeat: no-repeat;    
  }
}

</style>

