<template>
  <div class="operation-addition">
    <div class="fleche-et-operation">
      <div class="operation">
        <SignePlus class="signe-operation" />
        <DeDizaine
            :chiffre="refDizaines"
            :with-sound="withSound"
            :css-class="cssClass"
        />
        <DeUnite
            :chiffre="refUnites"
            :with-sound="withSound"
            :css-class="cssClass"
        />
      </div>
      <FlecheOperation class="fleche" />
    </div>
    <SigneEgal />
  </div>
</template>

<script setup>

import SignePlus from "../Signes/SignePlus";
import DeUnite from "../Des/DesUnites/DeUnite";
import DeDizaine from "../Des/DesDizaines/DeDizaine";
import FlecheOperation from "../Operations/FlecheOperation";
import SigneEgal from "../Signes/SigneEgal";

import {onMounted, ref, watch} from "vue";
import {getDizainesUnites} from "../../../js/utils";


//
// Reactive values
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
});

let refChiffre = ref();
let refDizaines = ref();
let refUnites = ref();

const update = function() {
  refChiffre.value = props.chiffre;

  const dizainesUnites = getDizainesUnites(refChiffre.value);
  refDizaines.value = dizainesUnites.dizaines;
  refUnites.value = dizainesUnites.unites;

  console.log('operation', refChiffre.value, refDizaines.value, refUnites.value);
};

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});


</script>

<style scoped lang="scss">

.operation-addition {

  position: relative;

  .signe-operation {
    margin-right: 10px;
  }

  .fleche-et-operation {
    position: absolute;
    transform: translate(-50%, -130%);

    .fleche {
      margin-left: 30px;
    }
  }

  .operation {
    display: flex;
    align-items: center;
    gap: 10px;

    @include on-print {
      gap: 3px;
    }
  }

}

</style>

