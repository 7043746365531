<template>
  <div @click="playSoundChiffre">
    <DeCentaineMillierComplex0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeCentaineMillierComplex1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeCentaineMillierComplex2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeCentaineMillierComplex3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeCentaineMillierComplex4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeCentaineMillierComplex5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeCentaineMillierComplex6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeCentaineMillierComplex7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeCentaineMillierComplex8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeCentaineMillierComplex9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeCentaineMillierComplex10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<script setup>

import DeCentaineMillierComplex0 from "./DeCentaineMillierComplex0";
import DeCentaineMillierComplex1 from "./DeCentaineMillierComplex1";
import DeCentaineMillierComplex2 from "./DeCentaineMillierComplex2";
import DeCentaineMillierComplex3 from "./DeCentaineMillierComplex3";
import DeCentaineMillierComplex4 from "./DeCentaineMillierComplex4"
import DeCentaineMillierComplex5 from "./DeCentaineMillierComplex5";
import DeCentaineMillierComplex6 from "./DeCentaineMillierComplex6";
import DeCentaineMillierComplex7 from "./DeCentaineMillierComplex7";
import DeCentaineMillierComplex8 from "./DeCentaineMillierComplex8";
import DeCentaineMillierComplex9 from "./DeCentaineMillierComplex9";
import DeCentaineMillierComplex10 from "./DeCentaineMillierComplex10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_CentaineMillier,
  playSound
} from "../../../../js/utils";


//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  }
});


//
// Reactive values
//

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  var soundPath;
  if (props.withSound) {
    if (props.withSoundDecomposed)
      soundPath = getSoundPath_CentaineMillier(refChiffre.value);
    else 
      soundPath = getSoundPath_Chiffre(refChiffre.value * 100000);
    playSound(soundPath);
  } 
}


//
// LifeCycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});


</script>

<style scoped lang="scss">
</style>
