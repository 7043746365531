<template>
  <div class="help-panel help-panel-dizaines-unites" :class="isOpenedCssClass">
    <div class="help-panel-button">
      <a href="#" @click="togglePanel($event)"></a>
    </div>
    <div class="table-parent">
      <table class="help-panel-contents">
        <thead>
          <tr>
            <th class="help-panel-close-parent-button">
              <a class="help-panel-close-button" href="#" @click="closePanel($event)"></a>
            </th>
            <th>
              <div>Dizaines</div>
            </th>
            <th>
              <div>Unités</div>
            </th>
          </tr>
        </thead>
        <tbody class="thin-scroll">
          <tr v-for="item in items" :key="chiffreCourant* 10 + item" :data-key="chiffreCourant* 100 + item">
            <td>
              {{ item }}
            </td>
            <td class="dizaines" :class="dizainesCssClass(item)">
              <DeDizaineNoirEtBlanc v-if="dizainesCssClass(item) ==='off'"
                :chiffre="1"
                :with-sound="false"
                @click="selectDizaine(item)"
              />
              <DeDizaineCouleur v-else
                :chiffre="1"
                :with-sound="false"
                @click="selectDizaine(item)" />
            </td>
            <td class="unites" :class="unitesCssClass(item)">
              <DeUniteNoirEtBlanc v-if="unitesCssClass(item) ==='off'"
                :chiffre="1"
                :with-sound="false"
                @click="selectUnite(item)"
              />
              <DeUnite v-else
                :chiffre="1"
                :with-sound="false"
               @click="selectUnite(item)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>

  import {computed, ref, onMounted, watch} from "vue";

  import DeDizaineCouleur from "../Des/DesDizainesCouleur/DeDizaineCouleur";
  import DeDizaineNoirEtBlanc from "../Des/DesDizainesNoirEtBlanc/DeDizaineNoirEtBlanc";
  import DeUnite from "../Des/DesUnites/DeUnite";
  import DeUniteNoirEtBlanc from "../Des/DesUnitesNoirEtBlanc/DeUniteNoirEtBlanc";
  import {
    getDizainesUnites,
    getSoundPath_Chiffre,
    getSoundPath_Dizaine,
    getSoundPath_Unite,
    playSound
  } from "../../../js/utils";


  //
  // Propriétés du composant
  //

  const props = defineProps({
    minimum: {
      type: Number,
      required: false,
      default: 0
    },
    maximum: {
      type: Number,
      required: false,
      default: 10
    },
    chiffre: {
      type: Number,
      required: false,
      default: -1
    },
    chiffreMisEnValeur: {
      type: Boolean,
      required: false,
      default: true
    },
    soundMode: {
      type: String,
      required: false,
      default: "nombre"
    },
  });


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    updatePanel();
  });

  onMounted(() => {
    initPanel();
    updatePanel();
  });


  //
  // Données et fonctions du composant
  //

  const items = ref(null);

  const isOpened = ref(false);
  const isOpenedCssClass = computed(() => isOpened.value ? 'opened' : '');

  let chiffreCourant = ref(-1);
  let dizaines = ref(-1);
  let unites = ref(-1);


  const initPanel = function() {
    let i;
    items.value = [];
    for(i=props.minimum; i<= props.maximum; i++) {
      items.value.push(i);
    }
  };

  const updatePanel = function() {

    // Chiffre
    chiffreCourant.value = props.chiffre;

    // Décomposition du chiffre
    const dizainesUnites = getDizainesUnites(chiffreCourant.value);
    dizaines.value = dizainesUnites.dizaines;
    unites.value = dizainesUnites.unites;
  }

  const unitesCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return item > unites.value ? 'off' : '';
  };

  const dizainesCssClass = function(item) {
    if (! props.chiffreMisEnValeur) return ''
    else return item > dizaines.value ? 'off' : '';
  };


  //
  // Evènements
  //


  // Sélection

  function selectDizaine(no) {
    const soundPath = props.soundMode === "decomposition" ? getSoundPath_Dizaine(no) : getSoundPath_Chiffre(no * 10) ;
    playSound(soundPath);
  }

  function selectUnite(no) {
    const soundPath = props.soundMode === "decomposition" ? getSoundPath_Unite(no) : getSoundPath_Chiffre(no) ;
    playSound(soundPath);
  }

  // Fermer / Ouvrir (transition CSS)

  function togglePanel($event) {
    $event.preventDefault();
    isOpened.value = ! isOpened.value;
  }

  function closePanel($event) {
    $event.preventDefault();
    isOpened.value = false;
  }

</script>

<style scoped lang="scss">

.help-panel-dizaines-unites {

  transform: translateX(470px);
  transition: transform ease-in-out 0.75s;

  &.opened {
    transform: translateX(0);
  }

  table {

    width: 450px;

    tbody {
      display: block;
      height: calc( 100vh - 110px );
      overflow: auto;
      pointer-events: auto;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }

    .dizaines.off,
    .unites.off {
      opacity: 0.5;
    }

  }


}

</style>
