<template>
  <div class="clavier-etiquettes-lettres-v2">
    <ul>
      <li v-for="(item, index) in etiquetteLettres"
          v-bind:key = "item+index"
          class="draggable"
      >
        <div :data-value="item" :data-numeric="getNumericValueAt(index)" class="with-value with-dragged-style">
          <Etiquette :chiffre="item" :class="getCssClassAt(index)" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>

// Props :

import {onMounted, ref, watch} from "vue";
import Etiquette from "../Etiquettes/Etiquette";
import {getChiffreEnLettres} from "../../../js/utils";

const props = defineProps({
  etiquettes: {
    type: Array,
    required: true
  },
  etiquettesEnLettres: {
    type: Array,
    required: false,
  }
});

//
// Reactive value
//

const etiquetteLettres = ref(null);


//
// Lifecycle hooks
//

watch( props, () => {
  update();
});

onMounted(() => {
  update();
});

//
// Fonctions spécifiques
//

const update = function() {

  let chiffresEnLettres = [];

  if (Array.isArray(props.etiquettes)) {
    if (! Array.isArray(props.etiquettesEnLettres)) {
      let i, chiffre, n = props.etiquettes.length;
      for(i=0;i<n;i++) {
        chiffre = props.etiquettes[i];
        chiffresEnLettres.push(getChiffreEnLettres(chiffre));
      }
    } else {
      chiffresEnLettres = props.etiquettesEnLettres.concat();
    }
  }

  etiquetteLettres.value = chiffresEnLettres;
  console.log(chiffresEnLettres);
}

const getNumericValueAt = function(i) {
  return props.etiquettes[i];
}

const getCssClassAt = function(i) {

  const chiffre = props.etiquettes[i]

  if (chiffre < 0) {
    return "";
  } else if (chiffre === 100) {
    return "green";
  } else if (chiffre === 1000) {
    return "blue";
  }

  return props.etiquettes[i] < 10 ? "blue" : "red";
}



</script>

<style scoped lang="scss">

  /* Clavier Etiquettes Milliers, Centaines, ... */

  .clavier-etiquettes-lettres-v2 {

    display: flex;
    align-items: center;
    height: 225px;

    // Bg et border :
    ul {
      /* Light Grey/90 -100 */
      background: #E6E6E6;

      /* Light Grey/95 - 100 */
      border: 8px solid #F2F2F2;
      /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25); */
      border-radius: 32px 32px 0px 0px;
      border-bottom: none;

      @include on-print {
        background: none;
        border: none;
      }
    }

    ul {
      list-style-type: none;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 800px;

      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 5px;

      li {

        div {

          span {
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover;
            width: 156px;
            height: 96px;

            &.etiquette-unites {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/unites_4x.png);
            }

            &.etiquette-centaines {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/centaines_4x.png);
            }

            &.etiquette-dizaines {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/dizaines_4x.png);
            }

            &.etiquette-milliers {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/milliers_4x.png);
            }

            &.etiquette-dizaines-milliers {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/dizaines_milliers_4x.png);
            }

            &.etiquette-centaines-milliers {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/centaines_milliers_4x.png);
            }

          }

        }
      }
    }
  }

</style>
