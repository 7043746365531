<template>
  <ListeDeroulante
      ref="selectColonnes"
      :values="options"
      @change="onChangeSelect"
  />
</template>

<script setup>

import ListeDeroulante from "./ListeDeroulante";
import {defineExpose, ref} from "vue";


//
// Reactive values
//

const selectColonnes = ref();

// Valeurs de la liste déroulante
const options = ref();
options.value = [
  { value: "unites", label: "Unités" },
  { value: "dizaines", label: "Dizaines" },
  { value: "centaines", label: "Centaines" },
  { value: "milliers", label: "Milliers" },
  { value: "dizaines-milliers", label: "Dizaines de milliers" },
  { value: "centaines-milliers", label: "Centaines de milliers" }
]


//
// Events
//

const emit = defineEmits(['change']);

function onChangeSelect(value) {
  emit( 'change', value);
}


//
// Méthodes publiques
//

defineExpose({
  setSelect
})

function setSelect(value) {
  selectColonnes.value.setSelect(value);
}


</script>

<style scoped lang="scss">
</style>