<template>
  <div class="header-activite" >
    <router-link :to="backRoute" class="back-button" ></router-link>
    <div class="buttons">
      <a class="restart-button" :class="replayCss" @click="replay" />
      <a class="tools-button" :class="toolsCss" @click="toggleTools" />
    </div>
  </div>
</template>

<script setup>

  import {onMounted, computed, ref} from "vue";
  import {useRouter} from "vue-router";

  const router = useRouter();

  // Par défaut, retour à l'accueil
  const backRoute = ref('/');

  const emit = defineEmits(['replay', 'toggleTools']);

  function replay() {
    emit('replay');
  }

  function toggleTools() {
    emit('toggleTools');
  }

  //
  // Propriétés
  //

  const props = defineProps({
    hasReplay: {
      type: Boolean,
      required: false,
      default: true
    },
    hasTools: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  //
  // LifeCycle Hooks
  //

  onMounted(() => {

      backRoute.value = router.resolve({
        name: "ListeActivites"
      }).href;

  });

  const replayCss = computed(() => props.hasReplay ? '' : 'hidden' );
  const toolsCss = computed(() => props.hasTools ? '' : 'hidden' );

</script>

<style scoped lang="scss">

  .header-activite {

    position: relative;

    /* Back */

    a.back-button {
      position: absolute;
      top: 20px;
      left: 0;

      background: url(../../../assets/images/svg/icones_v2/Back_4x.png) center / cover no-repeat;
      width:63px;
      height:71px;

      /*
      border: 2px solid #333333;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #333333;
      border-radius: 10px;
      */

      @include on-print {
        display: none;
      }
    }

    /* Tools + replay */

    .buttons {

      position: absolute;
      top: 20px;
      right: 0;

      a {
        display: inline-block;
        width:63px;
        height:71px;
        cursor: pointer;

        @include on-mobile {
          width:50px;
          height:60px;
        }

        &.tools-button {
          background: url(../../../assets/images/svg/icones_v2/Tools_4x.png) center / cover no-repeat;
        }

        &.restart-button {
          background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / cover no-repeat;
        }

      }
    }

  }

</style>