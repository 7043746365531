<template>

    <div class="jeu-des-couleurs">

      <div ref="interaction" class="interaction">

        <div class="de-et-slot">

          <Slots
              ref="slots1"
              :chiffre="refNombre"
              :has-validation="false"
              :with-sound="withSound"
          />

          <div style="min-height: 48px">
            <LedValidation v-if="niveau === 2" class="validation" :class="cssClassValidation" />
          </div>

          <div class="des-content">

            <div class="des-parent" :class="'de-'+refNombre">

              <!-- DES -->
              <DeCouleursUniteOutline class="des-outline" ref="des" :chiffre="refNombre" @click-des="clickDes"/>

              <!-- DES EN COULEURS -->
              <DeCouleursUnite v-if="deCouleursVisible" class="des-couleurs" :chiffre="refNombre" />

            </div>

          </div>

          <div class="couleurs-tools">
            <!-- AIDE -->
            <a href="#" class="help" @mousedown="showCorrectColors" ></a>

            <!-- TRASH -->
            <a href="#" class="trash" :class="trashCssClass" @click="clickTrash" ></a>
          </div>

        </div>

        <!-- PALETTE COULEUR -->
        <PaletteDeCouleurs ref="palette" class="palette" :paletteChiffres="paletteChiffres" :isEnded ="isEnded" @select-color="selectColor" />

      </div>

      <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne_CurrentNumber"/>

    </div>

</template>

<script setup>

  import {onMounted, ref, defineExpose, onUnmounted, watch, computed} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from "jquery";
  import {
    getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval,
  } from "../../../js/utils";

  import PaletteDeCouleurs from "../../../components/v2/Palettes/PaletteDeCouleurs";
  import DeCouleursUniteOutline from "../Des/DesJeuCouleursOutline/DeCouleursUniteOutline";
  import DeCouleursUnite from "../Des/DesJeuCouleurs/DeCouleursUnite";
  import Slots from "../Slots";
  import Ada from "../Ada";
  import LedValidation from "../Validation/LedValidation";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 10;

  // Couleurs trouvées
  let correct_colors = [];

  var nombre;
  var colorchoosen = "";
  var numberchoosen;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    withFreeColors: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  })


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  let refNombre = ref();
  let paletteChiffres = ref();
  let isEnded = ref();
  let cssClassValidation = ref();
  let deCouleursVisible = ref();

  // DES
  const des = ref();

  // Couleurs remplies
  let filled_colors = ref();


  const trashCssClass = computed(() => {
    return Array.isArray(filled_colors.value) && (filled_colors.value.length > 0) ? "on" : "off"
  })


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }



  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    document.body.style.cursor = "default";
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    document.body.style.cursor = "default";
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initInterface();
      initActivite(props.values);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initInterface();
      initActivite();
    }
  }


  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    currentMinimumNumber = 1;
    currentMaximumNumber = 10;

    // Ada
    success = 0;
    successMax.value = 3;

  }

  const getNiveauFromValues = function() {
    return props.withFreeColors ? 3 : 2;
  }


  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffre, avecConsigne = false) {

    // Ada
    updateAda("normal");

    cssClassValidation.value = "";
    isEnded.value = false;

    // On retire les couleurs éventuelles
    $(".couleur-point").removeClass("jaune vert rouge bleu violet correct");

    // Couleurs remplies
    filled_colors.value = [];

    // Couleurs trouvées
    correct_colors = [];

    if ((chiffre === null) || (chiffre === undefined)) {
      // Tirage au sort
      nombre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
    } else {
      // Saisie manuelle
      nombre = parseInt(chiffre);
    }

    // Dé + slot
    refNombre.value = nombre;

    // Palette
    initPalette();

    if (avecConsigne) {
      // Son de la consigne, puis du chiffre
      playSound_Consigne_CurrentNumber();
    } else {
      playSound_CurrentNumber();
    }

  }

  const clickDes = function(obj) {

    const $point = $(obj.divchild);
    $point.removeClass("jaune vert rouge bleu violet correct");

    const pointName = $point.attr("class");

    // Mise à jour de la liste des couleurs remplies
    if (filled_colors.value.indexOf(pointName) === -1) {
      filled_colors.value.push(pointName);
    }

    // console.log("filled_colors", filled_colors.value);

    // Par défaut, on retire de la liste pour que tenir compte des cas où on change la couleur alors qu'elle était bonne :
    const position = correct_colors.indexOf(pointName);
    if (position !== -1) {
      correct_colors.splice(position, 1);
    }

    // On ajoute la couleur du pinceau à la classe CSS pour colorier le point
    $point.addClass(colorchoosen);


    switch (niveauActivite.value) {

      case 2:

        // Si la couleur est la bonne,
        // on ajoute le point à la liste des bonnes réponses :

        if (colorchoosen === obj.color ) {

          // Bonne couleur :
          $point.addClass("correct");

          if (correct_colors.indexOf(pointName) === -1)
          {
            // Ajout à la liste des couleurs correctes
            correct_colors.push(pointName);

            // Est ce que tous les points ont été correctement remplis ?
            if (correct_colors.length === nombre) {

              isEnded.value = true;

              /* CURSOR */
              document.body.style.cursor = "default";

              // Validation
              cssClassValidation.value = "is-correct";

              // Son de validation, succès et replay
              playSound_Validation_and_AddSuccess();

            } else {

              // Si la dernière réponse est correcte, mais que d'autres ne le sont pas, on affiche la croix rouge:
              console.log(correct_colors.length, filled_colors.value.length);
              cssClassValidation.value = correct_colors.length < filled_colors.value.length ? "is-wrong" : "";

            }
          }

        } else {

          // Mauvaise couleur :
          cssClassValidation.value = "is-wrong";
        }

      break;

      case 3:

        // Pas de validation

        // Palette
        updatePalette(filled_colors.value.length);

        // Est ce que tous les points ont été remplis ?
        if (filled_colors.value.length === nombre) {

          isEnded.value = true;

          /* CURSOR */
          document.body.style.cursor = "default";

          // Son de validation, succès et replay
          playSound_Validation_and_AddSuccess();

        }

      break;
     }
  }

  function initInterface() {

    paletteChiffres.value = [
      {  no:"1", color:"jaune",  isSelected:false, isInactive:true},
      {  no:"2", color:"rouge",  isSelected:false, isInactive:true},
      {  no:"3", color:"violet", isSelected:false, isInactive:true},
      {  no:"4", color:"vert",   isSelected:false, isInactive:true},
      {  no:"6", color:"bleu",   isSelected:false, isInactive:true}
    ]

    /* CURSOR */
    document.body.style.cursor = "default";
  }

  const initPalette = function() {
    let i;
    for(i=0; i<6; i++) {
      if (i+1 <= nombre) {
        if (paletteChiffres.value[i] !== undefined) {
          paletteChiffres.value[i].isInactive = false;
          paletteChiffres.value[i].isSelected = false;
          if ( nombre === 5 || nombre === 10 )
            paletteChiffres.value[4].isInactive = true;
        }
      }
    }
  }

  const updatePalette = function(nb_filled) {

    var nombre_restant = nombre - nb_filled;

    console.log("updatePalette nombre_restant = ", nombre_restant, numberchoosen);

    // numberchoosen === nb_found

    if (nombre_restant <= 1) {
      paletteChiffres.value[1].isInactive = true;
      paletteChiffres.value[2].isInactive = true;
      paletteChiffres.value[3].isInactive = true;
      paletteChiffres.value[4].isInactive = true;

      paletteChiffres.value[1].isSelected = false;
      paletteChiffres.value[2].isSelected = false;
      paletteChiffres.value[3].isSelected = false;
      paletteChiffres.value[4].isSelected = false;
    }

    if(nombre_restant <= 2) {
      paletteChiffres.value[2].isInactive = true;
      paletteChiffres.value[3].isInactive = true;
      paletteChiffres.value[4].isInactive = true;

      paletteChiffres.value[2].isSelected = false;
      paletteChiffres.value[3].isSelected = false;
      paletteChiffres.value[4].isSelected = false;
    }

    if(nombre_restant <= 3) {
      paletteChiffres.value[3].isInactive = true;
      paletteChiffres.value[4].isInactive = true;

      paletteChiffres.value[3].isSelected = false;
      paletteChiffres.value[4].isSelected = false;
    }

    if(nombre_restant <= 4) {
      paletteChiffres.value[4].isInactive = true;
      paletteChiffres.value[4].isSelected = false;
    }
  }

  const selectColor = function(color, chiffre) {
      colorchoosen = color;
      numberchoosen = chiffre;
  }

  const showCorrectColors = function() {
    if (! deCouleursVisible.value) {
      deCouleursVisible.value = true;
    }
    document.body.addEventListener('mouseup', hide);
  }

  const clickTrash = function(isComplete = true) {
    if (isComplete) {
      $(".couleur-point").removeClass("jaune vert rouge bleu violet correct");

      correct_colors = [];
      filled_colors.value = [];
      cssClassValidation.value = "";
      initPalette();

    } else {
      // Pas utilisé
      $(".couleur-point").not('.correct').removeClass("jaune vert rouge bleu violet correct");
      filled_colors.value = correct_colors.concat();
      updatePalette(filled_colors.value.length)
    }
  }

  const hide = function() {
    if (deCouleursVisible.value) {
      deCouleursVisible.value = false;
    }
    document.body.removeEventListener('mouseup', hide);
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Sons
  //

  function playSound_Consigne_CurrentNumber() {
    if (props.withSound) {
      const soundPathChiffre = getSoundPath_Chiffre(refNombre.value);
      const soundPath2 = getSoundPath_Consigne("tu-peux-appuyer-sur-la-fleche-pour-changer-de-de");
      if (niveauActivite.value === 2) {
        const soundPath1 = getSoundPath_Consigne("as-tu-bien-observe-le-code-couleur", true, "jeu-des-couleurs");
        playSound([ soundPath1, soundPath2, soundPathChiffre ] );
      }
      else if (niveauActivite.value === 3){
        const soundPath1 = getSoundPath_Consigne("colorie-les-des-comme-tu-veux", true, "jeu-des-couleurs");
        playSound([ soundPath1, soundPath2, soundPathChiffre ] );
      }
    }
  }

  function playSound_CurrentNumber() {
    if (props.withSound) {
      const soundPathChiffre = getSoundPath_Chiffre(refNombre.value);
      playSound(soundPathChiffre);
    }
  }

  function playSound_Validation_and_AddSuccess() {
    if (props.withSound)
    {
      const soundPathFelicitations = getSound_Felicitations();
      playSound(soundPathFelicitations, addSuccessAndReplay);
    }
    else
    {
      addSuccessAndReplay();
    }
  }

</script>

<style scoped lang="scss">

  .jeu-des-couleurs {

    .interaction {
      position: unset;
      padding-top: 0;

      min-width:767px;
      min-height: 618px;

      .palette {
        position: fixed;
        bottom: 0;
        right: 0;

        @include on-small-desktop {
          transform: scale(0.60);
          transform-origin: bottom right;
        }

        @include on-print {
          transform: scale(0.60);
          transform-origin: bottom right;
        }

      }

    }

    .souris {
      clear: both;
    }

    .souris.show {
      display : block;
      opacity :  1;
    }

    .souris.hide {
      clear: both;
      display : none;
      opacity :  0;
    }
  
    // AUDREY FORCE COLOR  TMP
    .slot-zone.with-value.unites {
      color: rgba(51, 51, 51, 1);
      background-color: rgba(51, 51, 51, 0.1);
    }

    .de-et-slot {
      width: 40%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include on-small-desktop {
        width: 55%;
      }

      @include on-print {
        width: 55%;
      }

      .validation {
        margin-bottom: 30px;

        @include on-print {
          display: none;
        }
      }

      .des-content {
        display: flex;
        position: relative;

        .des-parent {
          position: relative;
          width: 288px;
          height: 288px;

          &.de-10 {
            width: 536px;
          }

          .des-outline,
          .des-couleurs {
            position: absolute;
            top:0;
            left:0;
          }

          .des-couleurs {
            z-index: 2;
          }
        }
      }

      .couleurs-tools {
        display: flex;
        gap: 5px;
        margin-top: 12px;

        @include on-print {
          display: none;
        }

        .trash {
          display: inline-block;
          width:136px;
          height: 79px;
          background: url(../../../assets/images/svg/icones_v2/Trash-off.svg) top center / 136px 79px no-repeat;

          &.on {
            background-image: url(../../../assets/images/svg/icones_v2/Trash-on.svg);
          }
        }

        .help {
          display: inline-block;
          width:136px;
          height: 79px;
          background: url("../../../assets/images/svg/icones_v2/Loupe-large.svg") center / auto 79px no-repeat;
        }
      }

    }
  }

</style>
