<template>
  <div class="content content-v2">
    <Asteroids :niveau="niveau" />
    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import Asteroids from "../../../components/v2/Jeux/Asteroids";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  import {computed} from "vue";
  import {useRoute} from "vue-router";

  const route = useRoute();
  const niveau = computed(() => parseInt(route.params.niveau) );

</script>
