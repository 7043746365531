<template>
  <div class="content" :class="settingsCssClass">
    <HeaderActivite
        @toggle-settings="toggleSettings"
        @toggle-sound="toggleSound"
        @print="printAsPDF()"
    />
    <main
        class="activite repertoire-additif"
        :class="desSettingsCssClass"
    >
      <!-- Bouton header -->
      <div class="activite-header">
        <!-- Recommencer -->
        <ReplayBtn @click="replay()" />
        <!-- Démo -->
        <DemoBtn @click="demo()" />
        <!-- Saisie manuelle -->
        <ManualInputsBtn @click="saisieManuelle()" />
      </div>

      <h1>{{ title }}</h1>

      <div class="interaction half-size" :class="cssClassName">

        <ul class="niveaux">
          <li><a class="niveau courant" data-level="1" href="#">Niveau 1 : de 1 à 9</a></li>
          <li><a class="niveau" data-level="2" href="#">Niveau 2 : de 1 à 9 (complément)</a></li>
          <li><a class="niveau" data-level="3" href="#">Niveau 3 : de 10 à 50</a></li>
          <li><a class="niveau" data-level="4" href="#">Niveau 4 : de 10 à 50 (complément)</a></li>
        </ul>

        <div class="clear"></div>

        <div class="columns">
          <div class="column des-carres">
            <div>
              <!-- Dés -->
              <div class="addition-des-parent">
                <DePourOperations
                    no=1
                    :chiffre="refChiffre1"
                    :refForceDizaine="refForceDizaine"
                />
                <div class="operation">{{ operationActivite }}</div>
                <DePourOperations
                    no=2
                    :chiffre="refChiffre2"
                    :refForceDizaine="refForceDizaine"
                />
                <div class="operation">=</div>
                <DePourOperations
                    no=3
                    :chiffre="refChiffre3"
                    :refForceDizaine="refForceDizaine"
                />
              </div>
              <!-- Chiffres -->
              <div class="cartouche-nombres-parent">
                <CartoucheNombrePourOperations
                    no=1
                    :chiffre="refChiffre1"
                    :refForceDizaine="refForceDizaine"
                    :is-hidden="refMissing1"
                />
                <div class="operation">{{ operationActivite }}</div>
                <CartoucheNombrePourOperations
                    no=2
                    :chiffre="refChiffre2"
                    :refForceDizaine="refForceDizaine"
                    :is-hidden="refMissing2"
                />
                <div class="operation">=</div>
                <CartoucheNombrePourOperations
                    no=3
                    :chiffre="refChiffre3"
                    :refForceDizaine="refForceDizaine"
                    :is-hidden="refMissing3"
                />
              </div>
            </div>
          </div>
          <div class="column clavier">
            <ClavierChiffres :nb_touches_par_ligne="3" />
          </div>
        </div>

        <a href="#" id="afficher-des">
          <span class="icone show afficher-des"></span>
          Afficher/Masquer les dés
        </a>

        <!-- pour démo -->
        <div class="souris"></div>

      </div>
    </main>
    <Footer />
    <ManualInputsPopUp
        :slots=3
        :maxlength=2
        operation="+"
        @change="validationSaisieManuelle"
        @close="fermerSaisieManuelle"
    />
    <SettingsPanel :class="settingsCssClass" />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v1/HeaderActiviteV1";
  import Footer from "../../../components/v1/FooterActivite";
  import ManualInputsBtn from "../../../components/v1/Boutons/ManualInputsBtn"
  import ManualInputsPopUp from "../../../components/v1/ManualInputsPopUp"
  import SettingsPanel from "../../../components/v1/SettingsPanel"
  import ReplayBtn from "../../../components/v1/Boutons/ReplayBtn";
  import DemoBtn from "../../../components/v1/Boutons/DemoBtn";

  import {onMounted, ref} from "vue";
  import { useStore } from 'vuex';

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    getDizainesUnites,
    getSoundPath,
    playSound,
    playSequence,
    stopSequence,
    stopAllAnimations,
    stopAllSounds,
    getPDF,
    addSVGToPDF,
    addSVGKeyboardToPDF,
    getURLFromBackgroundImageStyle,
    emptyAjaxResponse,
    randomIntFromInterval
  } from "../../../js/utils";
  import ClavierChiffres from "../../../components/v1/Claviers/ClavierChiffres";
  import DePourOperations from "../../../components/v1/Chiffres/DePourOperations";
  import CartoucheNombrePourOperations from "../../../components/v1/Chiffres/CartoucheNombrePourOperations";

  const store = useStore();

  //
  // Le composant sert de base à plusieurs activités :
  // - Repertoire Additif
  // - Repertoire soustractif
  // - Complément à 10
  //
  // Via les propriétés :
  //

  const props = defineProps({
    operation: {
      type: String,
      required: false,
      default: "+"
    },
    complementA10: {
      type: Boolean,
      required: false,
      default: false
    }
  });


  // Addition ou Soustraction ?
  let operationActivite = ref(props.operation).value;
  let isComplementA10 = ref(props.complementA10).value;

  let title, cssClassName;
  if (isComplementA10) {
    title = "Complément à 10";
    cssClassName = "complement-a-10";
  } else if (operationActivite === "-") {
    title = "Répertoire soustractif";
    cssClassName = "";
  } else {
    title = "Répertoire additif";
    cssClassName = "";
  }

  // Selon niveau :
  var currentLevel = 1;
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 9;

  let activite;
  // let outils;
  let interaction;
  let souris;
  let settingsPanel;


  const overlapThreshold = "50%";
  let isDragging = false;
  let selectedDraggable;

  // Style des dés (forme, couleur)
  let desSettingsCssClass = store.getters.desSettingsCssClass + ' ' + cssClassName;

  //
  // Reactive values
  //

  let refChiffre1 = ref();
  let refChiffre2 = ref();
  let refChiffre3 = ref();

  let refMissing1 = ref();
  let refMissing2 = ref();
  let refMissing3 = ref();

  let refForceDizaine = ref();



  onMounted(() => {

    activite = $(".activite");
    // outils = $(".outils");
    interaction = $(".interaction");
    souris = $(".souris");
    settingsPanel = $(".settings-panel");


    //
    // Initializations
    //

    // Event delegation pattern :
    activite.on("click", function (e) {
      e.preventDefault();

      var target = $(e.target);
      var soundPath;
      var parent;

      if (target.is(".demo")) {
        demo(target);
      } else if (target.is(".replay")) {
        replay(target);
      } else if (target.is(".de-parent")) {
        // Chiffres quand unités seules

        // On joue le son :
        // . si le nombre n'est pas celui à renseigner ( pas de zone )
        // . si le nombre est correctement renseigné

        if (!target.is(".zone") || target.hasClass('correct')) {
          soundPath = getSoundPath_Chiffre(target.data('value'));
          playSound(soundPath);

        }
      } else if (target.is(".de")) {
        // Dés

        // On joue le son :
        // . si le dé n'est pas celui à renseigner en nombre ( pas de zone )
        // . si le dé est celui à trouver ET est correctement renseigné
        var parentDes = target.closest('.des');
        var parentDesNo = parentDes.data('no');
        var zone = $('.nombres[data-no=' + parentDesNo + '] .zone');

        if ((zone.length === 0) || (zone.hasClass('correct'))) {
          parent = target.closest('.de-parent');
          soundPath = getSoundPath_Chiffre(parent.data('value'));
          playSound(soundPath);
        }

      } else if (target.is(".dizaines") || target.is(".unites")) {
        // Chiffres quand dizaines et unités

        // On joue le son :
        // . si le nombre n'est pas celui à renseigner ( pas de zone )
        // . si le nombre est correctement renseigné ( unités et dizaines)

        var allZonesCorrectes = true;
        $(".zone").each(function (no, item) {
          allZonesCorrectes = allZonesCorrectes && $(item).hasClass('correct');
        });

        if (!target.is(".zone") || allZonesCorrectes) {
          parent = target.closest('.de-parent');
          soundPath = getSoundPath_Chiffre(parent.data('value'));
          playSound(soundPath);
        }
      } else if (target.is(".soundPlayer")) {
        var chiffre = $(".soundPlayer").data("value");
        soundPath = getSoundPath_Chiffre(chiffre);
        playSound(soundPath);
      } else if (target.is(".operation")) {
        var operation = $(target[0]).text();
        console.log(operation);
        var operationFilename;
        switch (operation) {
          case "-":
            operationFilename = "moins";
            break;
          case "x":
            operationFilename = "multiplié-par";
            break;
          case "/":
            operationFilename = "divisé-par";
            break;
          case "+":
            operationFilename = "plus";
            break;
          case "=":
            operationFilename = "égal";
            break;
        }

        soundPath = getSoundPath(operationFilename);
        playSound(soundPath);
      }
    });

    activite.on("mousedown", function (e) {
      e.preventDefault();
    });


    //
    // Niveaux
    //

    if (isComplementA10) {
      currentLevel = 2;
      currentMinimumNumber = 10;
      currentMaximumNumber = 10;
    }

    $('.niveau').on("click", function (e) {
      e.preventDefault();

      var niveau = $(e.target);

      // Activation du lien
      var niveaux = niveau.closest(".niveaux");
      var tousLesniveaux = niveaux.find(".niveau");
      tousLesniveaux.removeClass("courant");

      niveau.addClass("courant");

      // Définition du nombre maximum du tirage au sort
      var niveauNo = niveau.data("level");

      switch (niveauNo) {

        case 1:
          currentLevel = 1;
          currentMinimumNumber = 1;
          currentMaximumNumber = 9;
          break;

        case 2:
          currentLevel = 2;
          currentMinimumNumber = 1;
          currentMaximumNumber = 9;
          break;

        case 3:
          currentLevel = 3;
          currentMinimumNumber = 10;
          currentMaximumNumber = 50;
          break;

        case 4:
          currentLevel = 4;
          currentMinimumNumber = 10;
          currentMaximumNumber = 50;
      }

      initActivite();
    });

    $("#afficher-des").on('click', function (e) {
      e.preventDefault();
      var icone = $(this).find('.icone');
      if (icone.hasClass('show')) {
        icone.removeClass('show').addClass('hide');
        $('.addition-des-parent').css('opacity', 0).css('height', '50px');
      } else {
        icone.removeClass('hide').addClass('show');
        $('.addition-des-parent').css('opacity', 1).css('height', 'auto');
      }
    });


    //
    // Drag and Drop
    //

    var dropZones = $(".zone");

    Draggable.create( $(".draggable"), {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        $('.draggable').removeClass("dragged");

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.addClass("dragged");

        var nombre = draggableElement.data('value');
        var soundPath = getSoundPath_Chiffre(nombre);
        playSound(soundPath);

        selectedDraggable = draggableElement;
      },
      onDragStart: function() {
        // var draggableElement = $(pointEvent.target).closest('.draggable');
        // var nombre = draggableElement.find('.de').data('value');

        isDragging = true;
        selectedDraggable = null;

        dropZones = $(".zone");
        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        stopAllAnimations();

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        $('.draggable').removeClass("dragged");

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.removeClass("dragged");

        dropZones = $(".zone");
        var i = dropZones.length;
        var dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold))
          {
            var nombre_drag = draggableElement.attr('data-value');

            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, { x:0, y:0, delay:0.1 });

        isDragging = false;
      },
      onDrag: function() {
        var i = dropZones.length, dropZone;
        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* PopUps */

    $('.close-popup-btn a').on('click', function(e){
      e.preventDefault();
      $(e.target).closest('.popup').removeClass('open');
    });


    /* Première initialisation au chargement de la page */

    // initSettingsEvents();
    // restoreSettings();
    initActivite();

  });



  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres ) {

    interaction.css("display", "block");

    var missingZone, total, chiffre1, chiffre2, forceDizaine;

    if ((chiffres === undefined) || (chiffres.length < 3))
    {

      // Tirage au sort du total
      total = randomIntFromInterval( Math.floor(currentMaximumNumber/2), currentMaximumNumber);

      console.log( currentMinimumNumber, currentMaximumNumber, 'total =', total);

      // Cas particulier : Complément à 10
      if (isComplementA10) {
        total = 10;
      }

      // Tirage au sort du premier nombre
      chiffre1 = randomIntFromInterval(1, total - 1);

      // Déduction du second nombre
      chiffre2 = total - chiffre1;

      // Missing zone : complément (1 ou 2) sinon c'est le total (3) qui est manquant
      switch(currentLevel) {
        case 1:
        case 3:
          missingZone = 3; // Total
          break;

        case 2:
        case 4:
          missingZone = randomIntFromInterval(1, 2); // Complément
          break;
      }

      forceDizaine = currentLevel > 2;

    } else {

      // Saisie manuelle

      var saisie1 = chiffres[0];
      var saisie2 = chiffres[1];
      var saisie3 = chiffres[2];

      console.log(saisie1, saisie2, saisie3);

      if (saisie1 === null) {

        missingZone = 1;
        chiffre2 = saisie2;
        total = saisie3;

        chiffre1 = total - chiffre2;

      } else if (saisie2 === null) {

        missingZone = 2;
        chiffre1 = saisie1;
        total = saisie3;

        chiffre2 = total - chiffre1;

      } else {

        missingZone = 3;
        chiffre1 = saisie1;
        chiffre2 = saisie2;

        total = chiffre1 + chiffre2;

      }

      forceDizaine = total > 9;

      if (total < 10) {
        currentLevel = missingZone === 3 ? 1 : 2;
      } else {
        currentLevel = missingZone === 3 ? 3 : 4;
      }
    }

    if (operationActivite === '-') {
      const _total = total;
      total = chiffre1;
      chiffre1 = _total;
    }



    // On le mémorise pour la fonction de création de PDF
    $('.activite').data('missing', missingZone);

    console.log(chiffre1, '+', chiffre2, '=', total, "niveau", currentLevel, "missing", missingZone);

    initInterface();

    refChiffre1.value = chiffre1;
    refChiffre2.value = chiffre2;
    refChiffre3.value = total;

    refForceDizaine.value = forceDizaine;

    refMissing1.value = missingZone === 1;
    refMissing2.value = missingZone === 2;
    refMissing3.value = missingZone === 3;


    /*
    var htmlDes = '';

    htmlDes += getHtmlDe(chiffre1, 1, forceDizaine, missingZone === 1);
    htmlDes += '<div class="operation">' + operationActivite +'</div>';
    htmlDes += getHtmlDe(chiffre2, 2, forceDizaine, missingZone === 2);
    htmlDes += '<div class="operation">=</div>';
    htmlDes += getHtmlDe(total, 3, false, missingZone === 3);

    $('.addition-des-parent').html( htmlDes );

    var htmlNombre = '';
    htmlNombre += getHtmlCartoucheNombre(chiffre1, 1, forceDizaine, missingZone === 1);
    htmlNombre += '<div class="operation">' + operationActivite +'</div>';
    htmlNombre += getHtmlCartoucheNombre(chiffre2, 2, forceDizaine, missingZone === 2);
    htmlNombre += '<div class="operation">=</div>';
    htmlNombre += getHtmlCartoucheNombre(total, 3, forceDizaine, missingZone === 3);

    $('.cartouche-nombres-parent').html( htmlNombre );

    */


    var c1 = $("#chiffre1");
    var c2 = $("#chiffre2");
    var c3 = $("#chiffre3");

    var d1 = $("#de1");
    var d2 = $("#de2");
    var d3 = $("#de3");

    var dw = 4;

    setTimeout( function() {

      c1.width( d1.width() );
      c2.width( d2.width() );
      c3.width( d3.width() );

      $(".dizaines", c1).width( $(".dizaines", d1).width() - dw );
      $(".unites", c1).width( $(".unites", d1).width() - dw );

      $(".dizaines", c2).width( $(".dizaines", d2).width() - dw );
      $(".unites", c2).width( $(".unites", d2).width() - dw);

      $(".dizaines", c3).width( $(".dizaines", d3).width() - dw );
      $(".unites", c3).width( $(".unites", d3).width() - dw );

    }, 100);


    var dropZones = $(".zone");
    dropZones.on('click', function() {
      if (! isDragging && selectedDraggable )
      {
        $('.draggable').removeClass("dragged");

        var draggableElement = selectedDraggable;
        var nombre = draggableElement.data('value');

        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre);
      }
    });

  }

  function checkZone( dropZone, nombre ) {

    dropZone.removeClass("hilite");
    dropZone.text(nombre);

    var dizaines_a_trouver, unites_a_trouver, dizaines_proposees, unites_proposees, dizainesOk, unitesOk;
    var correctionUnites = $('.correction-unites');
    var correctionDizaines = $('.correction-dizaines');

    var unitesSeules = $('.unites-seules .zone');
    if (unitesSeules.length === 1)
    {
      unites_a_trouver    = parseInt( unitesSeules.attr('data-value'));
      unites_proposees    = parseInt( unitesSeules.text());

      unitesOk = unites_a_trouver === unites_proposees;

      if ( unitesSeules.text() === '')
      {
        unitesSeules.removeClass("hilite").removeClass("correct").removeClass("incorrect");
        correctionUnites.removeClass("incorrect").removeClass("correct").addClass("incorrect");
      }
      else if (unitesOk)
      {
        unitesSeules.removeClass("hilite").removeClass("incorrect").addClass("correct");
        correctionUnites.removeClass("incorrect").removeClass("correct").addClass("correct");
      }
      else
      {
        unitesSeules.removeClass("hilite").removeClass("correct").addClass("incorrect");
        correctionUnites.removeClass("incorrect").removeClass("correct").addClass("incorrect");
      }
    }
    else
    {
      var dizainesElement = $('.nombres-dizaines-unites .dizaines.zone');
      var unitesElement = $('.nombres-dizaines-unites .unites.zone');

      dizaines_a_trouver  = parseInt( dizainesElement.data('value'));
      unites_a_trouver    = parseInt( unitesElement.data('value'));

      dizaines_proposees  = parseInt( dizainesElement.text());
      unites_proposees    = parseInt( unitesElement.text());

      dizainesOk  = dizaines_a_trouver === dizaines_proposees;
      unitesOk    = unites_a_trouver === unites_proposees;

      if ( dropZone.is('.dizaines') )
      {
        if ( dizainesElement.text() === '')
        {
          dizainesElement.removeClass("hilite").removeClass("correct").removeClass("incorrect");
          correctionDizaines.removeClass("incorrect").removeClass("correct").addClass("incorrect");
        }
        else if (dizainesOk)
        {
          dizainesElement.removeClass("hilite").removeClass("incorrect").addClass("correct");
          correctionDizaines.removeClass("incorrect").removeClass("correct").addClass("correct");
        }
        else
        {
          dizainesElement.removeClass("hilite").removeClass("correct").addClass("incorrect");
          correctionDizaines.removeClass("incorrect").removeClass("correct").addClass("incorrect");
        }
      }
      else if ( dropZone.is('.unites') )
      {
        if ( unitesElement.text() === '')
        {
          unitesElement.removeClass("hilite").removeClass("correct").removeClass("incorrect");
          correctionUnites.removeClass("incorrect").removeClass("correct").addClass("incorrect");
        }
        else if (unitesOk)
        {
          unitesElement.removeClass("hilite").removeClass("incorrect").addClass("correct");
          correctionUnites.removeClass("incorrect").removeClass("correct").addClass("correct");
        }
        else
        {
          unitesElement.removeClass("hilite").removeClass("correct").addClass("incorrect");
          correctionUnites.removeClass("incorrect").removeClass("correct").addClass("incorrect");
        }
      }

      if (dizainesOk && unitesOk) {
        var soundPath = getSoundPath_Chiffre( dizaines_a_trouver * 10 + unites_a_trouver );
        playSound(soundPath);
      }
    }
  }


  //
  // 3. Démo Activité
  //

  var sequenceActions;

  function demo() {

    stopDemo();
    resetActivite();

    souris.css("display", "block");
    souris.css("opacity", 1);

    var positionInteraction = interaction.offset();
    var zindex = 10;

    var dizaines_a_trouver, unites_a_trouver, unitesElement, dizainesElement;

    unitesElement = $('.unites-seules .zone');
    if (unitesElement.length === 1)
    {
      unites_a_trouver    = parseInt( unitesElement.data('value'));
    }
    else
    {
      dizainesElement = $('.nombres-dizaines-unites .dizaines.zone');
      unitesElement = $('.nombres-dizaines-unites .unites.zone');

      dizaines_a_trouver = parseInt(dizainesElement.data('value'));
      unites_a_trouver = parseInt(unitesElement.data('value'));
    }


    var nombres = [];

    // Dizaines
    if (! isNaN(dizaines_a_trouver)) {

      if ( (nombres.length === 0) && (dizaines_a_trouver === 0) || (dizaines_a_trouver === '')) {
        // Seulement des unités
      } else {

        nombres.push(
            {
              nombre: dizaines_a_trouver,
              zone: dizainesElement,
              draggable: $('.draggable[data-value=' + dizaines_a_trouver + ']'),
              text: dizainesElement
            }
        );
      }
    }

    // Unités
    if (! isNaN(unites_a_trouver)) {
      nombres.push(
          {
            nombre: unites_a_trouver,
            zone: unitesElement,
            draggable: $('.draggable[data-value=' + unites_a_trouver + ']'),
            text: unitesElement
          }
      );
    }


    sequenceActions = [];

    console.log('------- demo --------');

    var i, n = nombres.length;
    var nombreObj, nombre, draggableElement, draggablePosition, zoneNombreElement, textNombreElement, zonePosition;
    var dx, dy, sourisLeft, sourisTop, parentDraggableOffset, reparentedSourisLeft;

    var sourisDx = 20;
    var sourisDy = 20;

    for(i=0;i<n;i++) {

      nombreObj = nombres[i];
      nombre = nombreObj.nombre;

      // Nombre à déplacer
      draggableElement = nombreObj.draggable;

      // Position de départ
      draggablePosition = draggableElement.offset();

      // Zone de dépôt du nombre
      zoneNombreElement = nombreObj.zone;

      // Element où apparaitra le nombre suite au déplacement
      textNombreElement = nombreObj.text;

      // Position d'arrivée du dé
      zonePosition = zoneNombreElement.offset();

      dx = zonePosition.left - draggablePosition.left;
      dy = zonePosition.top  - draggablePosition.top;

      console.log(i, nombre, draggablePosition, zonePosition, "dx=", dx, "dy=", dy);

      sourisLeft = draggablePosition.left - positionInteraction.left + sourisDx;
      sourisTop = draggablePosition.top - positionInteraction.top + sourisDy;

      parentDraggableOffset = draggableElement.offset();
      reparentedSourisLeft = sourisLeft - parentDraggableOffset.left + interaction.offset().left;

      sequenceActions.push( { action : "moveElement", param : { element:souris , left: sourisLeft, top: sourisTop, duration: 0 } } );
      sequenceActions.push( { action : "fadeInElement", param : { element:souris } } );
      sequenceActions.push( { action : "addClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:draggableElement, left: reparentedSourisLeft } } );
      sequenceActions.push( { action : "moveElement", param : { element:souris , left: sourisDx, top: sourisDy, duration: 0 } } );
      sequenceActions.push( { action : "playSound", param : getSoundPath_Chiffre(nombre) } );
      sequenceActions.push( { action : "transformElement", param : { element:draggableElement , x: dx, y: dy, zindex:zindex ++ } } );
      sequenceActions.push( { action : "fadeOutElement", param : { element:souris } } );
      sequenceActions.push( { action : "addClass", param : { element:zoneNombreElement, className:"correct" } } );
      sequenceActions.push( { action : "removeClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:interaction } } );
      sequenceActions.push( { action : "transformElement", param : { element:draggableElement , x: 0, y: 0, duration: 0, zindex:zindex ++ } } );
      sequenceActions.push( { action : "setText", param : { element:textNombreElement, text: nombre } } );
      sequenceActions.push( { action : "wait", param : 2 } );
    }

    sequenceActions.push( { action : "callback", param : resetActivite } );

    playSequence( sequenceActions );
  }

  function stopDemo() {
    stopSequence(sequenceActions);
    stopAllAnimations();
    stopAllSounds();
  }


  //
  // 4. Rejouer Activité
  //

  function replay() {

    stopDemo();
    resetActivite();

    activite.css("display", "block");
    interaction.css("display", "block");
    settingsPanel.css("display", "none");

  }

  function resetActivite( chiffres ) {

    console.log('---------- resetActivite')

    souris.css("display", "none");

    const zone = $('.zone');
    zone.removeClass("hilite ");
    zone.removeClass("incorrect");
    zone.removeClass("correct");
    zone.text('');

    const correction = $('.correction');
    correction.removeClass("incorrect");
    correction.removeClass("correct");


    $(".draggable").removeClass("dragged");

    initActivite( chiffres );
  }

  //
  // 5. Saisie manuelle
  //

  function saisieManuelle() {
    $("#popup-saisie-manuelle input[type=text]:not([disabled])").val('');
    $("#popup-saisie-manuelle").removeClass('open').addClass('open');
  }

  function validationSaisieManuelle(chiffres_saisis) {

    var missingInputs = 0;
    var allValid = true;
    var chiffresArray = [];
    let chiffre, saisie;

    for(let i=0; i<chiffres_saisis.length; i++) {

      saisie = chiffres_saisis[i];

      if (saisie.length === 0)
      {
        // Nombre de trous parmi les 3 chiffres à entrer dans le formulaire
        missingInputs++;

        chiffresArray.push( null );
      }
      else
      {
        chiffre = parseInt(saisie);

        if ( ! isNaN(chiffre))
        {
          chiffresArray.push(chiffre);
        }
        else
        {
          allValid = false;
        }
      }
    }

    // On ne doit avoir qu'un trou, et les autres doivent être des nombres valides
    if ((missingInputs === 1) && allValid)
    {
      resetActivite( chiffresArray );
      fermerSaisieManuelle();
    }
  }

  function fermerSaisieManuelle() {
    $("#popup-saisie-manuelle").removeClass('open');
  }


  //
  // 6. PDF
  //

  function printAsPDF() {

    var doc = getPDF('landscape');
    doc.text(5, 20, isComplementA10 ? 'Complément à 10' : 'Répertoire additif');

    var topCadre = 30;

    var desDizaines = $('.de', '.dizaines');
    var promiseDizaines = desDizaines.length === 0 ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( desDizaines.first() ) });

    var unitesParent = $('.addition-des-parent .unites .de-parent');
    var unite1 = parseInt($(unitesParent[0]).attr('data-value'));
    var unite2 = parseInt($(unitesParent[1]).attr('data-value'));
    var unite3 = parseInt($(unitesParent[2]).attr('data-value'));
    // console.log('unites', unite1, unite2, unite3);

    var unites = $('.addition-des-parent .unites .de-parent .de');
    var promiseUnite1 = unite1 === 0 ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( $(unites[0]) ) });
    var promiseUnite2 = unite2 === 0 ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( $(unites[1]) ) });
    var promiseUnite3 = unite3 === 0 ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( $(unites[2]) ) });

    var chiffres = $('.cartouche-nombres-parent .de-parent');
    var nombre1 = parseInt($(chiffres[0]).attr('data-value'));
    var nombre2 = parseInt($(chiffres[1]).attr('data-value'));
    var nombre3 = parseInt($(chiffres[2]).attr('data-value'));
    var missingZone = $('.activite').data('missing');

    console.log('nombres', nombre1, nombre2, nombre3, 'missing', missingZone);

    $.when(
        $.ajax({ url: "images/svg/cadre_repertoire_additif_unites.svg" }),
        $.ajax({ url: "images/svg/cadre_repertoire_additif_dizaines_unites.svg" }),
        $.ajax({ url: "images/svg/cadre_repertoire_additif_dizaines_unites_vide.svg" }),
        $.ajax({ url: "images/svg/cadre_chiffre_clavier.svg" }),
        promiseDizaines,
        promiseUnite1,
        promiseUnite2,
        promiseUnite3

    )
        .done(function( jqXHRCadreUnites, jqXHRCadreDizainesUnites, jqXHRTrouDizainesUnites, jqXHRCadreClavier, jqXHRDizaine, jqXHRUnite1, jqXHRUnite2, jqXHRUnite3){

          var cadreClavier = jqXHRCadreClavier[0];

          var cadreUnitesHaut = jqXHRCadreUnites[0];
          var cadreDizainesUnitesHaut = jqXHRCadreDizainesUnites[0];

          var cadreUnitesBas = cadreUnitesHaut;
          var cadreUnitesVide = cadreUnitesHaut;
          var cadreDizainesUnitesVide = jqXHRTrouDizainesUnites[0];

          var deUnite1 = jqXHRUnite1[0];
          var deUnite2 = jqXHRUnite2[0];
          var deUnite3 = jqXHRUnite3[0];
          var deDizaine = jqXHRDizaine[0];

          // Clavier
          addSVGKeyboardToPDF(doc, cadreClavier, 230, topCadre, 3);

          var getBlockHeight = function( nbDizaines ) {
            return Math.max(nbDizaines * 65, 108);
          };

          var fillBlock = function(nombre, cadreUnites, cadreDizainesUnites, deDizaine, deUnite, x, y, heightMax) {

            var dizainesUnites = getDizainesUnites(nombre);

            var desLarges =  $('.activite').hasClass('de-bille');

            var offsetXDe = desLarges ?  2 : 4;
            var offsetYDe = desLarges ?  8 : 4;
            var scaleDe;

            var offsetXUnite = 11;
            var offsetYUnite = 17;
            var cadreSVGObject;
            var marginTop;
            var scaleSVGToPDF = 0.25; // Coordonnées SVG --> PDF

            if (nombre < 10) {

              // 1. Affichage d'une unité seule

              scaleDe = desLarges > 0 ? 0.30 : 1.0;

              // Cadre

              cadreSVGObject = $( cadreUnites.childNodes[0] ).clone();
              cadreSVGObject.attr('height', heightMax);
              cadreSVGObject.attr('viewBox', [0, 0, 108, heightMax].join(" "));
              cadreSVGObject.find('g').children('rect').attr('height', heightMax);  // fond blanc
              cadreSVGObject.children('rect').attr('height', heightMax - 2);        // bord

              addSVGToPDF( doc, cadreSVGObject, x, y, "cadre_unites_haut");

              if (deUnite instanceof XMLDocument)
              {
                // 1.1 Affichage d'une unité seule : dé

                // Dé Unité
                marginTop = (heightMax - getBlockHeight(1)) * 0.5 * scaleSVGToPDF;
                addSVGToPDF( doc, deUnite, x + offsetXDe, y + offsetYDe + marginTop, "unite" + nombre, false, scaleDe);
              }
              else if ( ! isNaN(deUnite))
              {
                // 1.1 Affichage d'une unité seule : chiffre

                // Chiffre
                doc.setTextColor('#3B8FF2');
                doc.text( x + offsetXUnite, y + offsetYUnite, String(deUnite) );
              }

              return x + 50;

            } else {

              // 2. Affichage d'une dizaine et unité

              if (deDizaine instanceof XMLDocument)
              {
                // 2.1 Affichage d'une dizaine et unité : dés

                // Cadre

                cadreSVGObject = $( cadreDizainesUnites.childNodes[0] ).clone();
                cadreSVGObject.attr('height', heightMax);
                cadreSVGObject.attr('viewBox', [0, 0, 216, heightMax].join(" "));
                cadreSVGObject.find('g').children('rect').attr('height', heightMax); // fond blanc
                cadreSVGObject.children('rect').attr('height', heightMax -2);        // bord
                cadreSVGObject.children('line').attr('y2', heightMax - 2);           // ligne verticale

                addSVGToPDF( doc, cadreSVGObject, x, y, "cadre_dizaines_unites");

                // Dés Dizaines

                marginTop = (heightMax - getBlockHeight(dizainesUnites.dizaines)) * 0.5 * scaleSVGToPDF;

                scaleDe = desLarges ? 0.30 : 0.6;
                offsetXDe = desLarges ?  4 : 4;
                offsetYDe = desLarges ?  6 : 4;

                for(var i=0; i<dizainesUnites.dizaines; i++) {
                  addSVGToPDF( doc, deDizaine, x + offsetXDe - 2, y + offsetYDe + 14 * i + marginTop, "dizaine" + i, false, scaleDe);
                }

                // Dé Unité
                if (dizainesUnites.unites > 0) {
                  marginTop = (heightMax - getBlockHeight(1)) * 0.5 * scaleSVGToPDF;

                  scaleDe = desLarges ? 0.30 : 1.0;
                  offsetXDe = desLarges ?  2 : 4;
                  offsetYDe = desLarges ?  6 : 4;

                  addSVGToPDF( doc, deUnite, x + offsetXDe + 27, y + offsetYDe + marginTop, "unite" + nombre, false, scaleDe);
                }
              }
              else if ( ! isNaN(deUnite))
              {
                // 2.2 Affichage d'une dizaine et unité : chiffres

                // Cadre double largeur, affichant le nombre

                cadreSVGObject = $( cadreUnites.childNodes[0] ).clone();
                cadreSVGObject.attr('width', 216);
                cadreSVGObject.attr('viewBox', [0, 0, 216, 108].join(" "));
                cadreSVGObject.find('g').children('rect').attr('width', 216); // fond blanc
                cadreSVGObject.children('rect').attr('width', 214);          // bord
                addSVGToPDF( doc, cadreSVGObject, x, y, "cadre_unites_bas");

                // Chiffre
                var marginLeft = (108 - 30) * 0.5 * scaleSVGToPDF;
                var ecartUnites = 30 * scaleSVGToPDF;
                var dizaines = Math.floor(nombre/10);
                var unites = nombre - dizaines * 10;

                doc.setTextColor('#FF3F33');
                doc.text( x + offsetXUnite + marginLeft, y + offsetYUnite, String(dizaines) );

                doc.setTextColor('#3B8FF2');
                doc.text( x + offsetXUnite + marginLeft + ecartUnites, y + offsetYUnite, String(unites) );
              }

              return x + 74;
            }
          };

          var fillEmptyBlock = function(nombre, cadreUnitesVide, cadreDizainesUnitesVide, x, y, height) {

            console.log('fillEmptyBlock', height);

            if (nombre < 10) {

              // Cadre
              addSVGToPDF( doc, cadreUnitesVide, x, y, "cadre_unites_vide");

              return x + 50;

            } else {

              // Cadre
              addSVGToPDF( doc, cadreDizainesUnitesVide, x, y, "cadre_dizaines_unites_vide");

              return x + 74;
            }

          };

          doc.setFontSize(30);

          var nextX;
          var offsetSignsX = 14;


          var dizainesUnites1 = getDizainesUnites(nombre1);
          var dizainesUnites2 = getDizainesUnites(nombre2);
          var dizainesUnites3 = getDizainesUnites(nombre3);

          var dizainesMax = Math.max( dizainesUnites1.dizaines, dizainesUnites2.dizaines, dizainesUnites3.dizaines );
          var blockHeight = getBlockHeight(dizainesMax);

          var scaleSVGToPDF = 0.25;
          var offsetSigneY = blockHeight * 0.5 * scaleSVGToPDF + 3;


          // 1. Ligne des dés

          nextX = fillBlock(nombre1, cadreUnitesHaut, cadreDizainesUnitesHaut, deDizaine, deUnite1, 10, topCadre, blockHeight);
          doc.setTextColor('#000000');
          doc.text( nextX - offsetSignsX, topCadre + offsetSigneY, operationActivite );
          nextX = fillBlock(nombre2, cadreUnitesHaut, cadreDizainesUnitesHaut, deDizaine, deUnite2, nextX, topCadre, blockHeight);
          doc.setTextColor('#000000');
          doc.text( nextX - offsetSignsX, topCadre + offsetSigneY, "=" );
          fillBlock(nombre3, cadreUnitesHaut, cadreDizainesUnitesHaut, deDizaine, deUnite3, nextX, topCadre, blockHeight);

          // 2. Ligne des chiffres

          var offsetY = (blockHeight + 10) * scaleSVGToPDF;
          var chiffreBlockHeight = 108;

          if (missingZone === 1) {
            nextX = fillEmptyBlock(nombre1, cadreUnitesVide, cadreDizainesUnitesVide, 10, topCadre + offsetY, chiffreBlockHeight);
          } else {
            nextX = fillBlock(nombre1, cadreUnitesBas, cadreUnitesBas, dizainesUnites1.dizaines, dizainesUnites1.unites, 10, topCadre + offsetY, chiffreBlockHeight);
          }

          // Signe +
          doc.setTextColor('#000000');
          doc.text( nextX - offsetSignsX, topCadre + 15 + offsetY, operationActivite );

          if (missingZone === 2) {
            nextX = fillEmptyBlock(nombre2, cadreUnitesVide, cadreDizainesUnitesVide, nextX, topCadre + offsetY, chiffreBlockHeight);
          } else {
            nextX = fillBlock(nombre2, cadreUnitesBas, cadreUnitesBas, dizainesUnites2.dizaines, dizainesUnites2.unites, nextX, topCadre + offsetY, chiffreBlockHeight);
          }

          // Signe =
          doc.setTextColor('#000000');
          doc.text( nextX - offsetSignsX, topCadre + 15 + offsetY, "=" );

          if (missingZone === 3) {
            nextX = fillEmptyBlock(nombre3, cadreUnitesVide, cadreDizainesUnitesVide, nextX, topCadre + offsetY, chiffreBlockHeight);
          } else {
            fillBlock(nombre3, cadreUnitesBas, cadreUnitesBas, dizainesUnites3.dizaines, dizainesUnites3.unites, nextX, topCadre + offsetY, chiffreBlockHeight);
          }

          // Footer
          doc.setFontSize(14);
          doc.setTextColor('#CCCCCC');
          doc.text(5, 200, "2019 © Cognitice");

          doc.save(isComplementA10 ? 'Complement_a_10' : 'repertoire_additif.pdf');

        })
        .fail(function(){
        });

  }

  function initInterface() {

    if (! isComplementA10)
    {
      $('.interaction').removeClass('avec-dizaines');

      switch(currentLevel) {

        case 1:
        case 2:
          break;

        case 3:
        case 4:
          $('.interaction').addClass('avec-dizaines');
          break;
      }
    }
    else
    {
      $('.interaction').addClass('avec-dizaines');
    }
  }


  //
  // 7. Settings
  //

  let settingsCssClass = ref('');

  const toggleSettings = function(bool) {
    if (bool) {
      stopDemo();
      settingsCssClass.value = "settings-opened";
    } else {
      replay();
      settingsCssClass.value = "";
    }

  };

  //
  // 8. Sound
  //

  const toggleSound = function(bool) {
    console.log(bool);
  };


</script>

<style lang="scss">

  .repertoire-additif {

    .columns {
      margin-top: 20px;
    }

    .column.des-carres {
      width: calc( 100% - 260px );
    }

    .column.clavier {
      width:260px;
    }

    ul.clavier-chiffres {
      margin-top: 10px;
    }

    .souris {
      clear: both;
    }

    .de-parent {
      box-shadow: none;
      margin: 0;
      border-radius: 0;
    }

    .addition-des-parent {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .addition-des-parent > div {
      margin: 10px 20px;
      font-size: 60px;
      text-align: center;
      box-sizing: border-box;
    }

    .addition-des-parent > .unites > .des {
      border: 1px solid #0B154B;
    }

    .avec-dizaines .addition-des-parent > div {
      margin: 5px 5px;
    }

    &.complement-a-10 ul.niveaux {
      display: none;
      pointer-events: none;
    }

    &.complement-a-10 .avec-dizaines .addition-des-parent > div:not(.des-dizaines-unites) {
      margin: 5px 16px;
    }

    .nombres-dizaines-unites {
      overflow: hidden;
    }

    .des {
      border: 1px solid #0B154B;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 15px 15px 15px;
    }

    .des-dizaines-unites,
    .avec-dizaines .des,
    .avec-dizaines .des-dizaines-unites .des {
      box-shadow: none;
      border: none;
    }

    .avec-dizaines .des-dizaines-unites {
      display: flex;
      border: 1px solid #0B154B;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }

    .half-size .de-parent {
      width:76px;
      height:76px;
    }

    .half-size .de-parent.double {
      width:76px;
    }

    .des-dizaines-unites {
      border: 1px solid rgba(255, 255, 255, 1.0);
    }

    .des-dizaines-unites > div {
      padding: 0 5px;
      position: relative;
      display: flex;
    }

    .des-dizaines-unites .dizaines {
      border-right: 1px solid rgba(255, 255, 255, 1.0);
    }

    .avec-dizaines .des-dizaines-unites .dizaines {
      border-right: 1px solid rgba(0, 0, 0, 1.0);
    }

    .des-dizaines-unites .dizaines.pas-de-dizaines {
      display: none;
    }

    .des-dizaines-unites .des {
      margin: auto;
    }

    .des-dizaines-unites .de-parent {
      margin-top: 4px;
    }

    .unites .de-parent,
    .dizaines .de-parent {
      background: transparent;
    }

    .unites {
      background-color: rgba(59, 143, 242, 0.2);
      border-radius: 12px;
    }

    .des-dizaines-unites .unites {
      border-radius: 0 12px 12px 0;
    }

    .dizaines {
      background-color: rgba(255, 63, 51, 0.2);
      border-radius: 12px 0 0 12px;
    }


    /* iPad */

    @media screen and (max-width: 1080px ) {

      .des {
        padding: 15px 10px;
      }

      .half-size .de-parent {
        width:50px;
        height:50px;
      }

      .half-size .de-parent.double {
        width:70px;
        height: 35px;
      }

      .cartouche-nombres-parent .de-parent {
        width:70px;
        height:80px;
      }

      .cartouche-nombres-parent .unites-seules {
        margin: 25px 20px 25px 20px;
      }

    }

  }

</style>
