import { createStore } from 'vuex'
import {getActivites, getJeux, getOutils, getParcours} from "../js/activites_v2";

export default createStore({
  state: {
    settings : {
      // v1
      forme_des: "de-classique",
      couleur_des: "de-fond-bleu",

      // v2
      forme_des_v2: "en-couleurs",  // noir-et-blanc
      separateur_milliers: true,
      son_general: true,
    },
    activites: getActivites(),
    jeux: getJeux(),
    outils: getOutils(),
    parcours: getParcours(),

    playlist: {
      routes: [],
      history: []
    }
  },
  mutations: {
    setSettings(state, settings) {
      state.settings.forme_des = settings.forme_des;
      state.settings.couleur_des = settings.couleur_des;
    },
    setSettingsV2_FormeDes(state, settings) {
      state.settings.forme_des_v2 = settings.value;
    },
    setSettingsV2_SeparateurMilliers(state, settings) {
      state.settings.separateur_milliers = settings.value;
    },
    setSettingsV2_GeneralSoundSetting(state, settings) {
      state.settings.son_general = settings.value;
    },
    updateActivites(state, settings) {
      state.activites = settings;
    },
    updateJeux(state, settings) {
      state.jeux = settings;
    },
    savePlaylist(state, routes) {
      state.playlist.routes = routes;
      state.playlist.history = [];
      state.playlist.username = '';
    },
    savePlaylistUsername(state, username) {
      state.playlist.username = username;
    },
    addToPlaylistHistory(state, activityStats) {
      state.playlist.history.push(activityStats);
    }
  },
  actions: {
    saveSettings(context, settings) {
      context.commit('setSettings', settings);
    },
    saveSettingsV2_FormeDes(context, settings) {
      context.commit('setSettingsV2_FormeDes', settings);
    },
    saveSettingsV2_SeparateurMilliers(context, settings) {
      context.commit('setSettingsV2_SeparateurMilliers', settings);
    },
    saveGeneralSoundSetting(context, settings) {
      context.commit('setSettingsV2_GeneralSoundSetting', settings);
    },
    saveSoundSettingActivite(context, soundSetting) {
      const activiteSettings = context.getters.activiteFromPath(soundSetting.path);
      activiteSettings.sound_active = soundSetting.value;
      context.commit('updateActivites', context.state.activites);
    },
    savePrintSettingActivite(context, printSetting) {
      const activiteSettings = context.getters.activiteFromPath(printSetting.path);
      activiteSettings.print_active = printSetting.value;
      context.commit('updateActivites', context.state.activites);
    },
    saveSoundSettingJeu(context, soundSetting) {
      const jeuSettings = context.getters.jeuFromPath(soundSetting.path);
      jeuSettings.sound_active = soundSetting.value;
      context.commit('updateJeux', context.state.jeux);
    },
    savePlaylist(context, routes) {
      context.commit('savePlaylist', routes);
    },
    savePlaylistUsername(context, username) {
      context.commit('savePlaylistUsername', username);
    },
    addToPlaylistHistory(context, activityStats) {
      context.commit('addToPlaylistHistory', activityStats);
    }
  },
  getters : {
    desSettingsCssClass(state) {
      return state.settings.forme_des + ' ' + state.settings.couleur_des;
    },

    // Son activité ( valeur pour l'activité pouvant être surdéterminée par le son général )
    isSoundActive: (state) => (path) => {
      let index, activite;
      for(index in state.activites) {
        activite = state.activites[index];
        if (activite.path === path) {
          return activite.sound_active && state.settings.son_general;
        }
      }
      return state.settings.son_general;
    },

    // activités
    activiteFromPath: (state) => (path) => {
      let index, activite;
      for(index in state.activites) {
        activite = state.activites[index];
        if (activite.path === path) {
          return activite;
        }
      }
      return false;
    },
    activiteFromName: (state) => (name) => {
      let index, activite;
      for(index in state.activites) {
        activite = state.activites[index];
        // Accepte le nom exact de l'activité, ou le nom suivi de "custom"
        if (name.indexOf(activite.name) === 0) {
          return activite;
        }
      }
      return false;
    },
    activiteNombreNiveau: (state) => (name) => {
      let index, activite;
      for(index in state.activites) {
        activite = state.activites[index];
        if (activite.name === name) {
          return activite.niveaux;
        }
      }
      return false;
    },

    // jeux
    jeuFromPath: (state) => (path) => {
      let index, jeu;
      for(index in state.jeux) {
        jeu = state.jeux[index];
        if (jeu.path === path) {
          return jeu;
        }
      }
      return false;
    },

    // outils audrey
    outilFromPath: (state) => (path) => {
      let index, outil;
      console.log("OUTILS ", state.outils);
      for(index in state.outils) {
        outil = state.outils[index];
        if (outil.path === path) {
          return outil;
        }
      }
      return false;
    },
    outilFromName: (state) => (name) => {
      let index, outil;
      for(index in state.outils) {
        outil = state.outils[index];
        if (outil.name === name) {
          return outil;
        }
      }
      return false;
    },
    outilNombreNiveau: (state) => (name) => {
      let index, outil;
      for(index in state.outils) {
        outil = state.outils[index];
        if (outil.name === name) {
          return outil.niveaux;
        }
      }
      return false;
    },
    nextPlaylist: (state) => (route) => {
      if (route.query && route.query.playlist) {
        const routes = state.playlist.routes;
        if (Array.isArray(routes) && routes.length) {
          const playListStep = parseInt(route.query.playlist);
          const playlistLength = routes.length;
          if (playListStep < playlistLength - 1) {
            return routes[playListStep + 1];
          }
        }
      }
      return false;
    },
    getPlaylistUsername: (state) => () => {
      return state.playlist.username;
    },
    getPlaylistHistoryForStep: (state) => (playlistStepNo) => {
      const history = state.playlist.history;
      const historyForStep = [];
      if (Array.isArray(history) && history.length) {
        let i, n = history.length, activityStats;
        for(i=0; i<n; i++) {
          activityStats = history[i];
          if (activityStats.playlistStepNo === playlistStepNo) {
            historyForStep.push(activityStats);
          }
        }
      }
      return historyForStep;
    },
    getPlaylistLength: (state) => (route) => {
      if (route.query && route.query.playlist) {
        const routes = state.playlist.routes;
        if (Array.isArray(routes) && routes.length) {
          return routes.length;
        }
      }
      return 0;
    },
    getPlaylistPosition: (state) => (route) => {
      if (route.query && route.query.playlist) {
        const routes = state.playlist.routes;
        if (Array.isArray(routes) && routes.length) {
          const playListStep = parseInt(route.query.playlist);
          const playlistLength = routes.length;
          if (playListStep < playlistLength - 1) {
            return playListStep;
          }
        }
      }
      return 0;
    },

    // settings
    sonGeneral: (state) => () => {
      return state.settings.son_general;
    },
    formeDesV2: (state) => () => {
      return state.settings.forme_des_v2;
    },
    separateurMilliers: (state) => () => {
      return state.settings.separateur_milliers;
    },

  },
  modules: {
  }
})
