<template>
  <router-link to="/" class="svg-icon home-link hidden" />
</template>

<script>
export default {
  name: 'BurgerBtn',
}
</script>

<style scoped lang="scss">

  a.svg-icon.home-link {
    background-image: url(../../../assets/images/svg/icones/home.svg);
  }

</style>
