<template>

  <div class="complement-a" :class="cssClassName">

    <div class="complement-contents">

      <!-- Dés -->
      <div class="cards-parent components-parent not-draggable">
        <Card
            :chiffre="refChiffre1"
            :code="code"
            :is-hidden="refIsMissing1 && !refMissingIsFound"
            :has-show-hidden-toggle="withValidation"
            :css-class="formeDes"
            :has-separator="separateurMilliers"
            :with-sound="withSound"
        />
        <div class="operation">
          <SignePlus />
        </div>
        <Card
            :chiffre="refChiffre2"
            :code="code"
            :is-hidden="refIsMissing2 && !refMissingIsFound"
            :has-show-hidden-toggle="withValidation"
            :css-class="formeDes"
            :has-separator="separateurMilliers"
            :with-sound="withSound"
        />
        <div class="operation egal">
          <SigneEgal />
        </div>
        <div class="cards-dizaines" :class="refChiffre3DizainesCssClass">
          <CardDeColoriable v-for="(item, index) in refChiffre3Dizaines" :key="item"
                :background-color="getBackgroundColors(index)"
                :colors="getDotColors(index)"
                :class="refChiffre3DesDizainesCssClass(item)"
                :css-class="formeDes"
                :has-separator="separateurMilliers"
          />
        </div>
      </div>

      <!-- Chiffres -->
      <div class="slots-parent components-parent">
        <Slots
            ref="slots1"
            data-slots="1"
            class="width-106 vertical-offset-for-flex"
            :chiffre="refChiffre1"
            :is-hidden="refIsMissing1"
            :has-validation="refIsMissing1 && withValidation"
            :with-sound="withSound"
            @change="slotChanged"
        />
        <div class="operation">
          <SignePlus />
        </div>
        <Slots
            ref="slots2"
            data-slots="2"
            class="width-106 vertical-offset-for-flex"
            :chiffre="refChiffre2"
            :is-hidden="refIsMissing2"
            :has-validation="refIsMissing2 && withValidation"
            :with-sound="withSound"
            @change="slotChanged"
        />
        <div class="operation egal">
          <SigneEgal />
        </div>
        <div class="slots-chiffre3-parent">
          <Slots
              ref="slots3"
              data-slots="3"
              class="slots-chiffre3 width-106 vertical-offset-for-flex"
              :chiffre="refChiffre3"
              :has-validation="refIsMissing3 && withValidation"
              :with-sound="withSound"
          />
        </div>
      </div>

    </div>

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne" />
    
    <ClavierChiffres
        class="clavier"
        ref="clavier"
        :nb-touches-par-ligne="10"
    />

  </div>

</template>

<script setup>

  import {onMounted, watch, ref, defineExpose, onUnmounted, computed} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import _ from 'lodash';
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getDizainesUnites, getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Card from "../Card";
  import Slots from "../Slots";
  import Ada from "../../../components/v2/Ada";
  import SignePlus from "../Signes/SignePlus";
  import SigneEgal from "../Signes/SigneEgal";
  import CardDeColoriable from "../Des/DeColoriable/CardDeColoriable";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Clavier
  let clavier = ref(null);

  // Selon niveau :
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 9;
  var total_complementA10;

  const overlapThreshold = "50%";
  let isDragging = false;
  let draggables;
  let selectedDraggable;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  let refChiffre1 = ref(); // Int
  let refChiffre2 = ref();

  let refChiffre3 = ref(); // Int
  let refChiffre3Dizaines = ref(); // Array
  let refChiffre3DizainesCssClass = ref(); // String

  // Liste des couleurs de fond des dizaines (les autres ont le fond gris)
  let refChiffre3DizainesBackgroundColors = ref(); // Array

  // Liste des couleurs des points du dé complément (les autres points sont sombres)
  let refChiffre3UnitesBackgroundColors = ref(); // Array


  let refIsMissing1 = ref(); // Boolean
  let refIsMissing2 = ref();
  let refIsMissing3 = ref(false); // 100

  let refMissingIsFound = ref(); // Boolean
  let refForceDizaine = ref(); // Boolean

  // Code :
  const code = ref(null);

  // Référence du composant Slots :
  const slots1 = ref(null);
  const slots2 = ref(null);
  const slots3 = ref(null);

  // Classe Css
  let cssClassName = computed(() => ' niveau-' + niveauActivite.value );


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }



  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch (niveau) {

      default:
      case 1:
        currentMinimumNumber = 10;
        currentMaximumNumber = 10;
        total_complementA10 = 10;
        successMax.value = 4;
        code.value = "simple";
        break;

      case 2:
        currentMinimumNumber = 20;
        currentMaximumNumber = 20;
        total_complementA10 = 20;
        successMax.value = 4;
        code.value = "simple";
        break;

      case 3:
        currentMinimumNumber = 50;
        currentMaximumNumber = 50;
        total_complementA10 = 50;
        successMax.value = 4;
        code.value = "simple";
        break;

      case 4:
        currentMinimumNumber = 100;
        currentMaximumNumber = 100;
        total_complementA10 = 100;
        successMax.value = 4;
        code.value = "simple";
        break;
    }

    // Ada
    success = 0;

    if (! niveau) console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  }

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 50) {
      return 2;
    } else if (maxValue <= 100) {
      return 3;
    } else if (maxValue <= 1000) {
      return 4;
    }
  }



  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    if (clavier.value) {
      clavier.value.resetGommettes();
    }

    refMissingIsFound.value = false;

    var missingZone, total, chiffre1, chiffre2, forceDizaine;

    if ((chiffres === null) || (chiffres === undefined))
    {
      // Total fixé selon le niveau
      total = total_complementA10;

      // Tirage au sort du premier nombre
      chiffre1 = randomIntFromInterval(1, total - 1);

      // Déduction du second nombre
      chiffre2 = total - chiffre1;

      // On alterne la position du chiffre manquant
      missingZone = randomIntFromInterval(1, 2);

      forceDizaine = niveauActivite.value > 2;

    } else {

      // Saisie manuelle

      var saisie1 = chiffres[0];
      var saisie2 = chiffres[1];
      var saisie3 = chiffres[2];

      console.log(saisie1, saisie2, saisie3);

      if (saisie1 === null) {

        missingZone = 1;

        chiffre2 = saisie2;
        total = saisie3;

        chiffre1 = total - chiffre2;

      } else if (saisie2 === null) {

        missingZone = 2;
        chiffre1 = saisie1;
        total = saisie3;

        chiffre2 = total - chiffre1;

      } else {

        missingZone = 3;
        chiffre1 = saisie1;
        chiffre2 = saisie2;

        total = chiffre1 + chiffre2;

      }

      forceDizaine = total > 9;

      if (total < 10) {
        niveauActivite.value = missingZone === 3 ? 1 : 2;
      } else {
        niveauActivite.value = missingZone === 3 ? 3 : 4;
      }

    }

    console.log(chiffre1, '+', chiffre2, '=', total, "niveau", niveauActivite.value, "missing", missingZone);

    refChiffre1.value = chiffre1;
    refChiffre2.value = chiffre2;
    refChiffre3.value = total;

    refForceDizaine.value = forceDizaine;

    refIsMissing1.value = missingZone === 1;
    refIsMissing2.value = missingZone === 2;

    // Si on complète 10, on a un dé-dizaine à droite
    // Si on complète 20, on a deux dé-dizaines à droite
    // ...
    const nbDizaines = Math.floor(total / 10 )
    const dizaines = [];
    for(let i = 0; i < nbDizaines; i++) {
      dizaines.push((i + 1) * 10);
    }

    // On doit afficher à droite les dizaines connues avec leur fond coloré
    // et dans le dernier dé les unités connues avec les points colorés
    let dizainesUnitesConnues, unitesConnues, dizainesConnues;
    if (missingZone === 1) {
      dizainesUnitesConnues = getDizainesUnites(chiffre2);
    } else if (missingZone === 2) {
      dizainesUnitesConnues = getDizainesUnites(chiffre1);
    } else {
      dizainesUnitesConnues = getDizainesUnites(chiffre1);
    }

    // Couleurs des points du dé complémentaire
    unitesConnues = dizainesUnitesConnues.unites;
    refChiffre3UnitesBackgroundColors.value = getCouleurs(unitesConnues);

    // Couleurs de fond des dizaines :
    dizainesConnues = dizainesUnitesConnues.dizaines;
    refChiffre3DizainesBackgroundColors.value = getCouleurs(dizainesConnues);

    // Ancienne version pour les niveaux 1 et 2 (sans indications) :
    // refChiffre3UnitesBackgroundColors.value = total_complementA10 < 50 ? "defaut" : getCouleurs(unitesConnues);
    // refChiffre3DizainesBackgroundColors.value = total_complementA10 < 50 ? "defaut" : getCouleurs(dizainesConnues);

    refChiffre3Dizaines.value = dizaines;
    refChiffre3DizainesCssClass.value = "nb-dizaines-" + nbDizaines;

    // Consigne
    if (avecConsigne) {
      playSound_Consigne();
    }

    // Drag and drop
    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", '.clavier').not('.draggable-initialized');
    $target.addClass('draggable-initialized')

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create($target, {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true,
      onPress: function (pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        selectedDraggable = draggableElement;
      },
      onDragStart: function (pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        var dropZones = $(".slot-zone");
        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function (pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        setTimeout(function () {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = parseInt(draggableElement.find('.with-value').attr('data-value'));

        var dropZones = $(".slot-zone");
        var i = dropZones.length;
        var dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, {x: 0, y: 0, delay: 0.1});

        isDragging = false;
      },
      onDrag: function () {

        var dropZones = $(".slot-zone");
        var i = dropZones.length, dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    var dropZones = $(".slot-zone");
    dropZones.on('click', function () {
      if (!isDragging && selectedDraggable) {
        $('.draggable').removeClass("dragged");

        var nombre_drag = parseInt(selectedDraggable.find('.with-value').attr('data-value'));
        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre_drag);
      }
    });
  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  // On renvoie la couleur pour les premiers dés, et la couleur grise au delà du nombre de dizaines connues
  function getBackgroundColors(index) {
    if (Array.isArray(refChiffre3DizainesBackgroundColors.value) && index < refChiffre3DizainesBackgroundColors.value.length) {
      return refChiffre3DizainesBackgroundColors.value[index];
    }
    return "#E6E6E6";
  }

  // On renvoie les couleurs des points pour le dés suivant la dernière dizaine connue
  function getDotColors(index) {
    if (Array.isArray(refChiffre3DizainesBackgroundColors.value) && index === refChiffre3DizainesBackgroundColors.value.length) {
      return refChiffre3UnitesBackgroundColors.value.concat();
    } else if (refChiffre3UnitesBackgroundColors.value === "defaut") {
      return getCouleurs(10);
    }
    return [];
  }

  function getCouleurs(chiffre) {

    const rouge = "#FF3F33";
    const vert = "#58CE45";
    const violet = "#CA8BFE";
    const bleu = "#5BA0F1";
    const orange = "#FFAB02";

    switch(chiffre) {
      case 1:
        return [orange];
      case 2:
        return [rouge, rouge];
      case 3:
        return [violet, violet, violet];
      case 4:
        return [vert, vert, vert, vert];
      case 5:
        return [vert, vert, vert, vert, orange];
      case 6:
        return [vert, vert, vert, vert, rouge, rouge];
      case 7:
        return [bleu, bleu, bleu, bleu, bleu, bleu, orange];
      case 8:
        return [bleu, bleu, bleu, bleu, bleu, bleu, rouge, rouge];
      case 9:
        return [bleu, bleu, bleu, bleu, bleu, bleu, violet, violet, violet];

        // Cas particulier : on stocke ici la séquence par défaut, pour le cas où on veut afficher les couleurs par défaut ( niveaux 1 et 2 )
      case 10:
        return [vert, vert, orange, vert, vert, vert, vert, orange, vert, vert];
    }
    return [];
  }

  function checkZone( dropZone, nombre ) {

    const slotsNo = parseInt( dropZone.closest('.slots').attr("data-slots") );
    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );

    // console.log("checkZone", dropZoneNo, '-->', dropZoneCorrectValue, 'dropped', nombre);
    // console.log("dropZoneNo", dropZoneNo, "slotsNo", slotsNo)

    let composantSlots;
    if (refIsMissing1.value) {
      if (slotsNo !== 1) return;
      composantSlots = slots1.value;
    } else if (refIsMissing2.value) {
      if (slotsNo !== 2) return;
      composantSlots = slots2.value;
    }

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      if (refMissingIsFound.value === true) {
        // Tout est correct

        // Son de félicitations, succès et replay
        playSound_Felicitations_and_AddSuccess();

      }
      else 
      {
        // Dernier chiffre indiqué correct
        updateAda("normal");
      }
    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);

      // ADA
      updateAda("error", 3000);
    }
  }

  const refChiffre3DesDizainesCssClass = function(item) {
    if (refIsMissing1.value) {
      return item < refChiffre2.value ? 'colored' : ''
    } else if (refIsMissing2.value) {
      return item < refChiffre1.value ? 'colored' : ''
    }
    return "";
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }
  }


  //
  // Statistiques
  //

  const slotChanged = function($event) {

    // Fin d'activité
    if ($event.isComplete) {
      refMissingIsFound.value = true;
    }

    // console.log('slotChanged', $event);

    const dislayChiffre = function(chiffre, isMissing) {
      return isMissing ? [ '(', chiffre, ')' ].join('') : chiffre;
    }

    let solution = [];
    solution.push( dislayChiffre( refChiffre1.value, refIsMissing1.value) );
    solution.push("+");
    solution.push( dislayChiffre( refChiffre2.value, refIsMissing2.value) );
    solution.push("=");
    solution.push( dislayChiffre( refChiffre3.value, refIsMissing3.value) );

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: "Compléter",
        solution: solution.join(' '),
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound) {
      const soundPath = getSoundPath_Consigne("utilise-le-clavier-pour-entrer-ta-reponse", true, "new");
      playSound(soundPath, () => { updateAda("help") });
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPathFelicitations = getSound_Felicitations();
        playSound(soundPathFelicitations, addSuccessAndReplay);
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }

</script>

<style scoped lang="scss">

  .complement-a {

    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 15px;

    &.niveau-1 {
      @include on-tablet {
        padding-top: 50px;
      }
    }

    .complement-contents {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .components-parent {
        display: flex;
        align-items: center;
        align-self: center;
      }

      .components-parent > div {
        margin: 10px 20px;
        font-size: 60px;
        text-align: center;
        box-sizing: border-box;
        position: relative;
      }
    }

    /* */

    .slots .slots-content {
      height: 132px;
    }

    .slots .slots-content > div > span.slot-zone {
      width: 112px;
    }

    .slots.slots-length-2 .slots-content > div > span.slot-zone,
    .slots .slots-content.is-hidden > div > span.slot-zone {
      width: 106px;
    }

    .slots.slots-length-3 .slots-content > div > span.slot-zone {
      width: 108px;
    }

    .slots.slots-length-2 .slots-content.is-hidden > div > span.slot-zone,
    .slots.slots-length-3 .slots-content.is-hidden > div > span.slot-zone,
    .slots.slots-length-4 .slots-content > div > span.slot-zone {
      width: 106px;
    }


    /* */

    .cards-dizaines {

      display: flex;

      .card .card-content > div > div {
        width: 92px;
      }

      .card.colored .de {
        background-image: url(../../../assets/images/svg/des_v2/unites_couleurs/Number_10_red.svg);
      }

      .card.colored svg {
        @include on-print {
          transform: scale(0.9);
        }
      }

      &.nb-dizaines-2 {
        flex-direction: column;
        padding-right: 96px;
      }

      &.nb-dizaines-5 {
        flex-direction: row;
      }

      &.nb-dizaines-10 {
        flex-direction: row;
        flex-wrap: wrap;
      }

    }

    /* */

    &.niveau-1 {

      .slots.slots-chiffre3 .slots-content > div > span.slot-zone {
        width: 60px;
      }

      .cards-dizaines {
        padding-right: 80px;
      }
    }

    &.niveau-2 {
      .card .dizaines .de {
        width: 80px;
        height: 44px;
        background-image: url(../../../assets/images/svg/des_v2/unites_couleurs/Number_10.svg);
      }
      .card.noir-et-blanc .dizaines .de {
        background-image: url(../../../assets/images/svg/des_v2/unites_noir_et_blanc/Number_10.svg);
      }
    }

    &.niveau-3,
    &.niveau-4 {

      .components-parent > div.operation {
        width: 30px;
        margin: 0;
      }

      .components-parent > div  {
        margin-left: 0;
        margin-right: 0;
        max-width: 700px;

        @include on-print {
          max-width: 590px;
        }
      }

      .slots.slots-chiffre3 {
        display: none;
      }

      .cards-dizaines .card.colored .de {
        width: 80px;
        height: 44px;
        /* background-image: url(../../../assets/images/svg/des_v2/unites_noir_et_blanc/Number_10_rouge_bg.svg); */
        background-image: url(../../../assets/images/svg/des_v2/unites_couleurs/Number_10_red.svg);
      }

    }

    &.niveau-3 {
      .slots-chiffre3-parent {
        width: 644px;
        height: 132px;
        background-image: url(../../../assets/images/svg/complement/slot-large-50.svg);
        background-repeat: no-repeat;

        @include on-print {
          background-image: url(../../../assets/images/svg/complement/slot-590-50.svg);
        }
      }
    }

    &.niveau-4 {
      .slots-chiffre3-parent {
        width: 734px;
        height: 132px;
        background-image: url(../../../assets/images/svg/complement/slot-large-100.svg);
        background-repeat: no-repeat;

        @include on-print {
          background-image: url(../../../assets/images/svg/complement/slot-590-100.svg);
        }
      }
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      @include on-tablet {
        margin-left: 40px;
      }
    }



  }

</style>
