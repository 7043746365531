<template>
  <div class="select" :class="cssClass">
    <label v-if="hasLabel">
      <span>{{ textLabel }}</span>
    </label>
    <div class="select-parent">
      <select
          ref="selectList"
          :id="selectId"
          @change="onChangeSelect"
      >
        <option v-for="option in options"
                :value="option.value"
                :key="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>

  //
  // Propriétés du composant
  //

  import {defineExpose, onMounted, ref} from "vue";

  const props = defineProps({
    values: {
      type: Array,
      required: true,
      default: null
    },
    selectId: {
      type: String,
      required: false,
      default: "select"
    },
    textLabel: {
      type: String,
      required: false,
      default: "Label"
    },
    hasLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    flexDirection: {
      type: String,
      required: false,
      default: "row"
    }
  });

  //
  // Reactive values
  //

  const selectList = ref();
  const cssClass = ref(null);

  const options = ref(null);


  //
  // Lifecycle hooks
  //

  onMounted(() => {

    if (props.values === null) {
      options.value = [
        { value: "valeur1", label: "Valeur 1" },
        { value: "valeur2", label: "Valeur 2" },
      ]
    } else {
      options.value = props.values;
    }

    cssClass.value = props.flexDirection;
  });


  //
  // Events
  //

  const emit = defineEmits(['change'])

  function onChangeSelect($event) {
    const select = $event.target;
    const selectedValue = select.value;

    emit( 'change', selectedValue);
  }


  //
  // Méthodes publiques
  //

  defineExpose({
    setSelect
  })

  function setSelect(value) {
    const selectElement = selectList.value;
    selectElement.value = value;
  }


</script>

<style scoped lang="scss">

.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0;

  &.column {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }

  .select-parent {
    display: inline-block;
    overflow-x: hidden;
    background: #FFFFFF;
    border: 1px solid #666666;
    border-radius: 4px;
    margin: 0;

    & > select {
      width: calc(100% + 15px);
      border: none;
      background: transparent url(../../../assets/images/svg/chevron-bas.svg) right 22px center / 12px auto no-repeat;
      padding: 5px 10px;

      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
    }
  }
}

</style>