<template>
  <a class="svg-icon fullscreen-link" href="#"></a>
</template>

<script>
export default {
  name: 'FullscreenBtn',
}
</script>

<style scoped lang="scss">

  a.fullscreen-link {
    background-image: url(../../../assets/images/svg/icones/fullscreen-off.svg);
    width:25px;
    height: 25px;
    margin-right:12px;
    float:left;
  }

  .fullscreen a.fullscreen-link:hover,
  .fullscreen a.fullscreen-link {
    background-image: url(../../../assets/images/svg/icones/fullscreen-on.svg);
  }

</style>
