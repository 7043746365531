<template>
  <div class="content content-v2">
    <div class="warning-message"><span style="color: #58cf45">In progress...</span> n'hésitez pas à nous signaler les bugs!</div>
    <PageHeader class="page-header" />
    <div class="home-page-content">
      <!-- -->
      <div class="title">
        <p>Bienvenue sur</p>
        <div class="title-with-ada">
          <!-- -->
          <h1>Dédys</h1>
          <!-- -->
          <div class="version-no">
            <a href="#" @click="openVersionMenu($event)">v 2.0</a>
            <ul class="version-menu" :class="menuCssClass">
              <li>
                <router-link to="/" class="selected">v 2.0</router-link>
              </li>
              <li>
                <router-link to="/v1/accueil">v 1.0</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- -->
      <CommencerAccueilBtn />
   </div>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import {computed, onMounted, onUnmounted, ref} from "vue";

  import PageHeader from "./PageHeader";
  import CommencerAccueilBtn from "../../../components/v2/Boutons/CommencerAccueilBtn";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";


  //
  // Reactive values
  //

  const menuOpened = ref(false);

  const menuCssClass = computed(() => {
    return menuOpened.value ? "opened" : "";
  });


  //
  // LifeCycle Hooks
  //

  onMounted(() => {
    document.body.addEventListener("click", closeVersionMenu);
  });

  onUnmounted(() => {
    document.body.removeEventListener("click", closeVersionMenu);
  });


  //
  // Fonctions spécifiques
  //

  const openVersionMenu = function($event){
    $event.preventDefault();
    $event.stopPropagation();
    menuOpened.value = true;
  }

  const closeVersionMenu = function(){
    menuOpened.value = false;
  }


</script>

<style scoped lang="scss">

  .warning-message {
    position: absolute;
    padding: 15px;

    @include on-tablet {
      left: 0;
      bottom: 0;
      font-size: 12px;
      line-height: 1;
    }
  }

  .home-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    width: 100%;
    height: calc( 100vh - 200px );
    min-height: 400px;

    @include on-mobile-landscape {
      padding-top: 20px;
      height: auto;
      min-height: 150px;
      gap: 25px;
    }

    .title {
      display: inline-block;
      color: #666666;

      & > p {
        font-size: 28px;
        font-weight: 500;
        line-height: 35px;
        text-align: center;
      }

      .title-with-ada {
        display: flex;
        align-items: flex-end;

        h1 {
          position: relative;
          padding-left: 173px;

          font-family: 'Quicksand', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 184px;
          line-height: 230px;

          @include on-mobile-landscape {
            padding-left: 120px;
            font-size: 90px;
            line-height: 100px;
          }

          &:after {
            content: "";
            position: absolute;
            top: 17px;
            left: 0;
            display: block;
            width: 248px;
            height: 217px;
            background: url(../../../assets/images/svg/ada/ada-accueil.svg) center / 248px auto no-repeat;

            @include on-mobile-landscape {
              top: 8px;
              width: 124px;
              height: 90px;
              background-size: contain;
            }
          }
        }

        .version-no {
          position: relative;
          transform: translate(10px, -23px);

          @include on-mobile-landscape {
            transform: translate(10px, -10px);
          }

          & > a {
            display: inline-block;
            font-family: 'Quicksand', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            text-decoration: none;
            color: rgba(102,102,102, 0.65);

            @include on-mobile-landscape {
              font-size: 18px;
              line-height: 20px;
            }

            &:after {
              content: "";
              display: inline-block;
              width: 20px;
              height: 30px;
              background: url(../../../assets/images/svg/chevron-bas.svg) right bottom / 14px auto no-repeat;
              opacity: 0.65;
            }

            &:hover {
              text-decoration: underline;
            }
          }

          .version-menu {
            visibility: hidden;

            position: absolute;
            top: 40px;
            left: 0;

            display: inline-block;
            background: #FFF;
            border: solid 1px #000;
            width: 50px;
            margin: 0;
            padding: 10px 20px;
            list-style-type: none;

            &.opened {
              visibility: visible;
            }

            & > li > a {
              text-decoration: none;
              color: #000;

              &.selected {
                color: rgba(102,102,102, 0.65);
                pointer-events: none;
              }

              &:hover {
                text-decoration: underline;
              }
            }
          }

        }
      }
    }

  }


</style>
