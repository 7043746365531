<template>
  <div class="content" :class="settingsCssClass">
    <HeaderActivite @toggle-settings="toggleSettings" />
    <main
        class="activite dictee-vocale"
        :class="desSettingsCssClass"
    >
      <!-- Bouton header -->
      <div class="activite-header">
        <!-- Recommencer -->
        <ReplayBtn />
        <!-- Démo -->
        <DemoBtn />
        <!-- Saisie manuelle -->
        <ManualInputsBtn />
      </div>

      <h1>Dictée vocale</h1>

      <div class="interaction avec-niveaux">

        <ul class="niveaux">
          <li><a class="niveau courant" data-level="1" href="#">Niveau 1 : de 10 à 99</a></li>
          <li><a class="niveau" data-level="2" href="#">Niveau 2 : de 100 à 199</a></li>
        </ul>

        <div class="columns">
          <div class="column sound-player-et-chiffres-a-trouver">

            <div class="soundPlayer"></div>

            <div class="chiffres-a-trouver">

              <div class="type-chiffre-a-trouver-parent">
                <div class="type-chiffre-a-trouver type-chiffre-a-trouver-centaine">Centaine</div>
                <div class="type-chiffre-a-trouver type-chiffre-a-trouver-dizaine">Dizaine</div>
                <div class="type-chiffre-a-trouver type-chiffre-a-trouver-unite">Unité</div>
              </div>

              <div class="chiffre-a-trouver-parent">
                <ul class="chiffre-a-trouver"><!--
                                    --><li class="nombre-parent colonne-centaines">
                  <div class="zone centaines" data-correction="correction-centaines">&nbsp;</div>
                  <div class="nombre nombre-centaines" id="chiffre-centaines">&nbsp;</div>
                </li><!--
                                    --><li class="nombre-parent">
                  <div class="zone dizaines" data-correction="correction-dizaines">&nbsp;</div>
                  <div class="nombre nombre-dizaines" id="chiffre-dizaines">&nbsp;</div>
                </li><!--
                                    --><li class="nombre-parent">
                  <div class="zone unites" data-correction="correction-unites">&nbsp;</div>
                  <div class="nombre nombre-unites" id="chiffre-unites">&nbsp;</div>
                </li>
                </ul>
                <div class="clear"></div>
              </div>

              <div class="correction-parent">
                <div class="correction correction-centaines"></div>
                <div class="correction correction-dizaines"></div>
                <div class="correction correction-unites"></div>
              </div>

            </div>

            <ClavierChiffres :nb_touches_par_ligne="5" />

          </div>
          <table class="column table-header-et-dizaines-unites">
            <tr class="table-header">
              <th class="table-column-title table-column-title-centaines">Centaine</th>
              <th class="table-column-title table-column-title-dizaines">Dizaine</th>
              <th class="table-column-title table-column-title-unites">Unité</th>
            </tr>
            <tr>
              <td colspan="3">
                <div class="table-parent-dizaines-unites">
                  <!-- Attention, ce tableau est reconstruit via JS -->
                  <table class="dizaines-unites des-carres" id="table-content">
                    <tr>
                      <td class="nb nb-centaines"></td>
                      <td class="blocs blocs-centaines"></td>
                      <td class="nb nb-dizaines"></td>
                      <td class="blocs blocs-dizaines"></td>
                      <td class="nb nb-unites"></td>
                      <td class="blocs blocs-unites"></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>

        </div>

        <!-- pour démo -->
        <div class="souris"></div>

      </div>
    </main>
    <Footer />
    <ManualInputsPopUp
        :slots=1
        :maxlength=3
        @change="validationSaisieManuelle"
        @close="fermerSaisieManuelle"
    />
    <SettingsPanel :class="settingsCssClass" />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v1/HeaderActiviteV1"
  import Footer from "../../../components/v1/FooterActivite"
  import ManualInputsBtn from "../../../components/v1/Boutons/ManualInputsBtn"
  import ManualInputsPopUp from "../../../components/v1/ManualInputsPopUp"
  import SettingsPanel from "../../../components/v1/SettingsPanel"
  import ReplayBtn from "../../../components/v1/Boutons/ReplayBtn";
  import DemoBtn from "../../../components/v1/Boutons/DemoBtn";

  import { ref, onMounted } from "vue";
  import { useStore } from 'vuex';

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    playSound,
    randomIntFromInterval,
    getDizainesUnites,
    playSequence,
    stopSequence,
    stopAllAnimations,
    stopAllSounds,
    getPDF,
    addSVGToPDF,
    getURLFromBackgroundImageStyle,
    addSVGKeyboardToPDF,
    getSVGSize
  } from "../../../js/utils";
  import ClavierChiffres from "../../../components/v1/Claviers/ClavierChiffres";

  const store = useStore();

  // Selon niveau :
  let currentLevel = 1;
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 99;

  let activite;
  // let outils;
  let interaction;
  let souris;
  let settingsCssClass = ref('');


  // Style des dés (forme, couleur)
  let desSettingsCssClass = store.getters.desSettingsCssClass;

  onMounted(() => {

    activite = $(".activite");
    // outils = $(".outils");
    interaction = $(".interaction");
    souris = $(".souris");


    //
    // Initializations
    //

    // Event delegation pattern :
    activite.on("click", function(e)
    {
      e.preventDefault();

      var target = $(e.target);
      var soundPath;

      if (target.is(".demo"))
      {
        demo( target );
      }
      else if (target.is(".saisie-manuelle"))
      {
        saisieManuelle( target );
      }
      else if (target.is(".replay"))
      {
        replay( target );
      }
      else if (target.is(".de-parent"))
      {
        console.log(target.data('value'));
      }
      else if (target.is(".de"))
      {
        var parent = target.closest('.de-parent');
        soundPath = getSoundPath_Chiffre( parent.data('value') );
        playSound(soundPath);
      }
      else if (target.is(".soundPlayer"))
      {
        var chiffre = $(".soundPlayer").data("value");
        soundPath = getSoundPath_Chiffre( chiffre );
        console.log("sp", chiffre, soundPath);
        playSound(soundPath);
      }
    });

    activite.on("mousedown", function(e)
    {
      e.preventDefault();

      var target = $(e.target);
      if (target.is(".draggable"))
      {
        console.log('mousedown')
      }
    });

    activite.on("replay", function(e)
    {
      e.preventDefault();
      replay();
    });

    activite.on("stopDemo", function(e)
    {
      e.preventDefault();
      stopDemo();
    });

    $('.print-link').on("click", function(e)
    {
      e.preventDefault();

      printAsPDF();
    });


    /* Niveaux */

    $('.niveau').on("click", function(e)
    {
      e.preventDefault();

      resetHilites();

      var niveau = $(e.target);

      // Activation du lien
      var niveaux = niveau.closest(".niveaux");
      var tousLesniveaux = niveaux.find(".niveau");
      tousLesniveaux.removeClass("courant");

      niveau.addClass("courant");

      // Définition du nombre maximum du tirage au sort
      var niveauNo = niveau.data("level");

      switch(niveauNo) {

        case 1:
          currentLevel = 1;
          currentMinimumNumber = 10;
          currentMaximumNumber = 99;
          break;

        case 2:
          currentLevel = 2;
          currentMinimumNumber = 100;
          currentMaximumNumber = 199;
          break;
      }

      initInterface();
      initActivite();
    });


    /* Drag and Drop */

    var dropZones = $(".zone");
    var overlapThreshold = "50%";
    var isDragging = false;
    var selectedDraggable;

    Draggable.create($(".draggable"), {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true,
      onPress: function (pointEvent) {

        $('.draggable').removeClass("dragged");

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.addClass("dragged");

        var nombre = draggableElement.data('value');

        var soundPath = getSoundPath_Chiffre(nombre);
        playSound(soundPath);

        selectedDraggable = draggableElement;
      },
      onDragStart: function (pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre = draggableElement.data('value');
        console.log(nombre);

        isDragging = true;
        selectedDraggable = null;

        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function (pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        // stopAllAnimations();

        setTimeout(function () {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        $('.draggable').removeClass("dragged");

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.removeClass("dragged");

        var i = dropZones.length;
        var dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            var nombre_drag = draggableElement.data('value');

            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, {x: 0, y: 0, delay: 0.1});

        isDragging = false;
      },
      onDrag: function () {

        var i = dropZones.length, dropZone;
        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    dropZones.on('click', function () {
      if (!isDragging && selectedDraggable) {
        $('.draggable').removeClass("dragged");

        var nombre = selectedDraggable.data('value');
        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre);
      }
    })


    /* Première initialisation au chargement de la page */

    // initSettingsEvents();
    // restoreSettings();
    
    initActivite();


  });


  //
  // 0. Fonctions spécifiques à l'activité
  //

  function initActivite( chiffre ) {

    /*
    Dictée audio
    Les quantités apparaissent à droite
    Annonce automatique du  nombre audio
    Peut être entendu à nouveau en cliquant sur icône son
    Réponse donnée grâce à la barre chiffrée
    Retour vocal et visuel (réponse juste ou fausse)
    */

    if (chiffre === undefined)
    {
      // Tirage au sort d'un nombre entre 10 et 99 (ou au niveau 2 : de 100 à 199)
      chiffre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);

    }
    else
    {
      // Saisie manuelle
    }

    var dizainesUnites = getDizainesUnites( chiffre );

    var centaines = dizainesUnites.centaines;
    var dizaines  = dizainesUnites.dizaines;
    var unites    = dizainesUnites.unites;

    console.log('Chiffre', chiffre, "-->", centaines, dizaines, unites);

    $("#chiffre-centaines").data('value', centaines);
    $("#chiffre-dizaines").data('value', dizaines);
    $("#chiffre-unites").data('value', unites);

    var tableHeaderHtml = '';

    if (centaines > 0) {
      tableHeaderHtml += '<th class="table-column-title table-column-title-centaines">Centaine</th>';
    }
    tableHeaderHtml += '<th class="table-column-title table-column-title-dizaines">Dizaine</th>';
    tableHeaderHtml += '<th class="table-column-title table-column-title-unites">Unité</th>';

    $(".table-header").html(tableHeaderHtml);


    var i, no;
    var tableHtml = '<tbody>';

    for(i=0;i<9;i++)
    {
      no = i + 1;

      tableHtml += '<tr>';

      if (centaines > 0)
      {
        if (no <= centaines) {
          tableHtml += '<td class="nb nb-centaines" rowspan="10">' + no + '</td>';
          tableHtml += '<td class="blocs blocs-centaines" rowspan="10"><div class="de-parent centaine" data-value="' + 100 * no + '"><div class="de chiffre100 "></td>';
        }
      }

      if (no <= dizaines) {
        tableHtml += '<td class="nb nb-dizaines">' + no + '</td>';
        tableHtml += '<td class="blocs blocs-dizaines"><div class="de-parent double" data-value="' + 10 * no + '"><div class="de chiffre10 "></td>';
      } else {
        tableHtml += '<td class="nb nb-dizaines nb-is-zero">' + no + '</td>';
        tableHtml += '<td class="blocs blocs-dizaines"><div class="de-parent double no-border"></div></td>';
      }


      if (no <= unites) {
        tableHtml += '<td class="nb nb-unites">' + no + '</td>';
        tableHtml += '<td class="blocs blocs-unites"><div class="de-parent" data-value="' + no + '"><div class="de chiffre1"></td>';
      } else {
        tableHtml += '<td class="nb nb-unites nb-is-zero">' + no + '</td>';
        tableHtml += '<td class="blocs blocs-unites"><div class="de-parent no-border"></div></td>';
      }

      tableHtml += '</tr>';
    }

    if (centaines > 0)
    {
      tableHtml += '<tr>';
      tableHtml += '<td class="nb nb-dizaines">&nbsp;</td>';
      tableHtml += '<td class="blocs blocs-dizaines"></td>';
      tableHtml += '<td class="nb nb-unites"></td>';
      tableHtml += '<td class="blocs blocs-unites"></td>';
      tableHtml += '</tr>';
    }

    tableHtml += '</tbody>';

    $('#table-content').html(tableHtml);

    // On vide les champs du chiffre à trouver
    $("#chiffre-centaines").html("&nbsp;");
    $("#chiffre-dizaines").html("&nbsp;");
    $("#chiffre-unites").html("&nbsp;");

    interaction.css("display", "block");

    // Son du chiffre
    $(".soundPlayer").data("value", chiffre);
    $(".soundPlayer").trigger('click');
  }

  function initInterface() {

    $('.colonne-centaines').removeClass('visible');
    $('.type-chiffre-a-trouver-centaine').removeClass('visible');
    $('.chiffres-a-trouver').removeClass('avec-centaines');

    switch(currentLevel) {

      case 1:
        break;

      case 2:
        $('.colonne-centaines').addClass('visible');
        $('.type-chiffre-a-trouver-centaine').addClass('visible');
        $('.chiffres-a-trouver').addClass('avec-centaines');
        break;
    }

  }

  function checkZone( dropZone, nombre ) {

    dropZone.removeClass("hilite");
    dropZone.siblings('.nombre').text(nombre);

    var correctionClass = dropZone.data("correction");
    var correctionElement = $('.' + correctionClass);

    if ( nombre === "") {
      correctionElement.removeClass("incorrect").removeClass("correct");
      return;
    }

    var centaines_a_trouver = parseInt( $("#chiffre-centaines").data('value'));
    var dizaines_a_trouver  = parseInt( $("#chiffre-dizaines").data('value'));
    var unites_a_trouver    = parseInt( $("#chiffre-unites").data('value'));

    var centaines_proposees = parseInt( $("#chiffre-centaines").text());
    if (isNaN(centaines_proposees)) {
      centaines_proposees = 0;
    }
    var dizaines_proposees  = parseInt( $("#chiffre-dizaines").text());
    var unites_proposees    = parseInt( $("#chiffre-unites").text());

    var centainesOk = centaines_a_trouver === centaines_proposees;
    var dizainesOk  = dizaines_a_trouver === dizaines_proposees;
    var unitesOk    = unites_a_trouver === unites_proposees;

    /*
    console.log("centaines_a_trouver", centaines_a_trouver, centaines_proposees);
    console.log("dizaines_a_trouver", dizaines_a_trouver, dizaines_proposees);
    console.log("unites_a_trouver", unites_a_trouver, unites_proposees);
    console.log(centainesOk, dizainesOk, unitesOk);
    */

    if (centainesOk && dizainesOk && unitesOk) {
      // Tout est correct :
    }

    var nombreEl = dropZone.siblings('.nombre');
    var valeur_a_trouver = parseInt( nombreEl.data('value'));
    var valeur_proposee  = parseInt( nombreEl.text());

    correctionElement.removeClass("incorrect").removeClass("correct");
    correctionElement.addClass(valeur_a_trouver === valeur_proposee ? "correct" : "incorrect");
  }


  //
  // 3. Démo Activité
  //

  var sequenceActions;

  function demo() {

    stopDemo();
    resetActivite();

    souris.css("display", "block");
    souris.css("opacity", 1);

    var positionInteraction = interaction.offset();
    var zindex = 10;

    var centaines_a_trouver = parseInt( $("#chiffre-centaines").data('value'));
    var dizaines_a_trouver  = parseInt( $("#chiffre-dizaines").data('value'));
    var unites_a_trouver    = parseInt( $("#chiffre-unites").data('value'));

    console.log("à trouver", centaines_a_trouver, dizaines_a_trouver, unites_a_trouver);

    var nombres = [];

    // Centaines
    if (! isNaN(centaines_a_trouver) && (centaines_a_trouver !== 0) && (centaines_a_trouver !== '')) {
      nombres.push(
          {
            nombre: centaines_a_trouver,
            zone: $('.zone.centaines'),
            draggable: $('.draggable[data-value=' + centaines_a_trouver + ']'),
            text: $("#chiffre-centaines")
          }
      );

      console.log(centaines_a_trouver, $('.zone.centaines'),$('.draggable[data-value=' + centaines_a_trouver + ']'));
    }

    // Dizaines
    if (! isNaN(dizaines_a_trouver)) {

      if ( (nombres.length === 0) && (dizaines_a_trouver === 0) || (dizaines_a_trouver === '')) {
        // Cas particulier :
        // Pas de centaines, pas de dizaines : seulement des unités
      } else {

        nombres.push(
            {
              nombre: dizaines_a_trouver,
              zone: $('.zone.dizaines'),
              draggable: $('.draggable[data-value=' + dizaines_a_trouver + ']'),
              text: $("#chiffre-dizaines")
            }
        );
      }

      console.log(dizaines_a_trouver, $('.zone.dizaines'),$('.draggable[data-value=' + dizaines_a_trouver + ']'));
    }

    // Unités
    if (! isNaN(unites_a_trouver)) {
      nombres.push(
          {
            nombre: unites_a_trouver,
            zone: $('.zone.unites'),
            draggable: $('.draggable[data-value=' + unites_a_trouver + ']'),
            text: $("#chiffre-unites")
          }
      );

      console.log(unites_a_trouver, $('.zone.unites'),$('.draggable[data-value=' + unites_a_trouver + ']'));
    }


    sequenceActions = [];
    sequenceActions.push( { action : "wait", param : 2 } );

    console.log('------- demo --------');

    var i, n = nombres.length;
    var nombreObj, nombre, draggableElement, draggablePosition, zoneNombreElement, textNombreElement, zonePosition;
    var dx, dy, sourisLeft, sourisTop, parentDraggableOffset, reparentedSourisLeft;

    var sourisDx = 20;
    var sourisDy = 20;

    for(i=0;i<n;i++) {

      nombreObj = nombres[i];
      nombre = nombreObj.nombre;

      // Nombre à déplacer
      draggableElement = nombreObj.draggable;

      // Position de départ
      draggablePosition = draggableElement.offset();

      // Zone de dépôt du nombre
      zoneNombreElement = nombreObj.zone;

      // Element où apparaitra le nombre suite au déplacement
      textNombreElement = nombreObj.text;

      // Position d'arrivée du dé
      zonePosition = zoneNombreElement.offset();

      dx = zonePosition.left - draggablePosition.left;
      dy = zonePosition.top  - draggablePosition.top;

      console.log(nombre, draggablePosition, zonePosition, "dx=", dx, "dy=", dy);

      sourisLeft = draggablePosition.left - positionInteraction.left + sourisDx;
      sourisTop = draggablePosition.top - positionInteraction.top + sourisDy;

      parentDraggableOffset = draggableElement.offset();
      reparentedSourisLeft = sourisLeft - parentDraggableOffset.left + interaction.offset().left;

      sequenceActions.push( { action : "moveElement", param : { element:souris , left: sourisLeft, top: sourisTop, duration: 0 } } );
      sequenceActions.push( { action : "fadeInElement", param : { element:souris } } );
      sequenceActions.push( { action : "addClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:draggableElement, left: reparentedSourisLeft } } );
      sequenceActions.push( { action : "moveElement", param : { element:souris , left: sourisDx, top: sourisDy, duration: 0 } } );
      sequenceActions.push( { action : "playSound", param : getSoundPath_Chiffre(nombre) } );
      sequenceActions.push( { action : "transformElement", param : { element:draggableElement , x: dx, y: dy, zindex:zindex ++ } } );
      sequenceActions.push( { action : "fadeOutElement", param : { element:souris } } );
      sequenceActions.push( { action : "addClass", param : { element:zoneNombreElement, className:"correct" } } );
      sequenceActions.push( { action : "removeClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:interaction } } );
      sequenceActions.push( { action : "transformElement", param : { element:draggableElement , x: 0, y: 0, duration: 0, zindex:zindex ++ } } );
      sequenceActions.push( { action : "setText", param : { element:textNombreElement, text: nombre } } );
      sequenceActions.push( { action : "wait", param : 2 } );
    }

    sequenceActions.push( { action : "callback", param : resetActivite } );

    playSequence( sequenceActions );
  }

  function stopDemo() {

    gsap.set( $('.draggable'), {x:0, y:0 });

    stopSequence(sequenceActions);
    stopAllAnimations();
    stopAllSounds();
  }


  //
  // 4. Rejouer Activité
  //

  function replay() {

    stopDemo();
    resetActivite();

    interaction.css("display", "block");
  }

  function resetHilites() {
    souris.css("display", "none");

    $(".correction").removeClass("hilite").removeClass("incorrect").removeClass("correct");
    $(".draggable").removeClass("dragged");
  }

  function resetActivite( chiffre ) {
    resetHilites();
    initActivite(chiffre);
  }


  //
  // 5. Saisie manuelle
  //

  function saisieManuelle() {
    $("#popup-saisie-manuelle input[type=text]").val('');
    $("#popup-saisie-manuelle").removeClass('open').addClass('open');
  }

  function validationSaisieManuelle(chiffres_saisis) {

    const chiffre = parseInt(chiffres_saisis[0]);

    if ( ! isNaN(chiffre))
    {
      if ((chiffre >= 10) && (chiffre < 100))
      {
        currentLevel = 1;
      }
      else if (chiffre < 200)
      {
        currentLevel = 2;
      }
      else
      {
        return;
      }

      initInterface();
      resetActivite(chiffre);
      fermerSaisieManuelle();
    }
  }

  function fermerSaisieManuelle() {
    $("#popup-saisie-manuelle").removeClass('open');
  }


  //
  // 6. PDF
  //

  function printAsPDF() {

    var doc = getPDF('landscape');
    doc.text(5, 20, 'Dictée vocale');

    var leftCadre = 150;
    var leftCadreSasie = 30;
    var topCadre = 50;

    var scale;

    if ($('.activite').hasClass('de-bille')) {
      scale = 0.40;
    } else {
      scale = 0.65;
    }

    var topDes = topCadre + 1;
    var topNombres   = topCadre + 9;
    var offsetDes_y = 14;

    var desUnites = $('.de', '.blocs-unites');
    var unites = desUnites.length;

    var desDizaines = $('.de', '.blocs-dizaines');
    var dizaines = desDizaines.length;

    // URLs des dés
    var urlUnites = getURLFromBackgroundImageStyle( desUnites.first() );
    var urlDizaines = getURLFromBackgroundImageStyle( desDizaines.first() );

    if (currentLevel === 1)
    {
      // Dizaines - Unités

      $.when(
          $.ajax({ url: "assets/images/svg/cadre_dictee_vocale.svg" }),
          $.ajax({ url: "assets/images/svg/cadre_saisie_dictee_vocale_dizaine_unite.svg" }),
          $.ajax({ url: "assets/images/svg/cadre_chiffre_clavier.svg" }),
          $.ajax({ url: urlUnites }),
          $.ajax({ url: urlDizaines })
      )
          .done(function( jqXHRCadre, jqXHRCadreSaisie, jqXHRCadreClavier, jqXHUnites, jqXHRDizaines){

            var cadreDicteeVocale = jqXHRCadre[0];
            var cadreDicteeVocaleSaisie = jqXHRCadreSaisie[0];
            var cadreClavier = jqXHRCadreClavier[0];

            var leftDesDizaines = leftCadre + 20;
            var leftDesUnites = leftCadre + 80;

            var leftDizaines = leftDesDizaines - 6;
            var leftUnites   = leftDesUnites - 6;

            doc.setFontSize(14);
            doc.setTextColor('#FF3F33');
            doc.text( leftDizaines, topCadre - 4, "Dizaine" );
            doc.setFontSize(10);
            doc.text( leftCadreSasie + 12, topCadre - 4, "Dizaine" );

            doc.setFontSize(14);
            doc.setTextColor('#3B8FF2');
            doc.text( leftUnites, topCadre - 4, "Unité" );
            doc.setFontSize(10);
            doc.text( leftCadreSasie + 43, topCadre - 4, "Unité" );

            addSVGToPDF( doc, cadreDicteeVocale, leftCadre, topCadre, "cadre", false, 1.0);
            addSVGToPDF( doc, cadreDicteeVocaleSaisie, leftCadreSasie, topCadre, "cadre_saisie", false, 1.0);

            addSVGKeyboardToPDF(doc, cadreClavier, 15, topCadre + 40, 4);

            var i, svgUnite, svgDizaine;

            doc.setFontSize(15);

            //
            // Dizaines :
            //

            if (jqXHRDizaines && jqXHRDizaines.length > 0) {

              svgDizaine = jqXHRDizaines[0];

              doc.setTextColor('#FF3F33');
              for(i=0; i<dizaines; i++) {
                doc.text( leftDizaines, topNombres + offsetDes_y * i, String(i+1) );
                addSVGToPDF(doc, svgDizaine, leftDesDizaines, topDes + offsetDes_y * i, "dizaine", true, scale);
              }

              doc.setTextColor('#CCCCCC');
              for(i=dizaines; i<9; i++) {
                doc.text( leftDizaines, topNombres + offsetDes_y * i, String(i+1) );
              }
            }

            //
            // Unités :
            //

            if (jqXHUnites && jqXHUnites.length > 0) {

              svgUnite = jqXHUnites[0];

              doc.setTextColor('#3B8FF2');
              for(i=0; i<unites; i++) {
                doc.text( leftUnites, topNombres + offsetDes_y * i, String(i+1) );
                addSVGToPDF( doc, svgUnite, leftDesUnites, topDes + offsetDes_y * i, "unite", true, scale);
              }

              doc.setTextColor('#CCCCCC');
              for(i=unites; i<9; i++) {
                doc.text( leftUnites, topNombres + offsetDes_y * i, String(i+1) );
              }
            }

            doc.setFontSize(14);
            doc.setTextColor('#CCCCCC');
            doc.text(5, 200, "2019 © Cognitice");

            doc.save('dictee_vocale.pdf');

          })
          .fail(function(){
          });
    }
    else
    {
      // Centaines - Dizaines - Unités

      var desCentaines = $('.de', '.blocs-centaines');
      var centaines = desCentaines.length;
      var urlCentaines = getURLFromBackgroundImageStyle( desCentaines.first() );

      $.when(
          $.ajax({ url: "assets/images/svg/cadre_dictee_vocale.svg" }),
          $.ajax({ url: "assets/images/svg/cadre_saisie_dictee_vocale_centaine_dizaine_unite.svg" }),
          $.ajax({ url: "assets/images/svg/cadre_chiffre_clavier.svg" }),
          $.ajax({ url: urlUnites }),
          $.ajax({ url: urlDizaines }),
          $.ajax({ url: urlCentaines })
      )
          .done(function( jqXHRCadre, jqXHRCadreSaisie, jqXHRCadreClavier, jqXHUnites, jqXHRDizaines, jqXHRCentaines){

            var cadreDicteeVocale = jqXHRCadre[0];
            var cadreDicteeVocaleSaisie = jqXHRCadreSaisie[0];
            var cadreClavier = jqXHRCadreClavier[0];

            var leftDesCentaines = leftCadre + 15;
            var leftDesDizaines = leftCadre + 55;
            var leftDesUnites = leftCadre + 100;

            var leftCentaines = leftDesCentaines - 6;
            var leftDizaines = leftDesDizaines - 6;
            var leftUnites   = leftDesUnites - 6;

            doc.setFontSize(14);
            doc.setTextColor('#18CD4B');
            doc.text( leftCentaines, topCadre - 4, "Centaine" );
            doc.setFontSize(10);
            doc.text( leftCadreSasie + 5, topCadre - 4, "Centaine" );

            doc.setFontSize(14);
            doc.setTextColor('#FF3F33');
            doc.text( leftDizaines, topCadre - 4, "Dizaine" );
            doc.setFontSize(10);
            doc.text( leftCadreSasie + 28, topCadre - 4, "Dizaine" );

            doc.setFontSize(14);
            doc.setTextColor('#3B8FF2');
            doc.text( leftUnites, topCadre - 4, "Unité" );
            doc.setFontSize(10);
            doc.text( leftCadreSasie + 48, topCadre - 4, "Unité" );

            addSVGToPDF( doc, cadreDicteeVocale, leftCadre, topCadre, "cadre", false, 1.1);
            addSVGToPDF( doc, cadreDicteeVocaleSaisie, leftCadreSasie, topCadre, "cadre_saisie", false, 1.0);

            addSVGKeyboardToPDF(doc, cadreClavier, 15, topCadre + 40, 4);

            var i, svgUnite, svgDizaine, svgCentaine;

            doc.setFontSize(15);

            //
            // Centaines :
            //

            if (jqXHRCentaines && jqXHRCentaines.length > 0) {

              svgCentaine = jqXHRCentaines[0];

              var scaleCentaine = scale;
              var size = getSVGSize(svgCentaine);
              if (size) {
                var svgHeight = parseInt( size.height );
                if (! isNaN(svgHeight) && ( svgHeight > 0 )) {
                  scaleCentaine = 0.68 * 800 / svgHeight;
                }
              }

              doc.setTextColor('#18CD4B');
              doc.text( leftCentaines, topNombres + 56, String(centaines) );
              addSVGToPDF(doc, svgCentaine, leftDesCentaines, topDes, "centaine", true, scaleCentaine);
            }

            //
            // Dizaines :
            //

            if (jqXHRDizaines && jqXHRDizaines.length > 0) {

              svgDizaine = jqXHRDizaines[0];

              doc.setTextColor('#FF3F33');
              for(i=0; i<dizaines; i++) {
                doc.text( leftDizaines, topNombres + offsetDes_y * i, String(i+1) );
                addSVGToPDF(doc, svgDizaine, leftDesDizaines, topDes + offsetDes_y * i, "dizaine", true, scale);
              }

              doc.setTextColor('#CCCCCC');
              for(i=dizaines; i<9; i++) {
                doc.text( leftDizaines, topNombres + offsetDes_y * i, String(i+1) );
              }
            }

            //
            // Unités :
            //

            if (jqXHUnites && jqXHUnites.length > 0) {

              svgUnite = jqXHUnites[0];

              doc.setTextColor('#3B8FF2');
              for(i=0; i<unites; i++) {
                doc.text( leftUnites, topNombres + offsetDes_y * i, String(i+1) );
                addSVGToPDF( doc, svgUnite, leftDesUnites, topDes + offsetDes_y * i, "unite", true, scale);
              }

              doc.setTextColor('#CCCCCC');
              for(i=unites; i<9; i++) {
                doc.text( leftUnites, topNombres + offsetDes_y * i, String(i+1) );
              }
            }

            doc.setFontSize(14);
            doc.setTextColor('#CCCCCC');
            doc.text(5, 200, "2019 © Cognitice");

            doc.save('dictee_vocale.pdf');

          })
          .fail(function(){
          });

    }
  }

  //
  // 7. Settings
  //

  const toggleSettings = function(bool) {
    if (bool) {
      $('.activite').trigger("stopDemo");
      settingsCssClass.value = "settings-opened";
    } else {
      $('.activite').trigger("replay");
      settingsCssClass.value = "";
    }

  };

</script>

<style lang="scss">

  .dictee-vocale {

    &.de-bille .blocs.blocs-centaines .de-parent {
      width: 160px;
      height: 793px;
      margin-top:11px;
    }

    &.de-bille .blocs.blocs-centaines .de-parent .de {
      width: 158px;
      height: 791px;
      background-position: top left;
    }

    &.de-classique .blocs.blocs-centaines .de-parent {
      width: 160px;
      height: 792px;
      margin-top: 7px;
    }

    &.de-classique .blocs.blocs-centaines .de-parent .de {
      width: 157px;
      height: 790px;
    }

    &.de-oblique .blocs .de-parent.double {
      width: 67px;
    }

    &.de-oblique .blocs.blocs-dizaines .de-parent {
      width: 67px;
    }

    &.de-oblique .blocs.blocs-dizaines .de-parent .de {
      width: 65px;
    }

    &.de-oblique .blocs.blocs-centaines .de-parent {
      width: 82px;
      margin-top: 7px;
      height: 800px;
    }

    &.de-oblique .blocs.blocs-centaines .de {
      width: 81px;
      height: 791px;
    }

    &.de-fond-bleu .blocs .de-parent {
      border: none;
    }

    &.de-bille .blocs.blocs-unites .de-parent .de,
    &.de-bille .blocs.blocs-dizaines .de-parent .de,
    &.de-bille .blocs.blocs-centaines .de-parent .de {
      background-size: contain;
    }

    //

    .column {
      width: 50%;
    }

    .column.sound-player-et-chiffres-a-trouver {
      padding-top: 10px;
    }

    .soundPlayer {
      width: 64px;
      height: 64px;
      background: #15299C;
      border-radius: 32px;
      margin: 38px 22px 52px 0;
      float: left;

      background-image: url(../../../assets/images/svg/icones/sound-player.svg);
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center center;
    }

    .chiffres-a-trouver {
      float: left;
    }
    .chiffre-a-trouver-parent {
      width: 260px;
      height: 96px;
      left: 231px;
      top: 128px;
      background: #FFFFFF;
      border: 1px solid #0B154B;
      box-sizing: border-box;
      border-radius: 12px;
      text-align: center;
    }

    .chiffre-a-trouver-parent > div {
      display: inline-block;
    }

    .type-chiffre-a-trouver-parent {
      margin-bottom: 10px;
    }

    .type-chiffre-a-trouver {
      width:100%;
      box-sizing: border-box;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    .type-chiffre-a-trouver-unite {
      display: inline-block;
      color: #3B8FF2;
      width: 49%;
    }

    .type-chiffre-a-trouver-dizaine {
      display: inline-block;
      color: #FF3F33;
      width: 49%;
    }

    .type-chiffre-a-trouver-centaine {
      display: none;
      color: #18CD4B;
    }

    .chiffres-a-trouver.avec-centaines .type-chiffre-a-trouver {
      width: 32%;
    }

    .type-chiffre-a-trouver-centaine.visible {
      display: inline-block;
    }

    .correction {
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center center;
      height: 20px;
      margin: 20px 0 40px;
      float: left;
    }

    .correction.correct {
      background-image: url(../../../assets/images/svg/correct-vert.svg);
    }

    .correction.incorrect {
      background-image: url(../../../assets/images/svg/incorrect-rouge.svg);
    }

    .correction {
      width: 50%;
    }

    .avec-centaines .correction {
      width: 32%;
    }

    .correction-centaines {
      display: none;
    }

    .avec-centaines .correction-centaines {
      display: block;
    }


    ul.chiffre-a-trouver {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }

    ul.chiffre-a-trouver li {
      display:inline-block;
      width: 49.6%;
      height: 100%;
      list-style-type: none;
      margin:0;
      font-size:50px;
      min-width: 30px;
      position: relative;
      border-right: 1px #0B154B dashed;
    }

    .chiffres-a-trouver.avec-centaines ul.chiffre-a-trouver li {
      width: 33%;
    }

    ul.chiffre-a-trouver li .zone {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 2px 20px;
    }

    ul.chiffre-a-trouver li .zone.unites {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      background-color: rgba(59, 143, 242, 0.2);
    }

    ul.chiffre-a-trouver li .zone.dizaines {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      background-color: rgba(255, 63, 51, 0.2);
    }

    .chiffres-a-trouver.avec-centaines ul.chiffre-a-trouver li .zone.dizaines {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    ul.chiffre-a-trouver li .zone.centaines {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      background-color: rgba(24, 205, 75, 0.2);
    }

    /*

    ul.chiffre-a-trouver li .zone.hilite {
        background-color: white;
    }

    ul.chiffre-a-trouver li .zone.filled {
        background-color: #EEEEEE;
    }

    ul.chiffre-a-trouver li .zone.correct {
        background-color: rgba(0, 255, 0, 0.5);
        transition-duration: 2s;
    }

    ul.chiffre-a-trouver li .zone.incorrect {
        background-color: rgba(255, 0, 0, 0.5);
        transition-duration: 0.5s;
    }
    */

    ul.chiffre-a-trouver li .nombre {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      text-align: center;
      width: 100%;
      height: 100%;
      padding-top: 20px;
      box-sizing: border-box;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
      font-weight: bold;
      font-size: 42px;
      line-height: 52px;
    }

    ul.chiffre-a-trouver li .nombre.nombre-unites {
      border-top-right-radius: 12px;
      border-bottom: 12px;
      color:#3B8FF2;
    }

    ul.chiffre-a-trouver li .nombre.nombre-dizaines {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      color: #FF3F33;
    }

    .chiffres-a-trouver.avec-centaines ul.chiffre-a-trouver li .nombre.nombre-dizaines {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    ul.chiffre-a-trouver li .nombre.nombre-centaines {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      color: #18CD4B;
    }

    ul.chiffre-a-trouver li:last-child {
      border-right: none;
    }

    .clavier-chiffres {
      clear: both;
    }

    .blocs {
      width: 180px;
    }

    .blocs .de-parent {
      width: 67px;
      height: 67px;
      box-shadow: none;
      border-radius: 12px;
      border: solid 1px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      margin:1px 0 0 0;
    }

    .blocs .de-parent.double {
      width: 132px;
    }

    .blocs .de-parent.no-border {
      border: none;
    }

    .blocs .de {
      background-size: cover;
      width: 65px;
      height: 65px;
    }

    .blocs.blocs-dizaines .de-parent .de {
      background-size: cover;
      width: 130px;
    }

    .blocs.blocs-centaines .de-parent {
      width: 132px;
      height: 652px;
      margin-bottom: 5px;
    }

    .blocs.blocs-centaines .de-parent .de {
      /*
      background-size: cover;
      width: 130px;
      height: 650px;
      */
      background-size: contain;
      width: 140px;
      height: 690px;
      margin-bottom: 5px;
    }

    .blocs-centaines {
      vertical-align: top;
    }

    .blocs-dizaines {
    }

    .blocs-unites {
    }


    .colonne-centaines, .saisie li.colonne-centaines, .nombre-parent.colonne-centaines {
      display: none;
    }

    .colonne-centaines.visible {
      display: table-column;
    }

    .saisie li.colonne-centaines.visible,
    .nombre-parent.colonne-centaines.visible {
      display: inline-block;
    }


    /* Table des chiffres */

    table.table-header-et-dizaines-unites {
      border: none;
      border-collapse: collapse;
      border-spacing: 0;
    }

    table.table-header-et-dizaines-unites tr, table.table-header-et-dizaines-unites td {
      margin:0;
    }

    .table-parent-dizaines-unites {
      width: 100%;
      border: 1px solid #0B154B;
      box-sizing: border-box;
      border-radius: 12px;
      max-height: calc( 100vh - 290px );
      overflow: auto;
    }

    .table.dizaines-unites {
      width: 100%;
      border: solid 0 black;
      border-collapse:collapse;
    }

    .table.dizaines-unites tr {
      min-height: 68px;
    }

    .table.dizaines-unites td {
      border: solid 0 black;
      text-align: center;
      height: 78px;
    }

    .table.dizaines-unites td {
      color: #18CD4B;
    }

    table.dizaines-unites td.nb-centaines {
      color: #18CD4B;
    }

    table.dizaines-unites td.nb-dizaines {
      color: #FF3F33;
    }

    table.dizaines-unites td.nb-unites {
      color: #3B8FF2;
    }

    .table-column-title {
      text-align:left;
      width: 33%;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 12px;
      padding-left: 20px;
    }

    .table-column-title-centaines {
      color: #18CD4B;
    }

    .table-column-title-dizaines {
      color: #FF3F33;
    }

    .table-column-title-unites {
      color: #3B8FF2;
    }

    .nb {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
      padding: 0 0 0 10px;
      width: 25px;
    }

    .nb.nb-is-zero {
      opacity: 0.25;
    }

    .nb-centaines {
    }

    .nb-dizaines {
    }

    .nb-unites {
    }

  }

</style>
