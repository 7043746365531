<template>

    <div class="slots" :class="slotsLengthCssClass">

      <div v-if="withHeader" class="slots-header">
        <div>
          <span v-if="hasCentainesMilliers" class="centaines-milliers">
            Centaine de milliers
          </span>
          <span v-if="hasDizainesMilliers" class="dizaines-milliers">
            Dizaine de milliers
          </span>
          <span v-if="hasMilliers" class="milliers">
            Millier
          </span>
          <span v-if="hasCentaines" class="centaines">
            Centaine
          </span>
          <span v-if="hasDizaines" class="dizaines">
            Dizaine
          </span>
          <span class="column unites">
            Unité
          </span>
        </div>
      </div>

      <div class="slots-content" :class="hiddenCssClass">
        <div :data-value="chiffre" >
          <span v-if="hasCentainesMilliers"
                class="slot-zone drop-zone with-value centaines-milliers"
                data-decomposition="centaines-milliers"
                data-slot="6"
                :data-value="centaines_milliers"
                @click="selectCentainesMilliers(centaines_milliers)"
          >
            {{ centaines_milliers_value }}
          </span>
          <span v-if="hasDizainesMilliers"
                class="slot-zone drop-zone with-value dizaines-milliers"
                data-decomposition="dizaines-milliers"
                data-slot="5"
                :data-value="dizaines_milliers"
                @click="selectDizainesMilliers(dizaines_milliers)"
          >
            {{ dizaines_milliers_value }}
          </span>
          <span v-if="hasMilliers"
                class="slot-zone drop-zone with-value milliers"
                data-decomposition="milliers"
                data-slot="4"
                :data-value="milliers"
                @click="selectMilliers(milliers)"
          >
            {{ milliers_value }}
          </span>
          <span v-if="hasCentaines"
                class="slot-zone drop-zone with-value centaines"
                data-decomposition="centaines"
                data-slot="3"
                :data-value="centaines"
                @click="selectCentaines(centaines)"
          >
            {{ centaines_value }}
          </span>
          <span v-if="hasDizaines"
                class="slot-zone drop-zone with-value dizaines"
                data-decomposition="dizaines"
                data-slot="2"
                :data-value="dizaines"
                @click="selectDizaines(dizaines)"
          >
            {{ dizaines_value }}
          </span>
          <span
              class="slot-zone drop-zone with-value unites"
              data-decomposition="unites"
              data-slot="1"
              :data-value="unites"
              @click="selectUnites(unites)"
          >
            {{ unites_value }}
          </span>
        </div>
      </div>

      <div v-if="withValidation" class="slots-validation">
        <div>
          <LedValidation v-if="hasCentainesMilliers" class="centaines-milliers" :class="centainesMilliersValidationCssClass" />
          <LedValidation v-if="hasDizainesMilliers" class="dizaines-milliers" :class="dizainesMilliersValidationCssClass" />
          <LedValidation v-if="hasMilliers" class="milliers" :class="milliersValidationCssClass" />
          <LedValidation v-if="hasCentaines" class="centaines" :class="centainesValidationCssClass" />
          <LedValidation v-if="hasDizaines" class="dizaines" :class="dizainesValidationCssClass" />
          <LedValidation class="unites" :class="unitesValidationCssClass" />
        </div>
      </div>

      <div v-if="withFooter" class="slots-footer" :class="footerCssClass">
        <div>
          <span v-if="hasCentainesMilliers" class="centaines-milliers">
            {{ centaines_milliers }}
          </span>
          <span v-if="hasDizainesMilliers" class="dizaines-milliers">
            {{ dizaines_milliers }}
          </span>
          <span v-if="hasMilliers" class="milliers">
            {{ milliers }}
          </span>
          <span v-if="hasCentaines" class="centaines">
            {{ centaines }}
          </span>
          <span v-if="hasDizaines" class="dizaines">
            {{ dizaines }}
          </span>
          <span class="column unites">
            {{ unites }}
          </span>
        </div>
      </div>

    </div>

</template>


<script setup>

  import {ref, onMounted, watch, computed, defineExpose} from "vue";
  import {getDizainesUnites, getSoundPath_Chiffre, playSound} from "../../js/utils";
  import LedValidation from "./Validation/LedValidation";

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 1
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    withoutColors: {
      type: Boolean,
      required: false,
      default: false
    },
    validation: {
      type: Number,
      required: false,
      default: 0   /*   +1: correct, -1: erreur  */
    },
    decompositionMininum: {
      type: Number,
      required: false,
      default: 1
    },
    hasHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    hasValidation: {
      type: Boolean,
      required: false,
      default: false
    },
    soundMode: {
      type: String,
      required: false,
      default: "nombre"
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
  });

  const emit = defineEmits(['init', 'change']);


  //
  // Reactive values (refs)
  //

  // Valeurs issus de chiffre ( valeurs correctes )
  let centaines_milliers = ref(0),
      dizaines_milliers = ref(0),
      milliers = ref(0),
      centaines = ref(0),
      dizaines = ref(0),
      unites = ref(0);

  // Valeurs mises à jour de l'extérieur ( valeurs erronées par ex )
  let centaines_milliers_ext = ref(-1),
      dizaines_milliers_ext = ref(-1),
      milliers_ext = ref(-1),
      centaines_ext = ref(-1),
      dizaines_ext = ref(-1),
      unites_ext = ref(-1);

  // Boolean permettant de masquer la valeur ( affiche un point d'interrogation )
  let centaines_milliers_visible = ref(true),
      dizaines_milliers_visible = ref(true),
      milliers_visible = ref(true),
      centaines_visible = ref(true),
      dizaines_visible = ref(true),
      unites_visible = ref(true);

  // Boolean permettant de valider la valeur ( affiche une LED grise, verte ou rouge )
  let centaines_milliers_correct = ref(null),
      dizaines_milliers_correct = ref(null),
      milliers_correct = ref(null),
      centaines_correct = ref(null),
      dizaines_correct = ref(null),
      unites_correct = ref(null);

  let decompositionMininum = ref();
  let withHeader = ref();
  let withFooter = ref();
  let withValidation = ref();

  let hasCentainesMilliers = ref();
  let hasDizainesMilliers = ref();
  let hasMilliers = ref();
  let hasCentaines = ref();
  let hasDizaines = ref();

  let isCorrect = ref(null);
  let isComplete = ref(null);

  let displayedSlots = [];


  //
  // Reactive values : computed
  //

  const getValidationCssClass = function(value) {
    if (! props.hasValidation) {
      return "";
    } else if (value === true) {
      return "is-correct";
    } else if (value === false) {
      return "is-wrong";
    } else {
      return "";
    }
  }

  const hiddenCssClass = computed(() => {
    let cssClass = '';
    if (props.isHidden) {
      cssClass = "is-hidden";
      if (props.hasValidation) {
        if ((props.validation === 1) || ( isComplete.value === true && isCorrect.value === true )) {
          cssClass += " is-correct";
        } else if ((props.validation === -1) || ( isComplete.value === true && isCorrect.value === false )) {
          cssClass += " is-wrong";
        } else if((isCorrect.value === null)) {
          cssClass = "is-hidden";
        }
      }
    }

    if (props.withoutColors) {
      cssClass += " without-colors";
    }
    return cssClass;
  });

  const centainesMilliersValidationCssClass = computed(() => {
    return getValidationCssClass(centaines_milliers_correct.value);
  });

  const dizainesMilliersValidationCssClass = computed(() => {
    return getValidationCssClass(dizaines_milliers_correct.value);
  });

  const milliersValidationCssClass = computed(() => {
    return getValidationCssClass(milliers_correct.value);
  });

  const centainesValidationCssClass = computed(() => {
    return getValidationCssClass(centaines_correct.value);
  });

  const dizainesValidationCssClass = computed(() => {
    return getValidationCssClass(dizaines_correct.value);
  });

  const unitesValidationCssClass = computed(() => {
    return getValidationCssClass(unites_correct.value);
  });

  const footerCssClass = computed(() => {
    let cssClass = '';
    if (props.withoutColors) {
      cssClass += " without-colors";
    }
    return cssClass;
  });

  const slotsLengthCssClass = computed(() => {
    return [
      "slots-length-" + String(props.chiffre).length,
      props.hasValidation ? "avec-validation" : "",
      props.hasFooter ? "avec-footer" : "",
    ].join(' ');
  });

  // Valeur affichée dans le slot des centaines de milliers
  const centaines_milliers_value = computed(() => {
    if (centaines_milliers_ext.value !== -1) {
      // Slot rempli manuellement, de l'extérieur du composant ( réponse erronée par ex. )
      return centaines_milliers_ext.value;
    } else if (props.isHidden && ! centaines_milliers_visible.value) {
      // Slot masqué, à deviner
      return '?';
    } else {
      // Slot correct ( ou vide, si la décomposition ne retourne pas de centaines de milliers
      return props.chiffre < 100000 && centaines_milliers.value === 0 ? '' : centaines_milliers.value;
    }
  });

  // Valeur affichée dans le slot des dizaines de milliers
  const dizaines_milliers_value = computed(() => {
    if (dizaines_milliers_ext.value !== -1) {
      // Slot rempli manuellement, de l'extérieur du composant ( réponse erronée par ex. )
      return dizaines_milliers_ext.value;
    } else if (props.isHidden && ! dizaines_milliers_visible.value) {
      // Slot masqué, à deviner
      return '?';
    } else {
      // Slot correct ( ou vide, si la décomposition ne retourne pas de dizaines de milliers
      return props.chiffre < 10000 && dizaines_milliers.value === 0 ? '' : dizaines_milliers.value;
    }
  });

  // Valeur affichée dans le slot des milliers
  const milliers_value = computed(() => {
    if (milliers_ext.value !== -1) {
      // Slot rempli manuellement, de l'extérieur du composant ( réponse erronée par ex. )
      return milliers_ext.value;
    } else if (props.isHidden && ! milliers_visible.value) {
      // Slot masqué, à deviner
      return '?';
    } else {
      // Slot correct ( ou vide, si la décomposition ne retourne pas de milliers
      return props.chiffre < 1000 && milliers.value === 0 ? '' : milliers.value;
    }
  });

  // Valeur affichée dans le slot des centaines
  const centaines_value = computed(() => {
    if (centaines_ext.value !== -1) {
      // Slot rempli manuellement, de l'extérieur du composant ( réponse erronée par ex. )
      return centaines_ext.value;
    } else if (props.isHidden && ! centaines_visible.value) {
      // Slot masqué, à deviner
      return '?';
    } else {
      // Slot correct ( ou vide, si la décomposition ne retourne pas de centaines
      return props.chiffre < 100 && centaines.value === 0 ? '' : centaines.value;
    }
  });

  // Valeur affichée dans le slot des dizaines
  const dizaines_value = computed(() => {
    if (dizaines_ext.value !== -1) {
      // Slot rempli manuellement, de l'extérieur du composant ( réponse erronée par ex. )
      return dizaines_ext.value;
    } else if (props.isHidden && ! dizaines_visible.value) {
      // Slot masqué, à deviner
      return '?';
    } else {
      // Slot correct ( ou vide, si la décomposition ne retourne pas de dizaines
      return props.chiffre < 10 && dizaines.value === 0 ? '' : dizaines.value;
    }
  });

  // Valeur affichée dans le slot des unités
  const unites_value = computed(() => {
    if (unites_ext.value !== -1) {
      // Slot rempli manuellement, de l'extérieur du composant ( réponse erronée par ex. )
      return unites_ext.value;
    } else if (props.isHidden && ! unites_visible.value) {
      // Slot masqué, à deviner
      return '?';
    } else {
      // Slot correct
      return unites.value;
    }
  });


  //
  // Lifecycle hooks
  //

  const update = function() {
    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites(chiffre);
    const isHidden = props.isHidden === true;
    const isVisible = ! isHidden;

    isCorrect.value = 0;
    isComplete.value = ! isHidden;

    // Décomposition
    centaines_milliers.value = dizainesUnites.centaines_milliers;
    dizaines_milliers.value = dizainesUnites.dizaines_milliers;
    milliers.value = dizainesUnites.milliers;
    centaines.value = dizainesUnites.centaines;
    dizaines.value = dizainesUnites.dizaines;
    unites.value = dizainesUnites.unites;

    // Nombre de décimales affichées ( 5 s'affiche pour 05 si decompositionMininum > 1 )
    decompositionMininum.value = props.decompositionMininum;

    // Header / Footer
    withHeader.value = props.hasHeader === true;
    withFooter.value = props.hasFooter === true;
    withValidation.value = props.hasValidation === true;

    //
    hasCentainesMilliers.value = (chiffre > 99999) || (decompositionMininum.value > 5);
    hasDizainesMilliers.value = (chiffre > 9999) || (decompositionMininum.value > 4);
    hasMilliers.value = (chiffre > 999) || (decompositionMininum.value > 3);
    hasCentaines.value = (chiffre > 99) || (decompositionMininum.value > 2);
    hasDizaines.value = (chiffre > 9) || (decompositionMininum.value > 1);

    // Réglage individuel de la visibilité de la décomposition
    // Par défaut elles prennent la valeur inverse de isHidden :
    centaines_milliers_visible.value = isVisible;
    dizaines_milliers_visible.value = isVisible;
    milliers_visible.value = isVisible;
    centaines_visible.value = isVisible;
    dizaines_visible.value = isVisible;
    unites_visible.value = isVisible;

    centaines_milliers_ext.value = -1;
    dizaines_milliers_ext.value = -1;
    milliers_ext.value = -1;
    centaines_ext.value = -1;
    dizaines_ext.value = -1;
    unites_ext.value = -1;

    centaines_milliers_correct.value = -1;
    dizaines_milliers_correct.value = -1;
    milliers_correct.value = -1;
    centaines_correct.value = -1;
    dizaines_correct.value = -1;
    unites_correct.value = -1;

    if (props.isHidden) {
      emit('init', {
        chiffre: props.chiffre
      });
    }

    // console.log(chiffre, isHidden, props.hasHeader, dizaines.value, chiffre < 10);
  };

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });


  //
  // Fonctions
  //

  // Validation
  const validateValues = function() {
    if (props.isHidden)
    {
      // On vide le tableau contenant les chiffres proposés (null ou ? si pas rempli)
      displayedSlots.splice(0, displayedSlots.length);

      // Correct ?
      let allSlotsCorrect = unites_visible.value || unites_ext.value === -1;
      let allSlotsFilled = unites_visible.value || (unites_ext.value !== null && unites_ext.value !== -1);
      displayedSlots.unshift(unites_value.value);

      if (props.chiffre > 9) {
        allSlotsCorrect &&= dizaines_visible.value || dizaines_ext.value === -1;
        allSlotsFilled  &&= dizaines_visible.value || (dizaines_ext.value !== null && dizaines_ext.value !== -1);
        displayedSlots.unshift(dizaines_value.value);
      }

      if (props.chiffre > 99) {
        allSlotsCorrect &&= centaines_visible.value || centaines_ext.value === -1;
        allSlotsFilled  &&= centaines_visible.value || (centaines_ext.value !== null && centaines_ext.value !== -1);
        displayedSlots.unshift(centaines_value.value);
      }

      if (props.chiffre > 999) {
        allSlotsCorrect &&= milliers_visible.value || milliers_ext.value === -1;
        allSlotsFilled  &&= milliers_visible.value || (milliers_ext.value !== null && milliers_ext.value !== -1);
        displayedSlots.unshift(milliers_value.value);
      }

      if (props.chiffre > 9999) {
        allSlotsCorrect &&= dizaines_milliers_visible.value || dizaines_milliers_ext.value === -1;
        allSlotsFilled  &&= dizaines_milliers_visible.value || (dizaines_milliers_ext.value !== null && dizaines_milliers_ext.value !== -1);
        displayedSlots.unshift(dizaines_milliers_value.value);
      }

      if (props.chiffre > 99999) {centaines
        allSlotsCorrect &&= centaines_milliers_visible.value || centaines_milliers_ext.value === -1;
        allSlotsFilled  &&= centaines_milliers_visible.value || (centaines_milliers_ext.value !== null && centaines_milliers_ext.value !== -1);
        displayedSlots.unshift(centaines_milliers_value.value);
      }

      isCorrect.value = allSlotsCorrect
      isComplete.value = allSlotsFilled;
    }
    else
    {
      isCorrect.value = null;
    }
  };

  //
  // Evénement @click sur les slots
  //

  function selectCentainesMilliers(no) {
    if (props.withSound) {
      if (props.soundMode === "nombre") {
        playSlotSound(props.chiffre);
      } else {
        playSlotSound(no * 100000);
      }
    }
  }

  function selectDizainesMilliers(no) {
    if (props.withSound) {
      if (props.soundMode === "nombre") {
        playSlotSound(props.chiffre);
      } else {
        playSlotSound(no * 10000);
      }
    }
  }

  function selectMilliers(no) {
    if (props.withSound) {
      if (props.soundMode === "nombre") {
        playSlotSound(props.chiffre);
      } else {
        playSlotSound(no * 10000);
      }
    }
  }

  function selectCentaines(no) {
    if (props.withSound) {
      if (props.soundMode === "nombre") {
        playSlotSound(props.chiffre);
      } else {
        playSlotSound(no * 100);
      }
    }
  }

  function selectDizaines(no) {
    if (props.withSound) {
      if (props.soundMode === "nombre") {
        playSlotSound(props.chiffre);
      } else {
        playSlotSound(no * 10);
      }
    }
  }

  function selectUnites(no) {
    if (props.withSound) {
      if (props.soundMode === "nombre") {
        playSlotSound(props.chiffre);
      } else {
        playSlotSound(no);
      }
    }
  }

  function playSlotSound(nombre) {
    if (props.withSound) {
      var soundPath = getSoundPath_Chiffre(nombre);
      if (! props.isHidden) {
        playSound(soundPath);
      } else {
        if (isCorrect.value === true && isComplete.value === true) {
          playSound(soundPath);
        }
      }
    }
  }



  // Emission d'un évènement

  const emitUpdatedValues = function(no, droppedValue, correctValue) {
    emit('change', {
      // Données du slot changé
      slotNo: no,
      slotDroppedValue: droppedValue,
      slotCorrectValue: correctValue,
      // Données du composant dans son ensemble
      chiffre: props.chiffre,
      isCorrect: isCorrect.value,
      isComplete: isComplete.value,
      proposed: isComplete.value === false ? false : parseInt(displayedSlots.join(''))
    });
  }




  /* Public methods */

  // no = 1 : unités
  // no = 2 : dizaines
  // no = 3 : centaines
  // no = 4 : milliers
  // no = 5 : dizaine de milliers
  // no = 6 : centaine de milliers

  // Rend visible la valeur correcte d'un slot, ou masque le slot (efface une éventuelle valeur mise à jour de l'extérieur)
  const setSlotVisible = function(no, isVisible) {
    if (props.isHidden)
    {
      switch(no) {
        case 1:
          unites_visible.value = isVisible;
          unites_ext.value = -1;
          break;
        case 2:
          dizaines_visible.value = isVisible;
          dizaines_ext.value = -1;
          break;
        case 3:
          centaines_visible.value = isVisible;
          centaines_ext.value = -1;
          break;
        case 4:
          milliers_visible.value = isVisible;
          milliers_ext.value = -1;
          break;
        case 5:
          dizaines_milliers_visible.value = isVisible;
          dizaines_milliers_ext.value = -1;
          break;
        case 6:
          centaines_milliers_visible.value = isVisible;
          centaines_milliers_ext.value = -1;
          break;
      }

      // AUDREY
      if (isVisible === false)
        isCorrect.value = null;
      }
  }

  const setSlotExternalValue = function(no, value) {
    // console.log('setSlotExternalValue', no, value)
    if (props.isHidden)
    {
      let correctValue;

      switch(no) {
        case 1:
          unites_ext.value = value;
          correctValue = unites.value;
          unites_visible.value = correctValue === value;
          unites_correct.value = value === null ? null : unites_visible.value;
          break;
        case 2:
          dizaines_ext.value = value;
          correctValue = dizaines.value;
          dizaines_visible.value = correctValue === value;
          dizaines_correct.value = value === null ? null : dizaines_visible.value;
          break;
        case 3:
          centaines_ext.value = value;
          correctValue = centaines.value;
          centaines_visible.value = correctValue === value;
          centaines_correct.value = value === null ? null : centaines_visible.value;
          break;
        case 4:
          milliers_ext.value = value;
          correctValue = milliers.value;
          milliers_visible.value = correctValue === value;
          milliers_correct.value = value === null ? null : milliers_visible.value;
          break;
        case 5:
          dizaines_milliers_ext.value = value;
          correctValue = dizaines_milliers.value;
          dizaines_milliers_visible.value = correctValue === value;
          dizaines_milliers_correct.value = value === null ? null : dizaines_milliers_visible.value;
          break;
        case 6:
          centaines_milliers_ext.value = value;
          correctValue = centaines_milliers.value;
          centaines_milliers_visible.value = correctValue === value;
          centaines_milliers_correct.value = value === null ? null : centaines_milliers_visible.value;
          break;
      }

      // Validation
      validateValues();

      // Evènement
      emitUpdatedValues(no, value, correctValue);

    }
  }

  const isComponentComplete = function() {
    return isComplete.value;
  }

  const isComponentCorrect = function() {
    return isCorrect.value;
  }

  defineExpose({
    setSlotVisible,
    setSlotExternalValue,
    isComponentComplete,
    isComponentCorrect
  })


</script>

<style scoped lang="scss">

  .slots {

    user-select: none;

    .slots-footer,
    .slots-header {

      display: table;
      margin-left: 6px;

      @include on-small-desktop {
        margin-left: 9px;
      }

      &.without-colors {
        .milliers,
        .centaines-milliers,
        .centaines,
        .dizaines-milliers,
        .unites,
        .dizaines {
          color: #666666 !important;
        }
      }

      & > div {
        background: #FFFFFF;
        display: table-cell;
        vertical-align: middle;

        span {
          width: 82px;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          border-radius: 0;

          &.milliers,
          &.unites {
            color: rgba(44, 133, 237, 1); // #2C85ED;
          }

          &.dizaines-milliers,
          &.dizaines {
            color: rgba(255, 63, 51, 1); // #FF3F33;
          }

          &.centaines-milliers,
          &.centaines {
            color: rgba(88, 206, 69, 1); // #FF3F33;
          }
        }
      }
    }

    .slots-header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      & > div {
        height: 50px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .slots-footer {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      & > div {
        height: 70px;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
      }
    }

    .slots-content {

      display: inline-block;
      background: #FFFFFF;
      height: 132px;
      border: 10px solid #FFFFFF;
      border-radius: 16px;

      @include on-print {
        height: 120px;
      }

      &.is-hidden {
        border-width: 8px;
      }

      &.is-hidden > div {
        padding: 0;
        border-radius: 16px;
        border: 4px solid #FFD32C;
      }

      &.is-hidden.is-correct > div {
        border-color: #58CE45;
      }

      &.is-hidden.is-wrong > div {
        border-color: #FF3F33;
      }

      &.without-colors {
        .milliers,
        .centaines-milliers,
        .centaines,
        .dizaines-milliers,
        .unites,
        .dizaines {
          color: rgba(51, 51, 51, 0.5) !important; // #FF3F33;
          background: rgba(51, 51, 51, 0.1) !important;
        }
      }

      & > div {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 52px;

        position: relative;
        overflow: hidden;

        display: flex;
        height: 100%;
        border-radius: 8px;

        & > span.slot-zone {
          display: flex;
          align-content: flex-start;
          align-items: center;
          justify-content: center;

          width: 82px;
          height: 100%;
          border-radius: 0;
        }
      }

      .milliers,
      .unites {
        color: rgba(44, 133, 237, 1); // #2C85ED;
        background: rgba(44, 133, 237, 0.1);
      }

      .is-hidden .milliers,
      .is-hidden .unites {
        color: rgba(44, 133, 237, 0.5); // #2C85ED;
      }

      .dizaines-milliers,
      .dizaines {
        color: rgba(255, 63, 51, 1); // #FF3F33;
        background: rgba(255, 63, 51, 0.1);
      }

      .is-hidden .dizaines-milliers,
      .is-hidden .dizaines {
        color: rgba(255, 63, 51, 0.5); // #FF3F33;
        background: rgba(255, 63, 51, 0.1);
      }

      .centaines-milliers,
      .centaines {
        color: rgba(88, 206, 69, 1); // #FF3F33;
        background: rgba(88, 206, 69, 0.1);
      }

      .is-hidden .centaines-milliers,
      .is-hidden .centaines {
        color: rgba(88, 206, 69, 0.5); // #FF3F33;
        background: rgba(88, 206, 69, 0.1);
      }

      .milliers:first-child {
        color: rgba(51, 51, 51, 1); // #FF3F33;
        background: rgba(51, 51, 51, 0.1);
      }

      .is-hidden .milliers:first-child {
        color: rgba(51, 51, 51, 0.5); // #FF3F33;
        background: rgba(51, 51, 51, 0.1);
      }

      .unites::before {
        content: '';
        display: block;
        position: absolute;
        width: 6.64px;
        height: 174.92px;
        left: -14.46px;
        top: -20.19px;

        /* White / 100 */
        background: #FFFFFF;
        opacity: 0.5;
        transform: rotate(30deg);
      }

      .unites::after {
        content: '';
        display: block;
        position: absolute;
        width: 23.96px;
        height: 169.69px;
        left: 0.77px;
        top: -22.74px;

        /* White / 100 */
        background: #FFFFFF;
        opacity: 0.5;
        transform: rotate(30deg);
      }

      .milliers::before {
        content: '';
        display: block;
        position: absolute;
        width: 23.96px;
        height: 169.69px;
        left: 326.84px;
        top: -22.74px;

        /* White / 100 */
        background: #FFFFFF;
        opacity: 0.5;
        transform: rotate(30deg);
      }

      .milliers::after {
        content: '';
        display: block;
        position: absolute;
        width: 6.64px;
        height: 174.92px;
        left: 361.46px;
        top: -33px;

        /* White / 100 */
        background: #FFFFFF;
        opacity: 0.5;
        transform: rotate(30deg);
      }
    }

    .slots-validation {
      display: table;
      margin-left: 10px;

      @include on-print {
        display: none;
      }

      & > div {

        & > div {
          display: table-cell;
          width: 82px;
          height: 43px;
          vertical-align: center;
          text-align: center;

        }
      }
    }

    /* Cas particulier */

    &.avec-validation.vertical-offset-for-flex {
      transform: translateY(22px);

      @include on-print {
        transform: translateY(0);
      }
    }

    &.width-156 {

      .slots-content > div > span.slot-zone {
        width: 156px;
      }

      .slots-validation {
        margin-left: 22px;
        margin-right: 22px;
        transform: translateY(-5px);

        & > div > div {
          width: 150px;
        }
      }

      .slots-footer {
        margin-left: 22px;
        margin-right: 22px;
        transform: translateY(-5px);

        & > div > span {
          width: 150px;
        }
      }
    }

    &.width-108 {

      .slots-content > div > span.slot-zone {
        width: 108px;

        @include on-small-desktop {
          width: 94px;
        }

        @include on-print {
          width: 82px;
        }
      }

      .slots-validation {
        margin-left: 16px;
        margin-right: 16px;
        transform: translateY(-5px);

        & > div > div {
          width: 108px;

          @include on-small-desktop {
            width: 94px;
          }

          @include on-print {
            width: 82px;
          }

        }
      }

      .slots-footer {
        margin-left: 16px;
        margin-right: 16px;
        transform: translateY(-5px);

        & > div > span {
          width: 108px;

          @include on-small-desktop {
            width: 94px;
          }

          @include on-print {
            width: 82px;
          }
        }
      }
    }

    &.width-106 {

      .slots-content > div > span.slot-zone {
        width: 106px;

        @include on-tablet {
          width: 97px;
        }

        @include on-print {
          width: 82px;
        }
      }

      .slots-validation {
        margin-left: 22px;
        margin-right: 22px;
        transform: translateY(-5px);

        & > div > div {
          width: 97px;
        }
      }

      .slots-footer {
        margin-left: 22px;
        margin-right: 22px;
        transform: translateY(-5px);

        & > div > span {
          width: 97px;
        }
      }
    }


    &.width-86 {

      .slots-content > div > span.slot-zone {
        width: 86px;

        @include on-print {
          width: 70px;
        }
      }

      .slots-validation {
        margin-left: 22px;
        margin-right: 22px;
        transform: translateY(-5px);

        & > div > div {
          width: 80px;
        }
      }

      .slots-footer {
        margin-left: 22px;
        margin-right: 22px;
        transform: translateY(-5px);

        & > div > span {
          width: 80px;
        }
      }
    }

    &.avec-validation.avec-footer {
      .slots-validation {
        background-color: #FFFFFF;
      }
    }

    &.validation-float-right {
      .slots-validation {
        float: right;
      }
    }
  }


</style>
