<template>
  <header class="header">
    <h1 class="titan">{{ title }}</h1>
    <div class="buttons">
      <router-link :to="settingsRoute" class="tools-button" :class="toolsCss" />
    </div>
  </header>
</template>

<script setup>

  import {onMounted, ref, computed} from "vue";

  const settingsRoute = ref('/parametres');

  //
  // Propriétés
  //

  const props = defineProps({
    title: {
      type: String,
      required: false,
      default: "Activités"
    },
    hasTools: {
      type: Boolean,
      required: false,
      default: false
    },
    hasToolsActive: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  //
  // LifeCycle Hooks
  //

  onMounted(() => {
  });

  const toolsCss = computed(() => [
      props.hasTools ? '' : 'hidden',
      props.hasToolsActive ? 'active' : 'inactive'
    ].join(' ')
  );

</script>

<style scoped lang="scss">

  header {

    position: relative;
    margin-bottom: 40px;

    @include on-mobile-landscape {
      margin-bottom: 20px;
    }

    h1 {
      display: inline-block;
      text-align: left;
    }

    /* Tools */

    .buttons {

      position: absolute;
      top: 20px;
      right: 0;

      @include on-mobile-landscape {
        top: 15px;
      }

      a {
        display: inline-block;
        width:63px;
        height:71px;
        cursor: pointer;

        @include on-mobile-landscape {
          width:50px;
          height:60px;
        }

        &.tools-button {
          background: url(../../../assets/images/svg/icones_v2/Tools_4x.png) center / cover no-repeat;

          @include on-mobile {
            width:50px;
            height:60px;
          }

          &.inactive {
            opacity: 0.25;
            pointer-events: none;
          }
        }

      }
    }

  }

</style>