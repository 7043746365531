<template>

  <div class="repertoire-multiplicatif-v2" >

    <div>

      <!-- Dés -->
      <div class="addition-des-parent not-draggable">
        <div class="cards">
         <Card v-for="index in refChiffre1"
                v-bind:key="index"
                :chiffre="refChiffre2"
                :decomposition="false"
                :css-class="formeDes"
                :has-separator="separateurMilliers"
                :with-sound="withSound"
          />
        </div>
        <div class="invert" @click="invertNumber()" :class="invertCssClass"></div>
      </div>

      <!-- Chiffres -->
      <div class="cartouche-nombres-parent">
        <Slots
            ref="slots1"
            class="vertical-offset-for-flex"
            :chiffre="refChiffre1"
            :with-sound="withSound"
            @change="slotChanged"
        />
        <SigneMultiplier />
        <Slots
            ref="slots2"
            class="vertical-offset-for-flex"
            :chiffre="refChiffre2"
            :with-sound="withSound"
            @change="slotChanged"
        />
        <SigneEgal />
        <Slots
            ref="slots3"
            class="vertical-offset-for-flex"
            :chiffre="refChiffre3"
            :is-hidden="true"
            :has-validation="withValidation"
            :without-colors="true"
            :with-sound="withSound"
            @change="slotChanged"
        />
      </div>

    </div>

    <ClavierChiffres
      class="clavier"
      :nb-touches-par-ligne="10"
    />

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne"/>

  </div>

</template>

<script setup>

  import {onMounted, watch, ref, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Card from "../Card";
  import Slots from "../Slots";
  import SigneMultiplier from "../Signes/SigneMultiplier.vue";
  import SigneEgal from "../Signes/SigneEgal.vue";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();


  // Selon niveau :
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 10;

  const overlapThreshold = "50%";
  let isDragging = false;
  let draggables;
  let selectedDraggable;

  let chiffresToInvert;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });



  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Référence du composant Slots :
  const slots1 = ref(null);
  const slots2 = ref(null);
  const slots3 = ref(null);

  let refChiffre1 = ref(); // Int
  let refChiffre2 = ref();
  let refChiffre3 = ref();

  const invertCssClass = computed(() => {
    return refChiffre1.value !== refChiffre2.value ? "invert-visible" : "invert-hidden";
  });


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });



  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }

  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 10;
        successMax.value = 8;
        break;

    }

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  }

  const getNiveauFromValues = function() {
    return 1;
  }



  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    var total, chiffre1, chiffre2;

    if ((chiffres === null) || (chiffres === undefined))
    {

      // Tirage au sort du total
      chiffre1 = randomIntFromInterval( currentMinimumNumber, currentMaximumNumber);

      // Tirage au sort du premier nombre
      chiffre2 = randomIntFromInterval( currentMinimumNumber, currentMaximumNumber);

      // Déduction du second nombre
      total = chiffre1 * chiffre2;

    } else {

      // Saisie manuelle

      var saisie1 = chiffres[0];
      var saisie2 = chiffres[1];

      chiffre1 = saisie1;
      chiffre2 = saisie2;

      total = chiffre1 * chiffre2;
    }

    console.log(chiffre1, 'x', chiffre2, '=', total, "niveau", niveauActivite.value);

    refChiffre1.value = chiffre1;
    refChiffre2.value = chiffre2;
    refChiffre3.value = total;

    // Mémorise chiffre pour fonction invert;
    chiffresToInvert = [refChiffre1.value, refChiffre2.value];

    // Consigne
    if (avecConsigne) {
      playSound_Consigne();
    }

    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", ".clavier")
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create( $target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        selectedDraggable = draggableElement;
      },
      onDragStart: function(pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        var dropZones = $(".slot-zone");
        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value') );

        var dropZones = $(".slot-zone");
        var i = dropZones.length;
        var dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold))
          {
            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, { x:0, y:0, delay:0.1 });

        isDragging = false;
      },
      onDrag: function() {

        var dropZones = $(".slot-zone");
        var i = dropZones.length, dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    var dropZones = $(".slot-zone");
    dropZones.on('click', function() {
      if (! isDragging && selectedDraggable )
      {
        $('.draggable').removeClass("dragged");

        var draggableElement = selectedDraggable;
        var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value') );

        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre_drag);
      }
    });
  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $(".draggable", '.clavier').find('.with-dragged-style').removeClass("dragged");
  }

  function checkZone( dropZone, nombre ) {

    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );
    console.log("checkZone", dropZoneNo, '-->', dropZoneCorrectValue, 'dropped', nombre);

    let composantSlots = slots3.value;

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      const composantSlotComplete = composantSlots.isComponentComplete();
      const composantSlotCorrect = composantSlots.isComponentCorrect();

      if (composantSlotComplete && composantSlotCorrect) {
        // Tous les slots sont bien remmplis :

        // Son de félicitations, succès et replay
        playSound_Felicitations_and_AddSuccess();

      }
      else 
      {
        // Le dernier slot rempli est correct
        updateAda("normal");
      }
    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      console.log('réponse erronée !');

      // ADA
      updateAda("error", 3000);
    }
  }

  // BOUTON
  function invertNumber(){
      refChiffre1.value = chiffresToInvert[1];
      refChiffre2.value = chiffresToInvert[0];
      chiffresToInvert = [refChiffre1.value,  refChiffre2.value];
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }



  //
  // Statistiques
  //

  const slotChanged = function($event) {

    // console.log('slotChanged', $event);

    const dislayChiffre = function(chiffre, isMissing) {
      return isMissing ? [ '(', chiffre, ')' ].join('') : chiffre;
    }

    let solution = [];
    solution.push( dislayChiffre( refChiffre1.value, false) );
    solution.push("x");
    solution.push( dislayChiffre( refChiffre2.value, false) );
    solution.push("=");
    solution.push( dislayChiffre( refChiffre3.value, true) );

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: "Compléter",
        solution: solution.join(' '),
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound) {
      const soundPath1 = getSoundPath_Consigne("utilise-le-clavier-pour-entrer-ta-reponse", true, "new");
      const soundPath2 = getSoundPath_Consigne("tu-peux-t-aider-en-cliquant-sur-les-quantités-au-dessus-des-nombres", true, "new");
      const soundPath3 = getSoundPath_Consigne("tu-peux-aussi-inverser-la-multiplication-en-cliquant", true, "new");
      playSound([ soundPath1, soundPath2, soundPath3 ] );
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPathFelicitations = getSound_Felicitations();
        playSound(soundPathFelicitations, addSuccessAndReplay);
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }

</script>

<style scoped lang="scss">

  .repertoire-multiplicatif-v2 {

    @include on-small-height-desktop {
      padding-top: 20px;
    }

    .columns {
      margin-top: 20px;
    }

    .column.des-carres {
      width: calc( 100% - 260px );
    }

    .column.clavier {
      width:260px;
    }

    ul.clavier-chiffres {
      margin-top: 10px;
    }

    .de-parent {
      box-shadow: none;
      margin: 0;
      border-radius: 0;
    }

    .addition-des-parent {
      display: flex;
      justify-content: center;
      align-items: center;

      .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: calc( 100vw - 200px );
        gap: 0;

        @include on-tablet {
        }

        & > div {
          font-size: 60px;
          text-align: center;
          box-sizing: border-box;
        }

        & > .unites > .des {
          border: 1px solid #0B154B;
        }
      }

      .card .card-border {
        border-width: 3px;
      }

      & .addition-des-parent > div {
        margin: 5px 5px;
      }

    }

    .nombres-dizaines-unites {
      overflow: hidden;
    }

    .des {
      border: 1px solid #0B154B;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 15px 15px 15px;
    }

    .des-dizaines-unites,
    .avec-dizaines .des,
    .avec-dizaines .des-dizaines-unites .des {
      box-shadow: none;
      border: none;
    }

    .avec-dizaines .des-dizaines-unites {
      display: flex;
      border: 1px solid #0B154B;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }

    .des-dizaines-unites {
      border: 1px solid rgba(255, 255, 255, 1.0);
    }

    .des-dizaines-unites > div {
      padding: 0 5px;
      position: relative;
      display: flex;
    }

    .des-dizaines-unites .dizaines {
      border-right: 1px solid rgba(255, 255, 255, 1.0);
    }

    .avec-dizaines .des-dizaines-unites .dizaines {
      border-right: 1px solid rgba(0, 0, 0, 1.0);
    }

    .des-dizaines-unites .dizaines.pas-de-dizaines {
      display: none;
    }

    .des-dizaines-unites .des {
      margin: auto;
    }

    .des-dizaines-unites .de-parent {
      margin-top: 4px;
    }

    .unites .de-parent,
    .dizaines .de-parent {
      background: transparent;
    }

    .unites {
      border-radius: 12px;
    }

    .des-dizaines-unites .unites {
      border-radius: 0 12px 12px 0;
    }

    .dizaines {
      border-radius: 12px 0 0 12px;
    }

    .invert {
      background: url(../../../assets/images/svg/icones_v2/Invert_4x.png) center/cover no-repeat;
      width: 63px;
      height: 70px;
      margin-left: 20px !important;
      cursor: pointer;

      @include on-print {
        display: none;
      }

      &.invert-hidden {
        display: none;
      }
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      @include on-tablet {
        margin-left: 40px;
      }
    }


    /* Print */
    @include on-print {

      .cartouche-nombres-parent {
        margin-top: 40px;
      }

      :deep(.card .card-content > div > div) {
          width: 90px;
      }
    }

  }

</style>
