<template>
  <div class="content content-v2">
    <main
        :class="desSettingsCssClass"
    >

      <h1>Slots</h1>

      <div class="interaction">
        <div class="flex flex-column flex-gap-20">

          <div class="flex flex-gap-20">
            <Slots :chiffre='123456' :hasValidation=true />
            <Slots :chiffre='12' :hasValidation=true />
          </div>

          <div class="flex flex-gap-20">
            <Slots :chiffre='123456' :hasHeader=true />
            <Slots :chiffre='1234' :isHidden=true :hasHeader=true />
          </div>

          <Slots :chiffre='1234' :isHidden=true :hasFooter=true ref="mySlot" />
          <Slots :chiffre='1234' :isHidden=true :hasFooter=true :withoutColors="true" />
          <Slots :chiffre='1'  />
          <Slots :chiffre='9' decomposition-mininum="3"  />
          <Slots :chiffre='15'  />
          <Slots :chiffre='97'  />
          <Slots :chiffre='158'  />
          <Slots :chiffre='987'  />
          <Slots :chiffre='1999'  />
          <Slots :chiffre='12345'  />
          <Slots :chiffre='123456'  />
          <Slots :isHidden=true :chiffre='1999'  />
          <Slots :isHidden=true :chiffre='12345'  />
          <Slots :isHidden=true :without-colors=true :chiffre='12345'  />
          <Slots :isHidden=true :chiffre='12345' :validation="1"  />
          <Slots :isHidden=true :chiffre='12345' :validation="-1"  />
        </div>
      </div>

    </main>
  </div>
</template>

<script setup>

  import {onMounted, ref} from "vue";
  import { useStore } from 'vuex';
  import Slots from "../../../components/v2/Slots";

  const store = useStore();

  // Style des dés (forme, couleur)
  let desSettingsCssClass = store.getters.desSettingsCssClass;

  const mySlot = ref(null);

  onMounted(() => {
    mySlot.value.setSlotVisible(1, true);
  });


</script>
