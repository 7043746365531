<template>

  <div class="drop-zones-dizaines-unites">

    <div class="espace-de-jeu">
      <div class="colonne-parent colonne-gauche">
        <DropZoneDizainesUnites
            ref="refZone1"
            class="drop-zone-de"
            :chiffre="refChiffreZone1"
            @change="updateDraggables"
        />
      </div>
      <div class="colonne-parent colonne-droite">
        <DropZoneDizainesUnites
            ref="refZone2"
            class="drop-zone-de"
            :chiffre="refChiffreZone2"
            @change="updateDraggables"
        />
      </div>
    </div>

    <!-- Palettes -->
    <div class="palettes" v-if="avecPalette">
      <PaletteDizainesUnites />
      <PalettePoubelle
          class="poubelle drop-zone-de"
          @supprimer="viderZones()"
      />
    </div>

    <!-- Boîtes -->
    <div class="boites flex" v-else>
      <div class="boite-dizaines">
        <BoiteDizaines
            ref="refBoiteDizaines"
            :unites="unitesBoiteDizaines"
            @dizaineCreated="dizaineCreated"
            @restoreUnites="restoreUnites"
        />
      </div>
      <div class="boite-unites">
        <BoiteUnites />
      </div>
    </div>

  </div>

</template>

<script setup>

  import { ref, watch, onMounted, defineExpose } from "vue";

  import {gsap} from "gsap";
  import $ from "jquery";
  import { Draggable } from "gsap/Draggable";
  gsap.registerPlugin(Draggable);

  import {getSoundPath_Chiffre, playSound} from "../../../js/utils";
  import DropZoneDizainesUnites from "../Zones/DropZoneDizainesUnites";
  import PalettePoubelle from "../Palettes/PalettePoubelle";
  import PaletteDizainesUnites from "../Palettes/PaletteDizainesUnites";
  import BoiteDizaines from "../Boites/BoiteDizaines";
  import BoiteUnites from "../Boites/BoiteUnites";


  //
  // Propriétés du composant
  //

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 0
    },
    avecPalette: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  defineExpose({
    addDraggableBehavior
  })

  function addDraggableBehavior() {
    setTimeout( updateDraggables, 100);
  }


  //
  // Reactive values
  //

  // Références des 2 zones (cf DOM : "ref")
  const refZone1 = ref();
  const refZone2 = ref();

  // Références du nombre dans les 2 zones
  const refChiffreZone1 = ref(22);
  const refChiffreZone2 = ref(10);

  // Boite de création de dizaines
  const refBoiteDizaines = ref();
  const unitesBoiteDizaines = ref(0);



  //
  // Lifecycle hooks
  //

  onMounted(() => {

    update();

    // Le temps que Vue modifie le DOM (pour que JQuery puisse retrouver les nouveaux éléments)
    setTimeout( initDraggables, 100);

  });

  watch( props, () => {
    update();
  });

  function update() {
    refChiffreZone1.value = props.chiffre;
    refChiffreZone2.value = 0;
    refZone2.value.clear();
  }


  //
  // Drag and Drop
  //

  const overlapThreshold = "50%";

  const addDraggableTo = function( $target ) {

    $target.addClass('draggable-initialized');

    Draggable.create( $target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");

        // Pour la restauration de la position, via la boite de dizaine, on stocke la position d'origine' :
        const $target = draggableElement[0];
        const tx = parseInt(draggableElement.css('left')) + gsap.getProperty($target, 'x');
        const ty = parseInt(draggableElement.css('top')) + gsap.getProperty($target, 'y');
        draggableElement.attr('left', tx);
        draggableElement.attr('top', ty);

        var nombre_drag = draggableElement.find('.with-value').attr('data-value');
        var soundPath = getSoundPath_Chiffre(nombre_drag);
        playSound(soundPath);

        // Le temps du drag, on retire la valeur de la colonne:
        const draggableIndex = draggableElement.attr("data-index");
        if (draggableIndex && draggableIndex.length) {
          if (draggableElement.closest(".colonne-gauche").length) {
            refZone1.value.startDragChiffre(draggableIndex);
          } else if (draggableElement.closest(".colonne-droite").length) {
            refZone2.value.startDragChiffre(draggableIndex);
          }
        }
      },
      onDragStart: function(pointEvent) {
        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = draggableElement.find('.with-value').attr('data-value');
        var soundPath = getSoundPath_Chiffre(nombre_drag);
        playSound(soundPath);
      },
      onDragEnd: function(pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var valeur_drag = draggableElement.find('.with-value').attr('data-value');

        var dropZones = $(".drop-zone-de");
        var i = dropZones.length;
        var dropZone;
        var dropZoneFound = false;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold))
          {
            dropZoneFound = checkZone(draggableElement, dropZone, valeur_drag);
          }
        }

        if (! dropZoneFound) {
          outOfZone(draggableElement, this.target)
        }

        // Le temps que Vue modifie le DOM (pour que JQuery puisse retrouver les nouveaux éléments)
        setTimeout(updateDraggables, 100);
      },
      onDrag: function() {

        var dropZones = $(".drop-zone-de");
        var i = dropZones.length, dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });
  };

  const initDraggables = function() {
    const $target = $(".drop-zones-dizaines-unites .draggable").not('.draggable-initialized');
    addDraggableTo($target);
  };

  const updateDraggables = function() {
    const $target = $(".drop-zones-dizaines-unites .draggable").not('.draggable-initialized');
    addDraggableTo( $target );
  }

  // Boite des dizaines : création d'une dizaine
  const dizaineCreated = function() {
    updateDraggables();
  }

  // Boite des dizaines : annulation de la création d'une dizaine
  const restoreUnites = function(eventObj) {

    const unites_a_recreer = eventObj.unites_par_zones;
    const n = unites_a_recreer.length;
    let i, unite_a_recreer, refZone;
    for(i=0;i<n; i++) {
      unite_a_recreer = unites_a_recreer[i];
      refZone = unite_a_recreer.zone === "zone1" ? refZone1:refZone2;
      refZone.value.addChiffre(1, unite_a_recreer.x, unite_a_recreer.y);
    }

    // Le temps que Vue modifie le DOM (pour que JQuery puisse retrouver les nouveaux éléments)
    setTimeout(updateDraggables, 100);
  }



  //
  // Fonctions du composant
  //

  function outOfZone( draggableElement, target ) {
    console.log(draggableElement, target)
    // Retour à la position initiale des dés de la palette
    gsap.set( target, { x:0, y:0, delay:0.1 });
  }

  function checkZone( draggableElement, dropZone, valeur_drag ) {

    const isDropOnColonneGauche = dropZone.closest('.colonne-gauche').length;
    const isDropOnColonneDroite = dropZone.closest('.colonne-droite').length;
    const isDropOnBoiteUnites   = dropZone.closest('.boite-unites').length;
    const isDropOnBoiteDizaines = dropZone.closest('.boite-dizaines').length;
    const isDropOnPoubelle = dropZone.closest('.poubelle').length;

    console.log(isDropOnBoiteUnites, isDropOnBoiteDizaines, valeur_drag);

    const ajout = valeur_drag === "unite" ? 1 : 10;

    // Le temps du drag, on retire la valeur :
    const draggableIndex = draggableElement.attr("data-index");

    const draggableElementPosition = draggableElement.offset();
    const colonneGauchePosition = $('.colonne-gauche').offset();
    const colonneDroitePosition = $('.colonne-droite').offset();

    // console.log(draggableElementPosition, colonneGauchePosition, colonneDroitePosition);

    let tx, ty;

    if (draggableIndex && draggableIndex.length)
    {
      // 1. Déplacement d'un dé appartenant à une zone :
      const draggableIndexInt = parseInt(draggableIndex);

      if (draggableElement.closest(".colonne-gauche").length)
      {
        // 1.1 Déplacement d'un dé de la colonne gauche

        if (isDropOnColonneGauche)
        {
          // Si on le déplace sur la colonne gauche (simple drag) :
          refZone1.value.stopDragChiffre(draggableIndexInt);
        }
        else if (isDropOnColonneDroite)
        {
          // Si on le déplace sur la colonne droite (suppression à gauche / ajout à droite ) :
          refZone1.value.removeChiffre(draggableIndexInt);

          tx = draggableElementPosition.left - colonneDroitePosition.left;
          ty = draggableElementPosition.top - colonneDroitePosition.top;
          refZone2.value.addChiffre(ajout, tx, ty);
        }
        else if (isDropOnPoubelle)
        {
          refZone1.value.removeChiffre(draggableIndexInt);
        }
        else if (isDropOnBoiteUnites)
        {
          if (valeur_drag === "dizaine")
          {
            // Création de 10 unités
            refZone1.value.addDixUnites();
            refZone1.value.removeChiffre(draggableIndexInt);
          }
          else
          {
            // Renverra le dé à sa position initiale (sur la palette)
            return false;
          }
        }
        else if (isDropOnBoiteDizaines)
        {
          console.log(valeur_drag)
          if (valeur_drag === "unite")
          {
            // Ajout à la boite dizaines
            refZone1.value.removeChiffre(draggableIndexInt);

            const tx = draggableElement.attr('left');
            const ty = draggableElement.attr('top');
            refBoiteDizaines.value.addUniteFromZone("zone1", tx, ty);
          }
          else
          {
            // Renverra le dé à sa position initiale (sur la palette)
            return false;
          }
        }
      }
      else if (draggableElement.closest(".colonne-droite").length)
      {
        // 1.2 Déplacement d'un dé de la colonne droite

        if (isDropOnColonneDroite)
        {
          // Si on le déplace sur la colonne gauche (simple drag) :
          refZone2.value.stopDragChiffre(draggableIndexInt);
        }
        else if (isDropOnColonneGauche)
        {
          // Si on le déplace sur la colonne droite (suppression à gauche / ajout à droite ) :
          refZone2.value.removeChiffre(draggableIndexInt);

          tx = draggableElementPosition.left - colonneGauchePosition.left;
          ty = draggableElementPosition.top - colonneGauchePosition.top;
          refZone1.value.addChiffre(ajout, tx, ty);
        }
        else if (isDropOnPoubelle)
        {
          refZone2.value.removeChiffre(draggableIndexInt);
        }
        else if (isDropOnBoiteUnites)
        {
          if (valeur_drag === "dizaine")
          {
            // Création de 10 unités
            refZone2.value.addDixUnites();
            refZone2.value.removeChiffre(draggableIndexInt);
          } else {
            // Renverra le dé à sa position initiale (sur la palette)
            return false;
          }
        }
        else if (isDropOnBoiteDizaines)
        {
          if (valeur_drag === "unite")
          {
            // Ajout à la boite dizaines
            refZone2.value.removeChiffre(draggableIndexInt);

            const tx = draggableElement.attr('left');
            const ty = draggableElement.attr('top');
            refBoiteDizaines.value.addUniteFromZone("zone2", tx, ty);

          } else {
            // Renverra le dé à sa position initiale (sur la palette)
            return false;
          }
        }
      }

      return true;
    }
    else
    {
      // 2. Déplacement d'une dizaine ou unité de la palette ( ajout )
      if (isDropOnColonneGauche)
      {
        // Si on le déplace sur la colonne gauche (simple drag) :
        tx = draggableElementPosition.left - colonneGauchePosition.left;
        ty = draggableElementPosition.top - colonneGauchePosition.top;
        refZone1.value.addChiffre(ajout, tx, ty);

        if (draggableElement.hasClass('from-boite-dizaine')) {
          refBoiteDizaines.value.resetUnites();
        }
      }
      else if (isDropOnColonneDroite)
      {
        // Si on le déplace sur la colonne droite (suppression à gauche / ajout à droite ) :
        tx = draggableElementPosition.left - colonneDroitePosition.left;
        ty = draggableElementPosition.top - colonneDroitePosition.top;
        refZone2.value.addChiffre(ajout, tx, ty);

        if (draggableElement.hasClass('from-boite-dizaine')) {
          refBoiteDizaines.value.resetUnites();
        }
      }

      // Renverra le dé à sa position initiale (sur la palette)
      return false;
    }
  }

  // Effet sur la carte ou le dé
  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  const viderZones = function() {
    refChiffreZone1.value = 0;
    refChiffreZone2.value = 0;
    refZone2.value.clear();
  };


</script>

<style scoped lang="scss">

  .espace-de-jeu {

    display: flex;

    width: 568px; /* 635px */
    height: 512px;

    /* Light Grey/95 - 100 */
    background: #F2F2F2;

    /* Colors / Light Yellow */
    border: 4px solid #FFD32C;
    border-radius: 16px;

    @include on-small-desktop {
      width: 540px;
    }

    @include on-print {
      width: 500px;
    }

    .colonne-gauche {
      flex: 50% 0 0;
      /* Dark Grey/40 - 100 */
      border-right: 4px dashed rgba(102, 102, 102, 0.3);
    }

    .colonne-droite {
      flex: 50% 0 0;
    }
  }

  .palettes {
    position: fixed;
    bottom: 0;
    left:calc(50% - 550px + 450px);
    transform: translateX(178px);

    display: flex;
  }

  .boites {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
  }

</style>
