<template>
    <a class="ecouter-btn" href="#" @click="ecouter($event)">
        <span  class="icone afficher-des" ></span>
    </a>
</template>

<script>
    export default({
        methods: {
          ecouter($event){
                $event.preventDefault();
                this.$emit("playSound");
            }
        }

    })
</script>


<style scoped lang="scss">

a.ecouter-btn {
  display: block;
  width: 241px;
  height: 70px;
  background: url(../../../assets/images/svg/boutons_v2/Listen_4x.png) center / contain no-repeat;

  @include on-print {
    display: none;
  }
}

</style>
