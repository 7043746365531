<template>
  <div class="content" :class="settingsCssClass">
    <HeaderActivite @toggle-settings="toggleSettings" />
    <main
        class="activite table-de-multiplication"
        :class="desSettingsCssClass"
    >
      <!-- Bouton header -->
      <div class="activite-header">
        <!-- Recommencer -->
        <ReplayBtn />
        <!-- Démo -->
        <DemoBtn />
        <!-- Pas de saisie manuelle -->
      </div>

      <h1>Les tables de multiplication</h1>

      <div class="introduction">

        <h2>Sélectionne une table</h2>

        <div class="choix-table">
          <div class="choix-table-groupe">
            <div class="de-parent">
              <a href="#" data-value="1" class="de chiffre1 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="2" class="de chiffre2 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="3" class="de chiffre3 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="4" class="de chiffre4 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="5" class="de chiffre5 choix-table-de"></a>
            </div>
          </div>
          <div class="choix-table-groupe">
            <div class="de-parent">
              <a href="#" data-value="6" class="de chiffre6 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="7"  class="de chiffre7 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="8"  class="de chiffre8 choix-table-de"></a>
            </div>
            <div class="de-parent">
              <a href="#" data-value="9"  class="de chiffre9 choix-table-de"></a>
            </div>
            <div class="de-parent double">
              <a href="#" data-value="10" class="de chiffre10 choix-table-de"></a>
            </div>
          </div>
        </div>
      </div>

      <div class="interaction">

        <h2></h2>

        <div class="elements"></div>
        <div class="regle"></div>

        <!-- pour démo -->
        <div class="souris"></div>

      </div>

    </main>
    <Footer />
    <SettingsPanel :class="settingsCssClass" />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v1/HeaderActiviteV1";
  import Footer from "../../../components/v1/FooterActivite";
  import SettingsPanel from "../../../components/v1/SettingsPanel"
  import ReplayBtn from "../../../components/v1/Boutons/ReplayBtn";
  import DemoBtn from "../../../components/v1/Boutons/DemoBtn";

  import { ref, onMounted } from "vue";
  import { useStore } from 'vuex';
  import $ from "jquery";

  import {
    getTableMultiplication,
    getSoundPath_Multiplication,
    getSoundPath_Chiffre,
    playSound,
    playSequence,
    stopSequence,
    stopAllAnimations,
    stopAllSounds,
    getPDF,
    addSVGToPDF,
    getURLFromBackgroundImageStyle
  } from "../../../js/utils";

  import {
    showTools,
    hideTools,
    initFullscreen
  } from "../../../js/common";

  const store = useStore();

  let activite;
  let outils;
  let regle;
  let introduction;
  let interaction;
  let souris;
  let settingsPanel;

  //
  let tableCourante;

  // Style des dés (forme, couleur)
  let desSettingsCssClass = store.getters.desSettingsCssClass;

  onMounted(() => {

    activite = $(".activite");
    regle = $(".regle");
    outils = $(".outils");
    introduction = $(".introduction");
    interaction = $(".interaction");
    souris = $(".souris");
    settingsPanel = $(".settings-panel");


    //
    // Initializations
    //

    // Event delegation pattern :
    activite.on("click", function(e)
    {
      e.preventDefault();

      var target = $(e.target);

      if (target.is(".choix-table .de"))
      {
        selectTable( target );
      }
      else if (target.is(".table-multiplication a.chiffres"))
      {
        stopDemo();
        resetActivite();
        selectMultiplication( target );
      }
      else if (target.is(".table-multiplication .de"))
      {
        selectDeMultiplication( target );
      }
      else if (target.is(".demo"))
      {
        demo( target );
      }
      else if (target.is(".replay"))
      {
        replay( target );
      }
    });

    activite.on("replay", function(e)
    {
      e.preventDefault();
      replay();
    });

    activite.on("stopDemo", function(e)
    {
      e.preventDefault();
      stopDemo();
    });

    $('.print-link').on("click", function(e)
    {
      e.preventDefault();
      printAsPDF();
    });


    /* Première initialisation au chargement de la page */

    introduction.css("display", "block");
    interaction.css("display", "none");

    /* Première initialisation au chargement de la page */

    // initSettingsEvents();
    // restoreSettings();

    initFullscreen();
    hideTools();

  });



  //
  // 0. Fonctions spécifiques à l'activité
  //

  function initActivite(table) {

    // Suppression d'une précédente initialisation

    $('.table-multiplication').remove();

    $(".de-parent").removeClass("selected");
    $(".de-parent").removeClass("inactive");


    // Initialisation de l'activité
    interaction.removeClass('double');
    if (table > 9) {
      interaction.addClass('double');
    }

    $(".elements").append( getTableMultiplication(table) );
  }

  // Parse la chaîne AxB en un tableau [A, B]
  function getChiffres( element ) {
    var multiplicationStr = element.text().toLowerCase();
    // console.log("getChiffres --> multiplicationStr", multiplicationStr, element.length);
    multiplicationStr = multiplicationStr.split(" ").join("");
    return multiplicationStr.split("x");
  }


  //
  // 1. Interactivité Introduction
  //

  function selectTable( target ) {

    var table = target.data('value');

    tableCourante = table;

    introduction.css("display", "none");
    outils.css("display", "none");

    interaction.css("display", "block");
    regle.css("display", "none");
    souris.css("display", "none");

    $('h2', interaction).text('La table de multiplication par ' + table);

    showTools();
    initActivite(table);
  }

  //
  // 2. Interactivité Activité
  //

  function selectMultiplicationAndMoveRegle( target ) {

    $('.de-outer-background').removeClass("with-border  first-with-background last-with-background with-background");
    $('.multiplication-et-de').removeClass("last-active");

    var multiplication = getChiffres( target );
    var multiplicateur = parseInt( multiplication[0] );
    // var table = parseInt( multiplication[1] );

    // On retire les éventuels selections
    $('.de-parent').removeClass('selected inactive first-with-background last-with-background with-background');

    // On rend inactif les dés au-delà de la règle
    $(".table-multiplication .de").each(function(no, item) {

      var de = $(item);
      var deParent = de.closest('.de-parent');
      deParent.removeClass('inactive');

      var deOuterBackground = de.closest('.de-outer-background');
      var deMultiplicationEtDe = de.closest('.multiplication-et-de');

      var multiplicateur_item = de.data('param');
      if (multiplicateur_item > multiplicateur){
        deParent.addClass('inactive');
      } else {
        deOuterBackground.addClass('with-border');
        if (no + 1 ===  multiplicateur) {
          deMultiplicationEtDe.addClass('last-active');
        }
      }

    });

    // On passe le lien en actif (bleu clair)
    $('.chiffres').removeClass("active");
    target.addClass("active");

    // On insère la règle sous le dé :
    regle.css("display", "block");
    regle.remove();
    $(target).closest('.multiplication-et-de').after(regle);
  }


  // Click sur un lien de type 1x2, 2x2....

  function selectMultiplication( target ) {

    selectMultiplicationAndMoveRegle(target);

    var multiplication = getChiffres( target );
    var multiplicateur = parseInt( multiplication[0] );
    var table = parseInt( multiplication[1] );

    console.log( multiplicateur, 'x', table );

    var soundPath = getSoundPath_Multiplication(table, multiplicateur);
    playSound(soundPath);
  }

  // Click sur un dé

  function selectDeMultiplication( target ) {

    var parentTarget = $(target).closest('.multiplication-et-de');

    // Le lien le plus proche :
    target = parentTarget.find('.chiffres');

    selectMultiplicationAndMoveRegle(target);

    var deTarget = $(target);
    // var deParentElement = deTarget.closest(".de-parent");
    var deElement = deTarget.closest('.multiplication-et-de').children("a.chiffres");
    var multiplication = getChiffres(deElement);
    var multiplicateur = parseInt( multiplication[0] );
    var table = parseInt( multiplication[1] );
    var multiple = multiplicateur * table;

    var soundPath = getSoundPath_Chiffre(multiple);
    playSound(soundPath);

  }


  //
  // 3. Démo Activité
  //

  var sequenceActions;

  function demo() {

    stopDemo();
    resetActivite();

    //
    // 2 x 2
    //

    var table = tableCourante;
    // var multiplicateur = 2;

    var interaction = $('.interaction');
    var positionInteraction = interaction.offset();

    // Lien : 2 x table
    var link = $('.chiffres.2x' + table);
    console.log("link", link);

    var position = link.offset();
    var left = position.left - positionInteraction.left + 0.5 * link.width();
    var top  = position.top  - positionInteraction.top + link.height();

    // Dés : 1 et 2
    var de1 = $(".de[data-param=1]");
    var positionDe1 = de1.offset();
    var leftDe1 = positionDe1.left - positionInteraction.left + 0.5 * de1.width();
    var topDe1  = positionDe1.top  - positionInteraction.top  + 0.5 * de1.height();

    var de2 = $(".de[data-param=2]");
    var positionDe2 = de2.offset();
    var leftDe2 = positionDe2.left - positionInteraction.left + 0.5 * de2.width();
    var topDe2  = positionDe2.top  - positionInteraction.top  + 0.5 * de2.height();

    // console.log(positionInteraction, position)

    souris.css("display", "block");
    souris.css("opacity", 1);
    regle.css("display", "none");

    playSequence( sequenceActions = [
      { action : "setElement", param : { element:souris , left: left + 50, top: top + 100 } },
      { action : "moveElement", param : { element:souris , left: left, top: top } },
      { action : "callback", param : function() { selectMultiplication( link ); } },
      { action : "wait", param : 2 },
      { action : "moveElement", param : { element:souris , left: leftDe1, top: topDe1 } },
      { action : "hilite", param : de1 },
      { action : "playSound", param : getSoundPath_Chiffre(table * 1) },
      { action : "unhilite", param : de1 },
      { action : "wait", param : 1.25 },
      { action : "moveElement", param : { element:souris , left: leftDe2, top: topDe2 } },
      { action : "hilite", param : de2 },
      { action : "playSound", param : getSoundPath_Chiffre(table * 2) },
      { action : "unhilite", param : de2 },
      { action : "wait", param : 1.25 },
      { action : "fadeOutElement", param : { element:souris } },
      { action : "callback", param : resetActivite },
    ]);
  }

  function stopDemo() {
    stopSequence(sequenceActions);
    stopAllAnimations();
    stopAllSounds();
  }


  //
  // 4. Rejouer Activité
  //

  function replay() {

    stopDemo();
    resetActivite();

    activite.css("display", "block");
    introduction.css("display", "block");
    interaction.css("display", "none");
    settingsPanel.css("display", "none");

  }

  function resetActivite() {

    $('.chiffres').removeClass('active');
    $('.de').css('opacity', 1);

    souris.css("display", "none");
    regle.css("display", "none");

    $('.de-outer-background').removeClass("with-border first-with-background last-with-background with-background");
    $('.multiplication-et-de').removeClass("last-active");

    $('.de-parent').removeClass('selected inactive');
  }

  //
  // 5. PDF
  //

  function printAsPDF() {

    var des = $(".de", ".table-multiplication");
    if (des.length === 0) return;

    var doc = getPDF('portrait');

    var scale = 0.5;
    var position_x  = 100;
    var position_y  = 40; // first y

    var positions_y = [];
    var offset_y = 20;

    var item = des.first();
    var value = item.data('value');
    var url = getURLFromBackgroundImageStyle(item);

    // Titre
    doc.setFontSize(30);
    doc.text(5, 20, 'La table de multiplication par ' + value);

    for( var i = 0; i < 10; i++)
    {
      positions_y.push( position_y + i * offset_y );
    }

    $.ajax({
      url: url,
      success: function( svgText ) {

        addSVGToPDF( doc, svgText, position_x, positions_y[0], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[1], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[2], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[3], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[4], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[5], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[6], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[7], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[8], "de", true, scale);
        addSVGToPDF( doc, svgText, position_x, positions_y[9], "de", true, scale);

        doc.setFontSize(16);

        for( var i = 0; i < 10; i++)
        {
          doc.text( position_x - 30, positions_y[i] + 9, (i+1) + " x " + value );
        }

        doc.setFontSize(14);
        doc.setTextColor('#CCCCCC');
        doc.text(5, 285, "2019 © Cognitice");

        doc.save('table_multiplication.pdf');

      }
    });
  }

  //
  // 7. Settings
  //

  let settingsCssClass = ref('');

  const toggleSettings = function(bool) {
    if (bool) {
      $('.activite').trigger("stopDemo");
      settingsCssClass.value = "settings-opened";
    } else {
      $('.activite').trigger("replay");
      settingsCssClass.value = "";
    }

  };

</script>

<style lang="scss">

  .table-de-multiplication {


    .introduction {
      margin-top: 42px;
    }

    .interaction {
      margin-left: 100px;
    }

    .interaction h2 {
      margin-bottom: 40px;
      text-align: center;
    }

    .table-multiplication .de-outer-background {
      display: inline-block;
    }

    .table-multiplication .de-outer-background.active {
      background-color: #EEEEEE;
    }

    .choix-table-groupe .de-parent {
      display: inline-block;
    }

    .de-bille .multiplication-et-de .de-parent,
    .de-bille .multiplication-et-de .de-parent.double,
    .de-bille .choix-table-groupe .de-parent,
    .de-bille .choix-table-groupe .de-parent.double {
      width: 168px;
      height: 79px;
    }

    .de-bille .multiplication-et-de .de-parent.de-fond-bleu,
    .de-bille .choix-table-groupe .de-parent.de-fond-bleu {
      background-color: #142896;
    }

    .de-bille .multiplication-et-de .de-parent .de,
    .de-bille .choix-table-groupe .de-parent a {
      border-radius: 12px;
    }

    .choix-table-groupe .de {
      display: block;
    }

    .interaction .elements  {
      width: 550px;
      margin: 0 auto;
      max-height: calc( 100vh - 250px );
      overflow: scroll;
    }

    .interaction.double .elements  {
      width: 650px;
    }

    .regle {
      width: 450px;
      height: 68px;
      margin: 10px auto;
    }


    .interaction .elements .table-multiplication {
      padding-bottom: 20px;
    }


    .interaction .elements .table-multiplication a.chiffres {
      display: inline-block;
      vertical-align: top;
      text-align: right;
      width: 100px;
      padding: 10px 40px 10px 0;
      margin-top: 42px;
      text-decoration: none;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
    }

    .interaction .elements .table-multiplication a.chiffres:hover,
    .interaction .elements .table-multiplication a.chiffres.active {
      color: #3B8FF2;
    }

    .interaction .elements .multiplication-et-de {
    }

    .interaction .elements .multiplication-et-de .de-parent {
      margin-bottom: 0;
    }

    .interaction .elements .multiplication-et-de.last-active .de-parent {
      margin-bottom: 0;
    }

    .interaction .elements .multiplication-et-de {
      margin-bottom: -5px;
    }

    .interaction .elements .multiplication-et-de.last-active {
      margin-bottom: 0;
    }


    /* Permet d'entourer les blocs de la multiplication choisie : n'est plus utilisée */

    .interaction .elements .multiplication-et-de .de-outer-background.with-border {
      background-color: #b5b8c8;
    }

    .interaction .elements .multiplication-et-de:first-child .de-outer-background.with-border {
      border-radius:  16px 16px 0 0;
    }

    .interaction .elements .multiplication-et-de:first-child.last-active .de-outer-background.with-border {
      border-radius:  16px;
    }

    .interaction .elements .multiplication-et-de .de-outer-background.with-border {
    }

    .interaction .elements .multiplication-et-de.last-active .de-outer-background.with-border {
      border-radius: 0 0 16px 16px;
      padding-bottom: 16px;
    }

  }

</style>
