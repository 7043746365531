<template>
  <a class="demo activite-demo" href="#" title="démo">
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" transform="translate(0,2)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33325 1L10.6666 7L1.33325 13V1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Démo
  </a>
</template>

<script setup>
</script>

<style scoped lang="scss">
</style>
