<template>
    <router-link
        to="/sommaire"
        class="commencer-accueil-btn"
    >
    </router-link>
</template>

<style scoped lang="scss">

a.commencer-accueil-btn {
  display: block;
  width: 249px;
  height: 78px;
  min-height: 78px;
  background: url(../../../assets/images/svg/boutons_v2/Commencer_accueil_4x.png) center / 249px auto no-repeat;
  text-decoration: none;

  @include on-mobile-landscape {
    background-size: contain;
    background-position: left center;
    width: 180px;
    height: 45px;
    min-height: 45px;
  }
}

</style>
