<template>

  <div class="repertoire-additif-v2">

    <div :class="cssClassName">

      <!-- Dés -->
      <div class="cards-parent components-parent not-draggable" :class='isActive ? "is-active": ""'>
        <Card
            :chiffre="refChiffre1"
            :is-hidden="refIsMissing1 && refMissingIsFound"
            :has-show-hidden-toggle="withValidation"
            :code = "code"
            :css-class="formeDes"
            :has-separator="separateurMilliers"
            :with-sound="withSound"
        />
        <div class="operation">
          <SignePlus v-if="operationActivite === '+'" />
          <SigneMoins v-else-if="operationActivite === '-'"  />
        </div>
        <Card
            :chiffre="refChiffre2"
            :is-hidden="refIsMissing2 && refMissingIsFound"
            :has-show-hidden-toggle="withValidation"
            :code = "code"
            :css-class="formeDes"
            :has-separator="separateurMilliers"
            :with-sound="withSound"
        />
        <div class="operation egal">
          <SigneEgal />
        </div>
        <Card
            :chiffre="refChiffre3"
            :is-hidden="refIsMissing3 && refMissingIsFound"
            :has-show-hidden-toggle="withValidation"
            :code = "code"
            :css-class="formeDes"
            :has-separator="separateurMilliers"
            :with-sound="withSound"
        />
      </div>

      <!-- Chiffres -->
      <div class="slots-parent components-parent" >
        <Slots
            ref="slots1"
            data-slots="1"
            :class="slotCssClassName"
            :chiffre="refChiffre1"
            :is-hidden="refIsMissing1"
            :has-validation="refIsMissing1 && withValidation"
            @change="slotChanged"
        />
        <div class="operation">
          <SignePlus v-if="operationActivite === '+'" />
          <SigneMoins v-else-if="operationActivite === '-'" />
        </div>
        <Slots
            ref="slots2"
            data-slots="2"
            :class="slotCssClassName"
            :chiffre="refChiffre2"
            :is-hidden="refIsMissing2"
            :has-validation="refIsMissing2 && withValidation"
            @change="slotChanged"
        />
        <div class="operation egal">
          <SigneEgal />
        </div>
        <Slots
            ref="slots3"
            data-slots="3"
            :class="slotCssClassName"
            :chiffre="refChiffre3"
            :is-hidden="refIsMissing3"
            :has-validation="refIsMissing3 && withValidation"
            @change="slotChanged"
        />
      </div>

      <div v-if="niveauActivite === 6"
           class="eye-button"
           :class='isSelected ? "is-selected":""'
           @click="clickDe">
        <a class="" />
      </div>

    </div>

    <ClavierChiffres
        class="clavier"
        ref="clavier"
        :avec-gommettes="showGommette"
        :nb-touches-par-ligne="10"
    />

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne"/>

  </div>

</template>

<script setup>

  import {onMounted, watch, ref, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery';
  import _ from 'lodash';
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Card from "../Card";
  import Slots from "../Slots";
  import Ada from "../../../components/v2/Ada";
  import SignePlus from "../Signes/SignePlus";
  import SigneMoins from "../Signes/SigneMoins";
  import SigneEgal from "../Signes/SigneEgal";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();


  // Selon niveau :
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 9;
  var pasDeRetenue = false;

  const overlapThreshold = "50%";
  let isDragging = false;
  let draggables;
  let selectedDraggable;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    operation: {
      type: String,
      required: false,
      default: "+"
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    exerciceATrou: {
      type: Boolean,
      required: false,
      default: false
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },

  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  let isActive = ref(true);
  let isHidden = ref(null);
  let isSelected = ref(true);


  // Le composant sert de base à plusieurs activités :
  // - Repertoire Additif
  // - Repertoire soustractif

  // Clavier
  let clavier = ref(null);

  // Addition ou Soustraction ?
  let operationActivite = ref(props.operation).value;


  // Référence du composant Slots :
  const slots1 = ref(null);
  const slots2 = ref(null);
  const slots3 = ref(null);

  // Code :
  const code = ref(null);

  let refChiffre1 = ref(); // Int
  let refChiffre2 = ref();
  let refChiffre3 = ref();

  let refIsMissing1 = ref(); // Boolean
  let refIsMissing2 = ref();
  let refIsMissing3 = ref();

  let refMissingIsFound = ref(); // Boolean

  const cssClassName = computed(() => {
    return [
      'niveau-' + niveauActivite.value,
      refIsMissing3.value ? 'align-from-top' : 'align-from-bottom'
    ].join(' ');
  });

  const slotCssClassName = computed(() => {
    return [
      niveauActivite.value < 5 ? "width-106" : "width-86",
      niveauActivite.value < 4 ? "vertical-offset-for-flex" : "",
      niveauActivite.value < 4 ? "" : "validation-float-right"
    ].join(' ');
  });

  const showGommette = computed(() =>  {
    return niveauActivite.value > 0;
  })


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }



  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch (niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 50;
        successMax.value = 8;
        code.value = "simple";
        pasDeRetenue = true;
        break;

      case 2:
        currentMinimumNumber = 50;
        currentMaximumNumber = 99;
        successMax.value = 8;
        code.value = "simple";
        pasDeRetenue = true;
        break;

      case 3:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        successMax.value = 4;
        code.value = "complex";
        pasDeRetenue = false;
        break;

      case 4:
        currentMinimumNumber = 1000;
        currentMaximumNumber = 9999;
        successMax.value = 4;
        code.value = "complex";
        pasDeRetenue = false;
        break;

      case 5:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        successMax.value = 4;
        code.value = "complex";
        pasDeRetenue = false;
        break;

      case 6:
        currentMinimumNumber = 100000;
        currentMaximumNumber = 999999;
        successMax.value = 4;
        code.value = "complex";
        pasDeRetenue = false;
        break;
    }

    // Ada
    success = 0;

    refMissingIsFound.value = true;

    if (! niveau) console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  }

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 50) {
      return 1;
    } else if (maxValue < 100) {
      return 2;
    } else if (maxValue < 1000) {
      return 3;
    } else if (maxValue < 10000) {
      return 4;
    } else if (maxValue < 100000) {
      return 5;
    } else {
      return 6;
    }
  }


  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    // Loupe
    refMissingIsFound.value = true;

    if (clavier.value) {
      clavier.value.resetGommettes();
    }

    var missingZone, total, chiffre1, chiffre2;

    if ((chiffres === null) || (chiffres === undefined))
    {

      // Tirage au sort du total
      total = randomIntFromInterval( Math.floor(currentMaximumNumber/2), currentMaximumNumber);

      // console.log( currentMinimumNumber, currentMaximumNumber, 'total =', total);

      // Cas particulier "pas de retenue"
      if (pasDeRetenue && (total >= 20)) {

        const nbDizaines = Math.floor(total / 10);
        const nbUnites = total - nbDizaines * 10;

        let nbUnitesChiffre1, nbUnitesChiffre2, nbDizainesChiffre1, nbDizainesChiffre2;

        if (nbUnites === 0)
        {
          // Cas particulier du cas particulier "pas de retenue" : 20, 30, 40, 50
          nbUnitesChiffre1 = nbUnitesChiffre2 = 0;
          nbDizainesChiffre1 = randomIntFromInterval(1, nbDizaines - 1);
          nbDizainesChiffre2 = nbDizaines - nbDizainesChiffre1;

        } else {

          nbUnitesChiffre1 = randomIntFromInterval(1, nbUnites);
          nbUnitesChiffre2 = nbUnites - nbUnitesChiffre1;

          nbDizainesChiffre1 = randomIntFromInterval(0, nbDizaines);
          nbDizainesChiffre2 = nbDizaines - nbDizainesChiffre1;
        }

        chiffre1 = 10 * nbDizainesChiffre1 + nbUnitesChiffre1;
        chiffre2 = 10 * nbDizainesChiffre2 + nbUnitesChiffre2;

        console.log(total, chiffre1 + chiffre2);

      } else {

        // Cas général :

        // Tirage au sort du premier nombre
        chiffre1 = randomIntFromInterval(1, total - 1);

        // Déduction du second nombre
        chiffre2 = total - chiffre1;
      }

      switch(niveauActivite.value) {
        case 2:
          missingZone = randomIntFromInterval(1, 2); // A trou
          break;

        default:
          missingZone = 3; // Pas à trou
          break;
      }

      if (operationActivite === '-') {
        const _total = total;
        total = chiffre1;
        chiffre1 = _total;
      }

    } else {

      //
      // Saisie manuelle
      //

      if (props.exerciceATrou && (niveauActivite.value < 4)) {

        missingZone = 2;
        chiffre1 = chiffres[0];
        total = chiffres[1];

        if (operationActivite === '-') {
          chiffre2 = chiffre1 - total;
        } else {
          chiffre2 = total - chiffre1;
        }

      } else {

        missingZone = 3;
        chiffre1 = chiffres[0];
        chiffre2 = chiffres[1];


        if (operationActivite === '-') {
          total = chiffre1 - chiffre2;
        } else {
          total = chiffre1 + chiffre2;
        }
      }

    }

    console.log(chiffre1, '+/-', chiffre2, '=', total, "niveau", niveauActivite.value, "missing", missingZone);

    refChiffre1.value = chiffre1;
    refChiffre2.value = chiffre2;
    refChiffre3.value = total;

    refIsMissing1.value = missingZone === 1;
    refIsMissing2.value = missingZone === 2;
    refIsMissing3.value = missingZone === 3;

    // Consigne
    if (avecConsigne) {
      playSound_Consigne();
    }

    // Drag and drop
    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", '.clavier').not('.draggable-initialized');
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create($target, {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true,
      onPress: function (pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        selectedDraggable = draggableElement;
      },
      onDragStart: function (pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        var dropZones = $(".slot-zone");
        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function (pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        setTimeout(function () {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = parseInt(draggableElement.find('.with-value').attr('data-value'));

        var dropZones = $(".slot-zone");
        var i = dropZones.length;
        var dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, {x: 0, y: 0, delay: 0.1});

        isDragging = false;
      },
      onDrag: function () {

        var dropZones = $(".slot-zone");
        var i = dropZones.length, dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    var dropZones = $(".slot-zone");
    dropZones.on('click', function () {
      if (!isDragging && selectedDraggable) {
        $('.draggable').removeClass("dragged");

        var nombre_drag = parseInt(selectedDraggable.find('.with-value').attr('data-value'));
        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre_drag);
      }
    });

  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  function clickDe() {
    isSelected.value = !isSelected.value;
    isActive.value = !isActive.value;
    isHidden.value = !isHidden.value;
  }

  function checkZone( dropZone, nombre ) {

    const slotsNo = parseInt( dropZone.closest('.slots').attr("data-slots") );
    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );
    console.log("checkZone", dropZoneNo, '-->', dropZoneCorrectValue, 'dropped', nombre);

    let composantSlots;

    console.log("dropZoneNo", dropZoneNo, "slotsNo", slotsNo)

    if (refIsMissing1.value) {
      if (slotsNo !== 1) return;
      composantSlots = slots1.value;
    } else if (refIsMissing2.value) {
      if (slotsNo !== 2) return;
      composantSlots = slots2.value;
    } else if (refIsMissing3.value) {
      if (slotsNo !== 3) return;
      composantSlots = slots3.value;
    }

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      const composantSlotComplete = composantSlots.isComponentComplete();
      const composantSlotCorrect = composantSlots.isComponentCorrect();

      if (composantSlotComplete && composantSlotCorrect) {
        // Tous les slots sont bien remplis :


        // Loupe
        refMissingIsFound.value = false;

        // On empêche l'interaction de l'activité en cours
        updateAda("lock");

        // Son de validation, succès et replay
        playSound_Validation_and_AddSuccess();

      }
      else 
      {
        // Le dernier slot rempli est correct
        updateAda("normal");
      }
    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      updateAda("error", 3000);
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }



  //
  // Statistiques
  //

  const slotChanged = function($event) {

    // console.log('slotChanged', $event);

    const dislayChiffre = function(chiffre, isMissing) {
      return isMissing ? [ '(', chiffre, ')' ].join('') : chiffre;
    }

    let solution = [];
    solution.push( dislayChiffre( refChiffre1.value, refIsMissing1.value) );
    solution.push(operationActivite);
    solution.push( dislayChiffre( refChiffre2.value, refIsMissing2.value) );
    solution.push("=");
    solution.push( dislayChiffre( refChiffre3.value, refIsMissing3.value) );

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: "Compléter",
        solution: solution.join(' '),
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound)
    {
      const soundPath1 = getSoundPath_Consigne("consigne-additionner");

      // RQ : Cas particuliers : en mode parcours (i.e quand il n'y a pas de validation), on ne propose pas de jouer avec d'autres nombres

      let soundPaths;

      if (niveauActivite.value === 1) {
        const soundPath2 = getSoundPath_Consigne("clique-sur-la-fleche-ronde", true, "new");
        soundPaths = props.withValidation ? [ soundPath1, soundPath2 ] : [ soundPath1 ];
        playSound(soundPaths,  () => { updateAda("help") });
      }
      else if (niveauActivite.value === 6) {
        const soundPath2 = getSoundPath_Consigne("tu-peux-t-aider-des-gommettes", true, "new");
        const soundPath3 = getSoundPath_Consigne("clique-sur-la-fleche-ronde", true, "new");
        const soundPath4 = getSoundPath_Consigne("tu-peux-cliquer-sur-l-oeil", true, "new");
        soundPaths = props.withValidation ? [ soundPath1, soundPath2, soundPath3, soundPath4 ] : [ soundPath1, soundPath2, soundPath4 ];
        playSound(soundPaths);
      }
      else {
        const soundPath2 = getSoundPath_Consigne("tu-peux-t-aider-des-gommettes", true, "new");
        const soundPath3 = getSoundPath_Consigne("clique-sur-la-fleche-ronde", true, "new");
        soundPaths = props.withValidation ? [ soundPath1, soundPath2, soundPath3 ] : [ soundPath1, soundPath2 ];
        playSound(soundPaths, () => { updateAda("help") });
      }
    }
  }

  function playSound_Validation_and_AddSuccess() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPathFelicitations = getSound_Felicitations();
        playSound(soundPathFelicitations, addSuccessAndReplay);
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }

</script>

<style scoped lang="scss">

  .repertoire-additif-v2 {

    @include on-small-height-desktop {
      padding-top: 20px;
    }

    & > div {

      .components-parent {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .components-parent > div {
        margin: 10px 20px;
        font-size: 60px;
        text-align: center;
        box-sizing: border-box;
        position: relative;
      }

      /* */

      .slots .slots-content {
        height: 132px;
      }

      .slots .slots-content > div > span.slot-zone {
        width: 112px;
      }


      /* */

      &.niveau-3 {

        .components-parent > div.operation {
          width: 30px;
          margin: 0;

          @include on-tablet {
            width: auto;
          }
        }

        .components-parent > div  {
          margin-left: 10px;
          margin-right: 10px;

          @include on-tablet {
            margin-left: 0;
            margin-right: 0;
          }
        }

      }

      &.niveau-5 {
        .slots-parent {
          flex: calc(45% - 20px) 0 0 !important;
        }
        .cards-parent {
          flex: calc(55% - 20px) 0 0 !important;
        }
      }

      &.niveau-6 {

        justify-content: flex-end;

        .slots-parent {
          flex: 44% 0 0 !important;
        }

        .cards-parent {
          display: none;
        }

        .components-parent > div.operation {
          width: 50px;
          margin: 0;
        }
      }

      &.niveau-4,
      &.niveau-5,
      &.niveau-6 {

        display: flex;
        flex-direction: row-reverse;
        gap:30px;
        align-items: flex-start;

        &.align-from-bottom {
          align-items: flex-end;
        }

        .components-parent {
          flex: calc(50% - 15px) 0 0;
          margin: 0;

          flex-wrap: wrap;
          justify-content: flex-end;
        }

        .components-parent::before {
          content:"";
          display: block;
          width: 30px;
        }

        .components-parent > div.operation {
          flex: 30px 0 0;
          width: 30px;
          margin: 0;
        }

        .components-parent > div.operation.egal {

          flex: 100% 0 0;
          width: 100%;
          height: 6px;
          margin-top: 20px;
          margin-bottom: 10px;

          /* White / 100 */
          background: #FFFFFF;

          /* Dark Grey/20 - 100 */
          border: 2px solid #333333;

          /* Dropshadow-2px */
          box-shadow: 0px 2px 0px #333333;
          border-radius: 2px;

          font-size: 0;
        }

        .components-parent > div  {
          flex: calc(100% - 30px) 0 0;
          width: calc(100% - 30px);
          margin-left: 0;
          margin-right: 0;
          text-align: right;

          &.card {
            display: flex;
            justify-content: flex-end;
          }
        }


        /* AUDREY */
        .components-parent.isHidden {
          display: none;
        }

        .components-parent.is-active {
          display: flex;
        }

        .operation.egal > .signe {
          transform: translateY(55px);
        }
      }

      &.niveau-6 {
        .components-parent {
          flex: calc(55% - 15px) 0 0;
        }
      }

      .clavier {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }

      .eye-button {
        position: absolute;
        top: -57px;
        right: calc(50% - 650px);
        width:63px;
        height:71px;
        background-image: url(../../../assets/images/svg/icones_v2/Eye_hover_4x.png);
        background-size: 100%;
        background-repeat: no-repeat;

        @include on-print {
          display: none;
        }
      }

      .eye-button.is-selected {
        background-image: url(../../../assets/images/svg/icones_v2/Eye_4x.png);
      }

    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    .ada {
      @include on-tablet {
        bottom: 154px;
      }
    }

  }

</style>
