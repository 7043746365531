<template>
  <ListeDeroulante
      ref="selectOrdre"
      :values="options"
      :has-label="true"
      text-label="Ordre de classification"
      flex-direction="column"
      @change="onChangeSelect"
  />
</template>

<script setup>

import ListeDeroulante from "./ListeDeroulante";
import {defineExpose, ref} from "vue";


  //
  // Reactive values
  //

  const selectOrdre = ref();

  const options = ref();

  options.value = [
    { value: "asc", label: "Croissant" },
    { value: "desc", label: "Décroissant" }
  ]


  //
  // Events
  //

  const emit = defineEmits(['change']);

  function onChangeSelect(value) {
    emit( 'change', value);
  }


  //
  // Méthodes publiques
  //

  defineExpose({
    setSelect
  })

  function setSelect(value) {
    selectOrdre.value.setSelect(value);
  }

</script>

<style scoped lang="scss">
</style>