<template>
  <DeCouleursUniteOutline1 ref="ref1" v-if="refChiffre === 1"  @click="clickDes($event)" />
  <DeCouleursUniteOutline2 ref="ref2" v-else-if="refChiffre === 2" @click="clickDes($event)" />
  <DeCouleursUniteOutline3 ref="ref3" v-else-if="refChiffre === 3" @click="clickDes($event)" />
  <DeCouleursUniteOutline4 ref="ref4" v-else-if="refChiffre === 4" @click="clickDes($event)" />
  <DeCouleursUniteOutline5 ref="ref5" v-else-if="refChiffre === 5" @click="clickDes($event)" />
  <DeCouleursUniteOutline6 ref="ref6" v-else-if="refChiffre === 6" @click="clickDes($event)" />
  <DeCouleursUniteOutline7 ref="ref7" v-else-if="refChiffre === 7" @click="clickDes($event)" />
  <DeCouleursUniteOutline8 ref="ref8" v-else-if="refChiffre === 8" @click="clickDes($event)" />
  <DeCouleursUniteOutline9 ref="ref9" v-else-if="refChiffre === 9" @click="clickDes($event)" />
  <DeCouleursUniteOutline10 ref="ref10" v-else-if="refChiffre === 10" @click="clickDes($event)" />
</template>

<style scoped lang="scss">
</style>

<script setup>
import {ref, onMounted, watch, defineExpose} from "vue";
import DeCouleursUniteOutline1 from "./DeCouleursUniteOutline1";
import DeCouleursUniteOutline2 from "./DeCouleursUniteOutline2";
import DeCouleursUniteOutline3 from "./DeCouleursUniteOutline3";
import DeCouleursUniteOutline4 from "./DeCouleursUniteOutline4";
import DeCouleursUniteOutline5 from "./DeCouleursUniteOutline5";
import DeCouleursUniteOutline6 from "./DeCouleursUniteOutline6";
import DeCouleursUniteOutline7 from "./DeCouleursUniteOutline7";
import DeCouleursUniteOutline8 from "./DeCouleursUniteOutline8";
import DeCouleursUniteOutline9 from "./DeCouleursUniteOutline9";
import DeCouleursUniteOutline10 from "./DeCouleursUniteOutline10";


//
// Reactive values
//
const ref1 = ref();
const ref2 = ref();
const ref3 = ref();
const ref4 = ref();
const ref5 = ref();
const ref6 = ref();
const ref7 = ref();
const ref8 = ref();
const ref9 = ref();
const ref10 = ref();


const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  }
});

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const emit = defineEmits(['click-des'])

const clickDes = function($event){
    $event.preventDefault();

    if( $event.target.getAttribute("data-value")) {
      emit( 'click-des', {color:$event.target.getAttribute("data-value"), divchild:$event.target});
    }
}

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});


  defineExpose({
    reset
  })
 
 
  function reset() {
    
     let $divs = document.getElementsByClassName("couleur-point");

    // DIV_JAUNE
    console.log("WJAT jaune ", $divs.length);


    var i;
    for (i = 0; i < $divs.length; i++) {
      console.log("WHY i" , i);
      console.log("classList" , $divs[i].classList);

      $divs[i].classList.remove("jaune");
      $divs[i].classList.remove("violet");
      $divs[i].classList.remove("vert");
      $divs[i].classList.remove("rouge");
      $divs[i].classList.remove("bleu");

    }
//
    //// DIV_VIOLET
    //var $divs_violet = document.getElementsByClassName("violet");
    //for (var j = 0; j < 10; j++) {
    //  if($divs_violet[j])
    //    $divs_violet[j].classList.remove("violet");
    //}
//
    //// DIV_VERT
    //var $divs_vert = document.getElementsByClassName("vert");  
    //for (var k = 0; k < 10; k++) {
    //  if($divs_vert[k])
    //   $divs_vert[k].classList.remove("vert");
    //}
//
    //// DIV_ROUGE
    //var $divs_rouge = document.getElementsByClassName("rouge");  
    //for (var l = 0; l < 10; l++) {
    //  if($divs_rouge[l])
    //    $divs_rouge[l].classList.remove("rouge");
    //}
//
    //// DIV_BLEUE
    //var $divs_bleue = document.getElementsByClassName("bleu");
    //for (var m = 0; m < 10; m++) {
    //  if($divs_bleue[m])
    //    $divs_bleue[m].classList.remove("bleu");
    //}
//
  }


</script>