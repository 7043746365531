<template>
  <div class="settings-tab-content">
    <div class="settings-tab-row flex flex-gap-20">
      <InputNombre
          ref="inputText1"
          input-id="nombre1"
          text-label="Nombre 1"
          :input-value="currentInput1Value"
          @change="onChangeInput1"
      />
      <InputNombre
          ref="inputText2"
          input-id="nombre2"
          text-label="Nombre 2"
          :input-value="currentInput2Value"
          @change="onChangeInput2"
      />
      <InputNombre
          ref="inputText3"
          input-id="nombre3"
          text-label="Nombre 3"
          :input-value="currentInput3Value"
          @change="onChangeInput3"
      />
      <InputNombre
          ref="inputText4"
          input-id="nombre4"
          text-label="Nombre 4"
          :input-value="currentInput4Value"
          @change="onChangeInput4"
      />
    </div>
    <div class="settings-tab-row" style="margin-top: 20px">
      <ListeDeroulanteOrdre
          ref="selectOrdre"
          class="select-ordre"
          @change="onChangeOrdre"
      />
    </div>
  </div>
</template>

<script setup>

  import {onMounted, ref, watch} from "vue";
  import InputNombre from "../Controls/InputNombre";
  import ListeDeroulanteOrdre from "../Controls/ListeDeroulanteOrdre";

  //
  // Propriétés du composant
  //

  const props = defineProps({
    tabId: {
      type: String,
      required: false,
      default: ""
    },
    tabValues: {
      type: Object,
      required: false,
      default: null
    },
  });

  // Référence des éléments du formulaire
  const inputText1 = ref(null);
  const inputText2 = ref(null);
  const inputText3 = ref(null);
  const inputText4 = ref(null);
  const selectOrdre = ref(null);

  // Valeurs des différents onglets
  const currentTabValues = ref();

  // Valeur de l'onglet courant
  const currentInput1Value = ref("");
  const currentInput2Value = ref("");
  const currentInput3Value = ref("");
  const currentInput4Value = ref("");
  const currentListItemValue = ref();

  const emit = defineEmits(['change'])


  //
  // Lifecycle Hooks
  //

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });

  function update() {
    // console.log("props.tabValues", JSON.stringify(props.tabValues));
    const valueObj = currentTabValues.value = props.tabValues;

    // Champ texte 1 :
    if ((valueObj !== null) && (valueObj.v1 !== undefined)) {
      currentInput1Value.value = valueObj.v1;
    } else {
      currentInput1Value.value = "";
      inputText1.value.reset();
    }

    // Champ texte 2 :
    if ((valueObj !== null) && (valueObj.v2 !== undefined)) {
      currentInput2Value.value = valueObj.v2;
    } else {
      currentInput2Value.value = "";
      inputText2.value.reset();
    }

    // Champ texte 3 :
    if ((valueObj !== null) && (valueObj.v3 !== undefined)) {
      currentInput3Value.value = valueObj.v3;
    } else {
      currentInput3Value.value = "";
      inputText3.value.reset();
    }

    // Champ texte 4 :
    if ((valueObj !== null) && (valueObj.v4 !== undefined)) {
      currentInput4Value.value = valueObj.v4;
    } else {
      currentInput4Value.value = "";
      inputText4.value.reset();
    }

    // Liste :
    let itemValue;
    if ((valueObj !== null) && (valueObj.ordre !== undefined)) {
      itemValue = valueObj.ordre;
    } else {
      itemValue = true;
    }

    currentListItemValue.value = itemValue;
    selectOrdre.value.setSelect(itemValue);
  }

  //
  // Events
  //

  function onChangeInput1(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v1: inputValue, v2: "" };
    } else {
      currentTabValues.value.v1 = inputValue;
    }

    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeInput2(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v2: inputValue, v1: "" };
    } else {
      currentTabValues.value.v2 = inputValue;
    }

    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeInput3(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v3: inputValue, v1: "" };
    } else {
      currentTabValues.value.v3 = inputValue;
    }

    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeInput4(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v4: inputValue, v1: "" };
    } else {
      currentTabValues.value.v4 = inputValue;
    }

    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeOrdre(itemValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: "", ordre: itemValue };
    } else {
      currentTabValues.value.ordre = itemValue;
    }
    // console.log("change",  JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

</script>

<style scoped lang="scss">
  .select-ordre {
    margin: 0 !important;
  }
</style>