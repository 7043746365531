<template>
  <header>
    <div class="content">
      <!-- Back home -->
      <BurgerBtn />
      <!-- Settings + Print -->
      <div class="settings-and-print-link">
        <!-- Sound -->
        <SoundBtn @click="toggleSound($event)"  />
        <!-- Print -->
        <PrintBtn @click="print($event)"  />
        <!-- Settings -->
        <SettingsBtn @click="toggleSettings($event)" />
      </div>
    </div>
  </header>
</template>

<script setup>

import SoundBtn from "./Boutons/SoundBtn";
import SettingsBtn from "./Boutons/SettingsBtn";
import BurgerBtn from "./Boutons/BurgerBtn";
import PrintBtn from "./Boutons/PrintBtn";

const emit = defineEmits(['toggle-settings', 'toggle-sound', 'print'])
let isOpened = false;
let isSoundActive = true;

const toggleSettings = function($event) {
  $event.preventDefault();
  isOpened = ! isOpened;
  emit( 'toggle-settings', isOpened);
}

const toggleSound = function($event) {
  $event.preventDefault();
  isSoundActive = ! isSoundActive;
  emit( 'toggle-sound', isSoundActive);
}

const print = function($event) {
  $event.preventDefault();
  emit( 'print', isSoundActive);
}


</script>
