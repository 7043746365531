<template>
    <div class="activite double">

      <div class="flex">

        <!-- Slots et clavier -->
        <div class="column">
          <div class="phrase">
            <h2 class="titan">Le double de</h2>
            <Slots
                :chiffre="refMoitie"
                :decomposition-mininum="2"
                :with-sound="withSound"
            />
          </div>

          <div class="phrase">
            <h1 class="titan">=</h1>
            <!--<h2 class="titan">est égal à</h2>-->
            <Slots
                class="vertical-offset-for-flex"
                ref="refSlots"
                :chiffre="refDouble"
                :is-hidden="true"
                :has-validation="withValidation"
                :with-sound="withSound"
                @change="slotChanged"
            />
          </div>

          <ClavierChiffres class="clavier" :nb-touches-par-ligne="5" />
        </div>

        <!-- Zones et palettes -->
        <div class="column">
          <DropZonesDizainesUnites :chiffre="refMoitie" :avec-palette="true" ref="refZones" />
        </div>
      </div>

      <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne"/>

    </div>
</template>

<script setup>

  import {onMounted, watch, ref, defineExpose, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from "jquery";
  import _ from 'lodash';
  import { gsap } from "gsap";
  import { Draggable } from "gsap/Draggable";
  gsap.registerPlugin(Draggable);

  import {
    getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval,
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Slots from "../Slots";
  import DropZonesDizainesUnites from "../Outils/DropZonesDizainesUnites";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 9;

  const overlapThreshold = "50%";
  let draggables;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Valeurs du double et de la moitié
  const refDouble = ref();
  const refMoitie = ref();

  // Référence du composant Slots :
  const refSlots = ref(null);

  // Référence du compoant des colonnes
  const refZones = ref(null);

  let isDragging = false;
  let selectedDraggable;


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }

    if (refZones.value) {
      refZones.value.addDraggableBehavior();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }


  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    switch (niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 100;
        successMax.value = 3;
        break;

      case 2:
        currentMinimumNumber = 100;
        currentMaximumNumber = 200;
        successMax.value = 3;
        break;
    }

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  }

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 100) {
      return 2;
    } else if (maxValue <= 1000) {
      return 3;
    } else if (maxValue <= 10000) {
      return 4;
    } else if (maxValue <= 100000) {
      return 5;
    } else {
      return 6;
    }
  }




  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    let moitie, double;

    if ((chiffres === null) || (chiffres === undefined))
    {
      // Tirage au sort de la moitié
      moitie = randomIntFromInterval(currentMinimumNumber / 2 , currentMaximumNumber / 2);

      // Déduction du second nombre
      double = moitie * 2;

    } else {

      // Saisie manuelle

      moitie = chiffres[0];
      double = moitie * 2;

    }

    refDouble.value = double;
    refMoitie.value = moitie;

    // Consigne
    if (avecConsigne) {
      playSound_Consigne();
    }

    // Drag and drop
    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", ".clavier")
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create($target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");


        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        selectedDraggable = draggableElement;
      },
      onDragStart: function(pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        var dropZones = $(".slot-zone");
        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value') );

        var dropZones = $(".slot-zone");
        var i = dropZones.length;
        var dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold))
          {
            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale du chiffre du clavier
        gsap.set(this.target, { x:0, y:0, delay:0.1 });

        isDragging = false;
      },
      onDrag: function() {

        var dropZones = $(".slot-zone");
        var i = dropZones.length, dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    var dropZones = $(".slot-zone");
    dropZones.on('click', function() {
      if (! isDragging && selectedDraggable )
      {
        $('.draggable').removeClass("dragged");

        var nombre_drag = parseInt( selectedDraggable.find('.with-value').attr('data-value') );
        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre_drag);
      }
    });
  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $(".draggable", '.clavier').find('.with-dragged-style').removeClass("dragged");
  }

  function checkZone( dropZone, nombre ) {

    //
    // Zones de drop des slots ( touches du clavier )
    //

    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );

    // console.log("checkZone", dropZoneNo, '-->', dropZoneCorrectValue, 'dropped', nombre);

    let composantSlots = refSlots.value;

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      const composantSlotComplete = composantSlots.isComponentComplete();
      const composantSlotCorrect = composantSlots.isComponentCorrect();

      if (composantSlotComplete && composantSlotCorrect) {
        // Tous les slots sont bien remmplis :

        // Son de félicitations, succès et replay
        playSound_Felicitations_and_AddSuccess();

      } else {
        // Le dernier slot rempli est correct
        updateAda("normal");
      }

    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);

      // ADA
      updateAda("error", 3000);
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }



  //
  // Statistiques
  //

  const slotChanged = function($event) {
    // console.log('slotChanged', $event);

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: [ "Le double de", refMoitie.value, "est : "].join(" "),
        solution: refDouble.value,
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound) {
      const soundPath1 = getSoundPath_Consigne("utilise-le-clavier-pour-entrer-ta-reponse", true, "new");
      const soundPath2 = getSoundPath_Consigne("pour-t-aider-tu-peux-disposer-des-dizaines-et-unites--a-droite-du-tableau", true, "new");
      playSound([ soundPath1, soundPath2], () => { updateAda("help") } );
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPathFelicitations = getSound_Felicitations();
        playSound(soundPathFelicitations, addSuccessAndReplay);
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }


</script>
  
<style lang="scss">

  .activite.double {
    gap: 20px;
    padding-top: 20px;

    & > div.flex {
      justify-content: center;
      gap: 60px;

      @include on-small-desktop {
        gap: 20px;
      }

      @include on-print {
        gap: 20px;
      }

      .column:first-child {
        flex: 560px 0 0;

        @include on-small-desktop {
          flex: auto 0 0;
        }

        @include on-print {
          flex: auto 0 0;
        }

        .phrase {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include on-small-desktop {
            h2.titan {
                font-size: 35px;
                max-width: 200px;
            }

            h1.titan {
              font-size: 60px;
            }
          }

          @include on-print {
            h2.titan {
                font-size: 35px;
                max-width: 200px;
            }

            h1.titan {
              font-size: 60px;
            }
          }
        }

        .clavier {
          position: fixed;
          bottom: 0;
          left:calc(50% - 500px);

          @include on-small-desktop {
            left: 0;
          }
        }
      }

      .column:last-child {
      }


    }

    .palettes {
      @include on-print {
        display: none;
      }
    }

    .ada {
      @include on-small-desktop {
        bottom: 250px;
      }
    }
  }

</style>
