<template>
  <div class="content content-v2">
    <main class="sommaire">

      <HeaderSommaire
        title="Activités"
        :has-tools="true"
        :has-tools-active="true"
      />

      <div class="activites-sommaire">
        <router-link
            v-for="(item, index) in items"
            :key="index"
            :to="getRouteNiveauxActivite(item)"
            :class="item.path"
        >
          <BoutonNiveauActivite
              :titre="item.titre"
          />
        </router-link>
      </div>

      <h2 class="titan">Outils</h2>

      <div class="activites-sommaire column2">
        <router-link
            v-for="(item, index) in outils"
            :key="index"
            :to="getRouteOutil(item)"
            :class="item.path"
        >
          <BoutonOutils
              :titre="item.titre"
          />
        </router-link>
      </div>

      <h2 class="titan">Jeux</h2>

      <div class="activites-sommaire column2">
        <router-link
            v-for="(item, index) in jeux"
            :key="index"
            :to="getRouteJeux(item)"
            :class="getCssClassJeux(item)"
        >
          <BoutonJeux
              :titre="item.titre"
          />
        </router-link>
      </div>

      <h2 class="titan" id="parcours">Parcours</h2>

      <div class="activites-sommaire column2">
        <router-link
            v-for="(item, index) in parcours"
            :key="index"
            :to="getRouteParcours(item)"
            :class="item.path"
        >
          <BoutonOutils
              :titre="item.titre"
          />
        </router-link>
      </div>

    </main>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

import {onMounted, ref} from "vue";
  import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

  import BoutonNiveauActivite from "../../components/v2/Sommaires/BoutonNiveauActivite";
  import BoutonJeux from "../../components/v2/Sommaires/BoutonJeux";
  import BoutonOutils from "../../components/v2/Sommaires/BoutonOutils";
  import HeaderSommaire from "../../components/v2/Zones/HeaderSommaire";
  import ResponsiveWarning from "../../components/v2/ResponsiveWarning";
import {stopAllSounds} from "../../js/utils";


  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Liste des activités (cf activites_v2.js )
  const items  = ref(store.state.activites);
  const jeux   = ref(store.state.jeux);
  const outils = ref(store.state.outils);
  const parcours = ref(store.state.parcours);

  const getRouteNiveauxActivite = function(activite) {

    const routeNiveauxActivite = router.resolve({
      name: 'Niveaux Activité',
      params: { activite: activite.path }
    });

    return routeNiveauxActivite.href;
  }

  const getRouteJeux = function(activite) {

    const routeNiveauxActivite = router.resolve({
      name: activite.name
    });

    return routeNiveauxActivite.href;
  }

  const getCssClassJeux = function(activite) {
    return [
      activite.path,
      activite.active ? "" : "inactive"
    ].join(' ')
  }

  const getRouteOutil = function(activite) {

    if (activite.niveaux) {

      // Outils avec Tables :

      const routeNiveauxOutil = router.resolve({
        name: 'Tables Outil',
        params: { outil: activite.path }
      });

      return routeNiveauxOutil.href;

    } else {

      // Outils sans niveaux, ni tables

      const routeNiveauxActivite = router.resolve({
        name: activite.name
      });

      return routeNiveauxActivite.href;

    }
  }


  const getRouteParcours = function(parcours) {

    const routeParcours = router.resolve({
      name: 'Sommaire Parcours',
      params: { id: parcours.id }
    });

    return routeParcours.href;
  }


  /*
  const getRouteOutils = function(activiteName) {

    const routeNiveauxActivite = router.resolve({
      name: activiteName
    });

    return routeNiveauxActivite.href;
  }
  */


  //
  // Lifecycle Hooks
  //

  onMounted(() => {

    stopAllSounds();

    // Ancre HTML éventuelle :
    if (route.hash) {
      setTimeout(() => location.hash = route.hash, 1);
    }
  });

</script>


<style scoped lang="scss">

  .sommaire {
    width:100%;
    padding-bottom:  120px;
  }

  .sommaire h1 {
    margin-top: 30px;
  }

  .sommaire h2 {
    margin-top: 50px;
  }

  .activites-sommaire {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    gap:10px;

    @include on-mobile-landscape {
      padding-top: 4px;
    }

  }

  .activites-sommaire > a {
    flex: calc( 33% - 5px ) 0 0;

    @include on-mobile-landscape {
      flex: calc( 50% - 5px ) 0 0;

      .niveau-button {
        height: 200px;
      }
    }
  }

  .activites-sommaire.column2 {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    gap:30px;
  }

  .activites-sommaire.column2 > a {
    flex: calc( 50% - 15px ) 0 0;

    &.inactive {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  .outil-sommaire {
    display: block;
    float: left;
    margin-right: 14px;
    margin-bottom: 15px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    width: 536px;
    height: 97px;
    box-sizing: border-box;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  }

  .outil-sommaire a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size:42px;
    line-height: 41px;
    font-weight: bold;
    padding: 16px;
  }


  /* iPad */

  @media screen and (max-width: 1080px ) {

    .activite-sommaire {
      width: 300px;
      height: 190px;

    }

  }

</style>
