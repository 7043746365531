<template>
  <div :id="'de' + no" class="des-carres">

    <div v-if="((chiffre > 9 ) || forceDizaine)" class="des-dizaines-unites">
        <div class="dizaines" :class="noDizaineCss">
          <div class="des" :data-no="no">
            <div
                v-for="index in dizaines" :key="index"
                class="de-parent double"
                data-value="10"
                :class="cssHide"
            >
              <div class="de chiffre10 "></div>
            </div>
          </div>
        </div>
        <div class="unites">
          <div class="des" :data-no="no">
            <div class="de-parent" :data-value="unites" :class="cssHide">
              <div class="de" :class="'chiffre'+ unites"></div>
            </div>
          </div>
        </div>
    </div>

    <div v-else-if="chiffre === 10" class="des-dizaines-unites">
      <div class="des" :data-no="no" >
        <div class="de-parent double" data-value="10" :class="cssHide">
          <div class="de chiffre10"></div>
        </div>
      </div>
    </div>

    <div v-else class="des-unites unites">
      <div class="des" :data-no="no">
        <div class="de-parent" :data-value="chiffre" :class="cssHide">
          <div class="de" :class="'chiffre'+ chiffre"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>

  import {ref, watch, onMounted} from "vue";
  import { getDizainesUnites } from "../../../js/utils";

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 1
    },
    no: {
      type: Number,
      required: false,
      default: 1
    },
    forceDizaine: {
      type: Boolean,
      required: false,
      default: false
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  let dizaines = ref(0), unites = ref(0), noDizaineCss = ref(''), cssHide = ref('');

  const update = function() {
    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites( chiffre );

    dizaines.value = dizainesUnites.dizaines;
    unites.value   = dizainesUnites.unites;
    noDizaineCss.value = dizaines.value > 0 ? '' : 'pas-de-dizaines';
    cssHide.value = props.isHidden ? 'hide' : '';

  };

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });

</script>

<style scoped lang="scss">

  .des-dizaines-unites {
    display: flex;
    border: 1px solid #0B154B;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: relative;
  }

  .des-dizaines-unites .unites {
    display: flex;
  }

  .des-dizaines-unites .dizaines {
    border-right: 1px solid black;
  }

  .des-dizaines-unites .dizaines.pas-de-dizaines {
    display: none;
  }

  .des {
    border: 1px solid #0B154B;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 15px 15px 15px;
  }

  .des-dizaines-unites .des {
    box-shadow: none;
    border: none;
    margin: auto; /* nécessaire pour l'alignement vertical */
  }

  .de-parent {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    width: 128px;
  }

  
  /* AUDREY */ 
  .de-parent.hide {
    opacity: 0;
  }

  .half-size .de-parent {
    width:76px;
  }

  /* Unités seules */

  .des-unites {
    width: 162px;
  }

  .half-size .des-unites {
    width: 108px;
  }

  /* Dizaines- Unités seules */

  .des-dizaines-unites .de-parent.double {
    width: 168px;
    height: 128px;
  }

  .half-size .des-dizaines-unites .de-parent {
    width:76px;
    height:76px;
  }


  .unites .de-parent,
  .dizaines .de-parent {
    background: transparent;
  }

  .unites {
    background-color: rgba(59, 143, 242, 0.2);
    border-radius: 12px;
  }

  .des-dizaines-unites .unites {
    border-radius: 0 12px 12px 0;
  }

  .dizaines {
    background-color: rgba(255, 63, 51, 0.2);
    border-radius: 12px 0 0 12px;
  }

  .de {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    box-sizing: border-box;
  }


  /* iPad */

  @media screen and (max-width: 1080px ) {

    .des {
      padding: 15px 10px;
    }

    .half-size .de-parent {
      width:50px;
      height:50px;
    }

    .half-size .de-parent.double {
      width:70px;
      height: 35px;
    }

  }

</style>
