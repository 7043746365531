<template>
  <div class="de image-de"></div>
</template>

<style scoped lang="scss">

  .image-de {
    width: 42px;
    height: 60px;
    background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_milliers/State_Enabled_Active_Yes.svg);

    &:hover {
      cursor: pointer;
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_milliers/State_Hover_Active_Yes.svg);
    }

    &.pressed {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_milliers/State_Pressed_Active_Yes.svg);
    }

    &.off {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_milliers/State_Enabled_Active_No.svg);
    }

    &.off.pressed {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_milliers/State_Pressed_Active_No.svg);
    }

    &.off:hover {
      background-image: url(../../../../assets/images/svg/des_v2/boutons/dizaines_milliers/State_Hover_Active_No.svg);
    }
  }

</style>
