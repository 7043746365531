<template>
  <footer>
    <div class="content">
      <!-- Fullscreen -->
      <FullscreenBtn />
      <!-- Logo -->
      <LogoCopyright />
      </div>
  </footer>
</template>

<script>

import FullscreenBtn from "./Boutons/FullscreenBtn";
import LogoCopyright from "./Logos/LogoCopyright";

export default {
  name: 'FooterActivite',
  components: {
    FullscreenBtn,
    LogoCopyright
  },
}
</script>

<style scoped lang="scss">
</style>
