<template>
  <div @click="playSoundChiffre">
    <DeDizaine0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeDizaine1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeDizaine2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeDizaine3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeDizaine4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeDizaine5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeDizaine6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeDizaine7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeDizaine8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeDizaine9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeDizaine10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeDizaine0 from "./DeDizaine0";
import DeDizaine1 from "./DeDizaine1";
import DeDizaine2 from "./DeDizaine2";
import DeDizaine3 from "./DeDizaine3";
import DeDizaine4 from "./DeDizaine4"
import DeDizaine5 from "./DeDizaine5";
import DeDizaine6 from "./DeDizaine6";
import DeDizaine7 from "./DeDizaine7";
import DeDizaine8 from "./DeDizaine8";
import DeDizaine9 from "./DeDizaine9";
import DeDizaine10 from "./DeDizaine10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Dizaine,
  playSound
} from "../../../../js/utils";


//
// Reactive values
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
});

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};


const playSoundChiffre = function() {
  var soundPath;

  if (props.withSound) {
    soundPath = getSoundPath_Dizaine(refChiffre.value);
    playSound(soundPath);
  } 
}

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>