<template>
  <div class="settings-tab-content">
    <InputNombre
        :input-value="currentInputValue"
        ref="inputText"
        text-label="Nombre"
        @change="onChangeInput"
    />
  </div>
</template>

<script setup>

import InputNombre from "../Controls/InputNombre";
import {onMounted, ref, watch} from "vue";

//
// Propriétés du composant
//

const props = defineProps({
  tabId: {
    type: String,
    required: false,
    default: ""
  },
  tabValues: {
    type: Object,
    required: false,
    default: null
  },
});

// Référence des éléments du formulaire
const inputText = ref(null);

// Valeurs des différents onglets
const currentTabValues = ref();

// Valeurs de l'interface de settings s'adaptant aux valeurs saisies
const currentInputValue = ref("");

const emit = defineEmits(['change'])


//
// Lifecycle Hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

function update() {
  const valueObj = currentTabValues.value = props.tabValues;
  if ((valueObj !== null) && (valueObj.v !== undefined)) {
    currentInputValue.value = valueObj.v;
  } else {
    currentInputValue.value = "";
    inputText.value.reset();
  }
}

function onChangeInput(inputValue) {
  currentTabValues.value = { v: inputValue };
  emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
}

</script>

<style scoped lang="scss">

</style>