<template>
  <div class="clavier-etiquettes-decomposition-v2">
    <ul>
      <li v-if="nombreEtiquettes>5" class="draggable" :class="centainesMilliersCssClass">
        <div data-value="centaines-milliers" class="with-value with-dragged-style">
          <span class="etiquette-centaines-milliers"></span>
        </div>
      </li>
      <li v-if="nombreEtiquettes>4"  class="draggable" :class="dizainesMilliersCssClass">
        <div data-value="dizaines-milliers" class="with-value with-dragged-style">
          <span class="etiquette-dizaines-milliers"></span>
        </div>
      </li>
      <li v-if="nombreEtiquettes>3"  class="draggable" :class="milliersCssClass">
        <div data-value="milliers" class="with-value with-dragged-style">
          <span class="etiquette-milliers"></span>
        </div>
      </li>
      <li v-if="nombreEtiquettes>2"  class="draggable" :class="centainesCssClass" >
        <div data-value="centaines" class="with-value with-dragged-style">
          <span class="etiquette-centaines"></span>
        </div>
      </li>
      <li v-if="nombreEtiquettes>1"  class="draggable" :class="dizainesCssClass">
        <div data-value="dizaines" class="with-value with-dragged-style">
          <span class="etiquette-dizaines"></span>
        </div>
      </li>
      <li v-if="nombreEtiquettes>0"  class="draggable" :class="unitesCssClass" >
        <div data-value="unites" class="with-value with-dragged-style">
          <span class="etiquette-unites"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>

// Props :

import {defineExpose, onMounted, ref, computed, watch} from "vue";
import {shuffleArray} from "../../../js/utils";

const props = defineProps({
  nombreEtiquettes: {
    type: Number,
    required: false,
    default: 2
  },
  isShuffled: {
    type: Boolean,
    required: false,
    default: false
  },
});

const ordreEtiquettes = ref(null);

const initOrdreEtiquettes = function() {
  let i, n = props.nombreEtiquettes;
  const ordre = [];
  for(i=0; i<n; i++) {
    ordre.push(i+1);
  }

  if (props.isShuffled) {
    shuffleArray(ordre);
  }

  ordreEtiquettes.value = ordre;
}

// Pour ne pas que le tableau soit vide et que les computed génèrent une erreur
initOrdreEtiquettes();


const centainesMilliersCssClass = computed(() => {
  return ordreEtiquettes.value.length < 6 ? "" : "flex-order-" + ordreEtiquettes.value[5];
});

const dizainesMilliersCssClass = computed(() => {
  return ordreEtiquettes.value.length < 5 ? "" : "flex-order-" + ordreEtiquettes.value[4];
});

const milliersCssClass = computed(() => {
  return ordreEtiquettes.value.length < 4 ? "" : "flex-order-" + ordreEtiquettes.value[3];
});

const centainesCssClass = computed(() => {
  return ordreEtiquettes.value.length < 3 ? "" : "flex-order-" + ordreEtiquettes.value[2];
});

const dizainesCssClass = computed(() => {
  return ordreEtiquettes.value.length < 2 ? "" : "flex-order-" + ordreEtiquettes.value[1];
});

const unitesCssClass = computed(() => {
  return ordreEtiquettes.value.length < 1 ? "" : "flex-order-" + ordreEtiquettes.value[0];
});


//
// Lifecycle hooks
//

watch( props, () => {
  initOrdreEtiquettes();
});

onMounted(() => {
  initOrdreEtiquettes();
});


/* Public methods */

const resetEtiquettes = function() {
  // Retour à la position initiale des étiquettes
}

const shuffletEtiquettes = function() {
  // Mélange les étiquettes
  initOrdreEtiquettes();
}

defineExpose({
  resetEtiquettes,
  shuffletEtiquettes
});


</script>

<style scoped lang="scss">

  /* Clavier Etiquettes Milliers, Centaines, ... */

  .clavier-etiquettes-decomposition-v2 {

    display: flex;
    align-items: center;
    height: 112px;

    // Bg et border :
    ul {
      /* Light Grey/90 -100 */
      background: #E6E6E6;

      /* Light Grey/95 - 100 */
      border: 8px solid #F2F2F2;
      /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25); */
      border-radius: 32px 32px 0px 0px;
      border-bottom: none;

      @include on-print {
        background: none;
        border: none;
      }
    }

    ul {
      list-style-type: none;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 5px;

      li {

        div {

          span {
            display: inline-block;
            background-repeat: no-repeat;
            background-size: cover;
            width: 156px;
            height: 96px;

            &.etiquette-unites {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/unites_4x.png);
            }

            &.etiquette-centaines {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/centaines_4x.png);
            }

            &.etiquette-dizaines {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/dizaines_4x.png);
            }

            &.etiquette-milliers {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/milliers_4x.png);
            }

            &.etiquette-dizaines-milliers {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/dizaines_milliers_4x.png);
            }

            &.etiquette-centaines-milliers {
              background-image: url(../../../assets/images/svg/etiquettes_v2/unites_dizaines/centaines_milliers_4x.png);
            }

          }

        }
      }
    }
  }

</style>
