<template>

  <footer class="footer-home">

    <div class="content">

      <div>
        <!-- Fullscreen -->
        <FullscreenBtn />

        <!-- Contacts -->
        <router-link class="contact-link" to="qui-sommes-nous">
          Qui sommes-nous ?
        </router-link>
      </div>

      <div class="partners">
        <img src="../../assets/images/edu-up.jpg" />
        <img src="../../assets/images/th-1999x1999-cardie.png.jpg" />
        <img src="../../assets/images/men.jpg" />
        <p>
          Réalisé avec le soutien du <a href="https://www.education.gouv.fr/">ministère de l'éducation nationale</a>
          avec le soutien de la <a href="rozan.fondationjudaisme.org" target="_blank">fondation Julien et Stella Rozan</a>
        </p>
      </div>

      <!-- Logo -->
      <LogoCopyright />

    </div>
  </footer>
</template>

<script>

import FullscreenBtn from "./Boutons/FullscreenBtn";
import LogoCopyright from "./Logos/LogoCopyright";

export default {
  name: 'FooterHome',
  components: {
    FullscreenBtn,
    LogoCopyright
  },
}
</script>

<style scoped lang="scss">

  a.contact-link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #15299C;
    float: left;
  }

  a.contact-link:hover {
    text-decoration: underline;
  }

  .partners {
    bottom: 10px;
    padding-right:160px;
    text-align: right;
    pointer-events: none;
    font-size:12px;
  }

  .partners img {
    max-height: 100px;
    max-width: 90px;
    margin: 0 10px 0 0;
  }

  .partners a {
    color:#000;
    text-decoration: none;
  }

  .partners p {
    width:150px;
    display: inline-block;
    text-align: left;
    margin-right: 40px;
  }

</style>
