<template>
  <header>
    <div class="content">
      <!-- Dedys -->
      <DedysLogo />
      <!-- Settings -->
      <div class="settings-and-print-link">
        <!-- Print -->
        <a class="svg-icon print-link hidden" href="#"></a>
        <!-- Settings -->
        <SettingsBtn @click="toggleSettings($event)" />
      </div>
    </div>
  </header>
</template>

<script setup>

import SettingsBtn from "./Boutons/SettingsBtn";
import DedysLogo from "./Logos/DedysLogo";

const emit = defineEmits(['toggle-settings'])
let isOpened = false;

const toggleSettings = function($event) {
  $event.preventDefault();
  isOpened = ! isOpened;
  emit( 'toggle-settings', isOpened);
}

</script>
