<template>
  <a class="svg-icon sound-state" href="#"></a>
</template>

<script>
export default {
  name: 'SoundBtn',
}
</script>

<style scoped lang="scss">

  a.svg-icon.sound-state {
    background-image: url(../../../assets/images/svg/icones/sound-on.svg);
  }

  a.svg-icon.sound-state.off {
    background-image: url(../../../assets/images/svg/icones/sound-off.svg);
  }

</style>
