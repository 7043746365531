<template>
    <div class="fleche-operation"></div>
</template>

<style scoped lang="scss">

  .fleche-operation {
    width: 123px;
    height: 53px;
    background-image: url(../../../assets/images/svg/fleche_operation.svg);
  }

</style>
