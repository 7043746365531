<template>
  <router-link to="/" class="fermer-page-btn"></router-link>
</template>

<style scoped lang="scss">

a.fermer-page-btn {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(../../../assets/images/svg/close-page.svg) center / contain no-repeat;
  text-decoration: none;
}

</style>
