<template>
  <div class="content content-v2" :class="settingsCssClass">
    <main
        :class="desSettingsCssClass"
    >

      <h1>Composants v1</h1>

      <div class="interaction columns">

        <div class="column">
          <DePourOperations :chiffre='1'  />
          <DePourOperations :chiffre='5'  />
          <DePourOperations :chiffre='10' />
          <DePourOperations :chiffre='13' />
        </div>

        <div class="column half-size">
          <DePourOperations :chiffre='1'  />
          <DePourOperations :chiffre='5'  />
          <DePourOperations :chiffre='10' />
          <DePourOperations :chiffre='13' />
          <DePourOperations :chiffre='23' />
          <DePourOperations :chiffre='58' />
        </div>

        <div class="column">
          <CartoucheNombrePourOperations :chiffre="9" />
        </div>

        <div class="column half-size">
          <CartoucheNombrePourOperations :chiffre="9" />
          <CartoucheNombrePourOperations :chiffre="42" />
          <CartoucheNombrePourOperations :chiffre="42" is-hidden />
        </div>

      </div>

    </main>
  </div>
</template>

<script setup>

import { useStore } from 'vuex';
import DePourOperations from "../../../components/v1/Chiffres/DePourOperations";
import CartoucheNombrePourOperations from "../../../components/v1/Chiffres/CartoucheNombrePourOperations";

const store = useStore();

// Style des dés (forme, couleur)
let desSettingsCssClass = store.getters.desSettingsCssClass;

</script>
