<template>
  <div class="boite-dizaines">
    <div class="boite-bg">
      <div class="boite-header">
        <h4 class="titan">Dizaine</h4>
        <a class="restart-button" @click="restoreUnites"/>
      </div>
      <div class="boite-content">

        <div class="boite-content-bg">
          <div></div>
          <div></div>
        </div>

        <!-- Compteur des unités -->
        <div v-if="unites < 10"
             class="boite-content-wrapper drop-zone-de"
        >
            <input type="text" id="inputDizaine" :value="unites" disabled />
            <div>Fabrique une dizaine</div>
        </div>

        <!-- Dizaine créé au bout de 10 unités -->
        <div v-else class="boite-content-wrapper nouvelle-dizaine" >
          <div class="draggable from-boite-dizaine">
            <div data-value="dizaine" class="with-value with-dragged-style">
              <DeDizaineNoirEtBlanc1 class="dizaines bg-image" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>

  import {onMounted, watch, ref, defineExpose} from "vue";
  import DeDizaineNoirEtBlanc1 from "../Des/DesDizainesNoirEtBlanc/DeDizaineNoirEtBlanc1";

  const emit = defineEmits(['dizaineCreated', 'restoreUnites']);


  //
  // Propriétés du composant
  //

  const props = defineProps({
    unites: {
      type: Number,
      required: true,
      default: 0
    },
  });


  //
  // Lifecycle hooks
  //

  onMounted(() => {
    unitesParZones.value = [];
    update();
  });

  watch( props, () => {
    update();
  });

  const update = function() {
    unites.value = props.unites;
  };


  //
  // Reactive values
  //

  let unites = ref(0);
  let unitesParZones = [];


  //
  // Méthodes publiques
  //

  defineExpose({
    addUniteFromZone,
    resetUnites
  })

  function addUniteFromZone(zoneId, left, top) {

    console.log("zoneId", zoneId, parseInt(left), parseInt(top));

    const unitesParZonesAssocArray = unitesParZones.value;
    unitesParZonesAssocArray.push({
      zone: zoneId,
      x: parseInt(left),
      y: parseInt(top)
    })

    unites.value ++;

    if (unites.value === 10)
    {
      setTimeout(dizaineCreated, 100);
    }
  }

  function resetUnites() {
    unitesParZones.value = [];
    unites.value = 0;
  }

  //
  // Fonctions spécifiques
  //

  const dizaineCreated = function() {
    emit('dizaineCreated', {
      chiffre: unites.value,
      unites_par_zones: unitesParZones.value
    });
  }

  const restoreUnites = function() {
    emit('restoreUnites', {
      chiffre: unites.value,
      unites_par_zones: unitesParZones.value
    });
    resetUnites();
  }

</script>

<style scoped lang="scss">

  .boite-dizaines {

    position: relative;
    width: 280px;
    height: 176px;

    /* Light Grey/90 -100 */
    background: #964e4a;

    /* Dark Grey/20 - 100 */
    border: 2px solid #333333;

    /* Dropshadow-Simple */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    .boite-bg {

      position: absolute;
      left:0;
      top:0;
      width: 100%;
      height: calc( 100% - 5px );
      background: #f96960;
      border-radius: 6px;

      .boite-header {
        padding: 8px 10px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        a.restart-button {
          display: inline-block;
          width:46px;
          height:50px;
          cursor: pointer;
          background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / cover no-repeat;
        }
      }

      .boite-content {
        position: absolute;
        left: 8px;
        right: 7.5px;
        top: 60px;
        bottom: 12px;

        background: #954d49;
        border-radius: 4px;

        .boite-content-bg {
          position: absolute;
          display: block;
          width: 100%;
          height: 8px;
          left: 0;
          top: 0;
          pointer-events: none;

          background: #65403e;
          border-radius: 4px 4px 0 0;

          & > div:first-child {
            position: absolute;
            left: 0;
            right: 0;
            top: 4px;
            bottom: -4px;

            background: #954d49;
            border-radius: 6px;
          }

          & > div:last-child {
            position: absolute;
            height: 98px;
            left: 0px;
            right: 0px;
            top: 0px;

            background: #C4C4C4;
            border-radius: 4px;
            opacity: 0;
          }

        }

        .boite-content-wrapper {

          height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          input[type="text"] {
            width: 36px;
            height: 36px;
            padding: 0;

            /* Light Grey/95 - 100 */
            border: 2px solid #F2F2F2;
            border-radius: 4px;
            background: rgba(51, 51, 51, 0.5);

            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 48px;
            text-align: center;

            user-select: none;
          }

          div {
            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #F2F2F2;
          }

          &.nouvelle-dizaine {

            div.with-dragged-style {

              background-color: #FFF;
              border-radius: 6px;
              box-shadow: 0 0 0 3px #FFF;

              & > .dizaines > .de,
              & > .dizaines {
                width: 39px;
                height: 25px;
              }
            }
          }

        }
      }
    }


  }

</style>
