<template>
  <div class="settings-tab-content">
    <div class="flex flex-gap-20">
      <RadioButton
          ref="radio1"
          input-id="radio1"
          input-name="group"
          input-value="pas-libre"
          text-label="Couleurs imposées"
          :checked="radio1Value"
          @change="onChangeRadio1"
      />
      <RadioButton
          ref="radio2"
          input-id="radio2"
          input-name="group"
          input-value="libre"
          text-label="Couleurs libres"
          :checked="radio2Value"
          @change="onChangeRadio2"
      />
    </div>
    <div>
      <InputNombre
          ref="inputText"
          :input-value="currentInputValue"
          text-label="Nombre"
          @change="onChangeInput"
      />
    </div>
  </div>
</template>

<script setup>

  import RadioButton from "../Controls/RadioButton";
  import InputNombre from "../Controls/InputNombre";
  import {computed, onMounted, ref, watch} from "vue";

  //
  // Propriétés du composant
  //

  const props = defineProps({
    tabId: {
      type: String,
      required: false,
      default: ""
    },
    tabValues: {
      type: Object,
      required: false,
      default: null
    },
  });

  // Référence des éléments du formulaire
  const inputText = ref(null);
  const radio1 = ref(null);
  const radio2 = ref(null);

  // Valeurs des différents onglets
  const currentTabValues = ref();

  // Valeur de l'onglet courant
  const currentInputValue = ref("");
  const currentRadioButtonValue = ref(true);

  const radio1Value = computed(() => currentRadioButtonValue.value === false);
  const radio2Value = computed(() => currentRadioButtonValue.value === true);

  const emit = defineEmits(['change'])


  //
  // Lifecycle Hooks
  //

  onMounted(() => {
    update();
  });

  watch( props, () => {
    // Mise à jour lorsqu'on passe d'un onglet à l'autre
    update();
  });

  function update() {
    // console.log("props.tabValues", JSON.stringify(props.tabValues));
    const valueObj = currentTabValues.value = props.tabValues;

    // Champ texte :
    if ((valueObj !== null) && (valueObj.v !== undefined)) {
      currentInputValue.value = valueObj.v;
    } else {
      currentInputValue.value = "";
      inputText.value.reset();
    }

    // Radiobutton :
    let checkedValue;
    if ((valueObj !== null) && (valueObj.libre !== undefined)) {
      checkedValue = valueObj.libre;
    } else {
      checkedValue = true;
    }

    currentRadioButtonValue.value = checkedValue;
    radio1.value.setRadioButton(! checkedValue);
    radio2.value.setRadioButton(checkedValue);
  }

  //
  // Events
  //

  function onChangeInput(inputValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: inputValue, libre: true };
    } else {
      currentTabValues.value.v = inputValue;
    }
    // console.log("change", JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

  function onChangeRadio1() {
    // Couleurs imposées
    onChangeRadio(false);
  }

  function onChangeRadio2() {
    // Couleurs libres
    onChangeRadio(true);
  }

  function onChangeRadio(radioButtonValue) {
    if (! currentTabValues.value) {
      currentTabValues.value = { v: "", libre: radioButtonValue };
    } else {
      currentTabValues.value.libre = radioButtonValue;
    }
    // console.log("change",  radioButtonValue, JSON.stringify(currentTabValues.value));
    emit('change', { tabId: props.tabId, tabValues: currentTabValues.value });
  }

</script>

<style scoped lang="scss">

</style>