<template>
  <div class="content content-v2">
    <HeaderOutils
        :title="titreActivite"
    />
    <main class="activite"/>  

  </div>

</template>

<script setup>

  import HeaderOutils from "../../../components/v2/Headers/HeaderOutils";

  import {ref} from "vue";


  // Titre activité :
  const titreActivite = ref('');
  titreActivite.value = "N+1  et  N-1";

  // 
  const activite = ref('');
  console.log("actvite ", activite);

</script>