<template>
  <div class="de"></div>
</template>

<style scoped lang="scss">

  .de {
    width: 40px;
    height: 44px;
    background-image: url(../../../../assets/images/svg/des_v2/unites_noir_et_blanc/Number_5.svg);
  }

</style>
