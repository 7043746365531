<template>
  <div class="identifier-ordonner-v2">

    <div class="order-btn-parent">
      <CroissantDecroissantBtn
          :ordre="ordre"
          @change="changeOrder"
      />
    </div>

    <div class="signs-and-cards" :class="getOrderCssClass">

      <div class="signs">
        <SigneComparaison :signe="croissant" :style="signStyles(0)" />
        <SigneComparaison :signe="croissant" :style="signStyles(1)" />
        <SigneComparaison :signe="croissant" :style="signStyles(2)" />
        <SigneComparaison :signe="croissant" :style="signStyles(3)" :class="card5CssClass" />
        <SigneComparaison :signe="croissant" :style="signStyles(4)" :class="card6CssClass" />
      </div>

      <div class="cards" id="cards">

        <div class="card-parent draggable" data-position="1">
          <Card
              :chiffre="chiffre1"
              :code="code"
              :data-value="chiffre1"
              :has-number="true"
              :has-validation="withValidation"
              :decomposition="niveauActivite > 1"
              :decomposition-mininum="niveauActivite"
              :validation-css-class="chiffre1ValidationCssClass"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>

        <div class="card-parent draggable" data-position="2">
          <Card
              :chiffre="chiffre2"
              :code="code"
              :data-value="chiffre2"
              :has-number="true"
              :has-validation="withValidation"
              :decomposition="niveauActivite > 1"
              :decomposition-mininum="niveauActivite"
              :validation-css-class="chiffre2ValidationCssClass"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>

        <div class="card-parent draggable" data-position="3">
          <Card
              :chiffre="chiffre3"
              :code="code"
              :data-value="chiffre3"
              :has-number="true"
              :has-validation="withValidation"
              :decomposition="niveauActivite > 1"
              :decomposition-mininum="niveauActivite"
              :validation-css-class="chiffre3ValidationCssClass"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>

        <div class="card-parent draggable" data-position="4">
          <Card
              :chiffre="chiffre4"
              :code="code"
              :data-value="chiffre4"
              :has-number="true"
              :has-validation="withValidation"
              :decomposition="niveauActivite > 1"
              :decomposition-mininum="niveauActivite"
              :validation-css-class="chiffre4ValidationCssClass"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>

        <div class="card-parent draggable" data-position="5" :class="card5CssClass">
          <Card
              :chiffre="chiffre5"
              :code="code"
              :data-value="chiffre5"
              :has-number="true"
              :has-validation="withValidation"
              :decomposition="niveauActivite > 1"
              :decomposition-mininum="niveauActivite"
              :validation-css-class="chiffre5ValidationCssClass"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>

        <div class="card-parent draggable" data-position="6" :class="card6CssClass">
          <Card
              :chiffre="chiffre6"
              :code="code"
              :data-value="chiffre6"
              :has-number="true"
              :has-validation="withValidation"
              :decomposition="niveauActivite > 1"
              :decomposition-mininum="niveauActivite"
              :validation-css-class="chiffre6ValidationCssClass"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>

      </div>
    </div>

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne"/>

  </div>

</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import _ from 'lodash';
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSound_Felicitations,
    getSoundPath_Consigne,
    playSound,
    shuffleArray,
  } from "../../../js/utils";

  import Card from "../Card";
  import CroissantDecroissantBtn from "../Boutons/CroissantDecroissantBtn";
  import SigneComparaison from "../Signes/SigneComparaison";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber;
  let currentMaximumNumber;

  let draggables;
  let checkPositionTimeout;

  const activeCardCssSelector = ".draggable:not(.hidden)";


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    ordre : {
      type: String,
      default: "asc"
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  let chiffre1 = ref(0);
  let chiffre2 = ref(0);
  let chiffre3 = ref(0);
  let chiffre4 = ref(0);
  let chiffre5 = ref(0);
  let chiffre6 = ref(0);

  // Ordre
  const ordre = ref(props.ordre);
  const croissant = computed(() => ordre.value === "asc" ? 1 : -1);

  // Ordre (croissant ou décroissant) et
  let chiffresEnOrdre = ref();

  // Ordre actuel des chiffres
  let chiffresOrdreActuel = ref();

  // Position X / Y
  let cardPositions = ref([]);
  let signPositions = ref([]);

  let card5CssClass = ref('');
  let card6CssClass = ref('');

  // Code :
  const code = ref(null);

  // Computed :

  const chiffre1ValidationCssClass = computed(() => {
    return getValidationCssClass(chiffre1.value);
  });

  const chiffre2ValidationCssClass = computed(() => {
    return getValidationCssClass(chiffre2.value);
  });

  const chiffre3ValidationCssClass = computed(() => {
    return getValidationCssClass(chiffre3.value);
  });

  const chiffre4ValidationCssClass = computed(() => {
    return getValidationCssClass(chiffre4.value);
  });

  const chiffre5ValidationCssClass = computed(() => {
    if (card5CssClass.value === "hidden") {
      return "";
    }
    return getValidationCssClass(chiffre5.value);
  });

  const chiffre6ValidationCssClass = computed(() => {
    if (card6CssClass.value === "hidden") {
      return "";
    }
    return getValidationCssClass(chiffre6.value);
  });

  const getValidationCssClass = function(chiffre) {
    if (! Array.isArray(chiffresEnOrdre.value) || ! Array.isArray(chiffresOrdreActuel.value) || isNaN(chiffre)) {
      return "";
    }

    const positionAttendue = chiffresEnOrdre.value.indexOf(chiffre);
    const positionActuelle = chiffresOrdreActuel.value.indexOf(chiffre);
    // console.log(chiffre, positionAttendue, positionActuelle, chiffresOrdreActuel.value);

    if ((positionAttendue === - 1) || (positionAttendue === - 1)) {
      return "";
    } else {
      return positionAttendue === positionActuelle ? "is-correct" : "is-wrong";
    }
  }

  const getOrderCssClass = computed(() => {
    return ordre.value;
  });


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // LifeCycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }




  //
  // Niveaux
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    let cp, defaultTop = 80, diffCardX = 0, diffCardY = 0, offsetCardX = 60, offsetX = 60, offsetCardY = 0;

    const windowWidth = $(window).width();

    const isSmallDesktop = windowWidth < 1200;
    // const smallDesktopOffsetX = isSmallDesktop ? (1200 - windowWidth) * 0.5 : 0;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 10;
        code.value = "simple";

        diffCardX = 240;
        diffCardY = 0;
        offsetCardX = 100 - isSmallDesktop * 30;
        offsetCardY = 40;

        cardPositions.value = [
          {x:                 offsetCardX, y: offsetCardY},
          {x: diffCardX +     offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
        ];

        cp = cardPositions.value;
        offsetX = 60 - isSmallDesktop * 10;

        signPositions.value = [
          {x: cp[0].x + 0.5 * diffCardX + offsetX, y: offsetCardY + defaultTop},
          {x: cp[1].x + 0.5 * diffCardX + offsetX, y: offsetCardY + defaultTop},
          {x: cp[2].x + 0.5 * diffCardX + offsetX, y: offsetCardY + defaultTop},
          {x: cp[3].x + 0.5 * diffCardX + offsetX, y: offsetCardY + defaultTop},
        ];

        // ECran moins large : on ne conserve que 4 cartes
        if (isSmallDesktop) {
          cardPositions.value.pop();
          signPositions.value.pop();
        }

        successMax.value = 8;
        break;

      case 2:
        currentMinimumNumber = 0;
        currentMaximumNumber = 99;
        code.value = "simple";

        diffCardX = 264;
        diffCardY = 0;
        offsetCardX = 0;
        offsetCardY = 100;

        cardPositions.value = [
          {x: offsetCardX,   y: offsetCardY},
          {x: diffCardX + offsetCardX, y: offsetCardY},
          {x: diffCardX * 2 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 3 + offsetCardX, y: offsetCardY},
          {x: diffCardX * 4 + offsetCardX, y: offsetCardY},
        ];

        cp = cardPositions.value;
        offsetX = 111 - isSmallDesktop * 12;

        signPositions.value = [
          {x: cp[0].x + 0.5 * diffCardX + offsetX,   y: offsetCardY + defaultTop},
          {x: cp[1].x + 0.5 * diffCardX + offsetX,   y: offsetCardY + defaultTop},
          {x: cp[2].x + 0.5 * diffCardX + offsetX,   y: offsetCardY + defaultTop},
          {x: cp[3].x + 0.5 * diffCardX + offsetX,   y: offsetCardY + defaultTop},
        ];

        // ECran moins large : on ne conserve que 4 cartes
        if (isSmallDesktop) {
          cardPositions.value.pop();
          signPositions.value.pop();
        }

        successMax.value = 3;
        break;

      case 3:
        currentMinimumNumber = 99;
        currentMaximumNumber = 999;
        code.value = "complex";

        diffCardX = 420 - isSmallDesktop * 90;
        diffCardY = 250;
        offsetCardX = 20 - isSmallDesktop * 25;

        cardPositions.value = [
          {x: offsetCardX, y:0},
          {x: diffCardX + offsetCardX, y:0},
          {x: diffCardX * 2 + offsetCardX, y:0},
          {x: offsetCardX, y:diffCardY},
          {x: diffCardX + offsetCardX, y:diffCardY},
          {x: diffCardX * 2 + offsetCardX, y:diffCardY},
        ];

        cp = cardPositions.value;
        offsetX = 165 - isSmallDesktop * 20;

        signPositions.value = [
          {x: cp[0].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[1].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[2].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[3].x + 0.5 * diffCardX + offsetX,   y:defaultTop + diffCardY },
          {x: cp[4].x + 0.5 * diffCardX + offsetX,   y:defaultTop + diffCardY },
        ];

        successMax.value = 3;
        break;

      case 4:
        currentMinimumNumber = 999;
        currentMaximumNumber = 9999;
        code.value = "complex";

        diffCardX = 550 - isSmallDesktop * 70;
        diffCardY = 250;
        offsetCardX = 120 - isSmallDesktop * 80;

        cardPositions.value = [
          {x: offsetCardX, y:0},
          {x: diffCardX + offsetCardX, y:0},
          {x: offsetCardX, y:diffCardY},
          {x: diffCardX + offsetCardX, y:diffCardY},
        ];

        cp = cardPositions.value;
        offsetX = 218 - isSmallDesktop * 30;

        signPositions.value = [
          {x: cp[0].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[1].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[2].x + 0.5 * diffCardX + offsetX,   y:defaultTop + diffCardY},
        ];

        successMax.value = 3;
        break;

      case 5:
        currentMinimumNumber = 9999;
        currentMaximumNumber = 99999;
        code.value = "complex";

        diffCardX = 600 - isSmallDesktop * 70;
        diffCardY = 250;
        offsetCardX = 40;

        cardPositions.value = [
          {x: offsetCardX, y:0},
          {x: diffCardX + offsetCardX, y:0},
          {x: offsetCardX, y:diffCardY},
          {x: diffCardX + offsetCardX, y:diffCardY},
        ];

        cp = cardPositions.value;
        offsetX = 268 - isSmallDesktop * 30;

        signPositions.value = [
          {x: cp[0].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[1].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[2].x + 0.5 * diffCardX + offsetX,   y:defaultTop + diffCardY},
        ];

        successMax.value = 3;
        break;

      case 6:
        currentMinimumNumber = 99999;
        currentMaximumNumber = 999999;
        code.value = "complex";

        diffCardX = 670;
        diffCardY = 250;
        offsetCardX = -20;

        cardPositions.value = [
          {x: offsetCardX, y:0},
          {x: diffCardX + offsetCardX, y:0},
          {x: offsetCardX, y:diffCardY},
          {x: diffCardX + offsetCardX, y:diffCardY},
        ];

        cp = cardPositions.value;
        offsetX = 318;

        signPositions.value = [
          {x: cp[0].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[1].x + 0.5 * diffCardX + offsetX,   y:defaultTop},
          {x: cp[2].x + 0.5 * diffCardX + offsetX,   y:defaultTop + diffCardY},
        ];

        successMax.value = 3;
        break;
    }

    card5CssClass.value = cardPositions.value.length >= 5 ? '' : 'hidden';
    card6CssClass.value = cardPositions.value.length >= 6 ? '' : 'hidden';

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 100) {
      return 2;
    } else if (maxValue <= 1000) {
      return 3;
    } else if (maxValue <= 10000) {
      return 4;
    } else if (maxValue <= 100000) {
      return 5;
    } else {
      return 6;
    }
  }


  //
  // 0. Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    if ( chiffres === null || chiffres === undefined ) {

      // Tirage au sort :
      var i, chiffresPossibles = [];
      for(i=currentMinimumNumber;i<=currentMaximumNumber;i++) {
        chiffresPossibles.push(i);
      }
      shuffleArray( chiffresPossibles );

      chiffres = chiffresPossibles.slice(0, 6);
    }
    else
    {
      // Saisie manuelle
    }

    // On s'assure que les nombres sont numériques
    for(i=0;i<chiffres.length;i++) {
      chiffres[i] = parseInt(chiffres[i]);
    }

    // Nombres
    chiffre1.value = chiffres[0];
    chiffre2.value = chiffres[1];
    chiffre3.value = chiffres[2];
    chiffre4.value = chiffres[3];
    chiffre5.value = chiffres[4];
    chiffre6.value = chiffres[5];

    // Le nombre de chiffre est différent selon le niveau, on ne garde que les chiffres nécessaires
    chiffresEnOrdre.value = chiffres.slice(0, cardPositions.value.length);

    // Ordre croissant ou décroissant
    applyOrder();

    // Consigne
    if (avecConsigne) {
      playSound_Consigne();
    }

    setTimeout(initDragAndDrop, 50);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    // Suppression d'une précédente initialisation
    const cardsDraggable = $('.draggable', '.signs-and-cards').not('.draggable-initialized');
    cardsDraggable.addClass('draggable-initialized');

    gsap.set(cardsDraggable, { x:0, y:0 } );
    cardsDraggable.find('.with-dragged-style').removeClass("dragged");
    cardsDraggable.removeClass(".dragging");

    //
    // Ordre actuel
    //
    updateOrdreActuel();

    //
    // Drag and Drop
    //

    let tiles  = $(activeCardCssSelector);
    let position, cp;

    tiles.each(function(i, tile) {

      // Setup tiles with some data
      tile = $(tile);
      tile.data({ index: i });

      cp = cardPositions.value;
      if (i < cp.length) {
        position = cp[i];

        gsap.set(tile, {
          x: position.x,
          y: position.y
        });
      }
    });

    // https://codepen.io/osublake/pen/waVYvz?editors=0010
    // https://greensock.com/forums/topic/13902-drag-drop-between-two-list/
    // https://greensock.com/forums/topic/11519-dragging-a-draggable-element-out-of-a-scrollable-div/

    draggables = Draggable.create( cardsDraggable, {

        type:"x,y",
        bounds:window,
        edgeResistance:0.65,
        throwProps:true,

        onPress : function() {
          removeDraggedStyle();
        },
        onDrag : function() {
          var tile = $(this.target);
          var zone = getZone(tile);
          if (!zone) reorderTiles(true);
          if (hitTest(tile)) reorderTiles();
        },
        onDragEnd   : function() {
          const $target = $(this.target);
          $target.removeClass("dragging");
          $target.find('.with-dragged-style').removeClass("dragged");

          removeDraggedStyle();
          hitTest(this.target);
          reorderTiles();

          // On doit vérifier si l'ordre est le bon
          if (checkPositionTimeout) clearTimeout(checkPositionTimeout);
          checkPositionTimeout = setTimeout(checkPositions, 600);
        },
        onDragStart : function() {
          const $target = $(this.target);
          $target.addClass("dragging");
          $target.find('.with-dragged-style').addClass("dragged");
        },
        onRelease   : function() {
          hitTest(this.target);
          reorderTiles();
        }
      });
  }

  function removeDragAndDrop() {
    const cardsDraggable = $('.draggable', '.signs-and-cards');
    cardsDraggable.removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $(".draggable", '.clavier').find('.with-dragged-style').removeClass("dragged");
  }

  function getZone(tile) {
    var pool   = $("#cards");
    return Draggable.hitTest(tile, pool) ? pool : null;
  }

  function hitTest(tile) {

    var target;
    const overlapThreshold = "50%";
    var query = ".draggable:not(.dragging):not(.hidden)";

    // Hit test tiles that aren't moving or being dragging
    $(query).each(function(i, element) {

      if (Draggable.hitTest(tile, element, overlapThreshold)) {
        target = element;
        return false;
      }
    });

    if (target) changePosition(tile, target);
    return target;
  }

  function reorderTiles(dragging) {
    var pool   = $("#cards");
    var query = dragging ? ".draggable:not(.dragging)" : ".draggable";
    query += ":not(.hidden)";
    pool.children(query).each(moveTile);
  }

  function moveTile(index, tile) {

    tile = $(tile);
    tile.data("index", index);

    if (tile.hasClass("dragging")) return;
    tile.addClass("moving");

    let cp = cardPositions.value;
    let position = cp[index];

    gsap.to(tile, 0.25, {
      x: position.x,
      y: position.y,
      onComplete: function() {
        tile.removeClass("moving");
      }
    });
  }

  function changePosition(tile1, tile2) {

    tile1 = $(tile1);
    tile2 = $(tile2);

    // Changes tiles position on the DOM which is used to
    // index and find the position to move to
    tile1.data("index") > tile2.data("index")
        ? tile1.insertBefore(tile2)
        : tile1.insertAfter(tile2);
  }

  const signStyles = function(no) {
    let sp = signPositions.value;
    if (! Array.isArray(sp) || (no > sp.length - 1))
    {
      return "display:none;";
    }
    else
    {
      const position = sp[no];
      return "left:" + position.x + "px; top:" + position.y + "px;";
    }
  }

  function updateOrdreActuel() {

    let chiffreElement;

    chiffresOrdreActuel.value = [];

    $(activeCardCssSelector).each(function(i, element) {

      chiffreElement = parseInt( $(element).find('.card').attr('data-value') );

      /*
      cardsValues.push({
        x: gsap.getProperty(element, 'x'),
        y: gsap.getProperty(element, 'y'),
        chiffre: $(element).find('.card').attr('data-value')
      });
     */

      chiffresOrdreActuel.value.push( chiffreElement );
    });

  }

  function checkPositions() {

    if (checkPositionTimeout) clearTimeout(checkPositionTimeout);

    updateOrdreActuel();

    // console.log("checkPositions", chiffresOrdreActuel.value.join(','), chiffresEnOrdre.value.join(','));

    if ( chiffresOrdreActuel.value.join('-') === chiffresEnOrdre.value.join('-') ) {

      // On empêche l'interaction de l'activité en cours
      updateAda("lock");

      // Son de félicitations, succès et replay
      playSound_Felicitations_and_AddSuccess();

    }
  }

  function applyOrder() {
    if (ordre.value === "asc")
    {
      chiffresEnOrdre.value.sort(function(a, b) {
        return a < b ? -1 : 1;
      });
    }
    else if (ordre.value === "desc")
    {
      chiffresEnOrdre.value.sort(function(a, b) {
        return a > b ? -1 : 1;
      });
    }

    // console.log("ordre = ", ordre.value);
    // console.log('chiffresEnOrdre', chiffresEnOrdre.value.join(','));
  }

  function changeOrder(nouvel_ordre) {
    ordre.value = nouvel_ordre;
    console.log("changeOrder", nouvel_ordre)
    applyOrder();
    playSound_Consigne();
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound) {
      const soundPath = getSoundPath_Consigne(ordre.value === "asc" ? "consigne-identifier-ordonner" : "consigne-identifier-ordonner-decroissant");
      playSound(soundPath, () => {updateAda("help")});
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withSound)
    {
      const soundPathFelicitations = getSound_Felicitations();
      playSound(soundPathFelicitations, addSuccessAndReplay);
    }
    else
    {
      addSuccessAndReplay();
    }
  }

</script>

<style scoped lang="scss">

  .identifier-ordonner-v2 {

    .order-btn-parent {
      display: flex;
      justify-content:  center;
      margin: 20px;
    }

    .signs-and-cards {
      position: relative;

      .signs {
        position: relative;

        @include on-print {
          display: none;
        }

        & > * {
          position: absolute;
          display: inline-block;
        }
      }

      .cards {
        position: absolute;
        top:0;
        left:0;

        margin-top: 20px;
        width:100%;
        min-height: 300px;
        display: flex;

        @include on-print {
          position: unset !important;
          margin-top: 5px;

          flex-wrap: wrap;
          justify-content: center;
        }

        /* https://stackoverflow.com/questions/2993851/how-to-remove-an-element-from-the-flow */

        .card-parent {
          position: absolute;

          &.is-dragging {
          }

          @include on-print {

            position: unset !important;
            transform: none !important;

            display: flex;
            justify-content: center;

            &::before {
              content: "<";

              font-family: 'Titan One', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 48px;

              display: flex;
              align-items: center;
              text-align: center;
              height: 190px;

              /* White / 100 */
              color: #FFFFFF;

              /* Dark Grey/20 - 100 */
              /* border: 2px solid #333333; */
              -webkit-text-stroke-width: 2px;
              -webkit-text-stroke-color: #333333;

              /* Dropshadow */
              text-shadow: 0px 3px 0px #333333;
            }

            &:first-child::before {
              visibility: hidden;
            }

            :deep(.card .validation) {
                display: none;
            }

          }
        }
      }

      &.desc {
        .card-parent {
          @include on-print {
            &::before {
              content: ">";
            }
          }
        }
      }
    }

    &.niveau-1 {

      @include on-print {
        .signs-and-cards {
          .cards {
            .card-parent {

              &::before {
                margin: 0 20px !important;
              }

              :deep(.card .card-content > div > div) {
                width: 96px;
              }
            }
          }
        }
      }
    }

  }

</style>
