<template>
  <div class="de image-de"></div>
</template>

<style scoped lang="scss">

  .image-de {
    width: 92px;
    height: 68px;
    background-image: url(../../../../assets/images/svg/des_v2/dizaines_des_noir_et_blanc/Number_7.png);

    @include on-small-desktop {
      width: $de_small_desktop_width;
      height: $de_small_desktop_height;
    }

    @include on-mobile-landscape {
      width: $de_mobile_width;
      height: $de_mobile_height;
    }

    @include on-print {
      width: $de_print_width;
      height: $de_print_height;
    }

  }

</style>
