<template>
  <div @click="playSoundChiffre">
    <DeUniteNoirEtBlanc0 v-if="refChiffre === 0" />
    <DeUniteNoirEtBlanc1 v-else-if="refChiffre === 1" />
    <DeUniteNoirEtBlanc2 v-else-if="refChiffre === 2" />
    <DeUniteNoirEtBlanc3 v-else-if="refChiffre === 3" />
    <DeUniteNoirEtBlanc4 v-else-if="refChiffre === 4" />
    <DeUniteNoirEtBlanc5 v-else-if="refChiffre === 5" />
    <DeUniteNoirEtBlanc6 v-else-if="refChiffre === 6" />
    <DeUniteNoirEtBlanc7 v-else-if="refChiffre === 7" />
    <DeUniteNoirEtBlanc8 v-else-if="refChiffre === 8" />
    <DeUniteNoirEtBlanc9 v-else-if="refChiffre === 9" />
    <DeUniteNoirEtBlanc10 v-else-if="refChiffre === 10" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeUniteNoirEtBlanc0 from "./DeUniteNoirEtBlanc0";
import DeUniteNoirEtBlanc1 from "./DeUniteNoirEtBlanc1";
import DeUniteNoirEtBlanc2 from "./DeUniteNoirEtBlanc2";
import DeUniteNoirEtBlanc3 from "./DeUniteNoirEtBlanc3";
import DeUniteNoirEtBlanc4 from "./DeUniteNoirEtBlanc4";
import DeUniteNoirEtBlanc5 from "./DeUniteNoirEtBlanc5";
import DeUniteNoirEtBlanc6 from "./DeUniteNoirEtBlanc6";
import DeUniteNoirEtBlanc7 from "./DeUniteNoirEtBlanc7";
import DeUniteNoirEtBlanc8 from "./DeUniteNoirEtBlanc8";
import DeUniteNoirEtBlanc9 from "./DeUniteNoirEtBlanc9";
import DeUniteNoirEtBlanc10 from "./DeUniteNoirEtBlanc10";

import {ref, onMounted, watch} from "vue";
import {getSoundPath_Chiffre, playSound} from "../../../../js/utils";


//
// Reactive values
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  }
});

let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  if (props.withSound) {
    var soundPath = getSoundPath_Chiffre(refChiffre.value);
    playSound(soundPath);
  }
}

//
// Lifecycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>