<template>
  <router-view/>
</template>

<script>
  export default {
  }
</script>

<style lang="scss">


  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Quicksand-Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Quicksand'),
    url(./assets/fonts/Quicksand-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Titan One';
    font-style: normal;
    font-weight: 400;
    src: local('Quicksand'),
    url(./assets/fonts/TitanOne-Regular.ttf) format('truetype');
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding:0;
    overflow-x: hidden;

    background-color: #FFFFFF;
    border: 0 solid transparent;
    font-size: 100%;
    min-height: 100%;

    @include on-tablet {
      width: 100vw;
      height: 100vh;
    }

  }

  /* Pour le bilan imprimé des parcours, on peut avoir besoin d'imprimer plus d'une page */
  body {

    @include on-print {
      max-height: 1024px;
      overflow: hidden;
    }

    &.heigth-auto {
      @include on-print {
        max-height: unset;
        overflow: unset;
      }
    }
  }



  body {
    font-family: 'Quicksand', sans-serif;

    position: relative;
    width: 100%;
    height: 100%;

    &:after {
      @include on-print {
        content: "© Cognitice 2023";
        font-size: 12px;
        color: #CCC;

        position: absolute;
        top: 80px;
        right: 0;
        transform: translateX(100%) rotate(-90deg);
        transform-origin: bottom left;
      }
    }
  }

  div, p, h1, h2, h3 {
    margin: 0;
    padding: 0;
  }

  div {
    box-sizing: border-box;

    @include on-print {
    }
  }

  img, object, embed, canvas, video, audio, picture {
    max-width: 100%;
    height: auto;
  }

  .clear {
    clear: both;
  }

  .hidden {
    display: none !important;
  }


  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    display: flex;
    align-items: center;
  }

  .titan {
    font-family: 'Titan One', sans-serif;
    color: #FFFFFF;
    -webkit-text-stroke-color: #333333;
    margin: 0;
  }

  h1.titan {
    font-size: 96px;
    line-height: 109px;
    -webkit-text-stroke-width: 1px;
    text-shadow: 0 6px 0 #333333;
  }

  .score-panel h1.titan {
    letter-spacing: -3px;
    -webkit-text-stroke-width: 2px;
    text-shadow: none;
  }

  .score-panel h2.titan {
    font-size: 42px;
    letter-spacing: -3px;
    -webkit-text-stroke-width: 2px;
    text-shadow: none;
  }

  .sub-page h1.titan {
    font-size: 48px;
    line-height: 56px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    margin: 0;
  }

  .header h1.titan,
  h2.titan {
    font-size: 48px;
    line-height: 54px;
    -webkit-text-stroke-width: 1px;
    text-shadow: 0px 4px 0px #333333;
    letter-spacing: -2px;

    @include on-mobile-landscape {
      font-size: 42px;
      line-height: 48px;
    }
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    margin: 0;
  }

  .design h3 {
    margin: 20px 10px 10px;
  }

  h3.titan {
    font-size: 32px;
    line-height: 36px;
    -webkit-text-stroke-width: 1px;
    text-shadow: 0px 4px 0px #333333;
  }

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: -1px;
    margin: 0;
  }

  h4.titan {
    font-size: 24px;
    line-height: 28px;
    -webkit-text-stroke-width: 1px;
    text-shadow: 0px 3px 0px #333333;
  }

  p, div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }

  p.small-size, div.small-size {
    font-size: 12px;
    line-height: 15px;
  }

  p.caption, div.caption {
    font-size: 10px;
    line-height: 12px;
    font-weight: 300;
  }

  .bold-weight {
    font-weight: 700;
  }

  .medium-weight {
    font-weight: 500;
  }

  .light-weight {
    font-weight: 300;
  }


  a.svg-icon {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    text-decoration: none;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    width: 24px;
    height: 24px;
    display: inline-block;
  }

  header {
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    margin-bottom: 15px;
    padding-top: 20px;

    @include on-mobile-landscape {
      padding-top: 10px;
    }
  }

  header .content {
    display: flex;
    justify-content: space-between;
  }

  footer {
    width: 100%;
    height: 64px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-top: 26px;
  }

  footer.footer-home {
    height: 120px;
  }

  footer .content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }


  /* Scroll */

  .thin-scroll {
    scrollbar-width: thin;
    scrollbar-color: rgba(230, 230, 230, 0.5) rgba(255, 255, 255, 0);
  }

  /* Works on Chrome/Edge/Safari */
  .thin-scroll::-webkit-scrollbar {
    width: 8px;
  }

  .thin-scroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  .thin-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(230, 230, 230, 0.5);
    border-radius: 10px;
    border: 3px solid rgba(255, 255, 255, 0);
  }


  /* Content */

  .columns .column {
    float: left;
  }

  .content {
    margin: 0 auto;
    width: 1100px;
    max-height: 100vh;
    position: relative;
  }

  /* Content v2 */

  .content-v2 {

    background-image: url(./assets/images/svg/bg2.svg);
    border-radius: 32px;

    width: 100%;
    min-height: 100vh;
    padding-left: calc( 50% - 650px);
    padding-right: calc( 50% - 650px);

    @include on-min-margin-desktop {
      width: 100% !important;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include on-mobile-landscape {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include on-print {
      background-image: none;
    }


    main {
      margin-top: 40px;

      &.sommaire {
        margin-top: 0 !important;
      }

      @include on-small-height-desktop {
        margin-top: 20px;
      }

      @include on-mobile-landscape {
        margin-top: 5px;
      }

      @include on-print {
        margin-top: 10px;
      }
    }

    .sub-page {
      position: relative;
      top: 40px;
      width: 100%;
      height: calc( 100vh - 110px );
      padding: 30px 60px 30px 112px;
      background: #FFFFFF;
      border: 2px solid #666666;
      border-radius: 16px;

      @include on-small-desktop {
        padding: 30px 50px 30px 50px;
      }

      a.fermer-page-btn {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .page-header {
      justify-content: flex-end;
    }

    .niveaux {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 40px;
      padding-bottom: 20px;

      @include on-mobile-landscape {
        gap: 10px;
      }
    }

    h2 + .niveaux {
      margin-top: 20px;
    }

    .niveaux.flex2-1 > *:nth-child(3),
    .niveaux.flex1   > *,
    .niveaux.flex1-1 > * {
      flex: 100% 0 0;
    }

    .niveaux.flex1-1-column > *,
    .niveaux.flex3-2 > *:nth-child(4),
    .niveaux.flex3-2 > *:nth-child(5),
    .niveaux.flex2-2 > *,
    .niveaux.flex2-1 > *:nth-child(1),
    .niveaux.flex2-1 > *:nth-child(2) {
      flex: calc( 50% - 15px ) 0 0;

      @include on-mobile-landscape {
        flex: calc( 50% - 5px ) 0 0;
      }
    }

    .niveaux.flex4-3 > *:nth-child(1),
    .niveaux.flex4-3 > *:nth-child(2),
    .niveaux.flex4-3 > *:nth-child(3),
    .niveaux.flex4-3 > *:nth-child(4) {
      flex: calc( 25% - 23px ) 0 0;

      @include on-mobile-landscape {
        flex: calc( 50% - 5px ) 0 0;
      }
    }

    .niveaux.flex3-3 > *,
    .niveaux.flex3-2 > *:nth-child(1),
    .niveaux.flex3-2 > *:nth-child(2),
    .niveaux.flex3-2 > *:nth-child(3),
    .niveaux.flex4-3 > *:nth-child(5),
    .niveaux.flex4-3 > *:nth-child(6),
    .niveaux.flex4-3 > *:nth-child(7) {
      flex: calc( 33.333% - 20px ) 0 0;

      @include on-mobile-landscape {
        flex: calc( 50% - 5px ) 0 0;
      }
    }

    .niveaux.flex5-5 > * {
      flex: calc( 20% - 40px ) 0 0;

      @include on-mobile-landscape {
        flex: calc( 50% - 5px ) 0 0;
      }
    }

    .flex {
      display: flex;
    }

    .flex-column {
      flex-direction: column;
    }

    .flex-gap-5 {
      gap: 5px;
    }

    .flex-gap-10 {
      gap: 10px;
    }

    .flex-gap-20 {
      gap: 20px;
    }

    .flex-gap-30 {
      gap: 30px;
    }

    .flex-gap-40 {
      gap: 40px;
    }

    .flex-gap-50 {
      gap: 50px;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .flex-align-center {
      align-items: center;
    }

    .flex-align-end {
      align-items: flex-end;
    }

    .flex-order-1 {
      order: 1;
    }

    .flex-order-2 {
      order: 2;
    }

    .flex-order-3 {
      order: 3;
    }

    .flex-order-4 {
      order: 4;
    }

    .flex-order-5 {
      order: 5;
    }

    .flex-order-6 {
      order: 6;
    }

    .signe {

      font-family: 'Titan One', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;

      line-height: 55px;
      display: flex;
      align-items: center;
      text-align: center;

      /* White / 100 */
      color: #FFFFFF;

      /* Dark Grey/20 - 100 */
      /* border: 2px solid #333333; */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #333333;

      /* Dropshadow */
      text-shadow: 0px 3px 0px #333333;

      &.large {
        font-size: 96px;
        line-height: 110px;
        text-shadow: 0px 5px 0px #333333;
      }

      &.small {
        font-size: 32px;
        line-height: 36px;
        text-shadow: 0 2px 0 #333333;
      }

      @include on-tablet {
        font-size: 40px;
      }
    }

    .help-panel {

      display: flex;
      align-items: center;
      margin: 20px 0;
      pointer-events: none;

      @include on-print {
        display: none;
      }

      .help-panel-button {
        display: block;
        width: 64px;
        height: 64px;

        background: #FFFFFF;
        border: 4px solid #E6E6E6;
        border-radius: 32px 0 0 32px;
        border-right: navajowhite;

        position: relative;
        transform: translateX(4px);

        pointer-events: auto;

        a {
          display: inline-block;
          width: 60px;
          height: 68px;
          background: url(./assets/images/svg/icones_v2/Panel.svg) center / contain no-repeat;
          margin-top: 2px;
        }
      }

      .table-parent {

        background: #fff;
        border: 4px solid #E6E6E6;
        border-radius: 20px;
        padding: 3px 6px 6px;

        table.help-panel-contents {

          border-spacing: 0;

          tr > th:nth-child(1),
          tr > td:nth-child(1) {
            font-family: 'Titan One', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #666666;
            text-align: center;
          }

          thead {

            width: calc( 100% - 15px );

            a.help-panel-close-button {
              display: block;
              width: 35px;
              height: 40px;
              padding-top: 2px;
              //background: url(./assets/images/svg/icones_v2/Close.svg) top left -4px;
              background: url(./assets/images/svg/icones_v2/Close_1x.png) no-repeat;
              background-size: 100%;
              pointer-events: auto;
            }

            tr {
              padding: 0;
              margin: 0;
            }

            tr > th {
              height: 40px;
              text-align: center;
              padding-bottom: 6px;
            }

            tr > th:nth-child(1) {
              padding-top: 4px;
              padding-right: 6px;
              width: 35px;
            }

            tr > th > div {
              border-radius: 8px;
              margin-right: 3px;
              margin-left: 3px;
              padding: 5px 0;
              height: 100%;
            }

            tr > th > div > span {
              vertical-align: center;
            }

            tr > th:nth-last-child(3n + 3) > div {
              color: #58CE45;
              background: rgba(88, 206, 69, 0.1);
            }

            tr > th:nth-last-child(3n + 2) > div {
              color: #FF3F33;
              background: rgba(255, 63, 51, 0.1);
            }

            tr > th:nth-last-child(3n + 1) > div {
              color: #5BA0F1;
              background: rgba(44, 133, 237, 0.1);
            }

          }

          tbody {

            padding-right: 7px;

            tr:first-of-type > td:last-of-type {
              border-top-right-radius: 12px;
            }

            tr:last-of-type > td:last-of-type {
              border-bottom-right-radius: 12px;
            }

            tr {
              padding: 0;
              background: url(./assets/images/svg/bg2.svg) center;
            }

            tr > td {
              padding: 25px 50px 18px;
              text-align: center;
            }

            tr > td.off {
              background: none !important;
            }

            tr > td > * {
              display: inline-block;
            }

            tr > td:nth-child(1) {
              background: #fff;
              padding-left: 10px;
              padding-right: 10px;
            }

            /* tr > td:nth-child(2) { */
            tr > td {
              border-bottom: 2px solid rgba(230,230,230, 0.5);
            }

            tr > td:nth-child(3) {
              border-bottom: 2px solid #E6E6E6;
            }

            tr > td:nth-last-child(3n + 1) {
              background: rgba(44, 133, 237, 0.1);
            }

            tr > td:nth-last-child(3n + 2) {
              background: rgba(255, 63, 51, 0.1);
            }

            tr > td:nth-last-child(3n + 3) {
              background: rgba(88, 206, 69, 0.1);
            }

            tr > td:first-child {
              background: white;
              width: 25px;
            }
          }

        }
      }

      &.large-help-panel {
        .table-parent {
          table.help-panel-contents {

            thead {
              tr > th > div {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              tr > th > div > span {
                font-size: 12px;
                line-height: 15px;
              }
            }

            tbody {
              tr > td:not(:first-child) {
                padding: 25px 4px 18px;
                /* width: 96px; */
              }
            }
          }
        }
      }

    }


    /* ACTIVITES */
    .additionner .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/repertoire_additif.svg);
    }

    .ajouter-quantites-manquantes .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/ajouter_quantites_manquantes.svg);
    }

    .appariement-nombre-quantite .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/jeu-des-quantites.svg);
    }

    .complement .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/complement_a_10.svg);
    }

    .decomposer .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/decomposer.svg);
    }

    .dictee-vocale .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/dictee_vocale.svg);
    }

    .double .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/doubles_et_moities.svg);
    }

    .moitie .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/doubles_et_moities.svg);
    }

    .ecrire-un-nombre-avec-des-etiquettes .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/ecrire_nombres_avec_etiquettes.svg);
    }

    .suite .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/encadrer.svg);
    }

    .ordonner .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/identifier_ordonner.svg);
    }

    .jeu-des-quantites .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/jeu-des-quantites.svg);
    }

    .jeu-du-tableau .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/jeu_du_tableau.svg);
    }

    .jeu-des-couleurs .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/jeu_des_couleurs.svg);
    }

    .repertoire-additif .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/repertoire_additif.svg);
    }

    .operations-en-ligne .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/repertoire_en_ligne.svg);
    }

    .table-de-multiplication .niveau-button .niveau-icon,
    .multiplier .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/repertoire_multiplicatif.svg);
    }

    .repertoire-soustractif .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/repertoire_soustractif.svg);
    }

    .soustraire .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/repertoire_soustractif.svg);
    }

    .taguer .niveau-button .niveau-icon {
      background-image: url(./assets/images/svg/icones_activites_v2/taguer.svg);
    }

    .c-est-pareil-que .niveau-button .niveau-icon {
    }

    /* Eléments draggable : clavier, cards, ... */

    .with-dragged-style {

      transform-origin: left top;
      transition: transform ease-in-out 0.2s;

      &:hover  {
        /* Dropshadow Hover */
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        cursor: grab;
      }

      &.dragged {
        /* Dropshadow Drag */
        filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.4));
        transform: rotate(1deg);
        cursor: move;
      }

    }

    .not-draggable .with-dragged-style {

      &:hover  {
        filter: none;
        cursor: default;
      }

    }


    /* DESIGN */
    .test {

      .columns {
        display: flex;
      }

      .column {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: rgba(255, 255, 255, 0);
      }

      .column > div {
        align-self:flex-start;
        margin: 10px;
      }

    }

  }

  @media screen and (max-width: 1080px ) {

    /* iPad */

    .content {
      width: 960px;
    }

    .content.fullscreen {
      max-width: 960px;
    }
  }


  /* Activités */

  .activite {
    width: 100%;
    margin:0 auto;
    position: relative;
  }

  .settings-opened .activite {
    display: none;
  }

  .introduction {
    display: none;
  }

  .interaction {
    /* display: none; */
    position: relative;
    padding-top: 50px;
  }

  .interaction.avec-niveaux {
    padding-top: 65px;
  }

  .interaction .outils {
    position: absolute;
    top:0;
    right:0;
  }

  .settings-and-print-link a {
    text-decoration: none;
    margin-left: 12px;
  }

  .souris {
    background-image: url(./assets/images/svg/cursor.png);
    background-repeat: no-repeat;
    background-size: cover;
    width:80px;
    height:80px;
    display: none;
    position: absolute;
    top:0;
    right:0;
    z-index: 2999;
  }

  .regle {
    background-image: url(./assets/images/svg/ruler.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    width:420px;
    height:64px;
    margin: 10px 0 12px 0;
  }

  .dragged {
    position: relative;
    z-index:1999 !important;
  }


  /* Niveaux */

  ul.niveaux {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 20px 0;
  }

  .niveaux li {
    float: left;
    list-style-type: none;
    margin-right: 20px;
  }

  .niveaux .niveau {
    background: #FFFFFF;
    color: blue;
    border: 1px solid #15299C;
    box-sizing: border-box;
    border-radius: 16px;
    text-decoration: none;
    padding: 5px 14px;
    box-shadow: 0 0 10px rgba(21, 41, 156, 0.3);
  }

  .niveaux .niveau.courant {
    color: #FFFFFF;
    background: #15299C;
  }

  @media screen and (max-width: 1080px ) {

    /* iPad */

    .niveaux li {
      margin-right: 10px;
    }
  }
  

  /* Sommaire */

  .sommaire-demo, .activite-demo, .dedys-recommencer {
    display: inline-block;
    box-sizing: border-box;
    padding: 7px 14px 0 14px;
    background: #15299C;
    border-radius: 16px;
    height: 32px;
    vertical-align: top;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    box-shadow: 0 0 10px rgba(21, 41, 156, 0.3);
    text-decoration: none;
  }

  .dedys-recommencer {
    padding: 7px 18px 0 18px;
  }

  .activite-recommencer {
    display: inline-block;
    box-sizing: border-box;
    padding: 2px 14px 0 14px;
    height: 32px;
    vertical-align: top;
    background: #FFFFFF;
    color: #15299C;
    border: 1px solid #15299C;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(21, 41, 156, 0.3);
    text-decoration: none;
  }

  .activite-header {
    margin-top: 12px;
  }

  .activite-header > a {
    margin-left: 12px;
  }

  .activite-header, .sommaire-demo {
    float: right;
  }

  .sommaire-demo {
    margin-right: 68px;
  }



  /* Afficher les dés */

  .afficher-des {
    margin-right:10px;
    vertical-align: top;
  }

  a#afficher-des {
    float:left;
    margin-top:50px;
    line-height: 20px;
    text-decoration: none;
    color:#15299C;
  }


  /* PWA */

  .icone {
    width:20px;
    height:20px;
    display: inline-block;
  }

  .icone.show {
    background: url(./assets/images/svg/show.svg) center center / contain no-repeat;
  }

  .icone.hide {
    background: url(./assets/images/svg/hide.svg) center center / contain no-repeat;
  }

  .pwa-install {
    background: #15299C;
    border-radius: 6px;
    margin-top: 5px;
    color: #fff;
    padding: 12px 10px;
  }


  /* Dés */

  .de-parent {
    width: 168px;
    height: 128px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    margin: 16px;
    border-radius: 12px;
    background-color: #FFFFFF;
  }

  .de-parent.double {
    width: 312px;
  }

  .de-oblique .de-parent {
    width: 168px;
  }

  .de-parent.demi-hauteur {
    height: 64px;
  }

  .half-size .de-parent {
    width:66px;
    height:76px;
  }

  .half-size .de-parent.double {
    width: 168px;
  }

  .half-size .de-parent.demi-hauteur {
    height: 32px;
  }

  .de-parent.inactive {
    opacity: 0.25;
  }

  /* END AUDREY */

  .de-parent.selected,
  .de-parent.dragged,
  .draggable.dragged .de-parent {
    border-color: #FBF605;
  }

  .de-parent.no-border {
    border: none;
  }

  .de {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    box-sizing: border-box;
    width: inherit;
    height: inherit;
  }

  .deux-des .de {
    display: inline-block;
  }

  .de-bille .de-parent {
  }

  .de-bille .blocs .de-parent  {
    width: 132px;
  }

  .de-bille .de-parent.double {
    width: 156px;
  }

  .de-bille .blocs.blocs-unites .de-parent .de {
    width: 130px;
  }

  .de-bille .half-size .de-parent.double,
  .de-bille .half-size .unites .de-parent {
    width: 90px;
  }

  .de-bille .half-size .de-parent.double .de,
  .de-bille .half-size .unites .de-parent .de {
    background-size: 100%;
  }

  .de-bille .half-size .addition-des-parent .de-parent.double,
  .de-bille .half-size .addition-des-parent .unites .de-parent {
    width: 76px;
  }

  .de-bille .half-size .addition-des-parent .de-parent.double .de,
  .de-bille .half-size .addition-des-parent .unites .de-parent .de {
    background-size: 100%;
  }

  .de-bille .de {
    background-position: center;
    background-size: 115%;
  }

  /* 1. Dés classiques ------------------------------------ */

  /* 1.1a - Dés classiques fond bleu */

  .de-classique.de-fond-bleu .de.chiffre0 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_0.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre1 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_1.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre2 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_2.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre3 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_3.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre4 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_4.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre5 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_5.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre6 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_6.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre7 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_7.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre8 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_8.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre9 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_9.svg);
  }

  .de-classique.de-fond-bleu .de.chiffre10 {
    background-image: url(./assets/images/svg/des/fond_bleu/De_10.svg);
  }

  /* 1.1b - Dés classiques fond bleu version carrée */

  .de-classique.de-fond-bleu .des-carres .de.chiffre0 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_0.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre1 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_1.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre2 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_2.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre3 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_3.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre4 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_4.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre5 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_5.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre6 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_6.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre7 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_7.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre8 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_8.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre9 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_9.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre10 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_10.svg);
  }

  .de-classique.de-fond-bleu .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des_carres/fond_bleu/De_100.svg);
  }

  /* 1.2a - Dés classiques fond blanc */

  .de-classique.de-fond-blanc .de.chiffre0 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_0.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre1 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_1.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre2 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_2.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre3 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_3.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre4 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_4.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre5 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_5.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre6 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_6.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre7 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_7.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre8 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_8.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre9 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_9.svg);
  }

  .de-classique.de-fond-blanc .de.chiffre10 {
    background-image: url(./assets/images/svg/des/fond_blanc/De_10.svg);
  }

  /* 1.2b - Dés classiques fond blanc version carrée */

  .de-classique.de-fond-blanc .des-carres .de.chiffre0 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_0.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre1 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_1.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre2 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_2.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre3 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_3.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre4 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_4.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre5 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_5.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre6 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_6.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre7 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_7.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre8 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_8.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre9 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_9.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre10 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_10.svg);
  }

  .de-classique.de-fond-blanc .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc/De_100.svg);
  }

  /* 1.3a - Dés classiques noir et blanc, fond blanc */

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre0 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_0.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre1 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_1.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre2 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_2.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre3 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_3.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre4 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_4.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre5 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_5.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre6 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_6.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre7 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_7.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre8 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_8.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre9 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_9.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .de.chiffre10 {
    background-image: url(./assets/images/svg/des/fond_blanc_nb/De_10.svg);
  }

  /* 1.3b - Dés classiques noir et blanc, fond blanc version carrée */

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre0 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_0.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre1 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_1.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre2 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_2.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre3 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_3.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre4 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_4.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre5 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_5.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre6 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_6.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre7 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_7.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre8 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_8.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre9 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_9.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre10 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_10.svg);
  }

  .de-classique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des_carres/fond_blanc_nb/De_100.svg);
  }

  /* 2. Dés obliques ------------------------------------ */

  /* 2.1a - Dés obliques fond bleu */

  .de-oblique.de-fond-bleu .de.chiffre0 {
    background-image: url(./assets/images/svg/des/constellation/De_0.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre1 {
    background-image: url(./assets/images/svg/des/constellation/De_1.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre2 {
    background-image: url(./assets/images/svg/des/constellation/De_2.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre3 {
    background-image: url(./assets/images/svg/des/constellation/De_3.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre4 {
    background-image: url(./assets/images/svg/des/constellation/De_4.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre5 {
    background-image: url(./assets/images/svg/des/constellation/De_5.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre6 {
    background-image: url(./assets/images/svg/des/constellation/De_6.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre7 {
    background-image: url(./assets/images/svg/des/constellation/De_7.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre8 {
    background-image: url(./assets/images/svg/des/constellation/De_8.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre9 {
    background-image: url(./assets/images/svg/des/constellation/De_9.svg);
  }

  .de-oblique.de-fond-bleu .de.chiffre10 {
    background-image: url(./assets/images/svg/des/constellation/De_10.svg);
  }

  /* 2.1b - Dés obliques fond bleu version carrée */

  .de-oblique.de-fond-bleu .des-carres .de.chiffre0 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_0.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre1 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_1.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre2 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_2.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre3 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_3.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre4 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_4.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre5 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_5.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre6 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_6.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre7 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_7.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre8 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_8.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre9 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_9.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre10 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_10.svg);
  }

  .de-oblique.de-fond-bleu .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des_carres/constellation/De_100.svg);
  }

  /* 2.2a - Dés obliques fond blanc */

  .de-oblique.de-fond-blanc .de.chiffre0 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_0.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre1 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_1.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre2 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_2.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre3 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_3.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre4 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_4.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre5 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_5.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre6 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_6.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre7 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_7.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre8 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_8.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre9 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_9.svg);
  }

  .de-oblique.de-fond-blanc .de.chiffre10 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc/De_10.svg);
  }

  /* 2.2b - Dés obliques fond blanc version carrée */

  .de-oblique.de-fond-blanc .des-carres .de.chiffre0 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_0.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre1 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_1.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre2 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_2.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre3 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_3.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre4 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_4.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre5 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_5.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre6 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_6.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre7 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_7.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre8 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_8.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre9 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_9.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre10 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_10.svg);
  }

  .de-oblique.de-fond-blanc .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc/De_100.svg);
  }


  /* 2.3a - Dés obliques fond blanc, noir et blanc */

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre0 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_0.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre1 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_1.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre2 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_2.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre3 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_3.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre4 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_4.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre5 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_5.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre6 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_6.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre7 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_7.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre8 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_8.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre9 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_9.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .de.chiffre10 {
    background-image: url(./assets/images/svg/des/constellation_fond_blanc_nb/De_10.svg);
  }

  /* 2.2b - Dés obliques fond blanc, noir et blanc, version carrée */

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre0 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_0.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre1 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_1.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre2 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_2.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre3 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_3.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre4 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_4.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre5 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_5.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre6 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_6.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre7 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_7.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre8 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_8.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre9 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_9.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre10 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_10.svg);
  }

  .de-oblique.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des_carres/constellation_fond_blanc_nb/De_100.svg);
  }

  /* 3. Dés billes ------------------------------------ */

  /* 3.1 - Dés billes fond bleu en couleurs  */

  .de-bille.de-fond-bleu .de.chiffre0 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_0.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre1 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_1.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre2 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_2.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre3 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_3.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre4 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_4.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre5 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_5.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre6 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_6.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre7 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_7.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre8 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_8.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre9 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_9.svg);
  }

  .de-bille.de-fond-bleu .de.chiffre10 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_10.svg);
  }

  .de-bille.de-fond-bleu .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_bleu/De_100.svg);
  }

  /* 3.2 - Dés billes fond blanc en couleurs  */

  .de-bille.de-fond-blanc .de.chiffre0 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_0.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre1 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_1.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre2 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_2.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre3 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_3.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre4 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_4.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre5 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_5.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre6 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_6.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre7 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_7.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre8 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_8.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre9 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_9.svg);
  }

  .de-bille.de-fond-blanc .de.chiffre10 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_10.svg);
  }

  .de-bille.de-fond-blanc .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des/billes_couleurs_fond_blanc/De_100.svg);
  }

  /* 3.3 - Dés billes fond blanc noir et blanc */

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre0 {
    background-image: url(./assets/images/svg/des/billes_nb/De_0.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre1 {
    background-image: url(./assets/images/svg/des/billes_nb/De_1.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre2 {
    background-image: url(./assets/images/svg/des/billes_nb/De_2.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre3 {
    background-image: url(./assets/images/svg/des/billes_nb/De_3.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre4 {
    background-image: url(./assets/images/svg/des/billes_nb/De_4.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre5 {
    background-image: url(./assets/images/svg/des/billes_nb/De_5.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre6 {
    background-image: url(./assets/images/svg/des/billes_nb/De_6.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre7 {
    background-image: url(./assets/images/svg/des/billes_nb/De_7.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre8 {
    background-image: url(./assets/images/svg/des/billes_nb/De_8.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre9 {
    background-image: url(./assets/images/svg/des/billes_nb/De_9.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .de.chiffre10 {
    background-image: url(./assets/images/svg/des/billes_nb/De_10.svg);
  }

  .de-bille.de-fond-blanc-noir-et-blanc .des-carres .de.chiffre100 {
    background-image: url(./assets/images/svg/des/billes_nb/De_100.svg);
  }



  /* Blocs dizaines-unités  */

  .blocs.quantites {
    display: flex;
  }

  .blocs.quantites .quantite > .draggable,
  .blocs.quantites .quantite > .draggable > .zone {
    height: 100%;
  }

  .bloc {
    float: left;
    box-sizing: border-box;
    height:100%;
    padding-bottom: 40px;
  }

  .blocs .bloc {
    border: 1px solid #15299C;
    box-sizing: border-box;
    border-radius: 8px;
    margin:0 5px 0 0;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .blocs.quantites .bloc {
    margin: 0;
  }

  .bloc .des-dizaines-unites {
    display: flex;
    height:100%;
  }

  .bloc .des-dizaines-unites > div {
    padding: 0 5px 10px;
    position: relative;
    display: flex;
  }

  .bloc .des-dizaines-unites .unites {
    background-color: rgba(59, 143, 242, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 1.0);
    width: 90px;
  }

  .bloc .des-dizaines-unites .des {
    margin: auto;
  }

  .quantite .bloc .des-dizaines-unites .de-parent,
  .bloc .des-dizaines-unites .de-parent {
    background-color: transparent !important;
    box-shadow: none;
    margin: 4px 0 0 0;
    border-radius: 0;
  }


  .bloc .des-dizaines-unites .dizaines {
    border-right: 1px solid #15299C;
    background-color: rgba(255, 63, 51, 0.2);
    min-width: 100px;
  }

  .bloc .nombre {
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    position: absolute;
    bottom: -40px;
    width: 100%;
    border-top: solid 1px #15299C;
  }

  .bloc .nombre.dizaines {
    color: #FF3F33;
    text-align: right;
    right: -1px;
    padding-right: 7px;
    border-radius: 0 0 0 12px;
    background-color: white;
  }

  .bloc .nombre.unites {
    color: #3B8FF2;
    position: absolute;
    left: 0;
    padding-left: 5px;
    border-radius: 0 0 12px 0;
    background-color: white;
  }

  .bloc.correct .nombre.dizaines,
  .bloc.correct .nombre.unites {
    background-color: rgba(0, 255, 0, 0.25);
  }

  .bloc .des-dizaines-unites .de-parent .de {
    background-position: left 0;
  }

  .bloc .des-dizaines-unites .de-parent {
    width:80px;
    height:50px;
  }

  .bloc .des-dizaines-unites .de-parent.double {
    width:120px;
  }

  .constellation-fond-blanc .bloc .des-dizaines-unites .de-parent.double,
  .constellation .bloc .des-dizaines-unites .de-parent.double {
    width:110px;
    height:70px;
  }

  .constellation-fond-blanc .bloc .des-dizaines-unites .de.chiffre10,
  .constellation .bloc .des-dizaines-unites .de.chiffre10 {
    width: 80px;
  }



  /* Cartouches Nombre dizaine-unités */

  .cartouche-nombres-parent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cartouche-nombres-parent > div {
    margin: 10px 20px;
    font-size: 60px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
  }

  .cartouche-nombres-parent > div.de-parent {
    width: auto;
  }

  .avec-dizaines .cartouche-nombres-parent > div {
    margin: 5px 5px;
  }

  .cartouche-nombres-parent .unites-seules {
    border: 1px solid #0B154B;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 25px 34px 25px 35px;
    overflow: hidden;
  }

  .cartouche-nombres-parent .nombres .unites {
    background: transparent;
    border-radius: 0 12px 12px 0;
    color: #3B8FF2;
  }

  .cartouche-nombres-parent .nombres.unites-seules {
    color: #3B8FF2;
  }

  .cartouche-nombres-parent .nombres .dizaines {
    background: transparent;
    color: #FF3F33;
  }

  .cartouche-nombres-parent .unites-parent {
    margin:0;
  }

  .cartouche-nombres-parent .unites-parent .correction-parent {
    width:100%;
    position: absolute;
  }

  .cartouche-nombres-parent .unites-parent .correction-parent .correction {
    width:100%;
    text-align: center;
  }


  .cartouche-nombres-parent .operation {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .operation.multiplication {
    transform: scaleY(0.8);
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .unites {
    padding-right:0;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites {
    display: flex;
    align-items: center;
    height: 100%;
    border: 1px solid #0B154B;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 5px;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .unites-seules {
    text-align: center;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites > div {
    padding: 0 5px;
    height: 100%;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites > div {
    min-width: 114px;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .dizaines.pas-de-dizaines {
    display: none;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .dizaines.zone {
    border-right: 1px dashed rgba(0, 0, 0, 0.0);
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .dizaines {
    text-align: right;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .unites {
    text-align: left;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .unites:not(.pas-de-dizaines).zone {
    border-left: 1px dashed rgba(0, 0, 0, 1.0);
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .unites.pas-de-dizaines {
    text-align: center;
    width: 100%;
  }

  .cartouche-nombres-parent .nombres-dizaines-unites .unites,
  .cartouche-nombres-parent .nombres-dizaines-unites .dizaines,
  .cartouche-nombres-parent .nombres-dizaines-unites .unites-seules,
  .cartouche-nombres-parent .unites-seules,
  .cartouche-nombres-parent .nombres-dizaines-unites .unites > div,
  .cartouche-nombres-parent .nombres-dizaines-unites .dizaines > div,
  .cartouche-nombres-parent .nombres-dizaines-unites .unites-seules > div,
  .cartouche-nombres-parent .unites-seules > div,
  .nombres.unites-seules > div {
    font-weight: bold;
    font-size: 40px;
    line-height: 72px;
  }

  .cartouche-nombres-parent .zone {
    border: solid 1px rgba(0, 0, 0, 0);
    border-radius: 12px;
  }

  .cartouche-nombres-parent .zone.hilite {
    background-color: white;
  }

  .cartouche-nombres-parent .zone.filled {
    background-color: #EEEEEE;
  }

  /*
  .cartouche-nombres-parent .zone.correct {
      border-color: rgba(0, 255, 0, 0.5);
      transition-duration: 2s;
  }

  .cartouche-nombres-parent .zone.incorrect {
      border-color: rgba(255, 0, 0, 0.5);
      transition-duration: 0.5s;
  }
  */



  /* Correction */

  .correction {
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center center;
    height: 20px;
    margin: 20px 0 40px;
    float: left;
  }

  .correction.correct {
    background-image: url(assets/images/svg/correct-vert.svg);
  }

  .correction.incorrect {
    background-image: url(assets/images/svg/incorrect-rouge.svg);
  }

  .dizaines-et-unites .correction.correction-dizaines  {
    background-position: top right 8%;
  }

  .dizaines-et-unites .correction.correction-unites  {
    background-position: top left 8%;
  }

  .correction-parent.pas-de-dizaines .correction-dizaines {
    display: none;
  }

  .correction-parent.pas-de-dizaines .correction-unites {
    width: 100%;
    background-position: center center;
  }

  .unites-parent .correction.correction-unites {
    margin-top:0;
  }

  .correction {
    width: 50%;
  }

  //
  // Ordre croissant - décroissant
  //

  .order-setting {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 20px 0;
  }

  .order-setting ul {
    margin: 0;
    padding: 0;
  }

  .order-setting li {
    float: left;
    list-style: none;
  }

  .order-setting li a {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    width: 36px;
    height: 32px;
  }

  .order-setting a.ascending {
    background-image: url(./assets/images/svg/icones/ascending.svg);
  }

  .order-setting a.order-toggle {
    background-image: url(./assets/images/svg/icones/order-asc.svg);
  }

  .order-setting a.order-toggle.descending {
    background-image: url(./assets/images/svg/icones/order-desc.svg);
  }

    
  /* ADA */
  .ada {
    position: fixed;
    bottom: 0;
    left: 0;

    @include on-min-margin-desktop {
      left: 0 !important;
    }

    @include on-small-desktop {
      left: -32px !important;
      transform: scale(0.7);
      transform-origin: bottom left;
    }
  }

  .bullecomplexe {
    position: fixed;
    left:30%;
    top:30%
  }

    
  @media (min-width: 1920px) and (max-width: 2560px) {
    .ada {
        left : calc( 50vw - 960px)
     }
  }

</style>
