<template>

    <div v-if="((chiffre > 10 ) || forceDizaine)"
         :id="chiffreId"
         :data-value="chiffre"
         class="de-parent dizaines-et-unites"
         style="width: 233px;"
    >

      <div class="nombres nombres-dizaines-unites" :data-no="no">
        <div :class="cssDizaines" :data-value="dizaines" :style="styleDizaines">
          {{ textZoneDizaines }}
        </div>
        <div :class="cssUnites" :data-value="unites" :style="styleUnites">
          {{ textZoneUnites }}
        </div>
      </div>

      <!-- correction -->
      <div v-if="isHidden" class="correction-parent" :class="noDizaineCss" >
        <div class="correction correction-dizaines"></div>
        <div class="correction correction-unites"></div>
      </div>

    </div>

    <div v-else class="unites-parent">
      <div class="nombres unites-seules" :data-no="no">
        <div :id="chiffreId" class="de-parent" :class="cssZoneUnites" :data-value="unites">
          {{ textZoneUnites }}
        </div>
      </div>
      <!-- correction -->
      <div v-if="isHidden" class="correction-parent" :class="noDizaineCss" >
        <div class="correction correction-unites"></div>
      </div>
    </div>

</template>


<script setup>

  import {ref, computed, onMounted, watch} from "vue";
  import { getDizainesUnites } from "../../../js/utils";

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 1
    },
    no: {
      type: Number,
      required: false,
      default: 1
    },
    forceDizaine: {
      type: Boolean,
      required: false,
      default: false
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    dizainesWidth: {
      type: Number,
      required: false,
      default: 0
    },
    unitesWidth: {
      type: Number,
      required: false,
      default: 0
    },
  });

  let dizaines = ref(0), unites = ref(0), noDizaineCss = ref('');

  const update = function() {
    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites(chiffre);

    dizaines.value = dizainesUnites.dizaines;
    unites.value = dizainesUnites.unites;
    noDizaineCss.value = dizaines.value > 0 ? '' : 'pas-de-dizaines';

    if (props.isHidden) {
      console.log('à trouver', props.chiffre, textZoneUnites.value);
    }
  };

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });


  /* Computed */

  const chiffreId = computed(() => {
    return "chiffre" + props.no;
  });

  const textZoneDizaines = computed(() => {
    return props.isHidden ? " " : dizaines.value;
  });

  const textZoneUnites = computed(() => {
    if (unites.value === undefined) {
      return  '';
    }
    return props.isHidden ? " " : unites.value;
  });

  const cssZoneDizaines = computed(() => {
    if (dizaines.value === undefined) {
      return  '';
    }
    return (dizaines.value > 0) && props.isHidden ? 'zone' : '';
  });

  const cssZoneUnites = computed(() => {
    return props.isHidden ? 'zone' : '';
  });

  const cssDizaines = computed(() => {
    return 'dizaines ' + cssZoneDizaines.value + ' ' + noDizaineCss.value;
  });

  const cssUnites = computed(() => {
    return 'unites ' + cssZoneUnites.value + ' ' + noDizaineCss.value;
  });

  const styleDizaines = computed(() => {
    return 'width:' + props.dizainesWidth + 'px';
  });

  const styleUnites = computed(() => {
    return 'width:' + props.unitesWidth + 'px';
  });

</script>

<style scoped lang="scss">

  .de-parent {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
  }

  .half-size .de-parent {
    width: 76px;
    height: 76px;
  }

  .half-size .unites-seules {
    width: 76px;
  }

  .half-size .unites-seules > div {
    width: inherit !important;
  }

  .unites-seules {
    border: 1px solid #0B154B;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 25px 34px 25px 35px;
    overflow: hidden;
  }

  .nombres-dizaines-unites .unites,
  .nombres-dizaines-unites .dizaines,
  .nombres-dizaines-unites .unites-seules,
  .unites-seules {
    font-weight: bold;
    font-size: 40px;
    line-height: 72px;
    text-align: center;
  }

  .nombres.unites-seules {
    color: #3B8FF2;
  }

  .nombres-dizaines-unites {
    display: flex;
    align-items: center;
    height: 100%;
    border: 1px solid #0B154B;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 5px;
  }

  .nombres-dizaines-unites {
    overflow: hidden;
  }

  .nombres-dizaines-unites {
    display: flex;
    align-items: center;
    height: 100%;
    border: 1px solid #0B154B;
    box-sizing: border-box;
    border-radius: 12px;
    margin-left: 5px;
  }

  .nombres-dizaines-unites > div {
    flex: 50% 0 0;
    min-width: 114px;
  }

  .nombres-dizaines-unites > div {
    padding: 0 5px;
    height: 100%;
  }

  .nombres-dizaines-unites .unites:not(.pas-de-dizaines).zone {
    border-left: 1px dashed black;
  }

  .nombres .dizaines {
    background: transparent;
    color: #FF3F33;
  }

  .nombres .unites {
    background: transparent;
    border-radius: 0 12px 12px 0;
    color: #3B8FF2;
  }

  .nombres-dizaines-unites .dizaines {
    text-align: right;
  }

  .nombres-dizaines-unites .unites {
    text-align: left;
  }

  .nombres-dizaines-unites .unites {
    padding-right: 0;
  }

  /* iPad */

  @media screen and (max-width: 1080px ) {

    .de-parent {
      width:70px;
      height:80px;
    }

    .half-size .de-parent {
      width:50px;
      height:50px;
    }

    .half-size .de-parent.double {
      width:70px;
      height: 35px;
    }

    .unites-seules {
      margin: 25px 20px 25px 20px;
    }

  }

</style>
