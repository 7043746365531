<template>
    <!-- POPUP WIN -->
    <div ref="bullewin" class="bulle-win" :class="cssWinClass">
      <div class="bulle-win-content">
          <h1>{{ messageWin }}</h1>
          <div class="boutons">
              <router-link :to="changeLevel"  class="niveau" :class="cssButtonLevel"></router-link>
              <a href="#" class="continuer" @click="replay"></a>
          </div>
      </div>
    </div>

    <!-- ADA + bulle -->
    <div class="ada" :class="adaState" @click="readConsigne" >
        <div class="bulle-aide"></div>
        <div ref="bulle" class="ada bullesimple" :class="cssErrorClass">
            <h4>{{ message }}</h4>
        </div>
        <div ref="ellipseSmall"  class="ellipseSmall"  :class="cssEllipse"></div>
        <div ref="ellipseMiddle" class="ellipseMiddle" :class="cssEllipse"></div>
        <div ref="ellipseBig"    class="ellipseBig"    :class="cssEllipse"></div>
    </div>
</template>


<script setup>

  import {onMounted, watch, computed, ref, defineExpose} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from  "vuex";
  import {
    //randomIntFromInterval,
    playSequence,
    stopSequence,
    //stopAllAnimations,
  } from "../../js/utils";

  const route = useRoute();
  const store = useStore();

  const emit = defineEmits(['replay', 'readConsigne']);

  let $domElements = [];

  // messages
  let errorTexts = [];
  let winTexts = []
  let aideTexts = [];

  //
  let helpSeen = false;


  //
  // Reactive values
  //

  const changeLevel = ref("");

  const ellipseSmall  = ref(null);
  const ellipseMiddle = ref(null);
  const ellipseBig    = ref(null);
  const bulle = ref(null);
  const bullewin = ref(null);

  let adaState = ref();
  let message = ref();
  let messageWin = ref();

  let cssErrorClass = ref();
  let cssWinClass = ref('hide');
  let cssButtonLevel = ref();
  let cssEllipse = ref();


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required:true,
      default:1
    },
    successMax: {
      type: Number,
      required:true,
      default:5
    }
  });


  //EVENTS
  function replay() { emit('replay');}
  function readConsigne() { emit ('readConsigne') }


   const initMessages = function() {
      aideTexts = [
        "Si tu veux de l’aide clique sur moi"
      ]

      errorTexts = [
         "Encore un effort !",
         "Mauvaise réponse. Réessaye !",
         "Encore un petit effort !",
         "Essaye encore"
      ]

      winTexts = [
         "Bravo ! Tu as reussi ! Que veux tu faire ?",
         "Bravo ! Tu as gagné ! Que veux tu faire maintenant ?",
         "Bravo ! Tu as gagné ! Que veux tu faire maintenant ?"
      ]
   }

  //
  // Lifecycle hooks
  //
  onMounted(() => {
    initMessages();
    updateBulleWin();
  });

  watch( props, () => {
    initMessages();
    updateBulleWin();
  });

  const currentRouteName = computed(() => route.name);

  // BULLE WIN SETTING - affiche btn continuer ou pas
  const updateBulleWin = function() {

    const currentLevel = props.niveau;
    const nombreNiveauMax = store.getters.activiteNombreNiveau(currentRouteName.value);

    // console.log("niveau courant", currentLevel, "niveau max", nombreNiveauMax, "route", currentRouteName.value);

    initMessages();
    
    if (currentLevel < nombreNiveauMax ) {

      const currentLevel = parseInt(route.params.niveau);
      const nextLevel = parseInt(currentLevel) + 1;

      var fullpath = route.path;
      var smallpath = fullpath.slice(0, -1);

      changeLevel.value = String(smallpath + nextLevel);

      cssButtonLevel.value = "active";
    } else {
      cssButtonLevel.value = "inactive";
    }
  }

  let adaTimeout;

  const setAdaNormal = function() {
    setAda("normal");
  }

  const setAda = function(value, duration_ms = 0) {

      //messages
      let random;

      //affichage bulle + ada
      switch(value) {
        case "normal":
          adaState.value = "normal";
          cssErrorClass.value = "hide";
          cssWinClass.value = "hide";
          cssEllipse.value = "show";

          animationEllipse();
        break;

        case "error":
          adaState.value = "error";
          cssErrorClass.value = "showBulle";
          cssWinClass.value = "hide";
          cssEllipse.value = "hide";

          random = Math.floor(Math.random() * errorTexts.length);
          message.value = errorTexts[random];

          var $bulleerror = bulle.value
          animationFadeInOut($bulleerror);
        break;

        case "lock":
          adaState.value = "lock";
          cssWinClass.value = "show lock";
          cssErrorClass.value = "hide";
          cssEllipse.value = "hide";
        break;

        case "win":
          adaState.value = "win";
          cssWinClass.value = "show";
          cssErrorClass.value = "hide";
          cssEllipse.value = "hide";

          random = Math.floor(Math.random() * winTexts.length);
          messageWin.value = winTexts[random];

          var $bullewin = bullewin.value
          initAnimation();
          animationFadeIn($bullewin);
        break;

        case "help":
          if (helpSeen === false ) {
            helpSeen = true;

            adaState.value = "help";
            cssErrorClass.value = "showBulle";
            cssWinClass.value = "hide";
            cssEllipse.value = "show";

            message.value = aideTexts[0];

            var $bullehelp = bulle.value
            animationFadeInOut($bullehelp);
          }
        break;
      }

      if (adaTimeout) clearTimeout(adaTimeout);
      if (duration_ms > 0) {
        adaTimeout = setTimeout(setAdaNormal, duration_ms);
      }
   }

    //-----------------------------------------------------------
    //------------        ANIMATIONS      -----------------------
    //-----------------------------------------------------------

    // ellipse
    var sequenceEllipse;
    
    const animationEllipse = function($test = false) {

      if ($test) return;

      var $ellipseSmall  =  ellipseSmall.value;
      var $ellipseMiddle =  ellipseMiddle.value;
      var $ellipseBig    =  ellipseBig.value;

      if ($ellipseSmall && $ellipseMiddle && $ellipseBig) {

        sequenceEllipse = [];

        sequenceEllipse.push( { action : "fadeInElement", param : { element:$ellipseSmall, duration:0.2 } } );
        sequenceEllipse.push( { action : "fadeInElement", param : { element:$ellipseMiddle, duration:0.4 } } );
        sequenceEllipse.push( { action : "fadeInElement", param : { element:$ellipseBig, duration:0.6 } } );

        sequenceEllipse.push( { action : "fadeOutElement", param : { element:$ellipseSmall, duration:0.8 } } );
        sequenceEllipse.push( { action : "fadeOutElement", param : { element:$ellipseMiddle, duration:1 } } );
        sequenceEllipse.push( { action : "fadeOutElement", param : { element:$ellipseBig, duration:1.2 } } );

        sequenceEllipse.push( { action : "wait", param : 3 } );

        //if(playellipse)
        sequenceEllipse.push( { action : "callback", param : animationEllipse } );
        playSequence( sequenceEllipse );

        $domElements.push($ellipseSmall);
        $domElements.push($ellipseMiddle);
        $domElements.push($ellipseBig);
      }
   }

   // bulles error
    var sequenceBulle;
    
    const animationFadeInOut = function($obj) {
      if ($obj) {
          sequenceBulle = [];
          sequenceBulle.push( { action : "fadeInElement", param : { element:$obj, duration:0.2 } } );
          sequenceBulle.push( { action : "wait", param : 1 } );
          sequenceBulle.push( { action : "fadeOutElement", param : { element:$obj, duration:0.2 } } );

          playSequence( sequenceBulle );

          $domElements.push($obj);
      }
   }

  
   // bulles win
    var sequenceBulleWin;
    
    const animationFadeIn = function($obj) {
      if ($obj) {
        sequenceBulleWin = [];
        //sequenceBulleWin.push( { action : "wait", param : 0.2 } );
        sequenceBulleWin.push( { action : "fadeInElement", param : { element:$obj, duration:0.8 } } );

        playSequence( sequenceBulleWin );

        $domElements.push($obj);
     }
   }

   // reinit 
   const initAnimation = function() {
       //stopAllAnimations();
       cssEllipse.value = "hide";

       /* stop sequences */
       stopSequence(sequenceEllipse);
       stopSequence(sequenceBulle);
       stopSequence(sequenceBulleWin);
    

        $domElements.forEach($domElement => {
            $domElement.style.opacity = 0;
        });
   }


  defineExpose({
    setAda
  })



</script>



<style scoped lang="scss">

  /* ADA */
  .ada {
    position: fixed;
    bottom: 0;
    left:5%;

    width:224px;
    height:189px;

    background-size: 100% 100%;
    background-image: url(../../assets/images/svg/ada/AdaNormal.svg);

    @include on-mobile-landscape {
      width:140px;
      height:120px;
    }

    @include on-print {
      display: none;
    }

    /* ELLIPSE */
    .ellipseSmall,
    .ellipseMiddle,
    .ellipseBig {
      pointer-events: none;
    }

    .ellipseSmall {
      position: absolute;
      left: 108px;
      top: 67px;
      width: 32px;
      height:32px;
      background-size: 100% 100%;
      background-image: url(../../assets/images/svg/ada/ellipseSmall.svg);

      @include on-mobile-landscape {
        width:16px;
        height:16px;
        left: 70px;
        top: 45px;
      }
    }

    .ellipseMiddle {
      position: absolute;
      left: 103px;
      top:  57px;

      width: 47px;
      height:47px;
      background-size: 100% 100%;
      background-image: url(../../assets/images/svg/ada/ellipseMiddle.svg);

      @include on-mobile-landscape {
        width:23px;
        height:23px;
        left: 67px;
        top: 41px;
      }
    }

    .ellipseBig {
      position: absolute;
      left: 98px;
      top: 47px;
      width: 62px;
      height:62px;
      background-size: 100% 100%;
      background-image: url(../../assets/images/svg/ada/ellipseBig.svg);

      @include on-mobile-landscape {
        width:31px;
        height:31px;
        left: 64px;
        top: 37px;
      }
    }

    .ellipseSmall.hide,
    .ellipseMiddle.hide,
    .ellipseBig.hide {
      opacity:0;
      display:none;
    }

    .ellipseSmall.show,
    .ellipseMiddle.show,
    .ellipseBig.show {
      opacity:1;
      display:block;
    }

  }

  .ada.lock,
  .ada.normal {
    background-image: url(../../assets/images/svg/ada/AdaNormal.svg);
  }

  .ada.help {
    background-image: url(../../assets/images/svg/ada/AdaHelp.svg);
  }

  .ada.error {
    background-image: url(../../assets/images/svg/ada/AdaError.svg);
  }

  .ada.win {
    background-image: url(../../assets/images/svg/ada/AdaBravo.svg);
  }

  /* BULLES */
  .ada.help .bulle-aide {
    position:absolute;
    left: 65px;
    top: -35px;
    width:97px;
    height:83px;
    background: url(../../assets/images/svg/ada/BulleAide.svg) center / cover no-repeat;
    cursor: pointer;
  }

  .ada.help .bullesimple {
    display: none;
  }

  .ada.bullesimple {
    position:relative;
    left:0;
    top:-150px;
    display:flex;
    width:317px;
    height:113px;
    background-size: 100% 100%;
    background-image: url(../../assets/images/svg/ada/Bulle_4x.png);
    align-items: center;
    justify-content: center;
    visibility: hidden;
    pointer-events: none;
  }

  .ada.bullesimple.hide {
    visibility: hidden;
    opacity:0;
  }

  .ada.bullesimple.showBulle {
    visibility: visible;
    opacity:0;
  }

  h4 {
    margin: 0;
    padding: 10px 20px;
    text-align: center;
  }

  .bulle-win {
    z-index:10000;
    visibility: hidden;

    &.hide {
      visibility: hidden;
      opacity:0;
      pointer-events: none;
    }

    &.show {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;

      padding-top: 100px;
      padding-left: 30%;
      visibility: visible;
      opacity:0;

      .bulle-win-content {
        width:720px;
        height:330px;
        background-size: 100% 100%;
        background-image: url(../../assets/images/svg/ada/BulleBravo_4x.png);

        h1 {
          display: flex;
          justify-content: center;
          padding: 30px 60px;
        }

        .boutons {
          display: flex;
          justify-content: center;
          width:100%;
          height:70px;
          gap:30px;

          .niveau {
            width:311px;
            height:70px;
            background-size: 100% 100%;
            background-image: url(../../assets/images/svg/boutons_v2/NiveauSuivant_4x.png);

            &.inactive {
              pointer-events: none;
              opacity: 0.5;
            }
          }

          .continuer {
            width:241px;
            height:70px;
            background-size: 100% 100%;
            background-image: url(../../assets/images/svg/boutons_v2/Continuer_4x.png);
          }
        }
      }
    }

    &.lock {
      background: rgba(255, 0, 0, 0);
      .bulle-win-content {
        display: none;
      }
    }
  }

</style>