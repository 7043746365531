<template>
  <div class="content content-v2">
    <main
        :class="desSettingsCssClass"
    >

      <h1>Card</h1>

      <div class="interaction design">

        <div class="flex flex-gap-20">
          <div>
            <h3>Simple</h3>
             <Card :css-class="cardCssClass" :chiffre='195' :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='100' :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='295' :has-number="true" />
          </div>
          <div>
            <h3>Mix1</h3>
             <Card :css-class="cardCssClass" :chiffre='195' code="mix" :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='100' code="mix" :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='295' code="mix" :has-number="true" />
          </div>
          <div>
            <h3>Complex</h3>
             <Card :css-class="cardCssClass" :chiffre='195' code="complex" :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='100' code="complex" :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='295' code="complex" :has-number="true" />
          </div>
        </div>

        <h3>Mix2 / complex</h3>
        <div class="flex">
           <Card :css-class="cardCssClass" :chiffre='1000' code="mix2" :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='1000' code="complex" :has-number="true" />
        </div>
        <div class="flex">
           <Card :css-class="cardCssClass" :chiffre='1001' code="mix2" :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='1001' code="complex" :has-number="true" />
        </div>

        <h3>Mix3 / complex</h3>
        <div class="flex">
           <Card :css-class="cardCssClass" :chiffre='10000' code="mix3" :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='10000' code="complex" :has-number="true" />
        </div>
        <div class="flex">
           <Card :css-class="cardCssClass" :chiffre='10001' code="mix3" :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='10001' code="complex" :has-number="true" />
        </div>

        <h3>Mix4 / complex</h3>
        <div class="flex">
           <Card :css-class="cardCssClass" :chiffre='100000' code="mix4" :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='100000' code="complex" :has-number="true" />
        </div>
        <div class="flex">
           <Card :css-class="cardCssClass" :chiffre='100001' code="mix4" :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='100001' code="complex" :has-number="true" />
        </div>

        <h3>Le million !</h3>
         <Card :css-class="cardCssClass" :chiffre='1000000' :has-number="true" />

        <h3>Avec ou sans nombre, avec séparateur de milliers</h3>

        <div class="flex flex-gap-20">

          <div class="flex flex-column flex-gap-20">
             <Card :css-class="cardCssClass" :chiffre='1'  />
             <Card :css-class="cardCssClass" :chiffre='9' decomposition-mininum="3"  />
             <Card :css-class="cardCssClass" :chiffre='15'  />
             <Card :css-class="cardCssClass" :chiffre='97'  />
             <Card :css-class="cardCssClass" :chiffre='158'  />
             <Card :css-class="cardCssClass" :chiffre='987'  />
             <Card :css-class="cardCssClass" :chiffre='1999'  />
             <Card :css-class="cardCssClass" :chiffre='12345'  />
             <Card :css-class="cardCssClass" :chiffre='123456'  />
          </div>

          <div class="flex flex-column flex-gap-20">
             <Card :css-class="cardCssClass" :chiffre='1' :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='9' decomposition-mininum="3" :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='15' :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='987' :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='1999' :has-number="true" />
          </div>

        </div>

        <div class="flex flex-column flex-gap-20">
          <div class="flex flex-gap-20">
             <Card :css-class="cardCssClass" :chiffre='1234' :has-number="true" />
             <Card :css-class="cardCssClass" :chiffre='12345' :has-number="true" />
          </div>
           <Card :css-class="cardCssClass" :chiffre='123456' :has-number="true" />
           <Card :css-class="cardCssClass" :chiffre='123456' :has-number="true" :has-separator="false" />
        </div>

        <div class="flex flex-column flex-gap-20">
          <h3>Valeur cachée</h3>
           <Card :css-class="cardCssClass" :isHidden=true :chiffre='1999'  />
        </div>

        <div class="flex flex-column flex-gap-20">
          <h3>Table de multiplication</h3>
          <Card
              chiffre=5
              multiplication="2"
              :hasMultiplication="true"
              :css-class="cardCssClass"
          />
        </div>

        <div class="flex flex-column flex-gap-20">
          <h3>Opérations</h3>
          <div class="flex flex-gap-20">
            <Card
                chiffre=333
                operation="+"
                :hasOperation="true"
                :css-class="cardCssClass"
            />
            <Card
                chiffre=124
                operation="x"
                :hasOperation="true"
                :css-class="cardCssClass"
            />
          </div>
        </div>

      </div>

    </main>
  </div>
</template>

<script setup>

import { useStore } from 'vuex';
import Card from "../../../components/v2/Card";
import {useRoute} from "vue-router";
import {computed} from "vue";

const store = useStore();
const route = useRoute();

// Style des dés (forme, couleur)
let desSettingsCssClass = store.getters.desSettingsCssClass;

const cardCssClass = computed(() => route.path.indexOf("noir-et-blanc") === - 1 ? "" : "noir-et-blanc");

</script>
