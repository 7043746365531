<template>
  <div class="de image-de"></div>
</template>

<style scoped lang="scss">

  .image-de {
    width: 92px;
    height: 44px;
    background-image: url(../../../../assets/images/svg/des_v2/dizaines_des_noir_et_blanc/Number_6.png);
  }

</style>
