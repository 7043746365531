<template>
  <a class="close-tab-btn" href="#">
  </a>

</template>

<script>
</script>


<style scoped lang="scss">

a.close-tab-btn {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../../../assets/images/svg/close-tab.svg) center / 7px auto no-repeat;
  text-decoration: none;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

</style>
