<template>
    <label class="checkbox" :for="inputId">
      <input type="checkbox"
             :name="inputName"
             :value="inputValue"
              :id="inputId"
             @change="onClickInput($event)"
      >
      <span class="label">
        <span v-if="hasLabel">{{ props.textLabel }}</span>
      </span>
    </label>
</template>

<script setup>

//
// Propriétés du composant
//

import {computed, defineExpose, onMounted, ref} from "vue";

const props = defineProps({
  inputName: {
    type: String,
    required: false,
    default: "checkbox"
  },
  inputValue: {
    type: String,
    required: false,
    default: "checkbox-value"
  },
  textLabel: {
    type: String,
    required: false,
    default: "Label"
  },
  checked: {
    type: Boolean,
    required: false,
    default: false
  },
  hasLabel: {
    type: Boolean,
    required: false,
    default: true
  }
});


//
// Reactive value
//

const isChecked = ref(props.checked);

const inputId = computed(() => {
  return ["checkbox", props.inputName, props.inputValue].join('-');
})


//
// LifeCycle hooks
//

onMounted(()=> {
  updateChecked(props.checked);
})


//
// Fonctions spécifiques
//

const updateChecked = function(bool) {
  const inputElement = document.getElementById(inputId.value);
  if (inputElement) {
    inputElement.checked = bool;
  }
}


//
// Events
//

const emit = defineEmits(['change'])

const onClickInput = function($event) {
  const inputCheckbox = $event.target;
  isChecked.value = inputCheckbox.checked

  emit( 'change', isChecked.value, props.inputName, props.inputValue);
}


//
// Méthodes publiques
//

defineExpose({
  setCheckBox
})

function setCheckBox(bool) {
  isChecked.value = bool;
  updateChecked(bool);
}


</script>


<style scoped lang="scss">

label.checkbox {
  display: block;
  cursor: pointer;

  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #666666;

  [type="checkbox"] {
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px
  }
  [type="checkbox"][disabled]+.label {
  }
  [type="checkbox"][disabled]+.label:hover {
    cursor:default
  }
  [type="checkbox"]+.label {
    display:flex;
    align-items: center;
    position:relative
  }
  [type="checkbox"]+.label::before {
    content:"";
    display:inline-block;
    flex:0 0 56px;
    width:56px;
    height:56px;
    background: url(../../../assets/images/svg/icones_v2/Checkbox_off.svg) center / 28px auto no-repeat;
  }
  [type="checkbox"]:focus:not([disabled])+.label::after,
  [type="checkbox"]:hover:not([disabled])+.label::after {
    content:"";
    display:inline-block;
    position:absolute;
    left:-4px;
    top:-4px;
    width:25px;
    height:25px
  }
  [type="checkbox"]:checked+.label::before {
    background: url(../../../assets/images/svg/icones_v2/Checkbox_on.svg) center / 56px auto no-repeat;
    content: "";
  }
}

</style>
