<template>
  <div class="content content-v2">

    <HeaderActivite
        :title="titreActivite"
        :niveau="niveau"
        @replay="replay"
    />
    <main class="activite" >
      <Decomposer
          ref="activite"
          :niveau="niveau"
          :with-custom-values="withCustomValues"
          :values="values"
          :colonne="colonne"
          :class="niveauCss"
          :forme-des="formeDes"
          :separateur-milliers="separateurMilliers"
          :with-sound="soundActive"
          :with-validation="withValidation"
      />
    </main>

    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v2/Headers/HeaderActivite";
  import Decomposer from "../../../components/v2/Activites/Decomposer";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

  import {computed, onBeforeMount, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useStore} from "vuex";
  import {parseIntArray} from "../../../js/utils";

  const route = useRoute();
  const store = useStore();

  const pathActivite = 'decomposer';

  // Settings
  const formeDes = ref();
  const separateurMilliers = ref();
  const soundActive = ref();

  // Validation
  const withValidation = ref();

  // Niveau de l'activité
  const niveau = computed(() => parseInt(route.params.niveau) );
  const niveauCss = computed(() => "niveau-" + niveau.value );

  // Valeurs manuelles
  const values = computed(() => route.query.v === undefined ? "" : parseIntArray(route.query.v.split(',')) );
  const colonne = computed(() => route.query.colonne === "dizaines" ? false : route.query.colonne );
  const withCustomValues = computed(() => route.query.v !== undefined );

  // Titre activité :
  const titreActivite = ref('');
  const activiteInfos = store.getters.activiteFromPath(pathActivite);
  titreActivite.value = activiteInfos.titre;

  // Composant de l'activité
  const activite = ref(null);

  function replay() {
    activite.value.replay();
  }


  //
  // LifeCycle hooks
  //

  onBeforeMount(() => {
    formeDes.value = store.getters.formeDesV2();
    separateurMilliers.value = store.getters.separateurMilliers();
    soundActive.value = store.getters.isSoundActive(pathActivite);
    withValidation.value = route.query.parcours === undefined;
  })


</script>
