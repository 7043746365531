<template>
    <a href="#" id="afficher-des" @click="afficheDes($event)">
        <span  class="icone afficher-des" :class= "{show:isShow, hide:isHide}" ref="icone"></span>
        Afficher/Masquer les dés
    </a>
</template>

<script>
    export default({
        data() {
            return {
                isShow: true,
                isHide: false,
            }
        },
        methods: {
            afficheDes($event){
                $event.preventDefault();


                this.isShow = !this.isShow;
                this.isHide = !this.isHide;

                this.$emit("affiche-des", this.isShow);

            }
        }

    })
</script>