<template>
  <div class="content content-v2">
    <Breakout :niveau="niveau" />
    <ResponsiveWarning />
  </div>

</template>

<script setup>

import Breakout from "../../../components/v2/Jeux/Breakout";

import {computed} from "vue";
import {useRoute} from "vue-router";
import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";

const route = useRoute();
const niveau = computed(() => parseInt(route.params.niveau) );

</script>
