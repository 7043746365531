<template>
  <div class="content" :class="settingsCssClass">
    <HeaderActivite @toggle-settings="toggleSettings" />
    <main
        class="activite identifier-ordonner"
        :class="desSettingsCssClass"
    >
      <!-- Bouton header -->
      <div class="activite-header">
        <!-- Recommencer -->
        <ReplayBtn />
        <!-- Démo -->
        <DemoBtn />
        <!-- Saisie manuelle -->
        <ManualInputsBtn />
      </div>

      <h1>Identifier et ordonner</h1>

      <div class="interaction half-size">

        <ul class="niveaux">
          <li><a class="niveau courant" data-level="1" href="#">Niveau 1 : de 10 à 15</a></li>
          <li><a class="niveau" data-level="2" href="#">Niveau 2 : de 10 à 20</a></li>
          <li><a class="niveau" data-level="3" href="#">Niveau 3 : de 10 à 50</a></li>
          <li><a class="niveau" data-level="4" href="#">Niveau 4 : de 10 à 100</a></li>
        </ul>

        <ul class="order-setting">
          <li><a href="#"  class="order-toggle"></a></li>
        </ul>

        <div class="clear"></div>

        <div class="bg-blocs"></div>
        <div class="blocs"></div>

        <!-- pour démo -->
        <div class="souris"></div>

      </div>

    </main>
    <Footer />
    <ManualInputsPopUp
        :slots=5
        :maxlength=3
        @change="validationSaisieManuelle"
        @close="fermerSaisieManuelle"
    />
    <SettingsPanel :class="settingsCssClass" />
  </div>
</template>

<script setup>

  import HeaderActivite from "../../../components/v1/HeaderActiviteV1";
  import Footer from "../../../components/v1/FooterActivite";
  import ManualInputsBtn from "../../../components/v1/Boutons/ManualInputsBtn"
  import ManualInputsPopUp from "../../../components/v1/ManualInputsPopUp"
  import SettingsPanel from "../../../components/v1/SettingsPanel"
  import ReplayBtn from "../../../components/v1/Boutons/ReplayBtn";
  import DemoBtn from "../../../components/v1/Boutons/DemoBtn";

  import {ref, onMounted} from "vue";
  import { useStore } from 'vuex';

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    getSoundPath_Dizaine,
    getSoundPath_Unite,
    getHTMLDizainesUnites,
    getDizainesUnites,
    playSound,
    playSequence,
    stopSequence,
    stopAllAnimations,
    stopAllSounds,
    getPDF,
    addSVGToPDF,
    getURLFromBackgroundImageStyle,
    shuffleArray,
    emptyAjaxResponse
  } from "../../../js/utils";

  const store = useStore();

  // Selon niveau :
  let currentLevel = 1;
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 15;

  let activite;
  // let outils;
  let interaction;
  let souris;
  let settingsPanel;
  let orderToggle;

  const overlapThreshold = "50%";

  // Style des dés (forme, couleur)
  let desSettingsCssClass = store.getters.desSettingsCssClass;

  onMounted(() => {

    activite = $(".activite");
    // outils = $(".outils");
    interaction = $(".interaction");
    souris = $(".souris");
    settingsPanel = $(".settings-panel");


    //
    // Initializations
    //

    // Event delegation pattern :
    activite.on("click", function(e)
    {
      e.preventDefault();

      var target = $(e.target);

      if (target.is(".demo"))
      {
        demo( target );
      }
      else if (target.is(".saisie-manuelle"))
      {
        saisieManuelle( target );
      }
      else if (target.is(".replay"))
      {
        replay( target );
      }
    });

    activite.on("mousedown", function(e)
    {
      e.preventDefault();
    });

    activite.on("replay", function(e)
    {
      e.preventDefault();
      replay();
    });

    activite.on("stopDemo", function(e)
    {
      e.preventDefault();
      stopDemo();
    });

    $('.print-link').on("click", function(e)
    {
      e.preventDefault();
      printAsPDF();
    });


    /* Ordre : ascendant / descendant */

    orderToggle = $('.order-toggle');

    orderToggle.on('click', function(e) {
      e.preventDefault();
      orderToggle.toggleClass("descending");
    });


    /* Niveaux */

    $('.niveau').on("click", function(e)
    {
      e.preventDefault();

      var niveau = $(e.target);

      // Activation du lien
      var niveaux = niveau.closest(".niveaux");
      var tousLesniveaux = niveaux.find(".niveau");
      tousLesniveaux.removeClass("courant");

      niveau.addClass("courant");

      // Définition du nombre maximum du tirage au sort
      var niveauNo = niveau.data("level");

      switch(niveauNo) {

        case 1:
          currentLevel = 1;
          currentMinimumNumber = 10;
          currentMaximumNumber = 15;
          break;

        case 2:
          currentLevel = 2;
          currentMinimumNumber = 10;
          currentMaximumNumber = 20;
          break;

        case 3:
          currentLevel = 3;
          currentMinimumNumber = 10;
          currentMaximumNumber = 50;
          break;

        case 4:
          currentLevel = 4;
          currentMinimumNumber = 10;
          currentMaximumNumber = 100;
          break;
      }

      console.log(', currentLevel', currentLevel);

      initActivite();
    });


    /* Première initialisation au chargement de la page */

    // initSettingsEvents();
    // restoreSettings();

    initActivite();

  });


  //
  // 0. Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres ) {

    console.log('initActivite', chiffres);


    if ( chiffres === undefined ) {

      console.log('initActivite', ':', currentMinimumNumber, currentMaximumNumber);

      // Tirage au sort :
      var i, chiffresPossibles = [];
      for(i=currentMinimumNumber;i<=currentMaximumNumber;i++) {
        chiffresPossibles.push(i);
      }
      shuffleArray( chiffresPossibles );

      chiffres = chiffresPossibles.slice(0, 5);
    }
    else
    {
      // Saisie manuelle
    }


    // L'ordre mémorisé est l'ordre ascendant
    // L'ordre descendant est pris en compte au moment de la vérification seulement ( ce qui permet de changer l'ordre après tirage )
    var chiffresOrdonnes = chiffres.concat().sort();

    console.log( "chiffres", chiffres, "dans l'ordre", chiffresOrdonnes );

    var n = chiffres.length, chiffre, dizaine, dizainesUnites, unites, ordre;
    var blocChiffre;
    var hmltBlocsBackgrounds, htmlBlocsDraggable;

    hmltBlocsBackgrounds = htmlBlocsDraggable = getBlocInsert(0);

    for(i=0;i<n;i++) {

      chiffre = chiffres[i];

      dizainesUnites = getDizainesUnites(chiffre);
      dizaine = dizainesUnites.dizaines;
      unites = dizainesUnites.unites;

      ordre = chiffresOrdonnes.indexOf(chiffre);

      blocChiffre = getHTMLDizainesUnites( dizaine, unites, i, ordre, 'draggable' );
      blocChiffre += getBlocInsert(i + 1);

      hmltBlocsBackgrounds += blocChiffre;
      htmlBlocsDraggable += blocChiffre;
    }

    var bgBlocs = $('.bg-blocs');
    var blocs = $('.blocs');

    bgBlocs.html(hmltBlocsBackgrounds);
    blocs.html(htmlBlocsDraggable);

    var blocsDraggable = $('.draggable', blocs);


    //
    // Suppression d'une précédente initialisation
    //

    gsap.set(blocsDraggable, { x:0, y:0 } );
    blocsDraggable.removeClass(".dragged");


    var dropZones = $(".insert", blocs);
    var currentOrder;

    var draggables = Draggable.create( blocsDraggable, {

      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,

      onPress: function() {
      },

      onDragStart: function() {

        currentOrder = [];

        // On remet les bonnes valeurs de data-position sur les  zones
        var bloc;
        blocsDraggable.sort( sortBlocsByLeft );
        blocsDraggable.each(function(position, item) {

          bloc = $(item);
          bloc.attr("data-position", position);
          bloc.removeClass("correct");
          bloc.removeClass("dragged");

          currentOrder.push( bloc.attr("data-value") );
        });

        $('.zone').removeClass("parent-of-dragged-item");

        // isDragging = true;

        $(this.target).addClass("dragged");
        $(this.target).closest('.zone').addClass("parent-of-dragged-item");

        // var dragPosition = $(this.target).attr("data-position");
        // console.log("onDragStart dragPosition=", dragPosition);

        var currentInsertPositions = [];
        dropZones.each(function(no, item) {
          currentInsertPositions.push( $(item).attr("data-position") );
        });

        var chiffre = $(this.target).attr("data-value");
        var soundPath = getSoundPath_Chiffre( chiffre );
        playSound(soundPath);

        // console.log( currentInsertPositions );
      },
      onDragEnd: function() {

        console.log("--------------------- onDragEnd --------------------");

        $('.zone').removeClass("parent-of-dragged-item");

        var i = dropZones.length;
        let insertFound = false;
        var dropZone;

        var dragPosition = $(this.target).attr("data-position");

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (isNaN($(dropZone).attr('data-value'))) {
            if ( this.hitTest(dropZone, overlapThreshold) ) {

              // On vérifie qu'on pose le bloc dans une position différente de celle de départ
              var insertPosition = dropZone.attr("data-position");

              var zone = $(this.target).parent();
              var insertNextZone = zone.prev('.insert');

              var ecart = insertPosition - dragPosition;
              console.log("insertPosition", insertPosition, "dragPosition", dragPosition, " / ecart", ecart);
              if ((ecart > 1) || ( ecart < 0)) {

                insertFound = true;

                console.log("---> insertion");

                // a. On avance le bloc dans l'ordre des blocs

                // On déplace la zone et le repère d'insertion situé après lui
                zone.insertAfter( $(dropZone) );
                insertNextZone.insertAfter( zone );

                // On remet les bonnes valeurs de data-position
                // - Sur les  zones
                // - Sur les repères d'insertion

                blocsDraggable.sort( sortBlocsByLeft );
                blocsDraggable.each(function(no, item) {
                  $(item).attr("data-position", no);
                });

                dropZones.sort( sortBlocsByLeft );
                dropZones.each(function(no, item) {
                  $(item).attr("data-position", no);
                });


                // On peut vérifier si l'ordre obtenu est correct
                checkCurrentOrder();

                break;
              }
            }
          }
        }

        // Dans tous les cas, le bloc doit retourner à sa place
        gsap.set(this.target, { x:0, y:0, delay:0 });

        if (insertFound === false) {
          // Console.log('insert found');
        }

        dropZones.removeClass('hilite');

        // isDragging = false;

        $(this.target).removeClass("dragged");

      },
      onDrag: function() {

        var i = dropZones.length, dropZone;
        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (isNaN( dropZone.attr('data-value')))
          {
            if (this.hitTest(dropZone, overlapThreshold)) {
              dropZone.addClass("hilite");
            } else {
              dropZone.removeClass("hilite");
            }
          }
        }
      }
    });

    $('.nombre.dizaines').on('click', function() {
      var chiffre = $(this).text();
      var soundPath = getSoundPath_Dizaine( chiffre );
      playSound(soundPath);
    });

    $('.nombre.unites').on('click', function() {
      var chiffre = $(this).text();
      var soundPath = getSoundPath_Unite( chiffre );
      playSound(soundPath);
    });

    interaction.css("display", "block");

    return draggables;
  }

  function isAscendingOrderActive() {
    return ! orderToggle.hasClass("descending");
  }

  function getBlocInsert( position ) {
    return '<div class="insert" data-position="' + position + '"></div>';
  }

  function sortBlocsByLeft(blocA, blocB) {
    var leftA = $(blocA).offset().left;
    var leftB = $(blocB).offset().left;
    return leftA < leftB ? -1 : 1;
  }

  function sortBlocsByValue(blocA, blocB) {
    var valueA = parseInt( $(blocA).attr('data-value'));
    var valueB = parseInt( $(blocB).attr('data-value'));
    return valueA < valueB ? -1 : 1;
  }

  function checkCurrentOrder() {

    var blocs = $('.blocs');
    var bloc, nombre, ordre;
    var blocsDraggable = $('.draggable', blocs);

    var n = blocsDraggable.length;
    var ascending = isAscendingOrderActive();

    var allCorrect = true;

    blocsDraggable.each(function(position, item) {

      bloc = $(item);
      bloc.attr("data-position", position);

      nombre = parseInt( bloc.attr('data-value'));
      ordre = parseInt( bloc.attr('data-ordre'));

      if ( ! ascending ) {
        ordre = n - 1 - ordre;
      }

      bloc.removeClass("correct");

      if (ordre !== position) {
        allCorrect = false;
      }

      console.log( "nombre=", nombre, "position=", position , "ordre=", ordre );
    });

    if (allCorrect) {
      blocsDraggable.addClass("correct");
    }

  }

  function insertZone(bloc) {

    var blocs = $('.blocs');
    var blocsDraggable = $('.draggable', blocs);
    var dropZones = $('.insert', blocs);

    var fromPosition = parseInt( bloc.data('position'));
    var toPosition = parseInt( bloc.data('ordre'));

    // Insert après lequel on va insérer le bloc
    var dropZone = $('.insert[data-position='+ toPosition +']', blocs);

    // Zone du bloc déplacé et son insert situé avant la zone (qui passera après la zone déplacée)
    var zone = bloc.parent();
    var insertNextZone = zone.prev('.insert');

    // On retire la transformation du Draggable :
    bloc.css('transform', '');

    var ecart = fromPosition - toPosition;
    if (ecart !== 0) {

      // a. On avance le bloc dans l'ordre des blocs

      // On déplace la zone et le repère d'insertion situé après lui
      zone.insertAfter( dropZone );
      insertNextZone.insertAfter( zone );

      // On remet les bonnes valeurs de data-position
      // - Sur les  zones
      // - Sur les repères d'insertion

      blocsDraggable.sort( sortBlocsByLeft );
      blocsDraggable.each(function(no, item) {
        $(item).attr("data-position", no);
      });

      dropZones.sort( sortBlocsByLeft );
      dropZones.each(function(no, item) {
        $(item).attr("data-position", no);
      });

      checkCurrentOrder();
    }
  }

  //
  // 3. Démo Activité
  //

  var sequenceActions;

  function demo() {

    stopDemo();

    var draggables = resetActivite();
    console.log(draggables);

    souris.css("display", "block");
    souris.css("opacity", 1);

    var positionInteraction = interaction.offset();
    var offsetInteraction = { left: - positionInteraction.left, top: - positionInteraction.top };
    var zindex = 10;

    sequenceActions = [];

    var blocs = $('.blocs');
    var bloc, draggableElement, nombre, ordre, position, positionCorrecte;

    var zonePosition;

    var blocsDraggable = $('.draggable', blocs);

    // On trie par position en X
    blocsDraggable.sort( sortBlocsByLeft );


    var positions = [];

    blocsDraggable.each(function(no, item) {

      bloc = $(item);

      nombre = parseInt( bloc.attr('data-value'));
      ordre = parseInt( bloc.attr('data-ordre'));

      var i, n = draggables.length, draggable;
      for(i=0; i<n; i++) {
        draggable = draggables[i];
        if ( $(draggable.target).data("ordre") == bloc.data("ordre") ) {
          break;
        }
      }

      positions.push({
        left: bloc.offset().left,
        top: bloc.offset().top,
        position: no,
        nombre: nombre,
        ordre: ordre,
        draggable: draggable
      });
    });

    // On trie par valeur
    blocsDraggable.sort( sortBlocsByValue );
    blocsDraggable.each(function(no, item) {

      bloc = draggableElement = $(item);

      nombre = parseInt( bloc.attr('data-value'));
      ordre = parseInt( bloc.attr('data-ordre'));
      position = parseInt( bloc.attr('data-position'));
      positionCorrecte = parseInt( bloc.attr('data-ordre'));

      console.log(position);

      // Arrivée
      zonePosition = positions[positionCorrecte];

      var toX = zonePosition.left;
      var toY = zonePosition.top;

      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:interaction } } );
      sequenceActions.push( { action : "moveElement", param : { element:souris , target: bloc.parent(), targetOffset:offsetInteraction, duration: 1 } } );
      sequenceActions.push( { action : "fadeInElement", param : { element:souris } } );
      sequenceActions.push( { action : "addClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "reparentElement", param : { element:souris , parent:draggableElement } } );
      sequenceActions.push( { action : "moveElement", param : { element:souris , left: 0, top: 0, duration: 0 } } );
      sequenceActions.push( { action : "playSound", param : getSoundPath_Chiffre(nombre) } );

      sequenceActions.push( { action : "transformElement", param : {
          element:draggableElement,
          toX: toX,
          toY: toY,
          zindex:zindex ++,
          onComplete: insertZone
        }
      });

      sequenceActions.push( { action : "removeClass", param : { element:draggableElement, className:"dragged" } } );
      sequenceActions.push( { action : "fadeOutElement", param : { element:souris } } );
      sequenceActions.push( { action : "wait", param : 2 } );

    });


    playSequence( sequenceActions );
  }

  function stopDemo() {
    stopSequence(sequenceActions);
    stopAllAnimations();
    stopAllSounds();
  }


  //
  // 4. Rejouer Activité
  //

  function replay() {

    stopDemo();
    resetActivite();

    activite.css("display", "block");
    interaction.css("display", "block");
    settingsPanel.css("display", "none");

  }

  function resetActivite( chiffres ) {

    souris.css("display", "none");

    var draggables = initActivite( chiffres );

    return draggables;
  }

  //
  // 5. Saisie manuelle
  //

  function saisieManuelle() {
    $("#popup-saisie-manuelle input[type=text]").val('');
    $("#popup-saisie-manuelle").removeClass('open').addClass('open');
  }

  function validationSaisieManuelle(chiffres_saisis) {

    var allValid = true;
    var chiffresArray = [];
    let chiffre;

    for(let i=0; i<chiffres_saisis.length; i++) {
      chiffre = parseInt(chiffres_saisis[i]);
      if ( ! isNaN(chiffre) && (chiffre > 0))
      {
        chiffresArray.push(chiffre);
      }
      else
      {
        allValid = false;
      }
    }

    if (allValid)
    {
      resetActivite( chiffresArray );
      fermerSaisieManuelle();
    }
  }

  function fermerSaisieManuelle() {
    $("#popup-saisie-manuelle").removeClass('open');
  }

  //
  // 6. PDF
  //

  function printAsPDF() {

    var doc = getPDF('landscape');
    doc.text(5, 20, 'Identifier et ordonner');

    var desDizaines = $('.de', '.dizaines');
    var urlDizaines = getURLFromBackgroundImageStyle( desDizaines.first() );

    var cadreSVG;

    if ( $('.activite').hasClass('de-bille') || $('.activite').hasClass('de-oblique') ) {
      cadreSVG = "images/svg/cadre_identifier_ordonner_largeur_egale.svg";
    } else {
      cadreSVG = "images/svg/cadre_identifier_ordonner.svg";
    }

    var unites = $(".blocs .des-dizaines-unites > .unites");
    var unite1 = $('.de', unites[0]).first();
    var unite2 = $('.de', unites[1]).first();
    var unite3 = $('.de', unites[2]).first();
    var unite4 = $('.de', unites[3]).first();
    var unite5 = $('.de', unites[4]).first();

    var dizaines = $(".blocs .zone > .bloc");

    var nombre1 = parseInt($(dizaines[0]).attr('data-value'));
    var nombre2 = parseInt($(dizaines[1]).attr('data-value'));
    var nombre3 = parseInt($(dizaines[2]).attr('data-value'));
    var nombre4 = parseInt($(dizaines[3]).attr('data-value'));
    var nombre5 = parseInt($(dizaines[4]).attr('data-value'));

    var nombres = [
      getDizainesUnites(nombre1),
      getDizainesUnites(nombre2),
      getDizainesUnites(nombre3),
      getDizainesUnites(nombre4),
      getDizainesUnites(nombre5)
    ];

    var maxNombre = Math.max(nombre1, nombre2, nombre3, nombre4, nombre5);
    var maxDizaines = Math.floor( maxNombre / 10);

    var promiseUnite1 = unite1.hasClass('chiffre0') ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( unite1 ) });
    var promiseUnite2 = unite2.hasClass('chiffre0') ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( unite2 ) });
    var promiseUnite3 = unite3.hasClass('chiffre0') ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( unite3 ) });
    var promiseUnite4 = unite4.hasClass('chiffre0') ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( unite4 ) });
    var promiseUnite5 = unite5.hasClass('chiffre0') ? emptyAjaxResponse([]) : $.ajax({ url: getURLFromBackgroundImageStyle( unite5 ) });

    $.when(
        $.ajax({ url: cadreSVG }),
        $.ajax({ url: urlDizaines }),
        promiseUnite1,
        promiseUnite2,
        promiseUnite3,
        promiseUnite4,
        promiseUnite5
    )
        .done(function( jqXHRCadre, jqXHRDizaines, jqXHRUnites1, jqXHRUnites2, jqXHRUnites3, jqXHRUnites4, jqXHRUnites5){

          var positionCadre_x = 5;
          var offsetPositionCadre_x = 58;
          var positionCadre_y = 30;

          var dizainesSVG = jqXHRDizaines[0];

          var offsetY = 15;
          var hauteurCadre = maxDizaines * 65 + 30;
          var h = hauteurCadre - 2;
          var scale = 0.40;

          var chiffre1_percent_pos = 0.65;
          var chiffre2_percent_pos = 0.85;
          var offset_x_dizaines = 0;

          if ( $('.activite').hasClass('de-bille') ) {

            chiffre1_percent_pos = 0.5;
            chiffre2_percent_pos = 0.70;
            scale =  0.3;
            offset_x_dizaines = 0;
          }
          else if ($('.activite').hasClass('de-oblique'))
          {
            chiffre1_percent_pos = 0.5;
            chiffre2_percent_pos = 0.70;
            scale =  0.4;
            offset_x_dizaines = 5;
          }

          var cadreSVG = jqXHRCadre[0];
          var cadreSVGObject = $( cadreSVG.childNodes[0] );

          var w = parseInt(cadreSVGObject.attr('width'));
          var w_pdf = w / 5;

          cadreSVGObject.attr('height', hauteurCadre);
          cadreSVGObject.attr('viewBox', [0, 0, w, hauteurCadre].join(" "));
          cadreSVGObject.find('g').children('rect').attr('height', hauteurCadre); // fond blanc
          cadreSVGObject.children('rect').attr('height', h);                      // bord
          cadreSVGObject.children('line').attr('y2', h);                          // ligne verticale

          doc.setFontSize(14);

          var fillBloc = function(cadreSVG, jqXHRUnites, dizaines, unites, x, y) {

            // Cadre
            addSVGToPDF( doc, cadreSVGObject, x, y, "cadre");

            // Dés Dizaines
            var i, offsetMiddleY = 0.5 * (maxDizaines - dizaines) * offsetY;
            for(i=0;i<dizaines;i++)
            {
              addSVGToPDF( doc, dizainesSVG, x + 2 + offset_x_dizaines, y + 3 + i * offsetY + offsetMiddleY, "dizaines", false, scale);
            }

            var chiffre1_x = x + chiffre1_percent_pos * w_pdf;
            var chiffre2_x = x + chiffre2_percent_pos * w_pdf;
            var chiffres_y = y + 6 + maxDizaines * ( offsetY + 1);


            // Dé Unités
            if (jqXHRUnites.length > 0) {

              var unitesSVG = jqXHRUnites[0];

              addSVGToPDF( doc, unitesSVG, chiffre2_x - 1, y + 3 + 0.5 * (maxDizaines - 1) * offsetY, "unite" + unites, false, scale);
            }

            // Chiffres
            doc.setTextColor('#FF3F33');
            doc.text( chiffre1_x, chiffres_y, String(dizaines) );

            doc.setTextColor('#3B8FF2');
            doc.text( chiffre2_x, chiffres_y, String(unites) );
          };

          fillBloc(cadreSVGObject, jqXHRUnites1, nombres[0].dizaines, nombres[0].unites, positionCadre_x, positionCadre_y );
          fillBloc(cadreSVGObject, jqXHRUnites2, nombres[1].dizaines, nombres[1].unites, positionCadre_x + offsetPositionCadre_x, positionCadre_y);
          fillBloc(cadreSVGObject, jqXHRUnites3, nombres[2].dizaines, nombres[2].unites, positionCadre_x + offsetPositionCadre_x * 2, positionCadre_y);
          fillBloc(cadreSVGObject, jqXHRUnites4, nombres[3].dizaines, nombres[3].unites, positionCadre_x + offsetPositionCadre_x * 3, positionCadre_y);
          fillBloc(cadreSVGObject, jqXHRUnites5, nombres[4].dizaines, nombres[4].unites, positionCadre_x + offsetPositionCadre_x * 4, positionCadre_y);

          doc.setFontSize(14);
          doc.setTextColor('#CCCCCC');
          doc.text(5, 200, "2019 © Cognitice");

          doc.save('identifier_ordonner.pdf');

        })
        .fail(function(){
        });

  }

  //
  // 7. Settings
  //

  let settingsCssClass = ref('');

  const toggleSettings = function(bool) {
    if (bool) {
      $('.activite').trigger("stopDemo");
      settingsCssClass.value = "settings-opened";
    } else {
      $('.activite').trigger("replay");
      settingsCssClass.value = "";
    }

  };

</script>

<style lang="scss">

  .identifier-ordonner {

    .bg-blocs, .blocs {
      margin-top: 20px;
      position: absolute;
      left:0;
      top:60px;
      width:100%;
      display: flex;
    }

    .bg-blocs .bloc.draggable {
      pointer-events: none;
      background-color: rgba(255, 255, 255, 0.25);
    }

    .bg-blocs .bloc.draggable > *,
    .bg-blocs .insert {
      opacity: 0 !important;
    }

    .bg-blocs .bloc.draggable {
      opacity: 0.1;
    }

    .bloc.dragged {
      background-color: rgba(255,255,255,1);
    }

    .zone {
      position: relative;
    }

    .zone.parent-of-dragged-item {
      z-index: 9000;
    }

    .insert {
      width:2px;
      margin-right: 5px;
      background-color: red;
      opacity: 0;
    }

    .insert.hilite {
      opacity: 1;
    }

    .de-parent.vide {
      border-color: rgba(0,0,0,0);
    }

    .de-parent.no-border {
      border:none;
    }

    /* iPad */

    @media screen and (max-width: 1080px ) {

      .bg-blocs, .blocs {
        margin-top: 10px;
      }

    }

  }

</style>
