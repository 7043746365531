<template>
  <div class="niveau-button">
    <div class="niveau-outer-border">
      <div class="niveau-inner-border">
        <div class="niveau-content">
          <div class="niveau-icon"></div>
          <div class="niveau-hilite"></div>
          <div class="niveau-title">
            <h2 class="titan">
              <span v-if="titre.length">
                {{ titre }}
              </span>
              <span v-if="niveauStr.length" class="niveau">
                {{ niveauStr }}
              </span>
            </h2>
            <h3 v-if="niveau.length">
              de 1 à 10
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref, watch} from "vue";

const props = defineProps({
  titre: {
    type: String,
    required: false,
    default: ""
  },
  niveau: {
    type: Number,
    required: false,
    default: 0
  }
});

let niveau = ref(1);
let niveauStr = ref('');

const update = function() {
  niveau.value = parseInt(props.niveau);
  niveauStr.value = niveau.value > 0 ? "Niveau " + niveau.value : "";
}

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

</script>


<style scoped lang="scss">

  .niveau-button:hover {

    cursor: pointer;

    .niveau-outer-border {
      background: #FFD32C;
    }
  }

  .niveau-button {

    position: relative;
    display: block;
    height: 278px;

    @include on-mobile-landscape {
      height: 200px;
    }

    .niveau-outer-border {

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0.41%;
      height: 100%;

      /* Light Grey/90 -100 */
      background: #E6E6E6;

      /* Dark Grey/20 - 100 */
      border: 2px solid #333333;

      /* DropShadow-2px+Blur */
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #333333;
      border-radius: 16px;

      .niveau-inner-border {

        position: absolute;
        left: 12px;
        right: 12px;
        top: 12px;
        bottom: 12.34px;

        /* Colors/Green - 100 */
        background: #58CE45;

        /* Dark Grey/20 - 100 */
        border: 2px solid #333333;

        /* Dropshadow-2px */
        box-shadow: 0px 2px 0px #333333;
        border-radius: 10px;

        .niveau-content {

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          left: 1px;
          right: 1px;
          top: 1px;
          bottom: 1px;

          /* Colors/Green - 100 */
          background: #58CE45;

          /* White / 100 */
          /*border: 3px solid #FFFFFF; AUDREY */ 
          border-radius: 8px;


          .niveau-icon {
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-position: right bottom;
            background-size: auto 96%; /* AUDREY */
            background-repeat: no-repeat;
          }

          .niveau-hilite {
            position: absolute;
            z-index: 2;
            left: 2.17%;
            right: 2.17%;
            top: 3.77%;
            bottom: 48.58%;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 86.98%, rgba(255, 255, 255, 0.3) 100%);
            opacity: 0.2;
            border-radius: 4px;
          }

          .niveau-title {

            position: relative;
            z-index: 3;
            padding:20px; /* AUDREY */

            h2.titan {
              display: block;
              font-size: 28px;
              line-height: 32px;
              -webkit-text-stroke-width: 1px;
              text-shadow: 0px 3px 0px #333333;
              text-align: center;
            }

            h3 {
              color: #FFFFFF;
              font-size: 28px;
              line-height: 35px;
              font-weight: 700;
              -webkit-text-stroke-color: #333333;
              -webkit-text-stroke-width: 1px;
              text-shadow: 0px 2px 0px #333333;
              text-align: center;
              letter-spacing: -1px;
            }

            .niveau {
              display: block;
              font-size: 22px;
            }

          }

        }
      }
    }

    &.disabled {
      pointer-events: none;
      .niveau-inner-border,
      .niveau-content {
        background: #A0A0A0 !important;
      }
    }
  }
</style>

