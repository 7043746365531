<template>
    <div class="bg-palette-couleurs">
      <div class="temp"></div>
      <div class="couleurs">
        <ul>
          <li v-for="(chiffre, index) in props.paletteChiffres" :key="chiffre" 
              :class="['couleur couleur' + chiffre.no, {selected:chiffre.isSelected}, {inactive:chiffre.isInactive}]"
              :data-number="index++"
              :data-value="chiffre.color"
              ref="chiffre.color"
              @click="SelectColorClick($event, chiffre, props.paletteChiffres, props.isEnded)"
          >
          </li>
        </ul>
      </div>
    </div>
</template>

<script setup>


  //
  // Reactive values
  //
  
  /* 
    PROPS
  */
  const props = defineProps (
      { 
        isEnded : {
           type:Boolean,
           required:false,
           default:false 
        },
        paletteChiffres : { 
          type:Array, 
          required: false, 
          default: () => [
            { no:"1", color:"jaune",  isSelected:false, isInactive:true },
            { no:"2", color:"rouge",  isSelected:false, isInactive:true },
            { no:"3", color:"violet", isSelected:false, isInactive:true },
            { no:"4", color:"vert",   isSelected:false, isInactive:true },
            { no:"6", color:"bleu",   isSelected:false, isInactive:true  }
          ] 
        }
      }
  )
  
  /*
      EVENT
  */
  const emit = defineEmits(['select-color'])

  const SelectColorClick = ($event, chiffre, paletteChiffre, isEnded) => {
    $event.preventDefault();

    var curchiffre = chiffre;
    
    if (isEnded === false) {
      for (chiffre of paletteChiffre) {
        if(curchiffre.isInactive === true )
          return;

        if (chiffre !== curchiffre )
              chiffre.isSelected = false; 
          else {
              chiffre.isSelected = true;
              var my_url = 'http://acgraphik.fr/tmp/' + chiffre.color + '/Paint.svg';
              document.body.style.cursor = "url("+my_url+") 15 25, auto";

              emit( 'select-color', chiffre.color, parseInt(chiffre.no));

          }        
      }
    }

    
  }

  
</script>

<style scoped lang="scss">

  .bg-palette-couleurs {

    position: relative;
    width: 767px;
    height: 618px;
    background-image: url(../../../assets/images/svg/jeu_des_couleurs/palette.svg);

    div.temp {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      margin-top: 10px;
      margin-left: 10px;
    }

    div.couleurs {

      position: absolute;
      width: 100%;
      height: 100%;

      ul {
        position: relative;
        list-style-type: none;

        li.couleur.inactive {
          mix-blend-mode: luminosity;
          opacity: 0.5;
        }

        li.couleur1 {
          position: absolute;
          left: 324px;
          top: 284px;
          width: 174px;
          height: 225px;
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/1.svg);
        }

        li.couleur1.selected {
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/1-selected.svg);
        }

        li.couleur2 {
          position: absolute;
          left: 440px;
          top: 76px;
          width: 327px;
          height: 333px;
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/2.svg);
        }

        li.couleur2.selected {
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/2-selected.svg);
        }

        li.couleur3 {
          position: absolute;
          left: 100px;
          top: 366px;
          width: 282px;
          height: 249px;
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/3.svg);
        }

        li.couleur3.selected {
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/3-selected.svg);
        }

        li.couleur4 {
          position: absolute;
          left: 96px;
          top: 168px;
          width: 274px;
          height: 261px;
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/4.svg);
        }

        li.couleur4.selected {
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/4-selected.svg);
        }

        li.couleur6 {
          position: absolute;
          left: 254px;
          top: 8px;
          width: 295px;
          height: 287px;
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/6.svg);
        }

        li.couleur6.selected {
          background-image: url(../../../assets/images/svg/jeu_des_couleurs/6-selected.svg);
        }

      }
    }
  }

  .cursor_violet {
    cursor: url(../../../assets/images/svg/jeu_des_couleurs/violet/Paint.svg);
  }

  .cursor_orange {
    cursor: url(../../../assets/images/svg/jeu_des_couleurs/orange/Paint.svg);
  }

  .cursor_rouge {
    cursor: url(../../../assets/images/svg/jeu_des_couleurs/rouge/Paint.svg);
  }

  .cursor_vert {
    cursor: url(../../../assets/images/svg/jeu_des_couleurs/vert/Paint.svg);
  }

  .cursor_bleu {
    cursor: url(../../../assets/images/svg/jeu_des_couleurs/vert/Paint.svg);
  }


</style>
