<template>
  <div
      class="repertoire-en-ligne"
      :class="cssClass"
  >

    <div class="repertoire-en-ligne">
      <div class="columns">
        <div class="column slots-and-operations">
          <div class="column">
            <Slots
                ref="slots1"
                data-slot="1"
                class="vertical-offset-for-flex"
                :is-hidden="refChiffre1IsHidden"
                :has-validation="refChiffre1IsHidden && withValidation"
                :chiffre="refChiffre1"
                :decomposition-mininum="refDecompositionMinimum"
                :with-sound="withSound"
            />
            <OperationAddition
                :chiffre="refAddition1"
                :css-class="formeDes"
                :with-sound="withSound"
            />
            <Slots
                ref="slots2"
                data-slot="2"
                class="vertical-offset-for-flex"
                :is-hidden="refChiffre2IsHidden"
                :has-validation="refChiffre2IsHidden && withValidation"
                :chiffre="refChiffre2"
                :decomposition-mininum="refDecompositionMinimum"
                :with-sound="withSound"
            />
            <OperationAddition
                :chiffre="refAddition2"
                :css-class="formeDes"
                :with-sound="withSound"
            />
            <Slots
                ref="slots3"
                data-slot="3"
                class="vertical-offset-for-flex"
                :is-hidden="refChiffre3IsHidden"
                :has-validation="refChiffre3IsHidden && withValidation"
                :chiffre="refChiffre3"
                :decomposition-mininum="refDecompositionMinimum"
                :with-sound="withSound"
            />

            <OperationAddition
              v-if="niveauActivite >= 3"
                :chiffre="refAddition3"
                :css-class="formeDes"
                :with-sound="withSound"
            />
            <Slots
              v-if="niveauActivite >= 3"
                ref="slots4"
                data-slot="4"
                class="vertical-offset-for-flex"
                :is-hidden="refChiffre4IsHidden"
                :has-validation="refChiffre4IsHidden && withValidation"
                :chiffre="refChiffre4"
                :decomposition-mininum="refDecompositionMinimum"
                :with-sound="withSound"
            />

            <OperationAddition
              v-if="niveauActivite >= 5"
                :chiffre="refAddition4"
                :css-class="formeDes"
                :with-sound="withSound"
            />
            <Slots
                v-if="niveauActivite >= 5"
                  ref="slots5"
                  data-slot="5"
                  class="vertical-offset-for-flex"
                  :is-hidden="refChiffre5IsHidden"
                  :has-validation="refChiffre5IsHidden && withValidation"
                  :chiffre="refChiffre5"
                  :decomposition-mininum="refDecompositionMinimum"
                  :with-sound="withSound"
            />
          </div>

        </div>
      </div>
    </div>

    <ClavierChiffres
        class="clavier"
        :nb-touches-par-ligne="10"
        :avec-gommettes="true"
    />

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne"/>

  </div>
</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSound_Felicitations,
    getSoundPath_Chiffre,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Slots from "../../../components/v2/Slots";
  import OperationAddition from "../../../components/v2/Operations/OperationAddition";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();


  // Selon niveau :
  let currentMinimumNumber = 1;
  let currentMaximumNumber = 9;

  const overlapThreshold = "50%";
  let isDragging = false;
  let draggables;
  let selectedDraggable;


  //
  // Propriétés du commposant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    cssClass: {
      type: String,
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Référence du composant Slots :
  const slots1 = ref(null);
  const slots2 = ref(null);
  const slots3 = ref(null);
  const slots4 = ref(null);
  const slots5 = ref(null);

  let refChiffre1 = ref();
  let refChiffre2 = ref();
  let refChiffre3 = ref();
  let refChiffre4 = ref();
  let refChiffre5 = ref();

  let refChiffre1IsHidden = ref(false);
  let refChiffre2IsHidden = ref(false);
  let refChiffre3IsHidden = ref(false);
  let refChiffre4IsHidden = ref(false);
  let refChiffre5IsHidden = ref(false);

  let refAddition1 = ref();
  let refAddition2 = ref();
  let refAddition3 = ref();
  let refAddition4 = ref();

  let refDecompositionMinimum = ref();


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }


  //
  // Niveaux
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau;

    successMax.value = 3;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 1;
        currentMaximumNumber = 9;
        break;

      case 2:
        currentMinimumNumber = 10;
        currentMaximumNumber = 99;
        break;

      case 3:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        break;

      case 4:
        currentMinimumNumber = 1000;
        currentMaximumNumber = 9999;
        break;

      case 5:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        break;

      case 6:
        currentMinimumNumber = 100000;
        currentMaximumNumber = 999999;
        break;
    }

    // Ada
    success = 0;

    if (! niveau) console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    return 3;
  }


  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffres, avecConsigne = false ) {

  // Ada
  updateAda("normal");

  var chiffre1, chiffre2, chiffre3, chiffre4, chiffre5;
  let addition1, addition2, addition3, addition4;

  if ((chiffres === null) || (chiffres === undefined))
  {
    // Tirage au sort
    chiffre5 = randomIntFromInterval(50, 59);
    chiffre4 = randomIntFromInterval(40, 49);
    chiffre3 = randomIntFromInterval(20, 39);
    chiffre2 = randomIntFromInterval(10, 19);
    chiffre1 = randomIntFromInterval( 1, 9);

    addition1 = chiffre1 + chiffre2;
    addition2 = addition1 + chiffre3;
    addition3 = addition2 + chiffre4;
    addition4 = addition3 + chiffre5;

  } else {

    // Saisie manuelle
    chiffre1 = chiffres[0];
    chiffre2 = chiffres[1];
    chiffre3 = chiffres[2];
    chiffre4 = chiffres[3];
    chiffre5 = chiffres[4];

    addition1 = chiffre1 + chiffre2;
    addition2 = addition1 + chiffre3;
    addition3 = addition2 + chiffre4;
    addition4 = addition3 + chiffre5;
  }

  console.log("chiffre to add : ", chiffre1, ',', chiffre2, ',', chiffre3);
  console.log("addition : ", addition1, ',', addition2, "," , addition3);

  refChiffre1.value = chiffre1;
  refChiffre2.value = addition1;
  refChiffre3.value = addition2;
  refChiffre4.value = addition3;
  refChiffre5.value = addition4;

  refChiffre1IsHidden.value = false;
  refChiffre2IsHidden.value = true;
  refChiffre3IsHidden.value = true;
  refChiffre4IsHidden.value = true;
  refChiffre5IsHidden.value = true;

  refAddition1.value = chiffre2;
  refAddition2.value = chiffre3;
  refAddition3.value = chiffre4;
  refAddition4.value = chiffre5;

  refDecompositionMinimum.value = 2;

  var dropZones = $(".zone");
  dropZones.on('click', function() {
    if (! isDragging && selectedDraggable )
    {
      $('.draggable').removeClass("dragged");

      var nombre = selectedDraggable.data('value');
      var dropZone = $(this);

      // Vérification :
      checkZone(dropZone, nombre);
    }
  });

  // Consigne
  if (avecConsigne) {
    playSound_Consigne();
  }

  // Drag and Drop
  setTimeout(initDragAndDrop, 100);
}

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", ".clavier")
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create( $target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        selectedDraggable = draggableElement;
      },
      onDragStart: function(pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre_drag = draggableElement.find('.with-value').attr('data-value');
          var soundPath = getSoundPath_Chiffre(nombre_drag);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        var dropZones = $(".slot-zone");
        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value') );

        var dropZones = $(".slot-zone");
        var i = dropZones.length;
        var dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold))
          {
            // Vérification :
            checkZone(dropZone, nombre_drag)
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, { x:0, y:0, delay:0.1 });

        isDragging = false;
      },
      onDrag: function() {

        var dropZones = $(".slot-zone");
        var i = dropZones.length, dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    var dropZones = $(".slot-zone");
    dropZones.on('click', function() {
      if (! isDragging && selectedDraggable )
      {
        $('.draggable').removeClass("dragged");

        var draggableElement = selectedDraggable;
        var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value') );

        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre_drag);
      }
    });

  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $(".draggable", '.clavier').find('.with-dragged-style').removeClass("dragged");
  }

  function checkZone( dropZone, nombre ) {

    const dropSlot = parseInt( dropZone.closest('.slots').attr('data-slot'));
    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );

    console.log("checkZone", dropSlot, dropZoneNo, '-->', dropZoneCorrectValue, 'dropped', nombre);

    let composantSlots;

    if (dropSlot === 1) {
      composantSlots = slots1.value;
    } else if (dropSlot === 2) {
      composantSlots = slots2.value;
    } else if (dropSlot === 3) {
      composantSlots = slots3.value;
    } else if (dropSlot === 4) {
      composantSlots = slots4.value;
    } else if (dropSlot === 5) {
      composantSlots = slots5.value;
    }

    if (! composantSlots) return;

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      const composantSlotComplete = composantSlots.isComponentComplete();
      const composantSlotCorrect = composantSlots.isComponentCorrect();

      if (composantSlotComplete && composantSlotCorrect) {
        // Tous les slots sont bien remmplis :

        // On doit vérifier si toutes les opérations ont été complétées
        const s1 = slots1.value;
        const s1_correct = (s1 === null ) || ! refChiffre1IsHidden.value || ( s1.isComponentComplete() && s1.isComponentCorrect())

        const s2 = slots2.value;
        const s2_correct = (s2 === null ) || ! refChiffre2IsHidden.value || ( s2.isComponentComplete() && s2.isComponentCorrect())

        const s3 = slots3.value;
        const s3_correct = (s3 === null ) || ! refChiffre3IsHidden.value || ( s3.isComponentComplete() && s3.isComponentCorrect())

        const s4 = slots4.value;
        const s4_correct = (s4 === null ) || ! refChiffre4IsHidden.value || ( s4.isComponentComplete() && s4.isComponentCorrect())

        const s5 = slots5.value;
        const s5_correct = (s5 === null ) || ! refChiffre5IsHidden.value || ( s5.isComponentComplete() && s5.isComponentCorrect())

        console.log(s1_correct, s2_correct, s3_correct, s4_correct, s5_correct)

        if (s1_correct && s2_correct && s3_correct && s4_correct && s5_correct)
        {
          // Toutes les opérations ont été complétées correctement :

          // Son de validation, succès et replay
          playSound_Felicitations_and_AddSuccess();
        }
      }
      else
      {
        // Le dernier slot rempli est correct
        updateAda("normal");
      }
    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      updateAda("error", 3000);
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Sons
  //

  function playSound_Consigne() {
    if (props.withSound)
    {
      const soundPath = getSoundPath_Consigne("calcule-en-ajoutant-les-quantites-au-dessus");
      playSound(soundPath, () => { updateAda("help") });
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withSound)
    {
      const soundPathFelicitations = getSound_Felicitations();
      playSound(soundPathFelicitations, addSuccessAndReplay);
    }
    else
    {
      addSuccessAndReplay();
    }
  }

</script>

<style lang="scss">

  .repertoire-en-ligne {

    .columns {
      display: flex;
      flex-direction: column;
      padding-top: 150px;
    }

    .columns > .slots-and-operations {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .columns > .slots-and-operations > div {
      display: flex;
      align-items: center;
    }

    &.niveau-5,
    &.niveau-6 {
      @include on-print {

        .column > div {
          margin: 10px 2px !important;

          .signe-operation {
            margin-right: 0;
            transform: translate(1px, -6px);
          }

          .signe {
            font-size: 40px;
          }

          .slots-content > div > span.slot-zone {
            width: 70px;
          }

        }
      }
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    .ada {
      @include on-tablet {
        bottom: 154px;
      }
    }

  }

</style>
