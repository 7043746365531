<template>
  <SigneInferieur v-if="signeInt === 1" />
  <SigneSuperieur v-else-if="signeInt === -1" />
  <SigneEgal v-else />
</template>

<script setup>

import SigneInferieur from "./SigneInferieur";
import SigneSuperieur from "./SigneSuperieur";
import SigneEgal from "./SigneEgal";
import {computed} from "vue";

const props = defineProps({
  signe: {
    type: Number,
    required: true,
    default: 1
  }
});

const signeInt = computed(() => parseInt(props.signe) );

</script>
