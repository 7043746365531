<template>
    <a class="generer-btn" href="#"></a>
</template>

<script>
</script>


<style scoped lang="scss">

a.generer-btn {
  display: block;
  width: 119px;
  height: 48px;
  background: url(../../../assets/images/svg/boutons_v2/Generer.svg) center / contain no-repeat;
}

</style>
