<template>
  <div class="home-title dedys-logo" ></div>
</template>

<script>
export default {
  name: 'DedysLogo',
}
</script>

<style scoped lang="scss">

  .dedys-logo {
    background: url(../../../assets/images/svg/logo.svg) -7px top / contain no-repeat;
    width:120px;
    height:60px;
  }

</style>
