<template>
  <div @click="playSoundChiffre">
    <DeCentaineComplex0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeCentaineComplex1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeCentaineComplex2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeCentaineComplex3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeCentaineComplex4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeCentaineComplex5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeCentaineComplex6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeCentaineComplex7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeCentaineComplex8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeCentaineComplex9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeCentaineComplex10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeCentaineComplex0 from "./DeCentaineComplex0";
import DeCentaineComplex1 from "./DeCentaineComplex1";
import DeCentaineComplex2 from "./DeCentaineComplex2";
import DeCentaineComplex3 from "./DeCentaineComplex3";
import DeCentaineComplex4 from "./DeCentaineComplex4"
import DeCentaineComplex5 from "./DeCentaineComplex5";
import DeCentaineComplex6 from "./DeCentaineComplex6";
import DeCentaineComplex7 from "./DeCentaineComplex7";
import DeCentaineComplex8 from "./DeCentaineComplex8";
import DeCentaineComplex9 from "./DeCentaineComplex9";
import DeCentaineComplex10 from "./DeCentaineComplex10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_Centaine,
  playSound
} from "../../../../js/utils";


//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  }
});


//
// Reactive values
//


let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};


const playSoundChiffre = function() {
  var soundPath;
  if (props.withSound) {
    if (props.withSoundDecomposed)
      soundPath = getSoundPath_Centaine(refChiffre.value);
    else 
      soundPath = getSoundPath_Chiffre(refChiffre.value * 100);
    playSound(soundPath);
  } 
  
}



//
// LifeCycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>