<template>
  <div class="popup" id="popup-saisie-manuelle">
    <div class="popup-content">
      <div class="close-popup-btn"><a href="#" @click="close($event)"></a></div>
      <p class="popup-title">Configuration de l'activité</p>
      <form class="saisie">
        <ul>
          <li><input type="text" :maxlength="maxlength" v-model="chiffre1" /></li>
          <li v-if="operation.length" class="operation">{{  operation }}</li>
          <li v-if="slots >= 2"><input type="text" :maxlength="maxlength" v-model="chiffre2" /></li>
          <li v-if="operation.length" class="operation">=</li>
          <li v-if="slots >= 3"><input type="text" :maxlength="maxlength" v-model="chiffre3" /></li>
          <li v-if="slots >= 4"><input type="text" :maxlength="maxlength" v-model="chiffre4" /></li>
          <li v-if="slots >= 5"><input type="text" :maxlength="maxlength" v-model="chiffre5" /></li>
        </ul>
        <input type="submit" value="Appliquer" @click="applyInput($event)" />
      </form>
    </div>
  </div>
</template>

<script setup>

  import { ref } from "vue";

  const props = defineProps({
    slots: {
      type: Number,
      required: true,
      default: 1
    },
    maxlength: {
      type: Number,
      required: true,
      default: 3
    },
    operation: {
      type: String,
      required: false,
      default: ""
    }
  });

  const maxlength = ref(props.maxlength);

  const chiffre1 = ref("");
  const chiffre2 = ref("");
  const chiffre3 = ref("");
  const chiffre4 = ref("");
  const chiffre5 = ref("");

  const emit = defineEmits(['change', 'close'])

  const close = function($event) {
    $event.preventDefault();
    emit( 'close');
  }

  const applyInput = function($event) {
    $event.preventDefault();
    emit( 'change', [
      chiffre1.value,
      chiffre2.value,
      chiffre3.value,
      chiffre4.value,
      chiffre5.value
    ].slice(0, props.slots));
  }

</script>

<style scoped lang="scss">

  /* PopUp Saisie manuelle */

  .popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3999;
    display: none;
    background-color: rgba(255,255,255,0.85);
    padding: 100px;
  }

  .popup-content {
    background: #FFFFFF;
    border: 1px solid #15299C;
    box-sizing: border-box;
    box-shadow: 0 0 30px rgba(11, 21, 75, 0.3);
    border-radius: 12px;
    padding: 20px;
  }

  .popup.open {
    display: block;
  }

  .popup-title {
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    font-weight: bold;
    color: #15299C;
  }

  .close-popup-btn {
    float: right;
  }

  .close-popup-btn a {
    display: inline-block;
    padding: 20px;
    background-color: #999999;
    color: white;
    background: url(../../assets/images/svg/close.svg) center center / 18px 18px no-repeat;
    margin: 5px;
  }

  .popup-content input[type=text]{
    border: 1px solid #15299C;
    box-sizing: border-box;
    border-radius: 4px;
    height: 32px;
    margin: 0 10px;
  }

  .popup-content .saisie input[type=submit]{
    border: 1px solid #15299C;
    box-sizing: border-box;
    border-radius: 16px;
    background: #FFFFFF;
    height: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px 0 30px;
    display: inline-block;
    background: url(../../assets/images/svg/input-arrow.svg) 10px center / 12px 12px no-repeat;
    color:#15299C;
  }


  .saisie {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 20px 0 50px;
  }

  .saisie ul {
    margin: 0 0 50px 0;
    padding: 0;
    display: inline-block;
  }

  .saisie li {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .saisie input[type=submit] {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  .saisie input{
    height: 50px;
    text-align: center;
  }

</style>
