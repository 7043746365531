<template>
  <div class="activite pareil-que">
    <div class="columns flex flex-column flex-gap-20">

      <!-- 1ère ligne : opération + zone de drop -->
      <div class="flex flex-gap-20">
        <div class="not-draggable">
          <Card
              :chiffre="chiffreCard0"
              operation="x"
              :hasOperation="true"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="figures-only"
          />
        </div>
        <SigneEgal />
        <!-- Zone de drop -->
        <div class="drop-zone-border">
          <div class="drop-zone-inner-border" :class="validationCssClass">
            <div class="drop-zone">
            </div>
          </div>
        </div>
      </div>

      <!-- 2ème ligne : propositions d'opération -->
      <div class="flex flex-gap-10 flex-wrap">
        <div class="draggable">
          <Card
              :chiffre="chiffreCard1"
              :data-value="chiffreCard1"
              operation="+"
              :hasOperation="true"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreCard2"
              :data-value="chiffreCard2"
              operation="+"
              :hasOperation="true"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
        <div class="draggable">
          <Card
              :chiffre="chiffreCard3"
              :data-value="chiffreCard3"
              operation="+"
              :hasOperation="true"
              :css-class="formeDes"
              :has-separator="separateurMilliers"
              :with-sound="withSound"
              class="with-value"
          />
        </div>
      </div>
    </div>

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne"/>

  </div>
</template>

<script setup>

  import {onMounted, watch, ref, defineExpose, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from "jquery";
  import { gsap } from "gsap";
  import { Draggable } from "gsap/Draggable";
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Multiplication,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval,
    shuffleArray,
    getSound_Felicitations
  } from "../../../js/utils";

  import Card from "../Card";
  import SigneEgal from "../Signes/SigneEgal";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();


  // Selon niveau :
  var currentMinimumNumber = 1;
  var currentMaximumNumber = 9;

  const overlapThreshold = "50%";

  let isDragging = false;
  let selectedDraggable;
  let draggables;
  let bonneReponse;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Multiplication
  let chiffreCard0 = ref();

  // Additions
  let chiffreCard1 = ref();
  let chiffreCard2 = ref();
  let chiffreCard3 = ref();

  let validationCssClass = ref('');


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }



  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }


  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function(niveau) {

    niveauActivite.value = niveau

    switch (niveau) {

      default:
      case 1:
        currentMinimumNumber = 2;
        currentMaximumNumber = 5;
        successMax.value = 5;
        break;
    }

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  }

  const getNiveauFromValues = function() {
    return 1;
  }



  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite(chiffre, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    // Suppression d'une précédente initialisation
    gsap.set($('.draggable'), { x:0, y:0 } );
    removeDraggedStyle();

    let m1, m2;

    if ((chiffre === null) || (chiffre === undefined))
    {
      // Tirage au sort des chiffres
      m1 = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
      m2 = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
    }
    else
    {
      // Saisie manuelle
      m1 = chiffre[0];
      m2 = chiffre[1];
    }

    const m1Str = String(m1);
    const m2Str = String(m2);

    // Multiplication :
    const chiffre0Str = m1Str + m2Str;

    let i, chiffre1Str = "", chiffre2Str = "", chiffre3Str = "", chiffre4Str = "", chiffre5Str, chiffre6Str;

    for(i=0; i<m1; i++) {

      // Bonne réponse
      chiffre1Str += m2Str;

      // Réponse ; 1, 2, 3...
      chiffre2Str += (i+1);

      // Mauvaise réponse : un chiffre en moins
      if (i<m1-1) {
        chiffre4Str += m2Str;
      }
    }

    // On mémorise la bonne réponse (pour tester après Drag and drop)
    bonneReponse = chiffre1Str;

    // Mauvaise réponse : un chiffre en plus
    chiffre3Str = chiffre1Str + m2Str;

    // Mauvaise réponse : les mêmes chiffres avec additions (au lieu de multiplication)
    // sauf pour 2 x 2
    chiffre5Str = m1Str + m2Str;
    chiffre6Str = m2Str + m1Str;

    // On constitue le tableau de propositions (avec la bonne proposition a minima)
    const chiffres = [chiffre1Str];

    // On tire au sort les mauvaises propositions
    const mauvaisChiffres = [chiffre2Str, chiffre3Str];
    if (chiffre4Str.length > 1) {
      mauvaisChiffres.push(chiffre4Str);
    }

    // On ne prend pas le cas 2 x 2 = 2 + 2
    if ((m1 !== 2) || (m2 !== 2)) {
      mauvaisChiffres.push(chiffre5Str);

      if (m1 !== m2) {
        mauvaisChiffres.push(chiffre6Str);
      }
    }

    // On tire au sort les mauvais chiffres
    shuffleArray(mauvaisChiffres);

    // On complète la liste
    chiffres.push(mauvaisChiffres[0]);
    chiffres.push(mauvaisChiffres[1]);

    // On mélange les propositions
    shuffleArray(chiffres);

    chiffreCard0.value = parseInt(chiffre0Str);
    chiffreCard1.value = parseInt(chiffres[0]);
    chiffreCard2.value = parseInt(chiffres[1]);
    chiffreCard3.value = parseInt(chiffres[2]);

    validationCssClass.value = "";

    // Son de la consigne :
    playSound_Consigne_CurrentNumber(m1, m2, avecConsigne);

    // Drag and Drop
    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", ".pareil-que")
    $target.addClass('draggable-initialized');

    let currentCardInDropZone;
    let draggableDefaultOffset;

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create( $target, {
      type:"x,y",
      bounds:window,
      edgeResistance:0.65,
      throwProps:true,
      onPress: function(pointEvent) {

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        draggableElement.find('.with-dragged-style').addClass("dragged");

        /*
          if (props.withSOund) {
            var nombre_drag = draggableElement.find('.with-value').attr('data-value');
            var soundPath = getSoundPath_Chiffre(nombre_drag);
            playSound(soundPath);
          }
         */

        selectedDraggable = draggableElement;

        // On note la position d'origine sur la palette
        var target = this.target;
        var draggableTarget = $(target);
        draggableDefaultOffset = draggableTarget.offset();

        // On tient compte du fait que l'étiquette a peut-être déjà été mise dans un slot via le drag and drop : on doit retirer ce déplacement
        draggableDefaultOffset.left -= gsap.getProperty(target, 'x');
        draggableDefaultOffset.top -= gsap.getProperty(target, 'y');
      },
      onDragStart: function() {

        /*
          if (props.withSOund) {
            var draggableElement = $(pointEvent.target).closest('.draggable');
            var nombre_drag = draggableElement.find('.with-value').attr('data-value');
            var soundPath = getSoundPath_Chiffre(nombre_drag);
            playSound(soundPath);
          }
         */

        isDragging = true;
        selectedDraggable = null;

        var dropZones = $(".slot-zone");
        dropZones.css("pointer-events", "none");

        if (currentCardInDropZone === this.target) {
          validationCssClass.value = '';
        }
      },
      onDragEnd: function(pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        setTimeout(function() {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre_drag = draggableElement.find('.with-value').attr('data-value');

        var dropZones = $(".drop-zone");
        var i = dropZones.length;
        var dropZone, dropZoneFound = false;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold))
          {
            dropZoneFound = true;

            if (currentCardInDropZone !== this.target) {
              gsap.set(currentCardInDropZone, {x: 0, y: 0});
              currentCardInDropZone = this.target
            }

            // Snap
            const dropZoneOffset = dropZone.offset();
            const dx = dropZoneOffset.left - draggableDefaultOffset.left;
            const dy = dropZoneOffset.top - draggableDefaultOffset.top;
            gsap.set(this.target, {x: dx, y: dy});

            // Vérification :
            const isCorrect = nombre_drag === bonneReponse;
            validationCssClass.value = isCorrect ? 'is-correct' : 'is-wrong';

            if (isCorrect)
            {
              // Son de validation, succès et replay
              playSound_Validation_and_AddSuccess();

            } else {
              updateAda("error", 3000);
            }

          }
        }

        if (! dropZoneFound) {
          // Retour à la position initiale
          gsap.set(this.target, { x:0, y:0, delay:0.1 });
        }

        isDragging = false;
      },
      onDrag: function() {

        var dropZones = $(".slot-zone");
        var i = dropZones.length, dropZone;

        while (--i > -1)
        {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    var dropZones = $(".drop-zone");
    dropZones.on('click', function() {
      if (! isDragging && selectedDraggable )
      {
        $('.draggable').removeClass("dragged");

        var draggableElement = selectedDraggable;
        var nombre_drag = parseInt( draggableElement.find('.with-value').attr('data-value') );

        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre_drag);
      }
    });

  }

  function removeDragAndDrop() {
    $(".draggable", '.pareil-que').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  function checkZone(dropZone, nombre_drag) {
    console.log('check zone', dropZone, nombre_drag);
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Sons
  //

  function playSound_Consigne_CurrentNumber(m1, m2, avecConsigne) {
    if (props.withSound)
    {
      const soundPath = getSoundPath_Multiplication(m2, m1);
      if (avecConsigne) {
        // Consigne + opération
        const soundPathConsigne = getSoundPath_Consigne("trouve-la-bonne-carte-celle-qui-est-pareille");
        playSound([soundPathConsigne, soundPath]);
      } else {
        // Opération
        playSound(soundPath);
      }
    }
  }

  function playSound_Consigne() {
    if (props.withSound)
    {
      const soundPath = getSoundPath_Consigne("trouve-la-bonne-carte-celle-qui-est-pareille");
      playSound(soundPath, () => { updateAda("help") });
    }
  }

  function playSound_Validation_and_AddSuccess() {
    if (props.withSound)
    {
      const soundPathFelicitations = getSound_Felicitations();
      playSound(soundPathFelicitations, addSuccessAndReplay);
    }
    else
    {
      addSuccessAndReplay();
    }
  }

</script>
  
<style scoped lang="scss">

  .pareil-que {

    @include on-small-height-desktop {
      padding-top: 10px;
    }

    @include on-print {
      margin-top: -4px;

      .flex-gap-10,
      .flex-gap-20 {
        gap: 0;
      }
    }

    .columns {

      & > div:first-child {
        height: 228px;

        & div:first-child {
          align-self: center;
        }
      }

      & > div:last-child {
        justify-content: center;
      }

      .drop-zone-border {
        width: 100%;

        flex-grow: 2;

        display: inline-block;
        background: #FFFFFF;
        border-radius: 22px;
        border: solid 8px #FFF;

        .drop-zone-inner-border {

          width: 100%;
          height: 100%;
          background: #F2F2F2;
          border: 4px solid #FFAB02;
          border-radius: 16px;

          position: relative;
          overflow: hidden;

          &.is-correct {
            border-color: #58CE45;
          }

          &.is-wrong {
            border-color: #FF3F33;
          }


          &::before {
            content: "";
            display: block;

            position: absolute;
            width: 6.64px;
            height: 174.92px;
            left: -15.46px;
            top: -20.19px;

            /* White / 100 */
            background: #FFFFFF;
            opacity: 0.5;
            transform: rotate(30deg);
          }

          &::after {
            content: "";
            display: block;

            position: absolute;
            width: 23.96px;
            height: 169.69px;
            left: 0.77px;
            top: -22.75px;

            /* White / 100 */
            background: #FFFFFF;
            opacity: 0.5;
            transform: rotate(30deg);
          }

          .drop-zone {
            width: 100%;
            height: 100%;
          }

        }
      }
    }

    .card.figures-only {
       .card-content > div > div > span:first-child {
         display: none;
       }
       .card-content > div > div > span:last-child {
         height: 180px;
       }
    }
}

</style>
