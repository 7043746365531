<template>
  <div class="content">
    <HeaderHome @toggle-settings="toggleSettings" />
    <main class="sommaire" :class="settingsCssClass">

      <!-- Bouton header -->
      <DemoBtn class="sommaire-demo hidden" />

      <h1>Activités</h1>

      <div class="activites-sommaire">
        <router-link to="/archives/dictee-vocale" class="activite-sommaire dictee-vocale">
          Dictée vocale
        </router-link>
        <router-link to="/archives/appariement-nombre-quantite" class="activite-sommaire appariement-nombre-quantite">
          Appariement nombre et quantité
        </router-link>
        <router-link to="/archives/identifier-ordonner" class="activite-sommaire identifier-ordonner">
          Identifier et ordonner
        </router-link>
        <router-link to="/archives/repertoire-additif" class="activite-sommaire repertoire-additif">
          Répertoire additif
        </router-link>
        <router-link to="/archives/repertoire-soustractif" class="activite-sommaire repertoire-additif">
          Répertoire soustractif
        </router-link>
        <router-link to="/archives/complement-a-10" class="activite-sommaire repertoire-additif">
          Complément<br/>à 10
        </router-link>
        <router-link to="/archives/repertoire-multiplicatif" class="activite-sommaire repertoire-additif">
          Répertoire multiplicatif
        </router-link>
        <router-link to="/archives/encadrer" class="activite-sommaire encadrer">
          Encadrer
        </router-link>
        <router-link to="/archives/decomposer" class="activite-sommaire decomposer">
          Decomposer
        </router-link>
      </div>
      <div class="clear"></div>

      <h1>Outils</h1>

      <div class="activites-sommaire">
        <router-link to="/archives/table-de-multiplication" class="outil-sommaire table-multiplication">
          Tables de multiplication
        </router-link>
      </div>
      <div class="clear"></div>

    </main>
    <FooterHome />
    <SettingsPanel :class="settingsCssClass" />
  </div>
</template>

<script setup>

  import HeaderHome from "../../components/v1/HeaderHome";
  import FooterHome from "../../components/v1/FooterHome";
  import SettingsPanel from "../../components/v1/SettingsPanel";
  import DemoBtn from "../../components/v1/Boutons/DemoBtn";
  import { ref } from "vue";


  //
  // Settings
  //

  let settingsCssClass = ref('');

  const toggleSettings = function(bool) {
     if (bool) {
       settingsCssClass.value = "settings-opened";
    } else {
       settingsCssClass.value = "";
    }

  };

</script>

<style scoped lang="scss">

  .sommaire {
    width:100%;
    padding-bottom:  120px;
  }

  .sommaire.settings-opened {
    display: none;
  }

  .sommaire h1 {
    margin-top: 30px;
  }

  .sommaire-demo {
    display: none;
  }

  .activite-sommaire {
    display: block;
    float: left;
    margin-right: 14px;
    margin-bottom: 15px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    width: 352px;
    height: 224px;
    box-sizing: border-box;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  }

  .outil-sommaire {
    display: block;
    float: left;
    margin-right: 14px;
    margin-bottom: 15px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    width: 536px;
    height: 97px;
    box-sizing: border-box;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  }

  .activites-sommaire {
    margin-top: 30px;
  }

  .outil-sommaire a,
  .activites-sommaire a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size:42px;
    line-height: 41px;
    font-weight: bold;
    padding: 16px;
  }

  .dictee-vocale {
    background-image: url(../../assets/images/svg/sommaire/dictee_vocale.svg);
  }

  .table-multiplication {
    background-image: url(../../assets/images/svg/sommaire/table_multiplication.svg);
  }

  .table-multiplication.outil-sommaire {
    background-image: url(../../assets/images/svg/sommaire/table_multiplication_outil.svg);
    font-size:32px;
  }

  .appariement-nombre-quantite {
    background-image: url(../../assets/images/svg/sommaire/appariement_nombre_quantite.svg);
  }

  .identifier-ordonner {
    background-image: url(../../assets/images/svg/sommaire/ordonner_identifier.svg);
  }

  .jeu-des-couleurs,
  .repertoire-en-ligne,
  .repertoire-additif {
    background-image: url(../../assets/images/svg/sommaire/repertoire_additif.svg);
  }

  .encadrer {
    background-image: url(../../assets/images/svg/sommaire/repertoire_additif.svg);
  }

  .decomposer {
    background-image: url(../../assets/images/svg/sommaire/repertoire_additif.svg);
  }



  /* iPad */

  @media screen and (max-width: 1080px ) {

    .activite-sommaire {
      width: 300px;
      height: 190px;

    }

  }

</style>
