<template>
  <div class="gommettes">
    <Gommette class="circle-red" />
    <Gommette class="triangle-blue" />
    <Gommette class="star-white" />
    <Gommette class="square-green" />
    <Gommette class="hexagone-yellow" />
    <Gommette class="circle-yellow" />
    <Gommette class="triangle-white" />
    <Gommette class="star-yellow" />
    <Gommette class="hexagone-blue" />
    <Gommette class="triangle-red" />
    <Gommette class="circle-black" />
    <Gommette class="triangle-green" />
    <Gommette class="square-blue" />
    <Gommette class="hexagone-black" />
    <Gommette class="star-red" />
  </div>
</template>

<script setup>

  import {onMounted, defineExpose} from "vue";
  import Gommette from "./Gommette";

  import $ from 'jquery'
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"

  gsap.registerPlugin(Draggable);

  let gommettesElements;

  onMounted(() => {

    gommettesElements = $(".gommettes > div");
    Draggable.create(gommettesElements, {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true
    });

  });


  /* Public methods */

  const reset = function() {
    // Retour à la position initiale
    gsap.set(gommettesElements, {x: 0, y: 0, delay: 0.1});

  }

  defineExpose({
    reset
  });

</script>

<style scoped lang="scss">

  .gommettes {

    position: relative;

    & > div {
      position: absolute;
    }

    & > div:nth-child(1) {
      left:50px;
      top:50px;
    }

    & > div:nth-child(2) {
      left:20px;
      top:20px;
    }

    & > div:nth-child(3) {
      left:50px;
      top:10px;
    }

    & > div:nth-child(4) {
      left:110px;
      top:40px;
    }

    & > div:nth-child(5) {
      left:135px;
      top:40px;
    }

    & > div:nth-child(7) {
      left:35px;
      top:60px;
    }

    & > div:nth-child(8) {
      left:20px;
      top:50px;
    }

    & > div:nth-child(9) {
      left:80px;
      top:0;
    }

    & > div:nth-child(10) {
      left:100px;
      top:70px;
    }

    & > div:nth-child(11) {
      left:70px;
      top:40px;
    }

    & > div:nth-child(12) {
      left:90px;
      top:30px;
    }

    & > div:nth-child(13) {
      left:30px;
      top:70px;
    }

    & > div:nth-child(14) {
      left:120px;
      top:0;
    }

    & > div:nth-child(15) {
      left:0;
      top:70px;
    }

  }

</style>
