<template>
  <div>
  </div>
</template>

<style scoped lang="scss">

div {

  display: inline-block;
  background: url(../../../assets/images/svg/validation/validation_neutre_4x.png) center / 48px auto no-repeat;
  width: 48px;
  height: 43px;

  @include on-mobile-landscape {
    background-size: contain;
    width: 36px;
    height: 36px;
  }

  &.is-correct {
      background-image: url(../../../assets/images/svg/validation/validation_win_4x.png);
  }

  &.is-wrong {
      background-image: url(../../../assets/images/svg/validation/validation_error_4x.png);
  }

}

</style>
