<template>
  <svg width="84" height="44" viewBox="0 0 84 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 4H76V0H8V4ZM80 8V36H84V8H80ZM76 40H8V44H76V40ZM4 36V8H0V36H4ZM8 40C5.79086 40 4 38.2091 4 36H0C0 40.4183 3.58172 44 8 44V40ZM80 36C80 38.2091 78.2091 40 76 40V44C80.4183 44 84 40.4183 84 36H80ZM76 4C78.2091 4 80 5.79086 80 8H84C84 3.58172 80.4183 0 76 0V4ZM8 0C3.58172 0 0 3.58172 0 8H4C4 5.79086 5.79086 4 8 4V0Z" fill="#666666"/>
    <path d="M2 8C2 4.68629 4.68629 2 8 2H76C79.3137 2 82 4.68629 82 8V36C82 39.3137 79.3137 42 76 42H8C4.68629 42 2 39.3137 2 36V8Z" :fill="backgroundColor" />
    <rect opacity="0.6" x="2" y="2" width="80" height="40" rx="6" fill="#666666"/>
    <g clip-path="url(#clip0_433_138732)">
      <rect x="2" y="2" width="80" height="40" rx="6" :fill="backgroundColor"/>
      <circle cx="15" cy="13" r="3" :fill="color1"/>
      <circle cx="29" cy="13" r="3" :fill="color2"/>
      <circle cx="22" cy="20" r="3" :fill="color3"/>
      <circle cx="15" cy="27" r="3" :fill="color4"/>
      <circle cx="29" cy="27" r="3" :fill="color5"/>
      <circle cx="55" cy="13" r="3" :fill="color6"/>
      <circle cx="69" cy="13" r="3" :fill="color7"/>
      <circle cx="62" cy="20" r="3" :fill="color8"/>
      <circle cx="55" cy="27" r="3" :fill="color9"/>
      <circle cx="69" cy="27" r="3" :fill="color10"/>
      <rect opacity="0.3" x="31.186" y="-30.564" width="3.50523" height="114" transform="rotate(33.6599 31.186 -30.564)" fill="white"/>
      <rect opacity="0.3" x="41.1216" y="-35" width="19.0962" height="114" transform="rotate(33.6599 41.1216 -35)" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_433_138732">
        <rect x="2" y="2" width="80" height="36" rx="6" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>


<script setup>


//
// Propriétés du composant
//

import {ref, onMounted, watch} from "vue";

const props = defineProps({
  backgroundColor: {
    type: String,
    required: false,
    default: "#E6E6E6"
  },
  colors: {
    type: Array,
    required: false
  },
});


//
// Couleurs
//

const color1 = ref();
const color2 = ref();
const color3 = ref();
const color4 = ref();
const color5 = ref();
const color6 = ref();
const color7 = ref();
const color8 = ref();
const color9 = ref();
const color10 = ref();

const noir = "#333333";

const update = function() {

  // Par défaut :
  color1.value = noir;
  color2.value = noir;
  color3.value = noir;
  color4.value = noir;
  color5.value = noir;
  color6.value = noir;
  color7.value = noir;
  color8.value = noir;
  color9.value = noir;
  color10.value = noir;

  if (Array.isArray(props.colors)) {

    const couleurs_unites = props.colors;
    const n = couleurs_unites.length;

    if (n > 0) {
      color1.value = couleurs_unites[0];

      if (n > 1) {
        color2.value = couleurs_unites[1];

        if (n > 2) {
          color3.value = couleurs_unites[2];

          if (n > 3) {
            color4.value = couleurs_unites[3];

            if (n > 4) {
              color5.value = couleurs_unites[4];

              if (n > 5) {
                color6.value = couleurs_unites[5];

                if (n > 6) {
                  color7.value = couleurs_unites[6];

                  if (n > 7) {
                    color8.value = couleurs_unites[7];

                    if (n > 8) {
                      color9.value = couleurs_unites[8];

                      if (n > 9) {
                        color10.value = couleurs_unites[9];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});


</script>