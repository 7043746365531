<template>
  <div class="asteroids" id="asteroids">
    <!-- Colonne 1 -->
    <div class="score1">
      <div class="score-jeu score-panel">
        <h1 class="titan">{{ score }}</h1>
      </div>
      <div class="score-chip score-panel">
        <h2 class="titan">{{ chips }}</h2>
      </div>
    </div>
    <!-- Colonne 2 -->
    <div class="jeu-parent">
      <div id="fps-parent">FPS : <span id="fps">0</span></div>
      <div id="canvas-parent"></div>
      <div id="score-parent">
        <span id="score-pairs">0</span>
        <span id="score-impairs">0</span>
      </div>
      <div id="debug-render"><canvas id="debug-canvas"></canvas></div>
    </div>
    <!-- Colonne 3 -->
    <div class="score2">
      <div class="vies-et-retour">
        <div class="score-dicey score-panel">
          <h2 class="titan">{{ nbVies }}</h2>
        </div>
        <router-link :to="backRoute" class="back-button" ></router-link>
      </div>
      <div class="dicey-large">
        <div class="bulle-parent" :class="gameOverCssClass">
          <div class="bulle">
            <div class="bulle-content">
              La partie est terminée !
              <br/>
              Ton score est de {{ score }} points.
              <br/>
              <br/>
              Clique sur ce bouton pour rejouer
              <a href="#" class="restart-button" @click="replay"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref, watch} from "vue";
  import {useRouter} from "vue-router";
  import * as PIXI from 'pixi.js'
  import * as Matter from 'matter-js'

  const router = useRouter();


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    }
  });

  const totalVies = 10;

  const speed = ref(1);
  const score = ref(0);
  const nbVies = ref(totalVies);
  const chips = ref(0);
  const gameOver = ref(false);

  // Par défaut, retour à l'accueil
  const backRoute = ref('/');
  backRoute.value = router.resolve({
    name: "ListeActivites"
  }).href;


  //
  // Niveau : chiffres minimum et maximum
  //

  const initNiveau = function() {

    switch(props.niveau) {

      default:
      case 1:
        speed.value = 1;
        break;

      case 2:
        break;

      case 3:
        break;

      case 4:
        break;

      case 5:
        break;

      case 6:
        break;
    }

    // console.log('niveau', props.niveau, speed.value);

  };


  //
  // Variables spécifiques à l'activité
  //

  let pixiApp;
  let pixiAppBackground;
  let pixiAppWall;
  let pixiAppExtWall;

  let scores;
  let bodyElement;
  let fpsText;


  const RENDER_WITH_CANVAS = false; // permet de voir les contours des objets du moteur physique Matter au lieu du rendu Pixi
  const PIXI_DEBUG = false; // permet de voir le second mur où s'arrêtent les astéroids de nombre 1

  const WIDTH = Math.min(600, window.innerWidth);
  const HEIGHT = Math.min(1200, window.innerHeight - 60);
  const MARGIN_X = 200;
  const MARGIN_Y = 200;

  // console.log('v1.01 WIDTH=', WIDTH, window.innerWidth, 'HEIGHT=', HEIGHT, window.innerHeight, "RESOLUTION", window.devicePixelRatio, PIXI.settings.RESOLUTION);

  const DEBUG_MARGIN_X = PIXI_DEBUG ? MARGIN_X : 0;
  const DEBUG_MARGIN_Y = PIXI_DEBUG ? MARGIN_Y : 0;

  const MIN_PARTICLES_COUNT = 8;
  const PARTICLE_COUNT = 10;
  const PARTICLE_SPEED = 2;
  const PARTICLE_RADIUS = 50;
  const PARTICLE_MAX_NUMBER = 9;
  const AVATAR_RADIUS = 30;

  const COLLISION_DELAY = 500;
  const UNLOCK_DELAY = 2000;

  const AVATAR_X = WIDTH/2;
  const AVATAR_Y = HEIGHT/3;

  const wallLabel = 'wall';
  const wallExtLabel = 'wallExt';
  const asteroidLabel = 'asteroid';
  const avatarLabel = 'avatar';

  const lockedAsteroidCategory = 0x1111;
  const asteroidCategory = 0x0001;
  const wallExtCategory = 0x0010;
  const wallCategory = 0x0100;
  const avatarCategory = 0x1000;

  const avatarImage = "/assets/jeux/asteroids/Spaceship100.png";
  const asteroidImage = "/assets/jeux/asteroids/asteroid_green.png";
  const chipImage = "/assets/jeux/asteroids/chip@2x.png";

  let avatarInPixi;

  // Scores
  let COLLISION_PAIR = 0;
  let COLLISION_IMPAIR = 0;
  let COLLISION_CHIP = 0;

  let updateAvatarPositionBind;
  let updateAvatarPositionInterval;
  let checkUnloackParticlesInterval;


  // Matter :

  const Engine = Matter.Engine,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Composite = Matter.Composite,
      Events = Matter.Events,
      World = Matter.World;

  let runner;
  let engine;
  let render;
  let matterAvatar;
  let particles;
  let counter0 = 0;
  let canvasParent;


  // Rendu Matter --> Pixi :

  if (! RENDER_WITH_CANVAS ) {

    // 1. Initialization

    Render.create = function (options) {

      const render = {};
      render.startTime = getCurrentTime();
      render.engine = options.engine;
      render.element = options.element;
      render.app = options.app;
      render.frameNo = 0;

      render.timing = {
        historySize: 60,
        delta: 0,
        deltaHistory: [],
        lastTime: 0,
        lastTimestamp: 0,
        lastElapsed: 0,
        timestampElapsed: 0,
        timestampElapsedHistory: [],
        engineDeltaHistory: [],
        engineElapsedHistory: [],
        elapsedHistory: []
      };

      render.options = {
        width: 800,
        height: 600,
        pixelRatio: 1,
        background: '#14151f',
        wireframeBackground: '#14151f',
        hasBounds: !!options.bounds,
        enabled: true,
        wireframes: true,
        showSleeping: true,
        showDebug: false,
        showStats: false,
        showPerformance: false,
        showBounds: false,
        showVelocity: false,
        showCollisions: false,
        showSeparations: false,
        showAxes: false,
        showPositions: false,
        showAngleIndicator: false,
        showIds: false,
        showVertexNumbers: false,
        showConvexHulls: false,
        showInternalEdges: false,
        showMousePosition: false
      }

      // Pixi
      const app = options.app;
      const element = options.element;

      element.appendChild(app.view);

      // Texture loading in Pixi
      app.loader
          .add('sprite', asteroidImage)
          .add('avatar', avatarImage)
          .add('chip', chipImage)
          .load((loader, resources) => {

            app.resources = resources;

            avatarInPixi = addAvatarToView(app);

            let firstParticule = true;

            particles.forEach(function(matter_particle) {
              addParticleToView(app, matter_particle, firstParticule);
              firstParticule = false;
            });
          });

      return render;

    }

    // 2. Update loop ( => Pixi update)

    Render.world = function(render) {

      const startTime = (new Date()).getTime() - render.startTime,
          engine = render.engine,
          world = engine.world,
          timing = render.timing,
          app = render.app;

      const pixi_bodies = app.stage.children;

      fpsText.textContent = Math.floor(app.ticker.FPS);

      if (pixi_bodies.length) {

        let pixi_sprite;

        world.bodies.forEach(function(matter_body) {

          // On met à jour :
          // - les objects non statiques géré par le modèle physique : astéroides
          // - les objets dont la position est géré par programmation : avatar

          if ( ! matter_body.isStatic || matter_body.isPseudoStatic ) {
            pixi_sprite = getSpriteById(render.app, matter_body.id);
            if (pixi_sprite) {
              pixi_sprite.x = matter_body.position.x;
              pixi_sprite.y = matter_body.position.y;
            }
          }
        });
      }

      // log the time elapsed computing this update
      timing.lastElapsed = getCurrentTime() - startTime;
    }
  }


  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite() {

    gameOver.value = false;

    canvasParent = document.getElementById('canvas-parent');
    scores = document.getElementById('score-parent');
    scores.style.left = (WIDTH - 150) + "px";

    bodyElement = document.getElementsByTagName('body')[0];
    fpsText = document.getElementById('fps');

    /* --------------------- PIXI ------------------- */

    pixiApp = new PIXI.Application({
      width: WIDTH + 2 * DEBUG_MARGIN_X,
      height: HEIGHT + 2 * DEBUG_MARGIN_X,
      resolution: 1
    });

    pixiAppBackground =  new PIXI.Graphics();
    pixiAppBackground.beginFill(0xFFFFFF);
    pixiAppBackground.drawRect(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT);
    pixiAppBackground.interactive = true;
    pixiApp.stage.addChild(pixiAppBackground);

    pixiAppWall =  new PIXI.Graphics();
    pixiAppWall.lineStyle(PIXI_DEBUG ? 1 : 0, 0xFF2222);
    pixiAppWall.drawRect(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT);
    pixiAppWall.interactive = true;
    pixiApp.stage.addChild(pixiAppWall);

    pixiApp.stage.x = DEBUG_MARGIN_X - MARGIN_X;
    pixiApp.stage.y = DEBUG_MARGIN_Y - MARGIN_Y;

    pixiAppExtWall =  new PIXI.Graphics();
    pixiAppExtWall.lineStyle(1, 0x22FF22);
    pixiAppExtWall.drawRect(1, 1, WIDTH + 2 * MARGIN_X - 2, HEIGHT + 2 * MARGIN_Y - 2);
    pixiAppExtWall.interactive = true;
    pixiApp.stage.addChild(pixiAppExtWall);

    /* MOUSE DOWN */

    pixiAppBackground.on('touchstart', function() {
      updateAvatarPositionBind = updateAvatarPosition.bind(this);
      pixiAppBackground.on('touchmove', updateAvatarPositionBind);
    });

    pixiAppBackground.on('mousedown', function() {
      updateAvatarPositionBind = updateAvatarPosition.bind(this);
      pixiAppBackground.on('mousemove', updateAvatarPositionBind);
    });

    /* MOUSE UP */

    bodyElement.addEventListener('mouseup', stopMouseMoveUpdate);
    bodyElement.addEventListener('touchend', stopTouchMoveUpdate);


    //
    // MATTER ENGINE
    //

    engine = Engine.create();
    engine.world.gravity.y = 0;

    matterAvatar = makeAvatar();

    Composite.add(engine.world, drawWalls(MARGIN_X, MARGIN_Y, WIDTH, HEIGHT, wallLabel, wallCategory));
    Composite.add(engine.world, drawWalls(0, 0, WIDTH + 2 * MARGIN_X, HEIGHT + 2 * MARGIN_Y, wallExtLabel, wallExtCategory));
    Composite.add(engine.world, matterAvatar);

    particles = makeParticles();
    Composite.add(engine.world, particles);


    if ( RENDER_WITH_CANVAS ) {

      /* Canvas render (Matter default rendering) */

      const canvas = document.getElementById('debug-canvas');
      canvas.width = WIDTH + 2 * MARGIN_X;
      canvas.height = HEIGHT + 2 * MARGIN_Y;

      render = Render.create({
        element: canvasParent,
        canvas: canvas,
        app: pixiApp,
        engine: engine,
        options: {
          width: WIDTH + 2 * MARGIN_X,
          height: HEIGHT + 2 * MARGIN_Y
        }
      });

      pixiApp.loader
          .add('sprite', asteroidImage)
          .add('avatar', avatarImage)
          .add('chip', chipImage)
          .load((loader, resources) => {

            pixiApp.resources = resources;

            avatarInPixi = addAvatarToView(pixiApp);

            let firstParticule = true;

            particles.forEach(function(matter_particle) {
              addParticleToView(pixiApp, matter_particle, firstParticule);
              firstParticule = false;
            });
          });


    } else {

      /* Pixi render */

      render = Render.create({
        element: canvasParent,
        app: pixiApp,
        engine: engine
      });

    }

    Render.run(render);

    runner = Runner.create();
    Runner.run(runner, engine);

    if (checkUnloackParticlesInterval) clearInterval(checkUnloackParticlesInterval);
    checkUnloackParticlesInterval = setInterval(checkToUnlockParticles, 2000);

    Events.on(engine, 'beforeUpdate', onBeforeUpdateEnginefunction);
    Events.on(engine, 'collisionStart', onCollisionStartfunction);
  }


  const getCurrentTime = function() {
    return new Date().getTime();
  }

  const addParticleToView = function(pixi_app, matterParticle, firstParticle = false) {

    const sprite = new PIXI.Sprite();
    sprite.last_split = getCurrentTime() - COLLISION_DELAY;
    sprite.chip = firstParticle;
    sprite.nombre = PARTICLE_MAX_NUMBER;
    sprite.id = matterParticle.id;
    pixi_app.stage.addChild(sprite);

    if (firstParticle) {

      const bgSprite = new PIXI.Sprite(pixi_app.resources.chip.texture);
      bgSprite.anchor.x = 0.5;
      bgSprite.anchor.y = 0.5;
      sprite.addChild(bgSprite);

      matterParticle.ratio = 0.5;

    } else {

      const bgSprite = new PIXI.Sprite(pixi_app.resources.sprite.texture);
      bgSprite.anchor.x = 0.5;
      bgSprite.anchor.y = 0.5;
      sprite.addChild(bgSprite);

      let text = new PIXI.Text( sprite.nombre, { fontFamily : 'Arial', fontSize: 24, fill : 0x58ce45, align: 'left' });
      text.x = -5;
      text.y = -2;
      text.anchor.x = 0.5;
      text.anchor.y = 0.5;
      sprite.addChild(text);
    }
  };

  const addAvatarToView = function(pixi_app) {
    const sprite = new PIXI.Sprite(pixi_app.resources.avatar.texture);
    sprite.id = 1;
    sprite.x = AVATAR_X;
    sprite.y = AVATAR_Y;
    sprite.anchor.x = 0.5;
    sprite.anchor.y = 0.5;
    pixi_app.stage.addChild(sprite);

    return sprite;
  };

  const getSpriteById = function(pixi_app, particleId) {
    let foundSprite = false;
    pixi_app.stage.children.forEach( function(sprite) {
      if (sprite.id === particleId) {
        foundSprite = sprite;
      }
    });
    return foundSprite;
  };

  const updateSpritePosition = function(matter_body, pixi_sprite) {
    pixi_sprite.x = matter_body.position.x;
    pixi_sprite.y = matter_body.position.y;
  }

  const updateSpriteNo = function(matter_asteroid, pixi_sprite, no) {
    if (! pixi_sprite.chip) {

      let w = 100, h = 102, ratio = 1;

      if (no < 9) {
        ratio = 0.75 + 0.25 * no / 9;
        w *= ratio;
        h *= ratio;
      }

      // MATTER ( cf maj beforeUpdate )
      matter_asteroid.ratio = ratio;

      // PIXI
      pixi_sprite.nombre = no;

      const bg = pixi_sprite.children[0];
      bg.width = w;
      bg.height = h;

      const textfield = pixi_sprite.children[1];
      textfield.text = no > 0 ? no : '';

    }
  }

  const updateScore = function() {
    if (totalVies - COLLISION_IMPAIR >= 0)
    {
      nbVies.value = totalVies - COLLISION_IMPAIR;
      score.value = COLLISION_PAIR * 20 + COLLISION_CHIP * 50;
      chips.value = COLLISION_CHIP;

      if (totalVies - COLLISION_IMPAIR === 0)
      {
        pauseGameEngine();

        // Affichage de la bulle de fin :
        gameOver.value = true;

        console.log("updateScore => pauseGameEngine", gameOver.value);
      }
    }
  }


  /* ------------------ EVENTS ------------------------ */

  const onBeforeUpdateEnginefunction = function (event) {
    if (event.timestamp >= counter0 + 50) {

      particles.forEach(function(p) {

        if (p.wasStatic) {

          // Astéroids débloqués et passant au milieu de l'espace de jeu
          if ((p.position.x > (MARGIN_X + WIDTH * 0.1)) && (p.position.x < (MARGIN_X + WIDTH * 0.9))) {
            if ((p.position.y > (MARGIN_Y + HEIGHT * 0.1)) && ((p.position.y < MARGIN_Y + HEIGHT * 0.9))) {
              p.wasStatic = false;
              p.collisionFilter.mask = asteroidCategory | avatarCategory | wallCategory | wallExtCategory;
            }
          }

          // Si un asteroid s'éloigne au-delà du canvas, on le remet parmi les asteroids disponibles
          if (p.wasStatic && ( (p.position.x < 0 ) || (p.position.y < 0 ) || ( p.position.x > WIDTH + 2 * MARGIN_X ) || ( p.position.y > HEIGHT + 2 * MARGIN_Y) )) {
            p.wasStatic = false;
            lockParticle(p, true);
          }

        } else if ((p.ratio !== undefined) && (p.ratio !== 1)) {

          // Cas particulier : si l'asteroid a changé de taille :
          const velocity = { x: p.velocity.x, y: p.velocity.y };
          let speedMultiplier = PARTICLE_SPEED / p.speed;

          let scaleMultiplier = PARTICLE_RADIUS / p.circleRadius * p.ratio ;
          Matter.Body.scale(p,scaleMultiplier, scaleMultiplier);

          Matter.Body.setInertia(p, Infinity);

          Matter.Body.setVelocity(p, {
            x: velocity.x * speedMultiplier,
            y: velocity.y * speedMultiplier
          });

          p.ratio = 1;

        } else {

          // Cas général :
          adjustE(p);

        }

      });

      counter0 = event.timestamp;
    }
  }

  const onCollisionStartfunction = function(event) {

    const pixi_app = render.app;
    const pairs = event.pairs;

    for (let i = 0; i < pairs.length; i++) {

      let pair = pairs[i];
      const bodyA = pair.bodyA;
      const bodyB = pair.bodyB;

      let asteroid = null;
      let sprite = null;

      // Collision entre l'avatar et un asteroid  ----------------------

      if ((bodyA.label ===  asteroidLabel) && (bodyB.label === avatarLabel)) {
        asteroid = bodyA;
      } else if ((bodyB.label === asteroidLabel) && (bodyA.label === avatarLabel)) {
        asteroid = bodyB;
      }

      if (asteroid) {

        // Sprite Pixi
        sprite = getSpriteById(pixi_app, asteroid.id);
        if (sprite)
        {
          const now = getCurrentTime();

          if (sprite.chip)
          {
            // On gagne un chip
            COLLISION_CHIP++;
            updateScore();

            // On le retire de l'écran
            asteroid.wasStatic = false;
            lockParticle(asteroid, true);
            updateSpritePosition(asteroid, sprite);

            sprite.last_split = now;

          }
          else if (sprite.nombre % 2 === 0)
          {
            if ( now - sprite.last_split > COLLISION_DELAY )
            {
              // On gagne un point
              COLLISION_PAIR++;
              updateScore();

              // On scinde l'asteroid en deux

              // On doit réactiver un astéroid en attente
              const secondAsteroid = findLockedParticle();
              if (secondAsteroid) {
                const secondSprite = getSpriteById(pixi_app, secondAsteroid.id);
                if (secondSprite) {
                  unlockParticleReference(secondAsteroid, asteroid)

                  // On divise le nombre par deux
                  const moitie = sprite.nombre / 2;
                  updateSpriteNo(asteroid, sprite, moitie);
                  updateSpriteNo(secondAsteroid, secondSprite, moitie);

                  sprite.last_split = now;
                  secondSprite.last_split = now;
                }
              }

            }

          } else {

            // On perd un point
            COLLISION_IMPAIR++;
            updateScore();
          }
        }
        return;
      }

      // Collision sur le mur ----------------------

      if ((bodyA.label === asteroidLabel) && (bodyB.label === wallLabel)) {
        asteroid = bodyA;
      } else if ((bodyB.label === asteroidLabel) && (bodyA.label === wallLabel)) {
        asteroid = bodyB;
      }

      if (asteroid) {
        // Sprite Pixi
        sprite = getSpriteById(pixi_app, asteroid.id);
        if (sprite) {
          if (sprite.nombre > 1 ) {
            updateSpriteNo(asteroid, sprite, sprite.nombre - 1)
          } else {
            asteroid.collisionFilter.mask = asteroidCategory | avatarCategory | wallExtCategory;
          }
        }
        return;
      }

      // Collision sur le mur extérieur ----------------------

      if ((bodyA.label ===  asteroidLabel) && (bodyB.label === wallExtLabel)) {
        asteroid = bodyA;
      } else if ((bodyB.label === asteroidLabel) && (bodyA.label === wallExtLabel)) {
        asteroid = bodyB;
      }

      if (asteroid) {
        // On le maintient bloqué contre le mur extérieur
        lockParticle(asteroid);
      }

      // On regarde s'il reste beaucoup d'asteroid en activité
      checkToUnlockParticles();
    }
  }

  const updateAvatarPosition = function(e) {

    const mouseX = e.x || (e.data && e.data.global.x);
    const mouseY = e.y || (e.data && e.data.global.y);

    if (!mouseX) return;

    const prevX = matterAvatar.position.x;
    const prevY = matterAvatar.position.y;

    const marginX = MARGIN_X - DEBUG_MARGIN_X;
    const marginY = MARGIN_Y - DEBUG_MARGIN_Y;

    const newX = (mouseX + marginX + 2 * prevX) /3;
    const newY = (mouseY + marginY + 2 * prevY) /3;

    Matter.Body.setPosition(matterAvatar, { x: newX, y: newY });

    if ((newX !== prevX) || (newY !== prevY)) {
      avatarInPixi.rotation = Math.atan2(newY - prevY, newX - prevX) - Math.PI;
      avatarInPixi.position.x = newX;
      avatarInPixi.position.y = newY;
    }

    // In user maintains the mouse same position :
    if (updateAvatarPositionInterval) clearInterval(updateAvatarPositionInterval);
    updateAvatarPositionInterval = setInterval(function() {
      updateAvatarPosition({ x: mouseX, y: mouseY });
    }, 60);

  };

  function stopMouseMoveUpdate() {
    pixiAppBackground.off('mousemove', updateAvatarPositionBind);
    if (updateAvatarPositionInterval) clearInterval(updateAvatarPositionInterval);
  }

  function stopTouchMoveUpdate() {
    pixiAppBackground.off('touchmove', updateAvatarPositionBind);
    if (updateAvatarPositionInterval) clearInterval(updateAvatarPositionInterval);
  }


  /* ------------------ MATTER ------------------------ */


  //
  // MATTER ELEMENTS CREATION
  //

  const drawWalls = function(x, y, w, h, label, category) {
    const Bodies = Matter.Bodies;
    const thickness = 1;
    const wallOptions = {
      label: label,
      isStatic: true,
      restitution: 10,
      friction:10,
      collisionFilter: {
        category: category,
        mask: asteroidCategory
      }
    };

    return [
      // Bottom wall
      Bodies.rectangle(
          // x, y
          x + w / 2, y + h,
          // width, height
          w, thickness,
          wallOptions
      ),
      // right wall
      Bodies.rectangle(
          // x, y
          x + w, y + h / 2,
          // width, height
          thickness, h,
          wallOptions
      ),
      // top wall
      Bodies.rectangle(
          // x, y
          x + w / 2, y,
          // width, height
          w, thickness,
          wallOptions
      ),
      // left wall
      Bodies.rectangle(
          // x, y
          x, y + h / 2,
          // width, height
          thickness, h,
          wallOptions
      ),
    ];
  };

  const makeParticle = function(label, isStatic) {

    // Pour décoller l'asteroid du mur, afin d'éviter une collision
    const particleMargin = 1;

    const p = Matter.Bodies.circle(
        MARGIN_X + (Math.random() * (WIDTH - particleMargin)) + (particleMargin / 2),
        MARGIN_Y + (Math.random() * (HEIGHT - particleMargin)) + (particleMargin / 2),
        PARTICLE_RADIUS,
        {
          label: label,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          frictionStatic: 0,
          collisionFilter: {
            category: asteroidCategory,
            mask: asteroidCategory | avatarCategory | wallCategory | wallExtCategory
          },
        });

    Matter.Body.setInertia(p, Infinity);

    const direction = Math.random() * Math.PI * 2;
    Matter.Body.setVelocity(p, {
      x: Math.sin(direction) * PARTICLE_SPEED,
      y: Math.cos(direction) * PARTICLE_SPEED
    });

    if (isStatic) {
      lockParticle(p, true);
    }

    return p;
  };

  const lockParticle = function( matterParticle, withRandomPosition ) {

    if (withRandomPosition === true) {
      const lockX = Math.random() < 0.5 ? 20 : WIDTH + 2 * MARGIN_X - 20;
      const lockY = Math.random() * ( HEIGHT + 2 * MARGIN_Y );
      Matter.Body.setPosition(matterParticle, { x: lockX, y: lockY });
    }

    Matter.Body.setVelocity(matterParticle, { x: 0, y: 0 });
    matterParticle.isStatic = true;
    matterParticle.collisionFilter.category = lockedAsteroidCategory;
  }

  const getParticlesByStatic = function( is_static = true ) {
    let i, matterParticle, matterParticles = [], isStatic = is_static === true;
    for(i=0; i< PARTICLE_COUNT; i++) {
      matterParticle = particles[i];
      if (matterParticle.isStatic === isStatic) {
        matterParticles.push(matterParticle);
      }
    }
    return matterParticles;
  };

  const findLockedParticle = function() {
    let i, matterParticle;
    for(i=0; i< PARTICLE_COUNT; i++) {
      matterParticle = particles[i];
      if (matterParticle.isStatic) {
        return matterParticle;
      }
    }
    return false;
  };

  const checkToUnlockParticles = function() {
    const unlockedParticlesCount = getParticlesByStatic(false).length;
    if (unlockedParticlesCount < MIN_PARTICLES_COUNT) {
      unlockParticles(MIN_PARTICLES_COUNT - unlockedParticlesCount);
    }
  };

  const lockAllParticles = function() {
    let i, matterParticle;
    for(i=0; i< PARTICLE_COUNT; i++) {
      matterParticle = particles[i];
      lockParticle(matterParticle, true);
    }
  }

  const updateAllParticles = function() {
    let i, matterParticle, pixiSprite;
    for(i=0; i< PARTICLE_COUNT; i++) {
      matterParticle = particles[i];
      pixiSprite = getSpriteById(pixiApp, matterParticle.id);
      if (pixiSprite) {
        updateSpritePosition(matterParticle, pixiSprite);
      }
    }
  }

  const unlockParticles = function( n ) {

    const lockedParticles = getParticlesByStatic(true);

    let i, m = Math.min(n, lockedParticles.length), matterParticle, pixiSprite;

    // console.log('unlock m=', m);

    const centerX = MARGIN_X + WIDTH * 0.5;
    const centerY = MARGIN_Y + HEIGHT * 0.5;
    const now = getCurrentTime();

    for(i = 0; i < m; i++)
    {
      // Particule Matter
      matterParticle = lockedParticles[i];

      // Sprite Pixi
      pixiSprite = getSpriteById(pixiApp, matterParticle.id);
      if (pixiSprite) {
        if (now - pixiSprite.last_split > UNLOCK_DELAY) {

          matterParticle.collisionFilter.category = asteroidCategory;
          matterParticle.isStatic = false;
          matterParticle.wasStatic = true; // Permet de remettre les bonnes contraintes après la traversée du mur (cf beforeUpdate)

          // Pour qu'il puisse franchir le mur
          matterParticle.collisionFilter.mask = asteroidCategory | avatarCategory;

          const direction = Math.atan2(centerY - matterParticle.position.y, centerX - matterParticle.position.x);

          Matter.Body.setVelocity(matterParticle, {
            x: Math.cos(direction) * PARTICLE_SPEED,
            y: Math.sin(direction) * PARTICLE_SPEED
          });

          updateSpriteNo(matterParticle, pixiSprite, PARTICLE_MAX_NUMBER);

        }
      }
    }
  };

  // Duplique un asteroid-reference lors d'une collision avec l'avatar
  const unlockParticleReference = function( matterParticle, referenceParticle ) {
    Matter.Body.setPosition(matterParticle, { x: referenceParticle.position.x , y: referenceParticle.position.y });
    Matter.Body.setVelocity(matterParticle, { x: referenceParticle.velocity.x, y: referenceParticle.velocity.y });
    matterParticle.isStatic = false;
    matterParticle.collisionFilter.mask = asteroidCategory | avatarCategory | wallCategory | wallExtCategory;
  };

  const makeAvatar = function() {

    return Matter.Bodies.circle(
        AVATAR_X,
        AVATAR_Y,
        AVATAR_RADIUS,
        {
          label: avatarLabel,
          isStatic: true,
          isPseudoStatic: true,
          restitution: 1,
          friction: 0,
          frictionAir: 0,
          collisionFilter: {
            category: avatarCategory,
            mask: asteroidCategory
          },
        });
  };

  const makeParticles = function() {
    const particles = [];
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      particles.push(makeParticle(asteroidLabel, i > 2 ));
    }
    return particles;
  };

  /*
  const getVelocitySign = function(matter_body) {
    return {
      x: Math.abs(matter_body.velocity.x) / matter_body.velocity.x,
      y: Math.abs(matter_body.velocity.y) / matter_body.velocity.y
    }
  }
   */



  //
  // MATTERS SPEED CONSERVATION TRICK
  //

  const adjustE = function(p) {
    if (p.speed !== 0) {
      let speedMultiplier = PARTICLE_SPEED / p.speed;
      Matter.Body.setVelocity(p, {
        x: p.velocity.x * speedMultiplier,
        y: p.velocity.y * speedMultiplier
      });
    }
  };

  //
  // GAME OVER
  //

  const gameOverCssClass = computed(() => gameOver.value === false ? "hidden" : "" );

  function removeEventListeners() {

    pixiAppBackground.off('touchstart');
    pixiAppBackground.off('mousedown');

    pixiAppBackground.off('touchmove');
    pixiAppBackground.off('touchend');

    pixiAppBackground.off('mousemove');
    pixiAppBackground.off('mouseup');

    bodyElement.removeEventListener('mouseup', stopMouseMoveUpdate);
    bodyElement.removeEventListener('touchend', stopTouchMoveUpdate);
  }

  function pauseGameEngine() {

    lockAllParticles();
    updateAllParticles();

    if (checkUnloackParticlesInterval) clearInterval(checkUnloackParticlesInterval);
    if (updateAvatarPositionInterval) clearInterval(updateAvatarPositionInterval);

    Events.off(engine, 'beforeUpdate');
    Events.off(engine, 'collisionStart');
  }

  function resumeGameEngine() {
    if (checkUnloackParticlesInterval) clearInterval(checkUnloackParticlesInterval);
    checkUnloackParticlesInterval = setInterval(checkToUnlockParticles, 2000);

    Events.on(engine, 'beforeUpdate', onBeforeUpdateEnginefunction);
    Events.on(engine, 'collisionStart', onCollisionStartfunction);

    checkToUnlockParticles();
  }

  function stopGameEngine() {

    if (checkUnloackParticlesInterval) clearInterval(checkUnloackParticlesInterval);
    if (updateAvatarPositionInterval) clearInterval(updateAvatarPositionInterval);

    if (engine && engine.world) {
      World.clear(engine.world);
      Engine.clear(engine);
      Render.stop(render);
      Runner.stop(runner);
    }

    Events.off(engine, 'beforeUpdate');
    Events.off(engine, 'collisionStart');
  }

  function clearGameRendering() {

    if (render.canvas) {
      render.canvas.remove();
      render.canvas = null;
    }

    render.context = null;
    render.textures = {};

    if (pixiApp) {
      pixiApp.loader.reset();
      pixiApp.destroy(true);
      // pixiApp.utils.destroyTextureCache();
      pixiApp = null;
    }

    // Suppression des canvas résiduels éventuels
    const element = document.getElementsByTagName('canvas');
    for (let i = element.length - 1; i >= 0; i--) {
      element[i].parentNode.removeChild(element[i]);
    }

  }

  function replay() {

    console.log('replay');

    // Scores
    COLLISION_PAIR = 0;
    COLLISION_IMPAIR = 0;
    COLLISION_CHIP = 0;

    gameOver.value = false;
    score.value = 0;
    nbVies.value = totalVies;
    chips.value = 0;

    updateScore();

    // Matter
    resumeGameEngine();
  }



  //
  // Lifecycle hooks
  //

  watch( props, () => {
    initNiveau();
    initActivite();
  });

  onMounted(() => {
    initNiveau();
    initActivite();
  });

  onUnmounted(() => {
    removeEventListeners();
    stopGameEngine();
    clearGameRendering()
  });

</script>

<style scoped lang="scss">

  .asteroids {

    position: relative;
    padding-top: 30px;

    display: flex;
    justify-content: center;
    gap: 30px;

    .score1 {
      position: relative;
      flex: 350px 0 0;

      display: flex;
      gap: 10px;

      .score-jeu {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 341px;
        height: 128px;
      }

      .score-chip {
        position: absolute;
        bottom: 0;
        right: 0;

        width: 128px;
        height: 64px;
        padding-top: 2px;
        padding-right: 20px;
        text-align: right;

        background-image: url(../../../assets/jeux/asteroids/chip.svg);
        background-position: left 20px top 13px;
        background-size: 40px auto;
        background-repeat: no-repeat;
      }
    }

    .jeu-parent {
      flex: auto 0 0;

      #canvas-parent {
        border: 4px solid #FFD32C;
        border-radius: 24px;
        overflow: hidden;
        height: calc( 100vh - 60px );
      }

      #fps-parent {
        width: 100px;
        display: none;
      }

      #fps {
        color: red;
      }

      #score-parent {
        width: 100px;
        display: none;
      }

      #score-impairs,
      #score-pairs {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 10px;
        border-radius: 50%;
        border: solid 1px grey;
        background-color: #FFF;

        font-family: "Arial", sans-serif;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
      }

      #score-pairs {
        color: green;
      }

      #score-impairs {
        color: red;
      }

      #debug-render {
        position: absolute;
        left:0;
        top:0;
        width: 1000px;
        height: 900px;
        pointer-events: none;
      }
    }

    .score2 {
      position: relative;
      flex: 250px 0 0;

      .vies-et-retour {
        display: flex;
        gap: 20px;

        .score-dicey {
          width: 128px;
          height: 64px;
          padding-top: 2px;
          padding-right: 20px;
          text-align: right;

          background-image: url(../../../assets/jeux/asteroids/small_dicey.svg);
          background-position: left 16px top 16px;
          background-size: 32px auto;
          background-repeat: no-repeat;
        }

        .back-button {
          display: inline-block;
          width:59px;
          height:65px;
          cursor: pointer;
          background: url(../../../assets/jeux/Bouton_menu.svg) center / 59px auto no-repeat;
        }
      }

      .dicey-large {
        position: absolute;
        bottom: 0;
        right: 0;

        width: 224px;
        height: 189px;

        background-image: url(../../../assets/jeux/Dicey.svg);
        background-position: right center;
        background-size: 224px auto;
        background-repeat: no-repeat;
      }
    }

    .score-panel {
      background: rgb(255, 255, 255, 0.1);
      box-shadow: 0 1px 10px rgb(0 0 0 / 0.2);
      border-radius: 32px;
    }

    .bulle-parent {
      position: relative;
      left: -720px;
      top: -330px;

      &.hidden {
        display: none;
      }

      .bulle {
        width: 720px;
        height: 330px;
        position: absolute;

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          display: block;
          width: 100%;
          height: 100%;
          transform: scaleX(-1);
          background-size: 100% 100%;
          background-image: url(../../../assets/images/svg/ada/BulleBravo_4x.png);
        }

        .bulle-content {
          position: absolute;
          z-index: 2;
          padding: 35px 50px;
          font-size: 30px;
          line-height: 1.3;
        }
      }
    }

    .restart-button {
      display: inline-block;
      width:50px;
      height:50px;
      cursor: pointer;
      background: url(../../../assets/images/svg/icones_v2/Restart_4x.png) center / contain no-repeat;
      transform: translateY(20px);
    }
 }

</style>
