<template>


<table class="column table-header-et-dizaines-unites">
  <tbody>
    <!-- HEADER -->
    <tr v-if="(chiffre >= 100)" class="table-header">
        <th class="table-column-title table-column-title-centaines">Centaine</th>
        <th class="table-column-title table-column-title-dizaines">Dizaine</th>
        <th class="table-column-title table-column-title-unites">Unité</th>
    </tr>
    <tr v-else class="table-header">
        <th class="table-column-title table-column-title-dizaines">Dizaine</th>
        <th class="table-column-title table-column-title-unites">Unité</th>
    </tr>

    <!-- content -->
    <tr>
        <td colspan="3">
            <div class="table-parent-dizaines-unites">
              <table class="dizaines-unites des-carres"  id="table-content">
                  <!-- TABELAU AVEC CENTAINE -->
                  <tbody v-if ="(centaines > 0)">
                    <tr  v-for="line in 9" :key="line">
                        <!-- CENTAINE --> 
                        <td v-if="(line <= centaines)" class="nb nb-centaines" rowspan="10">{{line}}</td>
                        <td v-if="(line <= centaines)" class="blocs blocs-centaines" rowspan="10">
                          <div class="de-parent centaine" :data-value=100*line>
                            <div class="de chiffre100"></div>
                          </div>
                        </td>

                        <!-- DIZAINES -->
                        <!-- affichage numero -->
                        <td v-if="(line <= dizaines)" class="nb nb-dizaines">{{line}}</td>
                        <td v-else class="nb nb-dizaines  nb-is-zero">{{line}}</td>

                        <!-- affichage des -->
                        <td v-if="(line <= dizaines)" class="blocs blocs-dizaines">
                          <div class="de-parent double" :data-value=10*line>
                            <div class="de chiffre10 "></div>
                          </div>
                        </td>
                        <td v-else class="blocs blocs-dizaines">
                          <div class="de-parent double no-border"></div>
                        </td>
                        
                        <!-- UNITES -->
                        <!-- affichage numero -->
                        <td v-if="(line <= unites)" class="nb nb-unites">{{line}}</td>
                        <td v-else class="nb nb-unites  nb-is-zero">{{line}}</td>

                        <td v-if="(line <= unites)" class="blocs blocs-unites">
                          <div class="de-parent" :data-value=line>
                            <div class="de chiffre1"></div>
                          </div>
                        </td>
                        <td v-else class="blocs blocs-unites">
                          <div class="de-parent no-border"></div>
                        </td>
                    </tr>
                  </tbody>

                  <!--TABLEAU DIZAINE-->
                  <tbody v-else>
                    <tr  v-for="line in 9" :key="line">
                        <!-- DIZAINES -->
                        <!-- affichage numero -->
                        <td v-if="(line <= dizaines)" class="nb nb-dizaines">{{line}}</td>
                        <td v-else class="nb nb-dizaines  nb-is-zero">{{line}}</td>

                        <!-- affichage des -->
                        <td v-if="(line <= dizaines)" class="blocs blocs-dizaines">
                          <div class="de-parent double" :data-value=10*line>
                            <div class="de chiffre10 "></div>
                          </div>
                        </td>
                        <td v-else class="blocs blocs-dizaines">
                          <div class="de-parent double no-border"></div>
                        </td>
                        
                        <!-- UNITES -->
                        <!-- affichage numero -->
                        <td v-if="(line <= unites)" class="nb nb-unites">{{line}}</td>
                        <td v-else class="nb nb-unites  nb-is-zero">{{line}}</td>

                        <td v-if="(line <= unites)" class="blocs blocs-unites">
                          <div class="de-parent" :data-value=line>
                            <div class="de chiffre1"></div>
                          </div>
                        </td>
                        <td v-else class="blocs blocs-unites">
                          <div class="de-parent no-border"></div>
                        </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </td>
    </tr>
  </tbody>
</table>
</template>

<script setup>

  import {ref, watch, onMounted} from "vue";
  import { getDizainesUnites } from "../../../js/utils";

  const props = defineProps({
    chiffre: {
      type: Number,
      required: true,
      default: 1
    }
  });

  let centaines = ref(0), dizaines = ref(0), unites = ref(0);

  const update = function() {
    const chiffre = parseInt(props.chiffre);
    const dizainesUnites = getDizainesUnites( chiffre );

    centaines.value = dizainesUnites.centaines;
    dizaines.value = dizainesUnites.dizaines;
    unites.value   = dizainesUnites.unites;
  };

  onMounted(() => {
    update();
  });

  watch( props, () => {
    update();
  });

</script>

<style scoped lang="scss">


  /* blocs */
  .blocs {
      width: 180px;
    }

    .blocs .de-parent {
      width: 67px;
      height: 67px;
      box-shadow: none;
      border-radius: 12px;
      box-sizing: border-box;
      margin:1px 0 0 0;
    }

    .blocs .de-parent.double {
      width: 132px;
    }

    .blocs .de-parent.no-border {
      border: none;
    }

    .blocs .de {
      background-size: cover;
      width: 65px;
      height: 65px;
    }

    .blocs.blocs-dizaines .de-parent .de {
      background-size: cover;
      width: 130px;
    }

    .blocs.blocs-centaines .de-parent {
      width: 132px;
      height: 652px;
      margin-bottom: 5px;
    }

    .blocs.blocs-centaines .de-parent .de {
      /*
      background-size: cover;
      width: 130px;
      height: 650px;
      */
      background-size: contain;
      width: 140px;
      height: 690px;
      margin-bottom: 5px;
    }

    .blocs-centaines {
      vertical-align: top;
    }

    .blocs-dizaines {
    }

    .blocs-unites {
    }


  /* des */
  .des-dizaines-unites {
    display: flex;
    border: 1px solid #0B154B;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: relative;
  }

  .des-dizaines-unites .unites {
    display: flex;
  }

  .des-dizaines-unites .dizaines {
    border-right: 1px solid black;
  }

  .des-dizaines-unites .dizaines.pas-de-dizaines {
    display: none;
  }

  .des {
    border: 1px solid #0B154B;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 15px 15px 15px;
  }

  .des-dizaines-unites .des {
    box-shadow: none;
    border: none;
    margin: auto; /* nécessaire pour l'alignement vertical */
  }

  .de-parent {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    width: 128px;
  }

  
  .half-size .de-parent {
    width:76px;
  }

  /* Unités seules */

  .des-unites {
    width: 162px;
  }

  .half-size .des-unites {
    width: 108px;
  }

  /* Dizaines- Unités seules */

  .des-dizaines-unites .de-parent.double {
    width: 168px;
    height: 128px;
  }

  .half-size .des-dizaines-unites .de-parent {
    width:76px;
    height:76px;
  }


  .unites .de-parent,
  .dizaines .de-parent {
    background: transparent;
  }

  .unites {
    background-color: rgba(59, 143, 242, 0.2);
    border-radius: 12px;
  }

  .des-dizaines-unites .unites {
    border-radius: 0 12px 12px 0;
  }

  .dizaines {
    background-color: rgba(255, 63, 51, 0.2);
    border-radius: 12px 0 0 12px;
  }

  .de {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    box-sizing: border-box;
  }


   /* ---------------------------- */
    /* Table des chiffres */

    table.table-header-et-dizaines-unites {
      border: none;
      border-collapse: collapse;
      border-spacing: 0;
    }

    table.table-header-et-dizaines-unites tr, table.table-header-et-dizaines-unites td {
      margin:0;
    }

    .table-parent-dizaines-unites {
      width: 100%;
      border: 1px solid #0B154B;
      box-sizing: border-box;
      border-radius: 12px;
      max-height: calc( 100vh - 290px );
      overflow: auto;
    }

    .table.dizaines-unites {
      width: 100%;
      border: solid 0 black;
      border-collapse:collapse;
    }

    .table.dizaines-unites tr {
      min-height: 68px;
    }

    .table.dizaines-unites td {
      border: solid 0 black;
      text-align: center;
      height: 78px;
    }

    .table.dizaines-unites td {
      color: #18CD4B;
    }

    table.dizaines-unites td.nb-centaines {
      color: #18CD4B;
    }

    table.dizaines-unites td.nb-dizaines {
      color: #FF3F33;
    }

    table.dizaines-unites td.nb-unites {
      color: #3B8FF2;
    }

    .table-column-title {
      text-align:left;
      width: 33%;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 12px;
      padding-left: 20px;
    }

    .table-column-title-centaines {
      color: #18CD4B;
    }

    .table-column-title-dizaines {
      color: #FF3F33;
    }

    .table-column-title-unites {
      color: #3B8FF2;
    }

    .nb {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
      padding: 0 0 0 10px;
      width: 25px;
    }

    .nb.nb-is-zero {
      opacity: 0.25;
    }

    .nb-centaines {
    }

    .nb-dizaines {
    }

    .nb-unites {
    }



  /* iPad */

  @media screen and (max-width: 1080px ) {

    .des {
      padding: 15px 10px;
    }

    .half-size .de-parent {
      width:50px;
      height:50px;
    }

    .half-size .de-parent.double {
      width:70px;
      height: 35px;
    }

  }

</style>
