<template>
  <a class="svg-icon settings-link" href="#"></a>
</template>

<style scoped lang="scss">

  a.svg-icon.settings-link {
    background-image: url(../../../assets/images/svg/icones/settings.svg);
  }

</style>
