<template>
  <a class="demo dedys-recommencer" href="https://prototype-cognitice.dedys.fr" title="prototype dédys v1">
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" style="transform:translate(0,2px)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33325 1L10.6666 7L1.33325 13V1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    Commencer
  </a>
</template>

<script setup>
</script>

<style scoped lang="scss">
</style>
