<template>
  <div class="help-panel help-panel-dizaines-unites" :class="isOpenedCssClass">
    <div class="help-panel-button">
      <a href="#" @click="togglePanel($event)"></a>
    </div>
    <div class="table-parent">
      <table class="help-panel-contents">
        <thead>
          <tr>
            <th class="help-panel-close-parent-button">
              <a class="help-panel-close-button" href="#" @click="closePanel($event)"></a>
            </th>
            <th>
              <div>Dizaines</div>
            </th>
            <th>
              <div>Unités</div>
            </th>
          </tr>
        </thead>
        <tbody class="thin-scroll">
          <tr v-for="item in items" :key="item">
            <td>
              {{ item }}
            </td>
            <td>
              <DesDizainesNoirEtBlanc :chiffre="1" />
            </td>
            <td>
              <DeUniteNoirEtBlanc :chiffre="1" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>

  import {computed, ref} from "vue";

  import DesDizainesNoirEtBlanc from "../Des/DesDizainesNoirEtBlanc/DeDizaineNoirEtBlanc";
  import DeUniteNoirEtBlanc from "../Des/DesUnitesNoirEtBlanc/DeUniteNoirEtBlanc";

  const items = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];
  const isOpened = ref(false);
  const isOpenedCssClass = computed(() => isOpened.value ? 'opened' : '');

  // Fermer / Ouvrir (transition CSS)

  function togglePanel($event) {
    $event.preventDefault();
    isOpened.value = ! isOpened.value;
  }

  function closePanel($event) {
    $event.preventDefault();
    isOpened.value = false;
  }

</script>

<style scoped lang="scss">

.help-panel-dizaines-unites {

  transform: translateX(470px);
  transition: transform ease-in-out 0.75s;

  &.opened {
    transform: translateX(0);
  }

  table {

    width: 450px;

    tbody {
      display: block;
      height: calc( 100vh - 110px );
      overflow: auto;
      pointer-events: auto;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
    }

  }


}

</style>
