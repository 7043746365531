<template>
  <div @click="playSoundChiffre">
    <DeDizaineComplex0 v-if="refChiffre === 0" :css-class="cssClass" />
    <DeDizaineComplex1 v-else-if="refChiffre === 1" :css-class="cssClass" />
    <DeDizaineComplex2 v-else-if="refChiffre === 2" :css-class="cssClass" />
    <DeDizaineComplex3 v-else-if="refChiffre === 3" :css-class="cssClass" />
    <DeDizaineComplex4 v-else-if="refChiffre === 4" :css-class="cssClass" />
    <DeDizaineComplex5 v-else-if="refChiffre === 5" :css-class="cssClass" />
    <DeDizaineComplex6 v-else-if="refChiffre === 6" :css-class="cssClass" />
    <DeDizaineComplex7 v-else-if="refChiffre === 7" :css-class="cssClass" />
    <DeDizaineComplex8 v-else-if="refChiffre === 8" :css-class="cssClass" />
    <DeDizaineComplex9 v-else-if="refChiffre === 9" :css-class="cssClass" />
    <DeDizaineComplex10 v-else-if="refChiffre === 10" :css-class="cssClass" />
  </div>
</template>

<style scoped lang="scss">
</style>

<script setup>

import DeDizaineComplex0 from "./DeDizaineComplex0";
import DeDizaineComplex1 from "./DeDizaineComplex1";
import DeDizaineComplex2 from "./DeDizaineComplex2";
import DeDizaineComplex3 from "./DeDizaineComplex3";
import DeDizaineComplex4 from "./DeDizaineComplex4"
import DeDizaineComplex5 from "./DeDizaineComplex5";
import DeDizaineComplex6 from "./DeDizaineComplex6";
import DeDizaineComplex7 from "./DeDizaineComplex7";
import DeDizaineComplex8 from "./DeDizaineComplex8";
import DeDizaineComplex9 from "./DeDizaineComplex9";
import DeDizaineComplex10 from "./DeDizaineComplex10";

import {ref, onMounted, watch} from "vue";

import {
  getSoundPath_Chiffre,
  getSoundPath_Dizaine,
  playSound
} from "../../../../js/utils";


//
// Propriétés du composant
//

const props = defineProps({
  chiffre: {
    type: Number,
    required: true,
    default: 1
  },
  cssClass: {
    type: String,
    required: false,
    default: ""
  },
  withSound: {
    type: Boolean,
    required: false,
    default: true
  },
  withSoundDecomposed: {
    type: Boolean,
    required: false,
    default: false
  }
});


//
// Reactive values
//


let refChiffre = ref();

const update = function() {
  refChiffre.value = props.chiffre;
};

const playSoundChiffre = function() {
  var soundPath;
  if (props.withSound) {
    if (props.withSoundDecomposed)
      soundPath = getSoundPath_Dizaine(refChiffre.value);
    else 
      soundPath = getSoundPath_Chiffre(refChiffre.value * 10);
    playSound(soundPath);
  } 
}



//
// LifeCycle hooks
//

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});



</script>