<template>
  <div class="palette-poubelle-v2">
    <div class="palette-poubelle-outer-border">
      <div class="palette-poubelle-content">
        <PoubelleRougeBtn @supprimer="supprimer()" />
      </div>
    </div>
  </div>
</template>

<script setup>

import PoubelleRougeBtn from "../Boutons/PoubelleRougeBtn";

const emit = defineEmits(['supprimer'])

const supprimer = function(){
  emit("supprimer");
}

</script>

<style scoped lang="scss">

  .palette-poubelle-v2 {

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 32px;

    width: 88px;
    height: 80px;

    /* White / 100 */
    background: #FFFFFF;
    border: 8px solid rgba(255, 63, 51, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px 16px 0px 0px;

    border-bottom: none;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    & > .palette-poubelle-outer-border {

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 24px 32px;
      gap: 10px;

      width: 72px;
      height: 100%;

      /* Colors/Red - 10 */
      background: rgba(255, 63, 51, 0.1);
      box-shadow: inset 0px 4px 0px rgba(105, 105, 105, 0.5);
      border-radius: 8px 8px 0px 0px;

      /* Inside auto layout */
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      & > .palette-poubelle-content {
        display: flex;
        justify-content: center;
        width: 100%;
      }

    }


  }

</style>
