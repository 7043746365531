<template>
  <div class="responsive-warning">
    <div class="responsive-warning-content">

      <div class="small-dicey">
        Dédys
      </div>

      <div class="unsupported">
        <div class="ada-accueil"></div>
        <h1>Cette taille d’écran n’est pas supportée pour le moment</h1>
        <p>
          Désolé mais cette résolution n’est pas encore prise en charge par Dédys.
        </p>
        <p>
          Connecte-toi sur un écran dont la largeur est supérieure à 1200px pour pouvoir avoir accès à l'ensemble des activités.
        </p>
        <p>
          Avec une tablette de largeur supérieure ou égale à 1080px, tu auras accès à une partie des niveaux disponibles seulement.
        </p>
      </div>

      <div class="rotation-needed">
        <div class="rotation-symbol"></div>
        <h1>Tourne ton dispositif pour pouvoir continuer</h1>
        <p>Dédys ne fonctionne qu’au format horizontal.</p>
      </div>

      <div class="print-landscape-needed">
        <div class="rotation-symbol"></div>
        <h1>Choisissez le format Paysage pour imprimer</h1>
        <p>Dans les paramètres d'impression, activez l'impression <br/>des arrière-plans</p>
      </div>

      <div>
        <ul class="footer-links">
          <li>
            <router-link to="/">
              Accueil
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss">

  .responsive-warning {
    display: none;

    position: fixed;
    top:0;
    left:0;
    z-index: 10001;

    height: 100vh;
    width: 100vw;
    background: #FFFFFF url(../../assets/images/svg/bg2.svg);

    @include on-print {
      background-image: none;
    }

    @media (orientation: portrait) {
      display: block;

      .unsupported {
        display: none;
      }
    }

    /* Non supporté */
    @media screen and (max-width:420px) and (orientation : landscape) {
      display: block;

      .rotation-needed {
        display: none;
      }
    }


    /* Portrait : message si mobile un peu large */
    @media screen and (max-width:420px) and (orientation : portrait) {
      display: block;

      .rotation-needed {
        display: block !important;
      }

      .unsupported {
        display: none !important;
      }
    }

    /* Message si mobile de petite taille : 420px de largeur */
    @media screen and (max-width:300px) and (orientation : portrait) {
      display: block;

      .rotation-needed {
        display: none !important;
      }

      .unsupported {
        display: block !important;
      }
    }

    @media screen and (max-height:300px) and (orientation : landscape) {
      display: block;

      .rotation-needed {
        display: none !important;
      }

      .unsupported {
        display: block !important;
      }
    }



    /* Impression portrait non supportée */

    .print-landscape-needed {
      display: none;
    }

    @media print and (orientation : portrait) {
      display: block;

      .rotation-needed {
        display: none !important;
      }

      .print-landscape-needed {
        display: block !important;
      }

      .unsupported {
        display: none !important;
      }
    }



    .responsive-warning-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 20px 50px;

      h1 {
        display: block;
        font-size: 28px;
        line-height: 1.1;
        margin-bottom: 30px;
        text-align: center;

        @include respond-small-mobile {
          font-size: 22px;
        }

        @media screen and (max-height:500px) and (orientation : landscape) {
          font-size: 22px;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 10px;

        @include respond-small-mobile {
          font-size: 14px;
        }

        @media screen and (max-height:500px) and (orientation : landscape) {
          font-size: 14px;
        }

        @include on-print {
          font-size: 18px;
          line-height: 1.25;
        }
      }

      .rotation-symbol {
        display: inline-block;
        width: 133px;
        height: 111px;
        background: url(../../assets/images/svg/rotate-device.svg) center / cover no-repeat;
        margin-bottom: 20px;

        @include on-mobile {
          background-size: contain;
          width: 120px;
          height: 90px;
          top: 32%;
        }
      }

      .unsupported,
      .rotation-needed,
      .print-landscape-needed {
        text-align: center;
      }

      .print-landscape-needed::after,
      .rotation-needed::after {
        content: "";
        position: absolute;
        right: 0;
        top: 200px;
        width: 182px;
        height: 248px;
        background: url(../../assets/images/svg/ada/ada-portrait.svg) center / cover no-repeat;

        @include on-mobile {
          background-size: contain;
          width: 120px;
          height: 160px;
          top: 32%;
        }
      }

      .small-dicey {
        padding-left: 42px;
        background: url(../../assets/images/svg/ada/Small_Dicey.svg) left center / 38px 38px no-repeat;

        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #666666;
      }

      .ada-accueil {
        display: inline-block;
        width: 248px;
        height: 217px;
        background: url(../../assets/images/svg/ada/ada-accueil.svg) center / cover no-repeat;
        margin-bottom: 20px;

        @include on-mobile {
          width: 124px;
          height: 109px;
          margin: 10px 0;
        }

        @media screen and (max-height:500px) and (orientation : landscape) {
          width: 124px;
          height: 109px;
          margin: 10px 0;
        }
      }
    }

    ul.footer-links {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      @media screen and (max-height:500px) and (orientation : landscape) {
        top: 20px;
        left: 20px;
        bottom: unset;
        transform: none;
      }

      li {
        padding: 0;
        margin: 0;

        a {
          color: #666666;
          text-decoration: none;
        }
      }
    }
  }

</style>
