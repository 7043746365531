<template>
  <div class="basic-button">
    <div class="basic-button-outer-border">
      <div class="basic-button-inner-border">
        <div class="basic-button-content">
          <div class="basic-button-icon"></div>
          <div class="basic-button-hilite"></div>
          <div class="basic-button-title">
            <h2 class="titan">
              <span v-if="titre.length">
                {{ titre }}
              </span>
              <span v-if="niveau.length">
                {{ niveauStr }}
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";

const props = defineProps({
  titre: {
    type: String,
    required: false,
    default: ""
  },
  niveau: {
    type: Number,
    required: false,
    default: 0
  }
});

let niveau = ref(1);
let niveauStr = ref('');

const update = function() {
  niveau.value = parseInt(props.niveau);
  niveauStr.value = niveau.value.length ? "Table " + niveau.value : "";
}

onMounted(() => {
  update();
});

watch( props, () => {
  update();
});

</script>


<style scoped lang="scss">

  .basic-button:hover {

    cursor: pointer;

    .basic-button-outer-border {
      background: #FFD32C;
    }
  }

  .basic-button {

    position: relative;
    display: block;
    height: 98px;

    .basic-button-outer-border {

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0.41%;

      /* New DA/Grey */
      background: #E8E8E8;

      /* New DA / Black */
      border: 2px solid #000000;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #000000;
      border-radius: 16px;

      .basic-button-inner-border {

        position: absolute;
        left: 12px;
        right: 12px;
        top: 12px;
        bottom: 11.59px;

        /* New DA/Grey 2 */
        background: #A0A0A0;
        mix-blend-mode: luminosity;

        /* New DA / Black */
        border: 1px solid #000000;
        border-bottom-width: 2px;
        box-shadow: 0px 2px 0px #000000;
        border-radius: 10px;

        .basic-button-content {

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          left: 1px;
          right: 1px;
          top: 1px;
          bottom: 1px;
          height: 100%;

          /* New DA/Grey 2 */
          background: #A0A0A0;

          /* White / 100 */
          border: 3px solid #FFFFFF;
          border-radius: 8px;

          .basic-button-hilite {

            position: absolute;
            left: 2.17%;
            right: 2.17%;
            top: 3.77%;
            bottom: 48.58%;

            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 86.98%, rgba(255, 255, 255, 0.3) 100%);
            opacity: 0.2;
            border-radius: 4px;
          }

          .basic-button-title {

            position: relative;
            z-index: 3;

            h2.titan {
              display: block;
              font-size: 28px;
              line-height: 32px;
              -webkit-text-stroke-width: 1px;
              text-shadow: 0px 3px 0px #333333;
              text-align: center;
              letter-spacing: -1px;
            }

          }

        }
      }
    }
  }
</style>

