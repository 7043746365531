<template>
    <a class="trash-btn" href="#" @click="supprimer($event)">
        <span  class="icone afficher-des" ></span>
    </a>
</template>

<script setup>

const emit = defineEmits(['supprimer'])

const supprimer = function($event){
  $event.preventDefault();
  emit("supprimer");
}

</script>


<style scoped lang="scss">

a.trash-btn {
  display: block;
  width: 25px;
  height: 24px;
  background: url(../../../assets/images/svg/icones_v2/trash-rouge.svg) center / contain no-repeat;
}

</style>
