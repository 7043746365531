<template>
  <div class="content content-v1">
    <PageHeader class="page-header" />
    <div class="home-page-content">
      <!-- -->
      <div class="title">
        <p>Bienvenue sur</p>
        <div class="title-image">
          <!-- -->
          <h1>Dédys</h1>
          <!-- -->
          <div class="version-no">
            <a href="#" @click="openVersionMenu($event)">v 1.0</a>
            <ul class="version-menu" :class="menuCssClass">
              <li>
                <router-link to="/">v 2.0</router-link>
              </li>
              <li>
                <router-link to="/v1/accueil" class="selected">v 1.0</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- -->
      <CommencerBtn />
   </div>
    <ResponsiveWarning />
  </div>
</template>

<script setup>

  import {computed, onMounted, onUnmounted, ref} from "vue";

  import PageHeader from "./PageHeader";
  import CommencerBtn from "../../../components/v1/Boutons/CommencerBtn";
  import ResponsiveWarning from "../../../components/v2/ResponsiveWarning";


  //
  // Reactive values
  //

  const menuOpened = ref(false);

  const menuCssClass = computed(() => {
    return menuOpened.value ? "opened" : "";
  });


  //
  // LifeCycle Hooks
  //

  onMounted(() => {
    document.body.addEventListener("click", closeVersionMenu);
  });

  onUnmounted(() => {
    document.body.removeEventListener("click", closeVersionMenu);
  });


  //
  // Fonctions spécifiques
  //

  const openVersionMenu = function($event){
    $event.preventDefault();
    $event.stopPropagation();
    menuOpened.value = true;
  }

  const closeVersionMenu = function(){
    menuOpened.value = false;
  }

</script>

<style scoped lang="scss">

  .content-v1 {

    .page-header {
      justify-content: flex-end;
    }

    .home-page-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 35px;

      width: 100%;
      height: calc( 100vh - 200px );


      .title {
        display: inline-block;
        color: #0B154B;

        & > p {
          font-size: 28px;
          font-weight: 500;
          line-height: 35px;
          text-align: center;
        }

        .title-image {
          display: flex;
          align-items: flex-end;
          margin-left: 80px;
          margin-top: 20px;

          h1 {
            display: block;
            width: 638px;
            height: 180px;
            background: #FFFFFF url(../../../assets/images/svg/accueil/logo_dedys_v1_accueil.png) center / 638px auto no-repeat;

            text-indent: -9999px;
            font-size: 2px;
          }

          .version-no {
            position: relative;
            transform: translate(10px, -23px);

            & > a {
              display: inline-block;
              font-family: 'Quicksand', sans-serif;
              font-weight: 500;
              font-size: 24px;
              line-height: 30px;
              text-decoration: none;
              color: rgba(102,102,102, 0.65);

              &:after {
                content: "";
                display: inline-block;
                width: 20px;
                height: 30px;
                background: url(../../../assets/images/svg/chevron-bas.svg) right bottom / 14px auto no-repeat;
                opacity: 0.65;
              }

              &:hover {
                text-decoration: underline;
              }
            }

            .version-menu {
              visibility: hidden;

              position: absolute;
              top: 40px;
              left: 0;

              display: inline-block;
              background: #FFF;
              border: solid 1px #000;
              width: 50px;
              margin: 0;
              padding: 10px 20px;
              list-style-type: none;

              &.opened {
                visibility: visible;
              }

              & > li > a {
                text-decoration: none;
                color: #000;

                &.selected {
                  color: rgba(102,102,102, 0.65);
                  pointer-events: none;
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }

          }
        }
      }

    }

  }

</style>
