<template>
  <a class="close-window-btn" href="#">
  </a>

</template>

<script>
</script>


<style scoped lang="scss">

a.close-window-btn {
  display: inline-block;
  width: 24px;
  height: 25px;
  background: url(../../../assets/images/svg/close-window.svg) center / 24px auto no-repeat;
  text-decoration: none;
}

</style>
