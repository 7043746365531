<template>
    <div class="couleur-point"></div>
</template>

<style scoped lang="scss">
    .couleur-point {
      position: absolute;
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background-color: transparent;
    }

    .correct {
      opacity: 1 !important;
    }

    .incorrect {
      /*
      opacity: 0.5 !important;
      background-color: #220000 !important; 
      */
    }

    .jaune {
      opacity: 1 !important;
      background-color: #FFAB02 !important; 
    }
   
    .rouge {
      opacity: 1 !important;
      background-color: #FF3F33 !important; 
    }

    .violet {
      opacity: 1 !important;
      background-color: #CA8BFE !important; 
    }

    .vert {
      opacity: 1 !important;
      background-color: #58CE45 !important; 
    }

    .bleu {
      opacity: 1 !important;
      background-color: #5BA0F1 !important; 
    }

</style>
