<template>
  <a class="settings-des-noir-et-blanc" href="#"></a>
</template>

<script>
</script>

<style scoped lang="scss">

a.settings-des-noir-et-blanc {
  display: inline-block;
  width: 133px;
  height: 137px;
  text-decoration: none;
  background: url(../../../assets/images/svg/settings/Parametres_de_noir_et_blanc_off.svg) center / 133px auto no-repeat;

  &.on {
    background-image: url(../../../assets/images/svg/settings/Parametres_de_noir_et_blanc_on.svg);
  }
}

</style>
