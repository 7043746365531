<template>
    <div class="jeu-du-tableau-v2" :class="niveauCssClass" >

        <div class="slots-parent">

          <Slots
              ref="slots"
              :chiffre="chiffreSlot"
              :has-footer="true"
              :has-validation="withValidation"
              :isHidden=true
              :withoutColors="withoutColors"
              :with-sound="withSound"
              class="width-156"
          />

        </div>

        <ClavierBoutonsDecomposition
            class="clavier"
            ref="etiquettes"
            :is-shuffled="true"
            :nombreEtiquettes="nombreEtiquettesClavier"
        />

      <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne_CurrentNumber"/>

    </div>
</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import _ from 'lodash';
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath,
    playSound,
    getSoundPath_Consigne,
    randomIntFromInterval,
    getSoundPath_Chiffre,
    getSound_Felicitations,
    getSoundPath_Colonne,
  } from "../../../js/utils";

  import ClavierBoutonsDecomposition from "../Palettes/PaletteEtiquettesDecomposition";
  import Slots from "../Slots";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 99;
  let draggables;

  // Zones occupées
  let filledZones;


  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    withColors: {
      type: Boolean,
      required: false,
      default: true
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Référence du composant Slots :
  const slots = ref(null);

  // Valeur affichée dans ce composant
  let chiffreSlot = ref(123456);

  // Clavier avec les étiquettes
  let etiquettes = ref(null);

  let withoutColors = ref(true);

  const nombreEtiquettesClavier = computed(() => niveauActivite.value < 3 ? 3 : 6 );

  const niveauCssClass = computed(() => {
    return "niveau-" + niveauActivite.value;
  });


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values, props.withColors)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues(), props.withColors);
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }


  //
  // Niveaux
  //

  const initNiveau = function(niveau, slotWithColors) {

    niveauActivite.value = niveau;

    switch(niveau) {

      default:
      case 1:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        withoutColors.value = false;
        successMax.value = 8;
       break;

      case 2:
        currentMinimumNumber = 100;
        currentMaximumNumber = 999;
        withoutColors.value = true;
        successMax.value = 8;
        break;

      case 3:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        withoutColors.value = false;
        successMax.value = 8;
        break;

      case 4:
        currentMinimumNumber = 10000;
        currentMaximumNumber = 99999;
        withoutColors.value = true;
        successMax.value = 8;
        break;
    }

    // Couleurs de fond Slots ( via l'URL)
    if (slotWithColors !== undefined) {
      withoutColors.value = ! slotWithColors;
    }

    // Ada
    success = 0;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 100) {
      return 2;
    } else if (maxValue <= 1000) {
      return 3;
    } else if (maxValue <= 10000) {
      return 4;
    } else if (maxValue <= 100000) {
      return 5;
    } else {
      return 6;
    }
  }



  //
  // Fonctions spécifiques à l'activité
  //

  function initDragAndDrop() {

    removeDragAndDrop();

    var dropZones = $(".slot-zone");
    var overlapThreshold = "50%";
    var isDragging = false;
    var selectedDraggable;
    var draggableDefaultOffset;
    var composantSlots = slots.value;

    const $target = $(".draggable", '.clavier').not('.draggable-initialized');
    $target.addClass('draggable-initialized');

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create($target, {
      type: "x,y",
      bounds: window,
      throwProps: true,
      onPress: function (pointEvent) {

        removeDraggedStyle();

        // On ajoute l'effet au dé déplacé
        var draggableElement = $(pointEvent.target).closest(".draggable");
        draggableElement.find('.with-dragged-style').addClass("dragged"); // Effet sur la carte ou le dé

        selectedDraggable = draggableElement;

        // On note la position d'origine sur la palette
        var target = this.target;
        var draggableTarget = $(target);
        draggableDefaultOffset = draggableTarget.offset();

        // On tient compte du fait que l'étiquette a peut-être déjà été mise dans un slot via le drag and drop : on doit retirer ce déplacement
        draggableDefaultOffset.left -= gsap.getProperty(target, 'x');
        draggableDefaultOffset.top -= gsap.getProperty(target, 'y');
      },
      onDragStart: function (pointEvent) {

        var draggableTarget = $(pointEvent.target);
        var draggableElement = draggableTarget.closest('.draggable');
        var etiquette = draggableElement.find('.with-value').data('value');

        if (props.withSound) {
          var soundPath = getSoundPath("decomposition/" + etiquette);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        dropZones.css("pointer-events", "none");

        // On doit éventuellement retirer l'élément s'il est placé sur un slot
        removeFromFilledZone(etiquette);
      },
      onDragEnd: function (pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        var dropZones = $(".slot-zone");

        setTimeout(function () {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableTarget = $(pointEvent.target);
        var draggableElement = draggableTarget.closest('.draggable');
        var etiquette = draggableElement.find('.with-value').data('value');

        var i = dropZones.length;
        var dropZone, dropZoneFound = false, dropResult;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {

            // Vérification : l'étiquette est-elle la bonne ?
            dropResult = checkZone(dropZone, etiquette, this.target);

            // Version 1 : en cas d'erreur, l'étiquette reste là où la dépose
            dropZoneFound = true;

            // Version 2 : en cas d'erreur, l'étiquette revient à sa place (dans la palette en bas)
            // dropZoneFound = dropResult.success;

            const zoneNo = dropResult.dropZoneNo - 1;
            const previousResult = filledZones[zoneNo];
            if (previousResult && previousResult.dragElement) {
              gsap.set(previousResult.dragElement, {x: 0, y: 0});
            }

            // On met à jour les LEDs
            if (dropResult.success) {
              composantSlots.setSlotExternalValue(dropResult.dropZoneNo, dropResult.dropNombre);
              composantSlots.setSlotVisible(dropResult.dropZoneNo, false);
            } else {
              composantSlots.setSlotExternalValue(dropResult.dropZoneNo, -1);
              composantSlots.setSlotVisible(dropResult.dropZoneNo, false);
            }

            // On mémorise le résultat
            filledZones[zoneNo] = dropResult;

            // Snap
            const dropZoneOffset = dropZone.offset();
            const dx = dropZoneOffset.left - draggableDefaultOffset.left;
            const dy = dropZoneOffset.top - draggableDefaultOffset.top;
            gsap.set(this.target, {x: dx, y: dy});

            break;
          }
        }

        if (! dropZoneFound)
        {
          // Retour à la position initiale
          gsap.set(this.target, {x: 0, y: 0, delay: 0.1});
        }
        else
        {
          // On regarde si tout est rempli, et si oui si tout est bon :
          let i, slotInfos;
          let isComplete = true;
          let isCorrect = true;

          for(i=0; i < filledZones.length; i++) {
            slotInfos = filledZones[i];
            if (! slotInfos) {
              isComplete = false;
              break;
            } else {
              isCorrect &= filledZones[i].success
            }
          }

          if (dropResult.success) {

            // Bonne étiquette
            if (isComplete && isCorrect) {

              //
              // Toutes les étiquettes sont correctement placées
              //

              // On empêche l'interaction de l'activité en cours
              updateAda("lock");

              // Son de félicitations, succès et replay
              playSound_Felicitations_and_AddSuccess();

            } else {

              if (props.withSound) {
                const soundPathChiffre = getSoundPath_Colonne(dropResult.dropNombre, dropResult.etiquette)
                playSound(soundPathChiffre);
              }

              updateAda("normal");
            }

          } else {
            // Mauvaise étiquette
            updateAda("error", 3000);
          }

          // On stocke les statistiques :
          if (isComplete) {

            let propositions = [];
            const n = String(chiffreSlot.value).length;
            for(i=0; i < n; i++) {
              propositions.push(filledZones[n - 1 - i].etiquette);
            }

            slotChanged({
              slotNo: dropResult.dropZoneNo,
              slotDroppedValue: dropResult.etiquette,
              slotCorrectValue: dropResult.dropZoneValue,
              chiffre: chiffreSlot.value,
              isCorrect: isCorrect && isComplete,
              isComplete: isComplete,
              proposed: propositions.reverse().join(' | ')
            })
          }

        }

        isDragging = false;
      },
      onDrag: function () {
        var i = dropZones.length, dropZone;
        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le composant à déplacer */
    dropZones.on('click', function () {
      if (!isDragging && selectedDraggable) {
        /*
          $('.draggable').removeClass("dragged");
          var nombre = selectedDraggable.data('value');
          var dropZone = $(this);
          // Vérification :
          checkZone(dropZone, nombre, selectedDraggable);
        */
      }
    });

  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  function initActivite( chiffre, avecConsigne = false ) {

    // Ada
    updateAda("normal");

    if ((chiffre === null) || (chiffre === undefined))
    {
      // Tirage au sort des chiffres
      chiffre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);
    }
    else
    {
      // Saisie manuelle
    }

    // Chiffre à trouver :
    chiffreSlot.value = chiffre;
    // console.log("----> chiffreSlot", chiffre);

    // Clavier Etiquette
    if (! etiquettes.value) return;
    etiquettes.value.shuffletEtiquettes();

    // Etiquettes
    gsap.set($(".draggable"), {x: 0, y: 0});

    // Zones occupées
    filledZones = [];
    const nbFilledZones = String(chiffre).length;
    for(let i=0; i < nbFilledZones; i++) {
      filledZones.push(false);
    }

    if (avecConsigne) {
      // Son de la consigne, puis du chiffre
      playSound_Consigne_CurrentNumber();
    } else {
      playSound_CurrentNumber();
    }

    setTimeout(initDragAndDrop, 100);
  }

  function checkZone( dropZone, etiquette, draggableElement ) {

    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneNumber = parseInt( dropZone.attr("data-value") );
    const dropZoneCorrectDecomposition = dropZone.attr("data-decomposition");

    // console.log("checkZone", dropZoneNo, etiquette, dropZoneCorrectDecomposition);

    return {
      success: etiquette === dropZoneCorrectDecomposition,
      etiquette: etiquette,
      dragElement: draggableElement,
      dropZoneNo: dropZoneNo,
      dropZoneValue: dropZoneCorrectDecomposition,
      dropNombre: dropZoneNumber
    }
  }

  function removeFromFilledZone( etiquette ) {
    const nbFilledZones = filledZones.length;
    const composantSlots = slots.value;
    let i, dropResult;

    for(i=0; i < nbFilledZones; i++) {
      dropResult = filledZones[i];
      if (dropResult.etiquette === etiquette) {

        composantSlots.setSlotExternalValue(dropResult.dropZoneNo, null);
        composantSlots.setSlotVisible(dropResult.dropZoneNo, false);

        filledZones[i] = false;
      }
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Statistiques
  //

  const slotChanged = function($event) {

    // console.log('slotChanged', $event);

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: 'Place les étiquettes pour le chiffre suivant',
        solution: $event.chiffre,
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }



  //
  // Sons
  //

  const playSound_CurrentNumber = function() {
    if (props.withSound)
    {
      const soundPath = getSoundPath_Chiffre(chiffreSlot.value);
      playSound(soundPath);
    }
  };

  function playSound_Consigne_CurrentNumber() {
    if (props.withSound)
    {
      const soundPathChiffre = getSoundPath_Chiffre(chiffreSlot.value);
      const soundPath1 = getSoundPath_Consigne("place-les-bonnes-etiquettes-au-dessus-de-chaque-chiffre", true, "new");
      if (niveauActivite.value === 1 || niveauActivite.value === 3 ) {
        const soundPath2 = getSoundPath_Consigne("tu-peux-t-aider-grace-aux-couleurs-mais-attention-aux-pieges", true, "new");
        playSound([ soundPath1, soundPath2, soundPathChiffre ], () => {updateAda("help")});
      }
      else {
        playSound([ soundPath1, soundPathChiffre], () => {updateAda("help")} );
      }
    }
  }

  function playSound_Felicitations_and_AddSuccess() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPathFelicitations = getSound_Felicitations();
        playSound(soundPathFelicitations, addSuccessAndReplay);
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }

</script>

<style lang="scss">

  .jeu-du-tableau-v2 {

    @include on-tablet {
      padding-top: 50px;
    }

    .slots-parent {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      margin-bottom: 50px;

      .slots .slots-content {
        height: 116px;
        border-radius: 40px;

        div {
          border-radius: 32px;
        }
      }
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    &.niveau-3,
    &.niveau-4 {

      .ada {
        @include on-tablet {
          bottom: 118px;
        }
      }

    }
  }

</style>
