<template>
  <div class="decomposer-v2" >

    <div class="des-et-chiffres-a-trouver">
      <div class="question not-draggable">
        <h3 class="titan">{{ consigne }}</h3>
        <Card
            :chiffre="chiffreCard"
            :hasNumber=true
            :code="code"
            :css-class="formeDes"
            :has-separator="separateurMilliers"
            :with-sound="withSound"
        />
      </div>

      <div class="reponse">
        <h3 class="titan">il y a</h3>
        <Slots
            class="vertical-offset-for-flex"
            ref="slots"
            :chiffre="chiffreSlot"
            :isHidden="true"
            :has-validation="withValidation"
            :decomposition-mininum="decompositionMinimum"
            :without-colors="true"
            :with-sound="withSound"
            @change="slotChanged"
        />
        <h3 class="titan">{{ consigneSlot }}</h3>
      </div>
    </div>

    <ClavierChiffres class="clavier" :nb-touches-par-ligne="10" />

    <Ada ref="ada" @replay="replay" :niveau="niveauActivite" :successMax="successMax" @readConsigne="playSound_Consigne_CurrentNumber"/>

  </div>
</template>

<script setup>

  import {onMounted, ref, watch, defineExpose, computed, onUnmounted} from "vue";
  import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";

  import $ from 'jquery'
  import _ from 'lodash';
  import { gsap } from 'gsap';
  import {Draggable} from "gsap/Draggable"
  gsap.registerPlugin(Draggable);

  import {
    getSoundPath_Chiffre,
    getSoundPath_Chiffre_AsArray,
    getSoundPath_Consigne,
    playSound,
    randomIntFromInterval,
    shuffleArray
  } from "../../../js/utils";

  import ClavierChiffres from "../Palettes/ClavierChiffres";
  import Slots from "../Slots";
  import Card from "../Card";
  import Ada from "../Ada";
  import {useStore} from "vuex";

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  // Selon niveau :
  let currentMinimumNumber = 10;
  let currentMaximumNumber = 99;

  let currentSlots = [];
  let currentSlotsCopy = [];
  let currentSlot;
  let draggables;



  //
  // Propriétés du composant
  //

  const props = defineProps({
    niveau: {
      type: Number,
      required: false,
      default: 1
    },
    withCustomValues: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: [String, Array],
      required: false,
      default: ""
    },
    colonne: {
      type: String,
      required: false,
      default: "dizaines"
    },
    formeDes: {
      type: String,
      required: false,
      default: "en-couleurs"
    },
    separateurMilliers: {
      type: Boolean,
      required: false,
      default: true
    },
    withSound: {
      type: Boolean,
      required: false,
      default: true
    },
    withValidation: {
      type: Boolean,
      required: false,
      default: true
    },
  });


  //
  // Reactive values
  //

  let niveauActivite = ref(1);

  // Référence du composant Slots :
  const slots = ref(null);

  // Code :
  const code = ref(null)

  // Valeur affichée dans le composant Card
  let chiffreCard = ref(123456);

  // Valeur affichée dans le composant Slots
  let chiffreSlot = ref(123456);

  let decompositionMinimum = ref();

  let slotPluriel = ref('');
  let slotSingulier = ref('');

  const consigne = computed( () => {
    if (slotPluriel.value === "unités") {
      return "Combien y a t il d'" + slotPluriel.value + " en tout dans";
    }
    return "Combien y a t il de " + slotPluriel.value + " en tout dans";
  });

  const consigneSlot = computed( () => {
    return chiffreSlot.value > 1 ? slotPluriel.value : slotSingulier.value;
  });


  //
  // Ada
  //

  let ada = ref();
  let successMax = ref();
  let success = 0;

  const updateAda = function(value, duration = 0) {
    if (props.withValidation || (value !== "error" && value !== "error")) {
      let composantAda = ada.value;
      if (composantAda) composantAda.setAda(value, duration);
    }
  }


  //
  // Lifecycle hooks
  //

  watch( props, () => {
    init();
  });

  onMounted(() => {
    init();
  });

  onUnmounted(() => {
    removeDragAndDrop();
  });

  onBeforeRouteUpdate(async () => {
    // Changement de niveau sans changement de view
    removeDragAndDrop();
  });


  //
  // Méthodes publiques
  //

  defineExpose({
    replay
  })

  function replay() {
    const nextRoute = store.getters.nextPlaylist(route);
    if (nextRoute) {
      // Playlist :
      router.push(nextRoute);
    } else {
      initActivite();
    }
  }


  //
  // Init
  //

  const init = function() {
    // console.log("props.values", props.withCustomValues, props.values)
    if (props.withCustomValues) {
      // A. Initialisation avec des valeurs passées dans l'URL
      // Le niveau est déduit de la plus grande des valeurs
      initNiveau(getNiveauFromValues());
      initActivite(props.values, true);
    } else {
      // B. Initialisation d'un niveau, avec des valeurs aléatoires
      // Le niveau est passé dans les propriétés du composant de l'activité
      initNiveau(props.niveau);
      initActivite(null, true);
    }
  }



  //
  // Niveaux
  //

  const initNiveau = function(niveau) {

    // Cas particulier des niveaux 1 et 2 ( voir plus loin, dans initActivite ) :
    // On affichera le code "mix" si le slot tiré au sort est "centaines"

    niveauActivite.value = niveau;

    switch (niveau) {

      default:
      case 1:
        currentMinimumNumber = 0;
        currentMaximumNumber = 100;
        currentSlots = ["unites", "dizaines", "centaines"];
        code.value = "simple";
        break;

      case 2:
        currentMinimumNumber = 99;
        currentMaximumNumber = 199;
        currentSlots = ["unites", "dizaines", "centaines"];
        code.value = "simple";

        break;

      case 3:
        currentMinimumNumber = 99;
        currentMaximumNumber = 1000;
        currentSlots = ["unites", "dizaines", "centaines", "milliers"];
        code.value = "mix2";
        break;

      case 4:
        currentMinimumNumber = 999;
        currentMaximumNumber = 10000;
        currentSlots = ["unites", "dizaines", "centaines", "milliers"];
        code.value = "mix3";
        break;

      case 5:
        currentMinimumNumber = 9999;
        currentMaximumNumber = 100000;
        currentSlots = ["unites", "dizaines", "centaines", "milliers", "dizaines de milliers"];
        code.value = "mix4";
        break;

      case 6:
        currentMinimumNumber = 99999;
        currentMaximumNumber = 1000000;
        currentSlots = ["unites", "dizaines", "centaines", "milliers", "dizaines de milliers", "centaines de milliers"];
        code.value = "complex";
        break;
    }

    // Ada
    success = 0;
    successMax.value = 4;

    // console.log('niveau', niveauActivite.value, currentMinimumNumber, currentMaximumNumber);
  };

  const getNiveauFromValues = function() {
    const maxValue = _.max(props.values);
    if (maxValue <= 10) {
      return 1;
    } else if (maxValue <= 50) {
      return 2;
    } else if (maxValue <= 100) {
      return 3;
    } else if (maxValue <= 1000) {
      return 4;
    } else if (maxValue <= 10000) {
      return 5;
    } else if (maxValue <= 100000) {
      return 6;
    } else {
      return 7;
    }
  }


  //
  // Fonctions spécifiques à l'activité
  //

  function initActivite( chiffre, intro = false ) {

    // Ada
    updateAda("normal");

    let slot;

    if ((chiffre === null) || (chiffre === undefined))
    {
      // Tirage au sort des chiffres
      chiffre = randomIntFromInterval(currentMinimumNumber, currentMaximumNumber);

      // Tirage au sort : unités, dizaines, .... centaines de milliers

      // On crée un tableau des slots possibles, et on le mélange :
      // On épuisera le tableau avant de le cloner à nouveau

      let slotIsCompatible = false, slotIsCompatibleTestNo = 0;

      do {

        if (currentSlotsCopy.length === 0) {
          currentSlotsCopy = currentSlots.concat();
        }

        // On mélange le tableau
        shuffleArray(currentSlotsCopy);
        slot = currentSlotsCopy.shift();

        // On doit vérifier que le slot est possible selon le chiffre tiré au sort
        // ( test ajouté depuis que les niveaux ne correspondent exactement pas aux dizaines, centaines, ...)
        slotIsCompatible =
            (slot === "unites" ) ||
            ((slot === "dizaines" ) && (chiffre >= 10)) ||
            ((slot === "centaines") && (chiffre >= 100)) ||
            ((slot === "milliers" ) && (chiffre >= 1000)) ||
            ((slot === "dizaines de milliers" ) && (chiffre >= 10000)) ||
            ((slot === "centaines de milliers" ) && (chiffre >= 100000));

        slotIsCompatibleTestNo++;

        // console.log("test", chiffre, slot, "slotIsCompatible", slotIsCompatible, "test no", slotIsCompatibleTestNo);

        // Par prudence
        if ( !slotIsCompatible && (slotIsCompatibleTestNo > 3)) {
          slotIsCompatible = true;
          slot = "unites";
        }

      } while (slotIsCompatible === false)

    }
    else
    {
      // Saisie manuelle
      slot = props.colonne;
    }

    // Chiffre
    chiffreCard.value = chiffre;

    // Cas particulier :
    if (props.niveau < 3) {
      if ((chiffre > 99) && (slot === "centaines")) {
        code.value = "mix";
      } else {
        code.value = "simple";
      }
    }

    // Sert au son de la consigne :
    currentSlot = slot;

    let puissance_10 = 0;

    switch(slot) {

      case "unites":
        slotSingulier.value = "unité";
        slotPluriel.value = "unités";
        puissance_10 = 0;
        break;

      case "dizaines":
        slotSingulier.value = "dizaine";
        slotPluriel.value = "dizaines";
        puissance_10 = 1;
        break;

      case "centaines":
        slotSingulier.value = "centaine";
        slotPluriel.value = "centaines";
        puissance_10 = 2;
        break;

      case "milliers":
        slotSingulier.value = "millier";
        slotPluriel.value = "milliers";
        puissance_10 = 3;
        break;

      case "dizaines de milliers":
        slotSingulier.value = "dizaine de milliers";
        slotPluriel.value = "dizaines de milliers";
        puissance_10 = 4;
        break;

      case "centaines de milliers":
        slotSingulier.value = "centaine de milliers";
        slotPluriel.value = "centaines de milliers";
        puissance_10 = 5;
        break;
    }

    // Chiffre à trouver :
    chiffreSlot.value = Math.floor(chiffre / Math.pow(10, puissance_10));

    // Décomposition minimal du slot :
    decompositionMinimum.value = String(chiffreSlot.value).length;

    // console.log("----> chiffreSlot à trouver", chiffreSlot.value);

    // Son du chiffre
    playSound_Consigne_CurrentNumber(intro);

    // Drag and drop
    setTimeout(initDragAndDrop, 100);
  }

  function initDragAndDrop() {

    removeDragAndDrop();

    const $target = $(".draggable", '.clavier').not('.draggable-initialized');
    $target.addClass('draggable-initialized');

    var dropZones = $(".slot-zone");
    var overlapThreshold = "50%";
    var isDragging = false;
    var selectedDraggable;

    /* Méthode 1 : Drag and Drop */
    draggables = Draggable.create($target, {
      type: "x,y",
      bounds: window,
      edgeResistance: 0.65,
      throwProps: true,
      onPress: function (pointEvent) {

        removeDraggedStyle();

        // On ajoute l'effet au dé déplacé
        var draggableElement = $(pointEvent.target).closest(".draggable");
        draggableElement.find('.with-dragged-style').addClass("dragged"); // Effet sur la carte ou le dé

        selectedDraggable = draggableElement;
      },
      onDragStart: function (pointEvent) {

        var draggableElement = $(pointEvent.target).closest('.draggable');

        if (props.withSound) {
          var nombre = parseInt( draggableElement.find('.with-value').data('value') );
          var soundPath = getSoundPath_Chiffre(nombre);
          playSound(soundPath);
        }

        isDragging = true;
        selectedDraggable = null;

        dropZones.css("pointer-events", "none");
      },
      onDragEnd: function (pointEvent) {

        selectedDraggable = null;
        isDragging = false;

        var dropZones = $(".slot-zone");

        setTimeout(function () {
          dropZones.css("pointer-events", "inherit");
        }, 1000);

        removeDraggedStyle();

        var draggableElement = $(pointEvent.target).closest('.draggable');
        var nombre = draggableElement.find('.with-value').data('value');

        var i = dropZones.length;
        var dropZone;

        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            // Vérification :
            checkZone(dropZone, nombre);
          }
        }

        // Retour à la position initiale
        gsap.set(this.target, {x: 0, y: 0, delay: 0.1});

        isDragging = false;
      },
      onDrag: function () {
        var i = dropZones.length, dropZone;
        while (--i > -1) {
          dropZone = $(dropZones[i]);
          if (this.hitTest(dropZone, overlapThreshold)) {
            dropZone.addClass("hilite");
          } else {
            dropZone.removeClass("hilite");
          }
        }
      }
    });

    /* Méthode 2 : cliquer sur la zone de drop APRES avoir cliqué sur le coposant à déplacer */
    dropZones.on('click', function () {

      if (!isDragging && selectedDraggable) {
        $('.draggable').removeClass("dragged");

        var nombre = selectedDraggable.data('value');
        var dropZone = $(this);

        // Vérification :
        checkZone(dropZone, nombre);
      }
    })
  }

  function removeDragAndDrop() {
    $(".draggable", '.clavier').removeClass('draggable-initialized');

    // Kill Draggable behavior
    if (Array.isArray(draggables)) {
      draggables.map((draggable) => draggable.kill());
    }
  }

  function removeDraggedStyle() {
    $('.draggable').find('.with-dragged-style').removeClass("dragged");
  }

  function checkZone( dropZone, nombre ) {

    const dropZoneNo = parseInt( dropZone.attr("data-slot") );
    const dropZoneCorrectValue = parseInt( dropZone.attr("data-value") );
    // console.log("checkZone", dropZoneNo, '-->', dropZoneCorrectValue);

    const composantSlots = slots.value;

    if (nombre === dropZoneCorrectValue)
    {
      // Réponse correcte : dévoilement de la valeur du slot du composant
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      composantSlots.setSlotVisible(dropZoneNo, true);

      const composantSlotComplete = composantSlots.isComponentComplete();
      const composantSlotCorrect = composantSlots.isComponentCorrect();

      if (composantSlotComplete && composantSlotCorrect) {

        // Tous les slots sont bien remmplis : on lit l'opération

        // Son de félicitations, succès et replay
        playSound_Validation_and_AddSuccess();

      } else {
        // Le dernier slot rempli est correct
        updateAda("normal");
      }
    }
    else
    {
      // Réponse erronée : on affiche la valeur proposée ( qui s'affiche, mais ne modifie pas les données du composant )
      composantSlots.setSlotExternalValue(dropZoneNo, nombre);
      updateAda("error", 3000);
    }
  }

  const addSuccessAndReplay = function() {

    success++;

    if (success === successMax.value) {
      success = 0;
      updateAda("win");
    } else {
      setTimeout(() => {
        replay()
      }, 1000);
    }

  }


  //
  // Statistiques
  //

  const slotChanged = function($event) {
    // console.log('slotChanged', $event);

    if ($event.isComplete) {
      store.dispatch("addToPlaylistHistory", {
        playlistStepNo : parseInt(route.query.playlist),
        activite: route.name,
        niveau: niveauActivite.value,
        enonce: consigne.value,
        solution: chiffreCard.value,
        proposition: $event.proposed,
        isCorrect: $event.isCorrect
      });
    }
  }


  //
  // Sons
  //

  const playSound_Consigne_CurrentNumber = function(intro) {
    if (props.withSound)
    {
      // Son de la consigne
      let soundPath1;

      switch(currentSlot) {

        case "unites":
          soundPath1 = getSoundPath_Consigne("combien-d-unites-en-tout-dans", true, "decomposer");
          break;

        case "dizaines":
          soundPath1 = getSoundPath_Consigne("combien-de-dizaines-en-tout-dans", true, "decomposer");
          break;

        case "centaines":
          soundPath1 = getSoundPath_Consigne("combien-de-centaines-en-tout-dans", true, "decomposer");
          break;

        case "milliers":
          soundPath1 = getSoundPath_Consigne("combien-de-milliers-en-tout-dans", true, "decomposer");
          break;

        case "dizaines de milliers":
          soundPath1 = getSoundPath_Consigne("combien-de-dizaines-de-milliers-en-tout-dans", true, "decomposer");
          break;

        case "centaines de milliers":
          soundPath1 = getSoundPath_Consigne("combien-de-centaines-de-milliers-en-tout-dans", true, "decomposer");
          break;
      }

      const soundPaths = [ soundPath1 ].concat(getSoundPath_Chiffre_AsArray(chiffreCard.value));

      if (intro) {
        playSound(soundPaths, function(){ updateAda("help") });
      } else {
        playSound(soundPaths);
      }
    }
  };

  const getSoundPaths_Validation = function() {

    let soundPath1 = getSoundPath_Consigne("il-y-a", true, "decomposer");
    let soundPath3;

    switch(currentSlot) {

      case "unites":
        soundPath3 = getSoundPath_Consigne("unites-dans", true, "decomposer");
        break;

      case "dizaines":
        soundPath3 = getSoundPath_Consigne("dizaines-dans", true, "decomposer");
        break;

      case "centaines":
        soundPath3 = getSoundPath_Consigne("centaines-dans", true, "decomposer");
        break;

      case "milliers":
        soundPath3 = getSoundPath_Consigne("milliers-dans", true, "decomposer");
        break;

      case "dizaines de milliers":
        soundPath3 = getSoundPath_Consigne("dizaines-de-milliers-dans", true, "decomposer");
        break;

      case "centaines de milliers":
        soundPath3 = getSoundPath_Consigne("centaines-de-milliers-dans", true, "decomposer");
        break;
    }

    let soundPaths = [ soundPath1 ].concat(getSoundPath_Chiffre_AsArray(chiffreSlot.value, true));
    soundPaths.push(soundPath3);
    soundPaths = soundPaths.concat(getSoundPath_Chiffre_AsArray(chiffreCard.value));

    return soundPaths;
  };

  function playSound_Validation_and_AddSuccess() {
    if (props.withValidation)
    {
      if (props.withSound)
      {
        const soundPaths = getSoundPaths_Validation();
        playSound(soundPaths, addSuccessAndReplay);
      }
      else
      {
        addSuccessAndReplay();
      }
    }
  }


</script>

<style scoped lang="scss">

  .decomposer-v2 {

    @include on-tablet {
      padding-top: 40px;
    }

    .des-et-chiffres-a-trouver {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 80px;

      margin-bottom: 50px;
      flex-wrap: wrap;

      @include on-small-height-desktop {
        gap: 30px;
      }
    }

    .clavier {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      @include on-tablet {
        margin-left: 40px;
      }
    }

    .question,
    .reponse {
        display: flex;
        flex-basis: 100%;
        align-items: center;
    }

    .question > h3,
    .reponse > h3 {
      margin-right:20px;
      margin-left:20px;
    }

  }

</style>
